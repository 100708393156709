import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false,
            showLogin: false
        };
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LogoutActions.Logout:
                this.setState({ message: "Please wait.  Logging you out." });
                this.logout(this.getReturnUrl());                
                break;
            case LogoutActions.LogoutCallback:
                this.setState({ message: "Logging you out.  Almost done." });
                this.processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                this.setState({ isReady: true, message: "You have been successfully logged out." });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        this.populateAuthenticationState();
    }

    renderLoggedOut = (showLogin) => {
        return (
            <div className="container-fluid sign-up-block">
                <div className="row">
                    <div className="col-md-5 px-0">
                        <figure className="m-0 w-100 h-100">
                            <img src="/images/sign-up-bg.png" alt="" className="w-100 h-100 bg-image" />
                        </figure>
                            <div className="logo position-absolute">
                                <img src="/images/Logo.svg" alt="" />
                                    <h1>Tell Your Story. Share in the Adventure.</h1>
                            </div>
                            </div>
                            <div className="col-md-7">
                                <div className="sign-up-form w-100 mx-auto pb-3">
                                    <h2 className="mb-4 mb-md-5 text-center text-lg-left">Thanks for visiting.  See you next time!</h2>
                                    <div className="mb-4 mb-md-5">
                                    <p>{this.state.message}</p>
                                        {showLogin ? <p>Click <a href="/Identity/Account/Login">here</a> to login.</p> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            );
    }

    render() {
        const { isReady } = this.state;
        if (!isReady) {
            return <div></div>
        }

        const action = this.props.action;
        switch (action) {
            case LogoutActions.Logout:
                return (this.renderLoggedOut(false));
            case LogoutActions.LogoutCallback:
                return (this.renderLoggedOut(false));
            case LogoutActions.LoggedOut:
                return (this.renderLoggedOut(true));
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    async logout(returnUrl) {
        const state = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ message: result.message });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } else {
            this.setState({ message: "You successfully logged out!" });
        }
    }

    async processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ isReady: true, authenticated });
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl) {
        return window.location.replace(returnUrl);
    }
}
