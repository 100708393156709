import React from "react";
import { FormGroup, Label } from "reactstrap";

/**
 * 
 * @param {{rows:Number,title:string, value:string, handleInput:Function, name:string, inputStyle:object, placeholder:string}} props
 */
const FormTextArea = (props) => {
    return (
        <FormGroup>
            <Label className="control-label" style={{ fontFamily: "Archivo" }}>
                <strong>{props.title}</strong>
            </Label>

            <textarea
                className="form-control"
                rows={props.rows}
                value={props.value}
                onChange={props.handleInput}
                name={props.name}
                style={props.inputStyle}
                placeholder={props.placeholder}
            />
        </FormGroup>
        )
}

export default FormTextArea;