import React from "react";
import { Link } from "react-router-dom";
import CommentIcon from "components/icons/CommentIcon";
import ImageWrapper from "components/ImageWrapper";
import { connect } from "react-redux";
import { toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import CommentIconPlusSign from "components/icons/CommentIconPlusSign";
import SaleIcon from "./icons/SaleIcon";

/**
 * 
 * @param {{
 * product:{coupon:string, id:Number,product:{imageUrl:string, name:string}}
 * productKey:Number,
 * post:object
 * showDescription:Function,
 * comment:string,
 * isEditing:boolean,
 * parentModal:{type:string,shareCode:string,parentState:object}
 * }} props
 */
const PostPageProduct = (props) => {
    function displayIcon() {
        if (props.locker && props.comment?.length > 0) {
            return (
                <span style={{ left: !!(props.product?.creatorCount) && props.product.creatorCount > 0 ? "45px" : "", cursor: "pointer", paddingRight: "2px" }} href="/" className="product-comment-locker-page position-absolute" onClick={(e) => {
                    e.preventDefault()
                    props.showDescription(props.productKey)
                }}>
                     <CommentIcon />
                </span>
            )
        }
        else if (props.post?.type === 2 && props.comment?.length > 0) {
            return (
                <span style={{ cursor: "pointer" }} href="/" className="product-comment-locker-page position-absolute" onClick={(e) => {
                    e.preventDefault()
                    props.showDescription(props.productKey)
                }}>
                    <CommentIcon width={18} height={18} />
                </span>
            )
        }
    }

    const renderSaleOverlay = (sale) => {
        if (!props.saleIconNever && sale && ["sm", "md"].includes(props.deviceSize)) {
            return (
                <span className="on-sale-icon">
                    <SaleIcon />
                </span >
            );
        }
    }

    let url = "/Product/ProductPage/" + props.product.shareCode;
    url += props.brandEmbed ? "?r=0&b=1&showBrandNav=true" : "?r=1";
    //url = props.disableRedirects ? "https://" + window.location.host + "/browse" : url;

    return (
        <>
            {renderSaleOverlay(props.product.sale)}
            <a href={url} target={props.product.merchant} style={{ cursor: "pointer" }}>
                <ImageWrapper src={props.product.imageUrl} className={`${props.brandEmbed ? "pt-3" : ""}`} brandEmbed={props.brandEmbed} size={`Product`} style={{ ...props.style, top: props.isBrandSmall ? "1%" : props.brandEmbed ? "1%" : "" }} alt="product"
                    lazy={!!props.aboveTheFold ? !props.aboveTheFold : true}
                />
            </a>

            {displayIcon()}

            {/*props.post?.type === 2 && props.comment?.length > 0 ?
                (<span style={{ cursor: "pointer", paddingRight: "2px" }} href="/" className="product-comment position-absolute" onClick={(e) => {
                    e.preventDefault()
                    props.showDescription(props.productKey)
                }}>
                    {props.deviceSize === "sm" ? <CommentIcon width={12} height={12} /> : <CommentIcon />}
                </span>) : null
            */}
        </>
    )
}

export default connect(null, { toggleProductPageModal })(PostPageProduct);