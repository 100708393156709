import React from "react";


const UserFeedCarousel = ({ post, index }) => {
    const mapProducts = () => {
        if (post.productItems != undefined && post.productItems != null && post.productItems.$values.length > 0) {
            return post?.productItems?.$values.map((product, index) => {
                let renderedItem;

                let url = "/Products/BuyAuthorized?sharecode=" + product.userMerchantProduct.coupon;
                if (index === 0) {
                    renderedItem = (
                        <div className="carousel-item active" >
                            <a href={url} target="_blank" style={{ cursor: "pointer" }}>
                                <img class="d-block" style={{ maxHeight: "250px", position: "relative", left: "50%", transform: "translate(-50%, 10%)" }}
                                    src={product.userMerchantProduct.product.imageUrl}
                                    alt="First slide" />
                            </a>
                        </div>
                    )
                } else {
                    renderedItem = (
                        <div class="carousel-item" >
                            <a href={url} target="_blank" style={{ cursor: "pointer" }}>
                                <img class="d-block" style={{ maxHeight: "250px", position: "relative", left: "50%", transform: "translate(-50%, 10%)" }} src={product.userMerchantProduct.product.imageUrl} alt="Additional slide" />
                            </a>
                        </div>
                    )
                }
                return renderedItem
            });
        } else {
            return <></>
        }
    }

    const renderCarouselIndicators = () => {
        if (post.productItems != undefined && post.productItems != null && post.productItems.$values.length > 0) {
            return post?.productItems?.$values.map((product, i) => {
                if (index === 0) {
                    return <li data-target={`#carouselExampleIndicators${index}`} data-slide-to={i} class="active"></li>
                } else {
                    return <li data-target={`#carouselExampleIndicators${index}`} data-slide-to={i}></li>
                }
            });
        } else {
            return <></>;
        }
    }

    return (
        <div id={`carouselExampleIndicators${index}`} class="carousel slide" style={{ width: "100%" }} data-ride="carousel">
            <ol class="carousel-indicators">
                {renderCarouselIndicators()}
                {/*<li data-target={`#carouselExampleIndicators${index}`} data-slide-to="0" class="active"></li>*/}
                {/*<li data-target={`#carouselExampleIndicators${index}`} data-slide-to="1"></li>*/}
                {/*<li data-target={`#carouselExampleIndicators${index}`} data-slide-to="2"></li>*/}
            </ol>
            <div class="carousel-inner">
                {mapProducts()}
                {/*<div class="carousel-item active">*/}
                {/*    <img class="d-block" style={{ maxHeight: "350px" }} src="https://evolvedoutdoorsdev.blob.core.windows.net/users/100005/Posts/617/4c1580d6-5ed8-4da6-9ced-a0e1d116c009.JPG" alt="First slide"/>*/}
                {/*</div>*/}
                {/*<div class="carousel-item">*/}
                {/*    <img class="d-block" style={{ maxHeight: "350px" }} src="https://evolvedoutdoorsdev.blob.core.windows.net/users/100005/Posts/617/4c1580d6-5ed8-4da6-9ced-a0e1d116c009.JPG" alt="Second slide"/>*/}
                {/*</div>*/}
                {/*<div class="carousel-item">*/}
                {/*    <img class="d-block" style={{ maxHeight: "350px" }} src="https://evolvedoutdoorsdev.blob.core.windows.net/users/100005/Posts/617/4c1580d6-5ed8-4da6-9ced-a0e1d116c009.JPG" alt="Third slide"/>*/}
                {/*</div>*/}
            </div>
            <a class="carousel-control-prev" href={`#carouselExampleIndicators${index}`} role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href={`#carouselExampleIndicators${index}`} role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
                                    
    )
}

export default UserFeedCarousel;
