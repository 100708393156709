import React from "react";

const ThreeDotsVerticalIcon = () => {
    return (
        <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V28C32 29.1046 31.1046 30 30 30H2C0.895431 30 0 29.1046 0 28V2Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9998 12.3327C16.7332 12.3327 17.3332 11.7327 17.3332 10.9993C17.3332 10.266 16.7332 9.66602 15.9998 9.66602C15.2665 9.66602 14.6665 10.266 14.6665 10.9993C14.6665 11.7327 15.2665 12.3327 15.9998 12.3327ZM15.9998 13.666C15.2665 13.666 14.6665 14.266 14.6665 14.9993C14.6665 15.7327 15.2665 16.3327 15.9998 16.3327C16.7332 16.3327 17.3332 15.7327 17.3332 14.9993C17.3332 14.266 16.7332 13.666 15.9998 13.666ZM14.6665 18.9993C14.6665 18.266 15.2665 17.666 15.9998 17.666C16.7332 17.666 17.3332 18.266 17.3332 18.9993C17.3332 19.7327 16.7332 20.3327 15.9998 20.3327C15.2665 20.3327 14.6665 19.7327 14.6665 18.9993Z" fill="black" fillOpacity="0.6" />
        </svg>

    )
}

export default ThreeDotsVerticalIcon;