import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import InformationIcon from "components/icons/InformationIcon";
import Select from 'react-select'
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import _, { forEach } from "lodash";
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "./LoadSpinner";
import { Link } from "react-router-dom";

const { DateTime } = require("luxon");

class BrandCommissionsChart extends Component {
    constructor(props) {
        super(props);

        this.selectOptions = {
            last24Hours: {
                value: 0,
                label: "Yesterday",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 1 })).toUTC(),
                        endDate: asDate(DateTime.now()).toUTC()
                    }
                }
            },
            last7Days: {
                value: 1,
                label: "Last 7 Days",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 7 })).toUTC(),
                        endDate: asDate(DateTime.now().plus({ days: 1 })).toUTC()
                    }
                }
            },
            last30Days: {
                value: 2,
                label: "Last 30 Days",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 30 })).toUTC(),
                        endDate: asDate(DateTime.now().plus({ days: 1 })).toUTC()
                    }
                }
            },
            last90Days: {
                value: 3,
                label: "Last 90 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 90 })).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            yearToDate: {
                value: 4,
                label: "Year To Date",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year, 1, 1)).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            }
        };

        this.state = {
            selectOptions: {
                options: [
                    this.selectOptions.last24Hours,
                    this.selectOptions.last7Days,
                    this.selectOptions.last30Days,
                    this.selectOptions.last90Days,
                    this.selectOptions.yearToDate,
                ],
                defaultValue: this.selectOptions.last7Days,
                currentValue: this.selectOptions.last7Days,
            },
            series: [
                {
                    name: 'Estimated Commissions',
                    type: 'bar',
                    data: []
                }
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                markers: {
                    size: [4],
                },
                stroke: {
                    width: [4]
                },
                labels: [],
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [
                    {
                        seriesName: 'Estimated Commissions',
                        title: {
                            text: '$',
                        },
                        labels: {
                            // Formats the number displayed on the y-axis label
                            formatter: function (val) {
                                return val.toFixed(2);
                            }
                        }
                    }
                ],
                dataLabels: {
                    formatter: function (val) {
                        // Formats the number displayed on the bar
                        return val.toFixed(2);
                    }
                }
            },
        };
    }

    componentDidMount = async () => {
        this.handleSelect(this.state.selectOptions.defaultValue);
    }

    handleSelect = async (selectedOption) => {
        if (selectedOption) {
            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = selectedOption;
            this.setState({ selectOptions });
        }
        const dateRange = selectedOption?.getDateRange();
        this.props.getCommissions(dateRange?.startDate, dateRange?.endDate);
    }

    displayTableRows = () => {
        if (this.props.commissions.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="6" style={{ textAlign: "center"}}>No data for this time frame</td>
                </tr>
            );
        }

        let aggregated = [];

        this.props.commissions.forEach((commission) => {
            let found = aggregated.findIndex(el => el.username === commission.username && el.status === commission.status);

            if (found === -1) {
                aggregated.push({ ...commission });
            }
            else {
                aggregated[found].purchaseAmount += commission.purchaseAmount;
                aggregated[found].qualifiedCommissionAmount += commission.qualifiedCommissionAmount;

            }

        })

        return aggregated.map((data, i) => {
            return (
                <tr key={data.commissionId}>
                    {/*
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{convertToNumericMonthAndDay(new Date(data.createdDate), false)}</span>
                    </td>
                    */}
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span><Link to={`/Profile/${data.username}`}>{data.username}</Link></span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.purchaseAmount.toFixed(2)}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.returnAmount.toFixed(2)}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.qualifiedCommissionAmount.toFixed(2)}</span>
                    </td>
                    {/*
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{convertToNumericMonthAndDay(data.paymentDate, false)}</span>
                    </td>
                    */}
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.status}</span>
                    </td>
                </tr>);
        });
    }

    displayTotals = () => {
        let totals = {
            purchaseAmount: 0,
            returnAmount: 0,
            qualifiedCommissionAmount:0
        }

        this.props.commissions.forEach((commission) => {
            totals.purchaseAmount += commission.purchaseAmount;
            totals.qualifiedCommissionAmount += commission.qualifiedCommissionAmount;
        })

        return (
            <tr>
                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        Totals
                    </strong>
                </td>

                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        ${totals.purchaseAmount.toFixed(2)}
                    </strong>
                </td>

                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        ${totals.returnAmount.toFixed(2)}
                    </strong>
                </td>

                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        ${totals.qualifiedCommissionAmount.toFixed(2)}
                    </strong>
                </td>

                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                    </strong>
                </td>
            </tr>
            )
    }

    displayTableHeader = () => {
        let headers = [/*"Date",*/ "Creator", "Sales Amount", "Returns", "Estimated Commission", /*"Eligible Date",*/ "Status"];
        let infoIcon = [/*"",*/ "", "The total sales dollars generated after a User clicked a Buy Now link from this Creator", "The dollar amount of returns on the sale", "The estimated commission earned by the order. See RockPorch ToS for more detail.", "The date a commission is eligible for payment. See RockPorch ToS for more detail.",
            ["Qualified - The commission was generated by a Qualified Purchase and is held by the merchant or other parties for a period to account for returns, refunds, or other adjustments.",
                "Eligible - The net commission eligible for payment.Eligible commissions are paid at each RockPorch payment interval so long as the total Eligible Commissions exceed $100.",
                "Pending - The commission is ready for payment and will be paid at the next RockPorch payment interval.",
                "Paid - The commission payment has been remitted."]]

        return headers.map((header, i) => {
            return (
                <th key={i} style={{ minWidth: "0", paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>{header}</strong>
                    {i > 0 ?
                        <>
                            <small className="d-inline-block ml-1">
                                <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id={'cInfoIcon' + i}><InformationIcon /></button>
                            </small>
                            <UncontrolledPopover
                                placement={i === 6 ? 'left' : 'bottom'}
                                target={'cInfoIcon' + i}
                                trigger="legacy"
                            >
                                {i !== 6 ?
                                    <PopoverBody>{infoIcon[i]}</PopoverBody>
                                    : <PopoverBody>{infoIcon[i].map((item) => { return <>{item}<br /></> })}</PopoverBody>}
                            </UncontrolledPopover>
                        </>
                        : null}

                </th>
            )
        })
    }

    getDaysBetweenDates = function (start, end) {
        for (var arr = [], dt = new DateTime(start); dt < end; dt = dt.plus({ days: 1 })) {
            arr.push(dt.toJSDate());
        }
        return arr;
    };

    displayChart = () => {
        var data = []; // [{ x: '07/31/2021', y: 2.30 }]
        var labels = [];

        const dateRange = this.state.selectOptions.currentValue.getDateRange();
        var dates = this.getDaysBetweenDates(dateRange.startDate, dateRange.endDate);
        dates.forEach(date => {
            const formattedDate = convertToNumericMonthAndDayAndYear(date, false);

            if (!data.find(x => x.x == formattedDate)) {

                data.push({ x: formattedDate, y: 0 });

                const formattedLabelDate = convertToNumericMonthAndDayAndYear(date, false);
                labels.push(formattedLabelDate);
            }
        });

        this.props.commissions.forEach(commission => {
            const formattedDate = convertToNumericMonthAndDayAndYear(new Date(commission.createdDate), false);

            const item = _.find(data, (item) => item?.x === formattedDate);
            if (item) {
                item.y += commission.qualifiedCommissionAmount;
            } else {
                data.push({ x: formattedDate, y: commission.qualifiedCommissionAmount });
            }

            const label = _.find(labels, (label) => label === formattedDate);
            if (!label) {
                labels.push(formattedDate);
            }
        });

        const { options, series } = this.state;
        series[0].data = data;
        options.labels = labels;

        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" />
            </div>
        );
    }

    render() {
        return (
            <div className="container pt-0 pb-5 px-0 px-md-3" style={this.props.display ? {} : { display: 'none' }}>
                <div className="search-interest py-4 mx-auto">
                    <div className="search-block">
                        <div className="row mx-0">
                            <div className="col-12 col-md-4 mt-1 position-relative float-right">
                                <Select
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    placeholder="Select timeframe ..."
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    onChange={(e) => { this.handleSelect(e) }}
                                    options={this.state.selectOptions.options}
                                    defaultValue={this.state.selectOptions.defaultValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.commissionsLoading ?
                        <LoadSpinner /> :
                        <>
                            {this.displayChart()}
                            <div className="row mx-0 my-0">
                                <div className="table-responsive product-table">
                                    <table className="table">
                                        <colgroup>
                                            {/*<col span="1" style={{ width: "16%" }} />*/}
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                {this.displayTableHeader()}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.displayTableRows()}
                                            {this.displayTotals()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}

export default BrandCommissionsChart;