import React from "react";


/**
 * 
 * @param {{width:number,height:number}} props
 */
const CommentIconPlusSign = (props) => {
    return (
        <svg width={props.width || "16"} height={props.height||"16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5 2C15.5 1.175 14.825 0.5 14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V11C0.5 11.825 1.175 12.5 2 12.5H12.5L15.5 15.5V2ZM14 11.8775L13.1225 11H2V2H14V11.8775ZM7.25 2.75H8.75V5.75H11.75V7.25H8.75V10.25H7.25V7.25H4.25V5.75H7.25V2.75Z" fill="black" fillOpacity="0.4" />
        </svg>

        )
}

export default CommentIconPlusSign;
