import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { NavItem, NavLink, UncontrolledPopover, Popover, PopoverBody, PopoverHeader, Toast, ToastBody, ToastHeader, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';

import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

import GridIcon from "components/icons/GridIcon";
import DoubleBarIcon from "components/icons/DoubleBarIcon";
import PlusIconNav from "components/icons/PlusIconNav";
import CloseIcon from "components/icons/CloseIcon";

import { toggleFeedLayout } from "stateManagement/reducers/feedLayoutReducer";
import { toggleAddProductModal, toggleCreateLockerModal, toggleShareModal, toggleSharePostModal } from "stateManagement/reducers/modalReducer";

import { postRequest } from '../../sharedUtils/httpUtils';
import { getMessageCount, getNotificationCount } from "sharedUtils/notificationUtils";
import InformationIcon from "components/icons/InformationIcon";


class LoginMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            showSharePostModal: false,
            searchValue: "",
            redirectValue: "",
            redirectSearch: false,
            showProfilePicturePrompt: false,
            showPrompt: { }
        };

        this.clickHandler = this.clickHandler.bind(this);
        this.dropDownIconRef = React.createRef();
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        await this.populateState();

        if (this.props.loggedInUser?.imageUrl === null || this.props.loggedInUser?.imageUrl === undefined) {
            this.setState({ showProfilePicturePrompt: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let element = document.getElementById('createLockerPrompt')
        if (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }

        element = document.getElementById('sharePostPrompt')
        if (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        }

        if (this.state.redirectSearch === true) {
            this.setState({ redirectSearch: false })
        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    clickHandler(e) {
        e.preventDefault();
        this.toggleModal();
    }

    displayLayoutToggleButton = () => {

        return (
            <Link to="/" onClick={(e) => {
                e.preventDefault();
                this.props.toggleFeedLayout();
            }}
            >
                {this.props.feedLayout.isGrid === true ? <DoubleBarIcon /> : <GridIcon />}
            </Link>
        )
    }

    togglePrompt = (id) => {
        let showPrompt = { ...this.state.showPrompt };
        showPrompt[id] = !showPrompt[id];

        this.setState({ showPrompt });
    }

    mapAddIconDropdown = () => {
        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";

        let menu = [
            {
                title: "Add a Product",
                action: this.props.toggleAddProductModal,
                iconPath: "images/Thunder.svg",
            },
            {
                title: "Create a Locker",
                action: this.props.toggleCreateLockerModal,
                iconPath: "images/Difference.svg",
                infoPrompt: "Lockers are pursuit or destination specific gear lists",
                infoPromptId: "createLockerPrompt"
            },
            {
                title: "Share a Post",
                action: this.props.toggleSharePostModal,
                iconPath: "images/Picture.svg",
                infoPrompt: "Integrate your social accounts to repost content and tag products",
                infoPromptId: "sharePostPrompt"
            }
        ]

        return menu.map((menuItem, i) => {
            return (
                <li key={i} style={{ display: "flex", justifyContent: "start" }}>
                    <Link to="#" className="dropdown-item d-flex align-items-center px-2 py-2"
                        onClick={(e) => {
                            e.preventDefault();
                            menuItem.action();
                        }}
                    >
                        <img src={menuItem.iconPath} alt=""></img>
                        <p className="m-0">
                            <strong className="d-block">{menuItem.title}</strong>
                         </p>
                    </Link>
                    {menuItem.infoPromptId ?
                        <>
                            <button style={{ outline: "none", border: 0, paddingRight: "10px", marginTop: "2px", marginLeft: "0px", borderTop: "1px solid #e9ecef", backgroundColor: "white", position: "relative", top: "-2px" }} id={menuItem.infoPromptId} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                this.togglePrompt(menuItem.infoPromptId)
                            }}>
                                <InformationIcon />
                            </button>
                            <Popover
                                isOpen={this.state.showPrompt[menuItem.infoPromptId]}
                                placement="bottom"
                                target={menuItem.infoPromptId}
                                toggle={() => { this.togglePrompt(menuItem.infoPromptId) }}
                                trigger="legacy">
                                <PopoverBody>
                                    {menuItem.infoPrompt}
                                </PopoverBody>
                            </Popover>
                        </> : null
                    }
                </li>
            )
        })
    }

    mapThreeDotIconDropdown = () => {
        let menu = [
            { title: "Explore", path: "/Explore" },
            { title: "My Feed", path: "/HomeFeed" },
            { title: "About", path: "https://www.rockporch.com/home", target: "blank_" },
            { title: "Terms of Service", path: "https://www.rockporch.com/terms/", target: "blank_" },
            { title: "Privacy Policy", path: "https://www.rockporch.com/privacy-policy/", target: "blank_" },
            { title: "Contact", path: "/Contact" },
        ]

        if (this.props.loggedInUser?.proStatusId === 1) {
            menu = [
                { title: "Profile", path: `/Profile/${this.props?.loggedInUser?.userName}` },
                { title: "Explore", path: "/Explore" },
                { title: "My Feed", path: "/HomeFeed" },
                { title: "FAQ", path: "https://www.rockporch.com/faq/", target: "blank_" },
                { title: "About", path: "https://www.rockporch.com/home", target: "blank_" },
                { title: "Terms of Service", path: "https://www.rockporch.com/terms/", target: "blank_" },
                { title: "Privacy Policy", path: "https://www.rockporch.com/privacy-policy/", target: "blank_" },
                { title: "Contact", path: "/Contact" },
            ];
        }

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <NavLink tag={Link} to={nested.path} className="dropdown-item" onClick={(e) => e.preventDefault()}>{nested.title}</NavLink>
                    </li>
                )
            })
            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <a className="dropdown-item nav-link" href={menuItem.path} target={menuItem.target}>{menuItem.title}</a>
                            :
                            <NavLink tag={Link} to={menuItem.path} className="dropdown-item">{menuItem.title}</NavLink>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({
            isAuthenticated: isAuthenticated
        }, () => {
            if (isAuthenticated) {
            }
        });
    }

    redirect = () => {
        return <Link to={`/SearchResults/${this.state.redirectValue}`} id="redirectLink" style={{ display: "hidden" }}></Link>
    }

    renderLoginDropdown(logoutPath) {
        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";
        if (this.props.loggedInUser?.proStatusId === 1) {
            return (
                <>
                    <NavItem className="dropdown login-dropdown" style={{ display: "inline" }}>
                        <NavLink tag={Link}
                            className="btn dropdown-toggle p-0 d-flex justify-content-center align-items-center"
                            type="button"
                            id="profile-dropdown"
                            to="/"
                            data-toggle="dropdown"
                        >
                            {
                                this.props?.loggedInUser?.imageUrl ? (< img id="profilePicId" src={this.props.loggedInUser.imageUrl} alt=""></img>) : < img id="profilePicId" src="/images/avatar2.svg" alt=""></img>
                            }
                        </NavLink>

                        <ul className={`dropdown-menu`} aria-labelledby="dropdownMenuButton" >
                            <li>
                                <NavLink tag={Link} to={`/Profile/${this.props?.loggedInUser?.userName}`} className="dropdown-item override-active">My Profile</NavLink>
                            </li>
                            {this.props?.loggedInUser?.proStatusId === 1 ?
                                <li>
                                    <NavLink tag={Link} to="/Account/Products" className="dropdown-item override-active">My Products</NavLink>
                                </li> : <></>}
                            {this.props?.loggedInUser?.proStatusId === 1 ?
                                <li>
                                    <NavLink tag={Link} to="/Account/Brands" className="dropdown-item override-active">Merchant Partners</NavLink>
                                </li> : <></>}
                            {this.props?.loggedInUser?.proStatusId === 1 ?
                                <li>
                                    <NavLink tag={Link} to="/Account/CreatorDashboard" className="dropdown-item override-active">Creator Dashboard</NavLink>
                                </li> : <></>}

                        </ul>
                    </NavItem>

                    {
                        this.props.loggedInUser && this.props.loggedInUser.onboardingComplete && (this.props.loggedInUser?.imageUrl === null || this.props.loggedInUser?.imageUrl === undefined) ? (
                            <Popover
                                isOpen={this.state.showProfilePicturePrompt}
                                target="profilePicId"
                                placement="bottom"
                                trigger="legacy"
                                toggle={async () => { if (this.state.showProfilePicturePrompt) { await this.setDefaultProfilePicture() } }}
                            >
                                <PopoverHeader>Update Your Profile</PopoverHeader>
                                <PopoverBody>
                                    <div style={{ cursor: "pointer" }} onClick={async (e) => {
                                        await this.setDefaultProfilePicture();
                                        this.props.history.push('/Account/EditProfile');
                                    }}>
                                        <small>Edit</small></div>
                                    <div style={{ cursor: "pointer" }} onClick={e => {
                                        this.setDefaultProfilePicture();
                                    }}>
                                        <small>Skip</small>
                                    </div>
                                </PopoverBody>
                            </Popover>
                        ) : <span></span>
                    }
                </>
            );
        } else {
            return <></>
        }
    }

    setDefaultProfilePicture = async () => {
        let { props } = this;
        let userDetails = {
            name: props.loggedInUser.name,
            bio: props.loggedInUser.bio,
            tagLine: props.loggedInUser.tagLine,
            website: props.loggedInUser.website,
            googleUsername: props.loggedInUser.googleUsername,
            facebookUsername: props.loggedInUser.facebookUsername,
            instagramUsername: props.loggedInUser.instagramUsername,
            userName: props.loggedInUser.userName,
            email: props.loggedInUser.email,

            phoneNumber: props.loggedInUser.phoneNumber,
            proStatusId: props.loggedInUser.proStatusId,
            address: props.loggedInUser.address,
            city: props.loggedInUser.city,
            state: props.loggedInUser.state,
            postalCode: props.loggedInUser.postalCode,
            country: props.loggedInUser.country,

            onboardingComplete: props.loggedInUser.onboardingComplete,
            profileBannerUrl: props.loggedInUser.profileBannerUrl,

            quickStart: props.loggedInUser.quickStart
        };
        userDetails.imageUrl = document.location.protocol + '//' + document.location.host + '/images/avatar.png';

        let postUserUrl = "api/Account/PostUser";
        let user = await postRequest(postUserUrl, userDetails);

        await this.props.setLoggedInUser(user);
    }

    searchInputHandle = (event) => {
        this.setState({ searchValue: event.target.value, redirectValue: event.target.value })
    }

    searchInputSubmit = (e) => {

        if (e.keyCode === 13 && this.state.redirectValue.length > 1) {
            this.setState({ redirectSearch: true });
            document.getElementById("redirectLink").click();
            this.setState({ redirectSearch: false });
        }
    }

    toggleModal = () => {
        this.setState({ showSharePostModal: !this.state.showSharePostModal });
    }

    authenticatedView(user, profilePath, logoutPath) {
        if (this.props.deviceSize === "sm" || this.props.deviceSize === "md") {
            return (
                <Fragment>
                    {this.redirect()}

                    <div className="nav-block" id="main_div" style={{ display: "inline-flex", flexDirection: "row", justifyContent: "space-between", width: "fit-content", position: "relative", top: "0px !important" }} >

                        {this.props?.loggedInUser?.proStatusId === 1 ? (
                            <>
                                <NavItem className="dropdown product-dropdown" style={{ display: "inline" }}>
                                    <NavLink tag={Link} className="btn dropdown-toggle p-0 d-flex justify-content-center align-items-center"
                                        type="button"
                                        to="/"
                                        id="product-dropdown"
                                        data-toggle="dropdown"
                                    >
                                        <PlusIconNav height="8" width="8" />

                                    </NavLink>

                                    <ul className={`dropdown-menu`} aria-labelledby="dropdownMenuButton">
                                        {this.mapAddIconDropdown()}
                                    </ul>

                                </NavItem>
                            </>
                        ) : (

                            /* Ensures that the navbar logo stays centered on mobile for non-creators by adding an empty span with the same width as a Creators + button */
                                <span style={["lg", "xl"].includes(this.props?.deviceSize) ? { width: "200px", height: "20px" } : {}}></span>
                        )
                        }
                        {this.renderLoginDropdown(logoutPath)}
                    </div>

                </Fragment>
            )
        } else {
            const nonCreatorStyle = this.props?.loggedInUser?.proStatusId === 1 ? { width: "80%", marginLeft: "12.5%", marginRight: "-3.5%" } : { width: "80%", position: "relative", left: "-15% !important" };
            return (
                <Fragment>
                    {this.redirect()}
                    <div className="col-8 middle-column d-flex align-items-center justify-content-between text-nowrap"
                        style={this.props?.loggedInUser?.proStatusId === 1 ? {} : { marginLeft: "9%" } }>

                        <div className="form-inline my-lg-0" style={nonCreatorStyle}>
                            <div className="form-group position-relative" id="search-bar" style={{ flexFlow: "row", borderRadius: "30px" }}>

                                <input className="form-control mr-sm-2"
                                    id="search-input"
                                    type="search"
                                    placeholder="Search..."
                                    aria-label="Search"
                                    onChange={(e) => this.searchInputHandle(e)}
                                    value={this.state.searchValue}
                                    onKeyUp={(e) => this.searchInputSubmit(e)}
                                />

                                <button className="close mr-2" onClick={() => this.setState({ searchValue: "" })} >
                                    <CloseIcon />
                                </button>

                                <Link to={this.state.searchValue.length > 1 ? `/SearchResults/${this.state.searchValue}` : ""}>
                                    <button className="pl-0 pt-0 position-absolute"><img src="/images/search-icon.svg" alt="Search" /></button>
                                </Link>

                            </div>
                        </div>

                    </div>

                    <div className="col-3 nav-block navbar-collapse" id="main_div">

                        <ul className="navbar-nav d-flex justify-content-center justify-content-lg-end w-100 align-items-center">

                            {this.props?.loggedInUser?.proStatusId === 1 ?
                                <NavItem className="dropdown product-dropdown">

                                    <NavLink tag={Link} className="btn dropdown-toggle p-0 d-flex justify-content-center align-items-center"
                                        type="button"
                                        id="product-dropdown"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        to="/">
                                        <PlusIconNav />

                                    </NavLink>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {this.mapAddIconDropdown()}
                                    </ul>
                                </NavItem> : <></>}
                            {this.renderLoginDropdown(logoutPath)}
                        </ul>
                    </div>
                </Fragment>
            );
        }


    }

    anonymousView(registerPath, loginPath) {

        let isSmallDevice = this.props.deviceSize === "sm" || this.props.deviceSize === "md";

        const link = document.location;

        if (isSmallDevice) {
            return (
                <div className="col-4 pr-0" style={{ textAlign: "right" }}>
                    <a href="/Identity/Account/Login" style={{ borderRadius: "30px", padding: "5px 10px" }} className="btn btn-primary">Login</a>
                </div>
            )
        }
        else {
            return (
                <Fragment>
                    {this.redirect()}
                    <div className="col-8 middle-column d-flex align-items-center justify-content-between text-nowrap"
                        style={this.props?.loggedInUser?.proStatusId === 1 ? {} : { marginLeft: "3%" }}>
                        
                        <span style={this.props?.loggedInUser?.proStatusId === 1 ? { width: "35px" } : {width: "35px", marginLeft: "-10%"}}></span>

                        <div className="form-inline my-lg-0" style={this.props?.loggedInUser == undefined || this.props?.loggedInUser == null ? { width: "100%" } : {} }>
                            <div className="form-group position-relative" id="search-bar" style={{ flexFlow: "row", borderRadius: '30px' }}>

                                <input className="form-control mr-sm-2"
                                    id="search-input"
                                    type="search"
                                    placeholder="Search..."
                                    aria-label="Search"
                                    onChange={(e) => this.searchInputHandle(e)}
                                    value={this.state.searchValue}
                                    onKeyUp={(e) => this.searchInputSubmit(e)}
                                />

                                <button className="close mr-2" onClick={() => this.setState({ searchValue: "" })} >
                                    <CloseIcon />
                                </button>

                                <Link to={this.state.searchValue.length > 1 ? `/SearchResults/${this.state.searchValue}` : ""}>
                                    <button className="pl-0 pt-0 position-absolute"><img src="/images/search-icon.svg" alt="Search" /></button>
                                </Link>

                            </div>
                        </div>


                    </div>
                    <div className="col-3 text-right" style={this.props?.loggedInUser?.proStatusId === 1 ? {} : { width: "100px", paddingLeft: "75px" }} ><a href="/Identity/Account/Login" style={{ borderRadius: "30px", padding: "5px 10px" }} className="btn btn-primary">Login</a></div>
                </Fragment>
            );
        }
        
    }

    render() {
        const { isAuthenticated } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = {
                pathname: `${ApplicationPaths.LogOut}`, state: { local: true }
            };
            return this.authenticatedView(this.props.loggedInUser, profilePath, logoutPath);
        }
    }

}

function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer };
    return result;
}

export default connect(mapStateToProps, { toggleFeedLayout, toggleAddProductModal, toggleCreateLockerModal, toggleShareModal, toggleSharePostModal })(withRouter(LoginMenu));