import * as React from "react";
import { Link } from "react-router-dom";
import AccountBanner from "../../components/AccountBanner";
import AnalyticsIcon from "components/icons/AnalyticsIcon";
import { Helmet } from 'react-helmet';

const breadCrumbList = [{ link: '/Account/CreatorDashboard', text: 'Creator Dashboard' }];

const CreatorDashboard = (props) => {

    React.useEffect(() => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }, []);

    const renderDrafts = () => {
        if (props?.loggedInUser?.proStatusId === 1) {
            return (
                <div className="col-6 col-lg-4 mb-4">
                    <Link to="/Account/Drafts" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.247 11.831L21.4981 27.1903C21.0393 27.6681 20.4096 27.9443 19.7473 27.9582L13.5418 28.0884L13.6726 21.8575C13.6861 21.2103 13.9503 20.5936 14.4093 20.1372L29.4936 5.1391C30.4707 4.16756 32.0497 4.16982 33.024 5.14416L36.2115 8.33163C37.1738 9.29397 37.1896 10.8493 36.247 11.831Z" fill="#E55934" />
                            <path opacity="0.3" d="M21.5002 3.33334C22.4206 3.33334 23.1668 4.07954 23.1668 5.00001C23.1668 5.92049 22.4206 6.66668 21.5002 6.66668H10.0002C8.15921 6.66668 6.66683 8.15906 6.66683 10V30C6.66683 31.841 8.15921 33.3333 10.0002 33.3333H30.0002C31.8411 33.3333 33.3335 31.841 33.3335 30V21.6667C33.3335 20.7462 34.0797 20 35.0002 20C35.9206 20 36.6668 20.7462 36.6668 21.6667V30C36.6668 33.6819 33.6821 36.6667 30.0002 36.6667H10.0002C6.31826 36.6667 3.3335 33.6819 3.3335 30V10C3.3335 6.31811 6.31826 3.33334 10.0002 3.33334H21.5002Z" fill="#E55934" />
                        </svg>
                        <h4 className="text-center m-0">Drafts</h4>
                    </Link>
                </div>);
        }
    }
    const renderProducts = () => {
        if (props?.loggedInUser?.proStatusId === 1) {
            return (
                <div className="col-6 col-lg-4 mb-4">
                    <Link to="/Account/Products" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.6235 33.2316L30.3712 18.6101C30.7541 18.0357 30.5989 17.2596 30.0245 16.8766C29.8192 16.7397 29.5779 16.6667 29.3311 16.6667H21.6668V7.46182C21.6668 6.77147 21.1071 6.21182 20.4168 6.21182C19.9988 6.21182 19.6085 6.4207 19.3767 6.76845L9.62902 21.39C9.24607 21.9644 9.40129 22.7405 9.9757 23.1234C10.181 23.2603 10.4223 23.3334 10.6691 23.3334H18.3334V32.5382C18.3334 33.2286 18.8931 33.7882 19.5834 33.7882C20.0014 33.7882 20.3917 33.5793 20.6235 33.2316Z" fill="#E55934" />
                        </svg>
                        <h4 className="text-center m-0">My Products</h4>
                    </Link>
                </div>);
        }
    }
    const renderPartners = () => {
        if (props?.loggedInUser?.proStatusId === 1) {
            return (
                <div className="col-6 col-lg-4 mb-4">
                    <Link to="/Account/Brands" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <svg width="40" height="40" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1446364,11.84388 L17.4471627,16.0287218 C17.4463569,16.0335568 17.4455155,16.0383857 17.4446387,16.0432083 C17.345843,16.5865846 16.8252597,16.9469884 16.2818833,16.8481927 L4.91303792,14.7811299 C4.53842737,14.7130189 4.23500006,14.4380834 4.13039941,14.0719812 L2.30560137,7.68518803 C2.28007524,7.59584656 2.26712532,7.50338343 2.26712532,7.4104669 C2.26712532,6.85818215 2.71484057,6.4104669 3.26712532,6.4104669 L16.9929851,6.4104669 L17.606173,3.78251876 C17.7307772,3.24850086 18.2068633,2.87071314 18.7552257,2.87071314 L20.8200821,2.87071314 C21.4717328,2.87071314 22,3.39898039 22,4.05063106 C22,4.70228173 21.4717328,5.23054898 20.8200821,5.23054898 L19.6915238,5.23054898 L18.1446364,11.84388 Z" fill="#E55934" opacity="0.3"></path>
                            <path d="M6.5,21 C5.67157288,21 5,20.3284271 5,19.5 C5,18.6715729 5.67157288,18 6.5,18 C7.32842712,18 8,18.6715729 8,19.5 C8,20.3284271 7.32842712,21 6.5,21 Z M15.5,21 C14.6715729,21 14,20.3284271 14,19.5 C14,18.6715729 14.6715729,18 15.5,18 C16.3284271,18 17,18.6715729 17,19.5 C17,20.3284271 16.3284271,21 15.5,21 Z" fill="#E55934"></path>
                        </svg>

                        <h4 className="text-center m-0">Merchant Partners</h4>
                    </Link>
                </div>);
        }
    }

    const renderProSettings = () => {
        if (props?.loggedInUser?.proStatusId === 1) {
            return (
                <div className="col-6 col-lg-4 mb-4">
                    <Link to="/Account/ProSettings" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.1666 10L17.1333 7.68333L17.4166 4.61667L14.4083 3.93333L12.8333 1.28333L9.99998 2.5L7.16665 1.28333L5.59165 3.93333L2.58331 4.60833L2.86665 7.675L0.833313 10L2.86665 12.3167L2.58331 15.3917L5.59165 16.075L7.16665 18.725L9.99998 17.5L12.8333 18.7167L14.4083 16.0667L17.4166 15.3833L17.1333 12.3167L19.1666 10ZM8.33331 14.1667L4.99998 10.8333L6.17498 9.65833L8.33331 11.8083L13.825 6.31667L15 7.5L8.33331 14.1667Z" fill="#E55934" />
                        </svg>
                        <h4 className="text-center m-0">Creator Settings</h4>
                    </Link>
                </div>);
        }
    }

    const renderAnalytics = () => {
        if (props?.loggedInUser.proStatusId === 1) {
            return (
                <div className="col-6 col-lg-4 mb-4" >
                    <Link to="/Account/Analytics" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <AnalyticsIcon width={40} height={40} fill="#E55934" />
                        <h4 className="text-center m-0">Analytics</h4>
                    </Link>
                </div>)
        } else if (props?.loggedInUser.proStatusId === 2) {
            return (
                <div className="col-6 col-lg-4 mb-4" >
                    <Link to="/Account/BrandAnalytics" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <AnalyticsIcon width={40} height={40} fill="#E55934" />
                        <h4 className="text-center m-0">Analytics</h4>
                    </Link>
                </div>)
        }
    }

    return (
        <section>
            <Helmet>
                <title>RockPorch - Creator Dashboard</title>
            </Helmet>
            <AccountBanner breadCrumbList={breadCrumbList}>
                <h1 className="mb-4">Dashboard</h1>
                <p className="mb-0">Tools for Creators</p>
            </AccountBanner>

            <section className="gray-bg full-height">
                <div className="container">
                    <div className="row py-5 mt-0">
                        {renderAnalytics()}
                        {renderProducts()}
                        {renderPartners()}
                        {renderProSettings()}
                        {renderDrafts()}
                    </div>
                </div>
            </section>
        </section>);
};

export default CreatorDashboard;
