import React from "react";
import Modal from "components/Modal";
import FollowersFollowingList from "components/FollowersFollowing/FollowersFollowingList";
import CreatorsList from "components/FollowersFollowing/CreatorsList";
import { connect } from "react-redux";
import { toggleFollowersFollowingModal } from "stateManagement/reducers/modalReducer";



/**
 * 
 * @param {{loggedInUser:object, userId:any,followersFollowing:string, setLoggedInUser:Function}} props
 */
const FollowersFollowingModal = (props) => {
    if (props.showModal) {
        document.querySelector("body").style.overflow = "hidden";
    } else {
        document.querySelector("body").style.overflow = "";
    }
    if (props.followersFollowing === "creators") {

        return (
            <Modal isOpen={props.showModal} toggleModal={() => props.toggleModal(null)}>
                <CreatorsList loggedInUser={props.loggedInUser}
                    userId={props.userId}
                    productId={props.productId}
                    followersOrFollowing={props.followersFollowing}
                    setLoggedInUser={props.setLoggedInUser}
                />
            </Modal> 
        )
    }
    return (
        <Modal isOpen={props.showModal} toggleModal={() => props.toggleModal(null)}>
            <FollowersFollowingList loggedInUser={props.loggedInUser}
                userId={props.userId}
                followersOrFollowing={props.followersFollowing}
                setLoggedInUser={props.setLoggedInUser}
            />
        </Modal>

    )
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.followersFollowing.showFollowersFollowing,
        userId: storeState.modalReducer.followersFollowing.userId,
        productId: storeState.modalReducer.followersFollowing.productId,
        followersFollowing: storeState.modalReducer.followersFollowing.followersFollowing
    }
}

const mapDispatch = {
    toggleModal: toggleFollowersFollowingModal
}

export default connect(mapStateToProps, mapDispatch)(FollowersFollowingModal);