import React from "react"

const GridIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3"
                d="M7 4H5C4.44772 4 4 4.44772 4 5V7C4 7.55228 4.44772 8 5 8H7C7.55228 8 8 7.55228 8 7V5C8 4.44772 7.55228 4 7 4Z"
                fill="black" fillOpacity="0.6" />
            <path fillRule="evenodd" clipRule="evenodd"
                d="M5 10H7C7.55228 10 8 10.4477 8 11V13C8 13.5523 7.55228 14 7 14H5C4.44772 14 4 13.5523 4 13V11C4 10.4477 4.44772 10 5 10ZM11 4H13C13.5523 4 14 4.44772 14 5V7C14 7.55228 13.5523 8 13 8H11C10.4477 8 10 7.55228 10 7V5C10 4.44772 10.4477 4 11 4ZM11 10H13C13.5523 10 14 10.4477 14 11V13C14 13.5523 13.5523 14 13 14H11C10.4477 14 10 13.5523 10 13V11C10 10.4477 10.4477 10 11 10ZM17 4H19C19.5523 4 20 4.44772 20 5V7C20 7.55228 19.5523 8 19 8H17C16.4477 8 16 7.55228 16 7V5C16 4.44772 16.4477 4 17 4ZM17 10H19C19.5523 10 20 10.4477 20 11V13C20 13.5523 19.5523 14 19 14H17C16.4477 14 16 13.5523 16 13V11C16 10.4477 16.4477 10 17 10ZM5 16H7C7.55228 16 8 16.4477 8 17V19C8 19.5523 7.55228 20 7 20H5C4.44772 20 4 19.5523 4 19V17C4 16.4477 4.44772 16 5 16ZM11 16H13C13.5523 16 14 16.4477 14 17V19C14 19.5523 13.5523 20 13 20H11C10.4477 20 10 19.5523 10 19V17C10 16.4477 10.4477 16 11 16ZM17 16H19C19.5523 16 20 16.4477 20 17V19C20 19.5523 19.5523 20 19 20H17C16.4477 20 16 19.5523 16 19V17C16 16.4477 16.4477 16 17 16Z"
                fill="black" fillOpacity="0.6" />
        </svg>
    )
}

export default GridIcon;