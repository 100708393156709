import * as React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import { postRequest } from "sharedUtils/httpUtils";
import ActionButton from "components/ActionButton";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Helmet } from 'react-helmet';


class CreatorApplication extends React.Component {

    state = {
        pageNumber: 0,
        spinner: false,
        referralUserIdFlag: true,
        upcomingInput: "",
        showPlatformUserNameMounted: false,
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.formData?.upcomingProjects.length < this.props.formData?.upcomingProjects.length) {
            this.setState({ upcomingInput: "" });
        }
    }

    showPlatformUserName = () => {
        let platforms = [
            { name: "instagram", title: "Instagram Username", prepend: 'www.instagram.com/' },
            { name: "facebook", title: "Facebook Username", prepend: 'www.facebook.com/' },
            { name: "youtube", title: "YouTube Channel", prepend: 'www.youtube.com/' },
            { name: "tiktok", title: "TikTok Username", prepend: 'www.tiktok.com/@' },
            { name: "blog", title: "Blog URL", prepend: 'https://' },
        ];

        if (this.props.formData.platform !== "") {
            let platform = this.props.formData.platform.toLowerCase();
            if (!this.state.showPlatformUserNameMounted && this.props.formData["platformUsername"] === "") {
                this.props.formData["platformUsername"] = !!this.props.formData[platform] ? this.props.formData[platform] : "";
            }
            if (!this.state.showPlatformUserNameMounted) {
                this.setState({ showPlatformUserNameMounted: true });
            }

            let selectedPlatform = platforms.filter((p) => p.name === platform)[0];

            return (
                <FormGroup className="form-group">
                    <Label className="control-label">{selectedPlatform.title}</Label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{selectedPlatform.prepend}</span>
                        </div>
                        <Input
                            className="form-control"
                            name="platformUsername"
                            onChange={(e) => this.props.onInputChange(e)}
                            value={this.props.formData["platformUsername"]}
                        />
                    </div>
                </FormGroup>
            );
        }
    };

    mapInputFields = (fields) => {
        return fields.map((field, i) => {
            return (
                <FormGroup key={i} className="form-group">
                    <Label className="control-label">{field.title}</Label>
                    <Input
                        className="form-control"
                        name={field.name}
                        onChange={(e) => this.props.onInputChange(e)}
                        value={this.props.formData[field.name]}
                    />
                </FormGroup>
            );
        });
    }

    onInputChange = (e) => {
        this.setState({ upcomingInput: e.target.value });
    };

    renderNext = () => {
        const hearAboutUsRendered = this.state.referralUserIdFlag && !(this.props.loggedInUser?.referralUserId);
        const audienceNotUsOrUsPlusState = this.props.formData?.audience !== "United States" || (this.props.formData?.audience === "United States" && !!(this.props.formData?.usState));

        if (this.state.pageNumber === 1 && this.props.formData.mediaPermission !== "") {
            return (<div className="form-group button-block text-md-right mb-0">
                <button className="btn btn-secondary mr-1" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber - 1 }) }}>Back</button>
                <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber + 1 }) }} > Next</button>
            </div>);
        } else if (this.state.pageNumber === 1 && this.props.formData.mediaPermission === "") {
            return (<div className="form-group button-block text-md-right mb-0">
                <button className="btn btn-secondary mr-1" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber - 1 }) }}>Back</button>
                <button className="btn btn-primary" disabled> Next</button>
            </div>);
        } else if (this.props.formData?.realName.trim() === "" || this.props.formData?.phone.trim().length < 12|| this.props.formData?.platformUsername.trim() === "" || this.props.formData?.audience.trim() === "" || !audienceNotUsOrUsPlusState || this.props.formData?.platform.trim() === "" || this.props.formData?.followers.trim() === "" || (hearAboutUsRendered && this.props.formData?.hearAboutUs === "") ) {
            return (<button className="btn btn-primary disabled" onClick={(e) => { e.preventDefault(); }} > Next</button>);
        }
        return (<button className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber + 1 }) }} > Next</button>);
    }

    renderBackApply = () => {
        if (this.props.formData?.learnMore != "" && this.state.upcomingInput.length === 0) {
            return (<div className="form-group button-block text-md-right mb-0">
                <button className="btn btn-secondary mr-1" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber - 1 }) }}>Back</button>
                <button className={`btn btn-primary ${this.state.spinner ? "disabled" : ""}`} onClick={(e) => { e.preventDefault(); this.apply(); }}>
                    <div className="spinner-border spinner-border-sm mr-2" style={{ display: this.state.spinner ? "" : "none" }}>
                        <span className="sr-only">Loading...</span>
                    </div>
                    Apply</button>
            </div>);
        }

        if (!(this.props.formData?.learnMore) || this.state.upcomingInput.length > 0) {
            return (<div className="form-group button-block text-md-right mb-0">
                <button className="btn btn-secondary mr-1" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber - 1 }) }}>Back</button>
                <button className="btn btn-primary" disabled > Apply</button>
            </div>);
        }

        return (<div className="form-group button-block text-md-right mb-0">
            <button className="btn btn-secondary mr-1" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber - 1 }) }}>Back</button>
            <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.setState({ pageNumber: this.state.pageNumber + 1 }); this.apply(); }} > Apply</button>
            </div>);
    }

    apply = async () => {
        this.setState({ spinner: true });

        let formData = this.props.formData;
        if (formData.hearAboutUs === "referred") {
            formData.hearAboutUs = "";
        }

        let userUrl = '/api/Account/ApplyForPro';
        await postRequest(userUrl, formData);

        this.setState({ pageNumber: this.state.pageNumber + 1 })
        this.setState({ spinner: false });
        this.props.history.push('/Nav/Explore');
    }

    renderStates = () => {
        const states = [
            "None", "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
            "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois",
            "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
            "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana",
            "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York",
            "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
            "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
            "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
        ];
        return states.map((state, i) => {
            if (i === 0) {
                return <option key={i} style={{ display: "none" }} />
            }
            return <option key={i} value={state}>{state}</option>
        })
    }

    renderPage0 = () => {
        return (
            <div className="user-detail-form">
                <form>
                    <div className={`detail-block ${this.props.paddingBottom != null && this.props.paddingBottom != undefined ? this.props.paddingBottom : ""} ${this.props.paddingTop != null && this.props.paddingTop != undefined ? this.props.paddingTop : "pt-0"} mb-0`}>
                        {this.props.creatorApp ?
                            <h3 className="pb-4">Tell Us About Yourself</h3> :
                            <h5>Your Info</h5>}
                        <p>We just need to know a few things about you</p>
                        {this.mapInputFields([{ title: "Full Name", name: "realName" }])}
                        <FormGroup className="form-group">
                            <Label className="control-label">Phone Number</Label>
                            <PhoneInput
                                defaultCountry="US"
                                value={this.props.formData["phone"]}
                                onChange={this.props.onInputChangePhone}
                                style={{ height: "38px", maxWidth: "150px" }}
                            />
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Label className="control-label">Primary Audience</Label>
                            <select id="audience"
                                className="form-control pt-0 pb-0"
                                name="audience"
                                onChange={(e) => this.props.onInputChange(e)}
                                value={this.props.formData["audience"]}
                            >
                                <option style={{ display: "none" }} />
                                <option value="United States">United States</option>
                                <option value="Canada">Canada</option>
                                <option value="Mexico">Mexico</option>
                                <option value="European Union">European Union</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="Austrailia and New Zealand">Austrailia and New Zealand</option>
                                <option value="Other">Other</option>
                            </select>
                        </FormGroup>
                        <FormGroup className="form-group px-3" style={{ display: this.props.formData.audience === "United States" ? "" : "none" }}>
                            <Label className="control-label">State</Label>
                            <select id="usState"
                                className="form-control pt-0 pb-0"
                                name="usState"
                                onChange={(e) => this.props.onInputChange(e)}
                                value={this.props.formData["usState"]}
                            >
                                {this.renderStates()}
                            </select>
                        </FormGroup>
                        <FormGroup className="form-group">
                            <Label className="control-label">Primary Social Platform</Label>
                            <select id="platform"
                                className="form-control pt-0 pb-0"
                                name="platform"
                                onChange={(e) => this.props.onInputChange(e)}
                                value={this.props.formData["platform"]}
                            >
                                <option style={{ display: "none" }} />
                                <option value="Instagram">Instagram</option>
                                <option value="Facebook">Facebook</option>
                                <option value="YouTube">YouTube</option>
                                <option value="TikTok">TikTok</option>
                                <option value="Blog">Blog</option>
                            </select>
                        </FormGroup>
                        {this.showPlatformUserName()}
                        {this.mapInputFields([{ title: `${this.props.formData["platform"]} Follower Count `, name: "followers" }])}
                        <FormGroup className="form-group" style={{ display: this.state.referralUserIdFlag && !(this.props.loggedInUser?.referralUserId) ? "" : "none"}}>
                            <Label className="control-label pb-1"> How did you hear about us?</Label>
                            <select
                                id="hearAboutUs"
                                className="form-control pt-0 pb-0"
                                name="hearAboutUs"
                                onChange={(e) => this.handleHearAboutUsChange(e)}
                                value={this.props.formData.hearAboutUs}
                            >
                                <option style={{ display: "none" }} />
                                <option value="outbound">We reached out to you</option>
                                <option value="observed">I saw someone else using RockPorch</option>
                                <option value="organic">I came across RockPorch.com</option>
                                <option value="brand">A brand referred me to RockPorch</option>
                                <option value="social">I came across RockPorch’s Instagram</option>
                            </select>
                        </FormGroup>
                        <div className="text-right">
                            {this.renderNext()}
                        </div>
                    </div>
                </form>
            </div>);
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            this.props.handleUpload(file, this.state.upcomingInput);
        }
    };

    renderPage1 = () => {
        return (
            <div className="user-detail-form">
                <form>
                    <div className={`detail-block ${this.props.paddingBottom != null && this.props.paddingBottom != undefined ? this.props.paddingBottom : ""} ${this.props.paddingTop != null && this.props.paddingTop != undefined ? this.props.paddingTop : "pt-0"} mb-0`}>
                        <h3 className="pb-4">Content Permission</h3>
                        <p>By signing up for the RockPorch platform, you agree that media you load onto RockPorch
                            may appear in promotional posts that showcase the RockPorch website as a whole
                            (such as a scroll through our feed for a reel demonstrating functionality of the platform).
                        </p>
                        <p>
                            We also like to feature Creators from time to time on our website and social media channels, with your explicit permission. 
                        </p>

                        <FormGroup className="form-group">
                            <Label className="control-label">Select One:</Label>
                            <div onChange={(e) => this.props.onInputChange(e)}>
                                <div className="d-flex align-items-start">
                                    <input className="mr-2" style={{ marginTop: "3px" }} checked={this.props.formData.mediaPermission === "yes"} type="radio" id="html" name="mediaPermission" value="yes" />
                                    <label htmlFor="html">You may reuse or repost my social media content (with credit provided of course, we’re trying to promote you!)</label>
                                </div>
                                <div className="d-flex align-items-start">
                                    <input className="mr-2" style={{ marginTop: "3px" }} type="radio" id="html" name="mediaPermission" value="notify" />
                                    <label htmlFor="html">Please notify me before using content outside RockPorch</label>
                                </div>
                                <div className="d-flex align-items-start">
                                    <input className="mr-2" style={{ marginTop: "3px" }} type="radio" id="html" name="mediaPermission" value="no" />
                                    <label htmlFor="html">Do not use my content outside RockPorch</label>
                                </div>
                            </div>
                            
                        </FormGroup>
                        <div className="text-right">
                            {this.renderNext(true)}
                        </div>
                    </div>
                </form>
            </div>);
    }

    renderSavedFileNames() {
        const { formData } = this.props;
        const upcomingProjects = formData?.upcomingProjects;

        if (upcomingProjects) {
            // Split the upcomingProjects string by commas into an array and trim each string
            const savedFileNames = upcomingProjects.split(',').map((fileName) => {
                const index = fileName.indexOf('!@#');
                if (index !== -1) {
                    return fileName.substring(0, index);
                }
                return fileName.trim();
            });

            return (
                <div style={{ marginTop: "10px" }}>
                    <h6>Saved attachments:</h6>
                    <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                        {savedFileNames.map((fileName, index) => {
                            // Use regex to replace "!@#" with spaces and hyphens
                            const formattedFileName = fileName.replace(/!@#/g, ' - ');

                            return (
                                <li key={index}>{formattedFileName}</li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
    }

    renderPage2 = () => {
        const savedFileNames = this.props.savedFileNames;
        if (this.props.creatorApp) {
            return (
                <div className="user-detail-form">
                    <form>
                        <div className={`detail-block ${this.props.paddingBottom != null && this.props.paddingBottom != undefined ? this.props.paddingBottom : ""} ${this.props.paddingTop != null && this.props.paddingTop != undefined ? this.props.paddingTop : "pt-0"} mb-0`}>
                            <h3 className="pb-4">Tell Us About Yourself</h3>
                            <FormGroup className="form-group">
                                <Label className="control-label">Are you interested in learning more about RockPorch Connect (our creator campaign program?)</Label>
                                <div onChange={(e) => this.props.onInputChange(e)}>
                                    <div className="d-flex align-items-start">
                                        <input className="mr-2" style={{ marginTop: "3px" }} type="radio" id="learnMore" name="learnMore" value="yes" />
                                        <label htmlFor="learnMore">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <input className="mr-2" style={{ marginTop: "3px" }} type="radio" id="learnMore" name="learnMore" value="no" />
                                        <label htmlFor="learnMore">No</label>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <Label className="control-label">Tell us about any upcoming projects or trips. You can also attach anything here (e.g., media kit, content calendar, etc.).</Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        className="form-control"
                                        name="upcomingProjects"
                                        onChange={(e) => this.onInputChange(e)}
                                        value={this.state.upcomingInput}
                                        placeholder="Describe your attachment here..."
                                        style={{ marginRight: '10px' }}
                                    />
                                    <input
                                        type="file"
                                        accept=""
                                        onChange={this.handleFileChange}
                                        style={{ display: "none" }}
                                        ref={(input) => (this.fileInput = input)}
                                    />
                                    {this.props.isLoading ?
                                        <button className={`btn btn-primary`} disabled>
                                            <div className={`spinner-border spinner-border-sm`} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            Add
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => this.fileInput.click()}
                                            disabled={this.state.upcomingInput.length === 0}
                                        >
                                            Add
                                        </button>
                                    }
                                </div>
                                <p
                                    className="mb-0 mt-3"
                                    style={{ color: "red", display: this.props.error ? "" : "none" }}
                                >
                                    There was an error uploading the spreadsheet. Try again.
                                </p>
                            </FormGroup>
                            {this.renderSavedFileNames()}
                            <div className="text-right">
                                {this.renderBackApply()}
                            </div>
                        </div>
                    </form>
                </div>);
        }
    }


    handleHearAboutUsChange = async e => {
        await this.props.onInputChange(e);
    };


    renderPage = () => {
        if (this.state.pageNumber === 0) {
            return this.renderPage0();
        } else if (this.state.pageNumber === 1) {
            return this.renderPage1();
        } 
        return this.renderPage2();
    }

    render = () => {
        return (
            <div className="col-md-12 col-lg-12">
                <Helmet>
                    <title>RockPorch - Apply as Creator</title>
                </Helmet>
                {this.renderPage()}
            </div>
        )
    }
}

export default CreatorApplication;