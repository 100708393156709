import React, { Component } from 'react';
import BrandNavMenu from './BrandNavMenu';
import NavMenu from './NavMenu';
import { connect } from "react-redux";
import { toggle } from "../stateManagement/reducers/modalReducer";
import { setBrandNavState } from "../stateManagement/reducers/brandNavReducer";
import AddProductModal from "views/Product/AddProductModal";
import CreateLockerModal from "components/CreateLocker/CreateLockerModal";
import FollowersFollowingModal from "components/FollowersFollowing/FollowersFollowingModal";
import PostPageModal from "components/PostPageModal/PostPageModal";
import BlogPageModal from "components/BlogPageModal/BlogPageModal";
import LockerPageModal from "components/LockerPageModal/LockerPageModal";
import ProductPageModal from "components/ProductPageModal/ProductPageModal";
import ShareABlogModal from "components/ShareABlog/ShareABlogModal";
import ShareDeepLinkModal from "components/ShareDeepLink/ShareDeepLinkModal";
import AddProductOrDeepLinkModal from "components/AddProductOrDeepLink/AddProductOrDeepLinkModal";
import ShareModal from "components/Share/ShareModal";
import SignUpModal from "components/SignUpModal";
import { Coordinator } from 'components/Post/Create/Coordinator';
import { toggleSharePostModal } from "stateManagement/reducers/modalReducer";
import { getRequest } from "sharedUtils/httpUtils";

import AddToHomeScreenIOS from './AddToHomescreenIOS';

class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    toggleShowModal = () => {
        let newModal = !this.state.showModal;
        this.setState({ showModal: newModal })
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.brandNav !== this.props.brandNav) {
            await this.props.setBrandNavState(this.props.brandNav);
        }
    }

    render() {
        return (

            //<div style={pageStyle}>
            <div>

                {this.props.brandNav ? 
                    <BrandNavMenu
                        setShowTopNav={this.props.setShowTopNav}
                        loggedInUser={this.props.loggedInUser}
                        setLoggedInUser={this.props.setLoggedInUser}
                        showTopNav={this.props.showTopNav}
                        deviceSize={this.props.deviceSize || "md"}
                        toggleSignUpModal={this.props.toggleSignUpModal}
                        hamburgerOpen={this.props.hamburgerOpen}
                        toggleMenu={this.props.toggleMenu}
                        embed={this.props.embed}
                        screenWidth={this.props.screenWidth}
                        searchInput={this.props.searchInput}
                        populateSearch={this.props.populateSearch}
                        browseFilterOpen={this.props.browseFilterOpen}
                        resetBrowseSearch={this.props.resetBrowseSearch}
                        handleResetBrowseSearch={this.props.handleResetBrowseSearch}
                        resetSearchBar={this.props.resetSearchBar}
                        handleResetSearchBar={this.props.handleResetSearchBar}
                        mobileSearchOpen={this.props.mobileSearchOpen}
                        handleMobileSearchOpen={this.props.handleMobileSearchOpen}
                        categories={this.props.categories}
                    />
                    :
                    <NavMenu
                        setShowTopNav={this.props.setShowTopNav}
                        loggedInUser={this.props.loggedInUser}
                        setLoggedInUser={this.props.setLoggedInUser}
                        showTopNav={this.props.showTopNav}
                        deviceSize={this.props.deviceSize || "md"}
                        toggleSignUpModal={this.props.toggleSignUpModal}
                        hamburgerOpen={this.props.hamburgerOpen}
                        toggleMenu={this.props.toggleMenu}
                        embed={this.props.embed}
                    />
                }

            <AddProductModal loggedInUser={this.props.loggedInUser} deviceSize={this.props.deviceSize} isModal={true} toggleShareModal={this.toggleShareModal} toggleSignUpModal={this.props.toggleSignUpModal}/>
            <CreateLockerModal loggedInUser={this.props.loggedInUser}  />
            <PostPageModal deviceSize={this.props.deviceSize} loggedInUser={this.props.loggedInUser} toggleSignUpModal={this.props.toggleSignUpModal} />
            <BlogPageModal deviceSize={this.props.deviceSize} loggedInUser={this.props.loggedInUser} toggleSignUpModal={this.props.toggleSignUpModal} />
            <LockerPageModal deviceSize={this.props.deviceSize} loggedInUser={this.props.loggedInUser} toggleSignUpModal={this.props.toggleSignUpModal} />
            <FollowersFollowingModal loggedInUser={this.props.loggedInUser} setLoggedInUser={this.props.setLoggedInUser} />
            <ShareABlogModal loggedInUser={this.props.loggedInUser} setLoggedInUser={this.props.setLoggedInUser} deviceSize={this.props.deviceSize} />
            <ShareDeepLinkModal loggedInUser={this.props.loggedInUser} setLoggedInUser={this.props.setLoggedInUser} deviceSize={this.props.deviceSize} />
            <AddProductOrDeepLinkModal reloadCreatorDashboardAnalyticsData={this.props.reloadCreatorDashboardAnalyticsData} loggedInUser={this.props.loggedInUser} setLoggedInUser={this.props.setLoggedInUser} deviceSize={this.props.deviceSize} />
            <ShareModal loggedInUser={this.props.loggedInUser} />
            <Coordinator showModal={this.props.modalState.showSharePostModal} onModalClosed={this.props.toggleSharePostModal} loggedInUser={this.props.loggedInUser} deviceSize={this.props.deviceSize} />
	        {/*
                (this.props.modalState.showSharePostModal === true)
                    ? <Coordinator showModal={true} onModalClosed={this.props.toggleSharePostModal} loggedInUser={this.props.loggedInUser} deviceSize={this.props.deviceSize}/>
	                : <></>
	        */}
	        {this.props.children}
                <ProductPageModal loggedInUser={this.props.loggedInUser} brandNav={this.props.brandNav} screenWidth={this.props.screenWidth} deviceSize={this.props.deviceSize} toggleShareModal={this.props.toggleShareModal} toggleSignUpModal={this.props.toggleSignUpModal}/>

            <SignUpModal showModal={this.props.showSignUpModal} toggleSignUpModal={this.props.toggleSignUpModal} />
            
            </div>
            
    )}
}

function mapStateToProps(storeState, ownProps) {
    return { modalState: storeState.modalReducer, brandNavState: storeState.brandNavReducer.brandNavState }
}


export default connect(mapStateToProps, { toggleModal: toggle, toggleSharePostModal: toggleSharePostModal, setBrandNavState })(Layout);
