import React from "react";
import { Link } from "react-router-dom";

class ErrorPage extends React.Component {
    render() {
        return (
            
            <div className="container error-container">
                <img src="images/ErrorMonochrome.jpg" className="error-bg mt-3" />
                
                <div className="error-text-container">
                    <h6>Oops</h6>
                    <h6>It looks like something's broken. We'll fix it asap. For now, </h6>
                    <h6>
                        Go <Link to="/HomeFeed">
                            <button className="btn btn-primary btn-sm px-1 py-0">
                                home
                            </button>
                        </Link>
                    </h6>
                </div>
            </div>
            )
    }
}


export default ErrorPage;