import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import ProductDetailSection from "../../components/ProductDetailSection";
import ProfileInfoCard from "../../components/ProfileInfoCard";

var jsonref = require('jsonref');

let blankProductData = {
    rating: 0,
    ratingCount: 0,
    description: "",
    referalLink: {},
    manufacturer: "",
    name: "",
    price: 0,
    likes: 0,
    imageUrl: "",

    user: {
        name: "",
        username: "",
        imageUrl: "",
        tagLine: "",
        bio: ""
    }
}

const ProductModal = () => {
    const [showModal, toggleModal] = useState(false);
    const [productData, setProductData] = useState(blankProductData);

    let resetModal = () => {
        toggleModal(!showModal);
    }

    useEffect(() => {
        getData();
    })

    let getData = async () => {
        const response = await fetch("/Products/Get?userMerchantProductId=8&userId=1");
        let data = await response.json();

        data = await jsonref.parse(data, { scope: window.location });
        setProductData(data);
    }

    return (
        <>
            <button onClick={resetModal}>Show Modal</button>
            <Modal isOpen={showModal} toggleModal={resetModal} modalUseCase="product" showHeader={true} modalBodyCustomClass="modal-body pt-0 px-0 mt-0 pb-1">
                <ProductDetailSection
                    likes={productData.likes}
                    name={productData.name}
                    description={productData.description}
                    manufacturer={productData.manufacturer}
                    price={productData.price}
                    imageUrl={productData.imageUrl}
                    ratingCount={productData.ratingCount}
                    rating={productData.rating}
                />
                <div className="container user-profile py-5">
                    <div className="row">
                        <ProfileInfoCard user={productData.user}
                            followAction={() => alert("clicked follow")}
                            messageAction={() => alert("clicked message")} />

                        <div className="col-md-8 px-md-5">
                            <p>{productData.user.bio.split(/['\r\n'|'\n'|'\r']/).map(function (item, key) {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                )
                            })}</p>
                            <a href="/" className="read-more">Read more</a>
                        </div>

                    </div>

                </div>
            </Modal>
        </>

    )
}

export default ProductModal;