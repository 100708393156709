import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import ActionButton from "../../components/ActionButton"

import { getRequest } from "sharedUtils/httpUtils";

class ProductFeeds extends Component {

    constructor(props) {
        super(props)

        this.state = {
            productFeeds: [],
            productFeedsLoading: false,
            productFeedsTable: {
                title: "Product Feeds",
                columns: [
                    {
                        name: "merchantName",
                        label: "Name",
                        options: {
                            filter: true,
                            sort: true,
                            }
                        
                    },
                    {
                        name: "apmName",
                        label: "Affiliate",
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "internalId",
                        label: "Internal ID",
                        options: {
                            filter: true,
                            sort: true,
                            display: false
                        }
                    },
                    {
                        name: "feedLastProcessed",
                        label: "Last Processed",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const date = new Date(value);
                                return new Date(date).toLocaleString();
                            }
                        }
                    },
                    {
                        name: "refreshStatus",
                        label: "Refresh Status",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "dateLastRefreshed",
                        label: "Last Refreshed",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const date = new Date(value);
                                return new Date(date).toLocaleString();                            }
                        }
                    },
                    {
                        name: "modifiedDate",
                        label: "Last Modified",
                        options: {
                            filter: true,
                            sort: true,
                            display: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const date = new Date(value);
                                return new Date(date).toLocaleString();                            }
                        }
                    },
                    {
                        name: "legacyButton",
                        label: "Mark as Legacy",
                        options: {
                            display: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const apmId = tableMeta.rowData[8];
                                const merchantId = tableMeta.rowData[9];
     
                                return (
                                    <ActionButton className={`btn btn-primary brands-button`}
                                        onClick={(e) => { this.updateLegacyStatus(e, apmId, merchantId) }}
                                    >
                                        Legacy
                                    </ActionButton>
                                )
                            }
                        }
                    },
                    {
                        name: "apmId",
                        label: "Affiliate Program Manager ID",
                        options: {
                            filter: true,
                            sort: true,
                            display: false
                        }
                    },
                    {
                        name: "merchantId",
                        label: "Merchant Id",
                        options: {
                            filter: true,
                            sort: true,
                            display: false
                        }
                    },
                ],
                options: { selectableRows: false },
               
                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTable: {
                            root: {
                            },
                            paper: {
                            },
                            ResponseScroll: {
                                maxWidth: "100%"
                            }
                        },
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                        MUIDataTableBodyCell: {
                            root: {
                            }
                        }
                    }
                })
            },
        }
    }

    componentDidMount = async () => {
        const productFeeds = await getRequest('api/Merchant/GetProductFeeds') ?? [];
        this.setState({ productFeeds });
    }

    updateLegacyStatus = async (e, apmId, merchantId) => {
        const url = `api/Merchant/UpdateLegacyStatus?apmId=${apmId}&merchantId=${merchantId}`;
        await getRequest(url);

        const copyProductFeeds = [...this.state.productFeeds];
        const productFeeds = copyProductFeeds.map(feed => {
            if (feed.apmId === apmId && feed.merchantId === merchantId) {
                return { ...feed, refreshStatus: "Legacy" };
            }
            else {
                return { ...feed };
            }
        });
        this.setState({ productFeeds });
    }

    render() {
        return (
            <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>
                {
                    this.state.productFeedsLoading ?
                        <LoadSpinnerOverlay />
                        : <></>
                }

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.productFeedsTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.productFeedsTable.title}
                                        data={this.state.productFeeds}
                                        columns={this.state.productFeedsTable.columns}
                                        options={this.state.productFeedsTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ProductFeeds;