import React, { useState, useLayoutEffect } from "react";
import LoadSpinner from "components/LoadSpinner";
import parse from "html-react-parser";
import YoutubeIcon from "components/icons/YoutubeIcon";
/**
 * 
 * @param {{src:string, alt:string, style:object, className:string, role:Number, onClick:Function, videoWidth:Number}} props
 */
const UserImageWrapper = (props) => {

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    useLayoutEffect(() => {

        return () => {
            let video = document.getElementById(genId);

            if (video) {
                video.pause();
                video.currentTime = 0;
            }
        }
    }, []);

    let [imageLoaded, setImageLoaded] = useState(false);
    let [genId, setGenId] = useState(uuidv4());

    let emptyClick = (e) => e.preventDefault

    if (props.role && props.role === 1) {
        return (
            <video
                id={genId}
                onClick={(e) => {
                    e.preventDefault();
                    if (props.onClick === undefined) {
                        emptyClick(e)
                    } else {
                        document.getElementById(genId).pause();
                        props.onClick()
                    }
                }}
                className={props.className}
                poster={props.poster}
                preload="metadata"
                controls
                controlsList="nodownload"
                disablePictureInPicture
            >
                <source src={props.src.split('#')[0] + "#t=0.001"} />
            </video>
        )
    }
    else if (props.role === 3) {
        let paddingTop = 9 / 16 * 100 + '%';

        if (!!props.width && !!props.height) {
            if (props.width < props.height) {
                paddingTop = props.width / props.height * 100 + '%';
            } else {
                paddingTop = props.height / props.width * 100 + '%';
            }
        }


        return (
            <div style={{ textAlign: "center", overflow: "hidden", paddingTop: `${paddingTop}`, position: "relative" }}>
                <iframe id={'player' + genId} src={props.src} style={{ border: "none", left: 0, top: 0, position: "absolute" }} height="100%" width="100%">
                </iframe>
            </div>
        )
    } else {
        let overlayStyle = { position: "absolute", width: "100%", height: "100%", zIndex: 3, padding: "47%", cursor: "pointer" };
        if (props.lazy != null && props.lazy === false) {
            return (
                <img src={props.src}
                    onError={(e) => {
                        e.target.onError = null
                        e.target.src = "images/blank-banner.png"
                    }}
                    onLoad={(e) => {
                        setImageLoaded(true)
                    }}
                    alt={props.alt || ""}
                    style={props.style || {}}
                    className={props.className || ""}
                    onClick={props.onClick || emptyClick}
                />
            )
        }
        return (
            <>
                {props.role === 8 ? <div style={overlayStyle} onClick={props.onClick || emptyClick}><YoutubeIcon /></div> : null}
                <img src={props.src}
                    onError={(e) => {
                        e.target.onError = null
                        e.target.src = "images/blank-banner.png"
                    }}
                    onLoad={(e) => {
                        setImageLoaded(true)
                    }}
                    alt={props.alt || ""}
                    style={props.style || {}}
                    className={props.className || ""}
                    onClick={props.onClick || emptyClick}
                    loading={props.lazy != null && props.lazy == false ? "" : "lazy"}
                />
            </>
        )
    }

}


export default UserImageWrapper;