import React from "react";
import { withRouter } from 'react-router-dom';
import { getRequest } from "sharedUtils/httpUtils";
import InstagramIcon from "components/icons/InstagramIcon";
import FacebookIcon from "components/icons/FacebookIconNoLink";
import TikTokIcon from "components/icons/TikTokIcon";
import StravaIcon from "components/icons/StravaIcon";
import YoutubeIcon from "components/icons/YoutubeIcon";
import LoadSpinner from "components/LoadSpinner";

/**
 * 
 * @param {{userInformation:object}} props
 */
const ProfileAboutSection = (props) => {

    React.useEffect(() => { getSectors() }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const [sectors, setSectors] = React.useState([]);
    const [fetchingSectors, setFetchingSectors] = React.useState(true);

    const { website } = props.userInformation;

    const getSectors = async () => {
        let sectors = await getRequest('/api/Sector/GetSectors') ?? [];
        let userSectors = await getRequest(`/api/Sector/GetSectorsByUser?userid=${props.userInformation.id}`) ?? [];
        let activeSectors = [];

        for (var i = 0; i < userSectors.length; ++i) {
            for (var j = 0; j < sectors.length; ++j) {
                if (userSectors[i].sectorId === sectors[j].sectorId) {
                    activeSectors.push(sectors[j]);
                    break;
                }
            }
        }

        setSectors(activeSectors);
        setFetchingSectors(false);
    }

    let renderSectors = () => {
        if (fetchingSectors === true) {
            return <LoadSpinner/>
        }
        else {
            return sectors.map((sector, i) => {
                return <button key={i} className="btn d-flex align-items-center border" disabled>{sector.name}</button>
            });
        }
        
    }

    function displaySocials() {
        let { userInformation } = props;
        let { instagramUsername } = userInformation
        let normalizedInstagram = userInformation?.instagramUsername?.startsWith("@") === true ? instagramUsername.substring(1) : instagramUsername
        let socials = [
            {
                text: "Facebook Name",
                name: userInformation?.facebookUsername || null,
                icon: <FacebookIcon />,
                url: `facebook.com/${userInformation.facebookUsername}`,
                userInformationField:"facebookUsername"
            },
            {
                text: "Instagram Name",
                name: userInformation.instagramUsername || null,
                icon: <InstagramIcon fill="#000000" />,
                url: `instagram.com/${normalizedInstagram}`,
                userInformationField: "instagramUsername"
            },
            {
                text: "Youtube Name",
                name: userInformation?.googleUsername || null,
                icon: <YoutubeIcon />,
                url: `youtube.com/${userInformation.googleUsername}`,
                userInformationField:"googleUsername"
            },
            {
                text: "TikTok Name",
                name: userInformation?.tikTokUsername || null,
                icon: <TikTokIcon />,
                url: `tiktok.com/@${userInformation.tikTokUsername}`,
                userInformationField: "tikTokUsername"
            },
            {
                text: "Strava Name",
                name: userInformation?.stravaUsername || null,
                icon: <StravaIcon />,
                url: `strava.com/athletes/${userInformation.stravaUsername}`,
                userInformationField: "stravaUsername"
            },
        ];

        return socials.map((social, i) => {
            if (userInformation[social.userInformationField] === undefined || userInformation[social.userInformationField].length === 0 || userInformation[social.userInformationField] === null) {
                return <span key={ i}></span>
            }
            else {
                return (
                    <div className="col-lg-4">
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>{social.text}</span>
                        </div>
                        <a key={i} href={"https://www." + social.url} className="" target="_blank">
                            <p className="" style={{ display: "inline" }}>{social.url}</p>
                        </a>
                    </div>
                )

            }
            
        })
    }

    return (
        <div className="row mx-0 pt-0" style={{ fontFamily: "'Archivo', sans-serif" }}>
            <div className="col-lg-12">
                <div className={`row ${["sm","md"].includes(props.deviceSize) ? "" : "mb-3"}`}>
                    <div className={`col-lg-4 ${["sm","md"].includes(props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Name</span>
                        </div>
                        <p className="" style={{ display: "inline" }}>{ props.userInformation.name}</p>
                    </div>
                    <div className={`col-lg-4 ${["sm","md"].includes(props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Email</span>
                        </div>
                        <p className="" style={{ display: "inline" }}>{props.userInformation.email}</p>
                    </div>
                    <div className={`col-lg-4 ${["sm","md"].includes(props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Phone Number</span>
                        </div>
                        <p className="" style={{ display: "inline" }}>{props.userInformation.phoneNumber}</p>
                    </div>
                </div>

                <div className={`row ${["sm","md"].includes(props.deviceSize) ? "mt-0" : "mb-3"}`}>
                    <div className={`col-lg-4 ${["sm","md"].includes(props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Display Name</span>
                        </div>
                        <p className="" style={{ display: "inline" }}>{props.userInformation.userName}</p>
                    </div>
                    <div className={`col-lg-4 ${["sm","md"].includes(props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Personal Website</span>
                        </div>
                        <a href={"https://" + props.userInformation.website} className="" target="_blank">
                            <p className="" style={{ display: "inline" }}>{"https://" + props.userInformation.website}</p>
                        </a>
                    </div>
                </div>

                {props.userInformation?.tagLine == undefined || props.userInformation?.tagLine == null || props.userInformation?.tagLine === "" ?
                    <></> :
                    <div className="mb-3">
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Tagline</span>
                        </div>
                        <p className="" style={{ display: "inline" }}>{props.userInformation.tagLine}</p>
                    </div>
                }
                {props.userInformation?.bio == undefined || props.userInformation?.bio == null || props.userInformation?.bio === "" ?
                    <></> :
                    <div className="mb-3">
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Bio</span>
                        </div>
                        <p className="" style={{ display: "inline" }}>{props.userInformation.bio}</p>
                    </div>
                }

                {props.userInformation?.sectors == undefined || props.userInformation?.sectors == null || props.userInformation?.sectors == {} ?
                    <></> :
                    <div className="mb-3">
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Interests</span>
                        </div>
                        <div className="tag-content-sm d-flex flex-wrap py-0">
                            {renderSectors()}
                        </div>
                    </div>
                }
                <div className="row" style={{ marginTop: props.deviceSize === "sm" ? "" : "50px" }}>
                        {displaySocials()}
                    </div>
            </div>
        </div>



    )
}


export default withRouter(ProfileAboutSection);
