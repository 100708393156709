

/**
 * @description: Accepts an array of products that come in as part of the result of an api call to get posts. It then trims them and returns a leaner object. productItems found in `post.productItems.$values`
 * @param {[{
 *  id:Number, 
 *  ordinal:Number, 
 *  type:Number, 
 *  userMerchantProduct:{
 *  id:Number,
 *  coupon:string,
 *  product:{
 *      id:Number,
 *      description:string,
 *      imageUrl:string,
 *      price:number,
 *      merchant:{},
 *      product:{}
 *  }
 *  }
 * }]} productItems 
 * @returns {[{id:Number,coupon:string,imageUrl:string,name:string,type:Number}]} An array of trimmed product objects containing the id,type,coupon code, imageUrl and product name.
 */
function trimProductsArray(productItems) {

    let leanItems = productItems?.map((item, i) => {
        return {
            description: item.userMerchantProduct.product.description,
            imageUrl: item.userMerchantProduct.product.imageUrl,
            merchantProductId: item.userMerchantProduct.product.id,
            name: item.userMerchantProduct.product.name,
            price: item.userMerchantProduct.product.price,
            originalPrice: item.userMerchantProduct.product.originalPrice,
            salePrice: item.userMerchantProduct.product.salePrice,
            sale: item.userMerchantProduct.product.sale,
            shareCode: item.userMerchantProduct.coupon,
            approvedCreatorsOnly: item.userMerchantProduct.product.approvedCreatorsOnly,
            url: item.userMerchantProduct.product.url,
            user: item.userMerchantProduct.user,
            comment: item.comment,
            userDescription: item.userMerchantProduct.userDescription,
            userMerchantProductId: item.userMerchantProduct.id,
            manufacturer: item.userMerchantProduct.product.product.manufacturer.name,
            merchant: item.userMerchantProduct.product.merchant.name,
            integrated: item.userMerchantProduct.product.merchant.integrated,
            ordinal: item.ordinal,
            id: item.id,
            creatorCount: item.userMerchantProduct.product.creatorCount,
            productId: item.userMerchantProduct.product.product.id,
        };
    })
    return leanItems;
}

/**
 * @description Traverses Properties object from posts, cleans it up, and returns an object with the id, role and reference. The reference is a media item
 * @param {{$type:string,$values:[{$id:string,reference:string,id:Number,role:number}]}} properties Available in `post.properties`
 * @returns {[{id:Number, role:any , reference:string}]} filtered properties
 */
function getPropertiesFromPost(properties) {
    let postProperties = properties?.$values?.map((property, i) => {
        return { id: property.id, role: property.role, value: property.value, width: property.width, height: property.height, title: property.userPost?.title }
    })

    return postProperties
}

function getThumbnail(properties, id) {
    let thumbnail = properties?.$values && properties?.$values.filter(property => property.parentId === id);
    if (thumbnail?.length > 0) {
        return thumbnail[0].value;
    }
    return "";
}

module.exports = {
    trimProductsArray,
    getProperties: getPropertiesFromPost,
    getThumbnail
}

