import React from "react";
import { getRequest } from "../../../sharedUtils/httpUtils";
import CameraIcon from "components/icons/CameraIcon";

class CreateNativePost extends React.Component {
    /**
     * 
     * @param {{
     * selectedSectors:Array,
     * setNativePostMedia:Function,
     * setNativePostMediaUrls:Function,
     * setNativePostCaption:Function,
     * nativePostCaption:string,
     * addSector:Function,
     * removeSector:Function
     * }} props
     */
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            selectedFilesRaw: [],
            selectedFilesUrls: [],
            currentDisplayImageIndex: 0,
            sectors: [],
            sectorFilterInput: ""
        }
    }

    componentDidMount = async () => {
        let sectors = await this.fetchSectors();
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        this.setState({ sectors })
    }

    activateFileInput = () => {
        document.getElementById("uploadPostMedia").click();
    }

    displaySectors = () => {
        return this.state.sectors.map((sector, i) => {
            let sectorIsSelected = this.props.selectedSectors.findIndex(sec => sec.sectorId === sector.sectorId);
            let selectedClass = sectorIsSelected >= 0 ? "active" : "";
            if (sector.name.toLowerCase().includes(this.state.sectorFilterInput.toLowerCase())) {
                return (
                    <button className={`btn d-flex align-items-center ${selectedClass}`}
                        onClick={(e) => {
                            if (sectorIsSelected >= 0) {
                                this.props.removeSector(sectorIsSelected);
                            }
                            else {
                                this.props.addSector(sector);
                            }
                        }}
                        key={i}>
                        {sector.name}
                    </button>
                )
            }


        })
    }

    displayBanner = () => {
            return (
                <React.Fragment>
                    <div className="bg-white w-100" style={{height:`${this.props.deviceSize === "sm" ? "350px" : "400px"}`,border:"2px dashed blue"}}>
                    </div>
                    <button className="btn btn-secondary"
                        onClick={this.activateFileInput}
                        style={{ position: "absolute", right: "10px", bottom: "10px" }}>          
                        <CameraIcon />
                    </button>
                </React.Fragment>
                )
    }

    fetchSectors = async () => {
        let url = `api/Sector/GetSectors`;
        let sectors = await getRequest(url);
        if (sectors === undefined) {
            return [];
        }
        else {
            return sectors;
        }
    }

    handleInput = (e) => this.props.setNativePostCaption(e.target.value)

    selectMediaToUpload = (e) => {
        e.preventDefault();

        let urls = [];
        let files = [];

        for (let i = 0; i < e.target.files.length; i++) {
            let urlDetails = {
                role: e.target.files[i].type.includes("image/") ? 0 : 1,
                url: URL.createObjectURL(e.target.files[i])
            }
            urls.push(urlDetails);
            files.push(e.target.files[i]);
        }

        this.props.setNativePostMedia(files);
        this.props.setNativePostMediaUrls(urls);

        this.setState({ selectedFilesRaw: files, selectedFilesUrls: urls });
        e.target.files = null;
        e.target.value = null;
    }

    render() {
        let whiteBg = this.state.selectedFilesUrls.length > 0 ? "bg-white" : ""
        return (
            <div className={`position-relative ${whiteBg}`}>

                {this.displayBanner()}

                <input type="file"
                    name="files[]"
                    id="uploadPostMedia"
                    style={{ display: "none" }}
                    accept="image/*, video/*"
                    onChange={this.selectMediaToUpload}
                    multiple
                />

            </div>
        )
    }
}

export default CreateNativePost;