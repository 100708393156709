import * as React from "react";
import { Link } from "react-router-dom";
import AccountBanner from "../../components/AccountBanner";
import AnalyticsIcon from "components/icons/AnalyticsIcon";
import { Helmet } from 'react-helmet';
import LightningIcon from "components/icons/LightningIcon";

const breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }];

const AccountIndex = (props) => {

    React.useEffect(() => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }, []);

    const renderGoPro = () => {
        if (props?.loggedInUser?.proStatusId === 0) {
            return (
                <div className="col-6 col-lg-4 mb-4">
                    <Link to="/Account/CreatorApp" className="card-block d-flex align-items-center justify-content-center flex-column">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20 30L13.1927 33.5788C12.3779 34.0071 11.3702 33.6939 10.9419 32.8792C10.7713 32.5547 10.7125 32.1831 10.7744 31.8218L12.0745 24.2418L6.56728 18.8736C5.90814 18.2311 5.89465 17.1759 6.53716 16.5168C6.793 16.2543 7.12824 16.0835 7.49097 16.0308L15.1018 14.9249L18.5054 8.02831C18.9128 7.20288 19.9122 6.86398 20.7376 7.27136C21.0663 7.43357 21.3323 7.69962 21.4945 8.02831L24.8982 14.9249L32.509 16.0308C33.4199 16.1631 34.051 17.0089 33.9187 17.9198C33.866 18.2825 33.6951 18.6177 33.4327 18.8736L27.9254 24.2418L29.2255 31.8218C29.3811 32.7291 28.7718 33.5907 27.8646 33.7463C27.5033 33.8082 27.1317 33.7494 26.8073 33.5788L20 30Z" fill="#E55934" />
                        </svg>
                        <h4 className="text-center m-0">Apply as Creator</h4>
                    </Link>
                </div>);
        }
    }

    return (
        <section>
            <Helmet>
                <title>RockPorch - Account Dashboard</title>
            </Helmet>
        <AccountBanner breadCrumbList={breadCrumbList}>
            <h1 className="mb-4">Account</h1>
            <p className="mb-0">Manage account and stay connected</p>
        </AccountBanner>

        <section className="gray-bg full-height">
            <div className="container">
                <div className="row py-5 mt-0">

                    <div className="col-6 col-lg-4 mb-4">
                        <Link to="/Account/AccountSettings" className="card-block d-flex align-items-center justify-content-center flex-column">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.33339 14.4772V8.33335H14.4772L19.3097 3.50085L24.1422 8.33335H31.6667V15.8579L35.8089 20L31.6667 24.1421V31.6667H24.1422L19.3097 36.4992L14.4772 31.6667H8.33339V25.5229L2.81055 20L8.33339 14.4772ZM20.0001 25C22.7615 25 25.0001 22.7614 25.0001 20C25.0001 17.2386 22.7615 15 20.0001 15C17.2386 15 15.0001 17.2386 15.0001 20C15.0001 22.7614 17.2386 25 20.0001 25Z" fill="#E55934" />
                            </svg>
                            <h4 className="text-center m-0">Account Settings</h4>
                        </Link>
                    </div>
                        {renderGoPro()}

                    <div className="col-6 col-lg-4 mb-4">
                        <Link to="/Account/Saves" className="card-block d-flex align-items-center justify-content-center flex-column">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 6.66667H26.6667C28.5076 6.66667 30 8.15906 30 10V29.5446C30 30.4651 29.2538 31.2113 28.3333 31.2113C27.9163 31.2113 27.5145 31.055 27.2071 30.7732L21.1262 25.199C20.489 24.6149 19.511 24.6149 18.8738 25.199L12.7929 30.7732C12.1143 31.3952 11.0601 31.3493 10.4381 30.6708C10.1563 30.3634 10 29.9616 10 29.5446V10C10 8.15906 11.4924 6.66667 13.3333 6.66667Z" fill="#E55934"/>
                            </svg>
                            <h4 className="text-center m-0">My Saves</h4>
                        </Link>
                    </div>

                    <div className="col-6 col-lg-4 mb-4">
                        <Link to="/Account/Interests" className="card-block d-flex align-items-center justify-content-center flex-column">
                            <svg width="40" height="40" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <title>Icon For Flag</title>
                                <path d="M3.5,3 L5,3 L5,19.5 C5,20.3284271 4.32842712,21 3.5,21 L3.5,21 C2.67157288,21 2,20.3284271 2,19.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z" fill="#E55934"></path>
                                <path d="M6.99987583,2.99995344 L19.754647,2.99999303 C20.3069317,2.99999474 20.7546456,3.44771138 20.7546439,3.99999613 C20.7546431,4.24703684 20.6631995,4.48533385 20.497938,4.66895776 L17.5,8 L20.4979317,11.3310353 C20.8673908,11.7415453 20.8341123,12.3738351 20.4236023,12.7432941 C20.2399776,12.9085564 20.0016794,13 19.7546376,13 L6.99987583,13 L6.99987583,2.99995344 Z" fill="#E55934" opacity="0.3"></path>
                            </svg>
                            <h4 className="text-center m-0">Interests</h4>
                        </Link>
                        </div>


                </div>
            </div>
        </section>
    </section>);
};

export default AccountIndex;
