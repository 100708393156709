import React from "react";
//import Carousel from "../components/Carousel";
/**
 * 
 * @param {{images:[]}} props
 */
const PostAuthorBanner = (props) => {
    let renderCarousel = () => {
        if (props.images && props.images.length && props.images.length > 0) {
            return (
                <div className="text-center">
                    <img src={props.images[0]} className="img-fluid" alt="Responsive" />
                </div>
            )
        }
        else {
            return (
                <div className="login-top-banner" style={{ backgroundColor: "grey" }}>
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0">
                                        {/*render breadCrumblist here */}
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        {props.children}
                    </div>
                </div>

            )
        }
    }
    return (
        <>
            {renderCarousel()}
        </>
    )
}

export default PostAuthorBanner;