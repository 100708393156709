import React from "react";
import AccountBanner from "./AccountBanner"
import { Helmet } from 'react-helmet';

class Contact extends React.Component {

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }


    render() {
        return (
            <div>
                <Helmet>
                    <title>RockPorch - Contact</title>
                </Helmet>
                <section>
                    <AccountBanner breadCrumbList={[]}>
                        <h1 className="mb-4">Contact</h1>
                        <p className="mb-0">Need to get in touch with us?</p>
                    </AccountBanner>
                </section>

                <div className="container py-2">
                    <div className="col-12 p-0">
                        <h4>General inquiries</h4>
                        <a href="mailto:info@rockporch.com" target="blank_">info@rockporch.com</a>
                    </div>
                </div>
                <div className="container py-2">
                    <div className="col-12 p-0">
                        <h4>General help</h4>
                        <a href="mailto:help@rockporch.com" target="blank_">help@rockporch.com</a>
                    </div>
                </div>
                {this.props.loggedInUser?.proStatusId === 2 ?
                    <div className="container py-2">
                        <div className="col-12 p-0">
                            <h4>Merchant Partner Support</h4>
                            <a href="mailto:merchantsupport@rockporch.com" target="blank_">merchantsupport@rockporch.com</a>
                        </div>
                    </div> : <></>}
                {this.props.loggedInUser?.proStatusId === 1 ?
                    <div className="container py-2">
                        <div className="col-12 p-0">
                            <h4>Creator Support</h4>
                            <a href="mailto:creatorsupport@rockporch.com" target="blank_">creatorsupport@rockporch.com</a>
                        </div>
                    </div> : <></>}
                <div className="container py-2">
                    <div className="col-12 p-0">
                        <h4>Report an issue</h4>
                        <a href="mailto:report@rockporch.com" target="blank_">report@rockporch.com</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;