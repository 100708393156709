import { ClientRequest } from "../../api-social/clientRequest";

const ENABLE_AUDIT_MESSAGES = false;

function getScript(src) {
    return new Promise((resolve) => {
        if (window.instgrm) {
            resolve(window.instgrm);
        }
        const id = 'instagram-embeds-jssdk';
        const fjs = document.querySelectorAll('script')[0];
        if (document.getElementById(id)) {
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = src;
        js.addEventListener('load', () => {
            resolve(window.instgrm);
        });
        fjs.parentNode.insertBefore(js, fjs);
    });
}

async function loadEmbeds(val, headers) {
    if (!!(val?.data)) {
        let tasks = [];

        for (var i = 0; i < val.data.length; i++) {
            let postItem = val.data[i];
            if (!(postItem?.embed)) {
                let args = {
                    url: postItem.url,
                    maxWidth: 640
                };
                let task = ClientRequest.get('/api/instagram/embed', args, false, headers)
                    .then(
                        (val) => {
                            if (!!(val.html)) {
                                postItem.embed = val.html;
                            }
                        },
                        (err) => { console.error(err); }
                    );
                tasks.push(task);
            }
        }
        while (tasks.length) {
            let task = tasks.pop();
            await task;
        }
    }
}

class InstagramAuthApi {
    constructor(appId) {
        this._appId = appId;
        this._authorization = undefined;
        this._authHeader = null;

        this.authorize = this.authorize.bind(this);
        this.doAuthorize = this.doAuthorize.bind(this);
        this.doInitAsync = this.doInitAsync.bind(this);
    }
    get authorization() {
        return this._authorization;
    }
    set authorization(value) {
        if (this._authorization === value)
            return;

        this._authorization = value;
        this.sessionAuthorization = value;
    }
    get isAuthorized() {
        return !!(this.authorization?.access_token);
    }

    get sessionAuthorization() {
        let retVal = undefined;
        try {
            let data = localStorage.getItem('instagram.authorization');
            if (data === 'null')
                data = null;
            retVal = !!data ? JSON.parse(data) : null;
        } catch (e) { }

        return retVal;
    }

    set sessionAuthorization(value) {
        try {
            let data = !!value ? JSON.stringify(value) : null;

            if (data != null) {
                localStorage.setItem('instagram.authorization', data);
            } else {
                localStorage.removeItem('instagram.authorization');
            }
        } catch (e) { }
    }

    async doAuthorize(resolve, reject) {
        await this.doGetAuthorizationTicket()
            .then(
                async (authorization) => {
                    if (!!authorization) {
                        let authorizationST = await ClientRequest.get(`/api/instagram/accesstoken/?authorizationCode=${authorization.code}`, null, false, this.authHeader);
                        resolve(authorizationST);
                    }
                    resolve(undefined);
                },
                (err) => {
                    _authApi.authorization = null;
                    reject(err);
                }
            );
    }
    doGetAuthorizationTicket() {
        return new Promise((resolve, reject) => {
            let url = `//api.instagram.com/oauth/authorize/?client_id=${this._appId}&redirect_uri=${window.location.origin}/instagram/auth/&scope=user_profile,user_media&response_type=code`;
            let s = window.screen, ww = s.width, wh = s.height, w = 700, h = 500, l = (ww - w) / 2, t = (wh - h) / 2;
            let features = `width=${w},height=${h},left=${l},top=${t}`;

            const handleLoad = async () => {
                if (window.location.hash.length === 0)
                    popup.open(url, '_self');

                var interval = setInterval(async () => {
                    try {
                        if (!popup || popup.closed) {
                            clearInterval(interval);
                            resolve(undefined);
                        } else if (popup.location.hash?.length) {
                            clearInterval(interval);
                            let qs = popup.location.search;
                            popup.close();

                            if (qs.startsWith('?')) {
                                qs = qs.substring(1);
                            }
                            let e = qs.split('&');
                            let authResponse = { authorization: {} };
                            let authorization = authResponse.authorization;

                            for (let i = 0; i < e.length; i++) {
                                let nvp = e[i].split('=');
                                authorization[nvp[0]] = nvp[1];
                            }
                            let code = authorization.code;
                            if (!code) {
                                reject(authResponse.authorization);
                            } else {
                                resolve(authorization);
                            }
                        }
                    }
                    catch (err) {
                        if (!(err.code === 18 && err.message.includes('Blocked a frame with origin'))) {
                            if (!!popup || popup.closed) {
                                clearInterval(interval);
                                popup.close();
                            }
                            _authApi.authorization = null;
                            reject(err);
                        }
                    }
                }, 100);
            };
            var popup = window.open(`${window.location.origin}/instagram/auth`, '', features);
            popup.onload = (handleLoad);
        });
    }
    async doInitAsync(resolve, headers) {
        this._authHeader = headers;

        this.authorization = this.sessionAuthorization;
        if (!(this.authorization)) {
            let instagramSession = await ClientRequest.get('/api/instagram/session/', null, false, this._authHeader);
            if (!(instagramSession?.code)) {
                if (!!(instagramSession['$id'])) {
                    delete instagramSession['$id'];
                }
                this.authorization = instagramSession;
            }
        }
        resolve(this);
    }

    authorize() {
        if (this.isAuthorized) {
            return new Promise((resolve) => {
                resolve(this.authorization);
            })
        }

        return new Promise(this.doAuthorize);
    }
    init(headers) {
        const handleResolve = async (resolve) => {
            await this.doInitAsync(resolve, headers);
        };
        return new Promise(handleResolve);
    }
    logout() {
        localStorage.removeItem("instagram.authorization");
        return new Promise((resolve) => {
            window.setTimeout(() => {
                resolve(true);
            }, 1000);
            window.open('https://instagram.com/accounts/logout/');
        });
    }
}

class InstagramClientApi {
    constructor(accessToken) {
        this._accessToken = accessToken;
    }
    setAccessToken(accessToken) {
        this._accessToken = accessToken;
    }

    getMe() {
        const ALL_USER_FIELDS = 'account_type,id,media_count,username'; 
        return new Promise(async (resolve, reject) => {
            const endpoint = '//graph.instagram.com/me/';

            let criteria = {
                access_token: this._accessToken,
                fields: ALL_USER_FIELDS
            };

            const handleResolved = (response) => {
                if (ENABLE_AUDIT_MESSAGES) console.log(new Date(), '<--', endpoint, response);
                resolve(response);
            };

            const handleRejected = (err) => {
                if (ENABLE_AUDIT_MESSAGES) console.error(new Date(), '<--', endpoint, err);
                reject(err)
            };

            let request = ClientRequest.get(endpoint, criteria).then(handleResolved, handleRejected);
            if (ENABLE_AUDIT_MESSAGES) console.log(new Date(), '-->', endpoint, criteria);
            await request;
        });
    }

    getUserMedia(accessToken, userId, after = null) {
        const ALL_MEDIA_FIELDS = 'caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,children{id,media_type,media_url,permalink,thumbnail_url,timestamp}';
        return new Promise(async (resolve, reject) => {
            let endpoint = `//graph.instagram.com/${userId}/media/`;
            let criteria = {
                access_token: this._accessToken,
                fields: ALL_MEDIA_FIELDS,
                limit:25
            };
            if (!!after) {
                criteria.after = after
            }
            const handleResolved = async (response) => {
                if (ENABLE_AUDIT_MESSAGES) console.log(new Date(), '<--', endpoint, response);
                resolve(response);
            };
            const handleRejected = (err) => {
                if (ENABLE_AUDIT_MESSAGES) console.error(new Date(), '<--', endpoint, err);
                reject(err);
            };

            let request = ClientRequest.get(endpoint, criteria).then(handleResolved, handleRejected);
            if (ENABLE_AUDIT_MESSAGES) console.log(new Date(), '-->', endpoint, criteria);
            await request;
        });
    }
}

var _authApi = new InstagramAuthApi('');
var _clientApi = new InstagramClientApi(null);
var _embedApi = undefined;

//getScript('//platform.instagram.com/en_US/embeds.js').then((lib) => _embedApi = lib);

let _clientHandle = undefined;

class InstagramClient {
    constructor() {
        this._headers = undefined;
        _authApi = null;
        _clientApi = null;

        this.init = this.init.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.getChannelsFor = this.getChannelsFor.bind(this);
        this.getPostsFor = this.getPostsFor.bind(this);
        this.getUserProfile = this.getUserProfile.bind(this);
    }

    get clientApi() {
        if (!(_clientApi)) {
            if (this.isAuthorized) {
                _clientApi = new InstagramClientApi(this.authorization.access_token);
            }
        }
        return _clientApi;
    }
    set clientApi(value) {
        _clientApi = null;
    }
    get authorization() {
        return _authApi?.authorization;
    }
    set authorization(value) {
        if (this.isAuthorized) {
            _authApi.authorization = null;
        }
    }
    get isAuthorized() {
        return !!(_authApi?.isAuthorized);
    }
    get isInitialized() {
        return !!_authApi;
    }

    getChannelsFor(userProfile) {
        return new Promise(async (resolve, reject) => {
            let access_token = this.authorization?.access_token;
            let hasAccessToken = !!access_token;

            if (!hasAccessToken) {
                reject('User is not authorized');
            } else if (!(userProfile?.id)) {
                reject('userProfile parameter required')
            } else {
                resolve({
                    data: [{ id: userProfile.id, name: 'default channel' }]
                });
            }
        });
    }

    getPostsFor(channel) {
        let headers = this._headers;
        return new Promise(async (resolve, reject) => {
            let access_token = this.authorization?.access_token;
            let hasAccessToken = !!access_token;

            if (!hasAccessToken) {
                reject('User is not authorized');
            } else if (!(channel?.id)) {
                reject('channel parameter required')
            } else {

                let promise = undefined;

                if (!!(channel.posts?.paging?.cursors.after)) {
                    promise = this.clientApi.getUserMedia(access_token, channel.id, channel.posts.paging.cursors.after);
                } else {
                    promise = this.clientApi.getUserMedia(access_token, channel.id);
                }

                promise.then(
                    async (val) => {
                        const targetModelFrom = (sourceModel) => {

                            let targetModel = {
                                content: sourceModel.caption,
                                embed: null,
                                id: sourceModel.id,
                                link: sourceModel.permalink,
                                media: [],
                                platformName: 'instagram',
                                publishedOn: sourceModel.timestamp,
                                raw: { ...sourceModel },
                                title: null
                            };
                            if (!!(sourceModel?.children?.data)) {
                                for (let i = 0; i < sourceModel.children.data.length; i++) {
                                    let sourceMediaModel = sourceModel.children.data[i];
                                    let targetMediaModel = {
                                        content: null,
                                        children: [],
                                        duration: null,
                                        height: null,
                                        imageLink: null,
                                        isExternalReference: true,
                                        thumbnailLink: null,
                                        videoLink: null,
                                        width: null
                                    };
                                    if (sourceMediaModel.media_type === 'IMAGE') {
                                        targetMediaModel.imageLink = sourceMediaModel.media_url;
                                    } else if (sourceMediaModel.media_type === 'VIDEO') {
                                        targetMediaModel.videoLink = sourceMediaModel.media_url;
                                    }
                                    if (!!(sourceMediaModel?.thumbnail_url)) {
                                        targetMediaModel.thumbnailLink = sourceMediaModel.thumbnail_url;
                                    }
                                    targetModel.media.push(targetMediaModel);
                                }
                            } else if (!!(sourceModel?.media_type)) {
                                let targetMediaModel = {
                                    content: null,
                                    children: [],
                                    duration: null,
                                    height: null,
                                    imageLink: sourceModel.media_type === 'IMAGE' ? sourceModel?.media_url : null,
                                    isExternalReference: true,
                                    thumbnailLink: null,
                                    videoLink: sourceModel.media_type === 'VIDEO' ? sourceModel?.media_url : null,
                                    width: null
                                };

                                if (sourceModel.media_type === 'VIDEO') {
                                    let childModel = {
                                        content: null,
                                        children: [],
                                        duration: null,
                                        height: null,
                                        imageLink: null,
                                        isExternalReference: true,
                                        thumbnailLink: sourceModel.thumbnail_url,
                                        videoLink: null,
                                        width: null
                                    };
                                    targetMediaModel.children.push(childModel);
                                }
                                targetModel.media.push(targetMediaModel);
                            }
                            return targetModel;
                        };

                        let retVal = { data: [] };
                        for (let i = 0; i < val.data.length; i++) {
                            let sourceModel = val.data[i];
                            let targetModel = targetModelFrom(sourceModel);
                            retVal.data.push(targetModel);
                        }

                        if (retVal.data.length == 0) {
                            delete retVal.paging;
                        } else {
                            retVal.paging = val.paging;
                        }
                        
                        resolve(retVal);
                    },
                    (err) => {
                        this.authorization = null;
                        reject(err)
                    }
                );

                await promise;
            }
        });
    }

    getUserProfile() {
        let authorization = _authApi.authorization;
        return new Promise(async (resolve, reject) => {
            let access_token = authorization?.access_token;
            let hasAccessToken = !!access_token;

            if (!hasAccessToken) {
                reject('User is not authorized');
            } else {
                await this.clientApi.getMe()
                    .then(
                        (val) => {
                            val.name = val.username;
                            delete val.username;
                            resolve(val);
                        },
                        (err) => {
                            _authApi.authorization = null;
                            reject(err);
                        }
                    );
            }
        });
    }

    init(appId, headers) {
        let candidate = { api: this };
        let isInitializing = false;
        if (_clientHandle === undefined) {
            _clientHandle = candidate;
            isInitializing = true;
        }
        let retVal = _clientHandle;

        this._headers = headers;
        return new Promise(async (resolve, reject) => {
            try {
                if (this.isInitialized) {
                    resolve(retVal);
                } else {
                    if (!isInitializing) {
                        if (ENABLE_AUDIT_MESSAGES) console.log('INSTAGRAM RE-INITIALIZE WAS BLOCKED')
                        setTimeout(() => {
                            resolve(retVal);
                        }, 2000);
                    } else {
                        let authApi = new InstagramAuthApi(appId);
                        await authApi.init(headers)
                            .then(
                                async (val) => {
                                    _authApi = val;
                                    if (this.isAuthorized) {
                                        retVal.authorization = _authApi.authorization;
                                        await this.getUserProfile()
                                            .then(
                                                (val) => {
                                                    retVal.userProfile = val;
                                                },
                                                (err) => {
                                                    _authApi.authorization = null;
                                                    retVal.authorization = null;
                                                }
                                            );
                                    }
                                    resolve(retVal);
                                },
                                (err) => {
                                    _authApi.authorization = null;
                                    reject(err.error);
                                }
                            );
                    }
                }
            } catch (ex) {
                _authApi.authorization = null;
                reject(ex);
            }
        });
    }

    login() {
        return new Promise(async (resolve, reject) => {
            try {
                await _authApi.authorize()
                    .then(
                        (val) => {
                            if (!!val) {
                                _authApi.authorization = val;
                                _clientApi = new InstagramClientApi(val.access_token);
                            }

                            resolve(val);
                        },
                        (err) => {
                            _authApi.authorization = null;
                            console.error(err);
                            reject('User was not authorized');
                        }
                    );
            }
            catch (ex) {
                _authApi.authorization = null;
                reject(ex);
            }
        });
    };

    logout() {
        return new Promise(async (resolve, reject) => {
            if (!(this.isAuthorized)) {
                reject('user may remain authorized according to instagram')
            }
            if (await _authApi.logout()) {
                this.authorization = null;
                this.clientApi = null;
                resolve(true);
            }
        });
    };

    processEmbeds(elements, headers) {
        const doEmbed = async () => {
            let tasks = [];
            for (let i = 0; i < elements.length; i++) {
                let element = elements[i];
                let args = {
                    url: element.getAttribute('data-href')
                };
                if (!!element.getAttribute('data-width')) {
                    args.maxWidth = element.getAttribute('data-width');
                }
                let task = ClientRequest.get('/api/instagram/embed', args, false, headers)
                    .then(
                        (val) => {
                            if (!!(val.html)) {
                                element.innerHTML = val.html
                            }
                        },
                        (err) => { console.error(err); }
                    );
                tasks.push(task);
            }
            while (tasks.length) {
                let task = tasks.pop();
                await task;
            }
            window.instgrm.Embeds.process();
        }
        doEmbed();
    }
}

const instagramClient = window.instagramClient || new InstagramClient();
if (!window.instagramClient) {
    window.instagramClient = instagramClient;
}

export default instagramClient;
