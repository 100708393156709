import React from "react";

class ErrorTest extends React.Component {

    componentDidMount = () => {
        let a = this.state.errorTesting.errorTesting;
    }

    render() {
        return <></>
    }
}

export default ErrorTest;