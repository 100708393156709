import React from "react";
import AccountBanner from "../../components/AccountBanner";
import { Form, FormGroup, Label, Input } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { isValidUrl } from "sharedUtils/validationUtils";
import { getRequest, postRequest } from 'sharedUtils/httpUtils';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { convertToNumericMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "../../components/LoadSpinner";
import Modal from "components/Modal";

class CurrentDeals extends React.Component {
    state = {
        id: 0,
        url: "",
        ordinal: null,
        description: "",
        startDate: "",
        endDate: "",
        isValidUrl: false,
        isValidDate: false,
        showError: false,
        merchantExists: null,
        isSubmitting: false,
        isDeleting: false,
        isFetching: false,
        merchants: [],
        currentDeals: [],
        show: false,
        stateChange: true,
        modalType: 1,
        CurrentDealsTable: {
            title: "Current Deals",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "merchantName",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "url",
                    label: "URL",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "300px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "description",
                    label: "Description",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "500px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "ordinal",
                    label: "Preferred Order",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "100px" } }),
                    }
                },
                {
                    name: "startDate",
                    label: "Start Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return value.substring(0, value.indexOf("T"));
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return value.substring(0, value.indexOf("T"));
                        }
                    }
                },
            ],
            options: {
                selectableRows: false,
                onRowClick: async (rowData, rowMeta) => {
                    this.setState({
                        modalType: 2,
                        id: rowData[0],
                        url: rowData[2],
                        description: rowData[3],
                        ordinal: rowData[4],
                        startDate: rowData[5],
                        endDate: rowData[6],
                        isValidDate: true,
                        isValidUrl: true,
                        stateChange: false,
                    });
                    this.showModal();
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        },
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                        root: {
                        }
                    },
                }
            })
        },
    };

    async componentDidMount() {
        this.setState({ isFetching: true });

        const merchants = await this.fetchMerchants();
        const currentDealsInitial = await this.fetchCurrentDeals();

        this.setState({ isFetching: false });

        if (merchants && currentDealsInitial) {
            const currentDeals = this.addMerchantNameToDeals(currentDealsInitial, merchants);

            this.setState({ currentDeals });
        }
    }

    addMerchantNameToDeals = (deals, merchants) => {
        return deals.map((deal) => {
            const correspondingMerchant = merchants.find((merchant) => merchant.id === deal.merchantId);
            const merchantName = correspondingMerchant ? correspondingMerchant.name : 'Unknown Merchant';

            return {
                ...deal,
                merchantName,
            };
        });
    };



    fetchMerchants = async () => {
        const getUrl = "api/Merchant/GetMerchantList";
        const merchants = await getRequest(getUrl);
        this.setState({ merchants });
        return merchants;
    }

    fetchCurrentDeals = async () => {
        const getUrl = "api/Merchant/GetCurrentDeals?admin=true";
        const currentDeals = await getRequest(getUrl);
        return currentDeals;
    }

    getModalTitle = () => {
        switch (this.state.modalType) {
            case 1:
                return "Create New Current Deal";
            default:
                return "Edit Current Deal";
        }

    }

    resetState = (noShow = false) => {
        this.setState({
            show: this.state.modalType === 2 || noShow ? false : this.state.show,
            stateChange: true,
            url: "",
            description: "",
            startDate: "",
            endDate: "",
            isValidUrl: false,
            isValidDate: false,
            showError: false,
            modalType: 1,
        });
    };

    showModal = (e) => {
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false });
    }


    breadCrumbList = [
        { link: "/Admin", text: "Admin" },
        { link: "/Admin/CurrentDeals", text: "Current Deals" },
    ];

    handleUrl = (e) => {
        const url = e.target.value;
        const isValid = url.length > 0 ? isValidUrl(url, true) : false;
        this.setState({ url, isValidUrl: isValid, showError: false, stateChange: true });
    };

    handleOrdinal = (e) => {
        const ordinal = e.target.value > 0 ? e.target.value : null;
        
        this.setState({ ordinal, showError: false, stateChange: true });
    };

    handleDescription = (e) => {
        this.setState({ description: e.target.value, showError: false, stateChange: true });
    };

    handleStartDate = (e) => {
        this.setState({ startDate: e.target.value, showError: false, stateChange: true });
    };

    handleEndDate = (e) => {
        this.setState({ showError: false, endDate: e.target.value, stateChange: true });
    };

    handleSubmit = async () => {
      const { url, description, isValidUrl, startDate, endDate, ordinal } = this.state;

      const isValidDate = this.validateDate(startDate, endDate);
      if (isValidUrl && isValidDate) {
        const submitUrl = this.state.modalType === 1 ? "api/Merchant/CreateCurrentDeal" : "api/Merchant/UpdateCurrentDeal";
        const payload = this.state.modalType === 1 ? {
          url,
          description,
          startDate,
          endDate,
          ordinal,
        } :
        {
            id: this.state.id,
            url,
            description,
            startDate,
            endDate,
            ordinal
        };

        this.setState({ isSubmitting: true })
        const currentDeal = await postRequest(submitUrl, payload);
        const merchantExists = !!(currentDeal) ? true : false;
        this.setState({ merchantExists, isSubmitting: false });
        if (merchantExists) {
            this.resetState();
            this.setState({ isFetching: true });

            const currentDealsInitial = await this.fetchCurrentDeals();

            this.setState({ isFetching: false });

            if (currentDealsInitial) {
                const currentDeals = this.addMerchantNameToDeals(currentDealsInitial, this.state.merchants);

                this.setState({ currentDeals });
            }

            setTimeout(() => {
                this.setState({ merchantExists: null });
                if (this.state.modalType === 2) {
                    this.resetState();
                }
            }, 2000)
        }
      } else {
        this.setState({ showError: true, isValidDate });
      }
    };

    confirmDelete = () => {
        const confirmDelete = window.confirm("Are you sure you want to DELETE this item? This will be permanent...");
        if (confirmDelete) {
            this.handleDelete();
        }
    }


    handleDelete = async () => {
        const deleteUrl = "api/Merchant/DeleteCurrentDeal";

        const payload = {
            id: this.state.id
        }

        this.setState({ isDeleting: true })
        await postRequest(deleteUrl, payload);

        this.resetState();

        this.setState({ isDeleting: false, isFetching: true });
        const currentDealsInitial = await this.fetchCurrentDeals();

        this.setState({ isFetching: false });

        if (currentDealsInitial) {
            const currentDeals = this.addMerchantNameToDeals(currentDealsInitial, this.state.merchants);

            this.setState({ currentDeals });
        }
    }

    handleCreateCurrentDealButton = () => {
        this.resetState();
        this.setState({ show: true });
    }

    validateDate = (startDate, endDate) => {
        if (!startDate || !endDate) {
        return false;
        }

        const startTimestamp = new Date(startDate).setHours(0,0,0,0);
        const endTimestamp = new Date(endDate).setHours(23,59,59,999);
        const currentTimestamp = new Date().setHours(23, 59, 59, 999);

        return startTimestamp <= endTimestamp && endTimestamp >= currentTimestamp;
        };

    render() {
        const { url, description, startDate, endDate } = this.state; 
        const submitDisabled = !this.state.stateChange || (url.length === 0 || description.length === 0 || startDate.length === 0  || endDate.length === 0);

        return (
            <>
                <Modal isOpen={this.state.show} modalHeaderClass={"current-deals-modal-header"}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'lightgray',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '350px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => { this.setState({ show: false }); this.resetState(true); }}
                    toggleModal={() => { this.setState({ show: false }); this.resetState(true); }}
                >

                    <section className="gray-bg" style={{ marginBottom: "-20px", marginTop: "-5px" }}>
                        <div className="container pt-0 pb-5" >
                            <div className="d-flex flex-column pt-4 mb-2">
                                <h4>{this.getModalTitle()}</h4>
                            </div>
                            <FormGroup className="form-group" >
                                <Label className="control-label">URL:</Label>
                                <Input
                                    className="form-control"
                                    name="url"
                                    type="text"
                                    value={this.state.url}
                                    placeholder="Enter URL from integrated merchant here..."
                                    onChange={this.handleUrl}
                                />
                            </FormGroup>
                            <FormGroup className="form-group" >
                                <Label className="control-label">Description:</Label>
                                <Input
                                    className="form-control"
                                    name="Description"
                                    value={this.state.description}
                                    type="textarea"
                                    rows="4"
                                    placeholder="Description of deal..."
                                    onChange={this.handleDescription}
                                />
                            </FormGroup>
                            <FormGroup className="form-group" >
                                <Label className="control-label">Preferred Order:</Label>
                                <Input
                                    className="form-control"
                                    name="ordinal"
                                    type="number"
                                    value={this.state.ordinal}
                                    placeholder="Select preferred order"
                                    min={0}
                                    onChange={this.handleOrdinal}
                                />
                            </FormGroup>
                            <div className="d-flex">
                                <FormGroup className="form-group" style={{ width: "150px" }}>
                                    <Label className="control-label">Start Date:</Label>
                                    <Input
                                        className="form-control"
                                        name="startDate"
                                        value={this.state.startDate}
                                        type="date"
                                        onChange={this.handleStartDate}
                                    />
                                </FormGroup>
                                <FormGroup className="form-group ml-5" style={{ width: "150px" }}>
                                    <Label className="control-label">End Date:</Label>
                                    <Input
                                        className="form-control"
                                        name="endDate"
                                        value={this.state.endDate}
                                        type="date"
                                        onChange={this.handleEndDate}
                                    />
                                </FormGroup>
                            </div>

                            <p style={{ color: "red", display: this.state.showError && !this.state.isValidUrl ? "" : "none" }}>
                                Use a valid URL
                            </p>
                            <p style={{ color: "red", display: this.state.showError && !this.state.isValidDate ? "" : "none" }}>
                                Start date should be before the end date, & end date should be no later than today
                            </p>
                            <p style={{ color: "red", display: this.state.merchantExists === false ? "" : "none" }}>
                                Merchant not found. Try again.
                            </p>
                            <p style={{ color: "green", display: this.state.merchantExists === true ? "" : "none" }}>
                                Success!
                            </p>
                            <div className="d-flex">
                                <ActionButton className="btn btn-primary mt-3"
                                    onClick={this.handleSubmit}
                                    isLoading={this.state.isSubmitting}
                                    isDisabled={submitDisabled}
                                >
                                    Submit
                                </ActionButton>
                                {
                                    this.state.modalType === 2 ?
                                        <ActionButton className="btn btn-danger ml-3 mt-3"
                                            onClick={this.confirmDelete}
                                            isLoading={this.state.isDeleting}
                                        >
                                            Delete
                                        </ActionButton>
                                        :
                                        <></>
                                }
                       
                            </div>
                        </div>
                           
                    </section>
                </Modal>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Current Deals</h1>
                        <p className="mb-0">Create current deals with a URL from an integrated merchant</p>
                    </AccountBanner>
                </section>
                <section>
                    <div className="container-fluid">
                        <div className="text-right">
                            <ActionButton className="btn btn-primary mt-3"
                                onClick={this.handleCreateCurrentDealButton}
                            >
                                Create Current Deal
                            </ActionButton>
                        </div>
                        {
                            this.state.isFetching ?
                                <LoadSpinner />
                                :
                                <div className="row">
                                    <div className="col">
                                        <ThemeProvider theme={this.state.CurrentDealsTable.getTheme()}>
                                            <MUIDataTable
                                                title={this.state.CurrentDealsTable.title}
                                                data={this.state.currentDeals}
                                                columns={this.state.CurrentDealsTable.columns}
                                                options={this.state.CurrentDealsTable.options}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                         }
                    </div>
                </section>
                
            </>
        )
    }
}

export default CurrentDeals;