import React from "react";


/**
 * 
 * @param {{isFilled:boolean, fillBackground:string}} props
 */
const PinIcon = (props) => {

    //let iconClass = props.isFilled == true ? "fas fa-thumbtack" : "far fa-thumbtack";

    //let iconClass = props.isFilled == true ? "fas fa-star" : "far fa-star";

    //https://img.icons8.com/ios-filled/50/000000/pin--v1.png
    //https://img.icons8.com/office/16/000000/pin.png

    /*let iconSrc = props.isFilled == true ? "https://img.icons8.com/office/16/000000/pin.png" : "https://img.icons8.com/ios/50/000000/pin--v1.png";*/

    let iconSrc = props.isFilled == true ? "images/thumbtackRed.png" : "images/thumbtack.png";
    let backgroundColor = props.isFilled == true ? "#ffd700" : "#ffffff"
    return (

        <img src={iconSrc} width={props.width || "15"} height={props.height || "15"} style={{ backgroundColor: props.fillBackground, borderRadius: "50%", padding: props.padding || "2px", opacity: props.opacity || "1" }} />

    )

}

export default PinIcon;