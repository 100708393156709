import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "../../components/LoadSpinner";
import InformationIcon from "components/icons/InformationIcon";
import PenIcon from "components/icons/PenIcon";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { CheckIconBlack } from "../../icons";
import ActionButton from "../../components/ActionButton"
import Modal from 'react-modal';
import { wrap } from "lodash";


class Brands extends React.Component {

    state = {
        filter: "",
        filteredPartners: [],
        partners: [],
        loading: true,
        sectors: [],
        show: false,
        fetchingExcel: false,
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/Brands', text: 'Merchant Partners' }
    ]

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.getPartners();
    }

    getPartners = async () => {
        let partnersUrl = '/api/Merchant/GetCommissionEstimate?getMaxByMerchant=true';
        let partners = await getRequest(partnersUrl);
        this.setState({ partners: partners, filteredPartners: partners, loading: false });
    }

    onInputChange = (e) => {
        let filter = e.target.value;
        let filteredPartners = this.state.partners.filter(partner => partner.merchantName.toLowerCase().includes(filter.toLowerCase()));

        this.setState({ filter: filter, filteredPartners: filteredPartners });
    };

    renderPartners = () => {
        return this.state.filteredPartners.map((partner, i) => {

            let commissionPercentage = partner.commission * 100;
            let roundedCommissionPercentage = commissionPercentage % 1 == 0 ? commissionPercentage : commissionPercentage.toFixed(2);

            return (
                <div key={i} className="col-12 col-md-6 col-lg-3 mb-5">
                    <div className="partner-block">
                        <div className="corner-label" />
                        <div className="corner-label-text">{roundedCommissionPercentage}%</div>
                        <a href={partner.merchantHomePage} target="blank_"><img src={partner.merchantLogoUrl} /></a>
                        <div className="partner-label float-right">{partner.merchantName}</div>
                    </div>
                </div>)
        });
    }

    exportMerchants = async () => {
        this.setState({ fetchingExcel: true });
        const url = `api/Merchant/GetMerchantPartnerExports`;
        const response = await getRequest(url);

        if (response) {
            this.setState({ excelError: false, fetchingExcel: false });
        } else {
            this.setState({ excelError: true, fetchingExcel: false });
        }
    }

    displayTableRows = () => {
        return this.state.filteredPartners.map((partner, i) => {
            let commissionPercentage = partner.commission * 100;
            let roundedCommissionPercentage = commissionPercentage % 1 === 0 ? commissionPercentage : commissionPercentage.toFixed(2);

            return (
                <tr key={i}>
                    <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <a href={partner.merchantHomepage} target="blank_">
                                <img style={{ objectFit: "contain" }} src={partner.merchantLogoUrl} />
                            </a>
                            <span>{partner.merchantName}</span>
                        </div>

                    </td>
                    <td>
                        <span>{partner.ordinal}</span>
                    </td>
                    <td>
                        <span>{roundedCommissionPercentage}%</span>
                    </td>
                    <td>
                        <span>{partner.integrated ? <CheckIconBlack/> : ""}</span>
                    </td>
                    <td>
                        <span>{!!(partner.deepLinkOnly) ? <CheckIconBlack/> : ""}</span>
                    </td>
                    <td>
                        <Link to={`/Admin/Brand/${partner.id}`}><PenIcon /></Link>
                    </td>
                </tr>);
        });
    }

    displayTableHeader = () => {
        let headers = ["Name", "Preferred Order", "Estimated Commission", "Integrated", "Deep Link Only", "Edit"];
        return headers.map((header, i) => {
            return (
                <th key={i} style={{ maxWidth: header !== "Name" ? "100px" : "" }}>
                    <strong>{header}
                        {header === "Estimated Commission" ? <><small className="d-inline-block ml-1" style={{ position: "relative", top: "-2px" }}>
                            <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id="infoIcon"><InformationIcon /></button>
                                </small>
                                    <UncontrolledPopover
                                        placement="bottom"
                                        target="infoIcon"
                                        trigger="legacy">
                                        <PopoverBody>Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase.</PopoverBody>
                                    </UncontrolledPopover></>
                            : null}
                    </strong></th>
            )
        })
    }

    displayTableFooter = () => {
        const totalBrands = this.state.partners?.length;
        const totalIntegrated = this.state.partners?.filter(p => p.integrated)?.length;
        const totalDeepLinked = this.state.partners?.filter(p => !!(p.deepLinkOnly))?.length;
        if (totalIntegrated || totalDeepLinked) {
            return (
                <tr>
                    <td style={{ textAlign: "center", fontWeight: "bold", fontFamily: "'Archivo', sans-serif", fontSize: "16px", display: "flex", justifyContent: "space-between" }}>
                        <div>TOTALS:</div>
                        <div className="d-flex flex-column" style={{ fontSize: "14px" }}>
                            <div>Brands</div>
                            <div>{totalBrands}</div>
                        </div>
                        <div>&nbsp;</div>
                    </td>
                    <td colSpan="2"></td> {/* Empty cells for Name, Preferred Order, Estimated Commission */}
                    <td style={{ textAlign: "center", fontWeight: "bold", fontFamily: "'Archivo', sans-serif", fontSize: "14px" }}>
                        <div className="d-flex flex-column">
                            <div>Integrated</div>
                            <div>{totalIntegrated}</div>
                        </div>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: "bold", fontFamily: "'Archivo', sans-serif", fontSize: "14px" }}>
                        <div className="d-flex flex-column">
                            <div>Deep Link</div>
                            <div>{totalDeepLinked}</div>
                        </div>
                    </td>
                    <td></td> {/* Empty cell for Edit */}
                </tr>
            );
        }
    }


    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    onRefreshYes = (e) => {
        e.preventDefault();

        this.refreshMerchants();

        this.setState({ show: false })
    };

    refreshMerchants = () => {
        getRequest(`/api/Merchant/GetMerchantsFromApms`);
    };

    render() {
        if (this.state.loading) {
            return <LoadSpinner />
        }
        return (
            <div className="overflow-hidden">
                <Modal isOpen={this.state.show}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="mx-0 my-0">
                            <h1 style={{ textAlign: "center", display: "block", fontSize: '1.75rem' }}>
                                Are you sure that you want to refresh Merchant Partners?
                            </h1>
                            <p style={{ textAlign: 'center', marginTop: "35px", fontWeight: "bold" }}>
                                This will synchronize approved merchants from Affiliate Networks
                            </p>
                        </div>

                        <div className="d-flex justify-content-center gap-3 mt-4">
                            <button type="button" className="btn btn-primary mr-2" onClick={(e) => this.onRefreshYes(e, this.state.apmId)}>Yes</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={() => this.setState({ show: false })}>No</button>
                        </div>
                    </div>
                </Modal>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Merchant Partners</h1>
                        <p className="mb-0">Edit Merchant Partners</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container pt-0 pb-5">
                        <div className="search-interest py-4 mx-auto**">
                            <div className="search-block d-md-flex justify-content-between">
                                <div className={`form-group position-relative ${["sm", "md"].includes(this.state.deviceSize) ? "mx-auto" : ""} d-md-flex justify-content-center`}>
                                    <input style={{position: "inline-block !important", marginRight: "50px"}} type="search" id="gsearch" name="gsearch" placeholder="Search for partner..." value={this.state.filter} onChange={(e) => this.onInputChange(e)} />
                                    <input type="submit" className="position-absolute**" />
                                </div>
                                <div className="d-flex" style={{ gap: "10px" }}>
                                    <ActionButton className={`btn btn-primary`}
                                        style={{ height: "46px" }}
                                        onClick={(e) => { this.showModal(e) }}
                                    >
                                        Refresh
                                    </ActionButton>
                                    <ActionButton className={`btn btn-primary`}
                                        style={{ height: "46px" }}
                                        onClick={this.exportMerchants}
                                        isLoading={this.state.fetchingExcel}
                                        isDisabled={this.state.fetchingExcel}
                                    >
                                        Export
                                    </ActionButton>
                                </div>
                                <p className="mb-0 mt-3" style={{ color: "red", display: this.state.excelError ? "" : "none" }}>There was an error downloading the spreadsheet. Try again.</p>

                            </div>
                        </div>

                        <div>
                            
                            </div>

                        <div className="row mx-0 my-0 brand-table-margin">
                            <div className="table-responsive product-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {this.displayTableHeader()}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.displayTableRows()}
                                    </tbody>
                                    <tfoot>
                                        {this.displayTableFooter()}
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Brands;