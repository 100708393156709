import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router";
import Layout from "./components/Layout";
import AuthorizedLanding from "components/api-authorization/AuthorizedLanding";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import AdminRoute from "components/api-authorization/AdminRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import AccountIndex from "./views/Account/Index";
import CreatorDashboard from "./views/Account/CreatorDashboard";
import NewCreatorDashboard from "./views/CreatorDashboard/CreatorDashboard";
import AccountSaves from "./views/Account/Saves";
import MyProducts from "views/Account/MyProducts";
import EditProfile from "./views/Account/EditProfile";
import GoPro from "./views/Account/GoPro";
import CreatorApp from "./views/Account/CreatorApp";
import AccountSettings from "./views/Account/AccountSettings";
import ProSettings from "./views/Account/ProSettings";
import Drafts from "views/Account/Drafts";
import CommunicationSettings from "./views/Account/CommunicationSettings";
import SiteMap from "./views/SiteMap/SiteMap";
import NotificationsAndMessages from "./views/Account/NotificationsAndMessages";
import LockerPage from './views/Locker/LockerPage';
import ProductPage from "./views/Product/ProductPage";
import ProductPageEmbed from "./views/Embed/ProductPageEmbed";
import ProductPageEmbedBrand from "./views/Embed/ProductPageEmbedBrand";
import NewLockerPage from "./views/Locker/NewLockerPage";
import PostPageAuthor from "./views/Post/PostPageAuthor";
import NewBlogPage from "./views/Blog/NewBlogPage";
import PostPage from "./views/Post/PostPage";
import BlogPage from "./views/Blog/BlogPage"
import PostPageEmbed from "./views/Embed/PostPageEmbed";
import BlogPageEmbed from "./views/Embed/BlogPageEmbed";
import FeaturedContent from "./views/Embed/FeaturedContent";
import FeaturedProducts from "./views/Embed/FeaturedProducts";
import FeaturedPostsAndProducts from "./views/Embed/FeaturedPostsAndProducts";
import ProfileEmbed from "./views/Embed/ProfileEmbed";
import PostPageEmbedBrand from "./views/Embed/PostPageEmbedBrand";
import LockerPageEmbed from "./views/Embed/LockerPageEmbed";
import LockerPageEmbedBrand from "./views/Embed/LockerPageEmbedBrand";
import ProductModal from "./views/Product/ProductModal";
import AddProductModal from "./views/Product/AddProductModal";
import AddProductOrDeepLinkModal from "components/AddProductOrDeepLink/AddProductOrDeepLinkModal";
import MerchantPage from "./views/Merchant/MerchantPage";
import HomeFeed from "./views/Home/Home";
import Explore from "./views/Explore/Explore.jsx";
import Search from "views/Search/Search";
import Browse from "views/Browse/Browse";
import Profile from "views/Profile/Profile";
import Links from "views/Profile/Links";
import User from "views/Profile/User";
import ErrorPage from "views/ErrorPage";

import AdminDashboard from "views/Admin/AdminDashboard";
import CreatorApplications from "views/Admin/CreatorApplications";
import CampaignReport from "views/Admin/CampaignReport";
import ManageCommissions from "views/Admin/CommissionAdmin";
import BrandApplications from "views/Admin/BrandApplications";
import AdminBrands from "views/Admin/Brands";
import Brand from "views/Admin/Brand";
import AffiliateNetworks from "views/Admin/AffiliateNetworks";
import AffiliateNetwork from "views/Admin/AffiliateNetwork";
import Sectors from "views/Admin/Sectors";
import Users from "views/Admin/Users";
import CurrentDeals from "views/Admin/CurrentDeals";
import Featured from "views/Admin/Featured";
import CreatorDashboardAdmin from "views/Admin/CreatorDashboard";
import PaymentRuns from "views/Admin/PaymentRuns";
import PaymentRunDetails from "views/Admin/PaymentRunDetails";
import CommissionOverrides from "views/Admin/CommissionOverrides";
import AddUserCommissionOverride from "views/Admin/AddUserCommissionOverride";
import AddMerchantCommissionOverride from "views/Admin/AddMerchantCommissionOverride";
import AddUserMerchantCommissionOverride from "views/Admin/AddUserMerchantCommissionOverride";
import ItOperations from "views/Admin/ItOperations";
import Category from "views/Admin/Category";
import ManageBrands from "views/Admin/ManageBrands";
import AdminAnalytics from "views/Admin/Analytics/Analytics";


import { getAuthorizedUserData } from "./sharedUtils/authUtils";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { Create } from "./views/Post/Create";
import { getMessageCount, getNotificationCount } from "sharedUtils/notificationUtils";

import "./custom.css";

import Interests from "./views/Account/Interests";
import Brands from "./views/Account/Brands";
import OnboardInterests from "./views/Account/OnboardInterests";
import OnboardUserType from "./views/Account/OnboardUserType";
import OnboardGoPro from "./views/Account/OnboardGoPro";
import OnboardGettingStarted from "./views/Account/OnboardGettingStarted";
import OnboardWhoToFollow from "./views/Account/OnboardWhoToFollow";
import Analytics from "./views/Account/Analytics";
import BrandAnalytics from "./views/Account/BrandAnalytics";
import AddToHomescreenIOS from "components/AddToHomescreenIOS";

import { Redirect } from "react-router-dom";
import { DateTime, Interval } from "luxon";

import ErrorTest from "./views/Error"
import NotFound from "views/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import InstagramAuth from "./components/Post/Create/InstagramAuth";
import TikTokAuth from "./components/Post/Create/TikTokAuth";
import Contact from "./components/Contact"
import ChatBot from "./components/ChatBot";

import { showBrandNav } from "./sharedUtils/navUtils"


class App extends Component {
    static displayName = App.name;
    state = {
        loading: true,
        loggedInUser: null,
        showTopNav: true,
        unviewedMessageCount: 0,
        unviewedNotificationCount: 0,
        deviceSize: 'md',
        showSignUpModal: false,
        hamOpen: false,
        toggleMenu: false,
        homeFeedSaveUser: false,
        exploreSaveUser: false,
        embed: false,
        brandNav: true,
        screenWidth: 576,
        screenHeight: 700,
        searchInput: "",
        browseFilterOpen: false,
        resetBrowseSearch: false,
        resetSearchBar: false,
        mobileSearchOpen: false,
        categories: [],
        productPageOnly: false,
        reloadCreatorDashboardAnalyticsData: 0,
    }

    reloadCreatorDashboardAnalyticsData = () => {
        this.setState({ reloadCreatorDashboardAnalyticsData: this.state.reloadCreatorDashboardAnalyticsData + 1 })
    }

    setBrowseFilterOpen = (browseFilterOpen) => {
        this.setState({ browseFilterOpen });
    }

    handleResetSearchBar = (resetSearchBar = false) => {
        this.setState({ resetSearchBar });
    }

    handleMobileSearchOpen = (mobileSearchOpen) => {
        this.setState({ mobileSearchOpen });
    }

    populateSearch = (searchInput) => {
        this.setState({ searchInput });
    }

    setSignUpInterval = () => {
        this.signUpTimer = setTimeout(() => { this.refreshSignUp() }, 1000 * 20);
    }

    toggleSignUpModal = () => {
        this.setState({ showSignUpModal: !this.state.showSignUpModal });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearTimeout(this.signUpTimer);
    }

    fetchCategories = async () => {
        let url = `/api/Sector/GetMenuCategories?showAll=false&menuVisibleOnly=true`;
        const categories = await getRequest(url);
        this.setState({ categories });
    }

    async componentDidMount() {
        //const fullPageRender = this.allowFullPageLoadEnabledForUrl();
        const fullPageRender = true;

        let loggedInUser = undefined;

        if (fullPageRender) {
            this.loadAddToHomescreenCssAndJs();
            this.loadCssAndJs();
            this.timer = setInterval(() => { this.refreshState() }, 1000 * 60 * 10);
            this.setState({ loading: true });
            this.handleResize();

            window.addEventListener('resize', this.handleResize)
            if (window.location.pathname.toLowerCase().includes('authentication') || window.location.pathname === '/') {
                this.setShowTopNav(false);
            }

            if (!window.location.pathname.toLowerCase().includes('embed/brand') && !window.location.pathname.toLowerCase().includes('embed/prf')) {
                loggedInUser = await getAuthorizedUserData(
                    window.location.pathname.toLowerCase().startsWith('/product/') ||
                    window.location.pathname.toLowerCase().startsWith('/prd/') ||

                    window.location.pathname.toLowerCase().startsWith('/locker/') ||
                    window.location.pathname.toLowerCase().startsWith('/l/') ||

                    window.location.pathname.toLowerCase().startsWith('/post/') ||
                    window.location.pathname.toLowerCase().startsWith('/p/') ||

                    window.location.pathname.toLowerCase().startsWith('/profile/') ||
                    window.location.pathname.toLowerCase().startsWith('/prf/')
                );

                if (loggedInUser != undefined) {
                    var sectors = await getRequest("/api/Sector/GetUserSectors");
                    sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
                    loggedInUser.sectors.$values = sectors;

                    // LOGIC TO ADD LastLogin TO USER GOES HERE
                    postRequest("/api/Account/UpdateLastLogin");
                }

                //await this.fetchCategories();

            } else {
                this.setState({ embed: true })
            }
        } else {
            this.setState({ productPageOnly: true });
            this.setShowTopNav(false);
            loggedInUser = await getAuthorizedUserData(true);
        }

        this.setState({ loggedInUser }, () => {
            this.setState({ loading: false }, () => this.trackPage());
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.location !== prevProps.location) {
            this.trackPage();
        }
    }

    allowFullPageLoadEnabledForUrl = () => {
        // if pathname contains product or prd AND query does not contain b=1 or r=0 FALSE is returned
        // to avoid unnecessary delays for ProductPage redirects
        if (!window.location.pathname.toLowerCase().includes("addproduct") &&
            ((window.location.pathname.toLowerCase().startsWith('/product/') ||
            window.location.pathname.toLowerCase().startsWith('/prd/')) &&
            !(window.location.search.toLowerCase().includes('b=1') ||
                window.location.search.toLowerCase().includes('r=0')))) {
            return false;
        }
        return true;
    }

    loadAddToHomescreenCssAndJs = () => {
        const link = document.createElement('link');
        link.href = 'addToHomeScreen/addtohomescreen.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = 'addToHomeScreen/addtohomescreen.min.js';
        script.type = 'text/javascript';
        document.body.appendChild(script);
    }

    loadCssAndJs = () => {
        // Load Bootstrap CSS
        const bootstrapCssLink = document.createElement('link');
        bootstrapCssLink.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
        bootstrapCssLink.rel = 'stylesheet';
        bootstrapCssLink.integrity = 'sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm';
        bootstrapCssLink.crossOrigin = 'anonymous';
        document.head.appendChild(bootstrapCssLink);

        // Load Google Font
        const googleFontLink = document.createElement('link');
        googleFontLink.href = 'https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;700&display=swap';
        googleFontLink.rel = 'stylesheet';
        document.head.appendChild(googleFontLink);

        // Load Font Awesome
        const fontAwesomeCssLink = document.createElement('link');
        fontAwesomeCssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css';
        fontAwesomeCssLink.rel = 'stylesheet';
        fontAwesomeCssLink.integrity = 'sha512-1PKOgIY59xJ8Co8+NE6FZ+LOAZKjy+KY8iq0G4B3CyeY6wYHN3yt9PW0XpSriVlkMXe40PTKnXrLnZ9+fkDaog==';
        fontAwesomeCssLink.crossOrigin = 'anonymous';
        document.head.appendChild(fontAwesomeCssLink);

        // Load the stylesheets
        const cssLink = document.createElement('link');
        cssLink.href = `${process.env.PUBLIC_URL}/css/master.min.css?version=20240814`;
        cssLink.rel = 'stylesheet';
        document.head.appendChild(cssLink);

        //// Load jQuery
        //const jqueryScript = document.createElement('script');
        //jqueryScript.src = 'https://code.jquery.com/jquery-3.2.1.slim.min.js';
        //jqueryScript.integrity = 'sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN';
        //jqueryScript.crossOrigin = 'anonymous';
        //document.body.appendChild(jqueryScript);

        //// Load Popper.js
        //const popperScript = document.createElement('script');
        //popperScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js';
        //popperScript.integrity = 'sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q';
        //popperScript.crossOrigin = 'anonymous';
        //document.body.appendChild(popperScript);

        //// Load Bootstrap JS JS
        //const bootstrapScript = document.createElement('script');
        //bootstrapScript.src = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js';
        //bootstrapScript.integrity = 'sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl';
        //bootstrapScript.crossOrigin = 'anonymous';
        //document.body.appendChild(bootstrapScript);
    }


    trackPage() {
        setTimeout(() => {
            const host = window.location.host.toLowerCase().includes("app.rockpor");
            if (!host) return;
            const { search, hash } = this.props.location;
            window.gtag("event", "page_view", {
                page_path: window.location.href,
                page_search: search,
                page_hash: hash,
            });
        }, 0);
    }

    handleResize = () => {
        let sizes = {
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        };
        let windowSize = window.innerWidth;
        let windowHeight = window.innerHeight;

        this.setState({ screenWidth: windowSize, screenHeight: windowHeight });
        if (windowSize <= sizes.sm || windowSize < sizes.md) {
            this.setState({ deviceSize: "sm" })
        }
        else if (windowSize >= sizes.md && windowSize < sizes.lg) {
            this.setState({ deviceSize: "md" })
        }
        else if (windowSize >= sizes.lg && windowSize < sizes.xl) {
            this.setState({ deviceSize: "lg" })
        }
        else {
            this.setState({ deviceSize: "xl" })
        }
    }

    handleResetBrowseSearch = (resetBrowseSearch) => {
        this.setState({ resetBrowseSearch });
    }

    selectUserToFetchFollowersFollowing = (user) => {
        return user;
    }

    setShowTopNav = (show, brandNav = this.state.brandNav) => {
        this.setState({ showTopNav: show, brandNav });
    }

    setLoggedInUser = async (user) => {
        this.setState({ loggedInUser: user });
    }

    redirectToOnboarding = () => {
        if (!!(this.state.loggedInUser?.onboardingComplete) && this.state.loggedInUser.onboardingComplete == 1 && this.state.loggedInUser.proStatusId !== 1) {
            return <Redirect to="/" />
        } else {
            return <Redirect to="/Account/OnboardInterests" />
        }
    }

    refreshSignUp = async () => {
        this.setState({ showSignUpModal: true });
        clearInterval(this.signUpTimer);
    }

    refreshState = async () => {
        let profilePic = document.getElementById("profilePicId");
        if (profilePic != null) {
            let profilePicSrc = profilePic.src;

            var d = new Date();
            let index = profilePicSrc.lastIndexOf("?ver=");

            if (index >= 0) {
                profilePicSrc = profilePicSrc.substring(0, index);
            }

            profilePicSrc += "?ver=" + d.getTime();
            profilePic.src = profilePicSrc;
        }
    }
    hamburgerOpen = (isOpen) => {
        this.setState({hamOpen: isOpen})
    }

    setToggleMenu = () => {
        this.setState({ toggleMenu: !this.state.toggleMenu })
    }

    setHomeFeedSaveUser = () => {
        this.setState({ homeFeedSaveUser: true })
    }

    setExploreSaveUser = () => {
        this.setState({ exploreSaveUser: true })
    }

    displayAdminRoutes = () => {
        let standardViewProps = {
            loggedInUser: this.state.loggedInUser,
            setLoggedInUser: this.setLoggedInUser,
            deviceSize: this.state.deviceSize,
            setShowTopNav: this.setShowTopNav,
            setLoggedInUser: this.setLoggedInUser,
            isModal: false,
            showBrandNav,
        }

        if (this.state?.loggedInUser?.isAdmin === true) {
            return (
                <>
                    <AuthorizeRoute exact path="/Admin" component={AdminDashboard} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Analytics/Analytics" component={AdminAnalytics} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/ItOperations" component={ItOperations} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Category" component={Category} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/ManageBrands" component={ManageBrands} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CreatorApplications" component={CreatorApplications} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CampaignReport" component={CampaignReport} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/ManageCommissions" component={ManageCommissions} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/BrandApplications" component={BrandApplications} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Brands" component={AdminBrands} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Brand/:id" component={Brand} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/AffiliateNetworks" component={AffiliateNetworks} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/AffiliateNetworks/:id" component={AffiliateNetwork} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Sectors" component={Sectors} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Users" component={Users} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CurrentDeals" component={CurrentDeals} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/Featured" component={Featured} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CreatorDashboard" component={CreatorDashboardAdmin} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/PaymentRuns" component={PaymentRuns} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/PaymentRunDetails/:id" component={PaymentRunDetails} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CommissionOverrides" component={CommissionOverrides} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CommissionOverrides/AddUserCommissionOverride" component={AddUserCommissionOverride} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CommissionOverrides/AddMerchantCommissionOverride" component={AddMerchantCommissionOverride} componentProps={standardViewProps} />
                    <AuthorizeRoute exact path="/Admin/CommissionOverrides/AddUserMerchantCommissionOverride" component={AddUserMerchantCommissionOverride} componentProps={standardViewProps} />
                </>
            )
        }
        else {
            return <Route exact path="/NotFound" component={NotFound} />
        }
    }

    render() {
        if (this.state.loading) {
            return <></>;
        }

        let standardViewProps = {
            loggedInUser: this.state.loggedInUser,
            setLoggedInUser: this.setLoggedInUser,
            deviceSize: this.state.deviceSize,
            setShowTopNav: this.setShowTopNav,
            setLoggedInUser: this.setLoggedInUser,
            isModal: false,
            showBrandNav,
            brandNav:this.state.brandNav,
            screenWidth: this.state.screenWidth,
            screenHeight: this.state.screenHeight,
        }

        //if (this.state.productPageOnly) {
        //    return (
        //        <ErrorBoundary>
        //            <Switch>
        //                <Route exact path="/Product/ProductPage/:shareCode" render={(props) => <ProductPage screenWidth={this.state.screenWidth} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
        //                <Route exact path="/prd/:shareCode" render={(props) => <ProductPage screenWidth={this.state.screenWidth} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
        //            </Switch>
        //         </ErrorBoundary>
        //    );
        //}

        return (
            <Layout
                fetchCategories={this.fetchCategories}
                showSignUpModal={this.state.showSignUpModal}
                brandNav={this.state.brandNav}
                toggleSignUpModal={this.toggleSignUpModal}
                showTopNav={this.state.showTopNav}
                setShowTopNav={this.setShowTopNav}
                loggedInUser={this.state.loggedInUser}
                setLoggedInUser={this.setLoggedInUser}
                unviewedMessageCount={this.state.unviewedMessageCount}
                unviewedNotificationCount={this.state.unviewedNotificationCount}
                followersFollowingForUser={this.selectUserToFetchFollowersFollowing}
                deviceSize={this.state.deviceSize}
                hamburgerOpen={this.hamburgerOpen}
                toggleMenu={this.state.toggleMenu}
                embed={this.state.embed}
                screenWidth={this.state.screenWidth}
                screenHeight={this.state.screenHeight}
                searchInput={this.state.searchInput}
                populateSearch={this.populateSearch}
                browseFilterOpen={this.state.browseFilterOpen}
                resetBrowseSearch={this.state.resetBrowseSearch}
                handleResetBrowseSearch={this.handleResetBrowseSearch}
                resetSearchBar={this.state.resetSearchBar}
                handleResetSearchBar={this.handleResetSearchBar}
                mobileSearchOpen={this.state.mobileSearchOpen}
                handleMobileSearchOpen={this.handleMobileSearchOpen}
                categories={this.state.categories}
                reloadCreatorDashboardAnalyticsData={this.reloadCreatorDashboardAnalyticsData}
            >
                <ErrorBoundary>
                    {/*
                    this.state.showInstallIcon ? <button className="btn btn-primary" onClick={this.handleInstall}>Install</button> : <span></span>
                */}
                    <Switch>
                        <AuthorizeRoute exact path="/" component={AuthorizedLanding} componentProps={{ showBrandNav, loggedInUser: this.state.loggedInUser, setShowTopNav: this.setShowTopNav }} />
                        <Route
                            path={ApplicationPaths.ApiAuthorizationPrefix}
                            component={ApiAuthorizationRoutes}
                        />

                        <Route path="/dev/site-map" component={SiteMap} />
                        <AuthorizeRoute exact path="/Account/Dashboard" component={AccountIndex} componentProps={standardViewProps} />
                        <AuthorizeRoute exact path="/Account/CreatorDashboard" component={CreatorDashboard} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/CreatorDashboard" component={NewCreatorDashboard} componentProps={{ ...standardViewProps, reloadCreatorDashboardAnalyticsData: this.state.reloadCreatorDashboardAnalyticsData }} />

                        <AuthorizeRoute exact path="/Account/Saves" component={AccountSaves} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/EditProfile" component={EditProfile} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/Interests" component={Interests} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/Brands" component={Brands} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/GoPro" component={GoPro} componentProps={standardViewProps} />

                        {/*<Route exact path="/Account/CreatorApp" component={CreatorApp} componentProps={{ ...standardViewProps, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser, }} />*/}

                        <Route exact path="/Account/CreatorApp"
                            render={(props) => <CreatorApp {...props} showBrandNav={showBrandNav} screenWidth={this.state.screenWidth} screenHeight={this.state.screenHeight} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />

                        <AuthorizeRoute exact path="/Account/AccountSettings" component={AccountSettings} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/ProSettings" component={ProSettings} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/CommunicationSettings" component={CommunicationSettings} componentProps={{ loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser, deviceSize: this.state.deviceSize }} />

                        <AuthorizeRoute exact path="/Account/NotificationsAndMessages"
                            component={NotificationsAndMessages}
                            componentProps={{
                                loggedInUser: this.state.loggedInUser,
                                setLoggedInUser: this.setLoggedInUser,
                            }} />

                        <AuthorizeRoute exact path="/Account/OnboardInterests" component={OnboardInterests} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/OnboardUserType" component={OnboardUserType} componentProps={{ setShowTopNav: this.setShowTopNav, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser, ...standardViewProps }} />

                        <AuthorizeRoute exact path="/Account/OnboardGoPro" component={OnboardGoPro} componentProps={{ setShowTopNav: this.setShowTopNav, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser }} />

                        <AuthorizeRoute exact path="/Account/OnboardWhoToFollow" component={OnboardWhoToFollow} componentProps={{ setShowTopNav: this.setShowTopNav, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser }} />

                        <AuthorizeRoute exact path="/Account/OnboardGettingStarted" component={OnboardGettingStarted} componentProps={{ setShowTopNav: this.setShowTopNav, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser }} />

                        <AuthorizeRoute exact path="/Account/Products" component={MyProducts} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Account/Drafts" component={Drafts} componentProps={standardViewProps} />
                        <AuthorizeRoute exact path="/Account/Analytics" component={Analytics} componentProps={standardViewProps} />
                        <AuthorizeRoute exact path="/Account/BrandAnalytics" component={BrandAnalytics} componentProps={standardViewProps} />

                        <AuthorizeRoute exact path="/Locker/NewLockerPage/:shareCode" component={NewLockerPage} componentProps={standardViewProps} />

                        <Route exact path="/Product/ProductPage/:shareCode" render={(props) => <ProductPage screenWidth={this.state.screenWidth} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/prd/:shareCode" render={(props) => <ProductPage screenWidth={this.state.screenWidth} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal}/>} />
                        <Route exact path="/Embed/ProductPageEmbed/:shareCode" render={(props) => <ProductPageEmbed loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/Embed/prd/:shareCode" render={(props) => <ProductPageEmbed loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/Embed/ProductPageEmbedBrand/:shareCode" render={(props) => <ProductPageEmbedBrand loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/Embed/prdlink/:shareCode" render={(props) => <ProductPageEmbed loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />

                        <Route exact path="/Embed/Brand/prd/:shareCode" render={(props) => <ProductPageEmbedBrand loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/Embed/Brand/prdlink/:shareCode" render={(props) => <ProductPageEmbedBrand loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} deviceSize={this.state.deviceSize} isModal={false} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />

                        <Route exact path="/Merchant/:merchantId" render={(props) => <MerchantPage loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} setShowTopNav={this.setShowTopNav} />} />
                        <Route exact path="/m/:merchantId" render={(props) => <MerchantPage loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} {...props} setShowTopNav={this.setShowTopNav} />} />

                        <Route exact path="/Product/ProductModal" component={ProductModal} toggleSignUpModal={this.toggleSignUpModal} componentProps={{ loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser }} />

                        <AuthorizeRoute exact path="/Product/AddProductModal"
                            component={AddProductOrDeepLinkModal}
                            componentProps={standardViewProps}
                            launchAuthPopup={true}
                        />
                        <AuthorizeRoute exact path="/Product/AddProductOrDeepLinkModal"
                            component={AddProductOrDeepLinkModal}
                            componentProps={{ ...standardViewProps, isModal: false, reloadCreatorDashboardAnalyticsData: this.reloadCreatorDashboardAnalyticsData }}
                            launchAuthPopup={true}
                        />                        
                        <AuthorizeRoute exact path="/Post/EditPost/:shareCode" component={PostPageAuthor} componentProps={standardViewProps} />
                        <AuthorizeRoute exact path="/Post/PostPageAuthor/:shareCode" component={PostPageAuthor} componentProps={standardViewProps} />
                        <AuthorizeRoute exact path="/Blog/EditBlog/:shareCode" component={NewBlogPage} componentProps={standardViewProps} />
                        <AuthorizeRoute exact path="/Blog/NewBlogPage/:shareCode" component={NewBlogPage} componentProps={standardViewProps} />

                        <Route exact path="/Locker/LockerPage/:shareCode"
                            render={(props) => <LockerPage {...props} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/l/:shareCode"
                            render={(props) => <LockerPage {...props} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />} />

                        <Route exact path="/Post/PostPage/:shareCode"
                            render={(props) => <PostPage {...props} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal}/>}
                        />
                        <Route exact path="/p/:shareCode"
                            render={(props) => <PostPage {...props} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Blog/BlogPage/:shareCode"
                            render={(props) => <BlogPage {...props} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/b/:shareCode"
                            render={(props) => <BlogPage {...props} showBrandNav={showBrandNav} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/PostPageEmbed/:shareCode"
                            render={(props) => <PostPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/p/:shareCode"
                            render={(props) => <PostPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/plink/:shareCode"
                            render={(props) => <PostPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/BlogPageEmbed/:shareCode"
                            render={(props) => <BlogPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/b/:shareCode"
                            render={(props) => <BlogPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/blink/:shareCode"
                            render={(props) => <BlogPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        /> 
                        <Route exact path="/Embed/PostPageEmbedBrand/:shareCode"
                            render={(props) => <PostPageEmbedBrand {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/brand/p/:shareCode"
                            render={(props) => <PostPageEmbedBrand {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/brand/plink/:shareCode"
                            render={(props) => <PostPageEmbedBrand {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/Brand/FeaturedContent"
                            render={(props) => <FeaturedContent {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/Brand/FeaturedProducts"
                            render={(props) => <FeaturedProducts {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/Brand/FeaturedPostsAndProducts"
                            render={(props) => <FeaturedPostsAndProducts {...props} screenWidth={this.state.screenWidth} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/LockerPageEmbed/:shareCode"
                            render={(props) => <LockerPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/l/:shareCode"
                            render={(props) => <LockerPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/llink/:shareCode"
                            render={(props) => <LockerPageEmbed {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/Embed/LockerPageEmbedBrand/:shareCode"
                            render={(props) => <LockerPageEmbedBrand {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/brand/l/:shareCode"
                            render={(props) => <LockerPageEmbedBrand {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />
                        <Route exact path="/embed/brand/llink/:shareCode"
                            render={(props) => <LockerPageEmbedBrand {...props} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} deviceSize={this.state.deviceSize} setShowTopNav={this.setShowTopNav} toggleSignUpModal={this.toggleSignUpModal} />}
                        />

                        <AuthorizeRoute exact path="/HomeFeed" component={HomeFeed} componentProps={{ screenWidth: this.state.screenWidth, setShowTopNav: this.setShowTopNav, homeFeedSaveUser: this.state.homeFeedSaveUser, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser, deviceSize: this.state.deviceSize }} />
                        <AuthorizeRoute exact path="/Nav/HomeFeed" component={HomeFeed} navRedirect="HomeFeed" componentProps={{ screenWidth: this.state.screenWidth, setShowTopNav: this.setShowTopNav, loggedInUser: this.state.loggedInUser, setLoggedInUser: this.setLoggedInUser, deviceSize: this.state.deviceSize, setHomeFeedSaveUser: this.state.homeFeedSaveUser }} />

                        <Route exact path="/Explore"
                            render={(props) => <Explore {...props} screenWidth={this.state.screenWidth} timestamp={new Date().getTime().toString()} showBrandNav={showBrandNav} setExploreSaveUser={this.setExploreSaveUser} exploreSaveUser={this.state.exploreSaveUser} setHomeFeedSaveUser={this.setHomeFeedSaveUser} setToggleMenu={this.setToggleMenu} hamOpen={this.state.hamOpen} toggleSignUpModal={this.toggleSignUpModal} loggedInUser={this.state.loggedInUser} setLoggedInUser={this.setLoggedInUser} setShowTopNav={this.setShowTopNav} deviceSize={this.state.deviceSize} />}
                        />
                        <Route exact path="/Nav/Explore"
                            render={(props) => <Redirect to={{ pathname: "/Explore", search: props.location.search }} />}
                        />

                        <Route exact path="/SearchResults/:searchString"
                            render={
                                (props) => <Search {...props} populateSearch={this.populateSearch} showBrandNav={showBrandNav} toggleSignUpModal={this.toggleSignUpModal} {...standardViewProps} />
                            }
                        />

                        <Route exact path="/Browse"
                            render={
                                (props) => <Browse {...props} fetchCategories={this.fetchCategories} mobileSearchOpen={this.state.mobileSearchOpen} handleResetSearchBar={this.handleResetSearchBar} resetBrowseSearch={this.state.resetBrowseSearch} handleResetBrowseSearch={this.handleResetBrowseSearch} setBrowseFilterOpen={this.setBrowseFilterOpen} populateSearch={this.populateSearch} setBrowseSearch={this.setBrowseSearch} toggleSignUpModal={this.toggleSignUpModal} searchInput={this.state.searchInput} {...standardViewProps} />
                            }
                        />

                        <Route exact path="/ChatBot"
                            render={
                                (props) => <ChatBot {...props} toggleSignUpModal={this.toggleSignUpModal} loggedInUser={this.state.loggedInUser} {...standardViewProps} />
                            }
                        />

                        <Route exact path="/Browse/:searchString"
                            render={
                                (props) => <Browse {...props} mobileSearchOpen={this.state.mobileSearchOpen} handleResetSearchBar={this.handleResetSearchBar} resetBrowseSearch={this.state.resetBrowseSearch} handleResetBrowseSearch={this.handleResetBrowseSearch} setBrowseFilterOpen={this.setBrowseFilterOpen} populateSearch={this.populateSearch} setBrowseSearch={this.setBrowseSearch} toggleSignUpModal={this.toggleSignUpModal} searchInput={this.state.searchInput} {...standardViewProps} />
                            }
                        />

                        {/* Done this way so component has access to route props*/}
                        <Route exact path="/Profile/:userName" render={(routeProps) => <Profile {...routeProps} {...standardViewProps} showBrandNav={showBrandNav} setExploreSaveUser={this.setExploreSaveUser} setHomeFeedSaveUser={this.setHomeFeedSaveUser} setToggleMenu={this.setToggleMenu} hamOpen={this.state.hamOpen} toggleSignUpModal={this.toggleSignUpModal} setShowTopNav={this.setShowTopNav} />} />
                        <Route exact path="/Embed/Profile/:userName" render={(routeProps) => <ProfileEmbed {...routeProps} {...standardViewProps} setExploreSaveUser={this.setExploreSaveUser} setHomeFeedSaveUser={this.setHomeFeedSaveUser} setToggleMenu={this.setToggleMenu} hamOpen={this.state.hamOpen} toggleSignUpModal={this.toggleSignUpModal} setShowTopNav={this.setShowTopNav} />} />
                        <Route exact path="/prf/:userName" render={(routeProps) => <Profile {...routeProps} {...standardViewProps} showBrandNav={showBrandNav} setExploreSaveUser={this.setExploreSaveUser} setHomeFeedSaveUser={this.setHomeFeedSaveUser} setToggleMenu={this.setToggleMenu} hamOpen={this.state.hamOpen} toggleSignUpModal={this.toggleSignUpModal} setShowTopNav={this.setShowTopNav} />} />                        
                        <Route exact path="/prf/:userName" render={(routeProps) => <Profile {...routeProps} {...standardViewProps} setExploreSaveUser={this.setExploreSaveUser} setHomeFeedSaveUser={this.setHomeFeedSaveUser} setToggleMenu={this.setToggleMenu} hamOpen={this.state.hamOpen} toggleSignUpModal={this.toggleSignUpModal} setShowTopNav={this.setShowTopNav} />} />
                        <Route exact path="/embed/prf/:userName" render={(routeProps) => <ProfileEmbed {...routeProps} {...standardViewProps} setExploreSaveUser={this.setExploreSaveUser} setHomeFeedSaveUser={this.setHomeFeedSaveUser} setToggleMenu={this.setToggleMenu} hamOpen={this.state.hamOpen} toggleSignUpModal={this.toggleSignUpModal} setShowTopNav={this.setShowTopNav} />} />
                        <Route exact path="/User/:userName" render={(routeProps) => <User {...routeProps} {...standardViewProps} toggleSignUpModal={this.toggleSignUpModal} />} />
                        <Route exact path="/Links/:userName" render={(routeProps) => <Links {...routeProps} {...standardViewProps} />} />

                        {/*<AuthorizeRoute exact path="/Account/Links" component={Links} componentProps={standardViewProps} />*/}



                        <AuthorizeRoute exact path="/Post/Create" component={Create} componentProps={standardViewProps} />

                        <Route exact path="/ErrorTest" render={(props) => <ErrorTest {...props} />} />

                        <Route exact path="/NotFound" render={(props) => <NotFound {...props} {...standardViewProps} />} />

                        <AuthorizeRoute exact path="/instagram/auth" component={InstagramAuth} />

                        <AuthorizeRoute exact path="/tiktok/auth" component={TikTokAuth} />

                        <AuthorizeRoute exact path="/Contact" component={Contact} componentProps={{ loggedInUser: this.state.loggedInUser }} />




                        <AdminRoute exact path="/Admin" component={AdminDashboard} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/ItOperations" component={ItOperations} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/Category" component={Category} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/ManageBrands" component={ManageBrands} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/Analytics/Analytics" component={AdminAnalytics} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CreatorApplications" component={CreatorApplications} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CampaignReport" component={CampaignReport} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/ManageCommissions" component={ManageCommissions} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/BrandApplications" component={BrandApplications} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/Brands" component={AdminBrands} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser}/>

                        <AdminRoute exact path="/Admin/Brand/:id" component={Brand} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser}/>

                        <AdminRoute exact path="/Admin/AffiliateNetworks" component={AffiliateNetworks} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser}/>

                        <AdminRoute exact path="/Admin/AffiliateNetworks/:id" component={AffiliateNetwork} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser}/>

                        <AdminRoute exact path="/Admin/Sectors" component={Sectors} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/Users" component={Users} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CurrentDeals" component={CurrentDeals} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/Featured" component={Featured} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CreatorDashboard" component={CreatorDashboardAdmin} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/PaymentRuns" component={PaymentRuns} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/PaymentRunDetails/:id" component={PaymentRunDetails} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CommissionOverrides" component={CommissionOverrides} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CommissionOverrides/AddUserCommissionOverride" component={AddUserCommissionOverride} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CommissionOverrides/AddMerchantCommissionOverride" component={AddMerchantCommissionOverride} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser} />

                        <AdminRoute exact path="/Admin/CommissionOverrides/AddUserMerchantCommissionOverride" component={AddUserMerchantCommissionOverride} componentProps={standardViewProps} loggedInUser={this.state.loggedInUser}/>
                        <Route exact path="/ErrorPage" component={ErrorPage} componentProps={standardViewProps} />

                        <Route component={NotFound} />

                    </Switch>
                </ErrorBoundary>
            </Layout>
        );
    }
}

export default withRouter(App);

