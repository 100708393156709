import React from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useSwipeable } from "react-swipeable";
import SwipeableProducts from "./SwipeableProducts";
import { trimProductsArray, getProperties } from "../../sharedUtils/postFunctions";
import { NavbarBrand } from 'reactstrap';

import CommentForm from "components/CommentForm";
import CommentTile from "components/CommentTile";
import PostBanner from "components/PostBanner";
import Modal from "components/Modal";
import PostPageProduct from "components/PostPageProduct";
import LoadSpinner from "components/LoadSpinner";
import UserTileGeneral from "components/UserTileGeneral";
import MessageDetails from "../Account/MessageDetails";

import { toggleShareModal, toggleProductPageModal, closeAllModals } from "stateManagement/reducers/modalReducer";

import BookmarkIcon from "components/icons/BookmarkIcon";
import ShareIosIcon from "components/icons/ShareIosIcon";


import blankFeedPost from "../../sharedUtils/blankFeedPost";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { convertToLocaleDateTimeString } from "sharedUtils/timeUtils"
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";
import { getPostShareLink } from "../../sharedUtils/shareLinkPrefixes"
import InformationIcon from "../../components/icons/InformationIcon";

class PostPageEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessageDetails: false,
            likes: {
                count: 0,
                likedByUser: false
            },
            loggedInUser: null,
            isAuthorized: false,
            isFollowing: false,
            commentInput: "",
            shareCode: "",
            post: blankFeedPost,
            showDescriptionModal: false,
            selectedProduct: 0,
            isLoading: true,
            loadingComments: false,
            productPage: 0,
            submittingComment: false,
            shareLink: "",
            savedByUser: false,
            collapsed: true,
            contentStyle: this.notOverflowedContentStyle,
            contentOverflowed: false,
            maxHeight: "41px",
            checkHeight: 1,
            lastTotalHeight: 150,
        }
    }

    trimmedProducts = null;

    commentsTextArea = React.createRef();
    contentRef = React.createRef();
    bioRef = React.createRef();
    titleRef = React.createRef();
    picRef = React.createRef();
    userTileRef = React.createRef();
    editPostButtonRef = React.createRef();
    totalHeight = React.createRef();

    collapsedContentStyle = { marginTop: "0em", maxHeight: "42px", textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "6px", paddingRight: "6px" };
    notOverflowedContentStyle = { marginTop: "0em", maxHeight: "42px", textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "6px", paddingRight: "6px" };
    expandedContentStyle = { marginTop: "0em", paddingLeft: "6px", paddingRight: "6px" };

    async componentDidMount() {

        window.addEventListener('message', this.messageTotalHeight);
        window.addEventListener('resize', this.messageTotalHeight);

        if (this.props.setShowTopNav) {
            this.props.setShowTopNav(false);
        }

        let shareCode = "";
        let post = null;
        let loggedInUser = null;

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }

        if (this.props.isModal !== true) {
            window.scrollTo(scrollOptions);
            this.props.closeAllModals();
        }

        // from feed
        if (this.props.match.params != undefined && this.props.match.params.post != undefined) {
            shareCode = this.props.match.params.post.shareCode;
            post = this.props.match.params.post;
            loggedInUser = this.props.match.params.loggedInUser;
            // from modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params != undefined &&
            this.props.location.state.destinationState.params.post != undefined) {

            shareCode = this.props.location.state.destinationState.params.post.shareCode;
            post = { ...this.props.location.state.destinationState.params.post, comments: [] };
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;

            // from direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }

        let shareLink = await getPostShareLink(shareCode);

        if (loggedInUser == undefined) {
            let data = await this.fetchPost(shareCode, false);
            if (data == undefined || data == null) {
                this.props.history.push("/NotFound");
                //window.location.href = window.location.origin + "/NotFound";
                //this.setState({ post: "notFound", isLoading: false, shareCode, shareLink })
            } else {
                this.setState({ post: data, isLoading: false, shareCode, shareLink });
            }
        } else {
            if (post == undefined) {
                let data = await this.fetchPost(shareCode, true);
                if (data == undefined || data == null) {
                    this.props.history.push("/NotFound");
                    //this.setState({ post: "notFound", isLoading: false, loggedInUser, shareCode, shareLink })
                } else {
                    let isFollowing = await this.fetchIsFollowing(data.user?.id);
                    this.setState({ post: data, isFollowing, isLoading: false, loggedInUser, shareCode, shareLink });
                    this.fetchComments(0, 3);
                }
            } else {
                let trackBaseUrl = this.state.loggedInUser !== undefined ? `api/Post/TrackPostViewAuthorized` : `api/Post/TrackPostView`
                let trackPostViewUrl = `${trackBaseUrl}?userPostId=${post.id}&referrer=${document.referrer}`;

                getRequest(trackPostViewUrl);
                this.setState({ viewTracked: true });

                if (post.comments == undefined || post.comments.length == undefined) {
                    post.comments = [];
                };
                await this.setState({ post: post, isLoading: false, loggedInUser, shareCode, shareLink });

                let isFollowing = await this.fetchIsFollowing(post.user?.id);
                this.setState({ isFollowing });
                if (post.comments.length === 0) {
                    this.fetchComments(0, 3);
                }
            }
            if (!!(post)) {
                this.trimmedProducts = trimProductsArray(post.productItems?.$values).map((i) => { return i; })

            }
        }

        if (this.commentsTextArea?.current != null && this.props.isModal === true && this.props.isCommenting === true) {
            this.commentsTextArea.current.scrollIntoView();
            this.commentsTextArea.current.focus();
        }

        const savedByUser = this.props?.match?.params?.post?.savedByUser;
        this.setState({ savedByUser });

        // THIS IS LOGIC TO CALCULATE THE HEIGHT OF THE CONTENT <p> FOR 'SEE MORE'
        // FOR DEVICE SIZES THAT ARE NOT SMALL - it determines if height of ProfileInfoCard 
        // is less than the expanded < p > and then puts it in contentOverflowed as well as 
        // calculate the height of the of the 'SEE MORE' <p> based on it's expanded size vs.
        // the size of the ProfileInfoCard and applies it to the styling.

        // SMALL DEVICES SET HEIGHT TO 2 LINES OF TEXT WHICH IS ~40px
        let contentOverflowed = false;

        const picHeight = this.picRef?.current?.offsetHeight;
        const userTileHeight = this.userTileRef?.current?.offsetHeight;
        const editButtonHeight = this.editPostButtonRef?.current?.offsetHeight;

        if (this.props.deviceSize === "sm" && this.contentRef?.current?.offsetHeight > 40) {
            this.setState({ contentOverflowed: true, contentStyle: this.collapsedContentStyle })
        }

        this.setState({ contentStyle: this.expandedContentStyle });
        const bioHeight = this.bioRef?.current?.offsetHeight;
        const contentHeight = this.contentRef?.current?.offsetHeight;
        const titleHeight = this.titleRef?.current?.offsetHeight;

        this.setState({ contentStyle: this.notOverflowedContentStyle })

        const largeScreenContentHeight = editButtonHeight == undefined && editButtonHeight == null ? userTileHeight + titleHeight : userTileHeight + editButtonHeight + titleHeight;

        if (this.props.deviceSize !== "sm" && picHeight < largeScreenContentHeight) {
            const overflow = largeScreenContentHeight - picHeight;
            const maxHeight = contentHeight - overflow > 80 ? Math.floor((contentHeight - overflow) / 20) * 20 - 60 + "px" : "41px";

            this.setState({ contentOverflowed: true, maxHeight, contentStyle: this.collapsedContentStyle })
        }

        this.collapsedContentStyle = { marginTop: "0em", maxHeight: this.props.deviceSize === "sm" ? "42px" : this.state.maxHeight, textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "6px", paddingRight: "6px" };
        this.notOverflowedContentStyle = { marginTop: "0em", marginBottom: "0.5em", maxHeight: this.props.deviceSize === "sm" ? "42px" : this.state.maxHeight, textOverflow: "ellipsis", overflow: "hidden", paddingLeft: "6px", paddingRight: "6px" };
        this.expandedContentStyle = { marginTop: "0em", paddingLeft: "6px", paddingRight: "6px" };

        if (this.state.contentOverflowed) {
            this.setState({ contentStyle: this.collapsedContentStyle });
        } else if (!contentOverflowed && this.props.deviceSize !== "sm") {
            this.setState({ contentStyle: this.expandedContentStyle })
        }
    }


    async componentDidUpdate(prevProps, prevState) {
        if (this.props?.match?.params?.shareCode != prevProps?.match?.params?.shareCode) {

            this.setState({ isLoading: true });

            let shareCode = this.props.match.params.shareCode;
            let isAuthorized = this.props.loggedInUser !== null;
            let post = this.props.location.state.destinationState.params.post

            if (post == null || post == undefined) {
                post = await this.fetchPost(shareCode, isAuthorized);

                if (post == null || post == undefined) {
                    this.props.history.push("/NotFound");
                    return -1
                }
                else {
                    let isFollowing = await this.fetchIsFollowing(post.user?.id);

                    await this.fetchLikes(shareCode, isAuthorized);
                    await this.fetchComments(0, 25);
                    this.setState({ isFollowing });
                }
            }



            this.setState({ post, isLoading: false });

        }
        if (prevState.checkHeight !== this.state.checkHeight) {
            let totalHeight = document.documentElement.offsetHeight;

            if (this.state.lastTotalHeight !== totalHeight && totalHeight > 150) {
                this.setState({ lastTotalHeight: totalHeight });

                window.parent.postMessage({
                    id: 'rpFrame-' + this.props.match.params.shareCode,
                    height: totalHeight,
                }, '*');
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.messageTotalHeight);
    }

    messageTotalHeight = () => {
        this.setState({ checkHeight: { ...-this.state.checkHeight } });
    };

    fetchIsFollowing = async (userId) => {
        let url = `/api/Account/IsFollowing?id=${userId}`;
        let data = await getRequest(url);
        return data;
        //this.setState({ isFollowing: data });
    }

    // obsolete
    fetchLikes = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? `/api/post/getLikesAuthorized` : `/api/post/getLikes`;
        let url = `${baseUrl}?shareCode=${shareCode}`;
        let data = await getRequest(url);
        this.setState({ likes: data });
    }

    fetchPost = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? "/api/post/getPostAuthorized" : "/api/post/getPost";
        let url = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}`
        let data = await getRequest(url);

        return data;
        //this.setState({ post: data });
    }

    followAction = async () => {
        let url = `/prf/${this.state.post.user.userName}`
        window.open(url);
    }

    fetchComments = async (skip = this.state.post.comments.length, take = 25) => {
        return;
        this.setState({ loadingComments: true });
        let baseUrl = this.state.loggedInUser !== undefined ? `/api/post/getCommentsAuthorized` : `/api/post/getComments`;
        let url = `${baseUrl}?shareCode=${this.state.shareCode}&skip=${skip}&take=${take}`;
        let data = await getRequest(url);
        let newState = { ...this.state };
        newState.post.comments = [...data, ...this.state.post.comments];

        this.setState({ ...newState, loadingComments: false });
    }

    handleCommentInput = (e) => {
        this.setState({ commentInput: e.target.value });
    }

    handleTextMarkup = (text) => {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal, true);
            }
            else {
                return retVal;
            }
        });

        return markupHandled;
    }

    likePost = async (shareCode) => {
        let num = this.state.post.likedByUser === true ? -1 : 1;
        let newLikes = {
            likeCount: this.state.post.likeCount + num,
            likedByUser: !this.state.post.likedByUser
        }
        let newPost = { ...this.state.post, ...newLikes };

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.likedByUser = newLikes.likedByUser;
            this.props.match.params.post.likeCount = newLikes.likeCount;
        }

        this.setState({ post: newPost }, async () => {
            let url = `/api/post/likePost?shareCode=${shareCode}`
            let data = await postRequest(url);
        });
    }

    likeComment = async (comment, key) => {
        let num = this.state.post.comments[key].likedByUser === true ? -1 : 1;
        let newState = { ...this.state };
        newState.post.comments[key].likes += num;
        newState.post.comments[key].likedByUser = !newState.post.comments[key].likedByUser;

        this.setState(newState, async () => {
            let requestObject = {
                id: comment.id,
                likedByUser: num < 0
            }

            let url = `/api/post/likeComment`;
            let data = await postRequest(url, requestObject);
        });

    }

    savePost = async (shareCode) => {
        let reqBody = { shareCode };

        let route = this.state.post.savedByUser === false ? `/api/post/savePost` : `/api/post/removeSavedPost`;
        let data = await postRequest(route, reqBody);

        let newPost = { ...this.state.post };
        newPost.savedByUser = !this.state.post.savedByUser

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.savedByUser = newPost.savedByUser;
        }

        this.setState({ post: newPost });
    }

    renderBookmarkOverlay = () => {
        const shareCode = this.props?.match?.params?.post?.shareCode;
        return (
            <span className="locker-page-bookmark-overlay position-absolute" onClick={(e) => {
                this.followAction();
            }} style={{ cursor: "pointer" }}>
                <BookmarkIcon height="16" width="16" fill={this.state.savedByUser ? "#e55934" : "none"} />
            </span>);
    }

    renderShareOverlay = () => {
        return (<span className="locker-page-share-overlay position-absolute" onClick={() => {
            this.followAction();
        }} style={{ cursor: "pointer" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }


    onClickMessage = async () => {
        this.setState({ showMessageDetails: true });
    }

    onClickBack = async () => {
        this.setState({ showMessageDetails: false });
    }

    postComment = async () => {
        this.setState({ submittingComment: true });

        let dataToPost = {
            text: this.state.commentInput
        }
        let shareCode = this.state.post.shareCode;
        let url = `/api/post/postComment?shareCode=${shareCode}&postType=${this.state.post.type}`;

        var createdOn = new Date();
        createdOn.setSeconds(createdOn.getSeconds() - 1);
        let dummyComment = {
            likedByUser: false,
            likes: 0,
            replyCount: 0,
            text: this.state.commentInput,
            user: this.state.loggedInUser,
            createdOn: new Date()
        };

        let comments = [...this.state.post.comments];
        comments.push(dummyComment);

        let newState = { ...this.state };
        newState.commentInput = "";
        newState.post.comments = comments;
        newState.post.commentCount += 1;

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.comments = [...newState.post.comments];
            this.props.match.params.post.commentCount = newState.post.commentCount;
            this.props.match.params.post.totalCount = newState.post.totalCount;
        }

        this.setState(newState, async () => {
            var response = await postRequest(url, dataToPost);
            this.setState({ submittingComment: false });
        });
    }

    prevPage = () => {
        let page = this.state.productPage - 1;
        if (page < 0) {
            page = Math.floor(this.state.post.productItems?.$values?.length / 4);
        }

        this.setState({ productPage: page });
    }

    nextPage = () => {
        let page = this.state.productPage + 1;
        if (page * 4 > this.state.post.productItems?.$values?.length) {
            page = 0;
        }

        this.setState({ productPage: page });
    }

    renderProductPagination = () => {
        if (this.state.post.productItems?.$values?.length > 4) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.prevPage()
                        }}>
                        <span className="item-count-prev position-absolute d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <span className="item-count position-absolute d-flex justify-content-center align-items-center">
                        {this.state.post.productItems?.$values?.length}
                    </span>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.nextPage()
                        }}>
                        <span className="item-count-next position-absolute d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    renderInfoOverlay = (product) => {
        return <span className="info-overlay position-absolute" onClick={() => {
            this.props.toggleProductPageModal(product, this.props.loggedInUser, false, () => null)
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934" />
        </span>
    }

    renderProductImages = () => {
        return trimProductsArray(this.state.post.productItems?.$values)?.map((val, i) => {
            return (
                <div key={i} className="col-4 px-1 py-1">
                    <div className="post-page-product">
                        <div className="product-image-in-page">
                            {/*{this.renderInfoOverlay(val)}*/}
                            <PostPageProduct
                                deviceSize={this.props.deviceSize}
                                comment={val.comment}
                                product={val}
                                productKey={i}
                                key={i}
                                showDescription={(i) => this.toggleDescriptionModal(i)}
                                post={this.state.post}
                                loggedInUser={this.state.loggedInUser}
                                parentModal={this.props.isModal === true ? { type: "post", shareCode: this.props.match.params?.post?.shareCode, parentState: { ...this.state.post } } : null}
                            />
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{val.manufacturer?.toLowerCase().includes("rcktemp") ? val.merchant : val.manufacturer}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>${val.price.toFixed(2)}</div></h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderComments = () => {
        let comments = this.state.post.comments;
        if (comments && comments.length && comments.length > 0) {
            return comments.map((comment, i) => {
                return (<CommentTile comment={comment} loggedInUser={this.state.loggedInUser} likeAction={this.likeComment} key={i} commentKey={i} />)
            })
        }

    }

    toggleDescriptionModal = (productIndex) => {
        let newModalState = !this.state.showDescriptionModal;
        this.setState({ showDescriptionModal: newModalState, selectedProduct: productIndex });
    }

    renderDescriptionModal = () => {
        if (this.state.showDescriptionModal === true) {
            return (
                <Modal isOpen={this.state.showDescriptionModal} toggleModal={this.toggleDescriptionModal} showHeader={false}>
                    <div className="share-block" style={{ border: "none", backgroundColor: "transparent" }}>
                        <UserTileGeneral disableLink={true} user={this.state.post.user} subtitle={" "} showCheckMark={false} />
                        <p className="pt-3 mb-0">{this.state.post.productItems?.$values[this.state.selectedProduct].comment}</p>
                    </div>
                </Modal>
            )
        }
    }

    renderCommentForm = () => {
        if (this.state.loggedInUser) {
            return (
                <div className="container comment-block pt-0 pb-0" id="post-page-comments">
                    <div className="row mt-0">
                        <CommentForm
                            user={this.state.loggedInUser}
                            handleCommentInput={this.handleCommentInput}
                            commentText={this.state.commentInput}
                            postComment={this.postComment}
                            commentsTextArea={this.commentsTextArea}
                            submittingComment={this.state.submittingComment}
                        />
                    </div>
                </div>
            )
        }
    }

    renderCommentSectionHeading = () => {
        let commentCount = this.state.post.comments.length;
        let totalComments = this.state.post.commentCount;
        if (commentCount === 0) {
            return "No Comments";
        }
        else if (commentCount === 1) {
            return `${commentCount} comment`
        }
        else {
            return `${commentCount} of ${totalComments} comments`
        }
    }

    renderSectors = () => {
        return this.state.post.sectors?.$values?.map((sector, i) => {
            return (
                <button key={i} className="btn btn-secondary d-flex align-items-center" disabled>
                    {sector.sector.name}
                </button>
            )
        });
    }

    contentSection = () => {
        if (!this.state.contentOverflowed) {
            return (
                <div ref={this.titleRef}>
                    <p className="post-page-text mt-3" style={this.state.contentStyle} ref={this.contentRef} >
                        {this.handleTextMarkup(this.state.post?.content, true, true)}
                    </p>
                </div>
            )
        } else {
            if (this.state.collapsed) {
                return (
                    <>
                        <div className="mt-3 d-flex flex-column" ref={this.titleRef}>
                            <div>
                                <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                                    {this.handleTextMarkup(this.state.post?.content)}
                                </p>

                            </div>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.expandedContentStyle, collapsed: false });
                                }}>
                                    see more
                                </Link>
                            </div>
                        </div></>)

            } else {
                return (
                    <>
                        <div className="mt-3 d-flex flex-column" ref={this.titleRef}>
                            <div>
                                <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                                    {this.handleTextMarkup(this.state.post?.content)}
                                </p>

                            </div>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.collapsedContentStyle, collapsed: true });
                                }}>
                                    see less
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    contentSectionSmall = () => {
        let content = handleCommentMarkup(this.state.post?.content, true, true);
        return (
            <p className="post-page-text mt-3" ref={this.contentRef} style={{ ...this.state.contentStyle, whiteSpace: "nowrap", overflow: "hidden", maxWidth: "500px", textOverflow: "ellipsis" }}>
                {content}
            </p>
        )
    }

    renderFollowButton() {
            return (
                <NavbarBrand onClick={(e) => {
                    e.preventDefault();
                    let url = `/explore`;
                    window.open(url);
                }}
                    id="rp-nav-logo" to="/Nav/Explore" style={{ position: "relative",cursor: "pointer", width: "100px", marginRight: 0 }}>
                    <img src='/images/rockporch-logo.svg' alt='' />
                </NavbarBrand>
            )
    }

    redirectToPostPage = (e) => {
        e.preventDefault();
        let url = `/p/${this.state.shareCode}`;
        window.open(url);
    }

    render() {
        if (this.state.post === "notFound") {
            return <h1>Post not found</h1>
        }

        let commentCount = this.state.post?.comments?.length;
        let postImages = getProperties(this.state.post?.properties)
            .filter(property => property?.parentId == null && (property?.role === 0 || property?.role === 1 || property?.role === 3))
            .map(property => {
                return {
                    url: property?.value,
                    role: property?.role,
                    id: property?.id,
                    width: property?.width,
                    height: property?.height
                }
            });

        let { userName, imageUrl } = this.state.post?.user;
        let webLayouts = ["md", "lg", "xl"];
        let { deviceSize, isModal } = this.props;

        if (this.state.isLoading === true) {
            return <LoadSpinner />
        }

        let parsedDate = convertToLocaleDateTimeString(new Date(this.state.post?.postedOn), false)

        if (this.state.showMessageDetails) {
            return <MessageDetails onClickBack={this.onClickBack} user={this.state.post.user} loggedInUser={this.state.loggedInUser} />
        }

        //if the post isn't published, redirect to HomeFeed
        if ([0, 2, 6].includes(this.state.post.status) === true && this.state.post.user.id !== this.state.loggedInUser?.id && this.props.isModal !== true) {
            return <Redirect to="/HomeFeed" />
        }

        return (
            <section className={`container  "pl-0 pr-0"`} style={{ maxWidth: "500px" }} ref={this.totalHeight}>
                {this.renderDescriptionModal()}

                <div className={`row d-flex align-items-center pl-0 mx-0 pt-0 pb-2`}
                >

                    <div className="col-8 mt-0 pl-2" onClick={this.followAction}>
                        <UserTileGeneral disableLink={true} user={this.state.post.user} subtitle={" "} showCheckMark={false} />
                    </div>
                    <div className="col-4 mt-0 pl-2 text-right">
                        {this.renderFollowButton()}
                    </div>
                </div>
                 
                <div className="row mx-0 mt-0">
                    <div className={`col-12 "mt-0 px-0"`} ref={this.picRef} onClick={this.redirectToPostPage} style={{ cursor: "pointer" }}>
                        {/*{this.renderBookmarkOverlay()}*/}
                        {/*{this.renderShareOverlay()}*/}
                        <PostBanner images={postImages} customClass="post-page-banner-modal"
                            properties={this.state.post.properties} />


                    </div>

                </div>



                <div className={`container container-top-gap mt-1 px-2`}>
                    <button className="btn btn-primary" onClick={this.redirectToPostPage} style={{ marginLeft: "6px", marginTop: "16px" }}>View on RockPorch</button>
                    {
                        this.state.post?.content == null || this.state.post?.content == undefined || this.state.post?.content === "" ?
                            <></> :
                            <div>
                                <div>
                                    {this.contentSectionSmall()}
                                </div>
                            </div>
                    }
                            

                    {/*{*/}
                    {/*    this.state.post?.productItems?.$values?.length > 0 ? (*/}
                    {/*        <div className="container product-grid position-relative pt-0 pb-3">*/}
                    {/*            <div className={`row product-image-gallery ${this.state.post?.productItems?.$values?.length >= 4 ? " justify-content-left" : "justify-content-center"} px-0 position-relative mt-2`}>*/}
                    {/*                {this.renderProductImages()}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    ) : null*/}
                    {/*}*/}

                    {
                        this.state.post?.productItems?.$values?.length > 0 ? (
                            <div>
                                <SwipeableProducts productItems={ this.state.post?.productItems?.$values }/>
                            </div>
                        ) : null
                    }
                </div>
            </section>
        )
    }
}

const mapDispatch = {
    toggleShareModal: toggleShareModal,
    closeAllModals: closeAllModals,
    toggleProductPageModal, toggleProductPageModal
}

export default connect(null, mapDispatch)(PostPageEmbed);