import React from "react";
import { Link } from "react-router-dom";
import UserImageWrapper from "components/UserImageWrapper";
import CheckIcon from "icons/CheckIcon";
import DummyProfilePicture from "components/DummyProfilePicture";
import PenIcon from "components/icons/PenIcon";
import CameraIcon from "components/icons/CameraIcon";
import { Popover, PopoverBody } from "reactstrap";
import { postRequest } from "sharedUtils/httpUtils";

/**
 * 
 * @param {{
 * userInformation:object
 * deviceSize:string
 * isAuthenticated:boolean
 * profileBanner:string,
 * productCount:Number, 
 * postCount:Number, 
 * fetchProducts:Function,
 * fetchActivityFeed:Function,
 * fetchAbout:Function,
 * fetchLockers:Function
 * onClickMessage:Function
 * selectedCase: Number,
 * productCount:Number,
 * lockerCount:Number,
 * isFollowing:boolean,
 * isLoggedInUser:boolean,
 * followAction: Function,
 * showFollowersOrFollowing:Function
 * }} props 
 */

const UserHeader = (props) => {
    let { profileBanner, selectedCase, uploadingProfileBanner } = props;
    let { bio, email, followers, following, id, imageUrl, name, proStatusId, tagLine, userName } = props.userInformation;

    const [isRendered, setIsRendered] = React.useState(false);

    React.useLayoutEffect(() => {
        setIsRendered(true);
    }, []);

    let saveUser = async (loggedInUser) => {
        let serializedUser = {
            name: loggedInUser.name,
            firstName: loggedInUser.firstName,
            lastName: loggedInUser.lastName,
            bio: loggedInUser.bio,
            tagLine: loggedInUser.tagLine,
            website: loggedInUser.website,
            imageUrl: loggedInUser.imageUrl,
            userName: loggedInUser.userName,
            email: loggedInUser.email,

            phoneNumber: loggedInUser.phoneNumber,
            proStatusId: loggedInUser.proStatusId,
            address: loggedInUser.address,
            city: loggedInUser.city,
            state: loggedInUser.state,
            postalCode: loggedInUser.postalCode,
            country: loggedInUser.country,

            onboardingComplete: loggedInUser.onboardingComplete,
            profileBannerUrl: loggedInUser.profileBannerUrl,
            quickStart: loggedInUser.quickStart
        };

        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, serializedUser);
        props.setLoggedInUser(user);
    }

    let renderFollowButton = () => {
        let buttonText = props.isFollowing === true ? "Unfollow " : "Follow "
        let buttonSymbol = props.isFollowing === true ? "-" : "+";
        if (proStatusId === 1) {
            return (
                <button className="btn btn-primary" onClick={props.followAction}>
                    {buttonText}
                    <span style={{ fontSize: "1.5em !important" }}>
                        {buttonSymbol}
                    </span>
                </button>)
        }

    }

    let mapToggleButtons = () => {
        let buttons = [{ name: "About", action: props.fetchAbout, case: 5 }];

        if (props.userInformation.proStatusId === 1) {
            buttons = [
                { name: "Activity", action: props.fetchActivityFeed, case: 1, content: null },
                { name: "Lockers", action: props.fetchLockers, case: 2, content: props.lockerCount || null },
                { name: "Products", action: props.fetchProducts, case: 3, content: props.productCount || null },
                { name: "Posts", action: props.fetchPosts, case: 4, content: props.postCount || null },
                { name: "About", action: props.fetchAbout, case: 5 }
            ];
        }

        return buttons.map((button, i) => {
            let selectedCaseClass = selectedCase === button.case ? "btn-secondary" : "";
            let smallSizeClass = props.deviceSize === "sm" ? "btn-sm" : "";
            return (
                <button key={i}
                    onClick={button.action}
                    className={`btn ${smallSizeClass} ${selectedCaseClass} profile-toggle-buttons`}
                >
                    {button.name} <span>{button.content}</span>
                </button>
            )
        })
    }

    let displayEditOrMessageButtons = () => {
        if (props.isLoggedInUser === true) {
            return (
                <div className="col-md-4 text-center text-md-right button-block">
                    <div className="btn-block d-flex mt-3 profile-header-buttons" style={{ width: "60%", margin: "auto" }}>
                        <Link to="/Account/EditProfile">
                            <button className="btn btn-secondary" id="profile-header-edit-button">
                                Edit Profile <PenIcon />
                            </button>
                        </Link>
                    </div>
                </div>
            )
        }
        else if (props.isLoggedInUser !== true && props.isAuthenticated === true) {
            return (
                <div className="col-md-4 text-center text-md-right button-block">
                    <div className="btn-block d-flex mt-3 profile-header-buttons" style={{ width: "60%", margin: "auto" }}>
                        {renderFollowButton()}
                        <button className="btn btn-primary ml-3" onClick={props.onClickMessage}>Message</button>
                    </div>
                </div>
            )
        }
    }

    let displayBanner = () => {
        if (props.userInformation?.profileBannerUrl?.length === 0 || props.userInformation?.profileBannerUrl === undefined) {
            return (
                <>
                    <UserImageWrapper src={"images/blank-banner.png"} alt="" className="img-fluid" />
                    {
                        props.isLoggedInUser === true ? (
                            <>
                                <button id="cameraButton" className="btn btn-secondary profile-banner-button" onClick={() => document.getElementById("uploadProfileBanner").click()}>
                                    {uploadingProfileBanner ?
                                        <div className={`spinner-border spinner-border-sm`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        : <CameraIcon />} Add Cover Image
                                </button>

                                {isRendered ?
                                    <Popover placement="bottom"
                                        isOpen={
                                            (props.loggedInUser.quickStart == null || !JSON.parse(props.loggedInUser.quickStart).seenProfileBannerReminder)}
                                        target="cameraButton"
                                        trigger="legacy"
                                        toggle={async () => {
                                            let loggedInUser = props.loggedInUser;

                                            if (props.loggedInUser.quickStart == null) {
                                                loggedInUser.quickStart = JSON.stringify({ seenProfileBannerReminder: true });
                                            } else {
                                                let quickStart = JSON.parse(props.loggedInUser.quickStart);
                                                quickStart.seenProfileBannerReminder = true;
                                                loggedInUser.quickStart = JSON.stringify(quickStart);
                                            }

                                            await saveUser(loggedInUser);
                                        }}
                                    >
                                        <PopoverBody>Click here add a banner image to your profile</PopoverBody>
                                    </Popover> : null}
                            </>
                        ) : null
                    }
                </>
            )
        } else {
            return (
                <>
                    <UserImageWrapper src={props.userInformation?.profileBannerUrl} alt="" className="img-fluid" />
                    {
                        props.isLoggedInUser === true ? (
                            <button className="btn btn-secondary profile-banner-button" onClick={() => document.getElementById("uploadProfileBanner").click()}>
                                {uploadingProfileBanner ?
                                    <div className={`spinner-border spinner-border-sm`} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    : <PenIcon />}
                            </button>) : null
                    }
                </>
            )
        }
    }

    function followersFollowingSection() {
        let addedStyles = props.deviceSize === "sm" ? "d-flex px-0 justify-content-around" : "";
        return (
            <div className={`col-md-4 py-0 text-center text-md-left ${addedStyles}`}
                style={
                    props.deviceSize === "sm" ? { width: "50%", margin: "auto" } : null
                }
            >
                <Link to="#" onClick={(e) => {
                    e.preventDefault();
                    props.showFollowersOrFollowing("following")
                }}>
                    <h5>
                        <strong> {following} </strong>Following
                    </h5>
                </Link>



                {
                    props.userInformation.proStatusId === 1 ?
                        (
                            <Link to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.showFollowersOrFollowing("followers");
                                }}>
                                <h5>
                                    <strong> {followers} </strong>Followers
                                </h5>


                            </Link>
                        ) : <></>
                }


            </div>


        )
    }

    let avatarStyle = props.deviceSize === "sm" ? { width: "75px", height: "75px" } : {};

    let backgroundImageStyle = props.deviceSize === "sm" ? {
        backgroundImage: `url(${props.userInformation?.profileBannerUrl || "./images/blank-banner.png"})`,
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundOrigin: "border-box"
    } : {};

    let bannerRowStyle = props.deviceSize === "sm" ? { backgroundColor: "rgba(255,255,255,0.5)", backdropFilter: "blur(2px)" } : {};

    return (
        <>
            <div className={`container ${props.deviceSize == "sm" ? "pt-5 px-1" : ""}`}>

                <div className="row m-0 user-activity align-items-center pb-3">

                    {props.deviceSize != "sm" ? followersFollowingSection() : null}

                    <div className="col-md-4 mt-5 text-center user-activity-info">
                        {imageUrl?.length > 0 ? < UserImageWrapper src={imageUrl} alt="" style={avatarStyle, {display: "inline"}} /> : < UserImageWrapper src="/images/avatar2.svg" alt="" style={avatarStyle} />}
                        <h3>
                            {userName} {proStatusId === 1 || proStatusId === 2 ? <CheckIcon proStatusId={proStatusId} /> : null}
                        </h3>

                        <p className="mb-0">{name}</p>

                        <small>{tagLine}</small>
                    </div>

                    {props.deviceSize === "sm" ? followersFollowingSection() : null}

                    {displayEditOrMessageButtons()}

                </div>

                {props.userInformation.proStatusId === 1 ?
                    <div className={`row activity-list profile-header-buttons-bar py-1 m-0`}
                        style={{ paddingBottom: "5px !important" }}
                    >
                        <div className={`d-flex flex-wrap justify-content-center col-12 ${props.deviceSize == "sm" ? "px-0" : ""}`}>
                            {mapToggleButtons()}
                        </div>
                    </div>
                    : <></>}
            </div>
        </>
    )
}

export default UserHeader;
