import { QueryParameterNames, ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import authService from "components/api-authorization/AuthorizeService";
var jsonref = require("jsonref");



/**
 * @Description checks to see if a user is authorized and returns the authorized user data
 * @returns {object}
 * */
export async function getAuthorizedUserData(ignoreRedirect = false) {

    let token = await authService.getAccessToken();
    let authUser = await authService.getUser();

    if (authUser) {
        let response = await fetch("/api/Account/GetUser",
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
                redirect: 'manual'
            });

        if (response.type === "opaqueredirect") {
            if (ignoreRedirect) {
                return null;
            }

            const link = document.location;
            const returnUrl = `${link.pathname}${link.search}${link.hash}`;
            const redirectUrl = `${ApplicationPaths.IdentityLoginPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

            window.location = redirectUrl;
            return;
        }

        let jsonData = await response.json();
        return await jsonref.parse(jsonData, { scope: window.location }).then(result => result);
    }
}


/**
 * 
 * @param {any} userId
 * @returns {boolean} 
 */
export async function getIsFollowing(userId) {
    let token = await authService.getAccessToken();
    const isFollowing = await fetch("/api/Account/IsFollowing?id=" + userId,
        {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
    let data = await isFollowing.text();
    return data === "true";
}
