import React from "react"


const DoubleBarIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M18.5 5H5.5C4.67157 5 4 5.67157 4 6.5V9.5C4 10.3284 4.67157 11 5.5 11H18.5C19.3284 11 20 10.3284 20 9.5V6.5C20 5.67157 19.3284 5 18.5 5Z" fill="black" fillOpacity="0.6" />
            <path d="M18.5 13H5.5C4.67157 13 4 13.6716 4 14.5V17.5C4 18.3284 4.67157 19 5.5 19H18.5C19.3284 19 20 18.3284 20 17.5V14.5C20 13.6716 19.3284 13 18.5 13Z" fill="black" fillOpacity="0.6" />
        </svg>

    )
}

export default DoubleBarIcon;