import React from "react";

import BlueTickIcon from "./icons/BlueTickIcon";
import { Link } from "react-router-dom";
import ImageWrapper from "components/ImageWrapper";
import DummyProfilePicture from "components/DummyProfilePicture";

/**
 * 
 * @param {{firstNameLastName:string,profileBio:string,userName:string,profilePictureUrl:string,followAction:function, messageAction:function, proStatusId:Number, followers:Number: following:Number, showFollowersFollowing:Function, user:object} props 
 */
const ProfileInfoCard = (props) => {
    return (
        <div className="user-info mb-5">
            <Link to={`/Profile/${props.userName}`}>

                {
                    props.profilePictureUrl ? <ImageWrapper src={props.profilePictureUrl} alt="Avatar" className="avatar mb-3" lazy={false} /> : <ImageWrapper src="/images/avatar2.svg" alt="Avatar" className="avatar mb-3" lazy={false} />
                }

            </Link>
            {
                props.userName ? (<h4 className="overflow-hidden">
                    {props.userName}
                </h4>) : null
            }
            <div className="pt-2">{props.profileBio}</div>            
        </div>
    )
}


// ProfileInfoCard.propTypes = {
//     firstNameLastName: PropTypes.string,
//     profileBio: PropTypes.string,
//     userName: PropTypes.string,
//     profilePictureUrl: PropTypes.string,
//     followAction: PropTypes.func
// };

export default ProfileInfoCard;
