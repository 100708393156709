import React from "react";
import Modal from "components/Modal";
import { Link } from "react-router-dom";

const SignUpModal = (props) => {
    React.useEffect(() => {
        if (props.showModal) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }        
    }, [props]);

    return (
        <Modal isOpen={props.showModal} toggleModal={props.toggleSignUpModal} modalHeaderClass="pt-0 mb-0" modalBodyCustomClass="pb-0">
            <div className="container-fluid sign-up-block" style={{ height: "auto" }}>
                <div className="row mt-0">
                    <div className="col-12 px-0" style={{ paddingBottom: "0.1rem" }}>
                        <div style={{ paddingBottom: "120%", overflow: "hidden", backgroundImage: "url('/images/sign-up-bg.png')", backgroundPosition: "center" }}>
                        </div>
                        <div className="logo position-absolute">
                            <img src="/images/Logo.svg" alt="" />
                            <h1>Tell Your Story. Share in the Adventure.  Sign up today.</h1>
                            <Link to="/authentication/register">
                                <button type="submit" className="btn btn-primary" id="registerSubmit" onClick={() => { props.toggleSignUpModal() }}>Sign Up</button>
                            </Link>
                        </div>
                    </div>
                </div>                
            </div>
        </Modal>
    )
}

export default SignUpModal;
