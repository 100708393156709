import React, { Component } from "react";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import toast, { Toaster } from 'react-hot-toast';
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import TextField from '@material-ui/core/TextField';
import { getRequest, postRequest } from "sharedUtils/httpUtils";

const { DateTime } = require("luxon");

class UserCommissionOverrides extends Component {
    state = {
        // https://localhost:44334/Admin/CommissionOverrides?useMockData=true
        useMockData: document.location.search.toLowerCase().indexOf('useMockData=true'.toLowerCase()) > 0,
        userCommissionOverridesLoading: false,
        userCommissionOverridesSummaryTable: {
            title: "Current and Future Overrides",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "userId",
                    label: "User Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userName",
                    label: "Username",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userDisplayName",
                    label: "User",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let userName = item.userName;
                                let userDisplayName = item.userDisplayName;
                                let url = `/Profile/${userName}`;
                                return <Link to={url}>{userDisplayName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userEmail",
                    label: "User Email",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userCreatedDate",
                    label: "User Signup Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: false, // Column is not visible. Adding so that it will be included in CSV export.
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "userShare",
                    label: "User Share",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (value * 100).toFixed(0) + '%';
                        }
                    }
                },
                {
                    name: "effectiveDate",
                    label: "Effective Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!value) {
                                return value;
                            }
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                }
            ],
            data: [],
            endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'effectiveDate',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/pickers/ */}
                            <TextField
                                id="commissionOverrideSummaryEndDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={this.state.userCommissionOverridesSummaryTable.endDate}
                                className="mx-3"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    let table = this.state.userCommissionOverridesSummaryTable;
                                    table.endDate = e.target.value;
                                    this.setState({ userCommissionOverridesSummaryTable: table });
                                }}
                            />

                            <button className="btn btn-primary mt-auto" type="button" onClick={(e) => {
                                this.handleUpdateEndDate(
                                    selectedRows,
                                    setSelectedRows,
                                    this.state.userCommissionOverridesSummaryTable.data,
                                    this.state.userCommissionOverridesSummaryTable.endDate);
                            }}>
                                Update End Date
                            </button>
                        </div>
                    )
                },
                onRowClick: async (rowData, rowMeta) => {
                    let item = this.state.userCommissionOverridesSummaryTable.data[rowMeta.dataIndex];
                    if (!item) {
                        return;
                    }

                    const userId = item.userId;
                    const userDisplayName = item.userDisplayName;
                    
                    this.getUserCommissionOverrideHistoryByUserId(userId);

                    let userCommissionOverridesHistoryTable = this.state.userCommissionOverridesHistoryTable;
                    userCommissionOverridesHistoryTable.title = `History for ${userDisplayName} (User ID ${userId})`;
                    this.setState({ userCommissionOverridesHistoryTable });

                    this.userCommissionOverridesHistoryTableWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
                }
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        },
        userCommissionOverridesHistoryTable: {
            title: "History",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "userId",
                    label: "User Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userName",
                    label: "Username",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userDisplayName",
                    label: "User",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userCommissionOverridesHistoryTable.data[dataIndex];
                            if (item) {
                                let userName = item.userName;
                                let userDisplayName = item.userDisplayName;
                                let url = `/Profile/${userName}`;
                                return <Link to={url}>{userDisplayName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userEmail",
                    label: "User Email",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userCreatedDate",
                    label: "User Signup Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: false, // Column is not visible. Adding so that it will be included in CSV export.
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "userShare",
                    label: "User Share",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (value * 100).toFixed(0) + '%';
                        }
                    }
                },
                {
                    name: "effectiveDate",
                    label: "Effective Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!value) {
                                return value;
                            }
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                }
            ],
            data: [],
            endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'id',
                    direction: 'desc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/pickers/ */}
                            <TextField
                                id="commissionOverrideSummaryEndDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={this.state.userCommissionOverridesHistoryTable.endDate}
                                className="mx-3"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    let table = this.state.userCommissionOverridesHistoryTable;
                                    table.endDate = e.target.value;
                                    this.setState({ userCommissionOverridesHistoryTable: table });
                                }}
                            />

                            <button className="btn btn-primary mt-auto" type="button" onClick={(e) => {
                                this.handleUpdateEndDate(
                                    selectedRows,
                                    setSelectedRows,
                                    this.state.userCommissionOverridesHistoryTable.data,
                                    this.state.userCommissionOverridesHistoryTable.endDate);
                            }}>
                                Update End Date
                            </button>
                        </div>
                    )
                }
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            }
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        }
    }

    userCommissionOverridesHistoryTableWrapperRef = React.createRef();

    componentDidMount = async () => {
        await this.getUserCommissionOverrides();
    }

    getUserCommissionOverrides = async () => {
        this.setState({ userCommissionOverridesLoading: true });

        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));
            let userCommissionOverrides = this.getMockUserCommissionOverrides();

            let userCommissionOverridesSummaryTable = this.state.userCommissionOverridesSummaryTable;
            userCommissionOverridesSummaryTable.data = userCommissionOverrides;

            this.setState({ userCommissionOverridesSummaryTable: userCommissionOverridesSummaryTable, userCommissionOverridesLoading: false });
            return;
        }

        let getUserCommissionOverridesUrl = '/api/Merchant/GetCurrentUserCommissionOverrides';
        getUserCommissionOverridesUrl += `?offset=${DateTime.now().offset}`;

        let userCommissionOverrides = await getRequest(getUserCommissionOverridesUrl) ?? [];

        let userCommissionOverridesSummaryTable = this.state.userCommissionOverridesSummaryTable;
        userCommissionOverridesSummaryTable.data = userCommissionOverrides;

        this.setState({ userCommissionOverridesSummaryTable: userCommissionOverridesSummaryTable, userCommissionOverridesLoading: false });
    }

    getUserCommissionOverrideHistoryByUserId = async (userId) => {
        this.setState({ userCommissionOverridesLoading: true });

        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));
            let userCommissionOverrideHistoryByUserIdRecords = this.getMockUserCommissionOverrideHistoryByUserId(userId);

            let userCommissionOverridesHistoryTable = this.state.userCommissionOverridesHistoryTable;
            userCommissionOverridesHistoryTable.data = userCommissionOverrideHistoryByUserIdRecords;

            this.setState({ userCommissionOverridesHistoryTable: userCommissionOverridesHistoryTable, userCommissionOverridesLoading: false });
            return;
        }

        let getUserCommissionOverrideHistoryByUserIdUrl = '/api/Merchant/GetUserCommissionOverrideHistoryByUserId';
        getUserCommissionOverrideHistoryByUserIdUrl += `?offset=${DateTime.now().offset}`;
        getUserCommissionOverrideHistoryByUserIdUrl += `&userId=${(userId)}`;

        let userCommissionOverrideHistoryByUserIdRecords = await getRequest(getUserCommissionOverrideHistoryByUserIdUrl) ?? [];

        let userCommissionOverridesHistoryTable = this.state.userCommissionOverridesHistoryTable;
        userCommissionOverridesHistoryTable.data = userCommissionOverrideHistoryByUserIdRecords;

        this.setState({ userCommissionOverridesHistoryTable: userCommissionOverridesHistoryTable, userCommissionOverridesLoading: false });
    }

    updateUserCommissionOverrideEndDate = async (userCommissionOverrideHistoryIds, endDate) => {
        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));

            let userCommissionOverrides = userCommissionOverrideHistoryIds.map((id) => {
                return {
                    id: id,
                    endDate: endDate,
                    modifiedBy: 'jimbo',
                    modifiedDate: new Date().toISOString()
                };
            });

            return userCommissionOverrides;
        }

        let request = {
            userCommissionOverrideHistoryIds: userCommissionOverrideHistoryIds,
            endDate: endDate,
            offset: DateTime.now().offset
        }
        let updateUserCommissionOverrideEndDateUrl = '/api/Merchant/UpdateUserCommissionOverrideEndDate';
        let updatedUserCommissionOverrides = await postRequest(updateUserCommissionOverrideEndDateUrl, request);
        return updatedUserCommissionOverrides;
    }

    getMockUserCommissionOverrides = () => {
        function getRandomDateBetweenDates(startDate, endDate) {
            return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        }

        let mockData = [...Array(4).keys()].map(i => {
            return {
                id: i,
                userId: i,
                userName: `Username${i}`,
                userDisplayName: `User Display Name ${i}`,
                userEmail: `user${i}@example.com`,
                userCreatedDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                userShare: Math.random(),
                effectiveDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                endDate: null,
                createdBy: 'joe',
                modifiedBy: 'greg'
            };
        });

        return mockData;
    }

    getMockUserCommissionOverrideHistoryByUserId = (userId) => {
        function getRandomDateBetweenDates(startDate, endDate) {
            return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        }

        let count = Math.floor(Math.random() * 6);
        let recordCount = count > 0 ? count : 1;
        let mockData = [...Array(recordCount).keys()].map(i => {
            return {
                id: i,
                userId: userId,
                userName: `Username${userId}`,
                userDisplayName: `User Display Name ${userId}`,
                userEmail: `user${userId}@example.com`,
                userCreatedDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                userShare: Math.random(),
                effectiveDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                endDate: i === recordCount - 1 ? null : getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                createdBy: 'joe',
                modifiedBy: 'greg'
            };
        });

        return mockData;
    }

    handleUpdateEndDate = async (selectedRows, setSelectedRows, tableData, endDate) => {
        let parsedDate = DateTime.fromISO(endDate);
        if (!parsedDate || !DateTime.isDateTime(parsedDate)) {
            window.alert("Invalid End Date");
            return;
        }

        var isoEndDate = parsedDate.toJSDate().toISOString();

        if (!selectedRows && !selectedRows.data && selectedRows.data.length === 0) {
            window.alert("Nothing to update as there are no selected rows");
            return;
        }

        let alreadyHasEndDateCount = 0;
        let userCommissionOverrideHistoryIds = [];
        selectedRows.data.forEach(row => {
            let item = tableData[row.dataIndex];
            if (item && item.endDate) {
                alreadyHasEndDateCount++;
            }

            userCommissionOverrideHistoryIds.push(item.id);
        });

        if (alreadyHasEndDateCount > 0) {
            let msg = `Warning: ${alreadyHasEndDateCount} ${alreadyHasEndDateCount == 1 ? 'record already has' : 'records already have'} an End Date set.`;
            msg += ` Are you sure you wish to update ${alreadyHasEndDateCount == 1 ? 'this record' : 'these records'} with a new End Date?`
            if (!window.confirm(msg)) {
                return;
            }
        }

        this.setState({ userCommissionOverridesLoading: true });

        let updatedRecords = await this.updateUserCommissionOverrideEndDate(userCommissionOverrideHistoryIds, isoEndDate);

        function updateData(data) {
            let recordsUpdatedCount = 0;
            let updatedData = [...data];

            updatedRecords.forEach((updatedRecord) => {
                for (let i = 0; i < data.length; i++) {
                    var item = updatedData[i];
                    if (item.id === updatedRecord.id) {
                        recordsUpdatedCount++;
                        updatedData[i] = {
                            ...item,
                            endDate: updatedRecord.endDate,
                            modifiedBy: updatedRecord.modifiedBy,
                            modifiedDate: updatedRecord.modifiedDate
                        }
                    }
                }
            });

            return recordsUpdatedCount > 0 ? updatedData : null;
        }

        // Update any matching Summary table records
        let userCommissionOverridesSummaryTable = this.state.userCommissionOverridesSummaryTable;
        let updatedSummaryData = updateData(userCommissionOverridesSummaryTable.data);
        if (updatedSummaryData) {
            userCommissionOverridesSummaryTable.data = updatedSummaryData;
            this.setState({ userCommissionOverridesSummaryTable: userCommissionOverridesSummaryTable });
        }

        // Update any matching History table records
        let userCommissionOverridesHistoryTable = this.state.userCommissionOverridesHistoryTable;
        let updatedHistoryData = updateData(userCommissionOverridesHistoryTable.data);
        if (updatedHistoryData) {
            userCommissionOverridesHistoryTable.data = updatedHistoryData;
            this.setState({ userCommissionOverridesHistoryTable: userCommissionOverridesHistoryTable });
        }

        setSelectedRows([]);
        this.setState({ userCommissionOverridesLoading: false });
    }

    render() {
        return (
            <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>
                    {
                        this.state.userCommissionOverridesLoading ?
                            <LoadSpinnerOverlay />
                            : <></>
                    }

                    <div className="d-flex m-3">
                        <div className="dropdown ml-auto">
                            <Link to={`/Admin/CommissionOverrides/AddUserCommissionOverride`}>
                                <button className="btn btn-primary" type="button" id="addUserOverrideButton">
                                    Add User Override
                                </button>
                            </Link>
                        </div>
                    </div>

                    <section>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                <ThemeProvider theme={this.state.userCommissionOverridesSummaryTable.getTheme()}>
                                        <MUIDataTable
                                            title={this.state.userCommissionOverridesSummaryTable.title}
                                            data={this.state.userCommissionOverridesSummaryTable.data}
                                            columns={this.state.userCommissionOverridesSummaryTable.columns}
                                            options={this.state.userCommissionOverridesSummaryTable.options}
                                        />
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section ref={this.userCommissionOverridesHistoryTableWrapperRef}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <ThemeProvider theme={this.state.userCommissionOverridesHistoryTable.getTheme()}>
                                        <MUIDataTable
                                            title={this.state.userCommissionOverridesHistoryTable.title}
                                            data={this.state.userCommissionOverridesHistoryTable.data}
                                            columns={this.state.userCommissionOverridesHistoryTable.columns}
                                            options={this.state.userCommissionOverridesHistoryTable.options}
                                        />
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        );
    }
}

export default UserCommissionOverrides;