import React, { useState, useEffect, useRef } from "react";
import RightArrowIcon from "components/icons/RightArrowIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import ImageWrapper from "components/ImageWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { trimProductsArray } from "sharedUtils/postFunctions";
import { useSwipeable } from "react-swipeable";
import { getRequest } from "sharedUtils/httpUtils";
import FeedPostGridItem from "components/Feed/FeedPostGridItem";
import PostPageProduct from "components/PostPageProduct";
import SaleIcon from "components/icons/SaleIcon";
import ThumbsUpIcon from "components/icons/ThumbsUpGeneric";



/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const FeaturedProducts = (props) => {
    const [productsPage, setProductsPage] = useState(0);
    const [products, setProducts] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [postItems, setPostItems] = useState([
        { type: "post", shareCode: "3zGPAidJU6wxx" },
        { type: "locker", shareCode: "MSMKwYGmdCErk" },
        { type: "post", shareCode: "cqTpkmHEVBbtA" },
        { type: "post", shareCode: "bPzr6e2SvD6q6" },
        { type: "post", shareCode: "A7hR8O5mcjNKb" },
        { type: "post", shareCode: "D1bDP4pXYdDrp" },
        { type: "post", shareCode: "mapIiS3TnlqRj" },
        { type: "post", shareCode: "7W7jj3AL2g7ZN" },
        { type: "post", shareCode: "SFTawDbC1nBjJ" },
        { type: "post", shareCode: "Wd24A2dMwwUBa" },
    ]);

    const [intervalId, setIntervalId] = useState(null); // Add state for intervalId
    const [lastTotalHeight, setLastTotalHeight] = useState(150);

    const getHeightMessage = (e) => {
        if (e.data === 'get-rp-featured-products-height') {
            messageTotalHeight();
        }
    }

    const messageTotalHeight = () => {
        setCheckHeight({ ...-checkHeight });
    };

    useEffect(() => {
        props.setShowTopNav(false);
        populateData();

        window.addEventListener('message', getHeightMessage);
        window.addEventListener('resize', messageTotalHeight);
        return () => {
            window.removeEventListener('resize', messageTotalHeight);
            window.removeEventListener('message', getHeightMessage);
        };
    }, []); 

    useEffect(() => {
        if (props.deviceSize === "sm") {
            setItemsPerPage(2);
        } else {
            setItemsPerPage(4)
        }
    }, [props.deviceSize]);

    const [checkHeight, setCheckHeight] = useState(1);
    useEffect(() => {
        let totalHeight = document.documentElement.offsetHeight;

        if (lastTotalHeight !== totalHeight && totalHeight > 150) {
            setLastTotalHeight(totalHeight);

            window.parent.postMessage({
                id: 'rp-featured-products',
                height: totalHeight,
            }, '*');
        }
    }, [checkHeight])

    const populateData = async () => {
        let url = `api/Featured/GetFeaturedProducts`;
        let data = await getRequest(url);

        if (data === undefined) {
            setProducts([]);
        }
        else {
            setProducts(data);
            setTimeout(messageTotalHeight, 1000);
        }
    }


    const renderSaleOverlay = (sale) => {
        if (sale) {
            return (
                <span className="on-sale-icon">
                    <SaleIcon />
                </span >
            );
        }
    }

    const resetInterval = () => {
        clearInterval(intervalId);
        const newIntervalId = setInterval(() => {
            nextPage();
        }, 5000);
        setIntervalId(newIntervalId);
    }

    const productsPageHandlers = useSwipeable({
        onSwipedLeft: () => {
            nextPage();
            resetInterval();
        },
        onSwipedRight: () => {
            prevPage();
            resetInterval();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const prevPage = () => {
        if (products.length > itemsPerPage) {
            let page = productsPage - 1;
            if (page < 0) {
                page = Math.floor((products.length - 1) / itemsPerPage);
            }
            setProductsPage(page);
            resetInterval();
        }
    }

    const nextPage = () => {
        setProductsPage(prevPage => {
            let page = prevPage + 1;
            if (page * itemsPerPage >= products.length) {
                page = 0;
            }

            return page;
        });

        resetInterval();
    }

    useEffect(() => {
        if (products.length > 0 && !intervalId) { 
            resetInterval();
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [products, intervalId]);


    const renderProductPagination = () => {
        if (products && products.length > itemsPerPage) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            prevPage()
                        }}>
                        <span className="item-count-prev d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            nextPage()
                        }}>
                        <span className="item-count-next d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    const toTitleCase = (string) => {
        return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    const renderThumbsUpOverlay = (product, singleColumn = false) => {
        if (product.creatorCount > 0) {
            let url = "/Product/ProductPage/" + product.shareCode + "?r=0&b=1&showBrandNav=true";
            return (
                <a href={url} target="_blank" style={{ cursor: "pointer" }}>
                    <span className="thumbs-up-overlay position-absolute" style={{ cursor: "pointer", left: singleColumn ? "25px" : "22px", top: singleColumn ? "22px" : "" }}>
                        <ThumbsUpIcon height="16" width="16" fill={"#e55934"} />
                        <span style={{ marginLeft: "5px", paddingTop: "2px" }}>{!!(product.creatorCount) ? product.creatorCount : ""}</span>
                    </span>
                </a>
            )
        }
    }

    let renderProducts = () => {
        if (products && products?.length > 0) {
            return products.slice(productsPage * itemsPerPage, productsPage * itemsPerPage + itemsPerPage).map((product, i) => {
                //let redirectUrl = post.type === 2 ? `/Locker/LockerPage/${post?.shareCode}` : `/post/postPage/${post?.shareCode}`

                let sizes = ["sm"];
                let deviceSize = sizes.includes(props.deviceSize);
                return (
                    <div key={i} className="col-md-3 px-1 py-1 mx-0 my-0 grid-item ml-2 mr-2" style={{ padding: "1px", maxWidth: itemsPerPage < 4 ? "42%" : "22%", boxShadow: "rgba(229, 89, 52, 0.4) 0px 5px 15px", borderRadius: "15px" }}>
                        {renderThumbsUpOverlay(product)}
                        <div className="product-in-feed">
                            {deviceSize ? renderSaleOverlay(product.sale) : <></>}
                            <PostPageProduct
                                product={product}
                                loggedInUser={props.loggedInUser}
                                brandEmbed={true}
                            />
                        </div>

                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap", minHeight: "25px" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="position-relative" style={{ maxWidth: "1px" }}>{product.manufacturer?.toLowerCase().includes("rcktemp") ? product.merchant : product.manufacturer}{product.sale && !deviceSize ? <SaleIcon style={{ marginLeft: "3px" }} /> : ""}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}>
                                    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                            {toTitleCase(product.name)}
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className={`${product.onSale ? "mr-1": ""}`} >{product.sale && !!(product.salePrice) ? "$" + product.salePrice.toFixed(2) : <></>}</div><div className={`${product.sale && !!(product.salePrice) ? "on-sale-price" : ""}`}>${product.sale && !!(product.salePrice) && !!(product.originalPrice) ? product.originalPrice.toFixed(2) : product.price.toFixed(2)}</div></div></h5>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    let gridItem = () => {
        if (products?.length > 0) {
            return (
                <div className="explore-feed-wrapper" id="contentDiv" style={{ borderRadius: "20px", overflow: "hidden" }}>
                    <div className="feed-slider-products mt-3 pb-0" style={props.style}>
                        <div {...productsPageHandlers} className={`row mx-0 product-image-gallery justify-content-center px-2 position-relative pt-0 ${props.brand ? "mb-0" : "mb-3"} mt-0`} style={{ width: "100%" }}>
                            {renderProductPagination()}
                            {renderProducts()}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        gridItem()
        //    <PostPageEmbedBrand shareCodeDirect={"3zGPAidJU6wxx"} />
    //    <>Featured Products</>
    )
}
const mapDispatch = { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(FeaturedProducts);