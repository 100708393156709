import React, { useState } from "react";
import FormTextArea from "components/FormTextArea";
import ActionButton from "components/ActionButton";

/**
 * 
 * @param {{productComment:string,cancel:Function,save:Function}} props
 */
const EditProductCommentForm = (props) => {
    let [formInput, setFormInput] = useState(props.productComment);
    let [isSaving, setIsSaving] = useState(false);
    return (
        <div className="row mt-0 mx-0">
            <div className="col-md-12">
                <h5 className="mb-3">Product Notes</h5>

                <div className="form">
                    <FormTextArea
                        name="description"
                        handleInput={(e) => setFormInput(e.target.value)}
                        type="textarea"
                        value={formInput}
                        rows={4}
                        inputStyle={{ backgroundColor: "#e9ecef" }}
                        placeholder="Explain why this product is relevant to the locker (e.g., what is it used for, why is it included, how does it make this pursuit easier, etc.)"
                    />

                    <div className="form-group button-block text-md-right mb-0">
                        <button className="btn btn-secondary mt-1" onClick={props.cancel}>
                            Cancel
                        </button>


                        <ActionButton className="btn btn-primary ml-3 mt-1" onClick={async () => {
                            setIsSaving(true);
                            await props.save(formInput);
                            setIsSaving(false);
                        }}
                            isLoading={isSaving}
                            isDisabled={!formInput.length > 0}
                        >
                            Add Note
                        </ActionButton>


                    </div>
                </div>
            </div>
        </div>

    )
}

export default EditProductCommentForm;