import React, { useState } from "react";
import RightArrowIcon from "components/icons/RightArrowIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import ImageWrapper from "components/ImageWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { trimProductsArray } from "sharedUtils/postFunctions";
import { useSwipeable } from "react-swipeable";


/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const SwipeableProducts = (props) => {
    let history = useHistory();
    let [currentDisplayedMedia, setMedia] = useState(0);
    const [productPage, setProductPage] = useState(0);

    const { productItems } = props.productItems;
    const trimmedProducts = !!(props.product) ? props.productItems : trimProductsArray(props.productItems).map((i) => { return i; })

    const productPageHandlers = useSwipeable({
        onSwipedLeft: () => nextPage(),
        onSwipedRight: () => prevPage(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });


    const prevPage = () => {
        if (trimmedProducts.length > 3) {
            let page = productPage - 1;
            if (page < 0) {
                page = Math.floor((trimmedProducts.length - 1) / 3);
            }

            setProductPage(page);
        }
    }

    const nextPage = () => {
        if (trimmedProducts.length > 3) {
            let page = productPage + 1;
            if (page * 3 >= trimmedProducts.length) {
                page = 0;
            }

            setProductPage(page);
        }
    }


    const renderProductPagination = () => {
        if (trimmedProducts && trimmedProducts.length > 3) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            prevPage()
                        }}>
                        <span className="item-count-prev d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            nextPage()
                        }}>
                        <span className="item-count-next d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    let renderProducts = () => {
        if (trimmedProducts && trimmedProducts?.length > 0) {
            return trimmedProducts.slice(productPage * 3, productPage * 3 + 3).map((product, i) => {
                const url = "/Product/ProductPage/" + product.shareCode + "?r=1";
                if (!!(props.redirect)) {
                    return (
                        <div key={i} className="col-4 px-0">
                            <div className="product-image" onClick={e => props.redirect(e)}>
                                <ImageWrapper
                                    src={product.imageUrl}
                                    alt={product.name}
                                    key={i}
                                    className="feed-post-product-image"
                                />
                            </div>
                        </div>
                    )
                }
                return (
                    <div key={i} className="col-4 px-0">
                        <div className="product-image">
                            <a href={url} target={product.merchant} style={{ cursor: "pointer" }}>
                                <ImageWrapper
                                    src={product.imageUrl}
                                    alt={product.name}
                                    key={i}
                                    className="feed-post-product-image"
                                />
                            </a>
                        </div>
                    </div>
                )
            })
        }
    }

    let gridItem = () => {
        if (trimmedProducts?.length > 0) {
            return (
                <div style={{ borderRadius: "20px", overflow: "hidden" }}>
                    <div className="feed-slider-products" style={props.style}>
                        <div {...productPageHandlers} className={`row mx-0 product-image-gallery justify-content-center px-2 position-relative pt-0 ${props.brand ? "mb-0" : "mb-3"} mt-0`} style={{ width: "100%" }}>
                            {renderProductPagination()}
                            {renderProducts()}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        gridItem()
        )
}
const mapDispatch = { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(SwipeableProducts);