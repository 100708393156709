import React, { Component } from "react";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import toast, { Toaster } from 'react-hot-toast';
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import TextField from '@material-ui/core/TextField';
import { getRequest, postRequest } from "sharedUtils/httpUtils";

const { DateTime } = require("luxon");

class MerchantCommissionOverrides extends Component {
    state = {
        // https://localhost:44334/Admin/CommissionOverrides?useMockData=true
        useMockData: document.location.search.toLowerCase().indexOf('useMockData=true'.toLowerCase()) > 0,
        merchantCommissionOverridesLoading: false,
        merchantCommissionOverridesSummaryTable: {
            title: "Current and Future Overrides",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "merchantAffiliateProgramManagerId",
                    label: "Merchant Affiliate Program Manager Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerId",
                    label: "Affiliate Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.merchantCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let affiliateProgramManagerId = item.affiliateProgramManagerId;
                                let affiliateProgramManagerName = item.affiliateProgramManagerName;
                                let url = `/Admin/AffiliateNetworks/${affiliateProgramManagerId}`;
                                return <Link to={url}>{affiliateProgramManagerName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "merchantId",
                    label: "Merchant Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "merchantName",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.merchantCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let merchantId = item.merchantId;
                                let merchantName = item.merchantName;
                                let url = `/Admin/Brand/${merchantId}`;
                                return <Link to={url}>{merchantName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userShare",
                    label: "User Share",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (value * 100).toFixed(0) + '%';
                        }
                    }
                },
                {
                    name: "effectiveDate",
                    label: "Effective Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!value) {
                                return value;
                            }
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                }
            ],
            data: [],
            endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'effectiveDate',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/pickers/ */}
                            <TextField
                                id="commissionOverrideSummaryEndDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={this.state.merchantCommissionOverridesSummaryTable.endDate}
                                className="mx-3"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    let table = this.state.merchantCommissionOverridesSummaryTable;
                                    table.endDate = e.target.value;
                                    this.setState({ merchantCommissionOverridesSummaryTable: table });
                                }}
                            />

                            <button className="btn btn-primary mt-auto" type="button" onClick={(e) => {
                                this.handleMerchantUpdateEndDate(
                                    selectedRows,
                                    setSelectedRows,
                                    this.state.merchantCommissionOverridesSummaryTable.data,
                                    this.state.merchantCommissionOverridesSummaryTable.endDate);
                            }}>
                                Update End Date
                            </button>
                        </div>
                    )
                },
                onRowClick: async (rowData, rowMeta) => {
                    let item = this.state.merchantCommissionOverridesSummaryTable.data[rowMeta.dataIndex];
                    if (!item) {
                        return;
                    }

                    const merchantAffiliateProgramManagerId = item.merchantAffiliateProgramManagerId;
                    const affiliateProgramManagerId = item.affiliateProgramManagerId;
                    const affiliateProgramManagerName = item.affiliateProgramManagerName;
                    const merchantId = item.merchantId;
                    const merchantName = item.merchantName;

                    this.getMerchantCommissionOverrideHistory(merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId);

                    let merchantCommissionOverridesHistoryTable = this.state.merchantCommissionOverridesHistoryTable;
                    merchantCommissionOverridesHistoryTable.title = `History for ${merchantName} (Affiliate: ${affiliateProgramManagerName})`;
                    this.setState({ merchantCommissionOverridesHistoryTable });

                    this.merchantCommissionOverridesHistoryTableWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
                }
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        },
        merchantCommissionOverridesHistoryTable: {
            title: "History",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "merchantAffiliateProgramManagerId",
                    label: "Merchant Affiliate Program Manager Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerId",
                    label: "Affiliate Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.merchantCommissionOverridesHistoryTable.data[dataIndex];
                            if (item) {
                                let affiliateProgramManagerId = item.affiliateProgramManagerId;
                                let affiliateProgramManagerName = item.affiliateProgramManagerName;
                                let url = `/Admin/AffiliateNetworks/${affiliateProgramManagerId}`;
                                return <Link to={url}>{affiliateProgramManagerName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "merchantId",
                    label: "Merchant Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "merchantName",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.merchantCommissionOverridesHistoryTable.data[dataIndex];
                            if (item) {
                                let merchantId = item.merchantId;
                                let merchantName = item.merchantName;
                                let url = `/Admin/Brand/${merchantId}`;
                                return <Link to={url}>{merchantName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userShare",
                    label: "User Share",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (value * 100).toFixed(0) + '%';
                        }
                    }
                },
                {
                    name: "effectiveDate",
                    label: "Effective Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!value) {
                                return value;
                            }
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                }
            ],
            data: [],
            endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'id',
                    direction: 'desc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/pickers/ */}
                            <TextField
                                id="commissionOverrideSummaryEndDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={this.state.merchantCommissionOverridesHistoryTable.endDate}
                                className="mx-3"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    let table = this.state.merchantCommissionOverridesHistoryTable;
                                    table.endDate = e.target.value;
                                    this.setState({ merchantCommissionOverridesHistoryTable: table });
                                }}
                            />

                            <button className="btn btn-primary mt-auto" type="button" onClick={(e) => {
                                this.handleMerchantUpdateEndDate(
                                    selectedRows,
                                    setSelectedRows,
                                    this.state.merchantCommissionOverridesHistoryTable.data,
                                    this.state.merchantCommissionOverridesHistoryTable.endDate);
                            }}>
                                Update End Date
                            </button>
                        </div>
                    )
                }
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            }
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        }
    }

    merchantCommissionOverridesHistoryTableWrapperRef = React.createRef();

    componentDidMount = async () => {
        await this.getMerchantCommissionOverrides();
    }

    getMerchantCommissionOverrides = async () => {
        this.setState({ merchantCommissionOverridesLoading: true });

        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));
            let merchantCommissionOverrides = this.getMockMerchantCommissionOverrides();

            let merchantCommissionOverridesSummaryTable = this.state.merchantCommissionOverridesSummaryTable;
            merchantCommissionOverridesSummaryTable.data = merchantCommissionOverrides;

            this.setState({ merchantCommissionOverridesSummaryTable: merchantCommissionOverridesSummaryTable, merchantCommissionOverridesLoading: false });
            return;
        }

        let getCurrentMerchantAffiliateProgramManagerOverrides = '/api/Merchant/GetCurrentMerchantAffiliateProgramManagerOverrides';
        getCurrentMerchantAffiliateProgramManagerOverrides += `?offset=${DateTime.now().offset}`;

        let merchantCommissionOverrides = await getRequest(getCurrentMerchantAffiliateProgramManagerOverrides) ?? [];

        let merchantCommissionOverridesSummaryTable = this.state.merchantCommissionOverridesSummaryTable;
        merchantCommissionOverridesSummaryTable.data = merchantCommissionOverrides;

        this.setState({ merchantCommissionOverridesSummaryTable: merchantCommissionOverridesSummaryTable, merchantCommissionOverridesLoading: false });
    }

    getMerchantCommissionOverrideHistory = async (merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId) => {
        this.setState({ merchantCommissionOverridesLoading: true });

        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));
            let merchantCommissionOverrideHistoryRecords = this.getMockMerchantCommissionOverrideHistory(merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId);

            let merchantCommissionOverridesHistoryTable = this.state.merchantCommissionOverridesHistoryTable;
            merchantCommissionOverridesHistoryTable.data = merchantCommissionOverrideHistoryRecords;

            this.setState({ merchantCommissionOverridesHistoryTable: merchantCommissionOverridesHistoryTable, merchantCommissionOverridesLoading: false });
            return;
        }

        let getMerchantCommissionOverrideHistoryUrl = '/api/Merchant/GetMerchantAffiliateProgramManagerOverrideHistory';
        getMerchantCommissionOverrideHistoryUrl += `?offset=${DateTime.now().offset}`;
        getMerchantCommissionOverrideHistoryUrl += `&merchantAffiliateProgramManagerId=${(merchantAffiliateProgramManagerId)}`;

        let merchantCommissionOverrideHistoryRecords = await getRequest(getMerchantCommissionOverrideHistoryUrl) ?? [];

        let merchantCommissionOverridesHistoryTable = this.state.merchantCommissionOverridesHistoryTable;
        merchantCommissionOverridesHistoryTable.data = merchantCommissionOverrideHistoryRecords;

        this.setState({ merchantCommissionOverridesHistoryTable: merchantCommissionOverridesHistoryTable, merchantCommissionOverridesLoading: false });
    }

    updateMerchantCommissionOverrideEndDate = async (ids, endDate) => {
        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));

            let merchantCommissionOverrides = ids.map((id) => {
                return {
                    id: id,
                    endDate: endDate,
                    modifiedBy: 'jimbo',
                    modifiedDate: new Date().toISOString()
                };
            });

            return merchantCommissionOverrides;
        }

        let request = {
            merchantAffiliateProgramManagerOverrideHistoryIds: ids,
            endDate: endDate,
            offset: DateTime.now().offset
        }
        let url = '/api/Merchant/UpdateMerchantAffiliateProgramManagerOverrideHistoryEndDate';
        let updatedmerchantCommissionOverrides = await postRequest(url, request);
        return updatedmerchantCommissionOverrides;
    }

    getMockMerchantCommissionOverrides = () => {
        function getRandomDateBetweenDates(startDate, endDate) {
            return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        }

        let mockData = [...Array(4).keys()].map(i => {
            return {
                id: i,
                merchantAffiliateProgramManagerId: i + 57,
                affiliateProgramManagerId: i,
                affiliateProgramManagerName: `Affiliate Name ${i}`,
                merchantId: i + 134,
                merchantName: `Merchant Name ${i + 134}`,
                userShare: Math.random(),
                effectiveDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                endDate: null,
                createdBy: 'joe',
                modifiedBy: 'greg'
            };
        });

        return mockData;
    }

    getMockMerchantCommissionOverrideHistory = (merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId) => {
        function getRandomDateBetweenDates(startDate, endDate) {
            return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        }

        let count = Math.floor(Math.random() * 4);
        let recordCount = count > 0 ? count : 1;
        let mockData = [...Array(recordCount).keys()].map(i => {
            return {
                id: i + 1001,
                merchantAffiliateProgramManagerId: merchantAffiliateProgramManagerId,
                affiliateProgramManagerId: affiliateProgramManagerId,
                affiliateProgramManagerName: `Affiliate Name ${affiliateProgramManagerId}`,
                merchantId: merchantId,
                merchantName: `Merchant Name ${merchantId}`,
                userShare: Math.random(),
                effectiveDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                endDate: null,
                createdBy: 'joe',
                modifiedBy: 'greg'
            };
        });

        return mockData;
    }

    handleMerchantUpdateEndDate = async (selectedRows, setSelectedRows, tableData, endDate) => {
        let parsedDate = DateTime.fromISO(endDate);
        if (!parsedDate || !DateTime.isDateTime(parsedDate)) {
            window.alert("Invalid End Date");
            return;
        }

        var isoEndDate = parsedDate.toJSDate().toISOString();

        if (!selectedRows && !selectedRows.data && selectedRows.data.length === 0) {
            window.alert("Nothing to update as there are no selected rows");
            return;
        }

        let alreadyHasEndDateCount = 0;
        let merchantAffiliateProgramManagerOverrideHistoryIds = [];
        selectedRows.data.forEach(row => {
            let item = tableData[row.dataIndex];
            if (item && item.endDate) {
                alreadyHasEndDateCount++;
            }

            merchantAffiliateProgramManagerOverrideHistoryIds.push(item.id);
        });

        if (alreadyHasEndDateCount > 0) {
            let msg = `Warning: ${alreadyHasEndDateCount} ${alreadyHasEndDateCount == 1 ? 'record already has' : 'records already have'} an End Date set.`;
            msg += ` Are you sure you wish to update ${alreadyHasEndDateCount == 1 ? 'this record' : 'these records'} with a new End Date?`
            if (!window.confirm(msg)) {
                return;
            }
        }

        this.setState({ merchantCommissionOverridesLoading: true });

        let updatedRecords = await this.updateMerchantCommissionOverrideEndDate(merchantAffiliateProgramManagerOverrideHistoryIds, isoEndDate);

        function updateData(data) {
            let recordsUpdatedCount = 0;
            let updatedData = [...data];

            updatedRecords.forEach((updatedRecord) => {
                for (let i = 0; i < data.length; i++) {
                    var item = updatedData[i];
                    if (item.id === updatedRecord.id) {
                        recordsUpdatedCount++;
                        updatedData[i] = {
                            ...item,
                            endDate: updatedRecord.endDate,
                            modifiedBy: updatedRecord.modifiedBy,
                            modifiedDate: updatedRecord.modifiedDate
                        }
                    }
                }
            });

            return recordsUpdatedCount > 0 ? updatedData : null;
        }

        // Update any matching Summary table records
        let merchantCommissionOverridesSummaryTable = this.state.merchantCommissionOverridesSummaryTable;
        let updatedSummaryData = updateData(merchantCommissionOverridesSummaryTable.data);
        if (updatedSummaryData) {
            merchantCommissionOverridesSummaryTable.data = updatedSummaryData;
            this.setState({ merchantCommissionOverridesSummaryTable: merchantCommissionOverridesSummaryTable });
        }

        // Update any matching History table records
        let merchantCommissionOverridesHistoryTable = this.state.merchantCommissionOverridesHistoryTable;
        let updatedHistoryData = updateData(merchantCommissionOverridesHistoryTable.data);
        if (updatedHistoryData) {
            merchantCommissionOverridesHistoryTable.data = updatedHistoryData;
            this.setState({ merchantCommissionOverridesHistoryTable: merchantCommissionOverridesHistoryTable });
        }

        setSelectedRows([]);
        this.setState({ merchantCommissionOverridesLoading: false });
    }

    render() {
        return (
            <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>
                {
                    this.state.merchantCommissionOverridesLoading ?
                        <LoadSpinnerOverlay />
                        : <></>
                }

                <div className="d-flex m-3">
                    <div className="dropdown ml-auto">
                        <Link to={`/Admin/CommissionOverrides/AddMerchantCommissionOverride`}>
                            <button className="btn btn-primary" type="button" id="addMerchantOverrideButton">
                                Add Merchant Override
                                </button>
                        </Link>
                    </div>
                </div>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.merchantCommissionOverridesSummaryTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.merchantCommissionOverridesSummaryTable.title}
                                        data={this.state.merchantCommissionOverridesSummaryTable.data}
                                        columns={this.state.merchantCommissionOverridesSummaryTable.columns}
                                        options={this.state.merchantCommissionOverridesSummaryTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>

                <section ref={this.merchantCommissionOverridesHistoryTableWrapperRef}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.merchantCommissionOverridesHistoryTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.merchantCommissionOverridesHistoryTable.title}
                                        data={this.state.merchantCommissionOverridesHistoryTable.data}
                                        columns={this.state.merchantCommissionOverridesHistoryTable.columns}
                                        options={this.state.merchantCommissionOverridesHistoryTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default MerchantCommissionOverrides;