import React from "react";

import AccountBanner from "../../components/AccountBanner"
import { Popover, PopoverBody } from "reactstrap";

import { getRequest, postRequest } from "sharedUtils/httpUtils";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from "lodash";
const { DateTime } = require("luxon");


class AddUserCommissionOverride extends React.Component {
    state = {
        selected: [],
        options: [],
        value: [],
        isLoading: false,
        profilePicUrl: "",
        selectedProStatusId: "",
        userCommissionOverrideHistoryRecords: [],
        userShare: "",
        effectiveDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,

        formData: {
            displayName: "",
            profileUrl: "",
            bio: "",
            tagLine: "",
            proStatusId: "",
            isAdmin: "",
            id: ""
        }
    };

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/CommissionOverrides', text: 'Commission Overrides' },
        { link: '/Admin/CommissionOverrides/AddUserCommissionOverride', text: 'Add User Commission Override', active: true }
    ];

    proStatusIds = [
        { value: 0, label: 'General User' },
        { value: 1, label: 'Creator' },
        { value: 2, label: 'Merchant Partner' }];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    fetchUsers = async (query) => {
        this.setState({ isLoading: true });

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ options: users, isLoading: false });
    }

    onSubmit = async (event) => {
        event.preventDefault();

        if (this.state.userCommissionOverrideHistoryRecords.length > 0) {
            let recordsWithoutEndDate = this.state.userCommissionOverrideHistoryRecords.filter(x => !x.endDate);
            let count = recordsWithoutEndDate.length;
            if (count > 0) {
                let isAre = count === 1 ? 'is' : 'are';
                let records = count === 1 ? 'record' : 'records';
                let msg = `Warning: There ${isAre} already ${count} "active" ${records} without an End Date set.\n`;
                msg += ` Are you sure you wish to add a new User Commission Override?`
                if (!window.confirm(msg)) {
                    return;
                }
            }
        }

        let parsedDate = this.state.effectiveDate ? DateTime.fromISO(this.state.effectiveDate) : null;
        if (!parsedDate || !DateTime.isDateTime(parsedDate)) {
            window.alert("Invalid Effective Date");
            return;
        }

        var effectiveDate = parsedDate.toJSDate().toISOString();

        if (!this.state.userShare) {
            window.alert("Invalid User Share");
            return;
        }
        if (this.state.userShare > 100) {
            window.alert("Error: User Share cannot exceed 100%");
            return;
        }
        if (this.state.userShare < 10) {
            let msg = `Warning: User Share is set to less than 10%.\n`;
            msg += `Are you sure you wish to add a new User Commission Override with a UserShare value of ${this.state.userShare}%?`
            if (!window.confirm(msg)) {
                return;
            }
        }

        let userShare = this.state.userShare / 100;        

        let userId = this.state.formData.id;

        this.setState({ isLoading: true });

        let request = {
            userId: userId,
            userShare: userShare,
            effectiveDate: effectiveDate,
            offset: DateTime.now().offset
        };

        let addUserCommissionOverrideUrl = '/api/Merchant/AddUserCommissionOverride';
        let userCommissionOverrideHistory = await postRequest(addUserCommissionOverrideUrl, request);

        let userCommissionOverrideHistoryRecords = await this.getUserCommissionOverrideHistoryByUserId(userId);

        this.setState({ isLoading: false, showSavedProfile: true, userCommissionOverrideHistoryRecords });

        setTimeout(() => this.setState({ showSavedProfile: false }), 4000);
    };

    renderSubmit = () => {
        let submitDisabled = true;

        if (this.state.formData.id && this.state.userShare && this.state.effectiveDate) {
            submitDisabled = false;
        }

        return (
            <button type="submit" id="submitProfileChanges" className="btn btn-secondary mt-3" disabled={submitDisabled} onClick={(e) => { this.onSubmit(e); }}>
                {this.state.savingProfile ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : ""} Submit
            </button>
        );
    }

    getUserCommissionOverrideHistoryByUserId = async (userId) => {
        let getUserCommissionOverrideHistoryByUserIdUrl = '/api/Merchant/GetUserCommissionOverrideHistoryByUserId';
        getUserCommissionOverrideHistoryByUserIdUrl += `?offset=${DateTime.now().offset}`;
        getUserCommissionOverrideHistoryByUserIdUrl += `&userId=${(userId)}`;

        let userCommissionOverrideHistoryRecords = await getRequest(getUserCommissionOverrideHistoryByUserIdUrl) ?? [];
        return userCommissionOverrideHistoryRecords;
    };

    setSelected = async (selected) => {
        let formData = {
            displayName: "",
            bio: "",
            tagLine: "",
            imageUrl: "",
            userName: "",
            email: "",
            proStatusId: "",
            onboardingComplete: "",
            isAdmin: ""
        }

        let selectedProStatusId = this.proStatusIds[0];

        if (selected.length > 0) {

            formData = {
                displayName: selected[0].name,
                bio: selected[0].bio,
                tagLine: selected[0].tagLine,
                imageUrl: selected[0].imageUrl,
                userName: selected[0].userName,
                email: selected[0].email,
                proStatusId: selected[0].proStatusId,
                onboardingComplete: selected[0].onboardingComplete,
                isAdmin: selected[0].isAdmin,
                id: selected[0].id
            }

            selectedProStatusId = this.proStatusIds[selected[0].proStatusId];
        }

        let userCommissionOverrideHistoryRecords = await this.getUserCommissionOverrideHistoryByUserId(formData.id);

        this.setState({
            selected, formData,
            profilePicUrl: formData.imageUrl,
            selectedProStatusId,
            userCommissionOverrideHistoryRecords
        });
    }

    displayTableRows = () => {
        if (this.state.userCommissionOverrideHistoryRecords.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="4" style={{ textAlign: "center" }}>No data</td>
                </tr>
            );
        }

        return _.orderBy(this.state.userCommissionOverrideHistoryRecords, ['id'], ['desc']).map((data) => {
            return (
                <tr key={data.id}>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.id}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{(data.userShare * 100).toFixed(0) + '%'}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{convertToNumericMonthAndDayAndYearAndTime(data.effectiveDate, false).replace(',', '')}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left" }}>
                        <span>{data.endDate ? convertToNumericMonthAndDayAndYearAndTime(data.endDate, false).replace(',', '') : ''}</span>
                    </td>
                </tr>);
        });
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Add User Override</h1>
                        <p className="mb-0">Add user commission override</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container pt-0 pb-5">
                        <div className="search-interest py-4 mx-auto">
                            <div className="search-block">
                                <div className="position-relative mx-auto">
                                    <AsyncTypeahead
                                        id="userSearch"
                                        labelKey="userName"
                                        defaultSelected={this.state.value}
                                        clearButton
                                        onSearch={this.fetchUsers}
                                        onChange={selected => this.setSelected(selected)}
                                        isLoading={this.state.isLoading}
                                        options={this.state.options}
                                        placeholder="Search for users..." />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-form">
                            <div className="detail-block">
                                <h5>Public Profile</h5>

                                {
                                    this.state.formData.displayName || this.state.formData.userName ?
                                        <>
                                            <div className="avatar-upload my-3">
                                                <div className="avatar-preview">
                                                    <div id="imagePreview" style={{ backgroundImage: 'url(' + this.state.profilePicUrl + ')' }}>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <table className="table w-auto table-borderless table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Name:</span> &nbsp;</td>
                                                            <td>{this.state.formData.displayName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Administrator:</span> &nbsp;</td>
                                                            <td>{this.state.formData.isAdmin ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">User Type:</span> &nbsp;</td>
                                                            <td>{this.state.selectedProStatusId && this.state.selectedProStatusId.label}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">User Share:</span> &nbsp;</td>
                                                            <td>
                                                                {/* https://v4.mui.com/components/text-fields/ */}
                                                                <TextField
                                                                    id="userShare"
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                                    }}
                                                                    inputProps={{
                                                                        'data-lpignore': 'true'
                                                                    }}
                                                                    style={{ width: '244px' }}
                                                                    value={this.state.userShare}
                                                                    onChange={(e) => {
                                                                        let value = e.target.value.replace(/[^0-9.]/g, '');
                                                                        this.setState({ userShare: value });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Effective Date:</span> &nbsp;</td>
                                                            <td>
                                                                {/* https://v4.mui.com/components/pickers/ */}
                                                                <TextField
                                                                    id="effectiveDate"
                                                                    type="datetime-local"
                                                                    defaultValue={this.state.effectiveDate}
                                                                    onChange={(e) => {
                                                                        let effectiveDate = e.target.value;
                                                                        this.setState({ effectiveDate: effectiveDate });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            {this.renderSubmit()}

                                            <Popover
                                                placement="right"
                                                target="submitProfileChanges"
                                                isOpen={this.state.showSavedProfile}
                                                toggle={() => this.setState({ savingProfile: false })}
                                            >
                                                <PopoverBody>Changes saved</PopoverBody>
                                            </Popover>

                                            {
                                                this.state.isLoading ?
                                                    <LoadSpinnerOverlay />
                                                    : <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                            <div className="table-responsive product-table p-3">
                                <h5>Historical Commission Overrides</h5>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>User Share</th>
                                            <th>Effective Date</th>
                                            <th>End Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.displayTableRows()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default AddUserCommissionOverride;