import React, { Component } from "react";
import Select from 'react-select';
import ActionButton from "components/ActionButton";



class CategoryCreation extends Component {

    displayTableHeader = () => {
        let headers = ["Name"];

        return headers.map((header, i) => {
            return <th key={i} className="pl-5">{header}</th>
        })
    }

    displayTableRows = () => {
        return this.props.categories.map((category, i) => {
            if (category.level === this.props.level && (this.props.level === 1 || category.parentCategoryId === this.props.selectedCategories[this.props.selectedCategories.length - 1].id)) {
                return (
                    <tr key={i}>
                        <td className="pl-5 text-left">
                            <span><a href="#" onClick={(e) => { e.preventDefault(); this.props.handleCategoryTableClick(category); }}>{category.name}</a></span>
                        </td>
                    </tr>
                )
            }
        })
    }

    displayBreadcrumbs = () => {
        return this.props.selectedCategories.map((category, i, arr) => {
            if (i === 0 ) {
                return (
                    <a href="#" key={i} onClick={(e) => { e.preventDefault(); this.props.handleBreadcrumbClick(i); }}>Root Level</a>
                )
            }
            if (i === arr.length - 1) {
                return <span key={i}> &gt; {category.name}</span>;
            }
            return (
                <a href="#" key={i} onClick={(e) => { e.preventDefault(); this.props.handleBreadcrumbClick(i); }}> &gt; {category.name}</a>
            )
        });
    }


    render() { 
        const width = "500px";
        return (
            <>
                <section className="d-flex flex-column align-items-center gray-bg pb-3 px-4">
                    <div className="d-flex flex-column pt-4" style={{ minWidth: width }}>
                        <h4>Create Category</h4>
                        <h6>Name:</h6>
                        <input type="text" id="name" className="pl-2" value={this.props.category.name} onChange={this.props.handleCategoryName} />
                        <h6 className="mt-3 d-none" >ParentCategoryId:</h6>
                    </div>
                    <div className="pt-4" style={{ minWidth: width }}>
                        <h4>Selected Parent Category</h4>
                        <h6>{this.props.selectedCategories.length > 0 ? this.props.selectedCategories[this.props.selectedCategories.length - 1].name : "Select a category..."}</h6>
                     {this.props.category?.name?.length > 0 ?
                            <ActionButton className="btn btn-primary mt-3" style={{ width: "95px" }}
                                onClick={this.props.saveCategory}
                                isLoading={this.props.savingCategory}
                            >
                                Save
                            </ActionButton> :
                            <ActionButton className="btn btn-primary mt-3" isDisabled={true} style={{ width: "95px" }}
                                onClick={this.props.saveCategory}
                                isLoading={this.props.savingCategory}
                            >
                                Save
                            </ActionButton>
                        }
                    </div>
                </section>

                <section className="px-5 pb-5">
                    <div className="table-responsive product-table mx-auto" style={{ overflow: "visible", maxWidth: width }}>
                        <table className="table" style={{ border: 0 }}>
                            <thead>
                                <tr>
                                    <td className="border-0 pl-5">
                                        <span>
                                            {this.displayBreadcrumbs()}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    {this.displayTableHeader()}
                                </tr>
                            </thead>
                            <tbody>
                                {this.displayTableRows()}
                            </tbody>
                        </table>
                    </div>
                </section>
            </>
        );
    }
}

export default CategoryCreation;