import React, { useState, useEffect, useRef } from "react";
import Modal from "components/Modal";
import { connect } from "react-redux";
import { toggleAddProductOrDeepLinkModal } from "stateManagement/reducers/modalReducer";
import { Link } from "react-router-dom";
import { Popover, PopoverBody, UncontrolledPopover } from "reactstrap";
import RockporchMountainIcon from "components/icons/RockporchMountainIcon";
import InformationIcon from "components/icons/InformationIcon";
import CloseIcon from "components/icons/CloseIcon";

import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { isValidUrl } from "sharedUtils/validationUtils";
import ActionButton from "components/ActionButton";
import SaleIcon from "components/icons/SaleIcon";
import { currencyFormat, titleCase } from "sharedUtils/productUtils";
import { DateTime } from "luxon";

/**
 * 
 * @param {{loggedInUser:object, userId:any,followersFollowing:string, setLoggedInUser:Function}} props
 * productOrDeepLinkObject.resultType: 1 = Product, 2 = DeepLink, 3 = MerchantNotIntegrated
 */
const AddProductOrDeepLinkModal = (props) => {
    const [showCopied2, setCopiedText2] = useState(false);
    const [validUrl, setValidUrl] = useState(true);
    const [merchantExists, setMerchantExists] = useState(true);
    const [merchantId, setMerchantId] = useState(0);
    const [merchantName, setMerchantName] = useState(0);
    const [merchantLink, setMerchantLink] = useState(null);
    const [resultType, setResultType] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [urlInput, setUrlInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingDeepLinkDetails, setLoadingDeepLinkDetails] = useState(false);
    const [product, setProduct] = useState(null);
    const [showLearnMore, setLearnMore] = useState(false);
    const [doDeepLinkDetails, setDoDeepLinkDetails] = useState(false);
    const [deepLinkDetailsFailed, setDeepLinkDetailsFailed] = useState(false);
    const [doAddDeepLinkData, setDoAddDeepLinkData] = useState(false);
    const [manufacturer, setManufacturer] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState(''); // You can set an initial value if needed
    const [savedDeepLinkReturnObject, setSavedDeepLinkReturnObject] = useState(null);
    const [deepLinkPopoverOpen, setDeepLinkPopoverOpen] = useState(false);


    const inputRef = useRef(null);


    let isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let bookmarkOrigin = window.location.origin;
    let buttonStyle = {
        background: "#D2E3E0"
    }
    const resetParams = (resetUrlInput = true) => {
        setRedirectUrl("");
        setDoDeepLinkDetails(false);
        setDoAddDeepLinkData(false);
        setMerchantExists(true);
        setValidUrl(true);
        setMerchantId(0);
        setMerchantName("");
        setMerchantLink(null);
        setManufacturer('');
        setName('');
        setPrice('');
        setDeepLinkDetailsFailed(false);
        setDeepLinkPopoverOpen(false);
        setSavedDeepLinkReturnObject(null);

        setResultType(0);
        setProduct(null);
        if (resetUrlInput) {
            setUrlInput("");
        }
        if (!!(inputRef) && !!(inputRef.current)) {
            inputRef.current.focus();
        }
    }

    useEffect(() => {
        if (props.isModal === false) {
            props.setShowTopNav(false);
            if (window.location.search.length > 0)
            {
                let productUrl = window.location.search.split("=")[1];
                let decodedUrl = decodeURIComponent(productUrl);
                setUrlInput(decodedUrl);
                handleLogic(decodedUrl);
                handleGoogleAnalytics4Bookmark();
            }
        }
    }, [])

    useEffect(() => {
        if ((props.isModal == undefined || props.isModal == null || props.isModal == true) && props.showModal === false) {
            resetParams(true);
        }
    }, [props.showModal])

    useEffect(() => {
        let isValid = isValidUrl(urlInput, true);
        if (urlInput.length > 3 && (isValid === false)) {
            setValidUrl(false);
        }
        else {
            setValidUrl(true);
        }
    }, [urlInput])

    useEffect(() => {
        if (redirectUrl === "" && merchantId === 0 && !!(inputRef) && !!(inputRef.current)) {
            resetParams(true);
        }
    }, [redirectUrl])

    const resetMerchantLinkData = () => {
        setMerchantLink(null);
        setPrice("");
        setName("");
        setManufacturer("");
        setDeepLinkDetailsFailed(false);
        setDoAddDeepLinkData(false);
    }

    const fetchProductOrDeepLinkByUrl = async (url) => {
        let route = "/Products/GetProductOrDeepLinkByUrl";
        let requestData = { url: url.trim() };
        setLoading(true);
        let merchant = await postRequest(route, requestData);
        setLoading(false);

        return merchant;
    }

    let renderCopiedText2 = () => {
        if (showCopied2) {
            setTimeout(() => { setCopiedText2(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    const handleAddDeepLinkData = () => {
        setDeepLinkPopoverOpen(false);
        setDoAddDeepLinkData(true);
    }

    let handleInput = (e) => {
        let url = e.target.value;
        setUrlInput(url);
    }

    let handleGoogleAnalytics4Modal = () => {
        window.gtag('event', 'Add_Product_Deep_Link_Modal', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Button',
            event_label: `Add Product Deep Link Modal`,
        });
    }

    let handleGoogleAnalytics4Bookmark = () => {
        window.gtag('event', 'Add_Product_Deep_Link_Bookmark', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Button',
            event_label: `Add Product Deep Link Bookmark`,
        });
    }

    let handleDeepLinkChange = async (event) => {
        handleGoogleAnalytics4Modal();

        setRedirectUrl("");
        setValidUrl(true);
        resetParams(false);

        let url = urlInput

        if (validUrl) {
            await handleLogic(urlInput);
        } else {
            setValidUrl(false);
            setRedirectUrl("");
        }
    };

    const handleLogic = async (url) => {
        let productOrDeepLinkResult = await fetchProductOrDeepLinkByUrl(url);

        if (!!(productOrDeepLinkResult) && [1, 2].includes(productOrDeepLinkResult.returnType)) {

            setMerchantId(productOrDeepLinkResult.merchantId);
            setMerchantName(productOrDeepLinkResult.merchantName);
            setMerchantLink(productOrDeepLinkResult.merchantLink);
            setMerchantExists(true);
            setResultType(productOrDeepLinkResult.returnType);
            if (productOrDeepLinkResult.returnType === 1) {
                setRedirectUrl(productOrDeepLinkResult.shareLink);
                if (!!(productOrDeepLinkResult.product)) {
                    setProduct(productOrDeepLinkResult.product);
                    let event = new Event("_productAddedEvent");
                    document.dispatchEvent(event);
                }
            } else {
                setRedirectUrl(productOrDeepLinkResult.shareLink);
                setSavedDeepLinkReturnObject({ logoUrl: productOrDeepLinkResult.logoUrl });
                let event = new Event("_productAddedEvent");
                document.dispatchEvent(event);
            }
            props.reloadCreatorDashboardAnalyticsData();
        } else {
            setMerchantExists(false);
            setRedirectUrl("");
            setMerchantId(0);
            setMerchantName("");
            setMerchantLink(null);
        }
        if (!props.isModal && !!(productOrDeepLinkResult) && productOrDeepLinkResult.returnType === 3) {
            setValidUrl(true);
        }
    }

    const onKeypress = (e) => {
        if (e.key === 'Enter') handleDeepLinkChange();
    }

    const displayValidationMessage = () => {
        if (!validUrl) {
            return (
                <p style={{ textAlign: "center", fontSize: "12px" }}>

                    Sorry, that doesn't look like a the URL to one of our Merchant Partners. <br />
                    Copy and paste the URL of the page you're trying to add. <br />
                    Example: <span style={{ color: "#e55934" }}>https://www.example.com...</span>

                </p>
            )
        }
        else {
            return <span></span>
        }
    }
    function toggleShowLearnMore() {
        setLearnMore(true);
        setTimeout(() => {
            setLearnMore(false)
        }, 5000);
    }

    const displayMerchantNotIntegratedMessage = () => {
        if (urlInput.length > 0 && isValidUrl && !merchantExists) {
            return (
                <div className="text-center">
                    <small style={{ color: "#e55934" }}>
                        Sorry!<br />
                        We are not partnered with this merchant yet. We recorded your attempt and are establishing new merchants every day.
                    </small>
                    <p>&nbsp;</p>
                    <h6 className="text">Click <Link to="/Account/Brands" onClick={props.toggleModal}>HERE</Link> for a list of Merchant Partners</h6>
                </div>
            )
        }
        else {
            return <span></span>
        }
    }

    const displayLinkSavedMessage = () => {
        if (!!(savedDeepLinkReturnObject) && resultType === 2 && props.deviceSize === "sm") {
            return (
                <div className="text-center mb-2">
                    <small style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                        The product was successfully added to <br />
                        <Link
                            to={{
                                pathname: "/CreatorDashboard",
                                search: "?tab=1&productsTab=1",
                                state: { key: DateTime.now().toString() }
                            }}
                            style={{}}
                            onClick={props.toggleModal}
                        >Saved Links</Link>!
                    </small>
                </div>
            )
        } else if (!!(savedDeepLinkReturnObject) && resultType === 2) {
            return (
                <div className="text-center mb-2">
                    <small style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                        The link was successfully added to <Link
                            to={{
                                pathname: "/CreatorDashboard",
                                search: "?tab=1&productsTab=1",
                                state: { key: DateTime.now().toString() }
                            }}
                            style={{}}
                            onClick={props.toggleModal}
                        >Saved Links</Link>!
                    </small>
                </div>
            )
        }
        else {
            return <span></span>
        }
    }

    const displayProductAddedMessage = () => {
        if (resultType === 1 && props.deviceSize === "sm") {
            return (
                <div className="text-center mb-2">
                    <small style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                        The product was successfully added to <br />
                        <Link
                            to={{
                                pathname: "/CreatorDashboard",
                                search: "?tab=1&productsTab=0",
                                state: { key: DateTime.now().toString() }
                            }}
                            style={{}}
                            onClick={props.toggleModal}
                        >Saved Links</Link>!
                    </small>
                </div>
            )
        } else if (resultType === 1) {
            return (
                <div className="text-center mb-2">
                    <small style={{ color: "rgba(0, 0, 0, 0.8)" }}>
                        The product was successfully added to <Link
                            to={{
                                pathname: "/CreatorDashboard",
                                search: "?tab=1&productsTab=0",
                                state: { key: DateTime.now().toString() }
                            }}
                            style={{}}
                            onClick={props.toggleModal}
                        >Saved Links</Link>!
                    </small>
                </div>
            )
        }
        else {
            return <span></span>
        }
    }

    let displayManufacturer = (manufacturer) => {
        if (manufacturer?.toLowerCase().includes("rcktemp_")) {
            return "";
        }
        return manufacturer;
    }

    const submitDeepLinkDetails = async () => {
        setLoadingDeepLinkDetails(true);

        const referralUrl = merchantLink.referralUrl;
        const id = merchantLink.id;
        const merchantId = merchantLink.merchantId;
        const creatorUserId = merchantLink.creatorUserId;

        let uiControllerUrl = "/Products/AddMerchantLinkData";
        let manualProduct = {
            brand: manufacturer,
            price,
            description: referralUrl,
            id,
            merchantId,
            referralUrl,
            creatorUserId,
        };

        let productOrDeepLinkResult = await postRequest(uiControllerUrl, manualProduct);

        if (!!(productOrDeepLinkResult)) {
            productOrDeepLinkResult.merchantName = merchantName;
            setSavedDeepLinkReturnObject(productOrDeepLinkResult);

            resetMerchantLinkData()
            let event = new Event("_productAddedEvent");
            document.dispatchEvent(event);
        } else {
            setDeepLinkDetailsFailed(true);
        }

        setLoadingDeepLinkDetails(false);
    };


    const renderAddDeepLinkDetails = () => {
        if (doAddDeepLinkData) {
            return (
                <div className="mb-4 d-flex flex-column" style={{ marginLeft: "0px" }}>
                    <h5>Save Link Details</h5>
                    <p style={{ fontSize: "13px", marginBottom: "3px", color: "red", display: deepLinkDetailsFailed ? "" : "none" }}>Add FAILED. Check data and try again.</p>

                    <div>
                        <p style={{ fontSize: "13px", marginBottom: "3px" }}>Link</p>
                        <div style={{
                            position: "relative"
                        }}>
                            <input
                                className="w-100 mr-3"
                                type="text"
                                value={urlInput}
                                id="deepLinkUrl"
                                style={{ backgroundColor: "white", border: "none", marginTop: "", display: "inline-block", textOverflow: "ellipsis" }}
                                readOnly
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <p style={{ fontSize: "13px", marginBottom: "3px" }}>Retailer</p>
                        <input
                            className="w-100 mr-3"
                            type="text"
                            id="retailer"
                            value={merchantName}
                            onChange={(e) => setManufacturer(e.target.value)}
                            style={{ backgroundColor: "white", border: "none", marginTop: "" }}
                            readOnly
                        />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <p style={{ fontSize: "13px", marginBottom: "3px" }}>Brand Name</p>
                        <input
                            className="w-100 mr-3"
                            type="text"
                            value={manufacturer.length > 0 ? manufacturer : ""}
                            id="manufacturer"
                            placeholder={manufacturer.length === 0 ? "Enter brand name if any" : manufacturer}
                            onChange={(e) => setManufacturer(e.target.value)}
                            style={{ backgroundColor: "#e9ecef", border: "none", marginTop: "" }}
                            autoFocus
                        />
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <p style={{ fontSize: "13px", marginBottom: "3px" }}>Description<span style={{ position: "relative", top: "-3px" }}>*</span></p>
                        <input
                            className="w-100 mr-3"
                            type="text"
                            value={name.length > 0 ? name : ""}
                            id="name"
                            placeholder={name.length === 0 ? "Enter link description" : name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ backgroundColor: "#e9ecef", border: "none", marginTop: "" }}
                        />
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <p style={{ fontSize: "13px", marginBottom: "3px" }}>Retail Price</p>
                        <input
                            className="w-100 mr-3"
                            type="number"
                            value={price}
                            id="price"
                            placeholder="Enter product price if any"
                            onChange={(e) => setPrice(e.target.value)}
                            style={{ backgroundColor: "#e9ecef", border: "none", marginTop: "" }}
                        />
                    </div>

                    <p style={{ fontSize: "12px", position: "relative", top: "8px" }}><span style={{ position: "relative", top: "-3px" }}>*</span> Required field</p>

                    <ActionButton
                        onClick={submitDeepLinkDetails}
                        className="btn btn-primary mt-4"
                        style={{ marginTop: "10px", }}
                        isLoading={loadingDeepLinkDetails}
                        isDisabled={!(name?.trim()) }
                    >Save Link</ActionButton>
                </div>
            );
        }
    }

    const displaySaveLinkDetails = () => {
        return (
            <div style={{ display: deepLinkPopoverOpen ? "" : "none" }}>
                <p>Your commissionable link is in the box above!</p>
                <p>You can <span style={{ fontStyle: "italic", fontWeight: "600" }}>save this link</span> and add details by <span style={{ fontStyle: "italic", fontWeight: "600" }}>pressing the button below</span>.</p>
                <div>
                    <ActionButton
                        className="btn btn-primary w-100 mb-4"
                        onClick={handleAddDeepLinkData}>
                        Save Link
                    </ActionButton>
                </div>
            </div>
        );
    }

    const displayProductData = () => {
        if (resultType === 1 && !!(product)) {
            return (
                <div className="row w-100 m-0">

                    <div className={"w-100 d-flex"}>
                        <div className="col-4" style={{ alignSelf: "center" }}>
                            <div style={{ paddingBottom: "100%", position: "relative", overflow: "hidden", marginRight: "10px" }}>
                                <img style={{ position: "absolute", objectFit: "scale-down", width: "100%", height: "100%" }} className="border-0" src={product.imageUrl} onError={(e) => {
                                    e.target.onError = null
                                    e.target.src = "images/blank-banner.png"
                                }} />
                            </div>
                        </div>

                        <div className="align-self-md-center w-100 ml-2">
                            {
                                props.deviceSize === "sm" ?
                                    <>
                                        <h6 style={{ fontSize: "14px" }}>{displayManufacturer(product?.manufacturer)}</h6>

                                        <span style={{ fontSize: "14px" }}>{product?.name.toUpperCase()}</span>
                                        <h6>{currencyFormat(product.price)}<span className={`${product?.sale && !!(product?.salePrice) ? "on-sale-price ml-1" : ""}`}>{product?.sale && !!(product?.salePrice) && !!(product?.originalPrice) ? currencyFormat(product?.originalPrice) : <></>} {product?.sale && !!(product?.salePrice) ? <SaleIcon style={{ marginLeft: "5px" }} /> : <></>}</span></h6>

                                        <span className="d-flex" style={{ fontSize: "14px" }}>
                                            Estimated Commission
                                            <small className="d-inline-block ml-1">
                                                <button style={{ outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id="infoIcon"><InformationIcon /></button>
                                            </small>

                                            <UncontrolledPopover
                                                placement="bottom"
                                                target="infoIcon"
                                                trigger="legacy"
                                            >
                                                <PopoverBody>Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase.</PopoverBody>
                                            </UncontrolledPopover>

                                        </span>
                                        <h6 className="estimate-price">{currencyFormat(product?.estimatedCommission || 0)}</h6>
                                    </>
                                    :
                                    <>
                                        <h5>{displayManufacturer(product?.manufacturer)}</h5>

                                        <span>{product?.name.toUpperCase()}</span>
                                        <h5>{currencyFormat(product.price)}<span className={`${product?.sale && !!(product?.salePrice) ? "on-sale-price ml-1" : ""}`}>{product?.sale && !!(product?.salePrice) && !!(product?.originalPrice) ? currencyFormat(product?.originalPrice) : <></>}</span>{product?.sale && !!(product?.salePrice) ? <SaleIcon style={{ marginLeft: "5px" }} /> : <></>}</h5>

                                        <span className="d-flex" style={{ fontSize: "12px" }}>
                                            Estimated Commission
                                            <small className="d-inline-block ml-1">
                                                <button style={{ outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id="infoIcon"><InformationIcon /></button>
                                            </small>

                                            <UncontrolledPopover
                                                placement="bottom"
                                                target="infoIcon"
                                                trigger="legacy"
                                            >
                                                <PopoverBody>Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase.</PopoverBody>
                                            </UncontrolledPopover>

                                        </span>
                                        <h5 className="estimate-price">{currencyFormat(product?.estimatedCommission || 0)}</h5>
                                    </>
                            }
                            
                        </div>
                    </div>
                    <br />
                </div>
                
            );
        }
    }

    const displaySavedLinkData = () => {
        if (resultType === 2 && !!(savedDeepLinkReturnObject)) {
            return (
                <div className="row w-100 m-0">

                    <div className={"w-100 d-flex"}>
                        <div className="d-flex align-items-center" style={{ gap: props.deviceSize === "sm"  ? "15px" : "20px" }} >
                            {
                                props.deviceSize === "sm" ?
                                    <>
                                        <img style={{ objectFit: "scale-down", width: "75px", height: "75px" }} className="border-0" src={savedDeepLinkReturnObject.logoUrl} onError={(e) => {
                                            e.target.onError = null
                                            e.target.src = `images/blank-banner.png`
                                        }} />
                                        <h6 style={{ fontSize: "14px", margin: 0, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "200px" }} >{merchantLink?.referralUrl}</h6>                                        
                                    </>
                                    :
                                    <>
                                        <img style={{ objectFit: "scale-down", width: "75px", height: "75px" }} className="border-0" src={ savedDeepLinkReturnObject.logoUrl} onError={(e) => {
                                            e.target.onError = null
                                            e.target.src = `images/blank-banner.png`
                                        }} />
                                        <h6 style={{ margin: 0, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "330px" }}>{merchantLink?.referralUrl}</h6>
                                    </>
                            }

                        </div>
                    </div>
                    <br />
                </div>

            );
        }
    }

    return (
        <>  
            <Modal isOpen={props.showModal} toggleModal={() => props.toggleModal(null)}>
                {
                    <div className="text-center" style={props.customStyle}>
                        <div className="share-block" style={{ backgroundColor: "transparent", border: "none", borderRadius: "none" }}>

                            <div style={{ textAlign: "left" }}>
                                <h5 className="mb-4">Create a Commissionable Link</h5>
                                <h6 className="text" style={{ display: merchantId > 0 ? "none" : "" }}>Paste URL from a merchant web page here. All URLs must be from a <Link to="/Account/Brands"  onClick={props.toggleModal}>Merchant Partner</Link>!</h6>
                                {/*<h5 className="mb-4">Share a Link to a Product or Page from one of RockPorch's many Merchants!</h5>*/}
                                {/*<p style={{ color: "red", display: urlInput.length > 0 && isValidUrl && !merchantExists ? "" : "none" }}>Merchant is not yet integrated yet with RockPorch.</p>*/}


                                <div className={`${merchantId > 0 ? "" : "mb-4"} d-flex justify-content-between`} style={{ marginLeft: "0px", display: merchantId > 0 ? "none" : "" }}>

                                    {/*<input type="text" value={urlInput.length > 0 ? urlInput : ""} placeholder={redirectUrl.length === 0 ? "Paste URL for Deep Link here" : redirectUrl} id="myInput" onChange={(e) => handleInput(e)} />*/}
                                    <input
                                        ref={inputRef}
                                        className="w-75 mr-3"
                                        type="text"
                                        value={urlInput.length > 0 ? urlInput : ""}
                                        id="myInput"
                                        placeholder={urlInput.length === 0 ? "Paste URL from a Merchant Partner here" : urlInput}
                                        onChange={handleInput}
                                        onKeyPress={onKeypress}
                                        style={{ backgroundColor: "#e9ecef", border: "none", display: merchantId > 0 ? "none" : "" }}
                                        autoFocus
                                    />
                                    <ActionButton onClick={handleDeepLinkChange} style={{ display: merchantId > 0 ? "none" : "" }} className="btn btn-primary" isLoading={loading} isDisabled={urlInput.length === 0}>Create</ActionButton>
                                </div>

                                <div className="clipboard mb-4 ml-0 mr-0 mt-0" style={{ minWidth: "100%", display: merchantId > 0 ? "" : "none" }}>
                                    <input type="text" disabled defaultValue={!!(redirectUrl) ? redirectUrl : ""} id="productOrDeepLinkRedirectUrl" onChange={() => null} />
                                    <button className="btn" disabled={!validUrl || redirectUrl.length === 0} style={{ fontSize: "12px" }} onClick={() => { navigator.clipboard.writeText(redirectUrl); setCopiedText2(true); }}>{renderCopiedText2()}</button>
                                </div>

                                {displayProductData()}

                                {displayValidationMessage()}
                                {displayMerchantNotIntegratedMessage()}
                                {displayProductAddedMessage()}
                                {displayLinkSavedMessage()}
                                {displaySavedLinkData()}

                                <ActionButton onClick={resetParams} style={{ display: merchantId > 0 ? "" : "none" }} className="btn btn-outline-brand btn-block">Create another link</ActionButton>

                            </div>     
                            {
                                props?.isModal !== false ?
                                    isMobileDevice ?
                                        (<div>
                                            <hr />

                                            <p>
                                                <b style={{ color: "#e55934" }}>PRO TIP: </b> When adding several products to your inventory, the process moves much faster on a desktop!
                                            </p>
                                        </div>)
                                        :
                                        <span></span>
                                        :
                                       <span></span>
                            }
                        </div>
                    </div>
                }
            </Modal>
            {
                props.isModal === false ?

                    <div className="text-center" style={props.customStyle}>
                        <div className="share-block" style={{ backgroundColor: "transparent", border: "none", borderRadius: "none" }}>

                            <div style={{ textAlign: "left" }}>
                                <h5 className="mb-4">Create a Commissionable Link</h5>
                                <h6 className="text" style={{ display: merchantId > 0 || (urlInput.length > 0 && isValidUrl && !merchantExists) ? "none" : "" }}>Paste URL from a merchant web page here. All URLs must be from a <Link to="/Account/Brands" onClick={props.toggleModal}>Merchant Partner</Link>!</h6>
                                {/*<h5 className="mb-4">Share a Link to a Product or Page from one of RockPorch's many Merchants!</h5>*/}
                                {/*<p style={{ color: "red", display: urlInput.length > 0 && isValidUrl && !merchantExists ? "" : "none" }}>Merchant is not yet integrated yet with RockPorch.</p>*/}


                                <div className={`${merchantId > 0 || (urlInput.length > 0 && isValidUrl && !merchantExists) ? "" : "mb-4"} d-flex justify-content-between`} style={{ marginLeft: "0px" }}>

                                    {/*<input type="text" value={urlInput.length > 0 ? urlInput : ""} placeholder={redirectUrl.length === 0 ? "Paste URL for Deep Link here" : redirectUrl} id="myInput" onChange={(e) => handleInput(e)} />*/}
                                    <input
                                        className="w-75 mr-3"
                                        type="text"
                                        value={urlInput.length > 0 ? urlInput : ""}
                                        id="myInput"
                                        placeholder={urlInput.length === 0 ? "Paste URL from a Merchant Partner here" : urlInput}
                                        onChange={handleInput}
                                        onKeyPress={onKeypress}
                                        style={{ backgroundColor: "#e9ecef", border: "none", display: merchantId > 0 || (urlInput.length > 0 && isValidUrl && !merchantExists) ? "none" : "" }}
                                        autoFocus
                                    />
                                    <ActionButton style={{ display: merchantId > 0 || (urlInput.length > 0 && isValidUrl && !merchantExists) ? "none" : "" }} onClick={handleDeepLinkChange} className="btn btn-primary" isLoading={loading} isDisabled={urlInput.length === 0}>Create</ActionButton>
                                </div>

                                <div className="clipboard mb-4 ml-0 mr-0 mt-0" style={{ minWidth: "100%", display: merchantId > 0 ? "" : "none" }}>
                                    <input type="text" disabled defaultValue={!!(redirectUrl) ? redirectUrl : ""} id="myInput" onChange={() => null} />
                                    <button className="btn" disabled={!validUrl || redirectUrl.length === 0} style={{ fontSize: "12px" }} onClick={() => { navigator.clipboard.writeText(redirectUrl); setCopiedText2(true); }}>{renderCopiedText2()}</button>
                                </div>

                                {displayProductData()}
                                {displayValidationMessage()}
                                {displayMerchantNotIntegratedMessage()}
                                {displayProductAddedMessage()}
                                {displayLinkSavedMessage()}
                                {displaySavedLinkData()}
                            </div>
                            
                        </div>
                    </div> :
                    <></>
                    }
        </>
    )
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.addProductOrDeepLinkModal.showAddProductOrDeepLinkModal,
    }
}

const mapDispatch = {
    toggleModal: toggleAddProductOrDeepLinkModal
}

export default connect(mapStateToProps, mapDispatch)(AddProductOrDeepLinkModal);