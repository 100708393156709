import * as React from "react";
import { NavLink } from "react-router-dom";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { Form, FormGroup, Label, Input } from "reactstrap";

class OnboardGoPro extends React.Component {

    state = {
        submitting: false,
        formData: {}
    }

    componentDidMount = async () => {
        this.props.setShowTopNav(false);
        this.setState({ formData: this.convertToFormData(this.props.loggedInUser) });
    }

    renderSubmit = () => {
        if (this.state.formData?.google || this.state.formData?.instagram || this.state.formData?.facebook) {
            if (this.state.submitting) {
                return <button className="btn btn-primary">
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> Apply Now
            </button>;
            }
            return (
                <NavLink to="/Account/OnboardWhoToFollow">
                    <button className="btn btn-primary" onClick={() => this.onSubmitClick()}>Apply Now</button>;
                </NavLink>);
        }
        return <button className="btn btn-primary disabled">Apply Now</button>;

    }

    saveUser = async () => {
        let userUrl = '/api/Account/PostUser';
        this.props.loggedInUser.onboardingComplete = true;

        let user = await postRequest(userUrl, this.convertToUserModel());
        this.props.setLoggedInUser(user);
    }

    applyForPro = async () => {
        let userUrl = '/api/Account/ApplyForPro';
        await postRequest(userUrl, this.props.loggedInUser);
    }

    onSubmitClick = async () => {
        this.props.setShowTopNav(true);

        this.setState({ submitting: true });
        await this.saveUser();
        await this.applyForPro();
        this.setState({ submitting: false });
    }

    mapInputFields = (fields) => {
        return fields.map((field, i) => {
            return (
                <FormGroup key={i} className="form-group">
                    <Label className="control-label">{field.title}</Label>
                    <Input
                        className="form-control"
                        name={field.name}
                        onChange={(e) => this.onInputChange(e)}
                        value={this.state.formData[field.name]}
                    />
                </FormGroup>
            );
        });
    }

    mapInputFieldsSocial = () => {
        let fields = [
            { name: "instagram", title: "Instagram"  },
            { name: "facebook", title: "Facebook" },
            { name: "youtube", title: "YouTube" },
        ];
        return this.mapInputFields(fields);
    };

    mapInputFieldsPersonal = () => {
        let fields = [
            { name: "name", title: "Name" },
            { name: "audience", title: "Primary Audience / Region" },
        ];
        return this.mapInputFields(fields);
    };

    onInputChange = (e) => {
        let formkey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;
        formData[formkey] = newData;
        this.setState({ formData: formData });
    };

    convertToFormData = (user) => {
        return {
            youtube: user.googleUsername,
            facebook: user.facebookUsername,
            instagram: user.instagramUsername,
            userName: user.userName
        };
    }

    convertToUserModel = () => {
        return {
            name: this.props.loggedInUser.name,
            firstName: this.props.loggedInUser.firstName,
            lastName: this.props.loggedInUser.lastName,
            bio: this.props.loggedInUser.bio,
            tagLine: this.props.loggedInUser.tagLine,
            website: this.props.loggedInUser.website,
            googleUsername: this.state.formData.youtube,
            facebookUsername: this.state.formData.facebook,
            instagramUsername: this.state.formData.instagram,
            imageUrl: this.props.loggedInUser.imageUrl,
            userName: this.props.loggedInUser.userName,
            email: this.props.loggedInUser.email,

            phoneNumber: this.props.loggedInUser.phoneNumber,
            proStatusId: this.props.loggedInUser.proStatusId,
            address: this.props.loggedInUser.address,
            city: this.props.loggedInUser.city,
            state: this.props.loggedInUser.state,
            postalCode: this.props.loggedInUser.postalCode,
            country: this.props.loggedInUser.country,

            onboardingComplete: this.props.loggedInUser.onboardingComplete,
            profileBannerUrl: this.props.loggedInUser.profileBannerUrl,
            quickStart: this.props.loggedInUser.quickStart
        }
    }

    render = () => {
        return (
            <div className="container-fluid gray-bg full-height">
                <div className="row">
                    <div className="col-12 px-0 top-banner position-relative">
                        <img src="images/top-banner.png" alt="" className="w-100 h-100" />
                        <div className="interest-steps position-absolute text-center w-100">
                            <NavLink to="/Account/OnboardWhoToFollow" className="d-flex align-items-center skip-link position-absolute">
                                Skip this step
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M7.33335 10.0832C6.82709 10.0832 6.41669 10.4936 6.41669 10.9998C6.41669 11.5061 6.82709 11.9165 7.33335 11.9165H18.3334C18.8396 11.9165 19.25 11.5061 19.25 10.9998C19.25 10.4936 18.8396 10.0832 18.3334 10.0832H7.33335Z" fill="white" />
                                    <path opacity="0.3" d="M4.58333 3.66667C4.58333 3.16041 4.17293 2.75 3.66667 2.75C3.16041 2.75 2.75 3.16041 2.75 3.66667V18.3333C2.75 18.8396 3.16041 19.25 3.66667 19.25C4.17293 19.25 4.58333 18.8396 4.58333 18.3333V3.66667Z" fill="white" />
                                    <path d="M12.1852 15.8517C11.8272 16.2096 11.8272 16.79 12.1852 17.148C12.5431 17.506 13.1235 17.506 13.4815 17.148L18.9815 11.648C19.3285 11.301 19.3407 10.7422 19.0091 10.3804L13.9674 4.88042C13.6253 4.50723 13.0454 4.48202 12.6723 4.82411C12.2991 5.1662 12.2738 5.74606 12.6159 6.11925L17.0645 10.9723L12.1852 15.8517Z" fill="white" />
                                </svg>
                            </NavLink>
                            <h1 className="mb-4">Apply as Creator</h1>
                            <p>Creators get access to more features, analytics, and payouts</p>
                            <ul className="steps-count pl-0">
                                <li className="d-inline-flex justify-content-center align-items-center active">1</li>
                                <li className="d-inline-flex justify-content-center align-items-center active">2</li>
                                <li className="d-inline-flex justify-content-center align-items-center">3</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 search-interest py-4 py-md-5 mx-auto">
                        <div className="container">
                            <div className="row pt-0 mt-0 pb-5">
                                <div className="col-md-3 col-lg-3 side-nav">                                    
                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <div className="user-detail-form">
                                        <div className="detail-block">
                                            <h2>Apply as Creator</h2>
                                            <p>RockPorch is the leading community of outhdoor enthusiasts where users communicate and collaborate, and Creators get paid sharing their passion.  Ready to apply as a Creator?</p>
                                            <p><strong>Apply today to become a Creator and get paid for the content you share.</strong></p>
                                        </div>
                                    </div>

                                    <div className="user-detail-form">
                                        <form>
                                            <div className="detail-block">
                                                <h5>Your Info</h5>
                                                <p>We just need to know a few things about you</p>
                                                {this.mapInputFieldsSocial()}
                                            </div>
                                        </form>
                                    </div>

                                    <div className="user-detail-form">
                                        <form>
                                        <div className="detail-block">
                                                <h5>Social Profiles</h5>
                                                <p>Please provide your username to the social platforms you regulary contribute.</p>
                                                {this.mapInputFieldsSocial()}
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 interest-footer d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center p-4 position-fixed text-center text-md-left">
                        <p className="mb-4 mb-md-0">Not ready to apply?  No problem! <a href="/Account/OnboardWhoToFollow" className="d-block d-md-inline-block">Skip this step</a></p>
                        {this.renderSubmit()}                        
                    </div>
                </div>
            </div>)
    }
}

export default OnboardGoPro;