import React, { Component } from "react";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import _, { isInteger } from "lodash";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import toast, { Toaster } from 'react-hot-toast';
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import TextField from '@material-ui/core/TextField';
import { getRequest, postRequest } from "sharedUtils/httpUtils";

import Select from 'react-select'
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
const { DateTime } = require("luxon");

class ProductNotFound extends Component {

    constructor(props) {
        super(props);

        this.selectOptions = {
            last24Hours: {
                value: 0,
                label: "Yesterday",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 1 })).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();
                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            last7Days: {
                value: 1,
                label: "Last 7 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 7 })).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            last30Days: {
                value: 2,
                label: "Last 30 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 30 })).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            last90Days: {
                value: 3,
                label: "Last 90 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 90 })).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            yearToDate: {
                value: 4,
                label: "Year To Date",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year, 1, 1)).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            lastYear: {
                value: 5,
                label: "Last Year",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year - 1, 1, 1)).toUTC();
                    let endDate = asDate(DateTime.local(DateTime.now().year, 1, 1)).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            }
        };

        this.selectStatusOptions = {
            none: {
                value: 0,
                label: "None",
            },
            productSearchWorking: {
                value: 1,
                label: "Product Search Working",
            },
            productNotInFeed: {
                value: 2,
                label: "Product Not In Feed",
            },
            merchantNotIntegrated: {
                value: 3,
                label: "Merchant Not Integrated",
            },
            actionRequired: {
                value: 4,
                label: "Action Required",
            },
        };

        this.state = {
            productNotFoundData: [],
            productNotFoundDataLoading: false,
            selectOptions: {
                options: [
                    this.selectOptions.last24Hours,
                    this.selectOptions.last7Days,
                    this.selectOptions.last30Days,
                    this.selectOptions.last90Days,
                    this.selectOptions.yearToDate,
                    this.selectOptions.lastYear,
                ],
                defaultValue: this.selectOptions.last7Days,
                currentValue: this.selectOptions.last7Days,
            },
            selectStatusOptions: {
                options: [
                    this.selectStatusOptions.none,
                    this.selectStatusOptions.productSearchWorking,
                    this.selectStatusOptions.productNotInFeed,
                    this.selectStatusOptions.merchantNotIntegrated,
                    this.selectStatusOptions.actionRequired,
                ],
                defaultValue: this.selectStatusOptions.none,
                currentValue: this.selectStatusOptions.none,
            },
            productNotFoundTable: {
                title: "Product Not Found",
                columns: [
                    {
                        name: "createdDate",
                        label: "Date Created",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                            }
                        }
                    },
                    {
                        name: "userId",
                        label: "User Id",
                        options: {
                            filter: true,
                            sort: true,
                            display: false
                        }
                    },
                    {
                        name: "userName",
                        label: "User Name",
                        options: {
                            filter: true,
                            sort: true
                        }
                    },
                    {
                        name: "hostname",
                        label: "Hostname",
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "url",
                        label: "URL",
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: () => ({ style: { maxWidth: "600px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                        }
                    },
                    {
                        name: "status",
                        label: "Status",
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: () => ({ style: { minWidth: "250px" } }),

                            customBodyRender: (value, tableMeta, updateValue) => {
                                const id = tableMeta.rowData[6];
                                const rowStatus = this.getStatusSelectOption(value);
                                return (
                                    <Select
                                        isMulti={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        placeholder="Status..."
                                        blurInputOnSelect={true}
                                        captureMenuScroll={true}
                                        onChange={(e) => { this.handleStatusSelect(e, id) }}
                                        options={this.state.selectStatusOptions.options}
                                        value={rowStatus}
                                    />
                                );
                            }
                        }
                    },
                    {
                        name: "id",
                        label: "Database ID",
                        options: {
                            filter: true,
                            sort: true,
                            display: false
                        }
                    },
                ],
                endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
                options: { selectableRows: false },
               
                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTable: {
                            root: {
                            },
                            paper: {
                            },
                            //ResponseScroll: {
                            //    maxWidth: "100%"
                            //}
                        },
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                        MUIDataTableBodyCell: {
                            root: {
                            }
                        },

                    }
                })
            },
        }
    }

    componentDidMount = async () => {
        await this.getProductNotFoundData();
        let productNotFoundTable = { ...this.state.productNotFoundTable };
        productNotFoundTable.data = this.state.getProductNotFoundData;
        this.setState({ productNotFoundTable });

    }

    getProductNotFoundData = async () => {
        this.setState({ productNotFoundDataLoading: true });

        //let startDate = asDate(DateTime.local(DateTime.now().year - 1, 1, 1)).toUTC();
        //let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();
        let startDate = this.state.selectOptions.currentValue.getDateRange().startDate;
        let endDate = this.state.selectOptions.currentValue.getDateRange().endDate;

        this.updateProductNotFound(startDate, endDate);
    }

    updateProductNotFound = async (startDate, endDate) => {

        let now = DateTime.now();
        let offset = now.offset;

        let updateProductsNotFoundUrl = '/Products/GetProductNotFound';

        if (startDate) {
            updateProductsNotFoundUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            updateProductsNotFoundUrl += updateProductsNotFoundUrl.includes('?') ? `&endDate=${endDate.toSQL({ includeOffset: false })}` : `?endDate=${endDate.toSQL({ includeOffset: false })}`;
        }

        updateProductsNotFoundUrl += `&offset=${offset}`

        this.setState({ productNotFoundDataLoading: true })
        let productNotFoundData = await getRequest(updateProductsNotFoundUrl) ?? [];

        this.setState({ productNotFoundData, productNotFoundDataLoading: false });

    }

    getStatusSelectOption = (status) => {
        if (status != null && status != undefined) {

            if (status === "Product Search Working") {
                return this.selectStatusOptions.productSearchWorking;
            }
            if (status === "Product Not In Feed") {
                return this.selectStatusOptions.productNotInFeed;
            }
            if (status === "Merchant Not Integrated") {
                return this.selectStatusOptions.merchantNotIntegrated;
            }
            if (status === "Action Required") {
                return this.selectStatusOptions.actionRequired;
            }
        }

        return this.selectStatusOptions.none;
    }

    handleSelect = async (selectedOption) => {
        if (selectedOption) {
            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = selectedOption;
            this.setState({ selectOptions });
        }
        const dateRange = selectedOption?.getDateRange();
        this.updateProductNotFound(dateRange?.startDate, dateRange?.endDate);
    }

    updateProductNotFoundStatus =  async(id, statusId, status) => {
        if (id && statusId >= 0) {
            let productNotFoundData = this.state.productNotFoundData.map(data => {
                if (data.id === id) {
                    data.statusId = statusId;
                    data.status = status;
                }
                return data;
            });
            this.setState({ productNotFoundData });

            let updateProductNotFoundStatusUrl = `/Products/PostProductNotFoundStatus?id=${id}&statusId=${statusId}`;
            await getRequest(updateProductNotFoundStatusUrl);
        }
    }

    handleStatusSelect = async (selectedOption, id) => {
        this.updateProductNotFoundStatus(id, selectedOption.value, selectedOption.label);
    }

    render() {
        return (
            <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>
                {
                    this.state.productNotFoundDataLoading ?
                        <LoadSpinnerOverlay />
                        : <></>
                }

                <div className="search-interest py-4 mx-auto">
                    <div className="search-block">
                        <div className="row gap-5 mx-0">
                            <div className="col-12 col-md-4 mt-1 position-relative float-right" style={{ zIndex: 500 }}>
                                <Select
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    placeholder="Select timeframe ..."
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    onChange={(e) => { this.handleSelect(e) }}
                                    options={this.state.selectOptions.options}
                                    defaultValue={this.state.selectOptions.defaultValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="d-flex m-3">*/}
                {/*    <div className="dropdown ml-auto">*/}
                {/*        <Link to={`/Admin/ITOperations/ProductNotFound`}>*/}
                {/*            <button className="btn btn-primary" type="button" id="productNotFoundButton">*/}
                {/*                Product Not Found*/}
                {/*            </button>*/}
                {/*        </Link>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.productNotFoundTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.productNotFoundTable.title}
                                        data={this.state.productNotFoundData}
                                        columns={this.state.productNotFoundTable.columns}
                                        options={this.state.productNotFoundTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ProductNotFound;