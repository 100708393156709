import React from "react"

/**
 * 
 * @param {{width:number; height:number}} props
 */
const ThreeDotsHorizontalIcon = (props) => {
    return (
        <svg width={props.width || "14"} height={props.height|| "4"} viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.99967 0.333008C1.08301 0.333008 0.333008 1.08301 0.333008 1.99967C0.333008 2.91634 1.08301 3.66634 1.99967 3.66634C2.91634 3.66634 3.66634 2.91634 3.66634 1.99967C3.66634 1.08301 2.91634 0.333008 1.99967 0.333008ZM11.9997 0.333008C11.083 0.333008 10.333 1.08301 10.333 1.99967C10.333 2.91634 11.083 3.66634 11.9997 3.66634C12.9163 3.66634 13.6663 2.91634 13.6663 1.99967C13.6663 1.08301 12.9163 0.333008 11.9997 0.333008ZM5.33301 1.99967C5.33301 1.08301 6.08301 0.333008 6.99967 0.333008C7.91634 0.333008 8.66634 1.08301 8.66634 1.99967C8.66634 2.91634 7.91634 3.66634 6.99967 3.66634C6.08301 3.66634 5.33301 2.91634 5.33301 1.99967Z" fill="black" fillOpacity="0.4" />
        </svg>
    )
}

export default ThreeDotsHorizontalIcon;