import { getRequest } from "../sharedUtils/httpUtils";

// Updated function to return shortened URL without an API call regardless of localhost, dev, or app sites in use
export const getPostShareLink = shareCode => {
    const host = window.location.host;

    if (host.includes("localhost")) {
        return "https://" + host + "/plink/" + shareCode;
    }

    const hostArr = host.split(".");
    return "https://" + hostArr[0] + ".rockporch.com/plink/" + shareCode;
}

export const getLockerShareLink = shareCode => {
    const host = window.location.host;

    if (host.includes("localhost")) {
        return "https://" + host + "/llink/" + shareCode;
    }

    const hostArr = host.split(".");
    return "https://" + hostArr[0] + ".rockporch.com/llink/" + shareCode;
}

export const getBlogShareLink = shareCode => {
    const host = window.location.host;

    if (host.includes("localhost")) {
        return "https://" + host + "/blink/" + shareCode;
    }

    const hostArr = host.split(".");
    return "https://" + hostArr[0] + ".rockporch.com/blink/" + shareCode;
}

export const getProductShareLink = (shareCode, isBrowse = false) => {
    const host = window.location.host;
    const browse = isBrowse ? "?b=1" : "";
    if (host.includes("localhost")) {
        return "https://" + host + "/prdlink/" + shareCode + browse;
    }

    const hostArr = host.split(".");
    return "https://" + hostArr[0] + ".rockporch.com/prdlink/" + shareCode + browse;
}

export const getMerchantShareLink = urlFragment => {
    const host = window.location.host;

    if (host.includes("localhost")) {
        return "https://" + host + urlFragment;
    }

    const hostArr = host.split(".");
    return "https://" + hostArr[0] + ".rockporch.com" + urlFragment;
}

export const getReferralShareLink = urlFragment => {
    const host = window.location.host;

    if (host.includes("localhost")) {
        return "https://" + host + '/Identity/Account/Register?returnUrl=/Account/CreatorApp&ReferralUser=' + urlFragment;
    }

    const hostArr = host.split(".");
    return "https://" + hostArr[0] + ".rockporch.com" + '/Identity/Account/Register?returnUrl=/Account/CreatorApp&ReferralUser=' + urlFragment;
}

// OLD API CALLS THAT ARE NOT CURRENTLY USED

export async function getPostShareLinkOld(shareCode) {
    let request = await getRequest("/api/Post/GetShortenedUrl");
    let shareLink = request.url + (request.url.endsWith('/') ? "" : "/") + `${shareCode}`;
    return shareLink
}

export async function getLockerShareLinkOld(shareCode) {
    let request = await getRequest("/api/Locker/GetShortenedUrl");
    let shareLink = request.url + (request.url.endsWith('/') ? "" : "/") + `${shareCode}`;
    return shareLink
}

export async function getProductShareLinkOld(shareCode) {
    let request = await getRequest("/Products/GetShortenedUrl");
    let shareLink = request.url + (request.url.endsWith('/') ? "" : "/") + `${shareCode}`;
    return shareLink
}

