import React from "react";
import { Link } from "react-router-dom";
import { handleCommentMarkup,replaceUrls } from "sharedUtils/markupUtils";
import BlueTickIconXY from "components/icons/BlueTickIconXY"

/**
 * 
 * @param {{notification:object, onClick:Function, commentText:string}} props
 */
const NotificationContent = (props) => {

    let { notification } = props;

    let notificationType = notification.userNotificationTypeId;

    let isCommentMentionNotification = [1, 2, 3, 4].includes(notificationType);
    let isCommentNotification = [6,7,8].includes(notificationType);

    let userName = isCommentMentionNotification === true || isCommentNotification === true ? `${notification.commentingUserName}` : `${notification.postingUserName}`

    let isCommentingOn = "";

    let mainNotificationText = () => {
        if (isCommentMentionNotification) {
            return " mentioned you in a comment on a "
        } else if (isCommentNotification) {
            return " commented on your "
        } else {
            return " mentioned you in a "
        }
    }

    let parseCommentText = (text) => {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal);
            }
            else {
                return retVal;
            }
        });

        return markupHandled;
    }

    switch (notificationType) {
        case 1:
        case 8:
            isCommentingOn = "product";
            break;
        case 2:
        case 4:
        case 6:
            isCommentingOn = "post";
            break;
        case 3:
        case 5:
        case 7:
            isCommentingOn = "locker";
            break;
        default:
            isCommentingOn = "post";
    }
    
    return (
        <>
            <Link to={`/Profile/${userName}`}>
                { `@${userName}` }                             
            </Link> 

            {mainNotificationText()}
            
            <Link to={`#`} onClick={e => props.onClick(e, notification)}>
                {isCommentingOn}
            </Link>
            : {parseCommentText(props.commentText)}
        </>
        )
}

export default NotificationContent;