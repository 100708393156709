import React, { Component } from "react";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import toast, { Toaster } from 'react-hot-toast';
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import TextField from '@material-ui/core/TextField';
import { getRequest, postRequest } from "sharedUtils/httpUtils";

const { DateTime } = require("luxon");

class UserMerchantCommissionOverrides extends Component {
    state = {
        // https://localhost:44334/Admin/CommissionOverrides?useMockData=true
        useMockData: document.location.search.toLowerCase().indexOf('useMockData=true'.toLowerCase()) > 0,
        userMerchantCommissionOverridesLoading: false,
        userMerchantCommissionOverridesSummaryTable: {
            title: "Current and Future Overrides",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "userId",
                    label: "User Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userName",
                    label: "Username",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userDisplayName",
                    label: "User",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userMerchantCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let userName = item.userName;
                                let userDisplayName = item.userDisplayName;
                                let url = `/Profile/${userName}`;
                                return <Link to={url}>{userDisplayName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userEmail",
                    label: "User Email",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userCreatedDate",
                    label: "User Signup Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: false, // Column is not visible. Adding so that it will be included in CSV export.
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "merchantAffiliateProgramManagerId",
                    label: "Merchant Affiliate Program Manager Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerId",
                    label: "Affiliate Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userMerchantCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let affiliateProgramManagerId = item.affiliateProgramManagerId;
                                let affiliateProgramManagerName = item.affiliateProgramManagerName;
                                let url = `/Admin/AffiliateNetworks/${affiliateProgramManagerId}`;
                                return <Link to={url}>{affiliateProgramManagerName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "merchantId",
                    label: "Merchant Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "merchantName",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userMerchantCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let merchantId = item.merchantId;
                                let merchantName = item.merchantName;
                                let url = `/Admin/Brand/${merchantId}`;
                                return <Link to={url}>{merchantName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userShare",
                    label: "User Share",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (value * 100).toFixed(0) + '%';
                        }
                    }
                },
                {
                    name: "effectiveDate",
                    label: "Effective Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!value) {
                                return value;
                            }
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                }
            ],
            data: [],
            endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'effectiveDate',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/pickers/ */}
                            <TextField
                                id="commissionOverrideSummaryEndDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={this.state.userMerchantCommissionOverridesSummaryTable.endDate}
                                className="mx-3"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    let table = this.state.userMerchantCommissionOverridesSummaryTable;
                                    table.endDate = e.target.value;
                                    this.setState({ userMerchantCommissionOverridesSummaryTable: table });
                                }}
                            />

                            <button className="btn btn-primary mt-auto" type="button" onClick={(e) => {
                                this.handleUserMerchantUpdateEndDate(
                                    selectedRows,
                                    setSelectedRows,
                                    this.state.userMerchantCommissionOverridesSummaryTable.data,
                                    this.state.userMerchantCommissionOverridesSummaryTable.endDate);
                            }}>
                                Update End Date
                            </button>
                        </div>
                    )
                },
                onRowClick: async (rowData, rowMeta) => {
                    let item = this.state.userMerchantCommissionOverridesSummaryTable.data[rowMeta.dataIndex];
                    if (!item) {
                        return;
                    }


                    const userName = item.userName;
                    const merchantAffiliateProgramManagerId = item.merchantAffiliateProgramManagerId;
                    const affiliateProgramManagerId = item.affiliateProgramManagerId;
                    const affiliateProgramManagerName = item.affiliateProgramManagerName;
                    const merchantId = item.merchantId;
                    const merchantName = item.merchantName;

                    this.getUserMerchantCommissionOverrideHistory(merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId);

                    let userMerchantCommissionOverridesHistoryTable = this.state.userMerchantCommissionOverridesHistoryTable;
                    userMerchantCommissionOverridesHistoryTable.title = `History for ${userName} / ${merchantName} (Affiliate: ${affiliateProgramManagerName})`;
                    this.setState({ userMerchantCommissionOverridesHistoryTable });

                    this.userMerchantCommissionOverridesHistoryTableWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
                }
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        },
        userMerchantCommissionOverridesHistoryTable: {
            title: "History",
            columns: [
                {
                    name: "id",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "userId",
                    label: "User Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userName",
                    label: "Username",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userDisplayName",
                    label: "User",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userMerchantCommissionOverridesSummaryTable.data[dataIndex];
                            if (item) {
                                let userName = item.userName;
                                let userDisplayName = item.userDisplayName;
                                let url = `/Profile/${userName}`;
                                return <Link to={url}>{userDisplayName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userEmail",
                    label: "User Email",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userCreatedDate",
                    label: "User Signup Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: false, // Column is not visible. Adding so that it will be included in CSV export.
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "merchantAffiliateProgramManagerId",
                    label: "Merchant Affiliate Program Manager Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerId",
                    label: "Affiliate Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userMerchantCommissionOverridesHistoryTable.data[dataIndex];
                            if (item) {
                                let affiliateProgramManagerId = item.affiliateProgramManagerId;
                                let affiliateProgramManagerName = item.affiliateProgramManagerName;
                                let url = `/Admin/AffiliateNetworks/${affiliateProgramManagerId}`;
                                return <Link to={url}>{affiliateProgramManagerName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "merchantId",
                    label: "Merchant Id",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "merchantName",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.userMerchantCommissionOverridesHistoryTable.data[dataIndex];
                            if (item) {
                                let merchantId = item.merchantId;
                                let merchantName = item.merchantName;
                                let url = `/Admin/Brand/${merchantId}`;
                                return <Link to={url}>{merchantName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userShare",
                    label: "User Share",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (value * 100).toFixed(0) + '%';
                        }
                    }
                },
                {
                    name: "effectiveDate",
                    label: "Effective Date",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "endDate",
                    label: "End Date",
                    options: {
                        filter: false,
                        sort: true,
                        display: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!value) {
                                return value;
                            }
                            return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                        }
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "modifiedBy",
                    label: "Modified By",
                    options: {
                        filter: false,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                }
            ],
            data: [],
            endDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'id',
                    direction: 'desc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/pickers/ */}
                            <TextField
                                id="commissionOverrideSummaryEndDate"
                                label="End Date"
                                type="datetime-local"
                                defaultValue={this.state.userMerchantCommissionOverridesHistoryTable.endDate}
                                className="mx-3"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    let table = this.state.userMerchantCommissionOverridesHistoryTable;
                                    table.endDate = e.target.value;
                                    this.setState({ userMerchantCommissionOverridesHistoryTable: table });
                                }}
                            />

                            <button className="btn btn-primary mt-auto" type="button" onClick={(e) => {
                                this.handleUserMerchantUpdateEndDate(
                                    selectedRows,
                                    setSelectedRows,
                                    this.state.userMerchantCommissionOverridesHistoryTable.data,
                                    this.state.userMerchantCommissionOverridesHistoryTable.endDate);
                            }}>
                                Update End Date
                            </button>
                        </div>
                    )
                }
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            }
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        }
    }

    userMerchantCommissionOverridesHistoryTableWrapperRef = React.createRef();

    componentDidMount = async () => {
        await this.getUserMerchantCommissionOverrides();
    }

    getUserMerchantCommissionOverrides = async () => {
        this.setState({ userMerchantCommissionOverridesLoading: true });

        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));
            let userMerchantCommissionOverrides = this.getMockMerchantCommissionOverrides();

            let userMerchantCommissionOverridesSummaryTable = this.state.userMerchantCommissionOverridesSummaryTable;
            userMerchantCommissionOverridesSummaryTable.data = userMerchantCommissionOverrides;

            this.setState({ userMerchantCommissionOverridesSummaryTable: userMerchantCommissionOverridesSummaryTable, userMerchantCommissionOverridesLoading: false });
            return;
        }

        let getCurrentUserMerchantCommissionOverrides = '/api/Merchant/GetCurrentUserMerchantCommissionOverrides';
        getCurrentUserMerchantCommissionOverrides += `?offset=${DateTime.now().offset}`;

        let userMerchantCommissionOverrides = await getRequest(getCurrentUserMerchantCommissionOverrides) ?? [];

        let userMerchantCommissionOverridesSummaryTable = this.state.userMerchantCommissionOverridesSummaryTable;
        userMerchantCommissionOverridesSummaryTable.data = userMerchantCommissionOverrides;

        this.setState({ userMerchantCommissionOverridesSummaryTable: userMerchantCommissionOverridesSummaryTable, userMerchantCommissionOverridesLoading: false });
    }

    getUserMerchantCommissionOverrideHistory = async (merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId) => {
        this.setState({ userMerchantCommissionOverridesLoading: true });

        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));
            let userMerchantCommissionOverrideHistoryRecords = this.getMockMerchantCommissionOverrideHistory(merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId);

            let userMerchantCommissionOverridesHistoryTable = this.state.userMerchantCommissionOverridesHistoryTable;
            userMerchantCommissionOverridesHistoryTable.data = userMerchantCommissionOverrideHistoryRecords;

            this.setState({ userMerchantCommissionOverridesHistoryTable: userMerchantCommissionOverridesHistoryTable, userMerchantCommissionOverridesLoading: false });
            return;
        }

        let getUserMerchantCommissionOverrideHistoryUrl = '/api/Merchant/GetUserMerchantCommissionOverrideHistory';
        getUserMerchantCommissionOverrideHistoryUrl += `?offset=${DateTime.now().offset}`;
        getUserMerchantCommissionOverrideHistoryUrl += `&merchantAffiliateProgramManagerId=${(merchantAffiliateProgramManagerId)}`;

        let userMerchantCommissionOverrideHistoryRecords = await getRequest(getUserMerchantCommissionOverrideHistoryUrl) ?? [];

        let userMerchantCommissionOverridesHistoryTable = this.state.userMerchantCommissionOverridesHistoryTable;
        userMerchantCommissionOverridesHistoryTable.data = userMerchantCommissionOverrideHistoryRecords;

        this.setState({ userMerchantCommissionOverridesHistoryTable: userMerchantCommissionOverridesHistoryTable, userMerchantCommissionOverridesLoading: false });
    }

    updateUserMerchantCommissionOverrideEndDate = async (ids, endDate) => {
        if (this.state.useMockData) {
            await new Promise(r => setTimeout(r, 500));

            let userMerchantCommissionOverrides = ids.map((id) => {
                return {
                    id: id,
                    endDate: endDate,
                    modifiedBy: 'jimbo',
                    modifiedDate: new Date().toISOString()
                };
            });

            return userMerchantCommissionOverrides;
        }

        let request = {
            userMerchantCommissionOverrideHistoryIds: ids,
            endDate: endDate,
            offset: DateTime.now().offset
        }
        let url = '/api/Merchant/UpdateUserMerchantCommissionOverrideEndDate';
        let updatedUserMerchantCommissionOverrides = await postRequest(url, request);
        return updatedUserMerchantCommissionOverrides;
    }

    getMockMerchantCommissionOverrides = () => {
        function getRandomDateBetweenDates(startDate, endDate) {
            return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        }

        let mockData = [...Array(4).keys()].map(i => {
            return {
                id: i,
                merchantAffiliateProgramManagerId: i + 57,
                affiliateProgramManagerId: i,
                affiliateProgramManagerName: `Affiliate Name ${i}`,
                merchantId: i + 134,
                merchantName: `Merchant Name ${i + 134}`,
                userShare: Math.random(),
                effectiveDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                endDate: null,
                createdBy: 'joe',
                modifiedBy: 'greg'
            };
        });

        return mockData;
    }

    getMockMerchantCommissionOverrideHistory = (merchantAffiliateProgramManagerId, affiliateProgramManagerId, merchantId) => {
        function getRandomDateBetweenDates(startDate, endDate) {
            return new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        }

        let count = Math.floor(Math.random() * 4);
        let recordCount = count > 0 ? count : 1;
        let mockData = [...Array(recordCount).keys()].map(i => {
            return {
                id: i + 1001,
                merchantAffiliateProgramManagerId: merchantAffiliateProgramManagerId,
                affiliateProgramManagerId: affiliateProgramManagerId,
                affiliateProgramManagerName: `Affiliate Name ${affiliateProgramManagerId}`,
                merchantId: merchantId,
                merchantName: `Merchant Name ${merchantId}`,
                userShare: Math.random(),
                effectiveDate: getRandomDateBetweenDates(new Date(2021, 1, 1), new Date()).toISOString(),
                endDate: null,
                createdBy: 'joe',
                modifiedBy: 'greg'
            };
        });

        return mockData;
    }

    handleUserMerchantUpdateEndDate = async (selectedRows, setSelectedRows, tableData, endDate) => {
        let parsedDate = DateTime.fromISO(endDate);
        if (!parsedDate || !DateTime.isDateTime(parsedDate)) {
            window.alert("Invalid End Date");
            return;
        }

        var isoEndDate = parsedDate.toJSDate().toISOString();

        if (!selectedRows && !selectedRows.data && selectedRows.data.length === 0) {
            window.alert("Nothing to update as there are no selected rows");
            return;
        }

        let alreadyHasEndDateCount = 0;
        let userMerchantCommissionOverrideHistoryIds = [];
        selectedRows.data.forEach(row => {
            let item = tableData[row.dataIndex];
            if (item && item.endDate) {
                alreadyHasEndDateCount++;
            }

            userMerchantCommissionOverrideHistoryIds.push(item.id);
        });

        if (alreadyHasEndDateCount > 0) {
            let msg = `Warning: ${alreadyHasEndDateCount} ${alreadyHasEndDateCount == 1 ? 'record already has' : 'records already have'} an End Date set.`;
            msg += ` Are you sure you wish to update ${alreadyHasEndDateCount == 1 ? 'this record' : 'these records'} with a new End Date?`
            if (!window.confirm(msg)) {
                return;
            }
        }

        this.setState({ userMerchantCommissionOverridesLoading: true });

        let updatedRecords = await this.updateUserMerchantCommissionOverrideEndDate(userMerchantCommissionOverrideHistoryIds, isoEndDate);

        function updateData(data) {
            let recordsUpdatedCount = 0;
            let updatedData = [...data];

            updatedRecords.forEach((updatedRecord) => {
                for (let i = 0; i < data.length; i++) {
                    var item = updatedData[i];
                    if (item.id === updatedRecord.id) {
                        recordsUpdatedCount++;
                        updatedData[i] = {
                            ...item,
                            endDate: updatedRecord.endDate,
                            modifiedBy: updatedRecord.modifiedBy,
                            modifiedDate: updatedRecord.modifiedDate
                        }
                    }
                }
            });

            return recordsUpdatedCount > 0 ? updatedData : null;
        }

        // Update any matching Summary table records
        let userMerchantCommissionOverridesSummaryTable = this.state.userMerchantCommissionOverridesSummaryTable;
        let updatedSummaryData = updateData(userMerchantCommissionOverridesSummaryTable.data);
        if (updatedSummaryData) {
            userMerchantCommissionOverridesSummaryTable.data = updatedSummaryData;
            this.setState({ userMerchantCommissionOverridesSummaryTable: userMerchantCommissionOverridesSummaryTable });
        }

        // Update any matching History table records
        let userMerchantCommissionOverridesHistoryTable = this.state.userMerchantCommissionOverridesHistoryTable;
        let updatedHistoryData = updateData(userMerchantCommissionOverridesHistoryTable.data);
        if (updatedHistoryData) {
            userMerchantCommissionOverridesHistoryTable.data = updatedHistoryData;
            this.setState({ userMerchantCommissionOverridesHistoryTable: userMerchantCommissionOverridesHistoryTable });
        }

        setSelectedRows([]);
        this.setState({ userMerchantCommissionOverridesLoading: false });
    }

    render() {
        return (

            <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>
                {
                    this.state.usreMerchantCommissionOverridesLoading ?
                        <LoadSpinnerOverlay />
                        : <></>
                }

                <div className="d-flex m-3">
                    <div className="dropdown ml-auto">
                        <Link to={`/Admin/CommissionOverrides/AddUserMerchantCommissionOverride`}>
                            <button className="btn btn-primary" type="button" id="addUserMerchantOverrideButton">
                                Add User/Merchant Override
                            </button>
                        </Link>
                    </div>
                </div>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.userMerchantCommissionOverridesSummaryTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.userMerchantCommissionOverridesSummaryTable.title}
                                        data={this.state.userMerchantCommissionOverridesSummaryTable.data}
                                        columns={this.state.userMerchantCommissionOverridesSummaryTable.columns}
                                        options={this.state.userMerchantCommissionOverridesSummaryTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>

                <section ref={this.userMerchantCommissionOverridesHistoryTableWrapperRef}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.userMerchantCommissionOverridesHistoryTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.userMerchantCommissionOverridesHistoryTable.title}
                                        data={this.state.userMerchantCommissionOverridesHistoryTable.data}
                                        columns={this.state.userMerchantCommissionOverridesHistoryTable.columns}
                                        options={this.state.userMerchantCommissionOverridesHistoryTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default UserMerchantCommissionOverrides;