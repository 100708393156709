import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import { connect } from "react-redux";
import { toggleShareBlogModal, toggleShareDeepLinkModal } from "stateManagement/reducers/modalReducer";
import { Link } from "react-router-dom";

import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { isValidUrl } from "sharedUtils/validationUtils";
import ActionButton from "components/ActionButton";

/**
 * 
 * @param {{loggedInUser:object, userId:any,followersFollowing:string, setLoggedInUser:Function}} props
 */
const ShareDeepLinkModal = (props) => {

    const [showCopied2, setCopiedText2] = useState(false);
    const [validUrl, setValidUrl] = useState(true);
    const [merchantExists, setMerchantExists] = useState(true);
    const [merchantId, setMerchantId] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [urlInput, setUrlInput] = useState("");
    const [loading, setLoading] = useState(false);

    const resetParams = () => {
        setRedirectUrl("");
        setMerchantExists(true);
        setValidUrl(true);
        setUrlInput("");
        setMerchantId(0);
    }
    
    useEffect(() => {
        if (props.showModal === false) {
            resetParams();
        }
    }, [props.showModal])

    useEffect(() => {
        let isValid = isValidUrl(urlInput, true);
        if (urlInput.length > 3 && (isValid === false)) {
            setValidUrl(false);
        }
        else {
            setValidUrl(true);
        }
    }, [urlInput])

    const fetchMerchantByUrl = async (url) => {
        let route = '/api/Merchant/GetMerchantByHostname?url=' + url;

        setLoading(true);
        let merchant = await getRequest(route);
        setLoading(false);

        return merchant;
    }

    let renderCopiedText2 = () => {
        if (showCopied2) {
            setTimeout(() => { setCopiedText2(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    let handleInput = (e) => {
        let url = e.target.value;
        setUrlInput(url);
    }

    let handleDeepLinkChange = async (event) => {
        setRedirectUrl("");
        setValidUrl(true);

        let url = urlInput

        if (validUrl) {
            let merchant = await fetchMerchantByUrl(url);
            if (!!(merchant)) {
                setMerchantId(merchant.id);
                setMerchantExists(true);
                setRedirectUrl(window.location.origin + "/m/" + merchant.id + "?userId=" + props.loggedInUser.id + "&redirectUrl=" + url);
            } else {
                setMerchantExists(false);
                setRedirectUrl("");
                setMerchantId(0);
            }

        } else {
            setValidUrl(false);
            setRedirectUrl("");
        }
    };

    const onKeypress = (e) => {
        if (e.key === 'Enter') handleDeepLinkChange();
    }

    const displayValidationMessage = () => {
        if (!validUrl) {
            return (
                <p style={{ textAlign: "center", fontSize: "12px" }}>

                    Sorry, that doesn't look like a the URL to one of our Merchant Partners. <br />
                    Copy and paste the URL of the page you're trying to create a link for. <br />
                    Example: <span style={{ color: "#e55934" }}>https://www.example.com...</span>

                </p>
            )
        }
        else {
            return <span></span>
        }
    }

    const displayMerchantNotIntegratedMessage = () => {
        if (urlInput.length > 0 && isValidUrl && !merchantExists) {
            return (
                <div className="text-center">
                    <small style={{ color: "#e55934" }}>
                        Sorry!<br />
                        We are not partnered with this merchant yet. We recorded your attempt and are establishing new merchants every day.
                    </small>
                    <p>&nbsp;</p>
                    <h6 className="text">Click <Link to="/Account/Brands" onClick={props.toggleModal}>HERE</Link> for a list of Merchant Partners</h6>
                </div>
            )
        }
        else {
            return <span></span>
        }
    }

    return (
        <Modal isOpen={props.showModal} toggleModal={() => props.toggleModal(null)}>
            {
                <div className="text-center" style={props.customStyle}>
                    <div className="share-block" style={{ backgroundColor: "transparent", border: "none", borderRadius: "none" }}>

                        <div style={{ textAlign: "left" }}>
                            <h5 className="mb-4">Share a Deep Link</h5>
                            <h6 className="text">Share a link to a page from one of RockPorch's many <Link to="/Account/Brands" onClick={props.toggleModal}>Merchant Partners</Link>!</h6>
                            {/*<h5 className="mb-4">Share a Link to a Product or Page from one of RockPorch's many Merchants!</h5>*/}
                            {/*<p style={{ color: "red", display: urlInput.length > 0 && isValidUrl && !merchantExists ? "" : "none" }}>Merchant is not yet integrated yet with RockPorch.</p>*/}


                            <div className="mb-4 d-flex justify-content-around" style={{ marginLeft: "0px" }}>

                                {/*<input type="text" value={urlInput.length > 0 ? urlInput : ""} placeholder={redirectUrl.length === 0 ? "Paste URL for Deep Link here" : redirectUrl} id="myInput" onChange={(e) => handleInput(e)} />*/}
                                <input
                                    className="w-75 mr-3"
                                    type="text"
                                    value={urlInput.length > 0 ? urlInput : ""}
                                    id="myInput"
                                    placeholder={redirectUrl.length === 0 ? "Paste Merchant URL for Deep Link here" : redirectUrl}
                                    onChange={handleInput}
                                    onKeyPress={onKeypress}
                                    style={{ backgroundColor: "#e9ecef", border: "none" }}
                                    autoFocus
                                />
                                <ActionButton onClick={handleDeepLinkChange} className="btn btn-primary" isLoading={loading} isDisabled={urlInput.length === 0}>Submit</ActionButton>
                            </div>

                            {displayValidationMessage()}
                            {displayMerchantNotIntegratedMessage()
                            
}

                            <div className="clipboard mb-4 ml-0 mr-0 mt-0" style={{ minWidth: "100%", display: merchantId > 0 ? "" : "none" }}>
                                <input type="text" disabled defaultValue={!!(redirectUrl) ? redirectUrl : ""} id="myInput" onChange={() => null} />
                                <button className="btn" disabled={!validUrl || redirectUrl.length === 0} style={{ fontSize: "12px" }} onClick={() => { navigator.clipboard.writeText(redirectUrl); setCopiedText2(true); }}>{renderCopiedText2()}</button>
                            </div>
                            <ActionButton onClick={resetParams} className="btn btn-secondary btn-block">Reset</ActionButton>

                        </div>
                    </div>
                </div>
            }
        </Modal>

    )
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.shareDeepLinkModal.showShareDeepLinkModal,
    }
}

const mapDispatch = {
    toggleModal: toggleShareDeepLinkModal
}

export default connect(mapStateToProps, mapDispatch)(ShareDeepLinkModal);