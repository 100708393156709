import React from "react";
import { Form, Button, Spinner } from "reactstrap";
import { isValidPassword } from "sharedUtils/validationUtils";
import { postRequest } from "../../sharedUtils/httpUtils";
import FormInputField from "../../components/FormInputField";

class ChangePasswordForm extends React.Component {
    minLengthMessage = 'Minimum 8 characters';
    doesNotMatchMessage = 'Password does not match';
    requiredCharactersMessage = 'Password must contain one uppercase letter, one lowercase letter, and one number';

    state = {
        formData: {
            password: "",
            newPassword: "",
            confirmPassword: ""
        },
        minLengthMessage: "",
        doesNotMatchMessage: "",
        requiredCharactersMessage: "",
        saving: false,
        passwordMessage: "",
        passwordMessageStyle: ""
    };

    onInputChange = (e) => {
        const { name, value } = e.target;
        const formData = { ...this.state.formData, [name]: value };

        // Set password validation messages
        if (name === "newPassword") {
            if (value.length < 8 && value.length > 0) {
                this.setState({ minLengthMessage: this.minLengthMessage, requiredCharactersMessage: "" });
            } else if (!isValidPassword(value)) {
                this.setState({ requiredCharactersMessage: this.requiredCharactersMessage, minLengthMessage: "" });
            } else {
                this.setState({ minLengthMessage: "", requiredCharactersMessage: "" });
            }
        }

        // Set password match message
        if (name === "confirmPassword" || name === "newPassword") {
            if (formData.newPassword !== formData.confirmPassword) {
                this.setState({ doesNotMatchMessage: this.doesNotMatchMessage });
            } else {
                this.setState({ doesNotMatchMessage: "" });
            }
        }

        this.setState({ formData });
    };

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ saving: true });

        const { password, newPassword } = this.state.formData;
        const userUrl = '/api/Account/UpdatePassword';

        let result = await postRequest(userUrl, { password, newPassword });

        if (result) {
            this.setState({ passwordMessageStyle: "text-success", passwordMessage: "Password updated successfully" });
        } else {
            this.setState({ passwordMessageStyle: "form-text-alert", passwordMessage: "Unable to update password. Please try again." });
            this.props.handlePasswordError();
        }
        this.setState({ saving: false });
    };

    renderSubmitButton = () => {
        const { password, newPassword, confirmPassword } = this.state.formData;
        const isValid = isValidPassword(password) && isValidPassword(newPassword);
        const canSubmit = password && newPassword.length >= 8 && confirmPassword === newPassword && isValid;

        return (
            <Button color="secondary" className="mt-3" onClick={this.onSubmit} disabled={!canSubmit || this.state.saving}>
                {this.state.saving ? <Spinner size="sm" /> : "Submit"}
            </Button>
        );
    };

    render() {
        return (
            <Form onSubmit={this.onSubmit} className="p-3">
                <h5>Change Password</h5>

                <FormInputField
                    title="Current Password"
                    name="password"
                    inputType="password"
                    value={this.state.formData.password}
                    onInputChange={this.onInputChange}
                    smallText={this.state.passwordMessage}
                    smallTextStyle={this.state.passwordMessageStyle}
                />

                <FormInputField
                    title="New Password"
                    name="newPassword"
                    inputType="password"
                    onInputChange={this.onInputChange}
                    value={this.state.formData.newPassword}
                    smallText={this.state.minLengthMessage + this.state.requiredCharactersMessage}
                />

                <FormInputField
                    title="Confirm New Password"
                    name="confirmPassword"
                    inputType="password"
                    value={this.state.formData.confirmPassword}
                    onInputChange={this.onInputChange}
                    smallText={this.state.doesNotMatchMessage}
                />

                {this.renderSubmitButton()}
            </Form>
        );
    }
}

export default ChangePasswordForm;
