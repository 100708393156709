import React from "react";

const PlusIconNav = (props) => {
    return (
        <svg width={props.width || 16} height={props.height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9944 7.00012H8.98156V1.00293C8.98156 0.45293 8.53156 0.00292969 7.98156 0.00292969C7.43156 0.00292969 6.98156 0.45293 6.98156 1.00293V7.00012H1C0.45 7.00012 0 7.45012 0 8.00012C0 8.55012 0.45 9.00012 1 9.00012H6.98156V14.9973C6.98156 15.5473 7.43156 15.9973 7.98156 15.9973C8.53156 15.9973 8.98156 15.5473 8.98156 14.9973V9.00015H14.9944C15.5444 9.00015 15.9944 8.55015 15.9944 8.00015C15.9944 7.45015 15.5444 7.00015 14.9944 7.00015V7.00012Z" fill="white" />
        </svg>
        )
}

export default PlusIconNav;