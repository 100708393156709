import React from "react";
import { NavbarBrand } from 'reactstrap';
import UserTileGeneral from "components/UserTileGeneral";
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";

import LoadSpinner from "components/LoadSpinner";
import authService from "../../components/api-authorization/AuthorizeService";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { getProductShareLink } from "../../sharedUtils/shareLinkPrefixes"

var jsonref = require('jsonref');

class ProductPageEmbed extends React.Component {
    commentsTextArea = React.createRef();
    browse = false;

    state = {
        redirect: false,
        loadingPartial: true,
        loading: true,
        loadingComments: false,
        loadingNextLinkedPosts: false,
        loadingPrevLinkedPosts: false,
        loadingBuy: false,
        commentInput: "",
        isFollowing: false,
        shareCode: "",
        linkedPostPage: 0,
        lastLinkedPostPage: 0,
        userDescriptionPage: 0,
        userDescriptions: [],
        productInfo: {
            userMerchantProductId: 0,
            userDescription: "",
            merchantProductId: 0,
            userRating: 0,
            rating: 0,
            displayRating: 0,
            ratingCount: 0,
            description: "",
            referalLink: {},
            manufacturer: "",
            name: "",
            price: 0,
            likes: 0,
            likedByUser: false,
            savedByUser: false,
            imageUrl: "",

            user: {
                name: "",
                userName: "",
                imageUrl: "",
                tagLine: "",
                bio: "",
                proStatusId: 0
            },
            comments: [],
            linkedPosts: []
        },
        hidePageExtras: false,
        onlyIntegrated: true,
        shareLink: "",
        isUserMerchant: false,
    }

    async componentDidMount() {
        this.props.setShowTopNav(false);

        let search = decodeURIComponent(this.props.location?.search);
        let params = new URLSearchParams(search);

        let pathname = decodeURIComponent(this.props.location?.pathname);

        let search2 = pathname.split('?');
        if (search == "" && search2.length > 0) {
            params = new URLSearchParams('?' + search2[1]);
        }

        let redirect = !this.props.isModal && (params.get('r') === '1' || params.get('r') == null);
        let browse = params.get('b') === '1';

        this.browse = browse; 

        if (this.props.isModal !== true) {
        //    this.props.closeAllModals()
        } else {
            this.setState({ hidePageExtras: false })
        }

        let shareCode = "";
        let productInfo = null;
        let loggedInUser = null;

        // feed
        if (this.props.match.params != undefined && this.props.match.params.product != undefined) {
            shareCode = this.props.match.params.product.shareCode;
            productInfo = this.props.match.params.product;
            productInfo.comments = [];
            productInfo.linkedPosts = [];
            loggedInUser = this.props.match.params.loggedInUser;
            // modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params &&
            this.props.location.state.destinationState.params.product != undefined) {
            shareCode = this.props.location.state.destinationState.params.product.shareCode;
            productInfo = this.props.location.state.destinationState.params.product;
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;
            // direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }

        shareCode = decodeURIComponent(shareCode).split('?')[0];

        await this.setState({ shareCode });

        if (redirect) {
            await this.populateData(); // this will track the product view

            if (this.state.productInfo.integrated) {
                this.setState({ shareCode, redirect: true });
            }
            return;
        };

        let shareLink = await getProductShareLink(shareCode);

        await this.setState({ shareCode, productInfo, loggedInUser, shareLink, redirect, isUserMerchant: productInfo?.user?.id === this.props.loggedInUser?.id }, () => {
            this.populateData();
        });

        if (this.commentsTextArea?.current != null && this.props.isModal === true && this.props.isCommenting === true) {
            this.setState({ hidePageExtras: false }, () => {
                setTimeout(() => {
                    this.commentsTextArea.current.scrollIntoView();
                    this.commentsTextArea.current.focus();
                }, 500);

            });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.shareCode != prevProps.match.params.shareCode) {
            this.setState({ loading: true });
            let isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();

            let shareCode = this.props.match.params.shareCode;

            let product = this.props.location.state.destinationState.params.product;

            if (product == null || product == undefined) {

                product = await this.fetchProduct(shareCode);

                if (product == null || product == undefined) {
                    this.props.history.push("/NotFound");
                    return -1;
                }
                else {
                    await this.getLikes(isAuthenticated, product.userMerchantProductId);
                    await this.getSavedByUser(product.userMerchantProductId);
                    await this.getRating(isAuthenticated, product.userMerchantProductId);
                    await this.loadUserDescriptions(product.userMerchantProductId, this.state.loggedInUser ? this.state.loggedInUser.id : -1);


                }
            }
            this.loadLinkedPosts(0);

            this.setState({ productInfo: product, loading: false, isUserMerchant: product?.user?.id === this.props.loggedInUser?.id });
        }
    }

    fetchProduct = async (shareCode) => {
        let isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();
        let browse = this.browse ? "&browse=true" : "";
        let baseUrl = isAuthenticated === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let productUrl = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}${browse}`;

        let product = await getRequest(productUrl);


        return product;

    }

    async populateData() {
        var isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();
        let browse = this.browse ? "&browse=true" : "";

        let baseUrl = isAuthenticated === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let shareCode = this.props.match.params.shareCode;
        let productUrl = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}${browse}`;

        var data;
        if (this.state.productInfo && this.state.productInfo.userMerchantProductId && this.state.productInfo.userMerchantProductId !== 0) {
            data = this.state.productInfo;

            let trackBaseUrl = isAuthenticated ? `/Products/TrackProductViewAuthorized` : `/Products/TrackProductView`
            let trackProductViewUrl = `${trackBaseUrl}?userMerchantProductId=${data.userMerchantProductId}&referrer=${document.referrer}${browse}`;
            getRequest(trackProductViewUrl);
        } else {
            data = await getRequest(productUrl);
            
            if (data == null) {
                data = await getRequest(`/Products/Get?shareCode=${shareCode}&referrer=${document.referrer}${browse}`)
            }
        }

        if (data === undefined || data === null) {
            this.setState({ productInfo: null });
            this.props.history.push("/NotFound");
            return -1;
        }

        await this.setState({ productInfo: data, loading: false, isUserMerchant: data?.user?.id === this.props.loggedInUser?.id });

        if (data.inUserInventory == null || data.inUserInventory == undefined) {
            await this.getInUserInventory(isAuthenticated, data.merchantProductId);
        }

        if (data.likes == null || data.likes === 0) {
            this.getLikes(isAuthenticated, data.userMerchantProductId);
        }

        if (data.ratingAverage == null || data.ratingAverage === 0) {
            this.getRating(isAuthenticated, data.userMerchantProductId);
        }

        if (data.savedByUser == null) {
            this.getSavedByUser(data.userMerchantProductId);
        }

        this.loadUserDescriptions(data.userMerchantProductId, this.state.loggedInUser ? this.state.loggedInUser.id : -1);

        this.setState({ loadingPartial: false });

        if (data.comments?.length == 0) { this.loadPreviousComments(0, 3); }
        this.loadLinkedPosts(0);

        if (this.state.loggedInUser) {
            let isFollowing = await getRequest(`/api/Account/IsFollowing?id=${this.state.productInfo.user.id}`);
            this.setState({ isFollowing: isFollowing });
        }
    }


    getInUserInventory = async (isAuthenticated, merchantProductId) => {
        if (isAuthenticated) {
            let url = `/Products/InInventory?merchantProductId=${merchantProductId}`;
            let prod = await getRequest(url);
            let productInfo = { ...this.state.productInfo };
            productInfo.inUserInventory = prod;
            this.setState({ productInfo });
        }
    }

    getLikes = async (isAuthenticated, userMerchantProductId) => {
        return;
        let url = isAuthenticated ? `/Products/GetLikesAuthorized/?userMerchantProductId=${userMerchantProductId}` : `/Products/GetLikes/?userMerchantProductId=${userMerchantProductId}`;

        var likes = await getRequest(url);
        let productInfo = { ...this.state.productInfo };
        productInfo.likes = likes?.count;
        productInfo.likedByUser = likes?.likedByUser;

        this.setState({ productInfo });
    }

    getRating = async (isAuthenticated, userMerchantProductId) => {
        let url = isAuthenticated ? `/Products/GetRatingAuthorized/${userMerchantProductId}` : `/Products/GetRating/${userMerchantProductId}`;

        var rating = await getRequest(url);
        if (rating == null) {
            rating = await getRequest(`/Products/GetRating/${userMerchantProductId}`);
        }
        let productInfo = { ...this.state.productInfo };
        productInfo.userRating = rating?.userRating;
        productInfo.rating = rating?.average;
        productInfo.ratingCount = rating?.count;
        productInfo.displayRating = rating?.average;

        this.setState({ productInfo });
    }


 
    getSavedByUser = async (userMerchantProductId) => {
        let url = `/Products/IsSaved/${userMerchantProductId}`;
        let savedByUser = await getRequest(url);

        let productInfo = { ...this.state.productInfo };
        productInfo.savedByUser = savedByUser;

        this.setState({ productInfo });
    }



    loadPreviousComments = async (skip = this.state.productInfo.comments.length, take = 25) => {
        this.setState({ loadingComments: true });

        let url = this.state.loggedInUser ? '/Products/GetCommentsAuthorized?shareCode=' : '/Products/GetComments?shareCode=';
        const token = await authService.getAccessToken();
        const response = await fetch(url + this.state.shareCode + "&skip=" + skip + "&take=" + take, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        let data = await response.json();

        data = await jsonref.parse(data, { scope: window.location });

        let comments = [...this.state.productInfo.comments]
        comments = data.concat(comments);
        this.setState({ productInfo: { ...this.state.productInfo, ...{ comments } } });
        this.setState({ loadingComments: false });
    }


    loadUserDescriptions = async (userMerchantProductId, userId) => {
        let userDescriptions = await getRequest(`/Products/GetWithUserDescriptions?userMerchantProductId=${userMerchantProductId}&userId=${userId}`);
        this.setState({ userDescriptions });
    }

    loadLinkedPosts = async (pageNumber) => {
        const token = await authService.getAccessToken();

        const response = await fetch((this.state.loggedInUser ? "/Products/GetLinkedPostsAuthorized" : "/Products/GetLinkedPosts") + "?userMerchantProductId=" + this.state.productInfo.userMerchantProductId + "&page=" + pageNumber, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        let linkedPosts = await response.json();

        linkedPosts = await jsonref.parse(linkedPosts, { scope: window.location });

        if (linkedPosts.length > 0) {
            this.setState({ lastLinkedPostPage: Math.ceil(linkedPosts[0].totalLinkedPosts / 4) - 1, linkedPostPage: pageNumber });
        }

        this.setState({ productInfo: { ...this.state.productInfo, ...{ linkedPosts } } });

        this.setState({ loadingNextLinkedPosts: false });
        this.setState({ loadingPrevLinkedPosts: false });
    }


    getUser = async (userId) => {
        return await getRequest(`/api/Account/GetById?userId=${userId}`);
    }

    renderFollowButton() {
        return (
            <NavbarBrand onClick={(e) => {
                e.preventDefault();
                let url = `/explore`;
                window.open(url);
            }}
                id="rp-nav-logo" to="/Nav/Explore" style={{ position: "relative", cursor: "pointer", width: "100px", marginRight: 0 }}>
                <img src='/images/rockporch-logo.svg' alt='' />
            </NavbarBrand>
        )
    }
  
    followAction = async () => {
        let url = `/prf/${this.state.productInfo?.user?.userName}`
        window.open(url);
    }

    contentSectionSmall = () => {
        let content = handleCommentMarkup(this.state.post?.content, true, true);
        return (
            <p className="post-page-text mt-3"  style={{ ...this.state.contentStyle, whiteSpace: "nowrap", overflow: "hidden", maxWidth: "500px", textOverflow: "ellipsis" }}>
                {content}
            </p>
        )
    }

    render() {
        const browse = this.browse ? "&b=1" : "";
        if (this.state.loading) {
            return (
                <LoadSpinner />);
        }
       

        return (
            <section className={`container d-flex flex-column justify-content-center pl-0 pr-0`} style={{ maxWidth: "330px" }} ref={this.totalHeight}>

                {/*<div className={`row d-flex align-items-center pl-0 mx-0 pt-0 pb-2`}*/}
                {/*>*/}
                {/*    <div className="col-8 mt-0 pl-2" onClick={this.followAction}>*/}
                {/*        <UserTileGeneral disableLink={true} user={this.state.productInfo?.user} subtitle={" "} showCheckMark={false} />*/}
                {/*    </div>*/}
                {/*    <div className="col-4 mt-0 pl-2 text-right">*/}
                {/*        {this.renderFollowButton()}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="align-self-center" style={{ marginTop: "15px" }}>
                    <a href={`/Product/ProductPage/${this.state.productInfo?.shareCode}?r=${this.browse ? "0&b=1" : "1"}`} style={{ cursor: "pointer"}} target={this.state.productInfo?.merchant}>
                        <img src={this.state.productInfo?.imageUrl} alt={this.state.productInfo?.name} style={{ maxHeight: "290px", border: "1px solid #efecec", borderRadius: "10px" }} loading="lazy" />
                    </a>
                </div>

            </section>
        )
    }
}

export default ProductPageEmbed;