import React from "react";

const CommentIcon = (props) => {
    return (
        <svg width={props.width || 19} height={props.height || 18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M16.7293 3C16.7293 2.175 16.0618 1.5 15.2368 1.5H3.23682C2.41182 1.5 1.73682 2.175 1.73682 3V12C1.73682 12.825 2.41182 13.5 3.23682 13.5H13.7368L16.7368 16.5L16.7293 3ZM15.2368 3V12.8775L14.3593 12H3.23682V3H15.2368ZM13.7368 9H4.73682V10.5H13.7368V9ZM4.73682 6.75H13.7368V8.25H4.73682V6.75ZM13.7368 4.5H4.73682V6H13.7368V4.5Z"
                fill={props.fill || "black"} fillOpacity="0.4" />
        </svg>
    )
}

export default CommentIcon;