import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import ActionButton from "components/ActionButton";
import LoadSpinner from "components/LoadSpinner";

class CreatorAppAdmin extends React.Component {
    state = {
        loading: false,
        savingQuestions: false,
        dynamicQuestions: [],
        optionsVisible: {} // Key: questionIndex, Value: boolean (true if visible, false otherwise)
    };

    componentDidMount = async () => {
        await this.fetchCreatorApplicationDynamicDropdownQuestions();
    };

    fetchCreatorApplicationDynamicDropdownQuestions = async () => {
        this.setState({ loading: true });
        let url = `/api/CreatorApplicationAdmin/GetCreatorApplicationsDynamicDropdownQuestions`;
        let result = await getRequest(url);

        if (!!(result) && result.length > 0) {
            let dynamicQuestions = result.map((item, index) => ({
                title: item.title,
                label: item.label,
                options: JSON.parse(item.options), // Unstringify the options
                ordinal: index + 1 // Initialize ordinal based on index + 1
            }));

            const optionsVisible = dynamicQuestions.reduce((acc, _, index) => {
                acc[index] = false; // Initially hide all options
                return acc;
            }, {});

            this.setState({ dynamicQuestions, optionsVisible, loading: false });
        }
    };

    handleOptionChange = (questionIndex, optionIndex, field, value) => {
        const updatedQuestions = [...this.state.dynamicQuestions];
        updatedQuestions[questionIndex].options[optionIndex][field] = value;
        this.setState({ dynamicQuestions: updatedQuestions });
    };

    handleQuestionChange = (questionIndex, field, value) => {
        const updatedQuestions = [...this.state.dynamicQuestions];
        updatedQuestions[questionIndex][field] = value;
        this.setState({ dynamicQuestions: updatedQuestions });
    };

    handleOrdinalChange = (questionIndex, value) => {
        const updatedQuestions = [...this.state.dynamicQuestions];
        updatedQuestions[questionIndex].ordinal = value;
        this.setState({ dynamicQuestions: updatedQuestions });
    };

    addOption = (questionIndex) => {
        const updatedQuestions = [...this.state.dynamicQuestions];
        updatedQuestions[questionIndex].options.push({ value: "", text: "" });
        this.setState({ dynamicQuestions: updatedQuestions });
    };

    deleteOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...this.state.dynamicQuestions];
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        this.setState({ dynamicQuestions: updatedQuestions });
    };

    deleteQuestion = (questionIndex) => {
        const updatedQuestions = [...this.state.dynamicQuestions];
        updatedQuestions.splice(questionIndex, 1);
        // Update ordinals after a question is deleted
        const updatedQuestionsWithOrdinals = updatedQuestions.map((q, index) => ({
            ...q,
            ordinal: index + 1
        }));
        this.setState({ dynamicQuestions: updatedQuestionsWithOrdinals });
    };

    addQuestion = () => {
        const newQuestionIndex = this.state.dynamicQuestions.length;
        const newQuestion = {
            title: "",
            label: "",
            options: [{ value: "", text: "" }],
            ordinal: newQuestionIndex + 1 // Set ordinal based on length
        };
        this.setState(prevState => ({
            dynamicQuestions: [...prevState.dynamicQuestions, newQuestion],
            optionsVisible: {
                ...prevState.optionsVisible,
                [newQuestionIndex]: true // Show options by default for new questions
            }
        }));
    };

    toggleOptionsVisibility = (questionIndex) => {
        this.setState(prevState => ({
            optionsVisible: {
                ...prevState.optionsVisible,
                [questionIndex]: !prevState.optionsVisible[questionIndex]
            }
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ savingQuestions: true, loading: true })

        // Validate questions to ensure all required fields are filled
        if (!this.validateQuestions()) {
            alert("Please complete all required fields.");
            return;
        }

        // Remove invalid options from each question
        const cleanedQuestions = this.state.dynamicQuestions.map(question => ({
            ...question,
            options: question.options.filter(option => option.value && option.text) // Keep only options with both value and text
        }));

        // Sort questions by ordinal
        const sortedQuestions = cleanedQuestions.sort((a, b) => a.ordinal - b.ordinal);

        // Build the array of questions to be sent
        const questionsToSend = sortedQuestions.map(question => ({
            title: question.title,
            label: question.label,
            options: JSON.stringify(question.options) // Stringify the options array
        }));

        // Define the URL for the dummy API endpoint
        const url = '/api/CreatorApplicationAdmin/SaveCreatorApplicationsDynamicQuestions';

        try {
            // Send the data using postRequest
            await postRequest(url, questionsToSend);
        } catch (error) {
            console.error("Failed to submit questions:", error);
        }
        await this.fetchCreatorApplicationDynamicDropdownQuestions();
        this.setState({ savingQuestions: false, loading: false})
    };


    validateQuestions = () => {
        const { dynamicQuestions } = this.state;
        if (!(dynamicQuestions) || dynamicQuestions?.length === 0) {
            return;
        }
        // Check if each question has a title, label, and at least one valid option
        for (const question of dynamicQuestions) {
            if (!question.title.trim() || !question.label.trim()) {
                return false; // Title or label is missing
            }

            const hasValidOption = question.options.some(option => option.value.trim() && option.text.trim());
            if (!hasValidOption) {
                return false; // No valid options found
            }
        }

        return true; // All questions are valid
    };


    renderDynamicQuestions = () => {
        return this.state.dynamicQuestions.map((question, questionIndex) => (
            <div key={questionIndex} className="mb-4">
                <h4>Question {question.ordinal}</h4>
                <FormGroup>
                    <Label for={`questionOrdinal-${questionIndex}`} style={{ fontSize: "1rem" }}>Preferred Order</Label>
                    <Input
                        type="number"
                        id={`questionOrdinal-${questionIndex}`}
                        value={question.ordinal}
                        style={{ width: "100px" }}
                        onChange={(e) => this.handleOrdinalChange(questionIndex, e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={`questionTitle-${questionIndex}`} style={{ fontSize: "1rem" }}>Title (actual text to appear as question on application)</Label>
                    <Input
                        type="text"
                        id={`questionTitle-${questionIndex}`}
                        value={question.title}
                        onChange={(e) => this.handleQuestionChange(questionIndex, 'title', e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for={`questionLabel-${questionIndex}`} style={{ fontSize: "1rem" }}>Label (FOR HUBSPOT - no spaces - camelCase, PascalCase, or under_scores only)</Label>
                    <Input
                        type="text"
                        id={`questionLabel-${questionIndex}`}
                        value={question.label}
                        onChange={(e) => this.handleQuestionChange(questionIndex, 'label', e.target.value)}
                    />
                </FormGroup>
                <div>
                    <h6>Options:</h6>
                    <p
                        style={{ color: "gray", cursor: "pointer", fontSize: "12px", textDecoration: "underline" }}
                        onClick={() => this.toggleOptionsVisibility(questionIndex)}
                    >
                        {this.state.optionsVisible[questionIndex] ? "Hide Options" : "Show Options"}
                    </p>
                    {this.state.optionsVisible[questionIndex] && (
                        <div className="pl-5">
                            {question.options.map((option, optionIndex) => (
                                <FormGroup key={optionIndex} className="mb-2">
                                    <Label for={`optionValue-${questionIndex}-${optionIndex}`}>Value (text sent to hubspot associated with creator response)</Label>
                                    <Input
                                        type="text"
                                        id={`optionValue-${questionIndex}-${optionIndex}`}
                                        value={option.value}
                                        onChange={(e) => this.handleOptionChange(questionIndex, optionIndex, 'value', e.target.value)}
                                    />
                                    <Label for={`optionText-${questionIndex}-${optionIndex}`}>Text (as it will appear in the dropdown option)</Label>
                                    <Input
                                        type="text"
                                        id={`optionText-${questionIndex}-${optionIndex}`}
                                        value={option.text}
                                        onChange={(e) => this.handleOptionChange(questionIndex, optionIndex, 'text', e.target.value)}
                                    />
                                    <p style={{ color: "red", cursor: "pointer" }} onClick={() => this.deleteOption(questionIndex, optionIndex)}>Delete Option</p>
                                </FormGroup>
                            ))}
                            <p style={{ color: "gray", cursor: "pointer", fontSize: "12px", textDecoration: "underline" }} onClick={() => this.addOption(questionIndex)}>Add Additional Option</p>
                        </div>
                    )}
                </div>
                <button className="btn btn-danger" style={{ cursor: "pointer" }} onClick={() => this.deleteQuestion(questionIndex)}>Delete Question</button>
            </div>
        ));
    };

    handleReset = () => {
        // Show confirmation alert
        const confirmation = window.confirm("All changes will be lost. Are you sure you want to reset?");

        if (confirmation) {
            // Clear dynamicQuestions and refetch data
            this.setState({ dynamicQuestions: [] }, this.fetchCreatorApplicationDynamicDropdownQuestions);
        }
    };

    render() {
        if (this.state.loading) {
            return <LoadSpinner />
        }
        return (
            <section className="gray-bg full-height" style={{ fontFamily: "'Archivo',sans-serif" }}>
                <div className="container pt-0 pb-5">
                    <div className="user-detail-form mt-3">
                        <Form onSubmit={this.handleSubmit}>
                            <div className="detail-block">
                                <h5>Creator App Dynamic Question Administration</h5>
                                {this.renderDynamicQuestions()}
                                <Button className="btn btn-success mb-5" onClick={this.addQuestion}>Add New Question</Button>
                                <ActionButton className="btn btn-primary mb-5 w-100"
                                    isDisabled={!this.validateQuestions() || this.state.savingQuestions}
                                    onClick={this.handleSubmit}
                                    isLoading={this.state.savingQuestions}
                                >
                                    Save Questions
                                </ActionButton>
                                <ActionButton className="btn btn-secondary mb-5 w-100"
                                    onClick={this.handleReset}
                                    isLoading={this.state.loading}
                                >
                                    Reset
                                </ActionButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        );
    }
}

export default CreatorAppAdmin;
