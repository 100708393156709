import React from "react";

/**
 * 
 * @param {{width:Number,height:Number,fill:string}} props
 */
const SpreadsheetIcon = (props) => {
    return (
        <svg onClick={props.onClick || (() => { })} width={props.width || "20"} height={props.height || "20"} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g>
                <g>
                    <path className={props.fillClass || ""} fill="#000000" d="M174.3,128l-44.4,33.8c-0.6,0-1.3-0.1-1.9-0.1c-9.3,0-16.9,7.5-16.9,16.9c0,9.3,7.5,16.9,16.9,16.9c9.3,0,16.9-7.5,16.9-16.9c0-0.6,0-1.3-0.1-1.9l33.8-44.4L174.3,128z" />
                    <path className={props.fillClass || ""} fill="#000000" d="M128,43.7c-65.2,0-118,52.8-118,118c0,18.1,4.1,35.2,11.4,50.6h19c-8.1-14-12.7-29.8-13.4-46.3h16.7v-8.4H27c0.6-15.2,4.6-29.8,11.5-42.9l14.5,8.4l4.2-7.3l-14.5-8.3c3.9-6.1,8.5-11.9,13.8-17.2c5.4-5.3,11.2-10,17.4-14l8.3,14.4l7.3-4.2L81.2,72c13-6.8,27.5-10.7,42.5-11.3v16.8h8.4V60.7c15.1,0.6,29.5,4.5,42.6,11.3l-8.3,14.4l7.3,4.2l8.3-14.4c6.2,3.9,12.1,8.6,17.4,14c5.3,5.3,9.8,11,13.8,17.2l-14.4,8.3l4.2,7.3l14.5-8.4c6.9,13.1,10.9,27.7,11.5,42.9h-16.9v8.4H229c-0.7,16.5-5.3,32.3-13.4,46.3h19c7.3-15.3,11.4-32.5,11.4-50.6C246,96.6,193.1,43.7,128,43.7z" />
                </g>
            </g>
        </svg>
    )
}

export default SpreadsheetIcon;