import React, { useState, useRef, useEffect } from "react";
import BlogFeedPost from "./BlogFeedPost";
import PostFeedPost from "./PostFeedPost";
import LockerFeedPost from "./LockerFeedPost";
import CurrentDealFeedPost from "./CurrentDealFeedPost";

const FeedPost = (props) => {
    const { post } = props;

    let content;

    if (post.type === 2) {
        content = (
            <LockerFeedPost
                post={post}
                likePost={props.likePost}
                redirectUrl={props.redirectUrl}
                savePost={props.savePost}
                loggedInUser={props.loggedInUser}
                likeCount={post.likeCount}
                likedByUser={post.likedByUser}
                savedByUser={post.savedByUser}
                commentCount={post.commentCount}
                key={props.key}
                deviceSize={props.deviceSize}
                setPost={props.setPost}
                deletePost={props.deletePost}
                togglePinned={props.togglePinned}
                pinnedDate={post.pinnedDate}
                showPins={props.showPins}
                toggleSignUpModal={props.toggleSignUpModal}
                aboveTheFold={props.aboveTheFold}
            />
        );
    } else if (post.type === 4) {
        content = (
            <BlogFeedPost
                post={post}
                likePost={props.likePost}
                redirectUrl={props.redirectUrl}
                savePost={props.savePost}
                loggedInUser={props.loggedInUser}
                likeCount={post.likeCount}
                likedByUser={post.likedByUser}
                savedByUser={post.savedByUser}
                commentCount={post.commentCount}
                key={props.key}
                deviceSize={props.deviceSize}
                setPost={props.setPost}
                deletePost={props.deletePost}
                togglePinned={props.togglePinned}
                pinnedDate={post.pinnedDate}
                showPins={props.showPins}
                toggleSignUpModal={props.toggleSignUpModal}
                aboveTheFold={props.aboveTheFold}
            />
        );
    } else if (post.type === 5) {
        content = (
            <CurrentDealFeedPost
                loggedInUser={props.loggedInUser}
                deal={props.post}
                deviceSize={props.deviceSize}
                toggleProductShareModal={props.toggleProductShareModal}
                aboveTheFold={props.aboveTheFold}
            />
        );
    } else {
        content = (
            <PostFeedPost
                post={post}
                likePost={props.likePost}
                redirectUrl={props.redirectUrl}
                savePost={props.savePost}
                loggedInUser={props.loggedInUser}
                likeCount={post.likeCount}
                likedByUser={post.likedByUser}
                savedByUser={post.savedByUser}
                commentCount={post.commentCount}
                key={props.key}
                deviceSize={props.deviceSize}
                setPost={props.setPost}
                deletePost={props.deletePost}
                togglePinned={props.togglePinned}
                pinnedDate={post.pinnedDate}
                showPins={props.showPins}
                toggleSignUpModal={props.toggleSignUpModal}
                aboveTheFold={props.aboveTheFold}
            />
        );
    }

    return (
        <div className="feed-post-grid-item">
            {content}
        </div>
    );
};

export default FeedPost;

