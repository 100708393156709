import React from "react";
import GridIconAlternate from "components/icons/GridIconAlternate"
import DoubleBarIcon from "components/icons/DoubleBarIcon";

/**
 * 
 * @param {{ isGrid:boolean, toggleAction:Function, fetchResults:Function, hideToggleAction:boolean, selectedCase:Number,style:object}} props 
 */
const SearchFilterBar = (props) => {
    let renderToggleIcon = () => {
        let icon = props.isGrid === true ? <GridIconAlternate /> : <DoubleBarIcon />
        if (props.hideToggleAction !== true) {
            return (
                <button className="filter-toggle-btn" onClick={props.toggleAction}>
                    {icon}
                </button>
            )
        }
    }
    let buttonSelected = (caseNumber) => {
        if (caseNumber === props.selectedCase) {
            return "btn-primary btn-lg"
        }
        else {
            return ""
        }
    }
    return (
        <section className="filter-wrapper" style={props.style || {}}>
            <div className="container">
                <div className="row mt-0 pb-2 pt-2">
                    <div className="col-12 d-flex justify-content-between">
                        <div className="filter-block d-flex">
                            {/* {renderButtons()} */}
                            <button className={`btn ${buttonSelected(1)}`} onClick={(e) => props.fetchResults(1)}>Locker</button>
                            <button className={`btn ${buttonSelected(2)}`} onClick={(e) => props.fetchResults(3)}>Posts</button>
                            <button className={`btn ${buttonSelected(3)}`} onClick={(e) => props.fetchResults(4)}>People</button>
                        </div>

                        {renderToggleIcon()}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default SearchFilterBar;