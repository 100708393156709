import React, { useState, useEffect } from "react";
import LikeIcon from "./icons/LikeIcon";
import Carousel from "./Carousel";
import { Link } from "react-router-dom";
import CommentIcon from "./icons/CommentIcon";
import ShareIcon from "./icons/ShareIcon";
import BookmarkIcon from "./icons/BookmarkIcon";
import RatingIcon from "./icons/RatingIcon";
import RatingIconHalf from "./icons/RatingIconHalf";
import RatingIconEmpty from "./icons/RatingIconEmpty";
import SaleIcon from "./icons/SaleIcon";

import ShareBar from "components/Share/ShareBar";
import ProductReviewUsersList from "components/ProductReviewUsers/ProductReviewUsersList";
import { currencyFormat, titleCase } from "sharedUtils/productUtils";

import StarRatingComponent from 'react-star-rating-component';
//import { Modal, ModalBody, ModalHeader } from "reactstrap";

import Modal from "components/Modal";

import AnchorLink from 'react-anchor-link-smooth-scroll'

/**
 * 
 * @param {{
 * product:object 
 * rating:number, 
 * loggedInUser:object,
 * starHover:Function,
 * starHoverOut:Function,
 * starClick:Function,
 * deviceSize:string,
 * buyAction:Function}} props
 */
const ProductDetailSection = (props) => {
    let { imageUrl, manufacturer, merchant, name, ratingCount, likes, price, description } = props.product;

    const collapsedContentStyle = { textAlign: "left", marginTop: "0em", marginBottom: "0px", maxHeight: "10rem", textOverflow: "ellipsis", overflow: "hidden" };
    const expandedContentStyle = { textAlign: "left", marginTop: "0em", marginBottom: "0px" };

    const [contentStyle, setContentStyle] = useState(expandedContentStyle);
    const [showBuy, setShowBuy] = useState(false);
    const [showUserReviewList, setShowReviewList] = useState(false);

    const contentRef = React.createRef();

    useEffect(() => {
        if (props.collapsed) {
            setContentStyle(collapsedContentStyle);
        } else {
            setContentStyle(expandedContentStyle);
        }
    }, [props.collapsed]);

    let renderRatingIcons = () => {
        if (props.loadingPartial) {
            return <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        }
        if (props.loggedInUser) {
            return (
                <div className="position-relative w-100 d-flex align-items-center">
                    <StarRatingComponent
                        name="starRating"
                        starCount={5}
                        value={props.rating}
                        renderStarIcon={(index, value) => {
                            if (index <= value) {
                                return (<span className="ratingIcon" key={index}><RatingIcon /></span>);
                            } else {
                                return (<span className="ratingIcon" key={index}><RatingIconEmpty /></span>);
                            }
                        }}
                        renderStarIconHalf={(index, value) => {
                            return (<span className="ratingIcon" key={index}><RatingIconHalf /></span>);
                        }}
                        onStarHover={props.starHover}
                        onStarHoverOut={props.starHoverOut}
                        onStarClick={props.starClick}
                    />
                    <span className="rating-count" style={{ marginBottom: "8px", marginLeft: "3px" }}>
                        <Link to="/" onClick={e => {
                            e.preventDefault();
                            if (props.loggedInUser) {
                                setShowReviewList(true);
                            }
                            else {
                                props.toggleSignUpModal();
                            }

                        }}>

                            {!props.loadingPartial && ratingCount > 0 ? `(${ratingCount})` : null}

                        </Link>
                    </span>
                    {props.renderThumbsUpOverlay(props.productInfo)}

                </div>
            )
        } else {
            let ratingIcons = [];
            let { rating } = props;
            for (let i = 0; i < 5; i++) {
                if (rating - i >= 1) {
                    ratingIcons.push(<span className="ratingIcon" key={i}><RatingIcon /></span>);
                }
                else if (rating - i <= 1 && rating - i > 0) {
                    ratingIcons.push(<span className="ratingIcon" key={i}><RatingIconHalf /></span>);
                }
                else {
                    ratingIcons.push(<span className="ratingIcon" key={i}><RatingIconEmpty /></span>)
                }
            }
            return ratingIcons;
        }
    }

    let renderBuy = () => {
        const commissionable = !!(props.product.commission);

        if (props.loadingBuy) {
            let today = Date.now().toString();
            return (
                <a href={props.buyAction} target={props.product.merchant} style={{ pointerEvents: "none" }}>
                    <button className="btn btn-primary buy-btn mt-2 d-block">
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </button>
                </a>
            );
        } else {
            if (commissionable && props.product.integrated) {
                return (
                    <>
                        <a href={props.buyAction} onClick={() => props.handleGoogleAnalytics4BuyButton(false)} target={props.product.merchant + Date.now().toString()}>
                            <button className="btn btn-primary buy-btn mt-2 d-block">
                                Buy Now
                            </button>
                        </a>
                        <a href="/" style={{ pointerEvents: "none" }} className="commensible-link d-inline-block mb-2" rel="noopener noreferrer" onClick={(e) => e.preventDefault()}>Commissionable link</a>
                    </>
                );
            }
        }
    }

    let concatMerchantProduct = (manufacturer, merchant, name) => {
        if (manufacturer && manufacturer.toLowerCase() === merchant.toLowerCase()) {
            if (name.toLowerCase().indexOf(manufacturer.toLowerCase()) === 0) {
                return name.slice(manufacturer.length - name.length).trim();
            }
            return name;
        } else {
            if (manufacturer && name.toLowerCase().indexOf(manufacturer.toLowerCase()) === 0) {
                return name;
            }
            return name;
            //return (manufacturer + ' ' + name).trimStart();
        }
    }

    let displayManufacturer = (manufacturer) => {
        if (manufacturer?.toLowerCase().includes("rcktemp_")) {
            return "";
        }
        return manufacturer;
    }

    let displayUserReviewList = () => {
        return (
            <Modal isOpen={showUserReviewList}
                showHeader={false}
                toggleModal={() => setShowReviewList(!showUserReviewList)}
            >

                <ProductReviewUsersList merchantProductId={props.product.userMerchantProductId} loggedInUser={props.loggedInUser} deviceSize={props.deviceSize} />

            </Modal>
        )

    }

    let deviceSize = ["sm", "md"].includes(props.deviceSize);

    return (
        <div className="container product-detail mt-3">
            {displayUserReviewList()}
            <div className="row pt-3 pb-1 mt-0 product-image-gallery">
                <div className="col-5 mb-4 mb-md-0">
                    <div className="product-image">
                        <img className="border-0" src={[imageUrl]} onError={(e) => {
                            e.target.onError = null
                            e.target.src = "images/blank-banner.png"
                        }} />
                    </div>
                </div>

                <div className="col-7 pl-2 pr-2 pr-md-4">
                    <div className="d-flex">
                        <h5 className="product-company">{displayManufacturer(manufacturer)}</h5>
                    </div>
                    <h1 className="product-name mb-0">{titleCase(concatMerchantProduct(manufacturer, merchant, name)).toUpperCase()}{props.product.sale ? <SaleIcon height={`${deviceSize ? "18" : "28"}`} width={`${deviceSize ? "18" : "28"}`} style={{ marginLeft: "3px" }} /> : <></>}</h1>
                    <div className="d-flex">
                        <span className="mr-1 price" >{props.product.sale && !!(props.product.salePrice) ? "$" + props.product.salePrice.toFixed(2) : <></>}</span>
                        <span style={{ paddingTop: props.product.sale ? "3px" : "" }} className={`${props.product.sale ? "on-sale-price-single" : "price"} d-block mb-0`}>{currencyFormat(props.product.sale && !!(props.product.salePrice) && !!(props.product.originalPrice) ? props.product.originalPrice : props.product.price)}</span>
                    </div>
                    <div className="product-rating mb-1 d-flex">
                        {renderRatingIcons()}
                    </div>

                    {renderBuy()}
                    {
                        props.deviceSize !== "sm" && description?.toLowerCase() !== name?.toLowerCase() ? (
                            <p className="mb-0" style={contentStyle} ref={contentRef}>
                                {description}
                            </p>
                        ) : null
                    }
                </div>

                {
                    props.deviceSize === "sm" && description?.toLowerCase() !== name?.toLowerCase() ? (
                        <div className="col-12 mt-1 mb-1">
                            <p className="mb-0" style={contentStyle} ref={contentRef}>
                                {description}
                            </p>
                        </div>
                    ) : null
                }   
            </div>

        </div>
    )
}

export default ProductDetailSection