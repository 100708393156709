import React, { Component } from "react";
import AccountBanner from "../../components/AccountBanner";
import ActionButton from "components/ActionButton";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import quillEmoji from 'quill-emoji';
import Marquee from "react-fast-marquee";
import { Parser } from 'html-to-react';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji
}, true);

class CreatorDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            backgroundColor: null,
            divHeight: "40px",
            uiHtml: "",
            creatorDashboardMarqueeId: 0,
            loading: false,
        };
        this.parser = new Parser();
    }

    componentDidMount = () => {
        this.fetchCreatorDashboardMarquee();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.value !== this.state.value) {
            this.htmlToState(this.renderTranslatedHTML());
        }
    }

    fetchCreatorDashboardMarquee = async () => {
        let url = `/api/Creator/GetCreatorDashboardMarquee`;
        let creatorDashboardMarquee = await getRequest(url);
        if (!!(creatorDashboardMarquee) && creatorDashboardMarquee.adminHtml?.trim().length > 0) {
            this.setState({ value: creatorDashboardMarquee.adminHtml, creatorDashboardMarqueeId: creatorDashboardMarquee.id });
        }
    }

    saveCreatorDashboardMarquee = async () => {
        let cdm = {
            id: this.state.creatorDashboardMarqueeId,
            uiHtml: this.state.uiHtml,
            adminHtml: this.convertSpacesAndTabs(this.state.value),
            backgroundColor: this.state.backgroundColor,
            redirectUrl: this.state.redirectUrl,
        };

        if (cdm.id > 0) {
            this.setState({ loading: true });
            let url = `/api/Creator/UpdateCreatorDashboardMarquee`;
            await postRequest(url, cdm);
            this.setState({ loading: false });
        } else {
            this.setState({ loading: true });
            let url = `/api/Creator/PostCreatorDashboardMarquee`;
            const newCdm = await postRequest(url, cdm);
            this.setState({ loading: false, creatorDashboardMarqueeId: newCdm.id });
        }
    }

    deleteCreatorDashboardMarquee = async () => {
        if (this.state.creatorDashboardMarqueeId > 0) {
            this.setState({ loading: true });

            let url = `/api/Creator/DeleteCreatorDashboardMarquee?id=` + this.state.creatorDashboardMarqueeId;
            await postRequest(url);

            this.setState({ loading: false, value: "", uiHtml: "", creatorDashboardMarqueeId: 0 });
        } else {
            this.setState({ value: "", uiHtml: "" });
        }
    }

    handleChange = (value) => {
        this.setState({ value });
        this.extractBackgroundColor(value);
        this.extractRedirectUrl(value);
    }

    extractBackgroundColor = (html) => {
        const regex = /background-color:\s*(rgba?\([^)]+\));/i;
        const match = html.match(regex);

        if (match) {
            const color = match[1];
            this.setState({ backgroundColor: color });
        } else {
            this.setState({ backgroundColor: null });
        }
    }

    extractRedirectUrl = (html) => {
        const regex = /<a\s+href="([^"]+)"/i;
        const match = html.match(regex);

        if (match) {
            const redirectUrl = match[1];
            this.setState({ redirectUrl });
        } else {
            this.setState({ redirectUrl: null });
        }
    }

    removeBackslashesFromStyle = (html) => {
        return html.replace(/style="([^"]*)"/g, (match, style) => {
            const cleanedStyle = style.replace(/\\/g, '');
            return `style="${cleanedStyle}; margin: 0;"`;
        });
    }

    convertSpacesAndTabs = (html) => {
        return html.replace(/>([^<]+)</g, (match, text) => {
            return `>${text.replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}<`;
        });
    }

    convertTextSize = (html) => {
        // Replace ql-size-large with font-large
        let convertedHTML = html.replace(/ql-size-large/g, 'font-large');

        // Replace ql-size-small with font-small
        convertedHTML = convertedHTML.replace(/ql-size-small/g, 'font-small');

        // Replace ql-size-small with font-small
        convertedHTML = convertedHTML.replace(/ql-size-huge/g, 'font-huge');

        return convertedHTML;
    }

    htmlToState = (html) => {
        const htmlString = html && html.__html ? html.__html : "";
        this.setState({ uiHtml: htmlString });
    }


    renderTranslatedHTML = () => {
        const { value, divHeight } = this.state;
        let cleanedHTML = this.removeBackslashesFromStyle(value);
        cleanedHTML = this.convertSpacesAndTabs(cleanedHTML);
        cleanedHTML = this.convertTextSize(cleanedHTML);
        const hasStyle = cleanedHTML.includes('<p style="');

        // Add margin: 0 to the existing style or add new style attribute
        if (hasStyle) {
            cleanedHTML = cleanedHTML.replace(/<p style="/g, '<p style="margin: 0; ');
        } else {
            cleanedHTML = cleanedHTML.replace(/<p/g, '<p style="margin: 0;"');
        }

        const wrappedHTML = `${cleanedHTML}`;

        return { __html: wrappedHTML };
    }

    breadCrumbList = [
        { link: "/Admin", text: "Admin" },
        { link: "/Admin/CreatorDashboard", text: "Creator Dashboard" },
    ];

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Creator Dashboard</h1>
                        <p className="mb-0">Manage Creator Dashboard marquee banner</p>
                    </AccountBanner>
                </section>
                <div className="container mt-3">
                    <div className="react-quill">
                        <ReactQuill
                            theme="snow"
                            value={this.state.value}
                            onChange={this.handleChange}
                            modules={{
                                toolbar: {
                                    container: [
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        ['clean'],
                                        ['emoji'],
                                        ['link'],
                                        [{
                                            'color': [
                                                "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                                "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                                "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                                "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                                "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                                "#d46240", "#20a2e3", "#2f524c", "#e2f4e6", "#797979", "#476584", "#4d4d4d",
                                                "#d0d0d0"
                                            ] }], // Text color
                                        [{
                                            'background': [
                                                "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
                                                "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
                                                "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
                                                "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                                "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466",
                                                "#d46240", "#20a2e3", "#2f524c", "#e2f4e6", "#797979", "#476584", "#4d4d4d",
                                                "#d0d0d0"
                                            ] }], // Background color
                                    ],
                                },
                                'emoji-toolbar': true,
                                'emoji-textarea': false,
                                'emoji-shortname': true
                            }}
                        />
                    </div>
                    <div className="mt-5" style={{ border: "1px solid", backgroundColor: this.state.backgroundColor ?? "", height: "40px" }}>
                        <Marquee style={{ height: "100%", zIndex: 0 }}>
                            <div className="d-flex align-items-center" dangerouslySetInnerHTML={this.renderTranslatedHTML()} />
                        </Marquee>
                    </div>
                    <div className="mt-3">
                        <ActionButton onClick={this.saveCreatorDashboardMarquee} className="btn btn-primary mr-2" isLoading={this.state.loading} isDisabled={this.state.value?.length === 0 || this.state.uiHtml?.length === 0}>Save</ActionButton>
                        <ActionButton onClick={this.deleteCreatorDashboardMarquee} className="btn btn-secondary" isLoading={this.state.loading} isDisabled={false}>Delete</ActionButton>
                    </div>
                </div>
            </>
        );
    }
}

export default CreatorDashboard;
