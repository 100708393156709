import React, { forwardRef } from "react";
import SaleIcon from "../icons/SaleIcon";

const Photo = forwardRef(
    ({ displayIcon, deviceSize, renderInfoOverlay, dimensionsRef, removeItem, productItem, url, hover, index, faded, style, ...props }, ref) => {
        const inlineStyles = {
            opacity: faded ? "0" : "1",
            backgroundPosition: "center",
            backgroundSize: "60%",
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            backgroundImage: `url(${url})`,
            touchAction: "none",
            borderRight: !!(hover) && productItem.shareCode == hover ? "3px solid #e55934" : "",
            ...style
        };

        const renderSaleOverlay = (sale, faded) => {
            if (sale) {
                return (
                    <span className="on-sale-icon" style={{ right: "6px", bottom: "50px", display: faded ? "none" : "" }}>
                        <SaleIcon />
                    </span >
                );
            }
        }

        const toTitleCase = (string) => {
            return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
                return match.toUpperCase();
            });
        }   

        return (
            <React.Fragment key={index}>
                <div className="col-4 col-md-3 px-1 py-1" ref={ref} {...props}>

                    <div className="post-page-product disableLongPressPopUps" style={{ backgroundColor: faded ? "lightgray" : "" }}>
                        {["sm"].includes(deviceSize) ? renderSaleOverlay(productItem.sale, faded) : <></>}
                        {displayIcon(productItem.shareCode, faded)}
                        <div className="product-image-in-page disableLongPressPopUps" >
                            {/*<div ref={ref} {...props} style={{ position: "relative", background: "lightgray", height: "100px", margin: "5px", boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(34 33 81 / 15%)" }}>*/}
                            {/*<div {...props.listeners} ref={dimensionsRef} style={inlineStyles} id={`sortable-${productItem.shareCode}`} className="post-edit-image disableLongPressPopUps" />*/}
                            <img src={url} {...props.listeners} ref={dimensionsRef} style={inlineStyles} id={`sortable-${productItem.shareCode}`} className="post-edit-image disableLongPressPopUps" />
                            <div style={{ position: "absolute", top: "-5px", right: "3px", display: faded ? "none" : "" }}>
                                <button type="button"
                                    className="close"
                                    aria-label="Close"
                                    style={{ display: "inline-block" }}
                                    onClick={() => removeItem(productItem.shareCode)}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ cursor: "initial", background: faded ? "lightgray" : "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px", color: faded ? "transparent" : "black" }}><div style={{ maxWidth: "1px", color: faded ? "transparent" : "" }}>{productItem.manufacturer?.toLowerCase().includes("rcktemp") ? productItem.merchant : productItem.manufacturer}{productItem.sale && !!(productItem.salePrice) && !["sm"].includes(deviceSize) && !faded ? <SaleIcon style={{ marginLeft: "3px", display: faded ? "none" : "" }} /> : <></>}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ width: "95%", whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px", color: faded ? "transparent" : "black" }}>
                                    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                            {toTitleCase(productItem.name)}
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div className={`users user-tile-general py-1 ml-${productItem.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px", color: faded ? "transparent" : "black" }}><div className="d-flex" style={{ maxWidth: "1px", color: faded ? "transparent !important" : "black !important" }}><div className="mr-1" >{productItem.sale && !!(productItem.salePrice) ? "$" + productItem.salePrice.toFixed(2) : <></>}</div><div style={{ fontSize: productItem.sale && !!(productItem.salePrice) && deviceSize !== "sm" ? "10px" : "" }} className={`${productItem.sale && !!(productItem.salePrice) ? "on-sale-price" : ""}`}>${productItem.sale && !!(productItem.salePrice) && !!(productItem.originalPrice) ? productItem?.originalPrice?.toFixed(2) : productItem?.price?.toFixed(2)}</div></div></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
);

export default Photo;