import React from "react";
import GridIconAlternate from "components/icons/GridIconAlternate";
import DoubleBarIcon from "components/icons/DoubleBarIcon";


/**
 *
 * @param {{types:[],interests:[],toggleAction:Function, isGrid:boolean, className:string}} props
 */
const ProfileFilterBar = (props) => {
    let renderToggleIcon = () => {
        return props.isGrid === true ? <DoubleBarIcon /> : <GridIconAlternate />
    }
    return (
        <section className={`filter-wrapper pb-1`} >
            <div className="container">
                <div className="row mt-0">
                    <div className="col-12 profile-filter-bar">
                        <button className="filter-toggle-btn"
                            onClick={props.toggleAction}>
                            {renderToggleIcon()}
                        </button>

                    </div>
                </div>
            </div>
        </section>
        )
}

export default ProfileFilterBar;