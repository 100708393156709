import React from "react"

/**
 * 
 * @param {{width:Number,height:Number,opacity:Number}} props
 */
const PreviousIcon = (props) => {
    return (
        <svg onClick={props.onClick ?? {}} className={props.className ?? ""} width={props.width ?? "16"} height={props.height ?? "16"} opacity={props.opacity ?? 1} style={props.style ?? {}} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
            <g>
                <polygon points="367.1,0 111.1,256 367.1,512 400.9,478.2 178.7,256 400.9,33.8 367.1,0  " />
            </g>
        </svg>
    )
}

export default PreviousIcon;