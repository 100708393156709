import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


/**
 * 
 * @param {{application:object}} props
 */
const CreatorApplicationActionDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle caret>
                Action
            </DropdownToggle>

            <DropdownMenu style={{ width: "125%" }}>
                <DropdownItem onClick={(e) => { props.approveApplication(props.application); }}>
                    Approve
                </DropdownItem>

                <DropdownItem onClick={() => {props.rejectApplication(props.application); }}>
                    Reject
                </DropdownItem>

                {
                    props.deletedTab ?
                        <DropdownItem onClick={() => { props.unDeleteApplication(props.application); }}>
                            UnDelete
                        </DropdownItem>
                        :
                        <DropdownItem onClick={() => { props.deleteApplication(props.application); }}>
                            Delete
                        </DropdownItem>
                }

                <DropdownItem onClick={() => { props.synchApplication(props.application); }}>
                    Synch
                </DropdownItem>

            </DropdownMenu>
        </Dropdown>

    )
}


export default CreatorApplicationActionDropdown;

