import * as React from "react";
import AccountSidebar from "../../components/AccountSidebar";
import AccountBanner from "../../components/AccountBanner";

import { Form, FormGroup, Label, Input } from "reactstrap";
import { getRequest, postRequest } from "../../sharedUtils/httpUtils";

import { WithContext as ReactTags } from 'react-tag-input';
import { Helmet } from 'react-helmet'

let readOnlyFields = [ "profileUrl" ];

let userFormData = {
    displayName: "",
    profileUrl: "",
    bio: "",
    tagLine: "",
    website: "",
    youtube: "",
    facebook: "",
    instagram: "",
};

const Interests = (props) => {

    const [formData, setFormData] = React.useState(userFormData);
    const [savingProfile, setSavingProfile] = React.useState(false);
    const [sectors, setSectors] = React.useState([]);
    const [filter, setFilter] = React.useState("");
    const [filteredSectors, setFilteredSectors] = React.useState([]);

    const getData = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        await getSectors();
    };

    const getSectors = async () => {
        let sectors = await getRequest('/api/Sector/GetSectors');
        sectors = sectors.sort((a, b) => { return a.name < b.name ? -1 : 1 })
        let userSectors = await getRequest('/api/Sector/GetUserSectors');

        for (var i = 0; i < userSectors.length; ++i) {
            for (var j = 0; j < sectors.length; ++j) {
                if (userSectors[i].sectorId === sectors[j].sectorId) {
                    sectors[j].active = true;
                    break;
                }
            }
        }

        setSectors(sectors);
        setFilteredSectors(sectors);
    }
    
    const updateSectors = async () => {
        let selectedSectorIds = sectors.filter(sector => sector.active === true).map((sector) => { return sector.sectorId });
        await postRequest('/api/Sector/UpdateUserSectors', selectedSectorIds);
    }

    React.useEffect(() => { getData() }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderSectors = () => {
        return filteredSectors.map((sector, i) => {
            if (sector.active) {
                return <button key={i} className="btn d-flex align-items-center active" onClick={(e) => { e.preventDefault(); onSectorClick(sector.name) }}>{sector.name}</button>
            }
            return <button key={i} className="btn d-flex align-items-center" onClick={(e) => { e.preventDefault(); onSectorClick(sector.name) }}>{sector.name}</button>
        });
    }

    const onInputChange = (e) => {
        let filter = e.target.value;
        let filteredSectors = sectors.filter(sector => sector.name.toLowerCase().includes(filter.toLowerCase()));

        setFilter(filter);
        setFilteredSectors(filteredSectors);
    };

    const onSectorClick = async (name) => {
        let sects = [...sectors];
        sects.findIndex((s) => { return s.name === name });
        let i = sects.findIndex((s) => { return s.name === name })
        sects[i].active = !sects[i].active;

        setSectors(sects);
        setFilter("");
        setFilteredSectors(sectors);
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        setSavingProfile(true);

        updateSectors().then(() => { setSavingProfile(false); });        
    };


    const renderSubmit = () => {
        let submitDisabled = sectors.filter(sector => sector.active === true).length === 0;

        if (submitDisabled) {
            return (<button type="submit" className="btn btn-secondary mt-3" disabled onClick={(e) => { onSubmit(e); }}>{savingProfile ?
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                : ""} Submit</button>);
        }

        return (<button type="submit" className="btn btn-secondary mt-3" onClick={(e) => { onSubmit(e); }}>{savingProfile ?
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            : ""} Submit</button>);
    }

    const breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/Interests', text: 'Interests', active: true } ];
    return (
        <section>
            <Helmet>
                <title>RockPorch - Interests</title>
            </Helmet>
            <AccountBanner breadCrumbList={breadCrumbList}>
                <h1 className="mb-4">Interests</h1>
                <p className="mb-0">Manage what you're interested in</p>
            </AccountBanner>

            <div className="gray-bg full-height">
                <div className="container">
                    <div className="row py-5 mt-0">
                        {
                            ["sm", "md"].includes(props.deviceSize) === false ? (
                                <div className="col-md-5 col-lg-3 side-nav">
                                    <AccountSidebar loggedInUser={props.loggedInUser} setLoggedInUser={props.setLoggedInUser} />
                                </div>

                            ): <span></span>
                        }
                        
                        <div className="col-md-12 col-lg-9">
                            <div className="user-detail-form">
                                <Form onSubmit={(e) => { e.preventDefault(); }}>
                                    <div className="detail-block">
                                        <h5>Your Interests</h5>
                                        <div className="search-interest py-4 mx-auto">
                                            <div className="search-block">
                                                <div className="form-group position-relative mx-auto">
                                                    <input type="search" id="gsearch" name="gsearch" placeholder="Search for interests..." value={filter} onChange={(e) => onInputChange(e)}/>
                                                </div>
                                                <div className="tag-results-sm d-flex justify-content-center flex-wrap py-3">
                                                    {renderSectors()}
                                                </div>
                                            </div>
                                        </div>

                                        {renderSubmit()}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Interests;
