import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "../../components/LoadSpinner";
import AnalyticsChart from "../../components/AnalyticsChart";
import AnalyticsByPerformance from "../../components/AnalyticsByPerformance";
import AnalyticsPerformanceByHour from "../../components/AnalyticsPerformanceByHour";
import CommissionsChart from "../../components/CommissionsChart";
import TransactionsChart from "../../components/TransactionsChart";
import AnalyticsIcon from "../../components/icons/AnalyticsIconNew";
import CommissionsIcon from "../../components/icons/CommissionsIcon";
import SpreadsheetIcon from "../../components/icons/SpreadsheetIcon";
import SpeedometerIcon from "../../components/icons/SpeedometerIcon";
import { Helmet } from 'react-helmet';
import { getQueryStringParams } from "sharedUtils/navUtils"
import toast, { Toaster } from 'react-hot-toast';
import TextField from '@material-ui/core/TextField';

const { DateTime } = require("luxon");

class Analytics extends React.Component {
    constructor(props) {
        super(props);

        this.selectOptions = {
            clicks: {
                value: 2,
                label: "Clicks"
            },
            sales: {
                value: 3,
                label: "Sales"
            },
            conversionRate: {
                value: 4,
                label: "Conversion Rate"
            },
            name: {
                value: 1,
                label: "Name",
            },
            merchant: {
                value: 5,
                label: "Merchant",
            },
            price: {
                value: 6,
                label: "Price",
            },
            salesAmount: {
                value: 7,
                label: "Sale Amt",
            },
            commission: {
                value: 8,
                label: "Commission",
            }
        } 

        this.merchantSelectOptions = {
            merchant: {
                value: 1,
                label: "Merchant",
            },
            clicks: {
                value: 2,
                label: "Clicks"
            },
            sales: {
                value: 3,
                label: "Sales"
            },
            conversionRate: {
                value: 4,
                label: "Conversion Rate"
            },
            salesAmount: {
                value: 5,
                label: "Sale Amt",
            },
            commission: {
                value: 6,
                label: "Commission",
            }
        } 

        this.commissionDetailItemsSelectOptions = {
            commission: {
                value: 1,
                label: "Name",
            },
            merchant: {
                value: 2,
                label: "Merchant",
            },
            sales: {
                value: 3,
                label: "Sales"
            },
            conversionRate: {
                value: 4,
                label: "Estimated Commission"
            },
            salesAmount: {
                value: 5,
                label: "Price",
            },
        } 

        this.state = {
            startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: DateTime.utc().startOf('day'),
            minDate: "1753-01-01", 
            maxDate: "9999-12-31",
            selectedCase: 1,
            filter: "",
            filteredPartners: [],
            partners: [],
            loading: false,
            analyticsLoading: false,
            analyticsHourlyLoading: false,
            analyticsDailyLoading: false,
            commissionsLoading: false,
            transactionsLoading: false,
            analyticsByProductLoading: false,
            analyticsByMerchantLoading: false,
            analyticsByCommissionDetailItemLoading: false,
            analyticsByProductDatas: [],
            analyticsByMerchant: [],
            analyticsByCommissionDetailItems: [],
            performanceSalesSourceCommissionDetailItem: [],
            sectors: [],
            partnerSectors: [],
            selectedSectors: [],
            analytics: [],
            analyticsHourly: [],
            analyticsDaily: [],
            commissions: [],
            transactions: [],
            userId: this.props?.loggedInUser ? this.props.loggedInUser : null,
            date: null,
            sortDescending: true,
            merchantSortDescending: true,
            commissionDetailItemsSortDescending: true,
            analyticsPerformanceSourceByMerchantLoading: false,
            analyticsPerformanceSourceByUserMerchantProductLoading: false,
            analyticsPerformanceSalesSourceByUserMerchantProductLoading: false,
            analyticsPerformanceSalesSourceByMerchantLoading: false,
            performanceSourceUserMerchantProduct: [],
            performanceSalesSourceUserMerchantProduct: [],
            performanceSalesSourceMerchant: [],
            performanceSourceMerchant: [],
            salesSourceSortReferrer: false,
            salesSourceSortAscending: false,
            sourceSortReferrer: false,
            sourceSortAscending: false,
            selectOptions: {
                options: [
                    this.selectOptions.clicks,
                    this.selectOptions.sales,
                    this.selectOptions.conversionRate,
                    this.selectOptions.name,
                    this.selectOptions.merchant,
                    this.selectOptions.price
                ],
                defaultValue: this.selectOptions.clicks,
                currentValue: this.selectOptions.clicks,
            },
            merchantSelectOptions: {
                options: [
                    this.merchantSelectOptions.merchant,
                    this.merchantSelectOptions.clicks,
                    this.merchantSelectOptions.sales,
                    this.merchantSelectOptions.conversionRate,
                    this.selectOptions.salesAmount,
                    this.selectOptions.commission,
                ],
                defaultValue: this.merchantSelectOptions.clicks,
                currentValue: this.merchantSelectOptions.clicks,

            },
            commissionDetailItemsSelectOptions: {
                options: [
                    this.selectOptions.name,
                    this.selectOptions.merchant,
                    this.selectOptions.sales,
                    this.selectOptions.estimatedCommission,
                    this.selectOptions.price
                ],
                defaultValue: this.selectOptions.sales,
                currentValue: this.selectOptions.sales,
            },
        }
    }

    resetPerformanceTabs = () => {
        let merchantSelectOptions = { ...this.state.merchantSelectOptions };
        let selectOptions = { ...this.state.selectOptions };
        let commissionDetailItemsSelectOptions = { ...this.state.commissionDetailItemsSelectOptions };

        merchantSelectOptions.currentValue = this.merchantSelectOptions.clicks;
        selectOptions.currentValue = this.selectOptions.clicks;
        commissionDetailItemsSelectOptions.currentValue = this.commissionDetailItemsSelectOptions.sales;

        this.setState({ merchantSelectOptions, commissionDetailItemsSelectOptions, selectOptions, sortDescending: true, merchantSortDescending: true, commissionDetailItemsSortDescending: true })
    }

    breadCrumbList = [{ link: "/CreatorDashboard", text: 'Creator Dashboard' }, { link: '/Account/Analytics', text: 'Analytics', active: true }];

    componentDidMount = async () => {
        const params = getQueryStringParams(window.location.search);
        let date = params.date;
        let selectedCase = params.tab;

        if (date !== undefined && date !== null && !isNaN(date) && Number.isInteger(Number(date)) && parseInt(date, 10) > -1 && parseInt(date, 10) < 6) {
            date = parseInt(date, 10);
        } else {
            date = null;
        }

        if (selectedCase !== undefined && selectedCase !== null && !isNaN(selectedCase) && Number.isInteger(Number(selectedCase)) && parseInt(selectedCase, 10) > 0 && parseInt(selectedCase, 10) < 5) {
            selectedCase = parseInt(selectedCase, 10);
        } else {
            selectedCase = this.state.selectedCase
        }
        
        this.setState({ date, selectedCase });

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    handleStartDateChange = (event) => {
        const startDateRaw = event.target.value;

        if (startDateRaw.length === 0) {
            return;
        }

        const min = this.state.minDate;
        const max = this.state.maxDate;

        const startDate = DateTime.fromISO(startDateRaw).toUTC().startOf('day');
        if (startDate.isLuxonDateTime && startDateRaw >= min && startDateRaw <= max) {
            this.setState({
                startDate
            });
        } else {
            console.error("Invalid date entered:", startDateRaw, startDate, startDate.isLuxonDateTime); // Log error if invalid
        }
    };

    handleEndDateChange = (event) => {
        const endDateRaw = event.target.value;

        if (endDateRaw.length === 0) {
            return;
        }

        const min = this.state.minDate;
        const max = this.state.maxDate;

        const endDate = DateTime.fromISO(endDateRaw).toUTC().startOf('day');
        if (endDate.isLuxonDateTime && endDateRaw >= min && endDateRaw <= max) {
            this.setState({
                endDate
            });
        } else {
            console.error("Invalid date entered:", endDateRaw, endDate); // Log error if invalid
        }
    };

    handleWeekToDate = () => {
        const startDate = DateTime.utc().minus({ weeks: 1 }).startOf('day');
        const endDate = DateTime.utc().startOf('day');
        this.setState({ startDate, endDate });
    };

    handleMonthToDate = () => {
        const startDate = DateTime.utc().minus({ months: 1 }).startOf('day');
        const endDate = DateTime.utc().startOf('day');
        this.setState({ startDate, endDate });
    };

    handleYearToDate = () => {
        const startDate = DateTime.utc().minus({ years: 1 }).startOf('day');
        const endDate = DateTime.utc().startOf('day');
        this.setState({ startDate, endDate });
    };

    clearPerformance = () => {
        this.setState({ performanceSourceMerchant: [], performanceSourceUserMerchantProduct: [], performanceSalesSourceUserMerchantProduct: [], performanceSalesSourceMerchant: [], performanceSalesSourceCommissionDetailItem: [] })
    }

    clearSourceSort = () => {
        this.setState({ sourceSortReferrer: false, sourceSortAscending: false });
    }

    clearSalesSourceSort = () => {
        this.setState({ salesSourceSortReferrer: false, salesSourceSortAscending: false });
    }

    handleGetLink = (shareLink) => {
        navigator.clipboard.writeText(shareLink);

        toast.success(
            <div className="p-2">
                <h4 className="text-center">Commissionable Link Copied to Clipboard!</h4>
                <p className="text-center mx-auto px-2 pt-2 pb-">Your commissionable link has been copied to clipboard and is ready to be shared</p>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
         this.handleGoogleAnalytics4GetLinkButton();
    }

    handleGoogleAnalytics4GetLinkButton = () => {
        window.gtag('event', 'Analytics_By_Product_Get_Link', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Button',
            event_label: `Analytics By Product Get Link`,
        });
    }

    handleSourceClicksSort = (performanceSourceMerchant = null, performanceSourceUserMerchantProduct = null, performanceSalesSourceUserMerchantProduct = null, performanceSalesSourceMerchant = null, performanceSalesSourceCommissionDetailItem = null) => {
        if (performanceSourceMerchant === null) {
            performanceSourceMerchant = [...this.state.performanceSourceMerchant];
        }

        if (performanceSourceUserMerchantProduct === null) {
            performanceSourceUserMerchantProduct = [...this.state.performanceSourceUserMerchantProduct];
        }

        if (!(performanceSalesSourceUserMerchantProduct)) {
            performanceSalesSourceUserMerchantProduct = [...this.state.performanceSalesSourceUserMerchantProduct];
        }

        if (!(performanceSalesSourceMerchant)) {
            performanceSalesSourceMerchant = [...this.state.performanceSalesSourceMerchant];
        }

        if (!(performanceSalesSourceCommissionDetailItem)) {
            performanceSalesSourceCommissionDetailItem = [...this.state.performanceSalesSourceCommissionDetailItem]
        }

        let sourceSortAscending = this.state.sourceSortAscending;
        let sourceSortReferrer = this.state.sourceSortReferrer;

        let salesSourceSortAscending = this.state.salesSourceSortAscending;
        let salesSourceSortReferrer = this.state.salesSourceSortReferrer;


        if (performanceSourceMerchant?.length > 0) {
            performanceSourceMerchant.sort((a, b) => {
                if (sourceSortReferrer) {
                    return sourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                } else {
                    // Secondary sorting for tied counts
                    if (a.count === b.count) {
                        return sourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                    }
                    return sourceSortAscending ? a.count - b.count : b.count - a.count;
                }
            });
            this.setState({ performanceSourceMerchant, sourceSortAscending: !this.state.sourceSortAscending });
        } else if (performanceSourceUserMerchantProduct?.length > 0) {
            performanceSourceUserMerchantProduct.sort((a, b) => {
                if (sourceSortReferrer) {
                    return sourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                } else {
                    // Secondary sorting for tied counts
                    if (a.count === b.count) {
                        return sourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                    }
                    return sourceSortAscending ? a.count - b.count : b.count - a.count;
                }
            });

            this.setState({ performanceSourceUserMerchantProduct, sourceSortAscending: !this.state.sourceSortAscending });
        } else if (performanceSalesSourceUserMerchantProduct?.length > 0) {
            performanceSalesSourceUserMerchantProduct.sort((a, b) => {
                if (salesSourceSortReferrer) {
                    return salesSourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                } else {
                    // Secondary sorting for tied counts
                    if (a.count === b.count) {
                        return salesSourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                    }
                    return salesSourceSortAscending ? a.count - b.count : b.count - a.count;
                }
            });
            this.setState({ performanceSalesSourceUserMerchantProduct, salesSourceSortAscending: !this.state.salesSourceSortAscending });
        } else if (performanceSalesSourceMerchant?.length > 0) {
            performanceSalesSourceMerchant.sort((a, b) => {
                if (salesSourceSortReferrer) {
                    return salesSourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                } else {
                    // Secondary sorting for tied counts
                    if (a.count === b.count) {
                        return salesSourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                    }
                    return salesSourceSortAscending ? a.count - b.count : b.count - a.count;
                }
            });
            this.setState({ performanceSalesSourceMerchant, salesSourceSortAscending: !this.state.salesSourceSortAscending });
        } else if (performanceSalesSourceCommissionDetailItem?.length > 0) {
            performanceSalesSourceCommissionDetailItem.sort((a, b) => {
                if (salesSourceSortReferrer) {
                    return salesSourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                } else {
                    // Secondary sorting for tied counts
                    if (a.count === b.count) {
                        return salesSourceSortAscending ? a.referrer.localeCompare(b.referrer) : b.referrer.localeCompare(a.referrer);
                    }
                    return salesSourceSortAscending ? a.count - b.count : b.count - a.count;
                }
            });
            this.setState({ performanceSalesSourceCommissionDetailItem, salesSourceSortAscending: !this.state.salesSourceSortAscending });
        } 
    }

    handleCommissionDetailItemsSortSelect = async (e, data = null) => {
        let selectOptions = { ...this.state.commissionDetailItemsSelectOptions };
        const sortDescending = !!(e) && !!(e.label) && e.label === this.state.commissionDetailItemsSelectOptions.currentValue.label ? !this.state.commissionDetailItemsSortDescending : true;
        if (!e) {
            e = selectOptions.currentValue;
        } else {
            selectOptions.currentValue = e;
        }

        const analyticsByCommissionDetailItems = !!(data) && data.length > 0 ? data : this.state.analyticsByCommissionDetailItems;

        let sortedAnalytics = [...analyticsByCommissionDetailItems];

        this.setState({ commissionDetailItemsSortDescending: sortDescending });

        const compareFunction = (a, b) => {
            const comparison = this.getCommissionDetailItemsComparisonValue(a, b, e);

            if (comparison !== 0) {
                // Respect sortDescending flag for the initial comparison
                return sortDescending ? comparison : -comparison;
            } else {
                // Check for ties and sort by next priority columns
                const tieComparisons = [
                    { column: 'Name', comparison: this.getMerchantComparisonValue(a, b, { value: 1 }), sortDescending },
                    { column: 'Merchant', comparison: this.getMerchantComparisonValue(a, b, { value: 2 }), sortDescending },
                    { column: 'Sales', comparison: this.getMerchantComparisonValue(a, b, { value: 3 }), sortDescending },
                    { column: 'Estimated Commission', comparison: this.getMerchantComparisonValue(a, b, { value: 4 }), sortDescending },
                    { column: 'Price', comparison: this.getComparisonValue(a, b, { value: parseFloat(5) }), sortDescending },
                ];

                // filter out the passed in e option from further consideration
                const filteredTieComparisons = tieComparisons.filter(item => item.column !== e.label);

                for (let i = 0; i < filteredTieComparisons.length; i++) {
                    if (tieComparisons[i].comparison !== 0) {
                        // Respect sortDescending flag for tie comparisons
                        return sortDescending ? tieComparisons[i].comparison : -tieComparisons[i].comparison;
                    }
                }

                // Default comparison if all columns are equal
                return 0;
            }
        };


        // Sort the analytics array using the custom comparison function
        sortedAnalytics.sort(compareFunction);

        this.setState({ analyticsByCommissionDetailItems: sortedAnalytics, commissionDetailItemsSelectOptions: selectOptions });
    };


    getCommissions = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let now = DateTime.now();
        let offset = now.offset;

        let getCommissionsUrl = '/api/Merchant/GetCommissions';

        if (startDate) {
            getCommissionsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getCommissionsUrl += getCommissionsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getCommissionsUrl += `&offset=${offset}`

        this.setState({ commissionsLoading: true });
        let commissions = await getRequest(getCommissionsUrl) ?? [];
        this.setState({ commissions: commissions, loading: false, commissionsLoading: false });
    }

    getTransactions = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let now = DateTime.now();
        let offset = now.offset;

        let getTransactionsUrl = '/api/Account/GetTransactions';

        if (startDate) {
            getTransactionsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getTransactionsUrl += getTransactionsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getTransactionsUrl += `&offset=${offset}`

        this.setState({ transactionsLoading: true });
        let transactions = await getRequest(getTransactionsUrl) ?? [];
        this.setState({ transactions: transactions, loading: false, transactionsLoading: false });

    }

    getDaysBetweenDates = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    getMockAnalytics = (startDate, endDate) => {
        var mockAnalytics = [];
        var dates = this.getDaysBetweenDates(startDate, endDate);
        dates.forEach((date, i) => {
            var shouldInclude = Math.random() < 0.5; // 50% probability of getting true
            if (shouldInclude) {
                const formattedDate = date.toISOString().split('T')[0] + 'T00:00:00';
                const postViews = Math.floor(Math.random() * 100 + 1); // Random integer from 0 to 100
                const productViews = Math.floor(Math.random() * postViews + 1);
                const productClicks = Math.floor(Math.random() * productViews + 1);
                const salesCount = Math.floor(Math.random() * productClicks + 1);
                const salesConversionPercentage = salesCount / productClicks;
                const commissionsEarned = parseInt(Math.floor(Math.random() * salesCount + 1));
                mockAnalytics.push({
                    "$id": (mockAnalytics.length + 1).toString(),
                    date: formattedDate,
                    postViews: postViews,
                    productViews: productViews,
                    productClicks: productClicks,
                    salesCount: salesCount,
                    salesConversionPercentage: salesConversionPercentage,
                    commissionsEarned: commissionsEarned
                });
            }
        });
        return mockAnalytics;
    }

    getAnalyticsPerformanceSourceByMerchant = async (startDate = this.state.startDate, endDate = this.state.endDate, merchantId) => {
        let getAnalyticsUrl = '/api/Merchant/GetAnalyticsPerformanceSourceByMerchant';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&merchantId=${merchantId}&offset=${offset}&id=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}`


        this.setState({ AnalyticsPerformanceSourceByMerchantLoading: true });

        let response = await getRequest(getAnalyticsUrl) ?? [];
        await this.handleSourceClicksSort(response);

        this.setState({ AnalyticsPerformanceSourceByMerchantLoading: false });
    };

    getAnalyticsPerformanceSalesSourceByMerchant = async (startDate = this.state.startDate, endDate = this.state.endDate, merchantId) => {
        let getAnalyticsUrl = '/api/Merchant/GetAnalyticsPerformanceSalesSourceByMerchant';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&merchantId=${merchantId}&offset=${offset}&id=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}`


        this.setState({ AnalyticsPerformanceSalesSourceByMerchantLoading: true });

        let response = await getRequest(getAnalyticsUrl) ?? [];
        await this.handleSourceClicksSort(null, null, null, response);

        this.setState({ AnalyticsPerformanceSalesSourceByMerchantLoading: false });
    };

    getAnalyticsPerformanceSourceByUserMerchantProduct = async (startDate = this.state.startDate, endDate = this.state.endDate, userMerchantProductId) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsPerformanceSourceByUserMerchantProduct';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&userMerchantProductId=${userMerchantProductId}&offset=${offset}&id=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}`


        this.setState({ AnalyticsPerformanceSourceByUserMerchantProductLoading: true });

        let response = await getRequest(getAnalyticsUrl) ?? [];

        await this.handleSourceClicksSort(null, response);

        this.setState({ AnalyticsPerformanceSourceByUserMerchantProductLoading: false });
    };

    getAnalyticsPerformanceSourceByLink = async (startDate = this.state.startDate, endDate = this.state.endDate, referralUrl) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsPerformanceSourceByLink';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&referralUrl=${encodeURIComponent(referralUrl)}&offset=${offset}&userId=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}`

        this.setState({ analyticsPerformanceSourceByUserMerchantProductLoading: true });

        let performanceSourceLink = await getRequest(getAnalyticsUrl) ?? [];
        await this.handleSourceClicksSort(null, performanceSourceLink);


        this.setState({ analyticsPerformanceSourceByUserMerchantProductLoading: false })
    };

    getAnalyticsPerformanceSalesSourceByUserMerchantProduct = async (startDate = this.state.startDate, endDate = this.state.endDate, userMerchantProductId) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsPerformanceSalesSourceByUserMerchantProduct';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&userMerchantProductId=${userMerchantProductId}&offset=${offset}&id=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}`


        this.setState({ AnalyticsPerformanceSalesSourceByUserMerchantProductLoading: true });

        let response = await getRequest(getAnalyticsUrl) ?? [];

        await this.handleSourceClicksSort(null, null, response);

        this.setState({ AnalyticsPerformanceSalesSourceByUserMerchantProductLoading: false });
    };

    getAnalyticsByProductData = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsByProduct';
        let getAnalyticsLinkUrl = '/Products/GetAnalyticsByLink';

        let now = DateTime.now();

        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`;
            getAnalyticsLinkUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
            getAnalyticsLinkUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }


        getAnalyticsUrl += `&offset=${offset}&id=${this.props.loggedInUser.id}`
        getAnalyticsLinkUrl += `&offset=${offset}&id=${this.props.loggedInUser.id}`


        this.setState({ analyticsByProductLoading: true });

        let products = await getRequest(getAnalyticsUrl) ?? [];
        let links = await getRequest(getAnalyticsLinkUrl) ?? [];
        let analytics = [...products, ...links];

        if (analytics.length > 0) {
            const convertedAnalytics = analytics.map(a =>
                !(a.sourceDataAvailable) ? { ...a, sales: -1 } : a
            );
            await this.handleSortSelect(null, convertedAnalytics);
        } else {
            this.setState({ analyticsByProductDatas: analytics })
        }

        this.setState({ loading: false, analyticsByProductLoading: false, productUserChanged: false });
    };

    getAnalyticsByMerchant = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let getAnalyticsUrl = '/api/Merchant/GetAnalyticsByMerchant';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&offset=${offset}`

        this.setState({ analyticsByProductLoading: true });

        let analytics = await getRequest(getAnalyticsUrl) ?? [];

        if (analytics.length > 0) {
            await this.handleMerchantSortSelect(null, analytics);
        } else {
            this.setState({ analyticsByMerchant: analytics })
        }

        this.setState({ loading: false, analyticsByProductLoading: false });
    };

    getAnalytics = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let getAnalyticsUrl = '/api/Merchant/GetAnalytics';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&offset=${offset}`

        this.setState({ analyticsLoading: true });

        const urlParams = new URLSearchParams(window.location.search);
        const useMockData = urlParams.get('useMockData');

        let analytics = useMockData ? this.getMockAnalytics(startDate, endDate) : await getRequest(getAnalyticsUrl) ?? [];
        this.setState({ analytics: analytics, loading: false, analyticsLoading: false });
    };

    getAnalyticsHourly = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let getAnalyticsUrl = '/api/Merchant/GetAnalyticsWithHourly';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&offset=${offset}`

        this.setState({ analyticsHourlyLoading: true });

        const urlParams = new URLSearchParams(window.location.search);
        const useMockData = urlParams.get('useMockData');

        let analytics = useMockData ? this.getMockAnalytics(startDate, endDate) : await getRequest(getAnalyticsUrl) ?? [];
        this.setState({ analyticsHourly: analytics, loading: false, analyticsHourlyLoading: false });
    };

    getAnalyticsDaily = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let getAnalyticsUrl = '/api/Merchant/GetAnalyticsWithDaily';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&offset=${offset}`

        this.setState({ analyticsDailyLoading: true });

        let analytics = await getRequest(getAnalyticsUrl) ?? [];
        this.setState({ analyticsDaily: analytics, loading: false, analyticsDailyLoading: false, dailyUserChanged: false });
    };


    fetchCommissionDetailItemsByCommissionId = async (commissionId) => {
        const url = 'Products/GetCommissionDetailItemsByCommissionId?commissionId=' + commissionId;

        return await getRequest(url) ?? [];
    }

    displayToggleButtons = () => {
        if (this.props.deviceSize === "sm") {
            return (
                <div className="d-flex ml-3" style={{ gap: "30px", cursor: "pointer" }}>
                    <AnalyticsIcon fillClass={this.state.selectedCase === 1 ? "brand-path" : "gray-path"} width={35} height={35} onClick={() => this.setState({ selectedCase: 1 })} isActive={this.state.selectedCase === 1} />
                    <CommissionsIcon fillClass={this.state.selectedCase === 2 ? "brand-path" : "gray-path"} width={35} height={35} onClick={() => this.setState({ selectedCase: 2 })} isActive={this.state.selectedCase === 2} />
                    <SpreadsheetIcon fillClass={this.state.selectedCase === 3 ? "brand-path" : "gray-path"} width={35} height={35} onClick={() => this.setState({ selectedCase: 3 })} isActive={this.state.selectedCase === 3} />
                    <SpeedometerIcon fillClass={this.state.selectedCase === 4 ? "brand-path" : "gray-path"} width={35} height={35} onClick={() => this.setState({ selectedCase: 4 })} isActive={this.state.selectedCase === 4} />
                </div>
            );
        }

        let options = [
            {
                name: "Analytics",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Commissions",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Transactions",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
            {
                name: "Performance",
                case: 4,
                action: () => this.setState({ selectedCase: 4 })
            }
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    handleSortSelect = async (e, data = null) => {
        let selectOptions = { ...this.state.selectOptions };
        const sortDescending = !!(e) && e.label === selectOptions.currentValue.label ? !this.state.sortDescending : true;
        if (!e) {
            e = selectOptions.currentValue;
        } else {
            selectOptions.currentValue = e;
        }

        const analyticsByProductDatas = !!(data) && data.length > 0 ? data : this.state.analyticsByProductDatas;

        let sortedAnalytics = [...analyticsByProductDatas];
        this.setState({ sortDescending });

        const compareFunction = (a, b) => {
            const comparison = this.getComparisonValue(a, b, e);

            if (comparison !== 0) {
                // Respect sortDescending flag for the initial comparison
                return sortDescending ? comparison : -comparison;
            } else {
                // Check for ties and sort by next priority columns
                const tieComparisons = [
                    { column: 'Clicks', comparison: this.getComparisonValue(a, b, { value: 2 }), sortDescending },
                    { column: 'Sales', comparison: this.getComparisonValue(a, b, { value: 3 }), sortDescending },
                    { column: 'Conversion Rate', comparison: this.getComparisonValue(a, b, { value: 4 }), sortDescending },
                    { column: 'Name', comparison: this.getComparisonValue(a, b, { value: 1 }), sortDescending },
                    { column: 'Merchant', comparison: this.getComparisonValue(a, b, { value: 5 }), sortDescending },
                    { column: 'Price', comparison: this.getComparisonValue(a, b, { value: 6 }), sortDescending },
                    { column: 'Sale Amt', comparison: this.getComparisonValue(a, b, { value: parseFloat(7) }), sortDescending },
                    { column: 'Commission', comparison: this.getComparisonValue(a, b, { value: parseFloat(8) }), sortDescending },
                ];

                // filter out the passed in e option from further consideration
                const filteredTieComparisons = tieComparisons.filter(item => item.column !== e.label);

                for (let i = 0; i < filteredTieComparisons.length; i++) {
                    if (tieComparisons[i].comparison !== 0) {
                        // Respect sortDescending flag for tie comparisons
                        return sortDescending ? tieComparisons[i].comparison : -tieComparisons[i].comparison;
                    }
                }

                // Default comparison if all columns are equal
                return 0;
            }
        };


        // Sort the analytics array using the custom comparison function
        sortedAnalytics.sort(compareFunction);

        this.setState({ analyticsByProductDatas: sortedAnalytics, selectOptions });
    };

    handleMerchantSortSelect = async (e, data = null) => {
        let selectOptions = { ...this.state.merchantSelectOptions };
        const sortDescending = !!(e) && !!(e.label) && e.label === this.state.merchantSelectOptions.currentValue.label ? !this.state.merchantSortDescending : true;
        if (!e) {
            e = selectOptions.currentValue;
        } else {
            selectOptions.currentValue = e;
        }

        const analyticsByMerchant = !!(data) && data.length > 0 ? data : this.state.analyticsByMerchant;

        let sortedAnalytics = [...analyticsByMerchant];

        this.setState({ merchantSortDescending: sortDescending });

        const compareFunction = (a, b) => {
            const comparison = this.getMerchantComparisonValue(a, b, e);

            if (comparison !== 0) {
                // Respect sortDescending flag for the initial comparison
                return sortDescending ? comparison : -comparison;
            } else {
                // Check for ties and sort by next priority columns
                const tieComparisons = [
                    { column: 'Merchant', comparison: this.getMerchantComparisonValue(a, b, { value: 1 }), sortDescending },
                    { column: 'Clicks', comparison: this.getMerchantComparisonValue(a, b, { value: 2 }), sortDescending },
                    { column: 'Sales', comparison: this.getMerchantComparisonValue(a, b, { value: 3 }), sortDescending },
                    { column: 'Conversion Rate', comparison: this.getMerchantComparisonValue(a, b, { value: 4 }), sortDescending },
                    { column: 'Sale Amt', comparison: this.getComparisonValue(a, b, { value: parseFloat(5) }), sortDescending },
                    { column: 'Commission', comparison: this.getComparisonValue(a, b, { value: parseFloat(6) }), sortDescending },
                ];

                // filter out the passed in e option from further consideration
                const filteredTieComparisons = tieComparisons.filter(item => item.column !== e.label);

                for (let i = 0; i < filteredTieComparisons.length; i++) {
                    if (tieComparisons[i].comparison !== 0) {
                        // Respect sortDescending flag for tie comparisons
                        return sortDescending ? tieComparisons[i].comparison : -tieComparisons[i].comparison;
                    }
                }

                // Default comparison if all columns are equal
                return 0;
            }
        };


        // Sort the analytics array using the custom comparison function
        sortedAnalytics.sort(compareFunction);

        this.setState({ analyticsByMerchant: sortedAnalytics, merchantSelectOptions: selectOptions });
    };

    // Helper function to get the comparison value based on the column
    getCommissionDetailItemsComparisonValue = (a, b, e, sortDescending) => {
        if (e.value === 1) {
            return sortDescending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (e.value === 2) {
            return sortDescending ? a.merchant.localeCompare(b.merchant) : b.merchant.localeCompare(a.merchant);
        } else if (e.value === 3) {
            return sortDescending ? a.sales - b.sales : b.sales - a.sales;
        } else if (e.value === 4) {
            return sortDescending ? a.estimatedCommission - b.estimatedCommission : b.estimatedCommission - a.estimatedCommission;
        } else if (e.value === 5) {
            return sortDescending ? a.price - b.price : b.price - a.price;
        } else {
            // Default comparison if e.value is not recognized
            return 0;
        }
    };

    getAnalyticsByCommissionDetailItem = async (startDate = this.state.startDate, endDate = this.state.endDate) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsByCommissionDetailItem';

        let now = DateTime.now();

        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`;
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }


        getAnalyticsUrl += `&offset=${offset}&id=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}&browse=${this.state.aggregateCheck ? false : this.state.browseCheck}&aggregate=false`


        this.setState({ analyticsByCommissionDetailItemLoading: true });

        let analytics = await getRequest(getAnalyticsUrl) ?? [];

        if (analytics.length > 0) {
            await this.handleCommissionDetailItemsSortSelect(null, analytics);
        } else {
            this.setState({ analyticsByCommissionDetailItems: analytics })
        }

        this.setState({ loading: false, analyticsByCommissionDetailItemLoading: false, commissionDetailItemUserChanged: false });
    };

    getAnalyticsPerformanceSalesSourceByCommissionDetailItem = async (startDate = this.state.startDate, endDate = this.state.endDate, merchantProductId) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsPerformanceSalesSourceByCommissionDetailItem';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&merchantProductId=${encodeURIComponent(merchantProductId)}&offset=${offset}&userId=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}&aggregate=${this.state.aggregateCheck}`


        this.setState({ AnalyticsPerformanceSalesSourceByUserMerchantProductLoading: true });

        let response = await getRequest(getAnalyticsUrl) ?? [];

        await this.handleSourceClicksSort(null, null, null, null, response);

        this.setState({ AnalyticsPerformanceSalesSourceByUserMerchantProductLoading: false });
    };



    getAnalyticsPerformanceSalesSourceByLink = async (startDate = this.state.startDate, endDate = this.state.endDate, referralUrl) => {
        let getAnalyticsUrl = '/Products/GetAnalyticsPerformanceSalesSourceByLink';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}` : `?endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&referralUrl=${encodeURIComponent(referralUrl)}&offset=${offset}&userId=${this.state.userId?.id > 0 ? this.state.userId?.id : this.props.loggedInUser.id}`


        this.setState({ AnalyticsPerformanceSalesSourceByUserMerchantProductLoading: true });

        let response = await getRequest(getAnalyticsUrl) ?? [];

        await this.handleSourceClicksSort(null, null, response);

        this.setState({ AnalyticsPerformanceSalesSourceByUserMerchantProductLoading: false });
    };

    // Helper function to get the comparison value based on the column
    getComparisonValue = (a, b, e, sortDescending) => {
        const getStringValue = (obj, key) => (obj[key] !== undefined ? obj[key] : obj.referralUrl);
        const getNumericValue = (obj, key) => (obj[key] !== undefined ? obj[key] : 0);

        if (e.value === 1) {
            const aName = getStringValue(a, 'name');
            const bName = getStringValue(b, 'name');
            return sortDescending ? aName.localeCompare(bName) : bName.localeCompare(aName);
        } else if (e.value === 2) {
            return sortDescending ? a.clicks - b.clicks : b.clicks - a.clicks;
        } else if (e.value === 3) {
            return sortDescending ? a.sales - b.sales : b.sales - a.sales;
        } else if (e.value === 4) {
            return sortDescending ? a.conversionRate - b.conversionRate : b.conversionRate - a.conversionRate;
        } else if (e.value === 5) {
            const aMerchant = getStringValue(a, 'merchant');
            const bMerchant = getStringValue(b, 'merchant');
            return sortDescending ? aMerchant.localeCompare(bMerchant) : bMerchant.localeCompare(aMerchant);
        } else if (e.value === 6) {
            const aPrice = getNumericValue(a, 'price');
            const bPrice = getNumericValue(b, 'price');
            return sortDescending ? aPrice - bPrice : bPrice - aPrice;
        } else if (e.value === 7) {
            return sortDescending ? a.salesAmount - b.salesAmount : b.salesAmount - a.salesAmount;
        } else if (e.value === 8) {
            return sortDescending ? a.commission - b.commission : b.commission - a.commission;
        } else {
            // Default comparison if e.value is not recognized
            return 0;
        }
    };

    // Helper function to get the comparison value based on the column
    getMerchantComparisonValue = (a, b, e, sortDescending) => {
        if (e.value === 1) {
            return sortDescending ? a.merchant.localeCompare(b.merchant) : b.merchant.localeCompare(a.merchant);
        } else if (e.value === 2) {
            return sortDescending ? a.clicks - b.clicks : b.clicks - a.clicks;
        } else if (e.value === 3) {
            return sortDescending ? a.sales - b.sales : b.sales - a.sales;
        } else if (e.value === 4) {
            return sortDescending ? a.conversionRate - b.conversionRate : b.conversionRate - a.conversionRate;
        } else if (e.value === 5) {
            return sortDescending ? a.salesAmount - b.salesAmount : b.salesAmount - a.salesAmount;
        } else if (e.value === 6) {
            return sortDescending ? a.commission - b.commission : b.commission - a.commission;
        } else {
            // Default comparison if e.value is not recognized
            return 0;
        }
    };

    handleSourceReferrerClick = (sourceSortReferrer = !this.state.sourceSortReferrer, sourceSortAscending = this.state.sourceSortAscending ) => {
        this.setState({ sourceSortReferrer, sourceSortAscending: sourceSortReferrer !== this.state.sourceSortReferrer ? false : sourceSortAscending });
    }

    handleSalesSourceReferrerClick = (salesSourceSortReferrer = !this.state.salesSourceSortReferrer, salesSourceSortAscending = this.state.salesSourceSortAscending) => {
        this.setState({ salesSourceSortReferrer, salesSourceSortAscending: salesSourceSortReferrer !== this.state.salesSourceSortReferrer ? false : salesSourceSortAscending });
    }

    render() {

        if (this.state.loading) {
            return
        }
        return (
            <>
                <Toaster />

                {this.state.loading ?
                    <>
                        <></>
                    </>
                    :
                    <>
                        <Helmet>
                            <title>RockPorch - Analytics</title>
                        </Helmet>

                        <section>
                            <AccountBanner breadCrumbList={this.breadCrumbList} creatorCentric={true} deviceSize={this.props.deviceSize}>
                                <h1 className="mb-4">Analytics</h1>
                                <p className="mb-0">Reporting on traffic and commissions</p>
                            </AccountBanner>
                        </section>
                        <section className="filter-wrapper" >
                            <div className="container">
                                <div className="row py-2 mt-0">
                                    <div className="col-12 d-flex justify-content-between">
                                        <div className="filter-block d-flex">
                                            {this.displayToggleButtons()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="gray-bg mb-0 pt-2">
                            {
                                !["sm"].includes(this.props.deviceSize) ?
                                    <></>
                                    :
                                    <div className="d-flex w-100 justify-content-end pr-2" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                        <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                        <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                        <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                                    </div>
                            }
                            <div className="container pt-0 pb-0">

                                <div className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-4"} mx-auto`}>

                                    <div className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`} style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}>
                                        <p className="ml-3 pt-3 mb-0" style={{ color: "red", display: this.state.dateError ? "" : "none" }}>Start Date must be before the End Date</p>

                                        <div className="d-flex flex-column">
                                            <label className="mb-0">Start Date:</label>
                                            <TextField
                                                id="start-date"
                                                type="date"
                                                value={this.state.startDate.toISODate()}
                                                onChange={this.handleStartDateChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    style: { fontFamily: "'Archivo', sans-serif" }
                                                }} />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label className="mb-0">End Date:</label>
                                            <TextField
                                                id="end-date"
                                                type="date"
                                                value={this.state.endDate.toISODate()}
                                                onChange={this.handleEndDateChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    style: { fontFamily: "'Archivo', sans-serif" }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        ["sm"].includes(this.props.deviceSize) ?
                                            <></>
                                            :
                                            <div className="d-flex" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                                <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                                <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                                            </div>
                                    }

                                </div>
                            </div>

                        </section>

                        <section className="gray-bg full-height">
                            <AnalyticsChart
                                display={this.state.selectedCase === 1}
                                loggedInUser={this.props.loggedInUser}
                                analytics={this.state.analytics}
                                analyticsLoading={this.state.analyticsLoading}
                                getAnalytics={this.getAnalytics}
                                date={this.state.date}
                                deviceSize={this.props.deviceSize}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />

                            {
                                this.state.selectedCase === 2 ?
                                    <CommissionsChart
                                        deviceSize={this.props.deviceSize}
                                        screenWidth={this.props.screenWidth}
                                        display={this.state.selectedCase === 2}
                                        loggedInUser={this.props.loggedInUser}
                                        commissions={this.state.commissions}
                                        commissionsLoading={this.state.commissionsLoading}
                                        getCommissions={this.getCommissions}
                                        date={this.state.date}
                                        fetchCommissionDetailItemsByCommissionId={this.fetchCommissionDetailItemsByCommissionId}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                    />
                                    :
                                    <></>
                            }                            

                            <TransactionsChart
                                display={this.state.selectedCase === 3}
                                loggedInUser={this.props.loggedInUser}
                                transactions={this.state.transactions.transactions}
                                previousBalance={this.state.transactions.previousBalance}
                                transactionsLoading={this.state.transactionsLoading}
                                getTransactions={this.getTransactions}
                                deviceSize={this.props.deviceSize}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                            />

                            {
                                this.state.selectedCase === 4 ?
                                    <AnalyticsByPerformance
                                        display={this.state.selectedCase === 4}
                                        loggedInUser={this.props.loggedInUser}
                                        analytics={this.state.analyticsByProductDatas}
                                        merchantAnalytics={this.state.analyticsByMerchant}
                                        analyticsLoading={this.state.analyticsByProductLoading}
                                        merchantAnalyticsLoading={this.state.analyticsByMerchantLoading}
                                        getAnalytics={this.getAnalyticsByProductData}
                                        getMerchantAnalytics={this.getAnalyticsByMerchant}
                                        date={this.state.date}
                                        deviceSize={this.props.deviceSize}
                                        handleGetLink={this.handleGetLink}
                                        handleSortSelect={this.handleSortSelect}
                                        handleMerchantSortSelect={this.handleMerchantSortSelect}
                                        selectOptions={this.state.selectOptions}
                                        merchantSelectOptions={this.state.merchantSelectOptions}
                                        sortDescending={this.state.sortDescending}
                                        merchantSortDescending={this.state.merchantSortDescending}
                                        resetPerformanceTabs={this.resetPerformanceTabs}
                                        getAnalyticsPerformanceSourceByMerchant={this.getAnalyticsPerformanceSourceByMerchant}
                                        getAnalyticsPerformanceSourceByUserMerchantProduct={this.getAnalyticsPerformanceSourceByUserMerchantProduct}
                                        getAnalyticsPerformanceSalesSourceByUserMerchantProduct={this.getAnalyticsPerformanceSalesSourceByUserMerchantProduct}
                                        getAnalyticsPerformanceSalesSourceByLink={this.getAnalyticsPerformanceSalesSourceByLink}
                                        getAnalyticsPerformanceSalesSourceByMerchant={this.getAnalyticsPerformanceSalesSourceByMerchant}
                                        performanceSourceMerchant={this.state.performanceSourceMerchant}
                                        performanceSourceUserMerchantProduct={this.state.performanceSourceUserMerchantProduct}
                                        performanceSalesSourceUserMerchantProduct={this.state.performanceSalesSourceUserMerchantProduct}
                                        performanceSalesSourceMerchant={this.state.performanceSalesSourceMerchant}
                                        analyticsPerformanceSourceByUserMerchantProductLoading={this.state.analyticsPerformanceSourceByUserMerchantProductLoading}
                                        analyticsPerformanceSalesSourceByUserMerchantProductLoading={this.state.analyticsPerformanceSalesSourceByUserMerchantProductLoading}
                                        analyticsPerformanceSalesSourceByMerchantLoading={this.state.analyticsPerformanceSalesSourceByMerchantLoading}
                                        getAnalyticsPerformanceSourceByLink={this.getAnalyticsPerformanceSourceByLink}
                                        clearPerformance={this.clearPerformance}
                                        clearSourceSort={this.clearSourceSort}
                                        clearSalesSourceSort={this.clearSalesSourceSort}
                                        handleSourceReferrerClick={this.handleSourceReferrerClick}
                                        handleSourceClicksSort={this.handleSourceClicksSort}
                                        handleSalesSourceReferrerClick={this.handleSalesSourceReferrerClick}
                                        sourceSortAscending={this.state.sourceSortAscending}
                                        sourceSortReferrer={this.state.sourceSortReferrer}
                                        salesSourceSortAscending={this.state.salesSourceSortAscending}
                                        salesSourceSortReferrer={this.state.salesSourceSortReferrer}
                                        userId={this.state.userId?.id}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        hourlyUserChanged={this.state.hourlyUserChanged}
                                        userIdSelected={this.state.userId}
                                        analyticsHourly={this.state.analyticsHourly}
                                        getAnalyticsHourly={this.getAnalyticsHourly}
                                        analyticsHourlyLoading={this.state.analyticsHourlyLoading}
                                        analyticsDaily={this.state.analyticsDaily}
                                        getAnalyticsDaily={this.getAnalyticsDaily}
                                        analyticsDailyLoading={this.state.analyticsDailyLoading}
                                        dailyUserChanged={this.state.dailyUserChanged}
                                        commissionDetailItemsAnalytics={this.state.analyticsByCommissionDetailItems}
                                        analyticsByCommissionDetailItemLoading={this.state.analyticsByCommissionDetailItemLoading}
                                        getCommissionDetailItemsAnalytics={this.getAnalyticsByCommissionDetailItem}
                                        handleCommissionDetailItemsSortSelect={this.handleCommissionDetailItemsSortSelect}
                                        commissionDetailItemsSelectOptions={this.state.commissionDetailItemsSelectOptions}
                                        commissionDetailItemsSortDescending={this.state.commissionDetailItemsSortDescending}
                                        commissionDetailItemUserChanged={this.state.commissionDetailItemUserChanged}
                                        getAnalyticsPerformanceSalesSourceByCommissionDetailItem={this.getAnalyticsPerformanceSalesSourceByCommissionDetailItem}
                                        performanceSalesSourceCommissionDetailItem={this.state.performanceSalesSourceCommissionDetailItem}
                                    />
                                    :
                                    <></>
                            }                           
                        </section>
                    </>
                }
               
            </>
        )
    }
}

export default Analytics;