import * as React from "react";
import { NavLink } from "react-router-dom";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { Form, FormGroup, Label, Input } from "reactstrap";
import Modal from "../../components/Modal";
import CreatorApplication from "../../components/CreatorApplication";

class OnboardUserType extends React.Component {

    state = {
        submitting: false,
        formData: {},
        modalOpen: false,
    }

    toggleModal = () => {
        let newModalOpen = !this.state.modalOpen;
        this.setState({ modalOpen: newModalOpen });
    }

    renderModal = () => {
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleModal} centerModal={this.props.deviceSize !== 'sm' && this.props.deviceSize !== 'md'}>
                <CreatorApplication isOpen={this.state.modalOpen} toggleModal={this.toggleModal} onInputChange={this.onInputChange} formData={this.state.formData} saveUser={this.saveUser} continueUrl={"/Account/OnboardWhoToFollow"}/>
            </Modal>
        )
    }

    componentDidMount = async () => {
        this.props.setShowTopNav(false);
        this.setState({ formData: this.convertToFormData(this.props.loggedInUser) });
    }

    saveUser = async () => {
        let userUrl = '/api/Account/PostUser';

        let user = await postRequest(userUrl, this.convertToUserModel());
        this.props.setLoggedInUser(user);
    }

    mapInputFields = (fields) => {
        return fields.map((field, i) => {
            return (
                <FormGroup key={i} className="form-group">
                    <Label className="control-label">{field.title}</Label>
                    <Input
                        className="form-control"
                        name={field.name}
                        onChange={(e) => this.onInputChange(e)}
                        value={this.state.formData[field.name]}
                    />
                </FormGroup>
            );
        });
    }

    mapInputFieldsSocial = () => {
        let fields = [
            { name: "instagram", title: "Instagram" },
            { name: "facebook", title: "Facebook" },
            { name: "youtube", title: "YouTube" },
        ];
        return this.mapInputFields(fields);
    };

    mapInputFieldsPersonal = () => {
        let fields = [
            { name: "name", title: "Name" },
            { name: "audience", title: "Primary Audience / Region" },
        ];
        return this.mapInputFields(fields);
    };


    onInputChange = (e) => {
        let formkey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;
        formData[formkey] = newData;

        this.setState({ formData: formData });
    };

    convertToFormData = (user) => {
        return {
            youtube: user.googleUsername,
            facebook: user.facebookUsername,
            instagram: user.instagramUsername,
            realName: "",
            audience: "",
            postFrequency: "",
            timeCommitment: "",
            platform: "",
            platformUsername: "",
            followers: "",
            userName:user.userName
        };
    }

    convertToUserModel = () => {
        return {
            name: this.props.loggedInUser.name,
            firstName: this.props.loggedInUser.firstName,
            lastName: this.props.loggedInUser.lastName,
            bio: this.props.loggedInUser.bio,
            tagLine: this.props.loggedInUser.tagLine,
            website: this.props.loggedInUser.website,
            googleUsername: this.state.formData.youtube,
            facebookUsername: this.state.formData.facebook,
            instagramUsername: this.state.formData.instagram,
            imageUrl: this.props.loggedInUser.imageUrl,
            userName: this.props.loggedInUser.userName,
            email: this.props.loggedInUser.email,

            phoneNumber: this.props.loggedInUser.phoneNumber,
            proStatusId: this.props.loggedInUser.proStatusId,
            address: this.props.loggedInUser.address,
            city: this.props.loggedInUser.city,
            state: this.props.loggedInUser.state,
            postalCode: this.props.loggedInUser.postalCode,
            country: this.props.loggedInUser.country,

            onboardingComplete: this.props.loggedInUser.onboardingComplete,
            profileBannerUrl: this.props.loggedInUser.profileBannerUrl,
            quickStart: this.props.loggedInUser.quickStart
        }
    }

    render = () => {
        return (
            <div className="container-fluid gray-bg full-height">
                {this.renderModal()}
                <div className="row mt-0">
                    <div className="col-12 px-0 top-banner position-relative">
                        <img src="images/top-banner.png" alt="" className="w-100 h-100" />
                        <div className="interest-steps position-absolute text-center w-100">
                            <h1 className="mb-4">Explore and Create</h1>
                            <p>Sign up as a user to explore products, lockers, and posts from your favorite Creators, or apply to become a Creator</p>
                            <ul className="steps-count pl-0">
                                <li className="d-inline-flex justify-content-center align-items-center active">1</li>
                                <li className="d-inline-flex justify-content-center align-items-center active">2</li>
                                <li className="d-inline-flex justify-content-center align-items-center">3</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 search-interest py-4 py-md-5 mx-auto">
                        <div className="container">
                            <div className="row pt-0 mt-0 pb-5 justify-content-center">
                                <div className="col-6 col-lg-4 pl-0 pr-2 px-lg-3">
                                    <div className="user-detail-form text-center">
                                        <div className="user-type-detail-block px-0">
                                            <h3>Login as a User</h3>
                                            <small>to explore content</small>
                                            <hr className="mb-0" style={{borderColor: "#FFFFFF"}}/>
                                            <ul className="user-type-features mb-3 mb-lg-4">
                                                <li className="user-type-features-item">Shop your favorite content</li>
                                                <li className="user-type-features-item">Discover new gear</li>
                                                <li className="user-type-features-item">Learn a new skill</li>
                                                <li className="user-type-features-item">Message Creators for curated gear lists</li>
                                            </ul>
                                            <div className="text-center">
                                                <NavLink to="/Account/OnboardWhoToFollow">
                                                    <button className="btn btn-primary" onClick={() => { }}>Continue</button>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 col-lg-4 pr-0 pl-2 px-lg-3">
                                    <div className="user-detail-form text-center">
                                        <div className="user-type-detail-block px-0 mb-lg-4">
                                            <h3>Apply as a Creator</h3>
                                            <small>to share content</small>
                                            <hr className="mb-0" style={{ borderColor: "#FFFFFF" }} />
                                            <ul className="user-type-features mb-3 mb-lg-4">
                                                <li className="user-type-features-item">Requires RockPorch Approval</li>
                                                <li className="user-type-features-item">Content aligned with RockPorch</li>
                                                <li className="user-type-features-item">Demonstrates a positive influence</li>
                                                <li className="user-type-features-item">Earn commissions</li>
                                            </ul>
                                            <div className="text-center">
                                                <button className="btn btn-primary" onClick={() => {this.toggleModal()}}>Apply Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>)
    }
}

export default OnboardUserType;