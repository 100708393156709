import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import { connect } from "react-redux";
import LockerPage from "views/Locker/LockerPage";
import { toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { Redirect } from "react-router-dom";

/**
 * 
 * @param {{loggedInUser:object, shareCode:string, showModal:boolean, parentModal:{type:string,parentState:object}}} props
 */
const LockerPageModal = (props) => {
    let paramProps = {
        params: {
            post: props.post,
            loggedInUser: props.loggedInUser,
            setPost: props.setPost
        }
    }

    useEffect(() => {
        if (props.showModal) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [props.showModal]);

    let [isAddingProducts, setIsAddingProducts] = useState(false);

    let goToPage = () => setIsAddingProducts(true);

    if (isAddingProducts === true) {
        return <Redirect to={{
            pathname: `/Locker/LockerPage/${props.post.shareCode}`,
            state: {
                isAddingProducts: true,
                destinationState: {
                    params: paramProps.params
                }
            }
        }} />
    } else {
        return (
            <Modal
                hideOpenAsPage={true}
                isOpen={props.showModal}
                toggleModal={() => {
                    if (props.parentModal !== null) {
                        switch (props.parentModal?.type) {
                            case "product": {
                                props.toggleProductModal(props.parentModal.parentState,props.loggedInUser,false,null,null);
                                break;
                            }
                            default: {
                                props.toggleModal(props.post, props.loggedInUser, false, props.setPost);
                                break;
                            }
                        }
                    }
                    else {
                        props.toggleModal(props.post, props.loggedInUser, false, props.setPost)
                    }
                    
                }}
                showHeader={true}
                showThreeDotMenu={props.loggedInUser ? true : false}    
                loggedInUser={props.loggedInUser}
                post={props.post}
                modalDialogCustomClass="modal-dialog-md"
                modalHeaderRedirect={`/Locker/LockerPage/${props.post?.shareCode}`}
                state={paramProps}
            >
                <LockerPage
                    deviceSize={props.deviceSize}
                    match={paramProps}
                    loggedInUser={props.loggedInUser}
                    isModal={true}
                    isCommenting={props.isCommenting}
                    handleAddProducts={goToPage}
                    toggleSignUpModal={props.toggleSignUpModal}
                />
            </Modal>

        )
    }

   
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.lockerPageModal.showLockerPageModal,
        shareCode: storeState.modalReducer.lockerPageModal.shareCode,
        post: storeState.modalReducer.lockerPageModal.post,
        loggedInUser: storeState.modalReducer.lockerPageModal.loggedInUser,
        isCommenting: storeState.modalReducer.lockerPageModal.isCommenting,
        setPost: storeState.modalReducer.lockerPageModal.setPost,
        parentModal: storeState.modalReducer.lockerPageModal.parentModal
    }
}

const mapDispatch = {
    toggleModal: toggleLockerPageModal,
    toggleProductModal: toggleProductPageModal
}

export default connect(mapStateToProps, mapDispatch)(LockerPageModal);