import React, { useState, useEffect, useRef } from "react";
import RightArrowIcon from "components/icons/RightArrowIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import ImageWrapper from "components/ImageWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { trimProductsArray } from "sharedUtils/postFunctions";
import { useSwipeable } from "react-swipeable";
import { getRequest } from "sharedUtils/httpUtils";
import FeedPostGridItem from "components/Feed/FeedPostGridItem";


/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const FeaturedContent = (props) => {
    const [postPage, setPostPage] = useState(0);
    const [posts, setPosts] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [postItems, setPostItems] = useState([
        { type: "post", shareCode: "3zGPAidJU6wxx" },
        { type: "locker", shareCode: "MSMKwYGmdCErk" },
        { type: "post", shareCode: "cqTpkmHEVBbtA" },
        { type: "post", shareCode: "bPzr6e2SvD6q6" },
        { type: "post", shareCode: "A7hR8O5mcjNKb" },
        { type: "post", shareCode: "D1bDP4pXYdDrp" },
        { type: "post", shareCode: "mapIiS3TnlqRj" },
        { type: "post", shareCode: "7W7jj3AL2g7ZN" },
        { type: "post", shareCode: "SFTawDbC1nBjJ" },
        { type: "post", shareCode: "Wd24A2dMwwUBa" },
    ]);

    const [intervalId, setIntervalId] = useState(null); // Add state for intervalId
    const [lastTotalHeight, setLastTotalHeight] = useState(150);

    const getHeightMessage = (e) => {
        if (e.data === 'get-rp-featured-content-height') {
            messageTotalHeight();
        }
    }

    const messageTotalHeight = () => {
        setCheckHeight({ ...-checkHeight });
    };

    useEffect(() => {
        props.setShowTopNav(false);
        populateData();

        window.addEventListener('message', messageTotalHeight);
        window.addEventListener('resize', messageTotalHeight);
        return () => {
            window.removeEventListener('message', getHeightMessage);
            window.removeEventListener('resize', messageTotalHeight);
        };
    }, []); 

    useEffect(() => {
        if (props.deviceSize === "sm") {
            setItemsPerPage(2);
        } else {
            setItemsPerPage(4)
        }
    }, [props.deviceSize]);

    const [checkHeight, setCheckHeight] = useState(1);
    useEffect(() => {
        let totalHeight = document.documentElement.offsetHeight;

        if (lastTotalHeight !== totalHeight && totalHeight > 150) {
            setLastTotalHeight(totalHeight);

            window.parent.postMessage({
                id: 'rp-featured-content',
                height: totalHeight,
            }, '*');
        }
    }, [checkHeight])

    const populateData = async () => {
        let url = `api/Featured/GetFeaturedContent`;
        let data = await getRequest(url);

        if (data === undefined) {
            setPosts([]);
        }
        else {
            setPosts(data);
            setTimeout(messageTotalHeight, 1000);
        }
    }

    const resetInterval = () => {
        clearInterval(intervalId);
        const newIntervalId = setInterval(() => {
            nextPage();
        }, 5000);
        setIntervalId(newIntervalId);
    }

    const prevPage = () => {
        if (posts.length > itemsPerPage) {
            let page = postPage - 1;
            if (page < 0) {
                page = Math.floor((posts.length - 1) / itemsPerPage);
            }

            setPostPage(page);

            resetInterval();
        }
    }

    const nextPage = () => {
        setPostPage(prevPage => {
            let page = prevPage + 1;
            if (page * itemsPerPage >= posts.length) {
                page = 0;
            }

            return page;
        });

        resetInterval();
    }

    const postPageHandlers = useSwipeable({
        onSwipedLeft: () => {
            nextPage();
            resetInterval();
        },
        onSwipedRight: () => {
            prevPage();
            resetInterval();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    useEffect(() => {
        if (posts.length > 0 && !intervalId) { // Change condition to check if intervalId is falsy
            resetInterval();
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [posts, intervalId]);

    const renderProductPagination = () => {
        if (posts && posts.length > itemsPerPage) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            prevPage()
                        }}>
                        <span className="item-count-prev d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            nextPage()
                        }}>
                        <span className="item-count-next d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    let renderProducts = () => {
        if (posts && posts?.length > 0) {
            return posts.slice(postPage * itemsPerPage, postPage * itemsPerPage + itemsPerPage).map((post, i) => {
                let redirectUrl = post.type === 2 ? `/Locker/LockerPage/${post?.shareCode}?showBrandNav=true` : post.type === 4 ? `/Blog/BlogPage/${post?.shareCode}?showBrandNav=true` : `/post/postPage/${post?.shareCode}?showBrandNav=true`
                return (
                    <div className={`col-md-3 px-1 py-1 mx-0 my-0 grid-item ml-2 mr-2`}
                        style={{ maxWidth: itemsPerPage < 4 ? "42%" : "22%", boxShadow: "rgba(229, 89, 52, 0.4) 0px 5px 15px", borderRadius: "15px" }}
                        key={i}
                    >
                        <FeedPostGridItem
                            post={post}
                            isPictureAndProducts={post.type === 2}
                            redirectUrl={redirectUrl}
                            deviceSize={props.deviceSize}
                            loggedInUser={props.loggedInUser}
                            showUserTile={true}
                            savedByUser={post.savedByUser}
                            hideItemCount={![2,4].includes(post.type)}
                            disableRedirects={true}
                            brandEmbed={true}
                            isBrandSmall={itemsPerPage < 4}
                        />
                    </div >
                )
            })
        }
    }

    let gridItem = () => {
        if (posts?.length > 0) {
            return (
                <div className="explore-feed-wrapper" id="contentDiv" style={{ borderRadius: "20px", overflow: "hidden" }}>
                    <div className="feed-slider-products mt-3 pb-0" style={props.style}>
                        <div {...postPageHandlers} className={`row mx-0 product-image-gallery justify-content-center px-2 position-relative pt-0 ${props.brand ? "mb-0" : "mb-3"} mt-0`} style={{ width: "100%" }}>
                            {renderProductPagination()}
                            {renderProducts()}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        gridItem()
    //    <PostPageEmbedBrand shareCodeDirect={"3zGPAidJU6wxx"} />
    )
}
const mapDispatch = { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(FeaturedContent);