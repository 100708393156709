import { ClientRequest } from "../../api-social/clientRequest";
import { getRequest, postRequest } from "sharedUtils/httpUtils"

export class TikTokAuthApi {
    constructor(appId) {
        this._appId = appId;
        this._authorization = undefined;
        this._authHeader = null;

        this.authorize = this.authorize.bind(this);
        this.doAuthorize = this.doAuthorize.bind(this);
    }
    set appId(value) {
        this._appId = value;
    }

    get authorization() {
        return this._authorization;
    }
    set authorization(value) {
        if (this._authorization === value)
            return;
        if (!(value)) {
            this._authorization = undefined;
            return;
        }
        this._authorization = value;
        this.sessionAuthorization = value;
    }
    get isAuthorized() {
        return !!(this.authorization?.access_token);
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    async doAuthorize() {
        return new Promise(async (resolve, reject) => {
            try {
                const authorization = await this.doGetAuthorizationTicket();
                let authorizationST = -1;
                if (authorization !== -1) {
                    authorizationST = await ClientRequest.get(`/api/tiktok/accesstokenV2/?authorizationCode=${decodeURI(authorization)}`, null, false, this.authHeader);
                }
                resolve(authorizationST);
            } catch (err) {
                reject(err);
            }
        });
    }


    doGetAuthorizationTicket = async () => {
        const redirect_uri = ["rockpor", "evolvedoutdoorsprodweb-staging"].some(substring => window.location.host.includes(substring)) ? window.location.host : "dev.rockporch.com";
        return new Promise((resolve, reject) => {
            let url = `//www.tiktok.com/v2/auth/authorize/?client_key=${this._appId}&response_type=code&scope=user.info.basic,video.list,user.info.profile&redirect_uri=https://${redirect_uri}/tiktok/auth/`;
            let s = window.screen, ww = s.width, wh = s.height, w = 700, h = 500, l = (ww - w) / 2, t = (wh - h) / 2;
            let features = `width=${w},height=${h},left=${l},top=${t}`;

            const handleLoad = async () => {
                if (popup.location.search.length === 0) {
                    popup.open(url, '_self');
                }
                var interval = setInterval(async () => {
                    try {
                        if (!popup || popup.closed) {
                            clearInterval(interval);
                            //    reject('Authorization window closed by user');
                            resolve(-1);
                        } else if (popup.location.search?.length) {
                            clearInterval(interval);
                            let qs = popup.location.search;

                            popup.close();

                            let startIndex = qs.indexOf("code=") + "code=".length;
                            let endIndex = qs.indexOf("&");

                            let authorization = qs.slice(startIndex, endIndex);
                            if (!authorization) {
                                resolve(-1);
                            } else {
                                resolve(authorization);
                            }
                        }
                    }
                    catch (err) {
                        if (!(err.code === 18 && err.message.includes('Blocked a frame with origin'))) {
                            if (!!popup || popup.closed) {
                                clearInterval(interval);
                                popup.close();
                            }
                            this._authApi.authorization = null;
                            reject(err);
                        }
                    }
                }, 100);
            };
            var popup = window.open(`${window.location.origin}/TikTok/auth`, '', features);

            popup.onload = (handleLoad);
        });
    }

    authorize() {
        if (this.isAuthorized) {
            return new Promise((resolve) => {
                resolve(this.authorization);
            })
        }

        return new Promise(async (resolve) => {
            const auth = await this.doAuthorize();
            resolve(auth == -1 ? undefined : auth);
        })
    }

    init(headers) {
        const handleResolve = async (resolve) => {
            await this.doInitAsync(resolve, headers);
        };
        return new Promise(handleResolve);
    }

    async doInitAsync(resolve, headers) {
        this._authHeader = headers;
        resolve(this);
    }

    logout(accessToken) {
        return new Promise(async (resolve, reject) => {
            let logout = await ClientRequest.get(`/api/tiktok/logoutV2/?access_token=${accessToken}`, null, false, this.authHeader);
            resolve(logout);

        });
    }
}

export class TikTokClientApi {
    constructor(accessToken) {
        this._accessToken = accessToken;
    }
    setAccessToken(accessToken) {
        this._accessToken = accessToken;
    }

    getMe() {
        const ALL_USER_FIELDS = ["open_id", "union_id", "avatar_url", "display_name", "bio_description"];
        const url = 'https://open.tiktokapis.com/v2/user/info/?fields=' + ALL_USER_FIELDS.join(',');
        return new Promise(async (resolve, reject) => {
            const handleResolved = (response) => {
                resolve(response);
            };
            const handleRejected = (err) => {
                reject(err)
            };

            let request = await ClientRequest.get(url, null, false, {Authorization: 'Bearer ' + this._accessToken}).then(handleResolved, handleRejected);

            await request;
        });
    }

    getUserMedia(accessToken, after = null) {
        const ALL_MEDIA_FIELDS = ["id", "create_time", "cover_image_url", "share_url", "video_description", "duration", "height", "width", "embed_html", "embed_link", "title", "like_count", "comment_count", "share_count", "view_count"];
        return new Promise(async (resolve, reject) => {
            let endpoint = 'https://open.tiktokapis.com/v2/video/list/?fields=' + ALL_MEDIA_FIELDS.join(',');
            let criteria = {
                max_count: 20
            };

            if (after != null) {
                criteria.cursor = after
            }

            const handleResolved = async (response) => {
                resolve(response);
            };
            const handleRejected = (err) => {
                reject(err);
            };
            await postRequest(endpoint, criteria, "application/json", true, accessToken).then(handleResolved, handleRejected);
        });
    }
}

var _authApi = new TikTokAuthApi('');
var _clientApi = new TikTokClientApi(null);

let _clientHandle = undefined;

class TikTokClient {
    constructor() {
        this._headers = undefined;
        this._appId = undefined;
        this._open_id = undefined;
        _authApi = null;
        _clientApi = null;

        this.init = this.init.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.getChannelsFor = this.getChannelsFor.bind(this);
        this.getPostsFor = this.getPostsFor.bind(this);
        this.getUserProfile = this.getUserProfile.bind(this);
    }

    get clientApi() {
        if (!(_clientApi)) {
            if (this.isAuthorized) {
                _clientApi = new TikTokClientApi(this.authorization.access_token);
            }
        }
        return _clientApi;
    }
    set clientApi(value) {
        _clientApi = null;
    }

    get authorization() {
        return _authApi?.authorization;
    }
    set authorization(value) {
        if (this.isAuthorized) {
            _authApi.authorization = null;
        }
    }
    get isAuthorized() {
        return !!(_authApi?.isAuthorized);
    }
    get isInitialized() {
        return !!_authApi;
    }

    init(appId, headers) {
        let candidate = { api: this };
        let isInitializing = false;
        if (_clientHandle === undefined) {
            _clientHandle = candidate;
            isInitializing = true;
        }
        let retVal = _clientHandle;

        this._headers = headers;
        return new Promise(async (resolve, reject) => {
            try {
                if (this.isInitialized) {
                    resolve(retVal);
                } else {
                    if (!isInitializing) {
                        setTimeout(() => {
                            resolve(retVal);
                        }, 2000);
                    } else {
                        let authApi = new TikTokAuthApi(appId);
                        await authApi.init(headers)
                            .then(
                                async (val) => {
                                    _authApi = val;
                                    resolve(retVal);
                                },
                                (err) => {
                                    _authApi.authorization = null;
                                    reject(err.error);
                                }
                            );
                    }
                }
            } catch (ex) {
                _authApi.authorization = null;
                reject(ex);
            }
        });
    }

    login() {
        return new Promise(async (resolve, reject) => {
            try {
                await _authApi.authorize()
                    .then(
                        (val) => {
                            if (val == undefined) {
                                resolve(val);
                            }
                            this._open_id = val.open_id;
                            _authApi.authorization = val;
                            _clientApi = new TikTokClientApi(val.access_token);
                            resolve(val);
                        },
                        (err) => {
                            _authApi.authorization = null;
                            resolve(undefined);
                        }
                    );
            }
            catch (ex) {
                _authApi.authorization = null;
                reject(ex);
            }
        });
    };

    logout() {
        return new Promise(async (resolve, reject) => {
            if (!(this.isAuthorized)) {
                reject('user may remain authorized according to tiktok')
            }
            if (await _authApi.logout(this.authorization?.access_token)) {
                this.authorization = null;
                this.clientApi = null;
                resolve(true);
            }
        });
    };

    getChannelsFor(userProfile) {
        return new Promise(async (resolve, reject) => {
            let access_token = this.authorization?.access_token;
            let hasAccessToken = !!access_token;

            if (!hasAccessToken) {
                reject('User is not authorized');
            } else if (!(userProfile?.id)) {
                reject('userProfile parameter required')
            } else {
                resolve({
                    data: [{ id: userProfile.id, name: 'default channel' }]
                });
            }
        });
    }

    getPostsFor(channel) {
        let headers = this._headers;
        return new Promise(async (resolve, reject) => {
            let access_token = this.authorization?.access_token;
            let hasAccessToken = !!access_token;

            if (!hasAccessToken) {
                reject('User is not authorized');
            } else if (!(channel?.id)) {
                reject('channel parameter required')
            } else {

                let promise = undefined;

                if (!!(channel.posts?.paging?.cursors.after)) {
                    promise = this.clientApi.getUserMedia(access_token, channel.posts.paging.cursors.after);
                } else {
                    promise = this.clientApi.getUserMedia(access_token);
                }

                promise.then(
                    async (val) => {
                        const targetModelFrom = (sourceModel) => {
                            let embedHtml = sourceModel.embed_html;
                            let embedWidth = null;
                            if (sourceModel?.embed_html != undefined && sourceModel?.embed_html != null) {
                                const embed = sourceModel.embed_html;
                                const startIndex = embed.indexOf("max-width: ") + "max-width: ".length;
                                const endIndex = embed.indexOf("px", startIndex);
                                const tempWidth = Number.parseInt(embed.slice(startIndex, endIndex));
                                if (Number.isInteger(tempWidth) && tempWidth > 0) {
                                    embedWidth = tempWidth;
                                }
                            }
                            let targetModel = {
                                content: sourceModel.video_description,
                                embed: embedHtml,
                                id: sourceModel.id,
                                link: embedHtml,
                                media: [{
                                    children: [{
                                        height: sourceModel.height,
                                        width: sourceModel.width,
                                        thumbnailLink: sourceModel.cover_image_url,
                                        isExternalReference: true
                                    }],
                                    duration: sourceModel.duration,
                                    height: null,
                                    imageLink: null,
                                    embedLink: embedHtml,
                                    isExternalReference: false,
                                    thumbnailLink: null,
                                    videoLink: null,
                                    width: embedWidth,
                                }],
                                platformName: 'tiktok',
                                publishedOn: sourceModel.create_time,
                                raw: { ...sourceModel },
                                title: sourceModel.title,
                            };
                            return targetModel;
                        };

                        let retVal = { data: [] };
                        for (let i = 0; i < val.data.videos.length; i++) {
                            let sourceModel = val.data.videos[i];
                            let targetModel = targetModelFrom(sourceModel);
                            retVal.data.push(targetModel);
                        }

                        if (val.data.has_more) {
                            retVal.paging = { cursors: { after: val.data.cursor } };
                        } else {
                            delete retVal.paging;
                        }

                        resolve(retVal);
                    },
                    (err) => {
                        this.authorization = null;
                        reject(err)
                    }
                );

                await promise;
            }
        });
    }

    getUserProfile() {
        let authorization = _authApi.authorization;
        return new Promise(async (resolve, reject) => {
            let access_token = authorization?.access_token;
            let hasAccessToken = !!access_token;

            if (!hasAccessToken) {
                reject('User is not authorized');
            } else {
                await this.clientApi.getMe()
                    .then(
                        (initialVal) => {
                            let val = {
                                id: initialVal.data.user.open_id,
                                name: initialVal.data.user.display_name,
                                imageUrl: initialVal.data.user.avatar_url,
                            }
                            resolve(val);
                        },
                        (err) => {
                            _authApi.authorization = null;
                            reject(err);
                        }
                    );
            }
        });
    }
}

const tikTokClient = window.TikTokClient || new TikTokClient();
if (!window.TikTokClient) {
    window.TikTokClient = TikTokClient;
}

export default tikTokClient;
