import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";

import ActionButton from "components/ActionButton";

/**
 * 
 * @param {{
 * showModal:boolean,
 * toggleModal:Function,
 * product:object,
 * productDescription:string,
 * saveDescription:(description:string)=>void,
 * sectors:[],
 * updateSectors:(sectorIds:Array<Number>)=>void,
 * saveSectorChanges:()=>void,
 * updateProductRating:(rating:Number)=>void
 * }} props
 */
const EditLinkModal = (props) => {
    const [link, setLink] = useState({});
    const [brand, setBrand] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("")

    const tryParseFloat = (text) => {
        const parsed = parseFloat(text);
        return isNaN(parsed) ? null : parsed.toFixed(2);
    }

    useEffect(() => {
        setLink(props.link);
        setBrand(props.link.brand ?? "");
        setDescription(props.link.description ?? "");
        setPrice(props.link.price ?? "");
    }, [props.link]);

    const updateLink = async () => {
        let updatedLink = props.convertMerchantLinkToUploadableObject(props.link);
        updatedLink.brand = brand;
        updatedLink.description = description;
        updatedLink.price = tryParseFloat(price);

        await props.uploadLink(updatedLink);
        props.toggleModal();
    }

    //                 <p style={{ fontSize: "13px", marginBottom: "3px", color: "red", display: deepLinkDetailsFailed ? "" : "none" }}>Add FAILED. Check data and try again.</p>


    return (
        <Modal style={{ marginTop: "100px" }} isOpen={props.showModal}
            toggle={props.toggleModal}
        >
            <div className="mb-4 d-flex flex-column" style={{ marginLeft: "0px", margin: "15px" }}>
                <div className="mt-2">
                    <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "3px" }}>Link</p>
                    <div style={{
                        position: "relative"
                    }}>
                        <input
                            className="w-100 mr-3"
                            type="text"
                            value={link.referralUrl}
                            id="deepLinkUrl"
                            style={{ backgroundColor: "white", border: "none", marginTop: "", display: "inline-block", textOverflow: "ellipsis" }}
                            readOnly
                        />
                    </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "3px", fontWeight: "bold", }}>Retailer</p>
                    <input
                        className="w-100 mr-3"
                        type="text"
                        id="retailer"
                        value={link.merchantName}
                        style={{ backgroundColor: "white", border: "none", marginTop: "" }}
                        readOnly
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "3px", fontWeight: "bold", }}>Description<span style={{ position: "relative", top: "-3px" }}>*</span></p>
                    <input
                        className="w-100 mr-3"
                        type="text"
                        value={description.length > 0 ? description : ""}
                        id="name"
                        placeholder={description.length === 0 ? "Enter link description" : description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ backgroundColor: "#e9ecef", border: "none", marginTop: "" }}
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "3px", fontWeight: "bold", }}>Brand Name</p>
                    <input
                        className="w-100 mr-3"
                        type="text"
                        value={brand?.length > 0 ? brand : ""}
                        id="manufacturer"
                        placeholder={brand.length === 0 ? "Enter brand name if any" : brand}
                        onChange={(e) => setBrand(e.target.value)}
                        style={{ backgroundColor: "#e9ecef", border: "none", marginTop: "" }}
                        autoFocus
                    />
                </div>

                <div style={{ marginTop: "10px" }}>
                    <p style={{ fontSize: "16px", marginBottom: "3px", fontWeight: "bold", }}>Retail Price</p>
                    <input
                        className="w-100 mr-3"
                        type="number"
                        value={price}
                        id="price"
                        placeholder="Enter product price if any"
                        onChange={(e) => setPrice(e.target.value)}
                        style={{ backgroundColor: "#e9ecef", border: "none", marginTop: "" }}
                    />
                </div>

                <p style={{ fontSize: "12px", position: "relative", top: "10px" }}><span style={{ position: "relative", top: "-3px" }}>*</span> Required field</p>

               <div className="d-flex justify-content-end mt-3">
                    <ActionButton
                        onClick={props.toggleModal}
                        className="btn btn-secondary mt-3"
                        style={{ marginTop: "10px", }}
                    >Cancel</ActionButton>

                    <ActionButton
                        onClick={updateLink}
                        className="btn btn-primary ml-3 mt-3"
                        style={{ marginTop: "10px", }}
                        isLoading={props.loadingLinkUpload}
                        isDisabled={!(description?.trim())}
                    >Save</ActionButton>

                </div>

            </div>

        </Modal>

    )
}


export default EditLinkModal;