import React from "react"

/**
 *
 * @param {{fill:string, width:string, height:string}} props
 */
const LikeIcon = (props) => {
    return (
        <svg width={props.width || "12"} height={props.height || "12"} viewBox="0 0 12 12" fill={ props.fill || "none"} xmlns="http://www.w3.org/2000/svg">
            <path strokeWidth="1" stroke="currentColor" strokeOpacity="0.6" d="M8.25 2.5C7.44525 2.5 6.50413 3.41232 6 4C5.49587 3.41232 4.55475 2.5 3.75 2.5C2.3255 2.5 1.5 3.61109 1.5 5.0252C1.5 6.59173 3 8.25 6 10C9 8.25 10.5 6.625 10.5 5.125C10.5 3.71089 9.6745 2.5 8.25 2.5Z" fill={props.fill} fillOpacity="0.6" />
        </svg>
    )
}

export default LikeIcon;