import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ActionButton from "components/ActionButton";
import ImageWrapper from "components/ImageWrapper";
import { currencyFormat, titleCase } from "sharedUtils/productUtils";
import { toggleShareModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";



class CategoryProductAssignment extends Component {

    displayCategoryTableHeader = () => {
        let headers = ["Category"];

        return headers.map((header, i) => {
            return <th key={i}>{header}</th>
        })
    }

    displayCategoryTableRows = () => {
        return this.props.categories.map((category, i) => {
            if (category.level === this.props.level && (this.props.level === 1 || category.parentCategoryId === this.props.selectedCategories[this.props.selectedCategories.length - 1].id)) {
                return (
                    <tr key={i}>
                        <td className="text-left">
                            <span><a href="#" onClick={(e) => { e.preventDefault(); this.props.handleProductCategoryTableClick(category); }}>{category.name}</a></span>
                        </td>
                    </tr>
                )
            }
        })
    }

    displayProducts = () => {
        return (
            <InfiniteScroll
                dataLength={this.props.products.length}
                next={this.props.fetchMore}
                hasMore={!this.props.finishedFetchingProducts}
            >
                <colgroup>
                    <col span="1" style={{ width: "24%" }} />
                    <col span="1" style={{ width: "16%" }} />
                    <col span="1" style={{ width: "16%" }} />
                    <col span="1" style={{ width: "16%" }} />
                </colgroup>
                <thead>
                    <tr>
                        {this.displayProductTableHeader()}
                    </tr>
                </thead>
                <tbody>
                    {this.displayProductTableRows()}
                </tbody>
            </InfiniteScroll>
        )
    }

    displayProductTableHeader = () => {
        let headers = ["Product", "Brand", "Merchant", "Retail Price"];

        return headers.map((header, i) => {
            return <th key={i}>{header}</th>
        })
    }

    displayProductTableRows = () => {
        return this.props.products.map((product, i) => {
            const url = "/Product/ProductPage/" + product.shareCode;
            return (
                    <tr key={i}>
                        <td>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <a href={"#"} onClick={(e) => { e.preventDefault(); this.handleProductPageModalClick(product) }} target={product.merchant} style={{ cursor: "pointer" }}>
                                    <ImageWrapper src={product.imageUrl} style={{ objectFit: "contain" }} />
                            </a>
                            <span><a href="#" onClick={(e) => { e.preventDefault(); this.props.handleProductsTableClick(product); }}>{titleCase(this.concatMerchantProduct(product))}</a></span>
                            </div>
                        </td>
                        <td>
                            <span>{product.manufacturer}</span>
                        </td>

                        <td>
                            <span>{product.merchant}</span>
                        </td>

                        <td>
                            <span>{currencyFormat(product.price)}</span>
                        </td>
                    </tr>
            )
        })
    }

    displayBreadcrumbs = () => {
        return this.props.selectedCategories.map((category, i, arr) => {
            if (i === 0) {
                return (
                    <a href="#" key={i} onClick={(e) => { e.preventDefault(); this.props.handleBreadcrumbClick(i); }}>Root Level</a>
                )
            }
            if (i === arr.length - 1) {
                return <span key={i}> &gt; {category.name}</span>;
            }
            return (
                <a href="#" key={i} onClick={(e) => { e.preventDefault(); this.props.handleBreadcrumbClick(i); }}> &gt; {category.name}</a>
            )
        });
    }


    handleProductPageModalClick = (product) => {
        if (!!(product.shareCode)) {
            this.props.toggleProductPageModal(product, this.props.loggedInUser, false, () => null);
        }
    }

    concatMerchantProduct = (product) => {
        if (product.name.toLowerCase().indexOf(product.manufacturer.toLowerCase()) === 0) {
            return product.name.slice(product.manufacturer.length - product.name.length).trim();
        }
        return product.name;
    }

    render() {
        const width = "500px";
        return (
            <>
                <section className="gray-bg pb-3 px-4">
                    <div className="d-flex flex-column align-items-center">
                        <div className="pt-4" style={{ width: width }}>
                            <h4>Selected Product</h4>
                            <h6 style={{ border: "1px solid black", padding: "10px" }}>{!!(this.props.product) ? this.props.product.name : "Select a product..."}</h6>
                        </div>
                        <div className="pt-4" style={{ width: width }}>
                            <h4>Selected Category</h4>
                            <h6>{this.props.selectedCategories.length > 1 ? this.props.selectedCategories[this.props.selectedCategories.length - 1].name : "Select a category..."}</h6>
                            {this.props.product != null && this.props.product.productId > 0 && this.props.selectedCategories[this.props.selectedCategories.length - 1].id > 0 ?
                                <ActionButton className="btn btn-primary mt-3" style={{ width: "95px" }}
                                    onClick={this.props.saveProductCategory}
                                    isLoading={this.props.savingProductCategory}
                                >
                                    Save
                                </ActionButton> :
                                <ActionButton className="btn btn-primary mt-3" isDisabled={true} style={{ width: "95px" }}
                                    onClick={this.props.saveProductCategory}
                                    isLoading={this.props.savingProductCategory}
                                >
                                    Save
                                </ActionButton>
                            }
                        </div>
                    </div>
                </section>

                <div className="row col-12 pb-3">
                    <div className="col-9">&nbsp;</div>
                    <div className="col-3" style={{ paddingLeft: "10px" }}>
                        <span>
                            {this.displayBreadcrumbs()}
                        </span>
                    </div>
                </div>
                <section className="d-flex px-5 pb-5">
                    
                    <div className="table-responsive product-table w-75 mr-1" style={{ overflow: "visible" }}>
                        <table className="table" style={{ border: 0, tableLayout: "fixed" }}>
                            {this.displayProducts()}
                        </table>
                    </div>

                    <div className="table-responsive product-table w-25" style={{ overflow: "visible" }}>
                        <table className="table" style={{ border: 0 }}>
                            <thead>
                                <tr>
                                    {this.displayCategoryTableHeader()}
                                </tr>
                            </thead>
                            <tbody>
                                {this.displayCategoryTableRows()}
                            </tbody>  
                        </table>
                    </div>
                </section>
            </>
        );
    }
}

function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer };
    return result;
}

export default connect(mapStateToProps, { toggleProductPageModal })(withRouter(CategoryProductAssignment));