import React from "react"

/**
 * 
 * @param {{width:Number,height:Number,opacity:Number}} props
 */
const CloseIcon = (props) => {
    return (
        <svg className={`${props.browse ? "close-icon-filter" : ""} close-icon`} style={props.style ?? {}} width={props.width || "10"} height={props.height || "10"} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="close-icon" d="M9.66683 1.27398L8.72683 0.333984L5.00016 4.06065L1.2735 0.333984L0.333496 1.27398L4.06016 5.00065L0.333496 8.72732L1.2735 9.66732L5.00016 5.94065L8.72683 9.66732L9.66683 8.72732L5.94016 5.00065L9.66683 1.27398Z" fill={props.fill ?? "black"} fillOpacity={props.opacity || "0.6"} />
        </svg>

    )
}

export default CloseIcon;