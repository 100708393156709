import React from "react"

const RatingIcon = (props) => {
    return (
        <svg height={props.height || "24"} width={props.width || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: props.cursor ? props.cursor : "pointer" }}>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0003 18.0004L7.9159 20.1477C7.42705 20.4047 6.82242 20.2168 6.56542 19.7279C6.46308 19.5333 6.42777 19.3103 6.46495 19.0935L7.24499 14.5455L3.94066 11.3246C3.54518 10.9391 3.53708 10.306 3.92259 9.9105C4.07609 9.75301 4.27724 9.65052 4.49487 9.6189L9.06135 8.95535L11.1035 4.81742C11.348 4.32217 11.9476 4.11883 12.4428 4.36325C12.6401 4.46058 12.7997 4.62021 12.897 4.81742L14.9392 8.95535L19.5057 9.6189C20.0522 9.69832 20.4309 10.2058 20.3515 10.7523C20.3199 10.9699 20.2174 11.1711 20.0599 11.3246L16.7556 14.5455L17.5356 19.0935C17.629 19.6379 17.2634 20.1548 16.719 20.2482C16.5023 20.2854 16.2793 20.2501 16.0847 20.1477L12.0003 18.0004Z" fill="#FFC107" />
        </svg>
    )
}

export default RatingIcon;