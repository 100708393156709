// brandNavReducer.js

const initialState = {
  brandNavState: false,
};

const SET_BRAND_NAV_STATE = "SET_BRAND_NAV_STATE";

export function setBrandNavState(brandNavState) {
  return { type: SET_BRAND_NAV_STATE, payload: brandNavState };
}

export default function brandNavReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BRAND_NAV_STATE: {
      return { ...state, brandNavState: action.payload };
    }
    default:
      return state;
  }
}
