import * as React from "react";
import AccountSidebar from "../../components/AccountSidebar";
import AccountBanner from "../../components/AccountBanner";

import { Form, FormGroup, Label, Input, PopoverBody } from "reactstrap";
import { getRequest, postRequest } from "../../sharedUtils/httpUtils";

import { WithContext as ReactTags } from 'react-tag-input';
import { Popover, PopoverHeader } from "reactstrap";
import { Helmet } from 'react-helmet';

let readOnlyFields = ["profileUrl"];

let userFormData = {
    displayName: "",
    profileUrl: "",
    profileBannerUrl: "",
    bio: "",
    tagLine: "",
    website: "",
    youtube: "",
    facebook: "",
    instagram: "",
    tikTok: "",
    strava: "",
};

const EditProfile = (props) => {

    const [formData, setFormData] = React.useState(userFormData);
    const [profilePicUrl, setProfilePicUrl] = React.useState("");
    const [profilePic, setProfilePic] = React.useState("");
    const [savingProfile, setSavingProfile] = React.useState(false);
    const [profilePicUpdated, setProfilePicUpdated] = React.useState(false);
    const [uploadingImage, setUploadingImage] = React.useState(false);
    const [showSavedProfile, setShowSavedProfile] = React.useState(false);
    const [profilePicUploadError, setProfilePicUploadError] = React.useState(false);

    let selectedFile = "";

    //const setSelectedFile = async (e) => {
    //    setUploadingImage(true);
    //    //e.preventDefault();
    //    setProfilePic(e.target.files[0]);

    //    const formData = new FormData();
    //    formData.append("File", e.target.files[0]);

    //    let uploadProfilePicUrl = '/api/Account/UploadProfilePic';

    //    let user = await postRequest(uploadProfilePicUrl, formData, null, false);
    //    setProfilePicUrl(user.imageUrl);
    //    setProfilePic("");

    //    setProfilePicUpdated(true);
    //    setUploadingImage(false);

    //    props.setLoggedInUser(user);

    //    setTimeout(() => setProfilePicUpdated(false), 3000);
    //}

    const setSelectedFile = async (e) => {
        e.preventDefault();

        if (e.target.files?.length > 0 && e.target.files[0].type?.startsWith('image/')) {
            const file = e.target.files[0];

            setUploadingImage(true);
            setProfilePic(file);

            const formData = new FormData();
            formData.append('File', file);

            let uploadProfilePicUrl = '/api/Account/UploadProfilePic';

            postRequest(uploadProfilePicUrl, formData, null, false)
                .then((user) => {
                    setProfilePicUrl(user.imageUrl);
                    setProfilePicUpdated(true);
                    props.setLoggedInUser(user);
                    setTimeout(() => setProfilePicUpdated(false), 3000);
                })
                .catch((error) => {
                    console.error("Error uploading profile pic:", error);
                });

            setUploadingImage(false);
            e.target.value = null;
        } else {
            setProfilePicUploadError(true);
        }
    };


    const getData = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        setFormData(convertToFormData(props.loggedInUser));
        setProfilePicUrl(props.loggedInUser.imageUrl || "/images/avatar2.svg");
    };

    React.useEffect(() => { getData() }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const convertToFormData = (user) => {
        return {
            displayName: user.name || "",
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            bio: user.bio || "",
            tagLine: user.tagLine || "",
            website: user.website || "",
            youtube: user.googleUsername || "",
            facebook: user.facebookUsername || "",
            instagram: user.instagramUsername || "",
            tikTok: user.tikTokUsername || "",
            strava: user.stravaUsername || ""
        };
    }

    const convertToUserModel = () => {
        return {
            name: formData.displayName,
            firstName: formData.firstName,
            lastName: formData.lastName,
            bio: formData.bio,
            tagLine: formData.tagLine,
            website: formData.website,
            googleUsername: formData.youtube,
            facebookUsername: formData.facebook,
            instagramUsername: formData.instagram,
            tikTokUsername: formData.tikTok,
            stravaUsername: formData.strava,
            imageUrl: props.loggedInUser.imageUrl,
            userName: props.loggedInUser.userName,
            email: props.loggedInUser.email,

            phoneNumber: props.loggedInUser.phoneNumber,
            proStatusId: props.loggedInUser.proStatusId,
            address: props.loggedInUser.address,
            city: props.loggedInUser.city,
            state: props.loggedInUser.state,
            postalCode: props.loggedInUser.postalCode,
            country: props.loggedInUser.country,

            onboardingComplete: props.loggedInUser.onboardingComplete,
            profileBannerUrl: props.loggedInUser.profileBannerUrl,

            quickStart: props.loggedInUser.quickStart
        }
    }

    const onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        if (formKey === 'bio') {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }

        setFormData({ ...formData, [formKey]: newData });

    };

    const onClickImageUpload = (event) => {
        event.preventDefault();
        document.getElementById("profiePicInput").click();
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setSavingProfile(true);

        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, convertToUserModel());
        props.setLoggedInUser(user);
        setShowSavedProfile(true);

        setTimeout(() => { setSavingProfile(false); setShowSavedProfile(false) }, 2000);
        
    };

    const mapInputFields = (fields) => {
        return fields.map((field, i) => {
            if (readOnlyFields.includes(field.name) || uploadingImage) {
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => onInputChange(e)}
                            value={formData[field.name]}
                            readOnly="readonly"
                        />
                    </FormGroup>
                );
            } else {
                if (field.name === "bio") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <textarea
                                className="form-control"
                                name={field.name}
                                onChange={(e) => onInputChange(e)}
                                value={formData[field.name]}
                                style={{overflow: "hidden"}}
                            />
                        </FormGroup>);
                }
                if (field.prepend) {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{field.prepend}</span>
                                </div>
                                <Input
                                    className="form-control"
                                    name={field.name}
                                    onChange={(e) => onInputChange(e)}
                                    value={formData[field.name]}
                                    maxLength={field.name === "displayName" ? 20 : undefined}
                                    />
                            </div>
                        </FormGroup>
                    );
                }
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => onInputChange(e)}
                            value={formData[field.name]}
                            maxLength={field.name === "displayName" ? 20 : undefined}
                        />
                    </FormGroup>
                );
            }
        });
    }

    const mapInputFieldsSocial = () => {
        let fields = [
            { name: "instagram", title: "Instagram Username", prepend: 'www.instagram.com/' },
            { name: "facebook", title: "Facebook Username", prepend: 'www.facebook.com/' },
            { name: "youtube", title: "YouTube Channel", prepend: 'www.youtube.com/' },
            { name: "tikTok", title: "TikTok Username", prepend: 'www.tiktok.com/@' },
            { name: "strava", title: "Strava Username", prepend: 'www.strava.com/athletes/' },
        ];
        return mapInputFields(fields);
    };

    const mapInputFieldsPublic = () => {
        let fields = [
            { name: "displayName", title: "Name" },
            //{ name: "profileUrl", title: "Profile Url" },
            { name: "tagLine", title: "Tag Line" },
            { name: "bio", title: "Bio" },
            { name: "website", title: "Website",prepend:"https://" }
        ];
        return mapInputFields(fields);
    };

    const renderSubmit = () => {
        let submitDisabled = true;

        for (let key in formData) {
            if (formData[key] !== null && formData[key] !== "") {
                submitDisabled = false;
                break;
            }
        }

        if (profilePic !== "") {
            submitDisabled = false;
        }

        if (submitDisabled) {
            return (
                <button type="submit" className="btn btn-secondary mt-3" id="submitProfileChanges" disabled onClick={(e) => { onSubmit(e); }}>
                    {savingProfile ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        : ""} Submit
                </button>
            );
        }

        return (
            <button type="submit" id="submitProfileChanges" className="btn btn-secondary mt-3" onClick={(e) => { onSubmit(e); }}>
                {savingProfile ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : ""} Submit
            </button>
        );
    }

    const breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/EditProfile', text: 'Edit Profile', active: true }];
    return (
        <section>

            <Helmet>
                <title>RockPorch - Edit Profile</title>
            </Helmet>
            <AccountBanner breadCrumbList={breadCrumbList}>
                <h1 className="mb-4">Edit Profile</h1>
                <p className="mb-0">Manage what people see about you</p>
            </AccountBanner>

            <div className="gray-bg full-height">
                <div className="container">
                    <div className="row py-5 mt-0">
                        {
                            ["sm", "md"].includes(props.deviceSize) === false ? (
                                <div className="col-md-5 col-lg-3 side-nav">
                                    <AccountSidebar loggedInUser={props.loggedInUser} setLoggedInUser={props.setLoggedInUser} />
                                </div>
                            ): <span></span>
                        }
                        

                        <div className="col-md-12 col-lg-9">
                            <div className="user-detail-form">
                                <Form>
                                    <div className="detail-block">
                                        <h5>Public Profile</h5>

                                        <div className="form-group upload-image">
                                            <div className="avatar-upload">

                                                <div className="avatar-edit">
                                                    <button
                                                        className="btn btn-secondary"
                                                        onClick={(e) => { onClickImageUpload(e) }}
                                                        id="changeProfilePic"
                                                    >
                                                        {uploadingImage ?
                                                            <div className="spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : "Change"}
                                                    </button>
                                                    <Popover trigger="click" toggle={() => setProfilePicUploadError(false)} placement="left" isOpen={profilePicUploadError} target="changeProfilePic">
                                                        <PopoverBody>
                                                            Make sure to select a single image file
                                                        </PopoverBody>
                                                    </Popover>
                                                    <Popover
                                                        placement="right"
                                                        target="changeProfilePic"
                                                        isOpen={profilePicUpdated}
                                                        toggle={() => setProfilePicUpdated(false)}
                                                    >
                                                        <PopoverHeader>Profile Picture Updated</PopoverHeader>
                                                        <PopoverBody>
                                                            <div style={{ cursor: "pointer" }} onClick={async (e) => {
                                                                props.history.push(`/Profile/${props.loggedInUser.userName}`);
                                                            }}>
                                                            <small>Go to profile</small></div>
                                                            <div style={{ cursor: "pointer" }} onClick={e => {
                                                                this.setDefaultProfilePicture();
                                                            }}>
                                                            </div>
                                                        </PopoverBody>

                                                    </Popover>
                                                </div>

                                                <div className="avatar-preview">
                                                    {profilePic && (
                                                        <div id="imagePreview" style={{ backgroundImage: 'url(' + URL.createObjectURL(profilePic) + ')' }}>
                                                        </div>
                                                    )
                                                    }

                                                    {!profilePic && (
                                                        <div id="imagePreview" style={{ backgroundImage: 'url(' + profilePicUrl + ')' }}>
                                                        </div>
                                                    )
                                                    }

                                                </div>

                                                <input
                                                    hidden={true}
                                                    id="profiePicInput"
                                                    type="file"
                                                    accept="image/*"
                                                    value={selectedFile}
                                                    onChange={(e) => { setSelectedFile(e); }}
                                                />
                                            </div>
                                        </div>
                                        {mapInputFieldsPublic()}

                                        <h5>Social Profiles</h5>
                                        {mapInputFieldsSocial()}

                                        {renderSubmit()}

                                        <Popover
                                            placement="right"
                                            target="submitProfileChanges"
                                            isOpen={showSavedProfile}
                                            toggle={() => setSavingProfile(false)}
                                        >
                                            <PopoverBody>Profile changes saved</PopoverBody>
                                        </Popover>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditProfile;
