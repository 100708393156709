import React from "react";
import LoadSpinner from "components/LoadSpinner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import MessageReply from "./MessageReply";
import { getMinutesBetweenDates, getDaysBetweenDates, convertToNameOfWeekdayAndTime, convertToAbbreviatedMonthAndDayAndTime } from "../../sharedUtils/timeUtils"
import UserTileGeneral from "components/UserTileGeneral";

/**
 *
 * @param {{
 * user: object,
 * conversationId: int,
 * onClickSend: function,
 * onClickBack: function
 * }} props
 */

class MessageDetails extends React.Component {

    state = {
        sender: {},
        newMessage: "",
        messageDetails: [],
        loading: true,
        initialLoad: true,
        replyStyle: {}
    }

    inputRef = React.createRef();
    sendButtonRef = React.createRef();

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount = async () => {
        if (this.props.user) {
            this.setState({ sender: this.props.user });
        }

        this.refreshState();

        // Poll for new messages every 10 seconds since we don't have a websocket connection
        this.timer = setInterval(() => { this.refreshState() }, 10 * 1000);
    }

    refreshState = async () => {
        let messageDetailsUrl = '';

        if (this.props.conversationId) {
            messageDetailsUrl = '/api/Account/GetMessages?conversationId=' + this.props.conversationId;
        } else {
            messageDetailsUrl = '/api/Account/GetConversation?userId=' + this.props.user.id;
        }

        let messageDetails = await getRequest(messageDetailsUrl) ?? [];

        if (this.state.initialLoad || this.state.messageDetails.length !== messageDetails.length) {
            this.setState({ messageDetails: messageDetails, loading: false, initialLoad: false });

            this.inputRef.current.scrollIntoView();
            this.inputRef.current.focus();
        }
    }

    handleMessageInput = (e) => {
        this.setState({ newMessage: e.target.value });

        /* Auto-resize textarea */
        if (this.inputRef) {
            try {
                this.inputRef.current.style.height = "100px";
                this.inputRef.current.style.height = this.inputRef.current.scrollHeight + "px";
            }
            catch (err) {
                console.log(err);
            }
        }
    }

    onKeyDownMessageInput = async (e) => {
        if (this.state.newMessage.length === 0)
            return;

        /* Add Ctrl+Enter or Command+Enter hot key to trigger Send button */
        if ((e.keyCode === 10 || e.keyCode === 13) && e.ctrlKey) {
            this.sendButtonRef.current.click();
        }
    }

    onClickSend = async (e) => {
        e.preventDefault();

        let newMessage = this.state.newMessage;
        this.setState({ newMessage: "" });

        let messageDetails = this.state.messageDetails;
        let messagesUrl = '/api/Account/SendMessage';
        let message = await postRequest(messagesUrl,
            {
                conversationId: this.state.messageDetails.length > 0 ? this.state.messageDetails[0].conversationId : null,
                receiverId: this.state.sender.id,
                text: newMessage
            });
        messageDetails.push(message);

        if (this.props.onClickSend) {
            this.props.onClickSend(message);
        }

        this.setState({ newMessage: "", messageDetails: messageDetails });
    }

    renderMessageThread = () => {
        return this.state.messageDetails.map((message, i) => {
            let showDate = true;
            let paddingClass = "py-3";

            let formattedDate = null;
            let daysBetweenDates = getDaysBetweenDates(message.createdDate, new Date());
            if (daysBetweenDates <= 7) {
                formattedDate = convertToNameOfWeekdayAndTime(message.createdDate);
            } else {
                formattedDate = convertToAbbreviatedMonthAndDayAndTime(message.createdDate);
            }

            if (i > 0) {
                let lastMessage = this.state.messageDetails[i - 1];
                let minutesBetweenDates = getMinutesBetweenDates(lastMessage.createdDate, message.createdDate);
                if (minutesBetweenDates <= 10) {
                    showDate = false;
                }
            }

            if (message.sender.id === this.props.loggedInUser.id) {
                if (this.state.messageDetails.length > i + 1) {
                    let nextMessage = this.state.messageDetails[i + 1];
                    if (nextMessage.sender.id === message.sender.id) {
                        paddingClass = "py-1";
                    }
                }

                return <MessageReply key={i} message={message} formattedDate={showDate ? formattedDate : null} paddingClass={paddingClass} />
            }

            return (
                <div key={i} className={`message-reply-block d-flex flex-wrap align-items-center ${paddingClass}`}>
                    {
                        showDate ?
                            <div className="col-12 text-left mb-2" style={{ paddingLeft: "71px" }}>
                                <span className="date">{formattedDate}</span>
                            </div> :
                            <></>
                    }

                    <div className="col-11">
                        <div className="user-profile user-tile-sm">
                            {
                                message.sender.imageUrl ?
                                    <img style={{ marginRight: "16px" }} src={message.sender.imageUrl} alt="Avatar" className="avatar" /> :
                                    <img src="/images/avatar2.svg" className="avatar" alt="Avatar"/>
                            }
                            <div className="message-from">{message.text}</div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    render = () => {
        if (this.state.loading === true) {
            return <LoadSpinner />
        }

        return (
            <div className="container px-0 py-lg-5 py-md-3">
                <div className="row messges-details mx-0 my-0">
                    <div className="col-12">
                        <div className="message-detail-top clearfix pb-3 text-md-left">
                            <button onClick={() => this.props.onClickBack()} className="back-btn d-flex align-items-center justify-content-center mb-3 mb-md-4"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3332 7.33329H5.21984L8.9465 3.60663L7.99984 2.66663L2.6665 7.99996L7.99984 13.3333L8.93984 12.3933L5.21984 8.66663H13.3332V7.33329Z" fill="black" fillOpacity="0.54" />
                            </svg> Back</button>
                            
                            <div className="">
                                <UserTileGeneral user={this.state.sender} subtitle={" "} showCheckMark={false} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-0 p-md-3">
                        {this.renderMessageThread()}
                        <div className="reply-box pt-3">
                            <form action="">
                                <div className="form-group">
                                    <textarea ref={this.inputRef} className="form-control" style={{resize: "none", overflow: "hidden", minHeight: "100px"}} onChange={(e) => this.handleMessageInput(e)} onKeyDown={(e) => this.onKeyDownMessageInput(e)} rows="3" value={this.state.newMessage} placeholder="Write your message..."></textarea>
                                </div>
                                {this.state.newMessage.length > 0 ?
                                    <button ref={this.sendButtonRef} className="btn btn-primary w-100" onClick={(e) => this.onClickSend(e)}>Send</button>
                                    : <button style={{ pointerEvents: "none" }} type="submit" className="btn btn-primary w-100 disabled">Send</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MessageDetails;