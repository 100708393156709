import React, { Component } from "react";
import Select from 'react-select';
import ActionButton from "components/ActionButton";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadSpinner from "components/LoadSpinner";
import CloseIcon from "components/icons/CloseIcon";


class BrandNames extends Component {

    displayTableHeader = (noButtons = false) => {
        let headers = ["Brand Name", "", ""];

        return headers.map((header, i) => {
            const borders = noButtons && i === 0 ? "border-right-0" : noButtons && i === 1 ? "border-left-0 border-right-0" : noButtons ? "border-left-0" : "";
            return <th key={i} className={`pl-5 ${borders}`} >{header}</th>
        })
    }

    displayDetailsTable = () => {
        if (this.props.selectedBrandName?.length > 0) {
            return (
                <div className="table-responsive product-table mx-auto mb-3" style={{ overflow: "scroll", maxWidth: "90%", maxHeight: "300px" }}>
                    <table className="table" style={{ border: 0, maxWidth: "350px", margin: "0 auto" }}>
                        <tbody>
                            <tr>
                                <td style={{ border: 0, padding: 0 }}>
                                    <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>MERCHANT:</h6>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>AFFILIATE:</h6>
                                </td>
                            </tr>
                            {this.displayBrandDetailRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    displayTableRows = (tableType) => {
        return this.props.brands?.map((brand, i) => {
            if (this.props.brands?.length > 0) {
                return (
                    <tr key={i} style={{ lineHeight: "50px" }}>
                        {
                            tableType === "new" ?
                                <>
                                    <td className="pl-5 text-left" style={{ maxWidth: "250px", borderRight: 0 }}>
                                        <span><a href="#" onClick={(e) => { e.preventDefault(); this.props.handleBrandClick(brand.id, tableType) }}>{brand.name === "" ? "[BLANK NAME]" : brand.name}</a></span>
                                        <span style={{ color: "#e55934", marginLeft: "3px", fontSize: "12px" }}>
                                            ({brand.productCount})
                                        </span>
                                    </td>
                                    <td style={{ borderLeft: 0, borderRight: 0 }}>
                                            <ActionButton className="btn btn-secondary mt-3" isDisabled={true} style={{ width: "75px", height: "35px", fontSize: "12px", backgroundColor: "transparent", color: "transparent", border: "none" }}
                                            onClick={() => this.props.handleBrandNamesClick(brand.name, brand.id)}
                                        >
                                            Affiliates
                                        </ActionButton>
                                    </td>
                                    <td style={{ borderLeft: 0 }}>
                                        <ActionButton className="btn btn-secondary mt-3" isDisabled={true} style={{ width: "75px", height: "35px", fontSize: "12px", backgroundColor: "transparent", color: "transparent", border: "none" }}
                                            onClick={() => this.props.handleBrandProductsClick(brand.name, brand.id)}
                                        >
                                            ProductUrls
                                        </ActionButton>
                                    </td>
                                </>:
                                <>
                                    <td className="pl-5 text-left" style={{ maxWidth: "250px"}}>
                                        <span><a href="#" onClick={(e) => { e.preventDefault(); this.props.handleBrandClick(brand.id, tableType) }}>{brand.name === "" ? "[BLANK NAME]" : brand.name}</a></span>
                                        <span style={{ color: "#e55934", marginLeft: "3px", fontSize: "12px" }}>
                                            ({brand.productCount})
                                        </span>
                                    </td>
                                    <td>
                                        <ActionButton className="btn btn-secondary mt-3" style={{ width: "75px", height: "35px", fontSize: "12px" }}
                                            onClick={() => this.props.handleBrandNamesClick(brand.name, brand.id)}
                                        >
                                            Affiliates
                                        </ActionButton>
                                    </td>
                                    <td>
                                        <ActionButton className="btn btn-secondary mt-3" style={{ width: "88px", height: "35px", fontSize: "12px" }}
                                            onClick={() => this.props.handleBrandProductsClick(brand.name, brand.id)}
                                        >
                                            ProductUrls
                                        </ActionButton>
                                    </td>
                                </>
                            }
                    </tr>
                )
            }
        })
    }

    displayBrandDetailRows = () => {
        return this.props.selectedBrandName?.map((m, i) => {
            return (
                <tr key={i}>
                    <td style={{ border: 0, padding: 0 }}>
                        <h6>{m.merchantName}</h6>
                    </td>
                    <td style={{ border: 0, padding: 0 }}>
                        <h6>{m.affiliateProgramManager}</h6>
                    </td>
                </tr>
            )
        });
    }

    infiniteScrollTableRender = () => {
        const width = "500px";

        return (
            <InfiniteScroll
                dataLength={this.props.brands.length}
                next={this.props.fetchMore}
                hasMore={!this.props.finishedFetchingBrands}
                style={{ padding: 0 }}
            >
                <div className="table-responsive product-table mx-auto mt-3" style={{ overflow: "visible", maxWidth: width }}>
                    <table className="table" style={{ border: 0 }}>
                        <thead>
                            <tr>
                                <td className="border-0 pl-5">
                                    <span>
                                        Original Brand
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                {this.displayTableHeader(true)}
                            </tr>
                        </thead>
                        <tbody>
                            {this.displayTableRows("original")}
                        </tbody>
                    </table>
                </div>
            </InfiniteScroll>
        );
    }


    render() {
        const width = "500px";
        return (
            <>
                <section className="d-flex flex-column align-items-center gray-bg pb-3 px-4" style={{ position: "sticky", top: "60px" }}>
                    <div className="d-flex flex-column pt-4" style={{ minWidth: "300px" }}>
                        <h4 className="mb-4">Re-Associate Products to New Brand</h4>
                        <h6 className="mb-4">Original Brand: {this.props.selectedOriginalBrand[0].id !== 0 && this.props.selectedOriginalBrand[0].name === "" ? "[Blank Name]" : this.props.selectedOriginalBrand[0].name}</h6>
                        <h6 className="mb-4">New Brand: {this.props.selectedNewBrand[0].id !== 0 && this.props.selectedNewBrand[0].name === "" ? "[Blank Name]" : this.props.selectedNewBrand[0].name}</h6>
                        <div>
                            {this.props.selectedOriginalBrand[0].id > 0 && this.props.selectedNewBrand[0].id > 0 && this.props.selectedOriginalBrand[0].id !== this.props.selectedNewBrand[0].id ?
                                <ActionButton className="btn btn-primary mt-3" style={{ width: "95px" }}
                                    onClick={this.props.saveBrandAssociations}
                                    isLoading={this.props.savingBrandAssociations}
                                >
                                    Save
                                </ActionButton> :
                                <ActionButton className="btn btn-primary mt-3" isDisabled={true} style={{ width: "95px" }}
                                    onClick={this.props.saveBrandAssociations}
                                    isLoading={this.props.savingBrandAssociations}
                                >
                                    Save
                                </ActionButton>
                            }
                            <button className="btn btn-secondary mt-3 ml-3" onClick={this.props.brandsReset}>
                                Reset
                            </button>
                        </div>
                    </div>
                </section>


                {
                    this.props.brandsLoading ?
                        <LoadSpinner /> :
                        <>
                            <section style={{ position: "sticky", top: "293px", background: "white", paddingBottom: "20px" }}>
                                <div className={`search-interest pt-4 mx-auto position-relative`}>
                                    <div className="search-block">
                                        <div className={`form-group position-relative ${["sm", "md"].includes(this.props.deviceSize) ? "mx-auto" : "mx-auto mb-3"}`}>
                                            <input type="search" id="gsearch" name="gsearch" placeholder={`Search Brands...`} value={this.props.search} onChange={this.props.handleSearch} onKeyDown={(e) => { if (e.keyCode === 13) this.props.searchBrands(); }} />
                                            <input type="submit" className="position-absolute" onClick={(e) => { e.preventDefault(); this.props.searchBrands() }} />
                                            <button className="close browse-close mr-2 position-absolute" style={{ right: "3%", bottom: "15px" }} onClick={this.props.resetSearch} >
                                                <CloseIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="d-flex px-5 pb-5" style={{ marginTop: "-20px" }}>
                                {this.infiniteScrollTableRender()}

                                <div className="table-responsive product-table mx-auto mt-3" style={{ overflow: "visible", maxWidth: width }}>
                                    <table className="table" style={{ border: 0 }}>
                                        <thead>
                                            <tr>
                                                <td className="border-0 pl-5">
                                                    <span>
                                                        New Brand
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                {this.displayTableHeader(true)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.displayTableRows("new")}
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                        </>
                }
            </>
        );
    }
}

export default BrandNames;