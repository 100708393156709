import React, { Component } from 'react';
import { getRequest } from "../sharedUtils/httpUtils";

class ChatBot extends Component {
    constructor(props) {
        super(props);
        this.lastMessageRef = React.createRef();
        this.messageContainerRef = React.createRef();
        this.textareaRef = React.createRef();

        this.messageContainerRef = React.createRef();
        this.observer = null; 
    }

    state = {
        messages: [],
        inputText: '',
        isSubmitting: false,
        scrollToBottomOnTyping: true,
        scrollPosition: 0,
        currentIndex: 0,
    };

    componentDidMount = () => {
        this.props.setShowTopNav(false);
        this.addBotMessage('Hi and welcome to the RockPorch ChatBot!\n\nAsk me a question below about any topic you like!\n\nFeel free to ask about product recommendations related to your interests.');
        this.observer = new MutationObserver(this.handleMutation);
        const config = { attributes: true, childList: true, subtree: true };
        this.observer.observe(this.messageContainerRef.current, config);
    }

    componentDidUpdate = () =>  {
        if (this.state.scrollToBottomOnTyping) {
            this.scrollToLastMessage();
        }

        this.adjustTextareaHeight();
    }

    componentWillUnmount = () => {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    handleScroll = (e) => {
        const messageContainer = e.target;
        const { scrollTop, scrollHeight, clientHeight } = messageContainer;

        // Check if the user has scrolled to the bottom (with a small threshold)
        const atBottom = scrollTop + clientHeight + 5 >= scrollHeight;

        // Check if the user is actively scrolling (not just the page being programmatically scrolled)
        if (scrollTop !== this.state.scrollPosition) {
            const upwardScroll = scrollTop < this.state.scrollPosition;

            // Update the scrollToBottomOnTyping state based on the scroll behavior
            this.setState({ scrollPosition: scrollTop, scrollToBottomOnTyping: atBottom && !upwardScroll });
        }
    };


    handleMutation = (mutationsList, observer) => {
        // Iterate through the mutations
        for (const mutation of mutationsList) {
            // Check if the height of the container has changed
            if (mutation.type === 'childList' || mutation.type === 'attributes') {
                const messageContainer = this.messageContainerRef.current;
                const newDivHeight = messageContainer.getBoundingClientRect().height;
                if (newDivHeight !== this.lastDivHeight) {
                    this.lastDivHeight = newDivHeight;
                    if (this.state.scrollToBottomOnTyping) {
                        this.scrollToLastMessage();
                    }
                }
            }
        }
    };

    formatBotMessage = (message) => {
        let formattedMessage = message;

        // Check for anchor tags and replace them with appropriate formatting
        const anchorRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g;
        formattedMessage = formattedMessage.replace(anchorRegex, '<a href="$1">$2</a>');

        // Check for image tags and replace them with the image element
        const imageRegex = /<img\s+src="([^"]*)"\s+style="([^"]*)"\s+\/>/g;
        formattedMessage = formattedMessage.replace(imageRegex, '<img src="$1" style="$2" />');
        //console.log(formattedMessage);
        return (
            <pre
                style={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}
                dangerouslySetInnerHTML={{ __html: formattedMessage }}
            />
        );
    };


    addBotMessage = (message) => {
        var content = this.formatBotMessage(message);
        this.setState((prevState) => ({
            messages: [...prevState.messages, { type: 'bot', content }],
        }));
    };

    scrollToLastMessage = () => {
        this.lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    scrollToInput = () => {
        const messageContainer = this.messageContainerRef.current;
        messageContainer.scrollTop = messageContainer.scrollHeight;
    };

    handleInputChange = (e) => {
        this.setState({ inputText: e.target.value }, this.adjustTextareaHeight);
    };

    adjustTextareaHeight = () => {
        const textarea = this.textareaRef.current;

        // Reset the height first
        textarea.style.height = 'auto';
        // Set the height based on content
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    handleSendMessage = async () => {
        const { inputText, messages } = this.state;

        if (inputText.trim() !== '') {
            this.setState({
                messages: [
                    ...messages,
                    { type: 'user', content: this.formatBotMessage(inputText) },
                ],
                isSubmitting: true,
                inputText: '',
                scrollToBottomOnTyping: true,
            });

            const url = "search/GetChatGPTProductUrls?searchText=" + this.state.inputText + "&referrer=" + document.referrer;
            const response = await getRequest(url);

            if (!!response && !!response.textResponse) {
                let textResponse = response.textResponse;
                if (response.referralLinkReplacements !== undefined && response.referralLinkReplacements !== null && response.referralLinkReplacements.length > 0) {
                    // Replace occurrences of the replacementCode with the title and image in the textResponse
                    response.referralLinkReplacements.forEach((replacement) => {

                        const regex = new RegExp(replacement.replacementCode, 'g');
                        let url = null;
                        if (!!replacement.shareCode) {
                            url = "/prd/" + replacement.shareCode + "?browse=true";
                        }
                        const anchorTag = !!url
                            ? `<a href=${url} target="_blank">${replacement.title}</a>`
                            : replacement.title;

                        // Check if there's a shareCode and imageUrl and add the image element within the anchor tag
                        if (!!replacement.shareCode && !!replacement.imageUrl) {
                            const imgElement = `<img src="${replacement.imageUrl}" class="mr-2" style="max-width: 100px; max-height: 100px; border-radius: 10px; object-fit: scale-down; border: 1px solid black; display: inline-block; vertical-align: bottom;" />`;
                            textResponse = textResponse.replace(regex, `<a href="${url}" target="_blank">${imgElement}${anchorTag}</a>`);

                        } else {
                            textResponse = textResponse.replace(regex, anchorTag);
                        }
                    });
                }
                const content = this.formatBotMessage(textResponse);
                this.setState(
                    (prevState) => ({
                        response,
                        messages: [
                            ...prevState.messages,
                            { type: 'bot', content },
                        ],
                        isSubmitting: false
                    }));
                this.textareaRef.current.focus();
            } else {
                this.setErrorMessage();
                this.textareaRef.current.focus();
            }
        };
    };


    renderBotMessage = (messageHtml) => {
        const formattedString = messageHtml.props.dangerouslySetInnerHTML.__html;
        const regex = /(<a[^>]*>[\s\S]*?<\/a>|<[^>]+>|[^<]+)/g;
        const elements = formattedString.split(regex).filter((el) => el.trim() !== '');

        const content = elements.map((element, index) => {
            if (element.startsWith('<')) {
                // If it's an HTML element, push it directly into the content array
                return <span key={index} dangerouslySetInnerHTML={{ __html: element }} />;
            } else {
                // If it's a pure text, push it into the content array as a ReactTyped element
                return (
                    <React.Fragment>{element}</React.Fragment>
                );
            }
        });

        return <pre style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>{content}</pre>;
    };

    setErrorMessage = () => {
        const content = this.formatBotMessage("AN ERROR OCCURRED. Please resubmit your question");
        this.setState({
            messages: [
                ...this.state.messages,
                { type: 'bot', content },
            ],
            isSubmitting: false,
        });
    }

    render() {
        const { messages, inputText } = this.state;

        return (
            <div className="chat-container d-flex justify-content-end">
                <div ref={this.messageContainerRef} className="chat-message-container" onScroll={this.handleScroll}>
                    {messages.map((message, index) => (
                        <div key={index} className={`chat-message chat-${message.type}`} ref={this.messageContainerRef}>
                            {/* Check if it's the last bot message and apply the typing effect IF TYPING CODE ADDED BACK IN  */}
                            {message.type === 'bot' && index === messages.length - 1 ? (
                                this.renderBotMessage(message.content)
                            ) : (
                                message.content
                            )}
                        </div>
                    ))}
                    {this.state.isSubmitting && (
                        <div className="chat-bot-dots-container">
                            <div className="chat-bot-dot" />
                            <div className="chat-bot-dot" />
                            <div className="chat-bot-dot" />
                        </div>
                    )}
                    <div ref={this.lastMessageRef} />
                </div>
                <div className="chat-input-container">
                    <textarea
                        ref={this.textareaRef}
                        className="chat-input"
                        rows="1"
                        value={inputText}
                        placeholder="Type your message..."
                        onChange={this.handleInputChange}
                    />
                    <button className={`chat-button ${this.state.isSubmitting ? "disabled" : ""}`} onClick={this.handleSendMessage}>
                        Send
                    </button>
                </div>
            </div>
        );
    }

}   

export default ChatBot;
