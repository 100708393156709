import React, { useState } from "react";
import ImageWrapper from "components/ImageWrapper";
import ClickBurstIcon from "components/icons/ClickBurstIcon";
import toast from 'react-hot-toast';
import { currencyFormat } from "sharedUtils/productUtils";

/**
 * 
 * @param {{product:object,edit:Function, toggleProductModal:Function, toggleShareModal:Function}} props
 */
const MerchantRowMobile = (props) => {
    let [isLoadingShareCode, setIsLoadingShareCode] = useState(false);
    let { merchant } = props;
    let pstyle = { fontFamily: "Archivo", fontSize: "14px", width: "96%" }

    const getLinkError = () => {
        toast.error(
            <div className="p-2">
                <h4 className="text-center">Merchant currently not integrated</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    const handleGetLink = async () => {
        if (!merchant.integrated) {
            getLinkError();
            return;
        }

        let getLinkUrl = "https://";
        if (window.location.host.includes("app.rockp") && !!merchant.shareSubdomain) {
            getLinkUrl += merchant.shareSubdomain + ".rockporch.com";
        } else {
            getLinkUrl += window.location.host;
        }

        getLinkUrl = getLinkUrl + "/mlink/" + merchant.merchantId + "?userId=" + merchant.userId;
        props.handleGetLink(getLinkUrl);
    }

    const fixedWidthStyle = { width: "80px", textAlign: "center", fontFamily: "Archivo", fontSize: "12px", position: "relative" }; // Fixed width style

    const clicksZero = merchant.clicks === 0;
    const salesZero = merchant.sales === 0;

    return (
        <div className={`card p-1 ${props.analytics ? "border-0 pt-2" : ""} ${merchant.clicks === "clicks" ? "" : "hover-border"}`}>
            {
                merchant.clicks === "clicks" ?
                    <div className="d-flex justify-content-between align-items-center px-1">
                        <p className="ml-1 mb-0" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 1, label: "Merchant" })}>
                            <strong>Brand</strong>
                            <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: props.sortDescending ? "-4px" : "9px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Merchant" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                        </p>

                        <div style={{ flex: 1, marginLeft: "5px" }}>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "none", top: "1px" }} >
                                filler
                            </p>

                            <p className="ml-1 mb-0" style={{ ...pstyle, display: "none" }} >
                                <strong>Price</strong>: <span>0</span>
                            </p>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "none" }} >
                                <strong>Merchant</strong>: filler
                            </p>
                        </div>

                        <div className={`d-flex mr-3`} style={{ ...fixedWidthStyle, width: "40", left: "-22px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 2, label: "Clicks" })}>
                            <div><ClickBurstIcon /> </div>
                            <div style={{ position: "relative", right: "7px" }}><span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ fontSize: "16px", top: props.sortDescending ? "-2px" : "11px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Clicks" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                            </div>
                        </div>

                        <div style={{ ...fixedWidthStyle, textAlign: "revert", left: "2px", top: "7px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 3, label: "Sales" })}>
                            <p><strong>$</strong><span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: props.sortDescending ? "-4px" : "9px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Sales" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                            </p>
                        </div>

                        <div style={{ ...fixedWidthStyle, width: "38px", left: "-11px", top: "7px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 4, label: "Conversion Rate" })}>
                            <p><strong>%</strong><span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: props.sortDescending ? "-4px" : "9px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Conversion Rate" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                            </p>
                        </div>

                    </div>
                    :
                    <div className="d-flex justify-content-between align-items-center px-1" style={{ cursor: "pointer" }} onClick={handleGetLink}>
                        <ImageWrapper
                            className="mr-1"
                            src={merchant.logoUrl}
                            style={{ width: props.deviceSize === "sm" ? "50px" : "80px", height: props.deviceSize === "sm" ? "50px" : "80px", objectFit: "contain" }}
                        />

                        <div style={{ flex: 1, marginLeft: "5px", minWidth: "150px" }}>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "relative", top: "1px", wordBreak: "break-all" }}>
                                {merchant.merchant}
                            </p>

                            <p className="ml-1 mb-0" style={{ ...pstyle, display: !!(merchant.salesAmount) ? "" : "none" }} >
                                <strong>Sales</strong>: <span>${merchant.salesAmount.toFixed(2)}</span>
                            </p>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: !!(merchant.commission) ? "" : "none" }} >
                                <strong>Comm.</strong>: <span>${merchant.commission.toFixed(2)}</span>
                            </p>
                        </div>


                        <div className={`d-flex mr-3 ${props.analytics ? "" : "flex-column"} brand-primary-color`} onClick={(e) => props.handleClicksClick(e, merchant, clicksZero)} style={{ ...fixedWidthStyle, width: "43px", top: "6px" }} >
                            <p className={`${clicksZero ? "" : "brand-primary-color"}`} style={{ fontSize: "14px" }}>{merchant.clicks}</p>
                        </div>

                        <div className={`d-flex mr-3 ${props.analytics ? "" : "flex-column"}`} onClick={(e) => props.handleSalesClicksClick(e, merchant, salesZero)} style={{ ...fixedWidthStyle, top: "6px" }} >
                            <p className={`${salesZero ? "" : "brand-primary-color"}`} style={{ fontSize: "14px" }}>{merchant.sales}</p>
                        </div>

                        <div className={`d-flex mr-3 ${props.analytics ? "" : "flex-column"}`} style={{ ...fixedWidthStyle, width: "38px", top: "6px" }} >
                            <p style={{ fontSize: "14px" }}>{merchant.conversionRate}%</p>
                        </div>

                    </div>
            }
        </div>
    )
}

export default MerchantRowMobile;
