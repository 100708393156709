import React from "react";
import { Link } from "react-router-dom";
import FeedPost from "components/Feed/FeedPost";
import ProductPost from "./BrowseProductPost";
import PostPageProduct from "./BrowsePostPageProduct";
import FeedThreeColumn from "components/Feed/FeedThreeColumn";
import FeedSingleColumn from "components/Feed/FeedSingleColumn";
import { postRequest } from "sharedUtils/httpUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import InformationIcon from "../../components/icons/InformationIcon";
import { toggleShareModal, toggleProductPageModal, toggleFollowersFollowingModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import ShareIosIcon from "../../components/icons/ShareIosIcon";
import { getProductShareLink } from "../../sharedUtils/shareLinkPrefixes"
import BookmarkIcon from "../../components/icons/BookmarkIcon";
import PlusIcon from "../../components/icons/PlusIcon";
import Tick from "../../components/icons/Tick";
import ThumbsUpIcon from "../../components/icons/ThumbsUpGeneric";
import SaleIcon from "../../components/icons/SaleIcon";

import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import StarRatingComponent from 'react-star-rating-component';

import Modal from "components/Modal";
import ProductReviewUsersList from "components/ProductReviewUsers/ProductReviewUsersList";


/**
 * 
 * @param {{
 * products:[
 *  {
 *      description:string, 
 *      imageUrl:string, 
 *      likedByUser:boolean, 
 *      likes:Number, 
 *      merchantProductId:Number,
 *      name:string,
 *      price:Number,
 *      rating:Number,
 *      ratingCount:Number,
 *      savedByUser:boolean,
 *      shareCode:string,
 *      url:string,
 *      userDescription:string,
 *      userMerchantProductId: Number
 *   }
 * ], 
 * isGrid:boolean,
 * finishedFetching:Function,
 * fetchMore:Function
 * }} props
 */
class BrowseProductFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            showUserReviewList: false,
            ratingsUserMerchantProductId: 0,
        }
    }

    componentDidMount() {
        this.setState({ products: this.props.products });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.products != undefined && this.props.products != null && this.props.products !== prevProps.products) {
            this.setState({ products: [...this.props.products] })
        }
    }

    addProductToInventory = async (merchantProductId) => {
        var addModel = { MerchantProductId: merchantProductId };
        let products = [...this.state.products];
        let loc = products.findIndex(prod => prod.merchantProductId === merchantProductId);

        products[loc].inUserInventory = !products[loc].inUserInventory;

        this.setState({ products });

        var url = "/Products/AddProduct";
        await postRequest(url, addModel);
    }

    likeProduct = async (shareCode) => {
        let productsCopy = [...this.state.products];
        let i = productsCopy.findIndex(prod => prod.shareCode === shareCode);
        if (i > -1) {
            let num = productsCopy[i].likedByUser === true ? -1 : 1;
            let coupon = shareCode.split("/")[4];

            productsCopy[i].likes = productsCopy[i].likes + num;
            productsCopy[i].likedByUser = !productsCopy[i].likedByUser;
            this.setState({ products: productsCopy });
            await postRequest(`/products/like?shareCode=${shareCode}`);
        }

    }

    saveProduct = async (shareCode) => {
        let productsCopy = [...this.state.products];
        let i = productsCopy.findIndex(prod => prod.shareCode === shareCode);
        if (i > -1) {
            let saved = !productsCopy[i].savedByUser;
            let path = productsCopy[i].savedByUser === true ? "Unsave" : "Save";

            productsCopy[i].savedByUser = saved;

            this.setState({ products: productsCopy });
            await postRequest(`/Products/${path}?id=${productsCopy[i].userMerchantProductId}`);
        }

    }

    setProduct = (product) => {
        let products = [...this.state.products];
        let i = products.findIndex((p) => { return p.id === product.id });
        products[i] = product;

        this.setState({ products });
    }

    renderBookmarkOverlay = (shareCode, savedByUser) => {
        return (<span className="bookmark-overlay position-absolute" onClick={async (e) => {
            if (this.props.loggedInUser) {
                e.preventDefault()
                await this.saveProduct(shareCode);
            } else {
                this.props.toggleSignUpModal();
            }

        }} style={{ cursor: "pointer" }}>
            <BookmarkIcon height="16" width="16" fill={savedByUser === true ? "#e55934" : "none"} />
        </span>);
    }

    renderShareOverlay = (shareLink) => {
        return (<span className="share-overlay position-absolute" onClick={() => {
            this.props.toggleShareModal(shareLink)
        }} style={{ cursor: "pointer" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    renderAddProductOverlay = (product) => {
        if (this.props.loggedInUser != null && (this.props.loggedInUser.proStatusId === 1) && !(product.approvedCreatorsOnly)) {
            if (product.inUserInventory !== true) {
                return (
                    <span className="inventory-overlay-three-column-browse position-absolute" onClick={e => {
                        e.preventDefault();
                        this.addProductToInventory(product.merchantProductId);
                    }}
                        style={{ left: !!(product.creatorCount) && product.creatorCount > 99 ? "67px" : product.creatorCount > 9 ? "63px" : "" }}
                    >
                        <PlusIcon height={15} width={15} fill="#e55934" fillOpacity="0.54"></PlusIcon>
                    </span>
                )
            } else if (product.inUserInventory === true) {
                return (
                    <span className="inventory-overlay-three-column-browse position-absolute" onClick={e =>
                        e.preventDefault()} style={{ left: !!(product.creatorCount) && product.creatorCount > 99 ? "67px" : product.creatorCount > 9 ? "63px" : "" }} >
                        <Tick fill="#e55934" height="12" width={14} fillOpacity="0.54" />
                    </span>
                )
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    renderInfoOverlay = (product) => {
        return <span className="info-overlay position-absolute" onClick={() => {
            this.props.toggleProductPageModal(product, this.props.loggedInUser, false, () => null, { type: null, shareCode: null, parentState: null }, true)
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934" />
        </span>
    }

    renderThumbsUpOverlay = (product) => {
        const productId = !!(product.productId) ? product.productId : product.id;
        return <span className="thumbs-up-overlay position-absolute" onClick={() => this.toggleCreatorsModal(productId)} style={{ cursor: "pointer", transform: "translate(0, -50%)", left: 0  }}>
            <ThumbsUpIcon height="16" width="16" fill={"#e55934"} />
            <span style={{ marginLeft: "5px", paddingTop: "2px" }}>{!!(product.creatorCount) ? product.creatorCount : ""}</span>
        </span>
    }

    renderSaleOverlay = (sale) => {
        if (sale) {
            return (
                <span className="on-sale-icon" style={{ bottom: "7px" }}>
                    <SaleIcon />
                </span >
            );
        }
    }

    toggleCreatorsModal = (productId) => {
        const followersFollowing = "creators";
        this.props.toggleFollowersFollowingModal(!!(this.props.loggedInUser) ? this.props.loggedInUser.id : -1, followersFollowing, productId);
    }

    renderRatingsModal = ratingsUserMerchantProductId => {
        if (this.props.loggedInUser) {
            this.setState({ ratingsUserMerchantProductId, showUserReviewList: true });
        }
        else {
            this.props.toggleSignUpModal();
        }
    }

    toTitleCase = (string) => {
        return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    mapProducts = () => {
        let sizes = ["sm", "md"];
        let deviceSize = sizes.includes(this.props.deviceSize);
        let { products } = this.state;
        let { isGrid } = this.props;
        return products.map((product, i) => {
            if (isGrid === true) {
                return (
                    <div key={i} className="col-md-3 col-4" style={{ padding: "1px" }}>
                        <div className="product-in-feed">
                            {this.renderInfoOverlay(product)}
                            {this.renderThumbsUpOverlay(product)}
                            {this.renderAddProductOverlay(product)}
                            <PostPageProduct
                                product={product}
                                loggedInUser={this.props.loggedInUser}
                                setProduct={this.setProduct}
                                toggleCreatorsModal={this.toggleCreatorsModal}
                                deviceSize={this.props.deviceSize}
                            />
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="d-flex align-items-end">
                                <div className="ml-1" style={{ marginBottom: "-16px" }}>
                                    <StarRatingComponent
                                        name="starRating"
                                        starCount={5}
                                        value={product.rating}
                                        renderStarIcon={(index, value) => {
                                            if (index <= value) {
                                                return (<span className="ratingIcon" key={index}><RatingIcon height={16} width={16} /></span>);
                                            } else {
                                                return (<span className="ratingIcon" key={index}><RatingIconEmpty height={16} width={16} /></span>);
                                            }
                                        }}
                                        renderStarIconHalf={(index, value) => {
                                            return (<span className="ratingIcon" key={index}><RatingIconHalf height={16} width={16} /></span>);
                                        }}
                                        onStarClick={() => {
                                            if (product.ratingCount > 0) {
                                                this.renderRatingsModal(product.userMerchantProductId)
                                            }
                                        }}                                    />
                                </div>
                                <span style={{ color: "#e55934", marginLeft: "3px", fontSize: "12px" }}>
                                    <Link to="/" onClick={e => {
                                        e.preventDefault();
                                        this.renderRatingsModal(product.userMerchantProductId);
                                    }}>
                                        {product.ratingCount > 0 ? `(${product.ratingCount})` : null}
                                    </Link>
                                </span>
                            </div>
                            
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap", minHeight: "25px" }}>
                                <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }} className="position-relative" >{product.manufacturer?.toLowerCase().includes("rcktemp") ? product.merchant : product.manufacturer}{product.sale && !["sm", "md"].includes(this.props.deviceSize) ? <SaleIcon style={{ marginLeft: "3px" }} /> : ""}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}>
                                    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                            {this.toTitleCase(product.name)}
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div className={`users user-tile-general py-1 ml-${product.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{product.sale && !!(product.salePrice) ? "$" + product.salePrice.toFixed(2) : <></>}</div><div className={`${product.sale && !!(product.salePrice) ? "on-sale-price" : ""}`}>${product.sale && !!(product.salePrice) && !!(product.originalPrice) ? product.originalPrice.toFixed(2) : product.price.toFixed(2)}</div></div></h5>
                                {
                                    ["sm", "md"].includes(this.props.deviceSize) ?
                                        this.renderSaleOverlay(product.sale)
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <ProductPost
                        product={product}
                        likeProduct={this.likeProduct}
                        saveProduct={this.saveProduct}
                        likedByUser={product.likedByUser}
                        savedByUser={product.savedByUser}
                        likeCount={product.likes}
                        loggedInUser={this.props.loggedInUser}
                        key={i}
                        setProduct={this.setProduct}
                        addToInventory={this.addProductToInventory}
                        inUserInventory={product.inUserInventory}
                        toggleCreatorsModal={this.toggleCreatorsModal}
                        deviceSize={this.props.deviceSize}
                    />
                )
            }

        })
    }

    displayUserReviewList = () => {
        return (
            <Modal isOpen={this.state.showUserReviewList}
                showHeader={false}
                toggleModal={() => this.setState({ showUserReviewList: !this.state.showUserReviewList })}
            >
                <ProductReviewUsersList merchantProductId={this.state.ratingsUserMerchantProductId} loggedInUser={this.props.loggedInUser} deviceSize={this.props.deviceSize} />

            </Modal>
        )
    }

    render() {
        let { isGrid } = this.props;

        if (isGrid === true) {
            return (
                <div className="container product-grid px-0">
                    {this.displayUserReviewList()}
                    <InfiniteScroll
                        dataLength={this.state.products.length}
                        next={this.props.fetchMore}
                        hasMore={!this.props.finishedFetching}
                        style={{ overflow: "hidden" }}
                    >
                        <div className="row mx-0 mt-0 product-image-gallery" style={{ position: "relative" }}>
                            {this.mapProducts()}
                        </div>
                    </InfiniteScroll>

                </div>

            )
        }
        else {
            return (
                <div className="flex-column py-1">
                    <div className="col-12 mb-0 mx-auto px-0 activity-feed-wrapper" style={{ maxWidth: "576px" }}>
                        <InfiniteScroll
                            dataLength={this.state.products.length}
                            next={this.props.fetchMore}
                            hasMore={!this.props.finishedFetching}
                        >

                            {this.mapProducts()}

                        </InfiniteScroll>
                    </div>
                </div>
            )
        }
    }

}

function mapStateToProps(storeState, ownProps) {
    return { interactionState: storeState.interactionReducer }
}

export default connect(mapStateToProps, { toggleShareModal, toggleProductPageModal, toggleFollowersFollowingModal })(BrowseProductFeed);