import React from "react";
import { getDaysBetweenDates, convertToNameOfWeekdayAndTime, convertToAbbreviatedMonthAndDayAndTime } from "../../sharedUtils/timeUtils"
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";


class MessageReply extends React.Component {

    divRef = React.createRef();

    state = {
        style : "right"
    }

    componentDidMount = () => {
        this.checkLines();
    }

    checkLines = () => {
        let computedStyle = window.getComputedStyle(this.divRef.current);
        let borderTopWidth = (computedStyle.borderTopWidth.replace("px", ""));
        let borderBottomWidth = (computedStyle.borderBottomWidth.replace("px", ""));
        let paddingTop = (computedStyle.paddingTop.replace("px", ""));
        let paddingBottom = (computedStyle.paddingBottom.replace("px", ""));
        let lineHeight = (computedStyle.lineHeight.replace("px", ""));

        let lines = (this.divRef.current.offsetHeight - borderTopWidth - borderBottomWidth - paddingTop - paddingBottom) / lineHeight;    

        if (lines > 1) {
            this.setState({ style: "left" } );
        } else {
            this.setState({ style: "right" });
        }
    }

    handleTextMarkup = (text) => {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal, true);
            }
            else {
                return retVal;
            }
        });

        return markupHandled;
    }

    render = () => {
        let message = this.props.message;
        
        return (
            <div className={`message-reply-block d-flex flex-wrap align-items-center ${this.props.paddingClass ? this.props.paddingClass : "py-3"}`}>
                {
                    this.props.formattedDate ?
                        <div className="col-12 text-right mb-2">
                            <span className="date">{this.props.formattedDate}</span>
                        </div> :
                    <></>
                }
                
                <div className="d-flex ml-auto" style={{ paddingLeft: "71px", paddingRight: "15px" }}>
                    <div className={this.state.style === "right" ? "user-profile user-tile-sm text-right justify-content-end" : "user-profile user-tile-sm text-left justify-content-end"}>
                        <div ref={this.divRef} className="message-to">{this.handleTextMarkup(message.text)}</div>
                    </div>
                </div>
            </div>);
    }
}

export default MessageReply;