import React from "react";

import AccountBanner from "../../components/AccountBanner"
import { Form, FormGroup, Label, Input, Popover, PopoverBody, CustomInput } from "reactstrap";
import Select from 'react-select'
import ResetPasswordForm from "views/Admin/ResetPasswordForm";
import Modal from "components/Modal";

import { getRequest, postRequest } from "sharedUtils/httpUtils";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ProductNotFound from "./ProductNotFound";
import { symbol } from "prop-types";

class ProductUpdate extends React.Component {
    state = {
        isLoading: false,
        imageUrl: "",
        productNotFound: false,
        isInteger: true,
        product: {},
        formData: {
            merchant: "",
            manufacturer: "",
            name: "",
            price: "",
            imageUrl: "",
        },
        searchInput: "",
    };

    readOnlyFields = ["manufacturer", "brand", "productName", "price", "imageUrl"];

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/Users', text: 'Users' }
    ]

    componentDidMount = async () => {

    }

    submitImageUrl = async (e) => {
        e.preventDefault();
        const tempProduct = { ...this.state.product};
        const product = {
            Name: tempProduct.name, Description: tempProduct.description, Url: tempProduct.url,
            ImageUrl: this.state.formData.imageUrl, MerchantProductCode: tempProduct.merchantProductCode,
            MerchantProductId: tempProduct.merchantProductId, Price: tempProduct.price
        };

        const url = "/Products/UpdateMerchantProductHistory";
        await postRequest(url, product);

        this.setState({ imageUrl: this.state.formData.imageUrl });
    }

    onImageUrlInputChange = (e) => {
        const imageUrl = e.target.value;
        const formData = { ...this.state.formData };
        formData.imageUrl = imageUrl;
        this.setState({ formData });
    };

    onSearchInputChange = (e) => {
        const searchInput = e.target.value;
        this.setState({ searchInput });
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.fetchProductByShareCode(this.state.searchInput);
        }
    
    }

    onClickImageUpload = (event) => {
        event.preventDefault();
        document.getElementById("profilePicInput").click();
    };

    fetchProduct = async (merchantProductId) => {
        const intMerchantProductId = Number(merchantProductId);
        if (Number.isInteger(intMerchantProductId) && intMerchantProductId > 0) {
            this.setState({ isInteger: true })

            this.setState({ isLoading: true });

            let url = `/Products/GetProductByMerchantId?merchantProductId=${merchantProductId}`;
            let product = await getRequest(url);

            this.handleProduct(product);
        } else {
            this.setState({ isInteger: false, ProductNotFound: false })
        }
    }

    fetchProductByShareCode = async (shareCode) => {
        if (shareCode.length > 0) {
            this.setState({ isInteger: true })

            this.setState({ isLoading: true });

            let url = `/Products/GetProductByShareCode?shareCode=${shareCode}`;
            let product = await getRequest(url);

            this.handleProduct(product);
        } else {
            this.setState({ isInteger: false, ProductNotFound: false })
        }
    }

    handleProduct(product) {
        if (product != null) {
            const formData = this.formatProduct(product);

            this.setState({
                product, isLoading: false, productNotFound: false, formData, imageUrl: product.imageUrl
            });
        } else {
            this.setState({ productNotFound: true })
        }

    }
       
    formatProduct = (product) => {
        const formData = { ...this.state.formData };
        formData.manufacturer = product.manufacturer;
        formData.merchant = product.merchant;
        formData.name = product.name;
        formData.description = product.description;
        formData.price = `$${product.price.toFixed(2)}`;
        formData.imageUrl = product.imageUrl;

        return formData;
    }

    mapInputFields = (fields) => {
        return fields.map((field, i) => {
            if (this.readOnlyFields.includes(field.name) || this.state.uploadingImage) {
                if (field.name === "imageUrl") {
                    return (
                        <FormGroup key={i} className="input-group">

                            <div className="input-group-prepend">
                                <span className="input-group-text">{field.title}</span>
                            </div>

                            <Input
                                className="form-control"
                                name={field.name}
                                onChange={(e) => this.onImageUrlInputChange(e)}
                                value={this.state.formData[field.name]}
                            />

                            <div className="input-group-append">
                                <button className="btn btn-primary" onClick={this.submitImageUrl}>
                                    Submit URL
                                </button>
                            </div>

                        </FormGroup>
                    )
                }
                else {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <Input
                                className="form-control"
                                name={field.name}
                                value={this.state.formData[field.name]}
                                readOnly="readonly"
                            />
                        </FormGroup>
                    );
                }

            }
            else {
                if (field.name === "description") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <textarea
                                className="form-control"
                                name={field.name}
                                value={this.state.formData[field.name]}
                                readOnly="readOnly"
                                style={{ overflow: "hidden" }}
                            />
                        </FormGroup>);
                }
            }
            return (
                <FormGroup key={i} className="form-group">
                    <Label className="control-label">{field.title}</Label>
                    <Input
                        className="form-control"
                        name={field.name}
                        value={this.state.formData[field.name]}
                        maxLength={field.name === "displayName" ? 20 : undefined}
                    />
                </FormGroup>
            );
        
        });
    }

    mapInputFieldsPublic = () => {
        let fields = [
            { name: "merchant", title: "Merchant" },
            { name: "manufacturer", title: "Brand" },
            { name: "name", title: "Product Name" },
            { name: "price", title: "Price" },
            { name: "description", title: "Description" },
            { name: "imageUrl", title: "Image URL" }
        ];
        return this.mapInputFields(fields);
    };

    render() {
        return (
            <>
                <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>

                    <section className="gray-bg full-height">
                        <div className="container pt-0 pb-5">
                            <div className="search-interest py-4 mx-auto">
                                <div className="search-block">
                                    <div className="position-relative mx-auto">
                                        <Input
                                            className="form-control"
                                            name="Search Product by ShareCode"
                                            onChange={(e) => this.onSearchInputChange(e)}
                                            onKeyDown={this.onKeyDown}
                                            value={this.state.searchInput}
                                            placeholder="Search Product by ShareCode..."
                                        />
                                        {this.state.productNotFound ?
                                            <p style={{ color: "red" }}>NO PRODUCT WITH THAT SHARECODE</p> : <p></p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="user-detail-form">
                                <Form>
                                    <div className="detail-block">
                                        <h5>{this.state.formData.name}</h5>

                                        <div className="form-group upload-image">
                                            <div className="avatar-upload">

                                                <div className="avatar-preview">
                                                    <div id="imagePreview" style={{ backgroundImage: 'url(' + this.state.imageUrl + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.mapInputFieldsPublic()}
                                    </div>
                                </Form>
                            </div>

                        </div>
                        </section>
                </div>
            </>
        )
    }
}

export default ProductUpdate;