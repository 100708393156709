import React from "react";
import PropTypes from "prop-types";
import { getRequest } from "sharedUtils/httpUtils";
import UserTileGeneral from "components/UserTileGeneral";
import StarRatingComponent from "react-star-rating-component";
import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import LoadSpinner from "components/LoadSpinner";

class ProductReviewUsersList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ratings: [],
            isLoading:true
        }
    }
    

    async componentDidMount() {
        let ratings = await this.fetchRatings();
        this.setState({ ratings, isLoading:false });
    }

    displayButtonText = (isFollowing) => {
        let smallDevices = ["xs", "sm"]
        if (smallDevices.includes(this.props.deviceSize)) {
            if (isFollowing) {
                return "-"
            }
            else {
                return "+"
            }
        }
        else {
            if (isFollowing) {
                return "Unfollow -"
            }
            else {
                return "Follow +"
            }
        }
    }

    displayRatings = () => {
        if (this.state.isLoading) {
            return <LoadSpinner />
        }
        else if (this.state.ratings?.length > 0) {
            let smallSizes = ["sm"];
            let userTileStyle = smallSizes.includes(this.props.deviceSize) ? { width: "53%" } : { width: "45%" };
            let buttonClass = smallSizes.includes(this.props.deviceSize) ? "" : "btn ";
            return this.state.ratings.map((rating, i) => {
                let buttonText = this.displayButtonText(rating.isFollowing);
                return (
                    <div className="d-flex user-follow mb-1 py-1 px-0" style={{ boxShadow: "none" }} key={i} >

                        <div style={userTileStyle}>
                            <UserTileGeneral user={rating} subtitle={" "} isFollowing={rating.isFollowing} showCheckMark={false} />
                        </div>

                        {this.props.deviceSize === "sm" ?
                            <span className="mr-1 ml-2">
                                <StarRatingComponent
                                    name={`${rating.userName}_rating`}
                                    starCount={5}
                                    value={rating.rating}
                                    renderStarIcon={(index, value) => {
                                        if (index <= value) {
                                            return (<span className="ratingIcon" key={index}><RatingIcon height="20" width="20" /></span>);
                                        } else {
                                            return (<span className="ratingIcon" key={index}><RatingIconEmpty height="20" width="20" /></span>);
                                        }
                                    }}
                                    renderStarIconHalf={(index, value) => {
                                        return (<span className="ratingIcon" key={index}><RatingIconHalf height="20" width="20" /></span>);
                                    }}
                                    editing={false}
                                />

                            </span> :
                            <span className="mr-1 ml-2">
                                <StarRatingComponent
                                    name={`${rating.userName}_rating`}
                                    starCount={5}
                                    value={rating.rating}
                                    renderStarIcon={(index, value) => {
                                        if (index <= value) {
                                            return (<span className="ratingIcon" key={index}><RatingIcon  /></span>);
                                        } else {
                                            return (<span className="ratingIcon" key={index}><RatingIconEmpty /></span>);
                                        }
                                    }}
                                    renderStarIconHalf={(index, value) => {
                                        return (<span className="ratingIcon" key={index}><RatingIconHalf /></span>);
                                    }}
                                    editing={false}
                                />

                            </span>}


                        {
                            rating.userId !== this.props.loggedInUser.id ? (
                                <button
                                    style={{ width: "fit-content !important",border:"none" }}
                                    className={buttonClass + "btn-sm follow-btn d-flex justify-content-center ratings-follow-btn ml-2 h-50"}
                                    onClick={(e) => {
                                        if (rating.isFollowing) {
                                            //unfollow
                                            this.unfollowUser(rating.userId, i);
                                        }
                                        else {
                                            this.followUser(rating.userId, i);
                                            //follow
                                        }
                                    }}
                                >
                                    {buttonText}
                                </button>
                                
                                ):null
                        }
                       

                    </div>
                )
            })
        }
    }

    fetchRatings = async () => {
        let ratings = await getRequest(`/Products/GetUserRatings?userMerchantProductId=${this.props.merchantProductId}`);
        return ratings
    }

    followUser = async (userId, ratingIndex) => {
        let ratings = [...this.state.ratings];
        ratings[ratingIndex].isFollowing = !ratings[ratingIndex].isFollowing;

        this.setState({ ratings });
        let url = `api/Account/follow?id=${userId}`;
        await getRequest(url);
  
    }

    unfollowUser =async (userId,ratingIndex) => {

        let ratings = [...this.state.ratings];
        ratings[ratingIndex].isFollowing = !ratings[ratingIndex].isFollowing;

        this.setState({ ratings });

        let url = `api/Account/unfollow?id=${userId}`;
        await getRequest(url);
   
    }


    render() {
        return (
            <div className = "ml-3 mt-0">
                <h5 className="mb-3">Ratings</h5>
                {this.displayRatings()}
            </div>
            )
    }
}

ProductReviewUsersList.propTypes = {
    merchantProductId: PropTypes.number,
    loggedInUser: PropTypes.object
}

export default ProductReviewUsersList;