import React, { useState, useEffect, forwardRef, useRef, useCallback } from "react";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import DraggablePhoto from "./DraggablePhoto";
import SortablePhoto from "./SortablePhoto";
import Grid from "./Grid";
import CommentIconPlusSign from "components/icons/CommentIconPlusSign";

import {
    DndContext,
    closestCenter,
    closestCorners,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";


const DisplayProducts = (props) => {
    
    const [items, setItems] = useState(props.products);
    const [prevItems, setPrevItems] = useState(props.products);

    const [startPosition, setStartPosition] = useState(null);
    const [endPosition, setEndPosition] = useState(null);
    const [id, setId] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [activeUrl, setActiveUrl] = useState(null);
    const [updated, setUpdated] = useState(false);

    const [hover, setHover] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor, { activationConstraint: {distance: 10}}));


    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const dimensionsRef = useCallback(node => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        if (props.products?.length !== items.length) {
            setItems(props.products);
        };
    }, [props.products])

    useEffect(() => {
        if (updated && items !== prevItems) {
            saveProductsReorder();
        }
        setUpdated(false);
    }, [updated])

    const saveProductsReorder = async () => {
        let saveOridinalsModel = {
            id,
            max: startPosition > endPosition ? startPosition : endPosition,
            min: startPosition > endPosition ? endPosition : startPosition,
            finalOrdinal: endPosition,
            userPostId: props.postId,
        };
        let url = `/api/Post/SaveOrdinals`;
        await postRequest(url, saveOridinalsModel);
        props.reorderProductsInPost(items);
    }

    function displayIcon(shareCode, faded) {
        if (props.locker === true) {
            return (
                <span href="/" style={{ cursor: "pointer", paddingRight: "2px", top: props.deviceSize === "sm" ? "3px" : "5px", left: "9px", zIndex: "9", display: faded ? "none" : "" }}  className="position-absolute" onClick={(e) => {
                    e.preventDefault()
                    props.showDescription(shareCode)
                }}>
                    {<CommentIconPlusSign />}
                </span>
            )
        }
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={items} strategy={()=>{}}>
                <Grid>
                    {items.map((productItem, index) => (
                        <SortablePhoto displayIcon={displayIcon} deviceSize={props.deviceSize} renderInfoOverlay={props.renderInfoOverlay} dimensionsRef={dimensionsRef} productItem={productItem} key={productItem.shareCode} url={productItem.imageUrl} index={index} removeItem={props.removeProductFromPost} hover={hover} />
                    ))}
                </Grid>
            </SortableContext>
             
            <DragOverlay >
                { activeUrl ? (
                    <div

                    >
                        <DraggablePhoto url={activeUrl} height={height} width={width} />
                    </div>
                ) : null}
            </DragOverlay>

        </DndContext>
    );

    async function handleDragStart(event) {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        document.querySelector("body").style.overflow = "hidden";
        setActiveUrl(items.filter(item => item.shareCode === event.active.id)[0].imageUrl);
        setPrevItems([...items]);
    }

    function handleDragOver(event) {
        setHover(event.over.id);
    }

    async function handleDragEnd(event) {
        document.querySelector("body").style.overflow = "";

        setHover(null);

        const { active, over } = event;

        const activeIdentity = items.findIndex(item => item.shareCode == active.id);
        const overId = items.findIndex(item => item.shareCode == over.id);

        setId(items.filter(item => item.shareCode === active.id)[0].id);

        if (activeIdentity !== overId) {
            await setItems((items) => {
                const oldIndex = activeIdentity;
                const newIndex = overId;
                setStartPosition(oldIndex + 1);
                setEndPosition(newIndex + 1);

                return arrayMove(items, oldIndex, newIndex);
            });
            setUpdated(true);
        }
    }

    function handleDragCancel() {
        document.querySelector("body").style.overflow = "";

        setActiveUrl(null);
    }

};

export default DisplayProducts;