import React from "react";
import { connect } from "react-redux";
import { getRequest } from "sharedUtils/httpUtils";
import FollowersFollowingRender from "./FollowersFollowingRender";
import { toggleFetchFollowing } from "stateManagement/reducers/modalReducer";


class FollowersFollowingList extends React.Component {

    /**
     * 
     * @param {{loggedInUser:object,userId:any,followersOrFollowing:string}} props
     */

    constructor(props) {
        super(props);
        this.state = {
            followers: [],
            following: [],
            finishedFetchingFollowers: false,
            finishedFetchingFollowing: false
        }
    }

    async componentDidMount() {
        if (this.props.loggedInUser !== undefined && this.props.userId !== null) {
            if (this.props.followersOrFollowing === "followers") {
                let users = await this.fetchFollowers(this.props.userId, 0, 10);
                this.setState({ followers: users });
            }
            else if (this.props.followersOrFollowing === "following") {
                let users = await this.fetchFollowing(this.props.userId, 0, 10)
                this.setState({ following: users });
            }
        }
    }

    fetchFollowers = async (userId, skip, take) => {
        var users = await getRequest(`api/Account/GetFollowers?userId=${userId}&skip=${skip}&take=${take}`);
        return users;
    }

    fetchFollowing = async (userId, skip, take) => {
        var users = await getRequest(`api/Account/GetLeaders?userId=${userId}&skip=${skip}&take=${take}`);
        return users;
    }

    fetchMore = async () => {
        let { followersOrFollowing } = this.props;
        let { followers, following } = this.state;
        let users;
        if (followersOrFollowing === "followers") {
            users = await this.fetchFollowers(this.props.userId, followers.length, 10);
            if (users === undefined || users === null || users.length === 0) {
                this.setState({ finishedFetchingFollowers: true });
            }
            else {
                let updated = [...this.state.followers, ...users];
                this.setState({ followers: updated })
            }
        }

        else {
            users = await this.fetchFollowing(this.props.userId, following.length, 10);
            if (users === undefined || users === null || users.length === 0) {
                this.setState({ finishedFetchingFollowing: true });
            }
            else {
                let updated = [...this.state.following, ...users];
                this.setState({ following: updated })
            }
        }
    }

    setUsers = (users) => {
        if (this.props.followersOrFollowing === "followers") {
            this.setState({ followers: users });
        }
        else {
            this.setState({ following: users });
        }
    }

    render() {

        let { followersOrFollowing } = this.props;


        return (
            <FollowersFollowingRender
                isFollowers={this.props.followersOrFollowing === "followers"}
                isCreators={this.props.followersOrFollowing === "creators"}
                finishedFetching={this.props.followersOrFollowing === "followers" ? !this.state.finishedFetchingFollowers : !this.state.finishedFetchingFollowing}
                fetchMore={this.fetchMore}
                followingFollowersCreatorsLength={this.props.followersOrFollowing === "followers" ? this.state.followers.length : this.state.following.length}
                heading={this.props.followersOrFollowing.substring(0, 1).toUpperCase() + followersOrFollowing.substring(1)}
                users={this.props.followersOrFollowing === "followers" ? this.state.followers : this.state.following}
                followersOrFollowing={this.props.followersOrFollowing}
                setUsers={this.setUsers}
                fetchFollowing={this.props.fetchFollowing}
                toggleFetchFollowing={this.props.toggleFetchFollowing}
                loggedInUser={this.props.loggedInUser}
            />

        )
    }
}

function mapStateToProps(storeState) {
    let result = { fetchFollowing: storeState.modalReducer.fetchFollowing };
    return result;
}

export default connect(mapStateToProps, { toggleFetchFollowing })(FollowersFollowingList);
