import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import Select from 'react-select'
import { getRequest } from "sharedUtils/httpUtils";
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
const { DateTime } = require("luxon");

class ElmahLogs extends Component {

    constructor(props) {
        super(props)

        this.selectOptions = {
            last24Hours: {
                value: 0,
                label: "Yesterday",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 1 }));
                    let endDate = asDate(DateTime.now().plus({ days: 1 }));
                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            last7Days: {
                value: 1,
                label: "Last 7 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 7 }));
                    let endDate = asDate(DateTime.now().plus({ days: 1 }));

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            last30Days: {
                value: 2,
                label: "Last 30 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 30 }));
                    let endDate = asDate(DateTime.now().plus({ days: 1 }));

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            last90Days: {
                value: 3,
                label: "Last 90 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 90 }));
                    let endDate = asDate(DateTime.now().plus({ days: 1 }));

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            yearToDate: {
                value: 4,
                label: "Year To Date",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year, 1, 1));
                    let endDate = asDate(DateTime.now().plus({ days: 1 }));

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            lastYear: {
                value: 5,
                label: "Last Year",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year - 1, 1, 1));
                    let endDate = asDate(DateTime.local(DateTime.now().year, 1, 1));

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            }
        };

        this.state = {
            errorLogs: [],
            errorLogsLoading: false,
            selectOptions: {
                options: [
                    this.selectOptions.last24Hours,
                    this.selectOptions.last7Days,
                    this.selectOptions.last30Days,
                    this.selectOptions.last90Days,
                    this.selectOptions.yearToDate,
                    this.selectOptions.lastYear,
                ],
                defaultValue: this.selectOptions.last24Hours,
                currentValue: this.selectOptions.last24Hours,
            },
            errorLogsTable: {
                title: "Error Logs",
                columns: [
                    {
                        name: "apiName",
                        label: "API",
                        options: {
                            filter: true,
                            sort: true,
                        }

                    },
                    {
                        name: "host",
                        label: "Host",
                        options: {
                            filter: true,
                            sort: true,

                        }
                    },
                    {
                        name: "errorId",
                        label: "Error ID",
                        options: {
                            filter: true,
                            sort: true,
                            display: false
                        }
                    },
                    {
                        name: "statusCode",
                        label: "Status Code",
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: () => ({ style: { maxWidth: "100px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                        }
                    },
                    {
                        name: "type",
                        label: "Type",
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: () => ({ style: { maxWidth: "300px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                        }
                    },
                    {
                        name: "message",
                        label: "Error",
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: () => ({ style: { maxWidth: "600px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const message = tableMeta.rowData[5];
                                const id = tableMeta.rowData[2];
                                const thisLogArray = this.state.errorLogs.filter(log => log.errorId === id);
                                const thisLog = thisLogArray[0];
                                return (
                                    <p>{message}<span style={{ color: "blue" }} onClick={() => this.toggleErrorLog(thisLog)} > ...Details</span></p>
                                );
                            }
                        }
                    },
                    {
                        name: "user",
                        label: "User",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "timeUtc",
                        label: "When",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                let date = new Date(value);
                                date = date.setHours(date.getHours() - 5);
                                return new Date(date).toLocaleString();
                            }
                        }
                    },
                ],
                options: { selectableRows: false },

                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTable: {
                            root: {
                            },
                            paper: {
                            },
                            ResponseScroll: {
                                maxWidth: "100%"
                            }
                        },
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                        MUIDataTableBodyCell: {
                            root: {
                            }
                        }
                    }
                })
            },
        }
    }

    componentDidMount = async () => {
        //this.getErrorLogData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.display != this.props.display && this.props.display && this.state.errorLogs.length === 0) {
            this.getErrorLogData();
        }
    }

    getErrorLogData = async () => {
        let startDate = this.state.selectOptions.currentValue.getDateRange().startDate;
        let endDate = this.state.selectOptions.currentValue.getDateRange().endDate;

        this.updateErrorLogs(startDate, endDate);
    }

    updateErrorLogs = async (startDate, endDate) => {
        this.setState({ errorLogsLoading: true });

        let errorLogs = await getRequest(`api/ErrorLogger/GetElmahLogs?startDate=${startDate}&endDate=${endDate}`);
        if (errorLogs == null) errorLogs = [];

        this.setState({ errorLogs, errorLogsLoading: false });
    }

    handleSelect = async (selectedOption) => {
        if (selectedOption) {
            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = selectedOption;
            this.setState({ selectOptions });
        }
        const dateRange = selectedOption?.getDateRange();
        this.updateErrorLogs(dateRange?.startDate, dateRange?.endDate);
    }

    toggleErrorLog = (log) => {
        this.props.toggleErrorLog(log);
    }

    render() {
        return (
            <div className="gray-bg full-height" style={this.props.display ? {} : { display: 'none' }}>
                {
                    this.state.errorLogsLoading ?
                        <LoadSpinnerOverlay />
                        : <></>
                }

                <div className="search-interest py-4 mx-auto">
                    <div className="search-block">
                        <div className="row gap-5 mx-0">
                            <div className="col-12 col-md-4 mt-1 position-relative float-right" style={{ zIndex: 500 }}>
                                <Select
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    placeholder="Select timeframe ..."
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    onChange={(e) => { this.handleSelect(e) }}
                                    options={this.state.selectOptions.options}
                                    defaultValue={this.state.selectOptions.defaultValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.errorLogsTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.errorLogsTable.title}
                                        data={this.state.errorLogs}
                                        columns={this.state.errorLogsTable.columns}
                                        options={this.state.errorLogsTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ElmahLogs;