import React, { forwardRef } from "react";
import Photo from "./Photo";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortablePhoto = forwardRef(
    (props, ref) => {
        const {
            attributes,
            isDragging,
            listeners,
            setNodeRef,
            transform,
            transition
        } = useSortable({ id: props.productItem.shareCode });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition
        };

        return (
            <Photo
                ref={setNodeRef}
                style={style}
                faded={isDragging}
                dimensionsRef={props.dimensionsRef}
                productItem={props.productItem}
                key={props.productItem.shareCode}
                url={props.productItem.imageUrl}
                index={props.index}
                removeItem={props.removeItem}
                hover={props.hover}
                renderInfoOverlay={props.renderInfoOverlay}
                deviceSize={props.deviceSize}
                displayIcon={props.displayIcon}
                {...attributes}
                listeners={listeners}
            />
        );
    }
);

export default SortablePhoto;