import React from "react";
import { useSwipeable } from "react-swipeable";

const SwipeableDiv = (props) => {

    const handlers = useSwipeable({
        onSwiped: (eventData) => props.onSwiped ? props.onSwiped(eventData) : null,
        onSwipedLeft: (eventData) => props.onSwipedLeft ? props.onSwipedLeft(eventData) : null,
        onSwipedRight: (eventData) => props.onSwipedRight ? props.onSwipedRight(eventData) : null,
        onSwipedUp: (eventData) => props.onSwipedUp ? props.onSwipedUp(eventData) : null,
        onSwipedDown: (eventData) => props.onSwipedDown ? props.onSwipedDown(eventData) : null,
        onSwiping: (eventData) => props.onSwiping ? props.onSwiping(eventData) : null,
        onTap: (eventData) => props.onTap ? props.onTap(eventData) : null,
        preventDefaultTouchmoveEvent: props.preventDefaultTouchmoveEvent,
        trackMouse: props.trackMouse
    });

    return (<div {...handlers} className={props.className}>{props.children}</div>);
}

export default SwipeableDiv;
