import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { convertToNumericMonthAndDayAndYearAndTime, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay } from "sharedUtils/timeUtils";


const { DateTime } = require("luxon");



class AnalyticsAggregateTable extends React.Component {

    
    state = {
        analyticsData: [],
        AnalyticsAggregateTable: {
            title: "",
            columns: [
                {
                    name: "date",
                    label: "Date",
                    options: {
                        filter: true,
                        sort: true,     
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return convertToNumericMonthAndDayAndYear(new Date(value), false);
                        }
                    }
                },
                {
                    name: "productClicks",
                    label: "Clicks",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "salesCount",
                    label: "Sales Count",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "commissionsEarned",
                    label: "Estimated Commission",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <span>${value.toFixed(2)}</span>
                        }
                    }
                },
                {
                    name: "username",
                    label: "Username",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "600px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
            ],
            options: {
                selectableRows: false,
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        },
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                        root: {
                        }
                    },
                }
            })
        },
    }

    componentDidMount = () => {
        this.setState({ analyticsData: this.props?.analyticsData.filter(data => data.productClicks > 0 || data.salesCount > 0 || data.commissionEarned > 0) });
    }



    render() {
        return (
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <ThemeProvider theme={this.state.AnalyticsAggregateTable.getTheme()}>
                                <MUIDataTable
                                    title={this.state.AnalyticsAggregateTable.title}
                                    data={this.state.analyticsData}
                                    columns={this.state.AnalyticsAggregateTable.columns}
                                    options={this.state.AnalyticsAggregateTable.options}
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AnalyticsAggregateTable