import { ClientRequest } from "../../api-social/clientRequest";

const ENABLE_AUDIT_MESSAGES = false;

var _authorization = undefined;
var _userProfile = undefined;

function getFB() {
    return new Promise((resolve) => {

        if (window.FB) {
            resolve(window.FB);
        }
        const id = 'facebook-jssdk';
        const fjs = document.querySelectorAll('script')[0];
        if (document.getElementById(id)) {
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        js.addEventListener('load', () => {
            //Object.assign(this, {
            //    AppEvents: window.FB.AppEvents,
            //    Canvas: window.FB.Canvas,
            //    Event: window.FB.Event,
            //    Frictionless: window.FB.Frictionless,
            //    XFBML: window.FB.XFBML,
            //});
            resolve(window.FB);
        });
        fjs.parentNode.insertBefore(js, fjs);
    });
}

function facebookApi(...params) {
    return new Promise(async (resolve, reject) => {
        const FB = await getFB();
        const callback = (response) => {
            if (!response || response.error) {
                reject(response);
            } else {
                resolve(response);
            }
        };
        if (params.length > 3) {
            params = params.slice(0, 3);
        }
        params.push(callback);
        FB.api(...params);
    });
}

async function loadEmbeds(val, headers) {
    if (!!(val?.data)) {
        let tasks = [];

        for (var i = 0; i < val.data.length; i++) {
            let postItem = val.data[i];
            if (!(postItem?.embed)) {
                let args = {
                    url: postItem.url,
                    maxWidth: 640
                };
                let task = ClientRequest.get('/facebook/embed', args, false, headers)
                    .then(
                        (val) => {
                            if (!!(val.html)) {
                                postItem.embed = val.html;
                            }
                        },
                        (err) => { console.error(err); }
                    );
                tasks.push(task);
            }
        }
        while (tasks.length) {
            let task = tasks.pop();
            await task;
        }
    }
}

const scope = 'email,pages_show_list,pages_read_engagement,public_profile,user_posts';
const requiredPermissions = scope.split(',');

function handleAuthStatusChange(response) {
    return new Promise(async (resolve, reject) => {
        try {
            _authorization = null;
            _userProfile = null;
            if (response.status === 'connected') {
                let authorization = response.authResponse;
                let isAuthenticated = !!(authorization.accessToken);
                if (!(authorization.grantedScopes)) {
                    const handlePermissionsResponse = (reply) => {
                        let data = reply?.data;
                        if (!data)
                            return;
                        let grantedScopes = '';
                        while (data.length > 0) {
                            let item = data.pop();
                            if (item.status === 'granted') {
                                if (grantedScopes.length > 0)
                                    grantedScopes += ',';
                                grantedScopes += item.permission;
                            }
                        }
                        authorization.grantedScopes = grantedScopes;
                    }
                    await facebookApi('/me/permissions', 'get')
                        .then(handlePermissionsResponse);
                }
                let isAuthorized = isAuthenticated;
                if (isAuthenticated) {
                    for (let i = 0; i < requiredPermissions.length; i++) {
                        let perm = requiredPermissions[i];
                        isAuthorized = authorization.grantedScopes.indexOf(perm) >= 0
                        if (!isAuthorized && perm !== 'pages_show_list' && perm !== 'pages_read_engagement') // pagas_show_list and pages_read_engagement is optional
                            break;
                    }
                }
                if (isAuthenticated && isAuthorized && _authorization !== authorization) {
                    _authorization = authorization;
                }
            }
            resolve();
        } catch (ex) {
            reject(ex);
        }
    });
};

let _clientHandle = undefined;

class FacebookClient {
    constructor() {
        this._headers = undefined;
        this.getPostsFor = this.getPostsFor.bind(this);
    }

    get isAuthorized() {
        return _authorization != undefined;
    }

    getChannelsFor(userProfile) {
        let retVal = {
            data: []
        };
        return new Promise(async (resolve, reject) => {
            let access_token = _authorization?.accessToken;
            if (!access_token) {
                reject('User is not authorized');
            } else if (!(userProfile?.id)) {
                reject('userProfile parameter required')
            } else if (!!(userProfile?.channels)) {
                resolve();
            } else {
                // the first item in the result is always the user profile
                retVal.data.push(
                    {
                        id: userProfile.id,
                        name: 'Profile Posts',
                        access_token: access_token
                    }
                );

                // on error reject the promise
                const handleError = (err) => {
                    if (!!(err.error)) {
                        reject(err.error);
                    }
                    else {
                        reject(err);
                    }
                }

                // on response add channels to result data and follow all paging
                const handleResponse = async (response) => {
                    while (response.data.length > 0) {
                        retVal.data.push(response.data.pop());
                    }
                    if (!!(response.paging?.next))
                        await facebookApi(response.paging.next, 'get')
                            .then(
                                handleResponse,
                                handleError
                        );
                }

                let args = {
                    fields: 'id,name,access_token',
                    pretty: 0
                };

                await facebookApi('/me/accounts', 'get', args)
                    .then(
                        handleResponse,
                        handleError
                    );

                resolve(retVal);
            }
        });
    }

    getPostsFor(channel) {
        var headers = this._headers;
        return new Promise(async (resolve, reject) => {
            try {
                let posts = { data: [] };

                const targetModelFrom = (sourceModel) => {

                    let retVal = {
                        content: sourceModel.message,
                        embed: null,
                        id: sourceModel.id,
                        link: sourceModel.permalink_url,
                        media: [],
                        platformName: 'facebook',
                        publishedOn: sourceModel.created_time,
                        raw: { ...sourceModel },
                        title: null
                    };

                    if (!!(sourceModel?.attachments?.data?.length) && 1 === sourceModel.attachments.data.length && 'profile_media' === sourceModel.attachments.data[0].type) {
                        return null;
                    }

                    if (!!(sourceModel?.attachments?.data?.length)) {
                        for (let i = 0; i < sourceModel.attachments.data.length; i++) {
                            let sourceAttachmentModel = sourceModel.attachments.data[i];

                            if (!!(sourceAttachmentModel?.title) && !(retVal.title)) {
                                retVal.title = sourceAttachmentModel.title;
                            }
                            let targetAttachmentModel = {
                                children: [],
                                duration: null,
                                height: null,
                                imageLink: null,
                                isExternalReference: true,
                                thumbnailLink: null,
                                videoLink: null,
                                width: null
                            };
                            if (sourceAttachmentModel.media_type === 'video') {
                                if (sourceAttachmentModel.media?.source == null) {
                                    continue;
                                }
                                targetAttachmentModel.videoLink = sourceAttachmentModel.media?.source;
                                var thumbnail = sourceAttachmentModel.media?.image;
                                if (!!thumbnail) {
                                    targetAttachmentModel.children.push({
                                        children: [],
                                        duration: null,
                                        height: thumbnail.height,
                                        imageLink: null,
                                        isExternalReference: true,
                                        thumbnailLink: thumbnail.src,
                                        videoLink: null,
                                        width: thumbnail.width
                                    });
                                }
                            } else if (sourceAttachmentModel.media_type === 'photo' && !!(sourceAttachmentModel.media?.image)) {
                                var img = sourceAttachmentModel.media.image;
                                targetAttachmentModel.imageLink = img.src;
                                targetAttachmentModel.height = img.height;
                                targetAttachmentModel.width = img.width;
                            } else if (sourceAttachmentModel.media_type === 'album') {
                                for (let i = 0; i < sourceAttachmentModel.subattachments.data.length; ++i) {
                                    let sourceSubattachmentModel = sourceAttachmentModel.subattachments.data[i];

                                    if (sourceSubattachmentModel.type === 'photo' && !!(sourceSubattachmentModel.media?.image)) {
                                        retVal.media.push({
                                            children: [],
                                            duration: null,
                                            height: sourceSubattachmentModel.media.image.height,
                                            imageLink: sourceSubattachmentModel.media.image.src,
                                            isExternalReference: true,
                                            thumbnailLink: null,
                                            videoLink: null,
                                            width: sourceSubattachmentModel.media.image.width
                                        });
                                    } else if (sourceSubattachmentModel.type === 'video') {
                                    }
                                }                                
                                continue;
                            } else {
                                continue;
                            }
                            retVal.media.push(targetAttachmentModel);
                        }
                    }
                    if (retVal.media.length > 0) {
                        return retVal;
                    }
                    return null;
                };

                const handleResponse = async (response) => {
                    for (let i = 0; i < response.data.length;i++) {
                        let sourceModel = response.data[i];
                        let targetModel = targetModelFrom(sourceModel);
                        if(!!targetModel)
                            posts.data.push(targetModel);
                    }

                    if (!!(response.paging?.next)) {
                        posts.paging = response.paging.next;
                    }
                    else if (!!(posts.paging)) {
                        delete posts.paging;
                    }

                    //await loadEmbeds(posts, headers);
                }

                if (!!(channel.next)) {
                    let url = channel.next;
                    delete channel.next;
                    await facebookApi(url, 'get')
                        .then(handleResponse);
                } else {                   
                    if (!!channel.posts?.paging) {
                        await facebookApi(channel.posts.paging, 'get')
                            .then(handleResponse);
                    } else {
                        const FIELDS = 'created_time,id,is_expired,is_hidden,is_published,message,permalink_url,privacy,attachments{description,media,media_type,title,type,url,subattachments}';
                        let params = {
                            include_hidden: false,
                            show_expired: false,
                            fields: FIELDS,
                            limit: 25,
                            access_token: channel.access_token
                        };
                        await facebookApi(`${channel.id}/posts`, 'get', params)
                            .then(handleResponse);
                    }
                }
                resolve(posts);
            } catch (ex) {
                reject(ex);
            }
        });
    }

    getUserProfile() {
        return new Promise(async (resolve, reject) => {
            try {
                if (!_userProfile) {
                    if (_authorization) {
                        let userProfile = {};
                        const handleMeReply = async (result) => {
                            userProfile.id = result.id;
                            userProfile.name = result.name;
                            userProfile.imageUrl = result.picture?.data?.url;
                        };
                        await facebookApi('/me', 'get', { fields: 'id,name,picture' })
                            .then(handleMeReply);
                        _userProfile = userProfile;
                    } else {
                        reject('User is not authorized or is lacking permissions for application');
                    }
                }
                resolve(_userProfile);
            } catch (ex) {
                reject(ex);
            }
        });
    }

    init(appId, version, headers) {
        const args = {
            appId: appId,
            cookie: false,
            status: true,
            version: version,
            xfbml: false
        };

        let candidate = { api: this };
        let isInitializing = false;
        if (_clientHandle === undefined) {
            _clientHandle = candidate;
            isInitializing = true;
            this._headers = headers;
        }
        let retVal = _clientHandle;

        return new Promise(async (resolve, reject) => {
            try {
                const FB = await getFB();
                if (!isInitializing) {
                    if (ENABLE_AUDIT_MESSAGES) console.log('FACEBOOK RE-INITIALIZE WAS BLOCKED')
                    setTimeout(() => { resolve(retVal); }, 2000);
                } else {
                    FB.init(args);

                    let p = new Promise((resolve, reject) => {
                        try {
                            const handleResponse = (response) => {
                                resolve(response);
                            };
                            FB.getLoginStatus(handleResponse);
                        }
                        catch (ex) {
                            reject(ex);
                        }
                    });

                    let response = await p;

                    await handleAuthStatusChange(response);

                    if (!!_authorization) {
                        retVal.authorization = _authorization;
                        retVal.userProfile = await this.getUserProfile();
                    }

                    resolve(retVal);
                }
            } catch (ex) {
                reject(ex);
            }
        });
    }

    login() {
        return new Promise(async (resolve, reject) => {
            try {
                const FB = await getFB();                
                let options = {
                    scope: scope,
                    return_scopes: true,
                    enable_profile_selector: true
                };
                FB.login(
                    (response) => {
                        handleAuthStatusChange(response)
                            .then(
                                () => {
                                    if (!_authorization) {
                                        reject('User not authorized');
                                    } else {
                                        resolve(_authorization);
                                    }
                                },
                                (err) => {
                                    reject(err);
                                }
                            );
                    },
                    options
                );
            }
            catch (ex) {
                reject(ex);
            }
        });
    }

    logout() {
        return new Promise(async (resolve, reject) => {
            try {
                const FB = await getFB();
                FB.logout(
                    (response) => {
                        _userProfile = null;
                        _authorization = null;
                        resolve(response);
                    }
                );
            }
            catch (ex) {
                reject(ex);
            }
        });
    }

    processEmbeds(elements, headers) {
        const doEmbed = async () => {
            //let tasks = [];
            //for (let i = 0; i < elements.length; i++) {
            //    let element = elements[i];
            //    let task = ClientRequest.get('/facebook/embed', { url: element.getAttribute('data-href') }, false, headers)
            //        .then(
            //            (val) => {
            //                if (!!(val.html)) {
            //                    element.innerHTML = val.html
            //                }
            //            },
            //            (err) => { console.error(err); }
            //        );
            //    tasks.push(task);
            //}
            //while (tasks.length) {
            //    let task = tasks.pop();
            //    await task;
            //}
            window.FB.XFBML.parse();
        }
        doEmbed();
    }

}

const facebookClient = window.facebookClient || new FacebookClient();
if (!window.facebookClient) {
    window.facebookClient = facebookClient;
}

export default facebookClient;
