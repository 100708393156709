import React from "react";

export let handleHashtags = (text) => {
    let hashTagCount = 0;

    if (typeof text === "string") {
        for (let i = 1; i < text.length; ++i) {
            if (text[i] === '#' && (text[i - 1] != '#' && text[i - 1] !== ' ' && text[i - 1] !== '\n' && text[i - 1] != '\r')) {
                ++hashTagCount;
                text = text.substring(0, i) + " " + text.substring(i);
                ++i;
            }
        }
    }
    return text;
}

export let handleCommentMarkup = (text, onlyText = false, newTab = false) => {
    // This initial loop is to reformat "dummy text" from new comments that isn't being formatted server-side
    while (text?.match(/#!\^\(([^\s]+?)\)%~@\(\(\((.*?)\)\)\)/) != null) {
        const fullMatch = text?.match(/#!\^\(([^\s]+?)\)%~@\(\(\((.*?)\)\)\)/);
        const username = fullMatch[2];
        text = text.slice(0, fullMatch.index) + `#!^(` + username + `)%~@ ` + text.slice(fullMatch.index + fullMatch[0].length);
    }

    let result = [];
    let userName = [];

    let i = 0;
    while (userName = text?.match(/#!\^\(([^\s]+?)\)%~@/)) {
        ++i;
        if (userName.index > 0) {
            result.push(text?.slice(0, userName.index));
        }

        if (newTab) {
            result.push(<a key={i} href={"/Profile/" + userName[1]} target="_blank">{"@" + userName[1]}</a>)
        } else {
            result.push(<a key={i} href={"/Profile/" + userName[1]}>{"@" + userName[1]}</a>)
        }
        text = text.slice(userName.index + userName[0].length);
    }

    result.push(handleHashtags(text));

    if (onlyText === true) {
        return result;
    } else {
        return (
            <p className="comment-tile-text mt-0 mb-0" key={i} style={{ display: "inline" }}>
                {result}
            </p>
        );
    }
    
}


/**
 * 
 * @param {string} text
 */
export const replaceUrls = (text) => {
    let urlRegex = /(https?:\/\/)([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/
    let links = [];
    let result = [];

    let i = 0;

    while (links = text?.match(urlRegex)) {
        ++i;
        if (links.index > 0) {
            result.push(text?.slice(0, links.index));
        }

        result.push(
            <a key={i} className="inline-link-markup" href={links[0]} target="_blank" rel="noopener noreferrer">{links[0]}</a>
        )

        text = text.slice(links.index + links[0].length);     
    }

    result.push(text);
    return result;
}