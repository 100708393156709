import React, { useState } from "react";
import ActionButton from "components/ActionButton";
import LinkItemDropdown from "views/Account/LinkItemDropdown";
import ImageWrapper from "components/ImageWrapper";

import { currencyFormat } from "sharedUtils/productUtils";

/**
 * 
 * @param {{product:object,edit:Function, toggleProductModal:Function, toggleShareModal:Function}} props
 */
const LinkRowMobile = (props) => {
    let { link } = props;
    let pstyle = { fontFamily: "Archivo", fontSize: "12px", width: "96%" }

    const createMerchantLinkUrl = () => {
        let getLinkUrl = "https://";

        if (window.location.host.includes("app.rockp") && !!link.shareSubdomain) {
            getLinkUrl += link.shareSubdomain + ".rockporch.com";
        } else {
            getLinkUrl += window.location.host;
        }

        getLinkUrl = getLinkUrl + "/mlink/" + link.merchantId + "?userId=" + props.loggedInUser.id + "&redirectUrl=" + encodeURIComponent(link.referralUrl);
        return getLinkUrl;
    }

    const handleGetLink = async () => {        
        props.handleGetLink(createMerchantLinkUrl());
    }

    const redirectUrl = "https://" + window.location.host + "/mlink/" + link.merchantId + "?userId=" + props.loggedInUser.id + "&redirectUrl=" + link.referralUrl;

    return (
        <div className={`card p-1 ${props.analytics ? "border-0 pt-2" : ""}`}>

            <div className="d-flex justify-content-between align-items-center px-1">
                <ImageWrapper
                    className="mr-1"
                    src={link.logoUrl}
                    style={{ width: props.deviceSize === "sm" ? "50px" : "80px", height: props.deviceSize === "sm" ? "50px" : "80px", objectFit: "contain" }}
                    onClick={() => { window.open(redirectUrl, '_blank') }}
                />

                <div style={{ flex: 1, marginLeft: "5px", }}>
                    <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: !!(link.brand) ? "" : "none"  }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { window.open(redirectUrl, '_blank') }}>{link.brand}</span>
                    </p>

                    <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "relative", top: "1px", }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { window.open(redirectUrl, '_blank') }}>{!!(link?.description?.trim()) ? link?.description?.trim() : createMerchantLinkUrl()?.substring(0,35) + "..."}</span>
                    </p>

                    <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: !!(link.merchantName) ? "" : "none"}}>
                        <span onClick={() => { window.open(redirectUrl, '_blank') }} style={{ cursor: "pointer" }}><strong>Retailer</strong>: {link.merchantName}</span>
                    </p>

                    <p className="ml-1 mb-0" style={{ ...pstyle, display: !!(link.price) ? "" : "none" }} >
                        <span onClick={() => { window.open(redirectUrl, '_blank') }} style={{ cursor: "pointer" }}><strong onClick={() => { window.open(redirectUrl, '_blank') }} style={{ cursor: "pointer" }}>Price</strong>: <span>{currencyFormat(link.price || 0)}</span></span>
                    </p>

                    <p className="ml-1 mb-0" style={{ ...pstyle, display: !!(link.estimatedCommission) ? "" : "none" }}>
                        <span onClick={() => { window.open(redirectUrl, '_blank') }} style={{ cursor: "pointer" }}><strong>{props.analytics ? "Estimated" : "Est."} Commission</strong>
                        <sup>*</sup>
                        : <span style={{ fontSize: "12px" }}>{currencyFormat(link.estimatedCommission || 0)}</span></span>
                    </p>
                </div>


                {/**/}
                <div className={`d-flex flex-column`} style={{ gap: "8px" }}>
                    <ActionButton
                        className={`btn btn-primary btn-sm ${props.analytics && props.deviceSize === "sm" ? "mx-2" : ""}`}
                        onClick={handleGetLink}
                        style={{ padding: "4px 0", width: "65px", fontSize: "14px" }}
                    >
                        Get Link
                    </ActionButton>

                    {
                        props.analytics ?
                            <></>
                            :
                            <LinkItemDropdown
                                toggleShareModal={props.toggleShareModal}
                                loggedInUser={props.loggedInUser}
                                link={link}
                                hideClick={props.hideClick}
                                editClick={props.editClick}
                                style={{ width: "65px", padding: "4px 0", fontSize: "14px" }}
                            />
                    }
                </div>

            </div>

        </div>
    )
}

export default LinkRowMobile;