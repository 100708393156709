import React from "react";
import ImageWrapper from "components/ImageWrapper";
import FacebookIcon from "components/icons/FacebookIcon";
import InstagramIcon from "components/icons/InstagramIcon";
import TikTokIcon from "components/icons/TikTokIcon";
import StravaIcon from "components/icons/StravaIcon";
import YoutubeIcon from "components/icons/YoutubeIconLink";
import WebsiteIcon from "components/icons/WebsiteLinkIcon";

/**
 * 
 * @param {{
 * userInformation:object
 * deviceSize:string
 * isAuthenticated:boolean
 * profileBanner:string,
 * productCount:Number, 
 * postCount:Number, 
 * fetchProducts:Function,
 * fetchActivityFeed:Function,
 * fetchAbout:Function,
 * fetchLockers:Function
 * onClickMessage:Function
 * selectedCase: Number,
 * productCount:Number,
 * lockerCount:Number,
 * isFollowing:boolean,
 * isLoggedInUser:boolean,
 * followAction: Function,
 * showFollowersOrFollowing:Function
 * }} props 
 */

const ProfileHeaderEmbed = (props) => {
    let { imageUrl, userName } = props.userInformation;

    let displayRightSideFunctionalityButtons = () => {
        return (
            <div className="d-flex align-self-center profile-header-icons-right">
                <div className="align-items-center mb-1">
                    {displayShareButton()}
                </div>
            </div>
        )
    }

    let displayShareButton = () => {
        let styles = {
            borderRadius: "999px",
            textAlign: "center",
            backgroundColor: "#e55934",
            fontFamily: '"Archivo", sans-serif',
            fontSize: !!(props.screenWidth) && props.screenWidth < 500 ? "0.9rem" : props.deviceSize === "sm" ? "0.9rem" : "0.7rem",
            fontWeight: 500,
            color: "#fff",
            cursor: "pointer",
            padding: !!(props.screenWidth) && props.screenWidth < 500 ? "5px 20px" : props.deviceSize === "sm" ? "5px 20px" : "6px 12px",
            position: props.deviceSize === 'sm' ? "relative" : "",
            bottom: props.deviceSize === 'sm' ? "5px" : "6px",
            left: "5px",
        };
        return (
            <div className="grid-item">
                <span className="item-count" onClick={profileRedirect} style={styles}>
                    See Profile
                </span>
            </div>
        )
    }

    const profileRedirect = () => {
        const url = "/prf/" + props.userInformation?.userName + "?showBrandNav=true";
        window.open(url);
    };

    let avatarStyle = {
        width: props.deviceSize === "sm" ? "120px" : "123px",
        height: props.deviceSize === "sm" ? "120px" : "123px",
        border: "8px solid rgb(229, 89, 52)",
        borderRadius: "50%",
        marginBottom: props.deviceSize === "sm" ? "20px" : "",
        cursor: "pointer",
        objectFit: "cover",
    };

    const preStravaIconsExist = !!(props.userInformation?.facebookUsername) || !!(props.userInformation?.instagramUsername) || !!(props.userInformation?.tikTokUsername);
    const preWebsiteIconsExceptStrava = !!(props.userInformation?.facebookUsername) || !!(props.userInformation?.instagramUsername) || !!(props.userInformation?.tikTokUsername);
    const stravaExists = props.userInformation?.stravaUsername
    const youtubeExists = !!(props.userInformation?.googleUsername);
    return (
        <>
            <div className={`col-12 text-left user-activity-info ${props.deviceSize === "sm" ? "mt-4" : "mt-0"}`} style={{ padding: "0.1px", marginLeft: props.deviceSize === "sm" ? "" : "0 5px 5px 0" }}>
                <div className={`d-flex flex-wrap align-items-center py-0 px-0 ${props.deviceSize === 'sm' ? "justify-content-between" : ""}`} >
                    {imageUrl?.length > 0 ? < ImageWrapper src={imageUrl} brandEmbed={true} size={`Profile`} onClick={profileRedirect} style={avatarStyle} alt="" /> : < ImageWrapper src="/images/avatar2.svg" onClick={profileRedirect} alt="" style={avatarStyle} />}
                    <div className={`pr-1 d-flex flex-column ${props.deviceSize === 'sm' ? "justify-content-around" : ""}`} style={{ height: props.deviceSize === 'sm' ? "115px" : "", flexGrow: props.deviceSize === 'sm' ? "" : 1, maxWidth: "392px", position: "relative", bottom: props.deviceSize === "sm" ? "5px" : "-4px" }}>
                        {props.deviceSize === "sm" ?
                            <h3 onClick={profileRedirect} style={{ fontWeight: 700, cursor: "pointer", textDecoration: "underline", paddingLeft: "6px", marginBottom: "5px", fontSize: "1.45rem" }}>{userName}</h3>
                            :
                            <h2 onClick={profileRedirect} style={{ fontWeight: 700, cursor: "pointer", textDecoration: "underline", paddingLeft: "16px", marginBottom: "5px" }}>{userName}</h2>

                        }
                        <div className="row m-0 w-100 user-activity align-items-center" style={{ paddingTop: "0" }}>

                            <div className={`position-relative col-12 mb-2 ${props.deviceSize === "sm" ? "px-0" : ""} d-flex align-items-${props.deviceSize === 'sm' ? "start" : "center"} justify-content-between  ${props.deviceSize === 'sm' ? "flex-column" : ""}`} style={{ gap: props.deviceSize === 'sm' ? "12px" : "" }}>
                                <div className={`pt-1 pb-1 d-flex align-items-center ${props.deviceSize === "sm" ? "mt-0" : "mt-2"}`} style={{ gap: "5px", marginLeft: props.deviceSize === "sm" ? "6px" : "" }}>
                                    {props.userInformation?.facebookUsername != null && props.userInformation?.facebookUsername != undefined && props.userInformation?.facebookUsername !== "" ?
                                        <div>
                                            <FacebookIcon embed={true} hrefStyle={{ border: "1px solid rgb(229, 89, 52)", borderRadius: "50%", padding: "4px", marginTop: "1px" }} height={15} width={15} fill="#4267B2" username={props.userInformation?.facebookUsername} />
                                        </div> :
                                        <></>
                                    }
                                    {props.userInformation?.instagramUsername != null && props.userInformation?.instagramUsername != undefined && props.userInformation?.instagramUsername !== "" ?
                                        <div style={{ marginTop: "2px" }}>
                                            <InstagramIcon embed={true} hrefStyle={{ border: "1px solid rgb(229, 89, 52)", borderRadius: "50%" }} fill="#191D30" height={22} width={22} username={props.userInformation?.instagramUsername} style={{ marginRight: "10px" }} />
                                        </div> :
                                        <></>
                                    }
                                    {props.userInformation?.tikTokUsername != null && props.userInformation?.tikTokUsername != undefined && props.userInformation?.tikTokUsername !== "" ?
                                        <div style={{ marginTop: "1px" }}>
                                            <TikTokIcon embed={true} style={{ background: "#191D30", padding: "4.2px", border: "1px solid rgb(229, 89, 52)", borderRadius: "50%" }} username={props.userInformation?.tikTokUsername} height={14} width={14} fill="#fff" />
                                        </div> :
                                        <></>
                                    }
                                    {props.userInformation?.stravaUsername != null && props.userInformation?.stravaUsername != undefined && props.userInformation?.stravaUsername !== "" ?
                                        <div style={{ marginBottom: "3px" }}>
                                            <StravaIcon embed={true} hrefStyle={{ border: "1px solid rgb(229, 89, 52)", borderRadius: "50%", padding: "3.7px 4.5px", position: "relative", bottom: "-1px" }} style={{ marginBottom: "3px", position: "relative", left: "0.5px" }} username={props.userInformation?.stravaUsername} height={13} width={13} />
                                        </div> :
                                        <></>
                                    }
                                    {props.userInformation?.googleUsername != null && props.userInformation?.googleUsername != undefined && props.userInformation?.googleUsername !== "" ?
                                        <div>
                                            <YoutubeIcon embed={true} hrefStyle={{ marginTop: "1px", border: "1px solid rgb(229, 89, 52)", borderRadius: "50%", padding: "2.9px" }} username={props.userInformation?.googleUsername} fill="#FF0000" height={17} width={17} />
                                        </div> :
                                        <></>
                                    }
                                    {props.userInformation?.website != null && props.userInformation?.website != undefined && props.userInformation?.website !== "" ?
                                        <div>
                                            <WebsiteIcon embed={true} hrefStyle={{ marginTop: "1px", border: "1px solid rgb(229, 89, 52)", borderRadius: "50%", padding: "3px" }} website={props.userInformation?.website} fill="#191D30" height={17} width={17} />
                                        </div> :
                                        <></>
                                    }
                                    </div>
                                <div style={{ marginTop: props.deviceSize === "sm" ? "" : "8px" }}>
                                        {displayRightSideFunctionalityButtons()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}

export default ProfileHeaderEmbed