import React from "react"
import InformationIcon from "components/icons/InformationIcon";
import FormInputField from "components/FormInputField";
import FormInputFieldTags from "components/FormInputFieldTags";
import FormDropdown from "components/FormDropdown";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import ImageWrapper from "components/ImageWrapper";
import { getAuthorizedUserData } from "sharedUtils/authUtils";
import GreyTickIcon from "components/icons/GreyTickIcon";
import { WithContext as ReactTags } from "react-tag-input";
import ActionButton from "components/ActionButton";
import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import SaleIcon from "components/icons/SaleIcon";

import Select from 'react-select'
import LoadSpinner from "components/LoadSpinner";
import StarRatingComponent from 'react-star-rating-component';
import { Link } from "react-router-dom";
import { UncontrolledPopover, PopoverBody } from "reactstrap";

import { currencyFormat, titleCase } from "sharedUtils/productUtils";

class AddProductForm extends React.Component {

    /**
     * 
     * @param {advance:Function, cancel:Function,isModal:boolean} props
     */
    constructor(props) {
        super(props);
        this.state = {
            showLockerDropdown: false,
            manufacturer: "",
            name: "",
            productReview: "",
            interestTags: [],
            selectedLockers: [],
            selectedSectors: [],
            imageUrl: "",
            price: 0,
            vendor: "",
            commission: 0,
            merchantProductId: "",
            loggedInUser: {},
            sectors: [],
            sectorInput: "",
            tagInput: "",
            lockers: [],
            userMerchantProduct: {},
            isLoading: false,
            productRating: null,
            loaded: false,
        }
    }

    async componentDidMount() {

        let { name, price, manufacturer, commission, imageUrl, merchantProductId } = this.props.product;

        if (name.indexOf(manufacturer) === 0) {
            name = name.slice(manufacturer.length - name.length).trim().trim("-").trim();

            const firstLetter = name.charAt(0);
            if (name.length > 0 && !firstLetter.match(/[a-zA-Z0-9]/i)) name = name.slice(1).trim();
        }

        let loggedInUser = await getAuthorizedUserData();
        this.setState({
            name: titleCase(name),
            manufacturer: manufacturer,
            imageUrl: imageUrl,
            price: price,
            commission: this.props.product.estimatedCommission,
            merchantProductId,
            loggedInUser,
            vendor: this.props.product.merchant,
        });
        await this.fetchTags();
        await this.fetchLockers();
        this.setState({ loaded: true });
    }

    addInterestFromTag = (sector) => {
        let newInterests = [...this.state.interestTags];
        newInterests.push(sector);
        this.setState({ interestTags: newInterests, tagInput: "" })
    }

    addSectorsToProduct = async (userMerchantProductId) => {
        if (this.state.selectedSectors.length > 0) {
            let sectorIds = this.state.selectedSectors.map((sector) => sector.sectorId);
            let url = `/Products/AddUserMerchantProductSectors?userMerchantProductId=${userMerchantProductId}`;
            let response = await postRequest(url, sectorIds);
        }
    }

    addProductToLockers = async (userMerchantProductId) => {
        let { selectedLockers } = this.state;
        if (selectedLockers?.length > 0) {
            for (let i = 0; i < selectedLockers.length; i++) {
                let url = `/api/Post/addPostProducts?shareCode=${selectedLockers[i].shareCode}`;
                let products = [userMerchantProductId];
                let response = await postRequest(url, products);
            }
        }
    }

    displayDropdownMenu = () => {
        let lockerIsSelected = (shareCode) => {
            let location = this.state.selectedLockers.findIndex((locker) => locker.shareCode === shareCode)

            return location > -1
        }
        return this.state.lockers?.map((locker, i) => {
            return (

                <li className="dropdown-item" key={i} onClick={() => this.toggleSelectLocker(i)}>
                    {lockerIsSelected(locker.shareCode) ? <GreyTickIcon /> : null}
                    <ImageWrapper src={locker?.properties?.$values[0]?.value} />
                    {locker.title}
                </li>
            )
        })
    }

    displaySectors = () => {
        let filtered = this.state.sectors?.filter((sector) => sector.name.toLowerCase().includes(this.state.sectorInput.toLowerCase()))
        return filtered?.map((sector, i) => {
            let isSelected = this.state.selectedSectors.findIndex(sec => sec.sectorId === sector.sectorId);
            if (isSelected >= 0) {
                return (
                    <button className="btn d-flex align-items-center active" key={i} onClick={() => {
                        let updatedSectors = [...this.state.selectedSectors];
                        updatedSectors.splice(isSelected, 1);
                        this.setState({ selectedSectors: updatedSectors, sectorInput: "" });
                    }}>
                        {sector.name}
                    </button>
                )
            }
            else {
                return (
                    <button className="btn d-flex align-items-center" key={i} onClick={() => {
                        let updatedSectors = [...this.state.selectedSectors];
                        updatedSectors.push(sector);
                        this.setState({ selectedSectors: updatedSectors, sectorInput: "" });
                    }}>
                        {sector.name}
                    </button>
                )
            }

        })
    }

    displayStarRating = () => {
        return (<StarRatingComponent
            name="starRating"
            starCount={5}
            value={this.state.productRating}
            renderStarIcon={(index, value) => {
                if (index <= value) {
                    return (<span className="ratingIcon" key={index}><RatingIcon /></span>);
                } else {
                    return (<span className="ratingIcon" key={index}><RatingIconEmpty /></span>);
                }
            }}
            renderStarIconHalf={(index, value) => {
                return (<span className="ratingIcon" key={index}><RatingIconHalf /></span>);
            }}
            onStarClick={this.starClick}
        />)
    }

    fetchLockers = async () => {
        let url = `/api/Locker/getLockersByUser?publishedOnly=false`;
        let lockers = await getRequest(url);
        this.setState({ lockers })
    }

    fetchTags = async () => {
        let sectors = await getRequest(`/api/Sector/GetSectors`);
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        this.setState({ sectors });
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleTagInput = (e) => this.setState({ tagInput: e.target.value })

    mapTagSuggestions = () => {
        if (this.state.sectors?.length > 0) {
            let filtered = this.state.sectors?.filter(sector => sector.name.toLowerCase().includes(this.state.tagInput.toLowerCase()));
            return filtered.map((sector, i) => {
                return { id: sector.sectorId.toString(), text: sector.name }
                {/* return (
                    <button className="btn btn-primary btn-sm m-1"
                        onClick={() => this.addInterestFromTag(sector)}
                        key={i}
                    >
                        {sector.name}
                    </button>
                )*/}
            })
        }
    }

    removeInterest = (location) => {
        let updatedInterests = [...this.state.interestTags];
        updatedInterests.splice(location, 1);
        this.setState({ interestTags: updatedInterests })
    }

    resetForm = () => {
        let blankForm = { productManufacturer: "", productName: "", productReview: "" };
        this.setState(blankForm);
    }

    starClick = async (nextValue, prevValue, name) => {
        this.setState({ productRating: nextValue });
    }

    submitProduct = async () => {
        this.setState({ isLoading: true });

        let productObject = {
            MerchantProductId: this.state.merchantProductId,
            UserDescription: this.state.productReview,
            Rating: this.state.productRating
        };
        let result = await postRequest(`/Products/AddProduct`, productObject);
        await this.setState({ userMerchantProduct: result })
        let addedSectors = await this.addSectorsToProduct(result.userMerchantProductId);
        let addedToLockers = await this.addProductToLockers(result.userMerchantProductId)
        this.resetForm();
        this.setState({ productManufacturer: "", productName: "", productReview: "", isLoading: false });

        if (result !== null || result !== undefined) {
            this.props.advance(result)
        }
        else {
            alert("there seems to be an issue adding that product");
        }

    }

    toggleSelectLocker = (selection) => {
        if (selection == null) {
            this.setState({ selectedLockers: [] })
        } else {
            this.setState({ selectedLockers: [this.state.lockers[selection.value]] })
        }
    }

    render() {
        let productImageColumnStyling = this.props.isModal === true ? "py-3 py-md-5" : "pb-3 pb-md-5"
        if (!this.state.loaded) {
            return (
                <div style={{ minHeight: "900px" }}>
                    <LoadSpinner />
                </div>
            )
        }
        return (
            <div className="row w-100 m-0">

                <div className={"col-md-5 product-vendor-info " + productImageColumnStyling + " d-flex flex-wrap"}>
                    <div className="col-12">
                        <div style={{paddingBottom: "100%", position: "relative", overflow: "hidden"}}>
                            <img style={{position: "absolute", objectFit: "scale-down", width: "100%", height: "100%" }} className="border-0" src={this.state.imageUrl} onError={(e) => {
                                e.target.onError = null
                                e.target.src = "images/blank-banner.png"
                            }} />
                        </div>
                    </div>

                    <div className="align-self-md-end w-100">
                        <span className="d-block mt-2 mb-2">Merchant</span>
                        <h4 className="mb-4">{this.state.vendor}</h4>

                        <span className="d-block mb-2">Retail price{this.props.product?.sale && !!(this.props.product?.salePrice) ? <SaleIcon style={{ marginLeft: "5px" }} /> : <></>}</span>
                        <h4 className="mb-4">{currencyFormat(this.state.price)}<span className={`${this.props.product?.sale && !!(this.props.product?.salePrice) ? "on-sale-price ml-1" : ""}`}>{this.props.product?.sale && !!(this.props.product?.salePrice) && !!(this.props.product?.originalPrice) ? currencyFormat(this.props.product?.originalPrice) : <></>}</span></h4>

                        <span className="d-flex mb-2">
                            Estimated Commission
                            <small className="d-inline-block ml-1">
                                <button style={{ outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id="infoIcon"><InformationIcon /></button>
                            </small>

                            <UncontrolledPopover
                                placement="bottom"
                                target="infoIcon"
                                trigger="legacy"
                            >
                                <PopoverBody>Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase.</PopoverBody>
                            </UncontrolledPopover>

                        </span>
                        <h4 className="estimate-price mb-0 mb-md-4">{currencyFormat(this.props.product?.estimatedCommission || 0)}</h4>
                    </div>
                </div>

                <div className="col-md-7 product-form pt-1 pb-3 py-md-4">
                    <h5 className="mb-3">Add Product</h5>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form">
                            {
                                this.state.manufacturer.length > 0 ? (
                                    <FormInputField
                                        title="Brand" name="brand"
                                        inputType="text"
                                        onInputChange={this.handleInput}
                                        value={this.state.manufacturer}
                                        readOnly="readonly"
                                    />
                                ): <span></span>
                            }
                           

                            <FormInputField
                                title="Product Name"
                                name="name"
                                inputType="text"
                                onInputChange={this.handleInput}
                                value={this.state.name}
                                readOnly="readonly"
                            />

                            {this.displayStarRating()}

                            <FormInputField
                                title="Write a Short Review"
                                name="productReview"
                                inputType="textarea"
                                value={this.state.productReview}
                                onInputChange={this.handleInput}
                            />

                            <FormInputField
                                title="Search Interests"
                                name="sectorInput"
                                inputType="text"
                                value={this.state.sectorInput}
                                onInputChange={this.handleInput}
                                placeholder="Search for interests..."
                            />

                            <div className="tag-results-sm d-flex justify-content-center flex-wrap py-1">
                                {this.displaySectors()}
                            </div>

                            <div className="form-group select-group">
                                <label htmlFor="lockerselect" className="control-label">Add to Locker</label>
                                <Select
                                    isClearable={true}
                                    isSearchable={false}
                                    placeholder="Pick a locker..."
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    onChange={(i) => { this.toggleSelectLocker(i) }}
                                    options={this.state.lockers?.map((locker, i) => {
                                        return { value: i, label: locker.title };
                                    })} />
                            </div>

                            <div className="form-group button-block text-right text-md-right mb-0">

                                {
                                    this.props.isModal === true ? (
                                        <button type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            onClick={() => {
                                                this.resetForm();
                                                this.props.cancel()
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    ) : <span></span>
                                }




                                {
                                    this.props.success === true && this.props.isModal === false ? (

                                        <ActionButton className="btn btn-secondary"
                                            onClick={this.props.closeWindow}
                                            message={{ showMessage: this.props.success, messageText: "Successfully added product" }}
                                        >
                                            Close
                                        </ActionButton>

                                    ) :
                                        (

                                            <ActionButton className="btn btn-primary ml-4"
                                                onClick={this.submitProduct}
                                                isLoading={this.state.isLoading}
                                            >
                                                Add Product
                                            </ActionButton>
                                        )
                                }

                            </div>

                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddProductForm;