import React from "react";
import { Form, FormGroup, Label, Input, CustomInput,FormText } from "reactstrap";

import AccountBanner from "../../components/AccountBanner"
import ActionButton from "../../components/ActionButton"
import LoadSpinner from "../../components/LoadSpinner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";

import BrandTable from "../../components/Tables/BrandTable";
import Modal from 'react-modal';

Modal.setAppElement('#root');

class Brand extends React.Component {

    state = {
        filter: "",
        sectors: [],
        filteredSectors: [],
        breadCrumbList: [
            { link: '/Admin', text: 'Admin' },
            { link: '/Admin/Brands', text: 'Merchant Partners' }
        ],
        merchant: {},
        oldIntegratedDate: new Date().toISOString(),
        affiliateProgramManagers: [],
        isSaving: false,
        loading: true,
        show: false,
        apmId: 0,
    }

    merchantName = "";

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        await this.populateData();
    }

    populateData = async () => {
        var merchant = await getRequest(`/api/Merchant/GetMerchant/?id=${this.props.match.params.id}`);
        if (merchant && merchant.hasOwnProperty('homepage') && !!(merchant.homepage?.trim()) && merchant.homepage.startsWith("https://")) {
            merchant.homepage = merchant.homepage.substring("https://".length);
        }

        this.setState({ merchant, oldIntegratedDate: merchant.integratedDate });

        let breadCrumbList = this.state.breadCrumbList;
        breadCrumbList.push({ link: `/Admin/Brand/${merchant.id}`, text: `${merchant.name}` });
        this.merchantName = { ...merchant }.name;
        this.setState({ breadCrumbList, merchant });

        await this.getSectors();

        let apms = await getRequest(`api/AffiliateProgramManager/GetMerchantAffiliateProgamManagersByHostname?hostname=${merchant.hostname}`);
        this.setState({ affiliateProgramManagers: apms, loading: false });
    }

    getSectors = async () => {
        let sectors = await getRequest('/api/Sector/GetSectors');
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        let merchantSectors = await getRequest(`/api/Sector/GetMerchantSectors/?id=${this.state.merchant.id}`);

        for (var i = 0; i < merchantSectors.length; ++i) {
            for (var j = 0; j < sectors.length; ++j) {
                if (merchantSectors[i].sectorId === sectors[j].sectorId) {
                    sectors[j].active = true;
                    break;
                }
            }
        }

        this.setState({ sectors, filteredSectors: sectors });
    }


    mapMerchantFields = () => {
        let fields = [
            { name: "name", title: "Name" },
            { name: "hostname", title: "Hostname" },
            { name: "hostname2", title: "Product Feed Hostname" },
            { name: "homepage", title: "Homepage" },
            { name: "logoUrl", title: "Logo URL" },
            { name: "productUrlRegex", title: "Product URL Regex" },
            { name: "ordinal", title: "Preferred Order (set to 0 to delete preferred status)", inputType: "number", min: 0 },
            { name: "integrated", title: "Integrated" },
            { name: "deepLinkOnly", title: "Deep Link Only" },
            { name: "approvedCreatorsOnly", title: "Approved Creators Only" },
            { name: "manualProductCreation", title: "Manual Product Creation" },
            { name: "shareSubdomain", title: "Share Subdomain" },
            { name: "productUrlLookupStrategyId", title: "Product URL Lookup Strategy", inputType: "number" },
            { name: "brandPresent", title: "Brand Present" }
        ];

        return fields.map((field, i) => {
            return (
                <FormGroup key={i} className="form-group">
                    <Label className="control-label">{field.title}</Label>
                    {field.name === "homepage" ? (
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" style={{ backgroundColor: "lightgray" }}>https://</span>
                            </div>
                            <Input
                                className="form-control"
                                name={field.name}
                                onChange={(e) => this.onInputChange(e)}
                                value={this.state.merchant[field.name]}
                            />
                        </div>
                    ) : (
                        <>
                            {field.name === "integrated" || field.name === "brandPresent" || field.name === "approvedCreatorsOnly" || field.name === "manualProductCreation" || field.name === "deepLinkOnly" ? (
                                <CustomInput
                                    id={field.name}
                                    type="switch"
                                    name={field.name}
                                    onChange={(e) => this.onInputChange(e)}
                                    checked={this.state.merchant[field.name]}
                                />
                            ) : (
                                <Input
                                    className="form-control"
                                    name={field.name}
                                    onChange={(e) => this.onInputChange(e)}
                                    value={this.state.merchant[field.name]}
                                    type={field.inputType || "text"}
                                    style={{ backgroundColor: "#e9ecef", width: field.name === "ordinal" ? "100px" : "" }}
                                    min={field.name === "ordinal" ? field.min : undefined}
                                />
                            )}
                            {field.name === "productUrlLookupStrategyId" ? (
                                <FormText>
                                    1: Shopify | 2: MerchantSpecific | 3: Https To Http | 4: Fleet Feet | 5: Sportsman's guide | 6: Add "WWW"
                                </FormText>
                            ) : (
                                <span></span>
                            )}
                        </>
                    )}
                </FormGroup>
            );
        });
    }

    onSectorClick = async (name) => {
        let sectors = [...this.state.sectors];
        sectors.findIndex((s) => { return s.name === name });
        let i = sectors.findIndex((s) => { return s.name === name })
        sectors[i].active = !sectors[i].active;

        this.setState({ sectors, filteredSectors: sectors, filter: "" });
    }

    onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let merchant = this.state.merchant;

        if (name === "filter") {
            let filter = e.target.value;
            let filteredSectors = this.state.sectors.filter(sector => sector.name.toLowerCase().includes(filter.toLowerCase()));

            this.setState({ filter, filteredSectors });
        }
        if (name === "integrated") {
            merchant.integrated = !merchant.integrated;
            if (merchant.integrated) {
                merchant.integratedDate = new Date().toISOString();
            } else {
                merchant.integratedDate = this.state.oldIntegratedDate;
            }
            this.setState({ merchant });
        }
        else if (name === "deepLinkOnly") {
            if (!!(merchant.deepLinkOnly)) {
                merchant.deepLinkOnly = !merchant.deepLinkOnly;
            } else {
                merchant.deepLinkOnly = true;
            }
            this.setState({ merchant });
        }
        else if (name === "brandPresent") {
            merchant.brandPresent = !merchant.brandPresent;
            this.setState({ merchant });
        }
        else if (name === "approvedCreatorsOnly") {
            merchant.approvedCreatorsOnly = !merchant.approvedCreatorsOnly;
            this.setState({ merchant });
        }
        else if (name === "manualProductCreation") {
            !(merchant.manualProductCreation) ? merchant.manualProductCreation = true : merchant.manualProductCreation = false;
            this.setState({ merchant });
        }
        else {
            merchant[name] = value;
            this.setState({ merchant });
        }
    };

    renderSectors = () => {
        return this.state.filteredSectors.map((sector, i) => {
            if (sector.active) {
                return <button key={i} className="btn d-flex align-items-center active" onClick={(e) => { e.preventDefault(); this.onSectorClick(sector.name) }}>{sector.name}</button>
            }
            return <button key={i} className="btn d-flex align-items-center" onClick={(e) => { e.preventDefault(); this.onSectorClick(sector.name) }}>{sector.name}</button>
        });

    }

    setIntegratedOff = () => {
        let merchant = this.state.merchant;
        merchant.integrated = false;
        this.setState({ merchant });
    }

    renderInterests = () => {
        return (
            <FormGroup className="form-group">
                <Label className="control-label">Interests</Label>
                <div className="search-block">
                    <div className="form-group position-relative mx-auto my-0">
                        <input type="search" id="gsearch" name="filter" value={this.state.filter} onChange={(e) => this.onInputChange(e)} placeholder="Search for interests..." />
                    </div>
                    <div className="tag-results-sm d-flex justify-content-center flex-wrap py-3">
                        {this.renderSectors()}
                    </div>
                </div>
            </FormGroup>
        );
    }

    renderAffiliateProgramManagers = () => {
        return this.state.affiliateProgramManagers.map((apm, i) => {
            return (
                <button key={i} className="btn d-flex align-items-center" disabled>
                    {apm.name} {apm.commission * 100}%
                </button>
            )
        })
    }

    updateSectors = async () => {
        let selectedSectorIds = this.state.sectors.filter(sector => sector.active === true).map((sector) => { return sector.sectorId });
        await postRequest(`/api/Sector/UpdateMerchantSectors/?id=${this.state.merchant.id}`, selectedSectorIds);
    }

    updateMerchant = async () => {
        const merchant = { ...this.state.merchant };

        if (!!(merchant.ordinal) && merchant.ordinal < 1) {
            merchant.ordinal = null;
        }

        if (merchant.hasOwnProperty('homepage') && !!(merchant.homepage?.trim()) && !merchant.homepage.startsWith("https://") && !merchant.homepage.startsWith("http://")) {
            merchant.homepage = "https://" + merchant.homepage.trim();
        }

        if (!!(merchant.shareSubdomain)) {
            merchant.shareSubdomain = merchant.shareSubdomain.trim();
        }

        await postRequest('/api/Merchant/UpdateMerchant', merchant);

        if (merchant.hasOwnProperty('homepage') && !!(merchant.homepage?.trim()) && merchant.homepage.startsWith("https://")) {
            merchant.homepage = merchant.homepage.substring("https://".length);
        }

        this.setState({ merchant });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isSaving: true });
        await this.updateMerchant();
        await this.updateSectors();
        this.setState({ isSaving: false });
    }

    showModal = (e, id) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
        this.setState({ apmId: id })
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    onRefreshYes = (e, id) => {
        e.preventDefault();

        this.state.affiliateProgramManagers.forEach(managerObj => {
            const apmId = managerObj.affiliateProgramManagerId;
            const internalId = managerObj.internalId;
            
            if (id === apmId) {
                getRequest(`/api/Merchant/RefreshFeed/?affiliateProgramManagerId=${apmId}&internalId=${internalId}`);
            };
        });

        this.setState({ show: false })
    };

    render() {
        if (this.state.loading) {
            return <LoadSpinner />
        }

        return (
            <>
                <Modal isOpen={this.state.show}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-between">
                        <h1 style={{ marginTop: '20%',textAlign: "center", color: "#B73239" }}>WARNING</h1>

                        <div className="mx-0 my-4">
                            <p style={{ textAlign: "center", diplay: "block", fontSize: '0.9rem' }}>
                                NOT RECOMMENDED TO REFRESH FEED DURING PEAK TRAFFIC
                            </p>
                            <p style={{ textAlign: 'center', marginTop: "35px", fontWeight: "bold" }}>
                                Do you wish to continue?
                            </p>
                        </div>
                    
                        <div className="d-flex justify-content-center gap-3">
                            <button type="button" className="btn btn-primary mr-2" onClick={(e) => this.onRefreshYes(e, this.state.apmId)}>Yes</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={() => this.setState({ show: false })}>No</button>
                        </div>
                    </div>
                </Modal>
                <section>
                    <AccountBanner breadCrumbList={this.state.breadCrumbList}>
                        <h1 className="mb-4">{this.merchantName}</h1>
                        <p className="mb-0">Edit Merchant Partner details</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container py-5">
                        <div className="col-12">
                            <Form>
                                {
                                    !!(this.state.merchant?.logoUrl) && this.state.merchant.logoUrl.length > 0 ?
                                        <div className="form-group upload-image">
                                            <div className="avatar-upload">
                                                <div className="avatar-preview" style={{ width: "300px", backgroundColor: "white", position: "relative" ,borderRadius: "revert", border: "revert" }}>
                                                    <div id="imagePreview" style={{ position: "absolute", left: "50%", transform: "translate(-50%, 0)", maxWidth: "250px", minWidth: "200px", backgroundSize: "contain", borderRadius: "revert", backgroundImage: 'url(' + (!!(this.state.merchant?.logoUrl) ? this.state.merchant.logoUrl : "") + ')' }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        :
                                        <></>
                                }
                                {this.mapMerchantFields()}
                                {this.renderInterests()}

                                <BrandTable apm={this.state.affiliateProgramManagers} setIntegratedOff={this.setIntegratedOff} showModal={this.showModal}/>
                                {
                                    this.state.merchant.hostname?.trim().length > 0 &&
                                    this.state.merchant.name?.trim().length > 0 &&
                                        this.state.merchant.homepage?.trim().length > 0 ?

                                        <ActionButton className="btn btn-primary"
                                            onClick={(e) => { this.onSubmit(e) }}
                                            isLoading={this.state.isSaving}
                                        >
                                            Submit
                                        </ActionButton>
                                        :
                                        <ActionButton className="btn btn-primary"
                                            onClick={(e) => { this.onSubmit(e) }}
                                            isLoading={this.state.isSaving}
                                            isDisabled={true}
                                        >
                                            Submit
                                        </ActionButton>                                }
                                
                            </Form>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Brand;