
var jsonref = require("jsonref");

/**
 * @description Accepts a userId and makes an api call to retrieve user suggestions
 * @param {any} userId 
 * @returns {[{
 * $id:string,
 * user:{
 *      $id:string,
 *      imageUrl:string,
 *      proStatusId: Number,
 *      userName: string,
 *      name:string,
 *      merchantProducts: {
 *          $type:string,
 *          $values:[]
 *      },
 *      sectors: {
 *          $type:string,
 *          $values: []
 *      },
 *      id:Number
 * }
 * }]} An array of user objects if successful
 */
async function fetchUserSuggestions(userId,skip,take) {
    let request = await fetch(`/userSuggestions/get?userId=${userId}&skip=${skip}&take=${take}`);
    let response = await request.json().then(async(results) => {
        let parsed = await jsonref.parse(results, { scope: window.location })
        return parsed
    })
        .catch(err => {
            console.log(err);
            return []
        });
    return response;
}


let blankUserSuggestion = {
    $id: "",
    user: {
        $id: "",
        imageUrl: "",
        proStatusId: 0,
        userName: "",
        name: "",
        merchantProducts: {
            $type: "",
            $values: [],
        },
        sectors: {
            $type: "",
            $values: [],
        },
        id: 0,
    },
    posts: {
        $type: "",
        $values: [
            {
                $id: "",
                comments: {
                    $type: "",
                    $values: [],
                },
                content: "",
                flags: {
                    $type: "",
                    $values:[]
                },
                id: 0,
                likes: {
                    $type: "",
                    $values:[]
                },
                productItems: {
                    $type: "",
                    $values:[]
                },
                properties: {
                    $type: "",
                    $values: [{
                        $id: "",
                        id: 0,
                        reference: "",
                        role: 0,
                    }]
                },
                sectors: {
                    $type: "",
                    $values: {}
                },
                shareCode: "",
                status: 1,
                type: 1
            }
        ]
    }
};

/**
 * @Description Accepts a post and pulls out pictures from it. Posts can be found is userSuggestion.Posts.$values and it's an array of post objects
 * @param {{properties:{$type:"",$values:[{$id:string, id:Number, reference:string, role: Number}]}} post
 */
function mapPostMedia(post) {
    return post.properties.$values.map((property) => property.value);
}


export {
    fetchUserSuggestions,
    blankUserSuggestion,
    mapPostMedia
}