import React from "react";
import AccountBanner from "../../components/AccountBanner"
import Notifications from "./Notifications"
import Messages from "./Messages"
import NotificationDotIcon from "../../components/icons/NotificationDotIcon"
import { getMessageCount, getNotificationCount } from "sharedUtils/notificationUtils";
import { Helmet } from 'react-helmet';

class NotificationsAndMessages extends React.Component {
    breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/NotificationsAndMessages', text: 'Messages', active: true }];
    
    state = {
        notificationsSelected: false,
        onMessagesSelected: () => { },
        unviewedMessageCount: 0,
        unviewedNotificationCount: 0
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.props.history.location.hash === "#notifications") {
                this.notificationsSelected();
            } else if (this.props.history.location.hash === "#messages") {
                this.messagesSelected();
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
        clearInterval(this.timer);
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.refreshState();

        if (this.props.history.location.hash === "#notifications") {
            this.notificationsSelected();
        } else {
            this.messagesSelected();
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.unviewedMessageCount !== prevProps.unviewedMessageCount ||
            this.props.unviewedNotificationCount !== prevProps.unviewedNotificationCount) {
            this.refreshState();
        }
    }

    refreshState = async () => {
        let unviewedMessageCount = await getMessageCount(true);
        let unviewedNotificationCount = await getNotificationCount(true);

        if (this.state.unviewedMessageCount !== unviewedMessageCount) {
            this.state.unviewedMessageCount = unviewedMessageCount;
        }

        if (this.state.unviewedNotificationCount !== unviewedNotificationCount) {
            this.state.unviewedNotificationCount = unviewedNotificationCount;
        }
    }

    setOnMessagesSelected = (func) => {
        this.state.onMessagesSelected = func;
    }

    notificationsSelected = () => {
        if (this.props.history.location.hash !== "#notifications") {
            this.props.history.push("#notifications");
        }

        this.setState({ notificationsSelected: true });
    }

    messagesSelected = (clicked) => {
        if (this.props.history.location.hash !== "#messages") {
            this.props.history.push("#messages");
        }

        this.setState({ notificationsSelected: false });

        if (clicked) {
            this.state.onMessagesSelected();
        }
    }

    setNotificationsSelected = (e, notificationsSelected) => {
        e.preventDefault();

        if (notificationsSelected) {
            this.notificationsSelected();
        } else {
            this.messagesSelected(true);
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>RockPorch - Messages and Notifications</title>
                </Helmet>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Messages and Notifications</h1>
                        <p className="mb-0">Stay connected</p>
                    </AccountBanner>                
                </section>
                <section className="gray-bg full-height">
                    <div className="message-tabs py-2">
                       <div className="container">
                          <div className="row mt-0">
                             <div className="col-12">
                                    <a href="/Account/NotificationsAndMessages#Messages" className={!this.state.notificationsSelected ? "active mr-1" : "mr-1"} onClick={(e) => this.setNotificationsSelected(e, false)}>
                                        Messages
                                    </a>
                                    <a href="/Account/NotificationsAndMessages#Notifications" className={this.state.notificationsSelected ? "active mr-1" : "mr-1"} onClick={(e) => this.setNotificationsSelected(e, true)}>
                                        Notifications
                                    </a>
                             </div>
                          </div>
                       </div>
                    </div>

                    <Messages display={!this.state.notificationsSelected}  loggedInUser={this.props.loggedInUser} setOnSelected={this.setOnMessagesSelected} />
                    <Notifications display={this.state.notificationsSelected}/>
                </section>
            </>
        )
    }
}

export default NotificationsAndMessages;