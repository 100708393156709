import React from "react";
import Modal from "components/Modal";
//import ModalHeader from "components/ModalHeader";
import CreateLockerForm from "components/CreateLocker/CreateLockerForm";
import { connect } from "react-redux";
import { toggleCreateLockerModal } from "stateManagement/reducers/modalReducer";

const CreateLockerModal = (props) => {
    if (props.showModal) {
        document.querySelector("body").style.overflow = "hidden";
    } else {
        document.querySelector("body").style.overflow = "";
    }
    return (
        <Modal isOpen={props.showModal} toggleModal={props.toggleModal} ignoreClickAway={true}>
            <CreateLockerForm closeModal={props.toggleModal} />
        </Modal>
      )
}

function mapStateToProps(storeState, ownProps) {
    return { showModal: storeState.modalReducer.showCreateLockerModal }
}

const mapDispatch = {
    toggleModal: toggleCreateLockerModal
}

export default connect(mapStateToProps, mapDispatch)(CreateLockerModal);