import React from "react"

/**
 * 
 * @param {{width:Number,height:Number,opacity:Number}} props
 */
const NextIcon = (props) => {
    return (
        <svg onClick={props.onClick ?? {}} className={props.className ?? ""} width={props.width ?? "16"} height={props.height ?? "16"} opacity={props.opacity ?? 1} style={props.style ?? {}} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
            <g>
                <polygon points="111.1,33.8 333.3,256 111.1,478.2 144.9,512 400.9,256 144.9,0 111.1,33.8  " />
            </g>
        </svg>
    )
}

export default NextIcon;