import * as React from "react";
import { Link } from "react-router-dom";
import PenIcon from "./icons/PenIcon"
import AvatarIconSmall from "./icons/AvatarIconSmall";
import BellIcon from "./icons/BellIcon";
import GridIcon from "./icons/GridIcon";
import AnalyticsIcon from "./icons/AnalyticsIcon";
import GreyTickIcon from "./icons/GreyTickIcon";
import BookmarkIcon from "components/icons/BookmarkIcon";
import LightningIcon from "components/icons/LightningIcon";
import ShoppingCartIcon from "components/icons/ShoppingCartIcon";
import WritingIcon from "components/icons/WritingIcon";

const AccountSidebar = (props) => {
    const isCreator = props?.loggedInUser?.proStatusId === 1;

    const renderGoPro = () => {
        if (props?.loggedInUser?.proStatusId === 0) {
            return (<li>
                <div className="row">
                    <Link to={{ pathname: "/Account/CreatorApp" }} className="d-flex align-items-center"><div className="col-1"><GreyTickIcon /></div><div className="col-11">Apply as Creator</div></Link>
                </div>
            </li>)
        }
    }

    return (
        <ul>
            <li>
                <div className="row">
                    <Link to={{ pathname: "/Account/Interests" }} className="d-flex align-items-center"><div className="col-1"><GridIcon /></div><div className="col-11">Interests</div></Link>
                </div>
            </li>

            <li>
                <div className="row">
                    <Link to={{ pathname: "/Account/AccountSettings" }} className="d-flex align-items-center"><div className="col-1"><AvatarIconSmall /></div><div className="col-11">Account Settings</div></Link>
                </div>
            </li>
            <li>
                <div className="row">
                    <Link to="/Account/Saves" className="d-flex align-items-center">
                        <div className="col-1">
                            <BookmarkIcon width={19} height={19} fill="black" />
                        </div>
                        <div className="col-11">My Saves</div>
                </Link>
                </div>
            </li>

            {renderGoPro()}
        </ul>
    );
};

export default AccountSidebar;