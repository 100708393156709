import React from "react"

const StravaIcon = (props) => {
    const link = "https://www.strava.com/athletes/" + props.username;

    return (
		<>
			<a href={link} className={`${props.embed ? "" : "mx-2"}`} style={props.hrefStyle} target="_blank" rel="noopener noreferrer">
				<svg xmlns="http://www.w3.org/2000/svg" style={props.style}  viewBox="0 0 16 16" width={props.width || "26"} height={props.height || "26"}>
					<path d="M0 0h16v16H0z" fill="#fc4c02"/>
					<g fill="#fff" fillRule="evenodd">
						<path d="M6.9 8.8l2.5 4.5 2.4-4.5h-1.5l-.9 1.7-1-1.7z" opacity=".6"/>
						<path d="M7.2 2.5l3.1 6.3H4zm0 3.8l1.2 2.5H5.9z"/>
					</g>
				</svg>
			</a>
		</>
	)
}

export default StravaIcon;

