import React from "react";
import GridIconAlternate from "components/icons/GridIconAlternate";
import DoubleBarIcon from "components/icons/DoubleBarIcon";


/**
 * 
 * @param {{types:[],interests:[],toggleAction:Function, isGrid:boolean, className:string}} props 
 */
const FeedFilterBar = (props) => {
    let renderToggleIcon = () => {
        return props.isGrid === true ? <DoubleBarIcon /> : <GridIconAlternate />
    }
    return (
        <section className={`filter-wrapper ${props.className ? props.className:""}`} >
            <div className="container">
                <div className={props.innerClassName || "row pb-2 pb-md-4 pt-2"}>
                    <div className="col-12 d-flex justify-content-between">
                        <div className="filter-block d-flex">
                            {props.children || <span></span>}
                        </div>
                        <button className="filter-toggle-btn"
                            onClick={props.toggleAction}>
                            {renderToggleIcon()}
                        </button>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeedFilterBar;