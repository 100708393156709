import React from "react";
import { MentionsInput, Mention } from "react-mentions";
import { getRequest } from "sharedUtils/httpUtils";
import { debounce } from "throttle-debounce"

/**
 * @description: Component for posting comments
 * @param {{postComment:Function?,user: object, handleCommentInput:Function, commentText:string}} props
 */
const CommentForm = (props) => {

    const debounceFunc = React.useCallback(debounce(300, (query, callback) => fetchUsers(query, callback)), []);

    let renderButton = () => {
        if (props.commentText.length > 0 && !props.submittingComment) {
            return (<button className="comment-btn btn btn-primary" onClick={props.postComment} onTouch={props.postComment}>Post</button>);
        } else {
            return (<button style={{ pointerEvents: "none" }} className="comment-btn btn btn-primary disabled" disabled>Post</button>);
        }
    }

    function fetchUsers (query, callback) {
        if (!query || query.length < 1) {
            return;
        }

        getRequest(`/api/Account/GetUserSuggestionsByUsername?username=${query}`)
            .then(res =>
                res?.map(user => ({ display: user.userName, id: user.id, name: user.name }))
            )
            .then(callback)
    }

    return (
        <div className="col-md-12 mb-3 mb-md-0 pl-1">
            <div className="comment-form">
                <div className="row mx-0">
                    <div className="pl-0 col-10 col-md-11">
                        <MentionsInput
                            className="comments-textarea"
                            id="comments"
                            inputRef={props.commentsTextArea}
                            placeholder="Share your thoughts..."
                            value={props.commentText}
                            style={{ height: "auto", margin: "0px 0px 0px 0px" }}
                            allowSuggestionsAboveCursor={true}
                            onChange={(e) => { props.handleCommentInput(e); }}>

                            <Mention
                                trigger="@"
                                data={debounceFunc}
                                displayTransform={(id, display) => `@${display}`}
                                markup="#!^(__id__)%~@(((__display__)))"
                                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
                                    return <span><strong>@{entry.display}</strong><div><small>{entry.name}</small></div></span>
                                }}
                            />
                        </MentionsInput>
                    </div>
                    <div className="col-1 col-md-1 text-left px-0">
                        {renderButton()}
                    </div>
                    </div>
            </div>
        </div>

    )
}

export default CommentForm;