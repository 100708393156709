import React from "react";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { handleCommentMarkup } from "sharedUtils/markupUtils";
import { getNotificationCount } from "sharedUtils/notificationUtils";
import NotificationDotIcon from "../../components/icons/NotificationDotIcon"
import { convertToAbbreviatedMonthAndDay } from "../../sharedUtils/timeUtils"
import BlueTickIconXY from "components/icons/BlueTickIconXY";
import NotificationContent from "components/Notification/NotificationContent";
import { togglePostPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getAuthorizedUserData } from "sharedUtils/authUtils";

class Notifications extends React.Component {

    state = {
        notifications: [],
        notificationsPerPage: 10,
        page: 1,
        notificationCount: 0,
        pagingControls: []
    }

    componentDidMount = async () => {
        this.getNotificationCount();
        this.getNotifications();
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (this.props.display) {
            this.getNotificationCount();
            this.getNotifications();
        }
    };

    getNotificationCount = async () => {
        let notificationCount = await getNotificationCount(false);
        this.setState({ notificationCount: notificationCount });
    }

    getNotifications = async () => {
        let notificationsUrl = '/api/Account/GetNotifications?skip=' + (this.state.page - 1) * this.state.notificationsPerPage + '&take=' + this.state.notificationsPerPage;
        let notifications = await getRequest(notificationsUrl) ?? [];
        this.setState({ notifications: notifications });
    }

    renderHeaderLine = () => {
        return (
            <p className="float-md-right">Showing notifications {(this.state.page - 1) * this.state.notificationsPerPage + this.state.notifications.length > 0 ? 1 : 0} - {(this.state.page - 1) * this.state.notificationsPerPage + this.state.notifications.length} of {this.state.notificationCount}</p>
        );
    }

    onClickNotification = async (notification) => {
        let notifications = this.state.notifications;

        let index = notifications.findIndex((n) => { return n.userNotificationId === notification.userNotificationId });

        if (!notifications[index].viewedDate) {
            notifications[index].viewedDate = Date.UTC;
            this.setState({ notifications: notifications });

            let notificationsUrl = '/api/Account/MarkNotificationAsViewed/' + notification.userNotificationId;
            await postRequest(notificationsUrl);
        }
    }

    fetchPost = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? "/api/post/getPostAuthorized" : "/api/post/getPost";
        let url = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}`
        let data = await getRequest(url);
        return data;
    }

    onClickPost = async (e, notification) => {
        e.preventDefault();
        let loggedInUser = await getAuthorizedUserData();
        let isAuthorized = loggedInUser !== undefined && loggedInUser !== null;
        let post = await this.fetchPost(notification.userPostShareCode, isAuthorized);
        
        if (post.type === 2) {
            this.props.toggleLockerPageModal(post, loggedInUser, true, null)
        } else {
            this.props.togglePostPageModal(post, loggedInUser, true, null);
        }
    }

    fetchProduct = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let url = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}`;
        let data = await getRequest(url);
        return data;
    }

    onClickProduct = async (e, notification) => {
        e.preventDefault();
        let loggedInUser = await getAuthorizedUserData();
        let isAuthorized = loggedInUser !== undefined && loggedInUser !== null;
        let product = await this.fetchProduct(notification.userMerchantProductCoupon, isAuthorized);

        this.props.toggleProductPageModal(product, loggedInUser, true, null);
    }

    renderNotification = (notification) => {
        if (notification.userNotificationTypeId === 1 || notification.userNotificationTypeId === 8) {
            return <NotificationContent onClick={this.onClickProduct} notification={notification} commentText={notification.userMerchantProductCommentText} />
        }
        else if (notification.userNotificationTypeId === 2 || notification.userNotificationTypeId === 6) {
            return <NotificationContent onClick={this.onClickPost} notification={notification} commentText={notification.userPostCommentText} />
        }
        else if (notification.userNotificationTypeId === 3 || notification.userNotificationTypeId === 7 ) {
            return <NotificationContent onClick={this.onClickPost} notification={notification} commentText={notification.userPostCommentText} />
        }
        else if (notification.userNotificationTypeId === 4 || notification.userNotificationTypeId === 5) {
            return <NotificationContent onClick={this.onClickPost} notification={notification} commentText={notification.userPostContent} />
        }
    }

    renderNotifications = () => {
        return this.state.notifications.map((notification, i) => {
            return (
                <div className="row" onClick={() => this.onClickNotification(notification)} key={i}>
                    <div className="col-12">
                        <div className="notificationRow">
                            <div className="notificationRow-notification">
                                    <div>
                                        {
                                            notification.viewedDate ?
                                                this.renderNotification(notification) :
                                                <><strong>{this.renderNotification(notification)}</strong></>
                                        }
                                    </div>
                                </div>
                                <div className="notificationRow-date">
                                    {
                                        notification.viewedDate ?
                                        <>
                                            {convertToAbbreviatedMonthAndDay(notification.createdDate, false)}
                                        </> :
                                        <>
                                            <strong>{convertToAbbreviatedMonthAndDay(notification.createdDate, false)}</strong>
                                            <NotificationDotIcon customClass="ml-5px" />
                                        </>
                                    }
                                </div>
                        </div>
                        <div className="notificationRow-bottomBorder"></div>
                    </div>
                </div>
            );
        });
    }

    onClickPage = async (page) => {
        await this.setState({ page: page });
        this.getNotifications();
    }

    renderPagingControls = () => {
        let pageCount = Math.ceil(this.state.notificationCount / this.state.notificationsPerPage);
        let pagingControls = [];
        for (let i = 1; i <= pageCount; ++i) {
            pagingControls.push(<li key={i} className={this.state.page == i ? "page-item active" : "page-item"}><button className="page-link" value={i} onClick={() => this.onClickPage(i)}>{i}</button></li>);
        }

        return <>{pagingControls}</>;
    }

    render() {
        if (this.props.display) {
            let pageCount = Math.ceil(this.state.notificationCount / this.state.notificationsPerPage);
            return (
                <div className="container px-0 py-5">
                    <div className="row message-wrapper mx-0 my-0">
                        <div className="col-12 p-0 px-md-3">
                            <div className="message-top clearfix text-center text-md-left">
                                {this.renderHeaderLine()}
                            </div>
                        </div>
                        <div className="col-12 p-0 px-md-3">
                            {this.renderNotifications()}
                        </div>

                        {
                            pageCount > 1 ?
                                <nav aria-label="..." className="col-12 pt-5 pb-3">
                                    <ul className="pagination justify-content-center">
                                        {this.renderPagingControls()}
                                    </ul>
                                </nav> :
                                <></>
                        }
                    </div>
                </div>)                            
        }
        return <></>;
    }
}

const mapDispatch = { togglePostPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(Notifications);