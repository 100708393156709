import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Popover, PopoverBody, PopoverHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RPModal from "components/Modal";
import { Redirect } from "react-router-dom";
//import $ from 'jquery';
//import 'jquery.iframetracker';
import authService from '../../api-authorization/AuthorizeService';
import { ClientRequest } from '../../api-social/clientRequest';
import instagram from './Instagram';
import tiktok from './TikTok';
import facebook from './Facebook';
import youtube from './Youtube';
import { Sources } from './Sources';
import PreviewCard from './PreviewCard';
import ShareCard from './ShareCard';
import CreateNativePost from "components/Post/Create/CreateNativePost";
import "../post.css";
import { getRequest, postRequest } from "sharedUtils/httpUtils"
import LoadSpinner from "components/LoadSpinner";
import ActionButton from "components/ActionButton";
import GearIcon from "components/icons/GearIcon"
import StackTrace from "stacktrace-js";


const ENABLE_AUDIT_MESSAGES = false;

let _coordinatorLoadStatus = 0;

export const Coordinator = (props) => {
    const { id, showModal, onModalClosed, loggedInUser } = props;
    const [isPreviewMode, setPreviewMode] = useState(false);
    const [isReady, setReady] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();

    const [state, setState] = useState({
        sources: {
            data: [
                {
                    authorization: null,
                    name: 'file',
                    userProfile: {
                        name: '<authenticated user>',
                        channels: {
                            data: [
                                {
                                    id: 0,
                                    name: 'local file system',
                                    posts: {
                                        data: [
                                            { id: '<file handle>' }
                                        ],
                                        selectedIndex: -1,
                                        isFullyLoaded: false
                                    }
                                }],
                            selectedIndex: 0,
                            isFullyLoaded: false
                        }
                    }
                },
                { name: 'instagram' },
                { name: 'facebook' },
                { name: 'youtube' },
                { name: 'tiktok'},
            ],
            selectedIndex: 0
        }
    });
    const componentDidUpdateRef = useRef(false);

    const [postCaption, setPostCaption] = useState("");
    const [nativePostMedia, setNativePostMedia] = useState([]);
    const [nativePostSectors, setNativePostSectors] = useState([]);
    const [nativePostMediaUrls, setNativePostMediaUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showRemoveAccess, setShowRemoveAccess] = useState(false);
    //
    // audit
    /**/
    useEffect(() => {
        if (!!ENABLE_AUDIT_MESSAGES)
            console.log(new Date(), Coordinator.name, '::showModal', showModal);
    }, [showModal]);
    useEffect(() => {
        if (!!ENABLE_AUDIT_MESSAGES)
            console.log(new Date(), Coordinator.name, '::isReady', isReady);
    }, [isReady]);
    useEffect(() => {
        if (!!ENABLE_AUDIT_MESSAGES)
            console.log(new Date(), Coordinator.name, '::state', state);
    }, [state]);
    // */

    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "";
        }
    }, [isPreviewMode]);

    useEffect(() => {
        const data = state.sources.data;
        const selectedIndex = state.sources.selectedIndex;
        setShowRemoveAccess(data[selectedIndex].name.toLowerCase() === "youtube" &&
            !!(data[selectedIndex].authorization?.access_token) &&
            data[selectedIndex].authorization.access_token.length > 0)
    }, [state])

    const doLoad = async () => {
        let accessToken = await authService.getAccessToken();
        if (accessToken == null)
            return;

        let headers = { headers: !accessToken ? {} : { Authorization: `Bearer ${accessToken}` } };
        let config = await ClientRequest.get('api/post/getCreate', null, false, headers);
        if (config == null)
            return;

        let igResult = {};
        let instagramInit = instagram.init(config?.instagram?.appId, headers)
            .then(
                (val) => {
                    igResult = val;
                },
                (err) => console.error('Initializing Instagram', err)
            );

        let fbResult = {};
        let facebookInit = facebook.init(config?.facebook?.appId, config?.facebook?.version, headers)
            .then(
                (val) => fbResult = val,
                (err) => console.error('Initializing Facebook', err)
            );

        let ytResult = {};
        let youtubeInit = youtube.init(config?.youtube?.appId, config?.youtube?.apiKey, headers)
            .then(
                (val) => ytResult = val,
                (err) => console.error('Initializing Youtube', err)
        );

        let ttResult = {};
        let tiktokInit = tiktok.init(config?.tikTok?.clientKey, headers)
            .then(
                (val) => ttResult = val,
                (err) => console.error('Initializing TikTok', err)
            );

        await instagramInit;
        await facebookInit;
        await youtubeInit;
        await tiktokInit;

        let initialState = {
            ...headers,
            sources: {
                data: [
                    {
                        authorization: headers.headers,
                        name: 'file',
                        loginComplete: true,
                        userProfile: {
                            name: '<authenticated user>',
                            channels: {
                                data: [
                                    {
                                        id: 0,
                                        name: 'local file system',
                                        posts: {
                                            data: [
                                                { id: '<file handle>' }
                                            ],
                                            selectedIndex: -1,
                                            isFullyLoaded: true
                                        }
                                    }],
                                selectedIndex: 0,
                                isFullyLoaded: true
                            }
                        }
                    },
                    { name: 'instagram', ...igResult, loginComplete: true },
                    { name: 'facebook', ...fbResult, loginComplete: true },
                    { name: 'youtube', ...ytResult, loginComplete: true },
                    { name: 'tiktok', ...ttResult, loginComplete: true },
                ],
                selectedIndex: 0
            }
        };

        setState(initialState);
        setReady(true);
    }

    //
    // componentDidMount
    //
    //useEffect(() => {
    //    if (!!ENABLE_AUDIT_MESSAGES)
    //        console.log('handle mounted');

    //    let loadStatus = _coordinatorLoadStatus++;
    //    if (loadStatus === 0) {
    //        doLoad();
    //    } else {
    //        setReady(true);
    //    }
    //}, []);

    useEffect(() => {
        if (props.showModal) {
            if (!!ENABLE_AUDIT_MESSAGES)
                console.log('handle mounted');

            let loadStatus = _coordinatorLoadStatus++;
            if (loadStatus === 0) {
                doLoad();
            } else {
                setReady(true);
            }
        }
    }, [props.showModal]);

    //
    // componentDidUpdate (after update for DOM work)
    //
    useEffect(() => {
        if (componentDidUpdateRef.current) {
            if (!!ENABLE_AUDIT_MESSAGES)
                console.log('handle after render');


        } else {
            componentDidUpdateRef.current = true;
        }
    });

    const encodeHtml = (txt) => {
        var el = document.createElement('textarea');
        el.innerText = txt
        return el.innerHTML;
    }

    //const decodeHtml = (html) => {
    //    var el = document.createElement('textarea');
    //    el.innerHTML = html;
    //    return el.value;
    //}

    const doLoadChannelsAsync = async (model) => {
        let activeSource = getSelectedSource(model);

        if (!(activeSource.api))
            return;
        if (!(activeSource.userProfile)) return;
        let userProfile = activeSource.userProfile;
        await activeSource.api.getChannelsFor(userProfile)
            .then(
                async (val) => {
                    try {
                        if (!(userProfile.channels)) {
                            userProfile.channels = {
                                data: [],
                                isFullyLoaded: true,
                                selectedIndex: -1
                            };
                        }
                        let targetList = userProfile.channels;
                        let targetData = targetList.data;
                        let sourceData = val.data;
                        userProfile.channels.raw = val;
                        for (let i = 0; i < sourceData.length; i++) {
                            let item = sourceData[i];
                            let found = false;
                            for (let i = 0; i < targetData.length; i++) {
                                if (item.id === targetData[i].id) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                targetData.push(item);
                            }
                        }

                        if (targetData.length > 0 && targetList.selectedIndex < 0) {
                            await doSelectChannelAsync(model, 0);
                        }

                        if (!!(val.paging)) {
                            targetList.paging = val.paging;
                            targetList.isFullyLoaded = false;
                        } else {
                            targetList.isFullyLoaded = true;
                            if (!!(targetList.paging)) {
                                delete targetList.paging;
                            }
                        }
                    }
                    catch (ex) {
                        activeSource.authorization = null;
                    }
                },
                (err) => {
                    activeSource.authorization = null;
                }
            );
    };

    const doLoadPostsAsync = async (model) => {
        let activeSource = getSelectedSource(model);

        let activeChannel = getSelectedChannel(model);
        if (!activeChannel?.posts?.isFullyLoaded) {
            await activeSource.api.getPostsFor(activeChannel)
                .then(
                    async (val) => {
                        try {
                            if (!(activeChannel.posts)) {
                                activeChannel.posts = {
                                    data: [],
                                    isFullyLoaded: false,
                                    selectedIndex: -1,
                                };
                            }
                            let targetList = activeChannel.posts;
                            let targetData = targetList.data;
                            let sourceData = val.data;

                            for (let i = 0; i < sourceData.length; i++) {
                                let sourceItem = sourceData[i];
                                targetData.push(sourceItem);
                            }

                            if (!!(val.paging)) {
                                targetList.paging = val.paging;
                            } else {

                                targetList.isFullyLoaded = true;
                                if (!!(targetList.paging)) {
                                    delete targetList.paging;
                                }
                            }
                        }
                        catch (ex) {
                            activeSource.authorization = null;
                            setError(true)
                            let modelString = (activeSource !== undefined && activeSource !== null) ? JSON.stringify(activeSource) : 'activeSource is undefined or null';

                            postRequest('/api/ErrorLogger/LogError', { error: ex.toString(), stack: "Coordinator.jsx doLoadPostsAsync() Try Catch - model - " + modelString});
                        }
                    },
                    (ex) => {
                        activeSource.authorization = null;
                        setError(true);
                        let modelString = (activeSource !== undefined && activeSource !== null) ? JSON.stringify(activeSource) : 'activeSource is undefined or null';

                        postRequest('/api/ErrorLogger/LogError', { error: ex.toString(), stack: "Coordinator.jsx doLoadPostsAsync() .Then Catch - model - " + modelString });
                    }
            );
        }
    };

    const doLoginAsync = async (model) => {
        let activeSource = getSelectedSource(model);
        activeSource.loginComplete = false;

        await setState({ ...model });

        if (!!(activeSource?.api)) {
            await activeSource.api.login()
                .then(
                    (authorization) => {
                        activeSource.authorization = authorization;
                        let modelString = (activeSource !== undefined && activeSource !== null) ? JSON.stringify(activeSource) : 'activeSource is undefined or null';
                        console.log(modelString);
                    },
                    (error) => {
                        activeSource.authorization = null;
                        setError(true);

                        let modelString = (activeSource !== undefined && activeSource !== null) ? JSON.stringify(activeSource) : 'activeSource is undefined or null';
                        postRequest('/api/ErrorLogger/LogError', { error: error.toString(), stack: "Coordinator.jsx doLoginAync() .Then Catch - model - " + modelString });
                    }
                )
            if (activeSource.authorization) {
                await activeSource.api.getUserProfile()
                    .then(
                        (userProfile) => {
                            activeSource.userProfile = userProfile;
                        },
                        (ex) => {
                            activeSource.userProfile = null;
                            activeSource.authorization = null;
                            setError(true);

                            let modelString = (activeSource !== undefined && activeSource !== null) ? JSON.stringify(activeSource) : 'activeSource is undefined or null';
                            postRequest('/api/ErrorLogger/LogError', { error: ex.toString(), stack: "Coordinator.jsx doLoginAync() .Then Catch - model - " + modelString });
                        }
                    );
            }
            activeSource.loginComplete = true;
        }
    }

    const doLogoutAsync = async (model) => {
        let activeSource = getSelectedSource(model);

        await activeSource.api.logout()
            .then(
                async (val) => {
                    delete activeSource.userProfile;
                    delete activeSource.authorization;
                },
                (err) => {
                    delete activeSource.userProfile;
                    delete activeSource.authorization;
                    console.error(err);
                }
        );
    }

    const handleRevokeAccess = async (e) => {
        handleLogoutAsync();
    }

    const doClearPostSelection = async (model) => {
        if (!(model?.sources?.data)) return;
        for (let i = 0; i < model.sources.data.length; i++) {
            let source = model.sources.data[i];
            let userProfile = source.userProfile;
            if (!(userProfile?.channels?.data)) continue;
            for (let j = 0; j < userProfile.channels.data.length; j++) {
                let channel = userProfile.channels.data[j];
                if (!(channel?.posts?.data)) continue;
                channel.posts.selectedIndex = -1;
            }
        }
    }

    const doSelectChannelAsync = async (model, selectedIndex) => {
        let activeSource = getSelectedSource(model);
        if (selectedIndex >= activeSource.userProfile.channels.data.length) {
            selectedIndex = activeSource.userProfile.channels.data.length - 1;
        }
        let previousSelectedIndex = activeSource.userProfile.channels.selectedIndex;
        if (previousSelectedIndex === selectedIndex) return;
        activeSource.userProfile.channels.selectedIndex = selectedIndex;
        let activeChannel = getSelectedChannel(model);
        if (!(activeChannel === activeSource.userProfile.channels.data[selectedIndex])) {
        }
        if (!(activeChannel?.posts)) {
            await doLoadPostsAsync(model);
        }
        doClearPostSelection(model);
    };

    const doSelectPost = async (model, selectedIndex) => {
        let activeChannel = getSelectedChannel(model);
        activeChannel.posts.selectedIndex = selectedIndex;

        let activePost = getSelectedPost(state);
        setPostCaption(activePost.content);
        setPreviewMode(selectedIndex >= 0);
    }

    const doSelectSourceAsync = async (model, selectedIndex) => {
        let sources = model.sources;
        if (selectedIndex !== sources.selectedIndex) {
            sources.selectedIndex = selectedIndex;
            doClearPostSelection(model);

            let activeSource = getSelectedSource(model);
            if (!(activeSource?.userProfile)) {
                await doLoginAsync(model);
            }
            if (!!(activeSource?.userProfile) && !(activeSource?.userProfile?.channels)) {
                await doLoadChannelsAsync(model);
            }
        } else {
            // not authorized
            if ((state.sources.data[selectedIndex].api?.isAuthorized ?? true) == false) {
                await doLoginAsync(model);
                await doLoadChannelsAsync(model);
            }
        }
    }

    const getSelectedChannel = (model) => {
        let selectedChannel = null;
        let selectedUserProfile = getSelectedUserProfile(model);
        if (!!(selectedUserProfile)) {
            let channels = selectedUserProfile.channels;
            if (!!channels) {
                let i = channels.selectedIndex;
                if (i >= 0) {
                    selectedChannel = channels.data[i];
                }
            }
        }
        return selectedChannel;
    };

    const getSelectedPost = (model) => {
        let selectedPost = null;
        let activeChannel = getSelectedChannel(model);
        if (!!(activeChannel)) {
            let posts = activeChannel.posts;
            if (!!posts) {
                let i = posts.selectedIndex;
                if (i >= 0) {
                    selectedPost = posts.data[i];
                }
            }
        }
        return selectedPost;
    }

    const getSelectedSource = (model) => {
        let selectedSource = null;
        if (!!(model?.sources)) {
            let sourceIndex = model.sources.selectedIndex;
            if (sourceIndex >= 0) {
                selectedSource = model.sources.data[sourceIndex];
            }
        }
        return selectedSource;
    };

    const getSelectedUserProfile = (model) => {
        let selectedSource = getSelectedSource(model);
        return selectedSource?.userProfile;
    };

    const handleLoadPostsAsync = async () => {
        let newState = { ...state };
        await doLoadPostsAsync(newState);
        setState(newState);
    }

    const handleLoginAsync = async (e) => {
        let newState = { ...state };
        await doLoginAsync(newState);
        await handleSelectSourceAsync(state.sources.selectedIndex);
        setState(newState);
    }

    const handleLogoutAsync = async (e) => {
        let newState = { ...state };
        await doLogoutAsync(newState);
        setState(newState);
    }

    const handleModalClosed = async () => {
        let newState = { ...state };

        // clear channels
        if (newState.sources.data[1].userProfile != null) {
            newState.sources.data[1].userProfile.channels = null;
        }

        if (newState.sources.data[2].userProfile != null) {
            newState.sources.data[2].userProfile.channels = null;
        }

        if (newState.sources.data[3].userProfile != null) {
            newState.sources.data[3].userProfile.channels = null;
        }

        if (newState.sources.data[4].userProfile != null) {
            newState.sources.data[4].userProfile.channels = null;
        }

        setState(newState);

        _coordinatorLoadStatus = 0;
        onModalClosed();
    }

    const handleSavePost = async () => {
        const route = '/api/post/insertSocialPost';
        let selectedPost = { ...getSelectedPost(state) };
        selectedPost.content = postCaption;

        selectedPost.raw = JSON.stringify(selectedPost.raw);

        if (selectedPost.embed !== undefined) {
            selectedPost.embeddedHtml = selectedPost.embed;
            delete selectedPost.embed;
        }
        setLoading(true);
        let data = await postRequest(route, selectedPost);
        _coordinatorLoadStatus = 0;
        setPreviewMode(!isPreviewMode);
        setLoading(false);

        history.push(`/Post/PostPageAuthor/${data.shareCode}`, { state: { justCreated: true } });
    }

    const handleSaveNativePost = async () => {
        //todo: make post
        let payload = {
            content: postCaption,
            status: 0,
            type: 3,
            user: {
                id: loggedInUser.id
            }
        }

        let sectorModels = [];
        for (let i = 0; i < nativePostSectors.length; i++) {
            nativePostSectors[i].id = nativePostSectors[i].sectorId;
            let model = { sector: nativePostSectors[i] };
            sectorModels.push(model);
        }

        payload.sectors = sectorModels;

        let url = `api/Post/CreateNativePost`;

        setLoading(true);

        let createdPost = await postRequest(url, payload);

        let postId = createdPost.id;

        //todo: upload post media
        let formData = new FormData();

        for (let i = 0; i < nativePostMedia.length; i++) {
            formData.append(`postImage${i}`, nativePostMedia[i])
        }

        let mediaUploadUrl = `api/Locker/uploadMedia?postId=${postId}&postUserId=${createdPost.user.id}`;
        let newProperties = await postRequest(mediaUploadUrl, formData, null, false);

        if (newProperties != null) {
            _coordinatorLoadStatus = 0;
            setPreviewMode(!isPreviewMode);
            history.push(`/Post/PostPageAuthor/${createdPost.shareCode}`, { state: { justCreated: true }});
        } else {
            let url = `/api/Locker/DeleteLocker`;
            await postRequest(url, createdPost);
            setError(true);
        }

        _coordinatorLoadStatus = 0;
        setLoading(false);        
    }

    const handleSelectChannelAsync = async (e) => {
        e.preventDefault();
        let selectedIndex = parseInt(e.target.id.replace('channel-', ''));
        let newState = { ...state };
        await doSelectChannelAsync(newState, selectedIndex);
        setState(newState);
    }

    const handleSelectPostAsync = (id) => {
        let newState = { ...state };
        let i = -1;
        var posts = getSelectedChannel(newState)?.posts;
        if (!!posts) {
            for (let j = 0; j < posts.data.length; j++) {
                let post = posts.data[j];
                if (post.id === id) {
                    i = j;
                    break;
                }
            }
        }

        doSelectPost(newState, i);
        setState(newState);
    }

    const handleSelectSourceAsync = async (selectedIndex) => {
        let newState = { ...state };
        await doSelectSourceAsync(newState, selectedIndex);
        setState(newState);
    }

    const handleTogglePreview = (e) => {
        setError(false);
        setPreviewMode(!isPreviewMode);
        setNativePostMedia([]);
    }

    const removeNativePostSector = (i) => {
        let updated = [...nativePostSectors];
        updated.splice(i, 1)
        setNativePostSectors(updated);
    }

    const addNativePostSector = (sector) => {
        let updated = [...nativePostSectors];
        updated.push(sector);
        setNativePostSectors(updated);
    }

    const handleSelectNativePostMedia = (files) => {
        setNativePostMedia(files);
        setPreviewMode(true);
    }
    //
    // render
    //
    let activeChannel = getSelectedChannel(state);
    let activePost = getSelectedPost(state);
    let activeSource = getSelectedSource(state);
    let activeUserProfile = activeSource?.userProfile;

    let clientId = !id ? 'post' : id;
    let hideModal = (showModal === undefined || showModal === null) ? false : !showModal;
    let searchClientId = `${clientId}-search`;
    let previewClientId = `${clientId}-preview`;

    let channels = activeUserProfile?.channels;
    let channelsData = channels?.data;

    let sources = state?.sources;

    let userImage = activeUserProfile?.imageUrl;

    const renderChannels = () => {
        let retVal = <></>;
        if (!!channelsData && channelsData.length > 1) {
            retVal = <>
                <div className="col-auto">
                    <div className="form-group m-0">
                        <div className="input-group-append">
                            <button id="channelBtn" className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{activeChannel?.name}</button>
                            <div className="dropdown-menu">
                                {
                                    channelsData.map((channel, i) => {
                                        return <a key={`channel-${i}`} className="dropdown-item" href='/' id={`channel-${i}`} onClick={handleSelectChannelAsync}>{channel.name}</a>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }
        return retVal;
    }

    const renderGear = () => {
        let retVal = <></>;
        if (showRemoveAccess) {
            retVal = <>
                <div className="col-auto">
                    <div className="form-group m-0">
                        <div className="input-group-append">
                            <button className="btn btn-outline-none dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <GearIcon width={24} height={24} />
                            </button>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href='/' onClick={(e) => { e.preventDefault(); handleRevokeAccess(); }}>Revoke Access</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }
        return retVal;
    }

    const renderPostCards = (postData) => {
        return (isPreviewMode
            ? postData.map((post, i) => {
                return <PreviewCard key={i}
                    post={post}
                    loggedInUser={props.loggedInUser}
                    deviceSize={props.deviceSize}
                    handleContentInput={(val) => { setPostCaption(val); }}
                />
            })
            : postData.map((post, i) => {
                return <ShareCard key={i}
                    post={post}
                    onSelected={handleSelectPostAsync}
                />
            })
        );
    }

    const renderPreviewPost = () => {
        let activeSource = getSelectedSource(state);
        let activePost = getSelectedPost(state);
        if (activePost) {
            let postsData = [activePost];

            var platformName = activeSource?.name ?? '';
            if (platformName === 'instagram' ||
                platformName === 'facebook' ||
                platformName === 'youtube' ||
                platformName === 'tiktok') {
                return (renderPostCards(postsData));
            }
            return <></>;
        } else {
            if (nativePostMediaUrls.length > 0) {

                let mediaItems = nativePostMediaUrls.map((val) => {
                    if (val.role === 1) {
                        return { videoLink: val.url }
                    }
                    else {
                        return { imageLink: val.url }
                    }

                });

                let postPreviewData = {
                    user: props.loggedInUser,
                    media: mediaItems
                };

                return <PreviewCard
                    handleContentInput={(val) => setPostCaption(val)}
                    post={postPreviewData}
                    loggedInUser={props.loggedInUser}
                    deviceSize={props.deviceSize}
                    captionValue={postCaption}
                />
            }
        }
    };

    const renderShareCards = (shareCards) => {
        return shareCards && shareCards.map((shareCard, i) => {
            return (<div key={i} className="share-card-wrapper col-auto px-1 py-1 bg-white">{shareCard}</div >)
        });
    }

    const renderPosts = () => {
        let activeSource = getSelectedSource(state);
        let activeChannel = getSelectedChannel(state);
        let postsData = activeChannel?.posts?.data;

        let platformName = activeSource?.name ?? '';

        if (platformName === "instagram" ||
            platformName === "facebook" ||
            platformName === "youtube" ||
            platformName === "tiktok") {

            let shareCards = !!postsData && renderPostCards(postsData);

            return (
                <InfiniteScroll
                    dataLength={postsData?.length ?? 0}
                    next={handleLoadPostsAsync}
                    hasMore={true}
                    scrollableTarget="share-post-modal-posts-wrapper"
                >
                    <div id="share-post-modal-posts">
                        {renderShareCards(shareCards)}
                    </div>

                </InfiniteScroll>);
        } 

        return (
            <CreateNativePost
                setNativePostMediaUrls={setNativePostMediaUrls}
                setNativePostCaption={setPostCaption}
                setNativePostMedia={handleSelectNativePostMedia}
                nativePostCaption={postCaption}
                selectedSectors={nativePostSectors}
                addSector={addNativePostSector}
                removeSector={removeNativePostSector}
                loggedInUser={props.loggedInUser}
                deviceSize={props.deviceSize}
            />
        );
    }

    const renderPreviewModal = () => {
        let retVal = <></>;
        let isSocialPost = ["instagram", "facebook", "youtube", "tiktok"].includes(activeSource?.name);
        if (isReady && !hideModal) {
            retVal = (
                <RPModal id={previewClientId} toggleModal={handleTogglePreview} isOpen={isPreviewMode} modalDialogCustomClass="modal-dialog-md pb-0" modalBodyCustomClass="modal-body py-0 px-0 mt-0" showHeader={false} modalHeader={<></>} ignoreClickAway={true}>
                    {renderPreviewPost()}
                    <ModalFooter className="py-2">
                        <Button id="buttonId" color="secondary" onClick={handleTogglePreview}>Cancel</Button>
                        {!error ?
                            <ActionButton
                                isLoading={loading}
                                className="btn btn-primary"
                                onClick={isSocialPost === true ? handleSavePost : handleSaveNativePost}
                            >
                                Next
                        </ActionButton> : null}
                        
                    </ModalFooter>
                </RPModal>
            );
        }
        return retVal;
    }

    const renderRedirect = () => {
        let retVal = <></>;
        if (!!redirect) {
            retVal = <Redirect to={{
                pathname: `/Post/PostPageAuthor/${redirect}`, state: {
                    justCreated: true
                }
            }} />;

            const fireCloseModal = async () => {
                setTimeout(() => onModalClosed(), 10);
            }
        }
        return retVal;
    }

    const renderSearchModal = () => {
        let retVal = <></>;
        let isSocialPost = ["instagram", "facebook", "twitter", "tiktok"].includes(activeSource?.name);
        if (!hideModal) {
            retVal = (
                <Modal id={searchClientId} isOpen={!isPreviewMode} modalClassName='share-post-modal' className='modal-xl' scrollable={false} toggle={onModalClosed} style={{ marginTop: "100px"}}>
                    <div className="modal-header">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-auto mr-auto">
                                    <h4 className="m-0">Share a Post</h4>
                                </div>
                                {renderChannels()}
                                {renderGear()}
                            </div>
                        </div>
                    </div>
                    <ModalBody>
                        <div id="share-post-modal-body" className='d-flex flex-column flex-md-row m-sm-0 m-md-3' onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                setPreviewMode(false);
                            }
                        }}>
                            <div id="share-post-modal-sources" className='d-flex justify-content-center flex-md-column justify-content-md-start'>
                                <Sources
                                    context={sources}
                                    onSourceChanged={handleSelectSourceAsync}
                                    onLogin={handleLoginAsync}
                                    onLogout={handleLogoutAsync}
                                    isReady={isReady}
                                />
                            </div>
                            <div id="share-post-modal-posts-wrapper" className='w-100'>
                                {renderPosts()}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleModalClosed}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            );
        }
        return retVal;
    }

    return <>
        <div id="errorDiv" style={{ position: 'absolute', top: '50%', left: '50%'}}></div>
        {renderPreviewModal()}
        {renderSearchModal()}
        {error ?
            <Popover placement="bottom" isOpen={error} target="errorDiv" trigger="legacy" hideArrow={true} toggle={() => { setError(false); } }>
                <PopoverHeader>
                    Error
                </PopoverHeader>
                <PopoverBody>
                    Sorry an unexpected error occurred.  Please try again.
            </PopoverBody>
            </Popover>
            : null}
    </>
}

Coordinator.propTypes = {
    id: PropTypes.string,
    showModal: PropTypes.bool,
    onModalClosed: PropTypes.func.isRequired
};