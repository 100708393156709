import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import InformationIcon from "components/icons/InformationIcon";
import Select from 'react-select'
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import _ from "lodash";
import TextField from '@material-ui/core/TextField';
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "./LoadSpinner";
import AnalyticsAggregateTable from "components/AnalyticsAggregateTable";

const { DateTime } = require("luxon");


class AnalyticsChart extends Component {
    constructor(props) {
        super(props);        

        this.state = {  
            series: [                
                {
                    name: 'Clicks',
                    type: 'line',
                    data: []
                },
                {
                    name: 'Sales Count',
                    type: 'line',
                    data: []
                },
                {
                    name: 'Estimated Commmissions',
                    type: 'bar',
                    data: [],
                }
            ],
            options: {
                chart: {
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                markers: {
                    size: [1, 1, 1],
                },
                stroke: {
                    width: [2, 2, 2]
                },
                labels: [],
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [
                    {
                        seriesName: 'Clicks',
                        title: {
                            text: 'Count'
                        },
                        labels: {
                            // Formats the number displayed on the y-axis label and in the tooltip
                            formatter: function (val) {
                                return val.toFixed(0);
                            }
                        }
                    },
                    {
                        seriesName: 'Clicks',
                        show: false,
                        labels: {
                            // Formats the number displayed on the y-axis label and in the tooltip
                            formatter: function (val) {
                                return val.toFixed(0);
                            }
                        }
                    },
                    {
                        seriesName: 'Estimated Commmissions',
                        opposite: true,
                        title: {
                            text: '$',
                        },
                    }
                ]
            },
        };
    }

    componentDidMount = async () => {
        this.props.getAnalytics();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            if (this.props.startDate <= this.props.endDate) {
                this.setState({ dateError: false });
                this.props.getAnalytics();
            } else {
                this.setState({ dateError: true });
            }
        }
        if (prevProps.display != this.props.display && this.props.display) {
            this.props.getAnalytics();
        }
        if (prevProps.userChanged != null && prevProps.userChanged != this.props.userChanged && this.props.userChanged)
            if (prevProps.loggedInUser !== this.props.loggedInUser) {
                this.props.getAnalytics();
            }
        if (prevProps.aggregateChanged != null && prevProps.aggregateChanged !== this.props.aggregateChanged && this.props.display){
            this.props.getAnalytics();
        }
        if (prevProps.browseChanged !== this.props.browseChanged) {
            this.props.getAnalytics();
        }
    }

    displayTableRows = () => {
        if (this.props.analytics.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="7" style={{ textAlign: "center" }}>No data for this time frame</td>
                </tr>
            );
        }

        if (this.props.aggregateChanged != null && this.props.aggregateChanged) {
            return this.props.analytics.map((data, i) => {
                return (
                    <tr key={i}>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{convertToNumericMonthAndDayAndYear(new Date(data.date), false)}</span>
                        </td>
                        {

                        }

                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.productClicks}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.salesCount}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.commissionsEarned.toFixed(2)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.username}</span>
                        </td>
                    </tr>);
            }).reverse();
        }

        return this.props.analytics.map((data, i) => {
            if (data.productClicks > 0 || data.salesCount > 0 || data.commissionsEarned > 0) {
                return (
                    <tr key={i}>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{convertToNumericMonthAndDayAndYear(new Date(data.date), false)}</span>
                        </td>
                        {

                        }

                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.productClicks}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.salesCount}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.commissionsEarned.toFixed(2)}</span>
                        </td>
                    </tr>);
            }
        }).reverse();
    
            
    }

    displayTotalRow = () => {
        if (this.props.analytics.length > 0) {
            let totals = { totalProductClicks: 0, merchantRedirects: 0, salesCount: 0, estimatedCommission: 0 };

            this.props.analytics.forEach(val => {
                totals.totalProductClicks = totals.totalProductClicks + val.productViews;
                totals.merchantRedirects = totals.merchantRedirects + val.productClicks;
                totals.salesCount = totals.salesCount + val.salesCount;
                totals.estimatedCommission = totals.estimatedCommission + val.commissionsEarned;
            })

            return (
                <tr>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span><strong>Totals</strong></span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>
                            <strong>{totals.merchantRedirects}</strong>
                        </span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>
                            <strong>{totals.salesCount}</strong>
                        </span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>
                            <strong>${totals.estimatedCommission.toFixed(2)}</strong>
                        </span>
                    </td>
                </tr>
                )
        }
    }

    displayTableHeader = () => {
        let headers = this.props.aggregateChanged != null && this.props.aggregateChanged ? ["Date", "Clicks", "Sales Count", "Estimated Commission", "Username"] : ["Date", "Clicks", "Sales Count", "Estimated Commission"];
        let infoIcon = ["", "A User has clicked a Buy Now link that redirected them to a retailer's site", "A User placed an order with a retailer after clicking one of your Buy Now links", "The estimated commission earned across all orders. See RockPorch Terms of Service for more detail"]

        return headers.map((header, i) => {
            return (
                <th key={i} style={{ position: "relative", minWidth: "0", paddingLeft: "4px", paddingRight: "4px", minWidth: this.props.deviceSize === "sm" && (i === 1 || i === 2) ? "95px" : ["lg","xl"].includes(this.props.deviceSize) && i == 1 ? "160px" : ""}} >
                    <strong>{header}</strong>
                    {i > 0 ?
                        <>
                        <small className="d-inline-block ml-1">
                            <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white", position: "absolute", top: "1px", right: "5px" }} id={'infoIcon' + i}><InformationIcon /></button>
                        </small>
                        <UncontrolledPopover
                            placement={i === 5 ? 'left' : 'bottom' }
                            target={'infoIcon' + i}
                            trigger="legacy"
                        >
                                <PopoverBody>{infoIcon[i]}</PopoverBody>
                        </UncontrolledPopover>
                        </>
                        : null}

                </th>
            )
        })
    }

    getDaysBetweenDates = function (start, end) {
        for (var arr = [], dt = new DateTime.fromISO(start, { zone: 'utc' }); dt < end; dt = dt.plus({ days: 1 })) {
            const date = dt.toJSDate();
            const offset = date.getTimezoneOffset();

            const utcDate = new Date(date.getTime() + (!!(offset) ? offset : 0) * 60000);
            arr.push(utcDate);
        }
        return arr;
    };

    displayChart = () => {
        var data = [];
        var labels = [];

        const dateRange = {
            startDate: this.props.startDate ?? DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: this.props.endDate ?? DateTime.utc().startOf('day'),
        }
        var dates = this.getDaysBetweenDates(dateRange.startDate, dateRange.endDate);
        dates.forEach(date => {
            const formattedDate = convertToNumericMonthAndDayAndYear(date, false);

            if (!data.find(x => x.date == formattedDate)) {
                data.push({
                    date: formattedDate,
                    postViews: 0,
                    productViews: 0,
                    productClicks: 0,
                    salesCount: 0,
                    commissionsEarned: 0
                });

                const formattedLabelDate = convertToNumericMonthAndDayAndYear(date, false);
                labels.push(formattedLabelDate);
            }
        });

        this.props.analytics.forEach(analytic => {
            const formattedDate = convertToNumericMonthAndDayAndYear(new Date(analytic.date), false);
            const item = _.find(data, (item) => item?.date === formattedDate);
            if (item) {
                item.postViews += analytic.postViews;
                item.productViews += analytic.productViews;
                item.productClicks += analytic.productClicks;
                item.salesCount += analytic.salesCount;
                item.commissionsEarned += analytic.commissionsEarned;
            }
        });

        const { options, series } = this.state;
        series[0].data = data.map((item) => item.productClicks);
        series[1].data = data.map((item) => item.salesCount);
        series[2].data = data.map((item) => item.commissionsEarned);
        options.labels = labels;

        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="line" />
            </div>
        );
    }

    render() {
        return (
            <div className="container pt-0 pb-5 px-0 px-md-3" style={this.props.display ? {} : { display: 'none' }}>              
                {
                    this.props.analyticsLoading ?
                        <LoadSpinner noPaddingTop={true} /> :
                        <>
                            {this.displayChart()}
                            {this.props.aggregateChanged ?
      
                                <AnalyticsAggregateTable
                                    analyticsData={this.props.analytics?.sort((a, b) => new Date(b.date) - new Date(a.date))}
                                />
                                :
                                <div className="row mx-0 my-0">
                                    <div className="table-responsive commission-chart-top-table">
                                        <table className="table">
                                            {this.props.children != null && this.props.aggregateChanged ?
                                                <colgroup>
                                                    <col span="1" style={{ width: "12.5%" }} />
                                                    <col span="1" style={{ width: "12.5%" }} />
                                                    <col span="1" style={{ width: "12.5%" }} />
                                                    <col span="1" />
                                                    <col span="1" style={{ width: "12.5%" }} />
                                                </colgroup>
                                                :
                                                <colgroup>
                                                    <col span="1" style={{ width: "16%" }} />
                                                    <col span="1" style={{ width: "16%" }} />
                                                    <col span="1" style={{ width: "16%" }} />
                                                    <col span="1" />
                                                </colgroup>
                                            }

                                            <thead>
                                                <tr>
                                                    {this.displayTableHeader()}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.displayTableRows()}
                                                {this.displayTotalRow()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </>
                }
            </div>
        );
    }
}

export default AnalyticsChart;