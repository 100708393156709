import React from "react";
import Select from 'react-select';
import AccountBanner from "../../components/AccountBanner"
import { getRequest, postRequest, postSpreadsheet } from "sharedUtils/httpUtils";
import ActionButton from "components/ActionButton";
import CategoryCreation from "components/CategoryCreation";
import CategoryProductAssignment from "components/CategoryProductAssignment";
import ProductsWithoutCategoriesExcel from "components/ProductsWithoutCategoriesExcel";
import UploadProductCategories from "components/UploadProductCategories";



class Category extends React.Component {

    state = {
        selectedCase: 1,
        categories: [],
        selectedCategories: [],
        level: 1,
        category: {
            name: "",
        },
        savingCategory: false,

        // Product Category State
        products: [],
        product: null,
        levelPC: 1,
        selectedCategoriesPC: [{ id: 0, name: "None" }],
        savingProductCategory: false,
        finishedFetchingProducts: false,
        fetchingExcel: false,
        postingExcel: false,
        excelError: false,
        postExcelError: false,
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Category', text: 'Category' }];
    categoryCreationBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Category', text: 'Category Creation', active: true }
    ];
    categoryToProductBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Category', text: 'Category to Product Assignment', active: true }
    ];

    productsWithoutCategoriesExcelBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/Category', text: 'Products Without Categories Spreadsheet', active: true }
    ];

    UploadProductCategories = [
        ...this.breadCrumbList,
        { link: '/Admin/Category', text: 'Upload Product Categories Spreadsheet', active: true }
    ];
    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.fetchCategories();
        this.fetchProductsWithoutCategories();
    }

    fetchProductsWithoutCategories = async () => {
        const url = `/Products/GetProductsWithoutCategories?skip=0&take=24`;
        const products = await getRequest(url);

        this.setState({ products });
    }

    fetchMore = async () => {
        let skip = this.state.products?.length;

        const url = `/Products/GetProductsWithoutCategories?skip=${skip}&take=24`;
        const newProducts = await getRequest(url);

        if (newProducts == null || newProducts == undefined || newProducts?.length === 0) {
            this.setState({ finishedFetchingProducts: true });
        } else {
            const products = [...this.state.products, ...newProducts]
            this.setState({ products });
        }
    }

    getProductsWithoutCategoriesExcel = async () => {
        this.setState({ fetchingExcel: true });
        const url = `/Products/GetProductsWithoutCategoriesExcel`;
        const response = await getRequest(url);

        if (response) {
            this.setState({ excelError: false, fetchingExcel: false });
        } else {
            this.setState({ excelError: true, fetchingExcel: false });
        }
    }

    handleUpload = async (file) => {
        this.setState({ postExcelError: false, postingExcel: true });

        const formData = new FormData();
        formData.append("file", file);

        const response = await postSpreadsheet("/Products/UploadProductCategoriesExcel", formData);

        this.setState({ postExcelError: !(response), postingExcel: false });
    };



    displayToggleButtons = () => {
        let options = [
            {
                name: "Category Creation",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Category to Product Assignment",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Products Without Categories Spreadsheet",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
            {
                name: "Upload Product Categories Spreadsheet",
                case: 4,
                action: () => this.setState({ selectedCase: 4 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    saveProductCategory = async () => {
        let validProductId = Number.isInteger(parseInt(this.state.productCategory.productId.trim())) && parseInt(this.state.productCategory.productId.trim()) > 0;

        let validCategoryId = Number.isInteger(parseInt(this.state.productCategory.categoryId.trim())) && parseInt(this.state.productCategory.categoryId.trim()) > 0;


        if (validProductId && validCategoryId) {
            this.setState({ productCategorySaving: true })

            const productCategory = this.state.productCategory;

            const url = `api/Sector/PostProductCategory`;

            const newProductCategory = await postRequest(url, { productId: productCategory.productId.trim(), categoryId: productCategory.categoryId.trim() });
            productCategory.categoryId = "";
            productCategory.productId = "";

            this.setState({ productCategory, productCategorySaving: false });

        }

        if (!validProductId) {
            this.setState({ productCategoryValidProductId : false })
        }

        if (!validCategoryId) {
            this.setState({ productCategoryValidCategoryId: false })
        } 
    }

    /// NEW REFACTORED CODE STARTS HERE ///

    fetchCategories = async () => {
        let url = `/api/Sector/GetCategories?showAll=true`;
        const categories = await getRequest(url);

        const selectedCategories = [{ id: 0, name: "None" }];
        const category = {
            name: "",
        };
        this.setState({ categories, selectedCategories, category, level: 1 });
    }

    handleCategoryName = (e) => {
        let category = this.state.category;
        category.name = e.target.value;
        this.setState({ category });
    }

    saveCategory = async () => {
        this.setState({ savingCategory: true })

        const category = this.state.category;
        category.name = category.name.trim();
        if (this.state.selectedCategories.length > 1) {
            category.parentCategoryId = this.state.selectedCategories[this.state.selectedCategories.length - 1].id;
        }

        const url = `api/Sector/PostCategory`;
        const newCategory = await postRequest(url, category);
        this.setState({ savingCategory: false })

        this.fetchCategories();
    }

    handleBreadcrumbClick = index => {
        let selectedCategories = this.state.selectedCategories;
        let level = this.state.level;

        for (let i = selectedCategories.length - 1; i > index; i--) {
            selectedCategories.pop();
            level--;
        }
        this.setState({ selectedCategories, level });
    }
    /// NEW CODE ENDS HERE ///

    handleProductsTableClick = product => {
        this.setState({ product })
    }


    handleProductCategoryTableClick = (category) => {
        let selectedCategoriesPC = [...this.state.selectedCategoriesPC];
        selectedCategoriesPC.push(category);

        this.setState({ selectedCategoriesPC, levelPC: this.state.levelPC + 1 });
    }

    handleCategoryTableClick = (category) => {
        let selectedCategories = [...this.state.selectedCategories];
        selectedCategories.push(category);

        this.setState({ selectedCategories, level: this.state.level + 1 });
    }

    handleBreadcrumbClickPC = index => {
        let selectedCategoriesPC = this.state.selectedCategoriesPC;
        let levelPC = this.state.levelPC;

        for (let i = selectedCategoriesPC.length - 1; i > index; i--) {
            selectedCategoriesPC.pop();
            levelPC--;
        }
        this.setState({ selectedCategoriesPC, levelPC });
    }

    saveProductCategory = async () => {
        this.setState({ savingProductCategory: true });

        const productCategory = {
            productId: this.state.product.productId,
            categoryId: this.state.selectedCategoriesPC[this.state.selectedCategoriesPC.length - 1].id,
        };
        const url = `api/Sector/PostProductCategory`;
        const newProductCategory = await postRequest(url, productCategory);

        let products = this.state.products.filter(p => p.productId != productCategory.productId);
        this.setState({ products, product: null, levelPC: 1, selectedCategoriesPC: [{ id: 0, name: "None" }], savingProductCategory: false });
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.state.selectedCase === 1 ? this.categoryCreationBreadCrumbList :
                        this.state.selectedCase === 2 ? this.categoryToProductBreadCrumbList : this.state.selectedCase === 3 ? this.productsWithoutCategoriesExcelBreadCrumbList : this.breadCrumbList}>
                        <h1 className="mb-4">Category Management</h1>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {this.state.selectedCase === 1 ?
                    <CategoryCreation
                        categories={this.state.categories}
                        category={this.state.category}
                        selectedCategories={this.state.selectedCategories}
                        level={this.state.level}
                        savingCategory={this.state.savingCategory}
                        saveCategory={this.saveCategory}
                        handleCategoryName={this.handleCategoryName}
                        handleBreadcrumbClick={this.handleBreadcrumbClick}
                        handleCategoryTableClick={this.handleCategoryTableClick}
                    /> :
                    this.state.selectedCase === 2 ?
                        <CategoryProductAssignment
                            products={this.state.products}
                            categories={this.state.categories}
                            handleProductsTableClick={this.handleProductsTableClick}
                            product={this.state.product}
                            level={this.state.levelPC}
                            selectedCategories={this.state.selectedCategoriesPC}
                            handleProductCategoryTableClick={this.handleProductCategoryTableClick}
                            handleBreadcrumbClick={this.handleBreadcrumbClickPC}
                            savingProductCategory={this.state.savingProductCategory}
                            saveProductCategory={this.saveProductCategory}
                            fetchMore={this.fetchMore}
                            finishedFetchingProducts={this.state.finishedFetchingProducts}
                        /> :
                        this.state.selectedCase === 3 ?
                        <ProductsWithoutCategoriesExcel
                            error={this.state.excelError}
                            isLoading={this.state.fetchingExcel}
                            getProductsWithoutCategoriesExcel={this.getProductsWithoutCategoriesExcel}
                        />
                            :
                        <UploadProductCategories
                            handleUpload={this.handleUpload}
                            error={this.state.postExcelError}
                            isLoading={this.state.postingExcel}
                        />
                }
            </>
        )
    }
}

export default Category;