var accountPaths = [
    { Name: "Dashboard", Path: "/Account/Dashboard" },
    { Name: "My Saves", Path: "/Account/Storage" },
    { Name: "My Products", Path: "" },
    { Name: "Edit Profile", Path: "/Account/EditProfile" },
    { Name: "Account Settings", Path: "/Account/AccountSettings" },
    { Name: "Communication Settings", Path: "/Account/CommunicationSettings" },
    { Name: "Notifications", Path: "/Account/NotificationsAndMessages" },
    { Name: "Messages", Path: "/Account/NotificationsAndMessages" },
    { Name: "Go Pro", Path: "" }
];

var postPaths = [
    { Name: "New Post Page", Path: "" },
    { Name: "Post Page - In Progress", Path: "" },
    { Name: "Post Page - Newly Created", Path: "" },
    { Name: "Post Modal", Path: "" },
    { Name: "Post Page Author", Path: "/Post/PostPageAuthor" },
    { Name: "Post Page", Path: "/Post/PostPage/asfgklnwep" },
    { Name: "Share a social post Modal - Instgram", Path: "/Post/Create" },
    { Name: "Share a social post Modal - Upload", Path: "/Post/Create" },
    { Name: "Share a social post modal - Peek", Path: "/Post/Create" }
];

var authPaths = [
    { Name: "Sign Up", Path: "/Account/Register" },
    { Name: "Sign In", Path: "" },
    { Name: "Reset Password", Path: "" },
    { Name: "Onboard - Interests", Path: "" },
    { Name: "Onboard - Go Pro", Path: "" },
    { Name: "Onboard - Who to follow", Path: "/Onboard/WhoToFollow" },
];

var productPaths = [
    { Name: "Add Product 01", Path: "/Product/AddProductModal" },
    { Name: "Add Product 02", Path: "" },
    { Name: "Add Product 04", Path: "" },
    { Name: "Add Product 05", Path: "" },
    { Name: "Mobile Product Card", Path: "" },
    { Name: "Product Modal", Path: "/Product/ProductModal" },
    { Name: "Product Page", Path: "/Product/ProductPage/eoSZIJcEB6" },
    { Name: "Buy", Path: "" }
];

var feedPaths = [
    { Name: "My Home Feed", Path: "/HomeFeed" },
    { Name: "Explore Feed", Path: "/Explore" },
    { Name: "Search typeahead for recent searches", Path: "" },
    { Name: "Search Results", Path: "/SearchResults/cricket" },
];

var profilePaths = [
    { Name: "My Profile", Path: "/Profile/samuel.obembe", },
    { Name: "Activity Feed - myself", Path: "" },
    { Name: "Lockers - myself", Path: "" },
    { Name: "Products - myself", Path: "" },
    { Name: "Locker Product Note - myself", Path: "" },
    { Name: "About - myself", Path: "" },
    { Name: "Activity Feed - another user", Path: "" },
    { Name: "Lockers - another user", Path: "" },
    { Name: "Products - another user", Path: "" },
    { Name: "Locker Product Note - another user", Path: "" },
    { Name: "About - another user", Path: "" },
    { Name: "Follow", Path: "" },
    { Name: "Comment", Path: "" },
];

var lockerPaths = [
    { Name: "Create Locker - Modal 1", Path: "" },
    { Name: "Create Locker - Modal 2", Path: "" },
    { Name: "New Locker Page", Path: "/Locker/NewLockerPage" },
    { Name: "My Products Modal Selector", Path: "" },
    { Name: "Locker Modal", Path: "" },
    { Name: "Locker Page", Path: "/Locker/LockerPage" },
    { Name: "Comments Popup - Create(Author View)", Path: "" },
    { Name: "Comments Popup - Edit(Author View)", Path: "" },
    { Name: "Comments Popup - (Non-Author View)", Path: "" }
];

export default { accountPaths, postPaths, authPaths, productPaths, feedPaths, profilePaths, lockerPaths, };
