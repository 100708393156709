import React from "react";
import { Link } from "react-router-dom";
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";
import ImageWrapper from "components/ImageWrapper";
import LikeIcon from "./icons/LikeIcon";
import { convertToLocaleDateTimeString } from "sharedUtils/timeUtils"
import { connect } from "react-redux";

/**
 * Comment structure
 * @typedef {Object} Comment
 * @property {comment} text - comment string
 * @property {string} postedBy
 * @property {Date} postedTime
 * @property {number} likes
 * @property {string} profilePicture
 * @property {Array<Comment>} replies
 * @property {boolean} [isReply]
 */

/**
 * @param {{comment: Object, loggedInUser: Object, likeAction: function, commentKey:Number}} props
 */
const CommentTile = (props) => {

    let renderReplies = () => {
        if (props.replies != null && props.replies.length > 0) {
            return props.replies.map((reply, i) => {
                return (
                    <CommentTile
                        text={reply.text}
                        postedBy={reply.postedBy}
                        postedTime={reply.createdOn}
                        likes={reply.likes}
                        profilePicture={reply.profilePicture}
                        replies={reply.replies}
                        key={i}
                        isReply={true} />
                )
            })
        }
    }

    let isReplyClassName = () => {
        if (props.isReply && props.isReply === true) {
            return "reply-block mb-4 pl-4"
        }
        else {
            return "main-comment mb-1"
        }
    }

    let renderShowReplies = () => {
        if (props.replyCount === 1) {
            return <div><Link to="/" className="reply-link" onClick={(e) => { e.preventDefault(); }}>Show reply</Link></div>
        } else if (props.replyCount > 1) {
            return <div><Link to="/" className="reply-link" onClick={(e) => { e.preventDefault(); }}>Show {props.replyCount} replies</Link></div>
        }
    }

    let renderLikes = () => {
        if (props.loggedInUser) {
            return (
                <Link to="#" onClick={(e) => { e.preventDefault(); props.likeAction(props.comment, props.commentKey); }}>
                    <span className="comment-count float-right d-flex align-items-center">
                        <LikeIcon width="20" height="20" fill={props.comment.likedByUser === true ? "black" : "none"} />
                        {props.comment.likes || 0}
                    </span>
                </Link>
            );
        } else {
            return (
                <Link to="#" style={{ pointerEvents: "none" }} onClick={(e) => { e.preventDefault(); }}>
                    <span className="comment-count float-right d-flex align-items-center">
                        <LikeIcon width="20" height="20" fill={props.comment.likedByUser === true ? "black" : "none"} />
                        {props.comment.likes || 0}
                    </span>
                </Link>
            );
        }
    }

    let handleTextMarkup = (text) => {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal, true);
            }
            else {
                return retVal;
            }
        });

        return markupHandled;
    }

    let url = props.brandNavState ? "/Profile/" + props.comment.user?.userName + "?showBrandNav=true" : "/Profile/" + props.comment.user?.userName;

    return (
        <>
            <div className={isReplyClassName()}>
                <div className="comment-detail row mt-0 mx-0">

                    <div className="col-10 col-lg-11 pl-0 pr-0">

                        <Link to={url}>

                            {
                                props.comment.user?.imageUrl ? (
                                    <ImageWrapper className="user-image" src={props.comment.user?.imageUrl} alt="" style={{width:"18px",height:"18px"}}/>
                                ) : (
                                        <ImageWrapper className="user-image" src="/images/avatar2.svg" alt="" style={{ width: "18px", height: "18px" }}/>
                                    )
                            }

                            <span className="comment-tile-text ml-2" style={{ color: "black" }}>
                                <strong className="pr-1">
                                    {props.comment.user.userName}
                                </strong>
                                {"  "}
                            </span>
                        </Link>

                        {handleTextMarkup(props.comment.text)}
                        {/*handleCommentMarkup(props.comment.text)*/}
                        <span className="time d-flex align-items-center">
                            <small>{convertToLocaleDateTimeString(props.comment.createdOn)}</small>
                        </span>
                    </div>


                    <div className="flex pr-0" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        {renderLikes()}
                        
                    </div>

                </div>

                {renderShowReplies()}
            </div>
            {renderReplies()}
        </>

    )
}

function mapStateToProps(storeState, ownProps) {
    return { brandNavState: storeState.brandNavReducer.brandNavState }
}


export default connect(mapStateToProps)(CommentTile);