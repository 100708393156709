import React from "react";
import RightArrowIcon from './icons/RightArrowIcon';
import LeftArrowIcon from './icons/LeftArrowIcon';

/**
 * 
 * @param {{images:string[]}} props 
 */
const Carousel = (props) => {
    const mapImages = () => {
        return props.images.map((imgUrl, i) => {
            return (
                <div className="carousel-item active" key={i}>
                    <img src={imgUrl} alt="product" className="d-block w-100" />
                </div>
            )
        })
    }

    const carouselIcons = () => {
        if (props.images.length > 1) {
            return (
                <>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                            <LeftArrowIcon />
                        </span>
                        <span className="sr-only">Previous</span>
                    </a>

                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true">
                            <RightArrowIcon />
                        </span>
                        <span className="sr-only">Next</span>
                    </a>
                </>
            )
        }
    }
    return (
        <div id="carouselExampleControls" className={`carousel slide ${props.className}`} data-ride="carousel">
            <div className="carousel-inner">
                {mapImages()}
            </div>
            {carouselIcons()}
        </div>
    )
}

export default Carousel;