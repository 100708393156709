import React from "react";
import { getRequest } from "sharedUtils/httpUtils";
import ProfileHeader from "./ProfileHeaderEmbed";


class ProfileEmbed extends React.Component {
    state = {
        userInformation: {
            id: 0,
            proStatusId: 0,
            bio: "",
            name: "",
            imageUrl: "",
            tagLine: "",
            userName: "",
            followers: 0,
            following: 0,
            merchantProducts: {
                $values: []
            },
            sectors: {
                $values: []
            }
        },
        loggedInUser: {},
        isLoading: true,
    }

    async componentDidMount() {
        this.props.setShowTopNav(false);
        let userName = !!(this.props.brandUsername) ? this.props.brandUsername : this.props.match?.params?.userName;

        let user = await this.fetchUserInformation(userName);
        if (user == undefined || user == null) {
        //    this.props.history?.push("/NotFound")
        }
        else {
            this.setState({ userInformation: user });
            this.setState({ isLoading: false });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!(this.props.brandUsername) && this.props.match?.params?.userName !== prevProps.match?.params?.userName) {
            this.props.closeAllModals();

            this.setState({
                isLoading: true,
                }, async () => {
                let user = await (this.fetchUserInformation(this.props.match.params.userName));
                if (user === undefined || user == null) {
                    this.props.history.push("/NotFound");
                }
                else {
                    this.setState({ userInformation: user });
                    this.setState({ isLoading: false })
                }
            })
        }
    }

    fetchUserInformation = async (username) => {
        let url = `/api/account/getProfile?userName=${username}`;
        let data = await getRequest(url);
        if (data == null || data == undefined) {
            return null;
        }
        else {
            this.setState({ userInformation: data }, async () => {
                if (data.proStatusId !== 1) {
                    this.setState({ selectedCase: 5 });
                }
            });
            return data;
        }
    }

    render() {
        let { isLoading } = this.state;
        if (isLoading === true) {
            return <></>
        }
        else if (this.state.userInformation === "notFound") {
            return <h1>This user does not exist</h1>
        }
        else {
            return (
                <section>
                    <ProfileHeader
                        deviceSize={this.props.deviceSize}
                        userInformation={this.state.userInformation}
                        screenWidth={this.props.screenWidth}
                    />
                </section>
            )
        }
    }
}

export default ProfileEmbed;