import React, { useState, useRef, useEffect } from "react";
import ImageWrapper from "components/ImageWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleShareModal, togglePostPageModal, toggleBlogPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { trimProductsArray, getProperties } from "sharedUtils/postFunctions";
import PinIcon from "components/icons/PinIcon";
import { useSwipeable } from "react-swipeable";
import UserTileGeneral from "components/UserTileGeneral";
import { getLockerShareLink } from "../../sharedUtils/shareLinkPrefixes"

/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const LockerFeedPostGridItem = (props) => {
    let history = useHistory();
    let [currentDisplayedMedia, setMedia] = useState(0);
    const [productPage, setProductPage] = useState(0);
    const [contentOverlowed, setContentOverflowed] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [titleOverflowed, setTitleOverFlowed] = useState(0);
    const [titleExpanded, setTitleExpanded] = useState(false);

    const pRef = useRef(null);
    const titleRef = useRef(null);

    const { shareCode, title, productItems, properties, type, postedOn } = props.post;
    const shareLink = getLockerShareLink(shareCode);
    const trimmedProducts = trimProductsArray(productItems.$values).sort(function (a, b) {
        return a.ordinal - b.ordinal;
    }).map((i) => { return i; });
    const trimmedProperties = getProperties(properties);

    const productPageHandlers = useSwipeable({
        onSwipedLeft: () => nextPage(),
        onSwipedRight: () => prevPage(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    useEffect(() => {
        if (pRef.current) {
            const lineHeight = parseInt(getComputedStyle(pRef.current).lineHeight, 10);
            const maxHeight = lineHeight * 4 + 20;
            const isOverflowing = pRef.current.offsetHeight > maxHeight;
            setContentOverflowed(isOverflowing);
        }
        if (titleRef.current) {
            const lineHeight = parseInt(getComputedStyle(titleRef.current).lineHeight, 10);
            const maxHeight = lineHeight * 2;
            const isOverflowing = titleRef.current.offsetHeight > maxHeight;
            setTitleOverFlowed(isOverflowing);
        }
    }, []);

    const prevPage = () => {
        if (trimmedProducts.length > 3) {
            let page = productPage - 1;
            if (page < 0) {
                page = Math.floor((trimmedProducts.length - 1) / 3);
            }

            setProductPage(page);
        }
    }

    const nextPage = () => {
        if (trimmedProducts.length > 3) {
            let page = productPage + 1;
            if (page * 3 >= trimmedProducts.length) {
                page = 0;
            }

            setProductPage(page);
        }
    }

    const mediaItems = trimmedProperties
        .filter(property => property.role === 0 || property.role == 1 || property.role === 8)
        .map(property => {
            return {
                url: property.value,
                role: property.role,
                id: property.id,
                height: 1,
                width: 1
            }
        });

    const renderProductPagination = () => {
        if (trimmedProducts && trimmedProducts.length > 3) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            prevPage()
                        }}>
                        <span className="item-count-prev d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            nextPage()
                        }}>
                        <span className="item-count-next d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    let renderProducts = () => {
        if (trimmedProducts && trimmedProducts?.length > 0) {
            return trimmedProducts.slice(productPage * 3, productPage * 3 + 3).map((product, i) => {
                let url = "/Product/ProductPage/" + product.shareCode
                url += props.disableRedirects ? "?r=0&b=0&showBrandNav=true" : "?r=1";
                return (
                    <div key={i} className="col-4 px-0">
                        <div className="product-image">
                            <a href={url} target={product.merchant} style={{ cursor: "pointer" }}>
                                <ImageWrapper
                                    src={product.imageUrl}
                                    size={`ProductGrid`}
                                    brandEmbed={props.brandEmbed}
                                    alt={product.name}
                                    key={i}
                                    className="feed-post-product-image"
                                    lazy={!!props.aboveTheFold ? !props.aboveTheFold : true}
                                />
                            </a>
                        </div>
                    </div>
                )
            })
        }
    }

    let displayPin = (isAbsolute) => {
        let style = { position: "absolute", zIndex: 1, width: "100%", textAlign: "right", padding: "7px" };
        //rgba(229,89,52,0.5)
        if (props.showPins) {
            let disablePointer = props.loggedInUser?.id !== props.post.user.id;
            let disablePointerClass = disablePointer ? "disabled-pin-icon" : "";
            if (props.pinnedDate) {
                return (
                    <a href="#" className={disablePointerClass} onClick={(e) => {
                        e.preventDefault();
                        if (props.loggedInUser?.id === props.post.user.id) {
                            props.togglePinned(props.post.shareCode);
                        }

                    }}>
                        <span style={{ ...style, color: "rgba(0,0,0,0.6)" }}>
                            <PinIcon isFilled={true} fillBackground={"#F8F9FA"} width="28" height="29" padding="8px 7px" opacity="0.8" />
                        </span>
                    </a>
                )
            }
            else {
                if (props.loggedInUser?.id === props.post.user.id) {
                    return (
                        <a href="#" className={disablePointerClass} onClick={(e) => {
                            e.preventDefault();
                            props.togglePinned(props.post.shareCode);
                        }}>
                            <span style={{ ...style, color: "#e55934" }}>

                                <PinIcon isFilled={false} fillBackground="#F8F9FA" width="28" height="29" padding="8px 7px" opacity="0.8" />

                            </span>
                        </a>
                    )
                }

            }
        }
    }

    let renderUserTile = () => {
        if (props.showUserTile === true) {
            return (<div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-0 mx-0" style={{ background: "white" }}>
                <UserTileGeneral className={`py-1 ${props.brandEmbed && props.isBrandSmall ? "" : "ml-2"}`} size={"Profile"} brandEmbed={props.disableRedirects} user={props.post.user} subtitle={" "} showCheckMark={false} />
            </div>);
        }
    }

    let renderProductCount = () => {
        if (trimmedProducts.length === 1) {
            return "1 product"
        } else if (trimmedProducts.length > 1) {
            return trimmedProducts.length + " products"
        }
        return "";
    }

    let renderTitle = () => {
        return (
            <h3 className={`position-absolute ${props.brandEmbed ? "blog-feed-post-grid-item-title-hidden" : ""}`} ref={titleRef} onClick={() => {
                if (!props.disableRedirects) {
                    props.toggleLockerPageModal(props.post, props.loggedInUser, null, null, props.toggleSignUpModal)
                } else {
                    window.open(getLockerShareLink(props.post.shareCode));
                }

            }} style={{ cursor: "pointer", fontSize: props.brandEmbed ? "16px" : "" }}>
                {title}
            </h3>
        )
    }

    let renderPost = () => {
        const radius = { borderRadius: props.showUserTile ? "" : trimmedProducts?.length > 0 ? "15px 15px 0 0" : "15px" }
        return (
            <>
                <div className="image-items">
                    <ImageWrapper
                        src={mediaItems[currentDisplayedMedia]?.url || "images/blank-banner.png"}
                        alt=""
                        className={"feed-post-grid-locker-image"}
                        role={mediaItems[currentDisplayedMedia]?.role}
                        size={`Post`}
                        brandEmbed={props.brandEmbed}
                        onClick={() => {
                            if (!props.disableRedirects) {
                                props.toggleLockerPageModal(props.post, props.loggedInUser, null, props.setPost);
                            }
                            else {
                                window.open(props.redirectUrl);
                            }
                        }}
                        width={mediaItems[currentDisplayedMedia]?.width}
                        height={mediaItems[currentDisplayedMedia]?.height}
                        style={{ cursor: "pointer", radius }}
                        lazy={!!props.aboveTheFold ? !props.aboveTheFold : true}
                    />
                </div>

                {renderTitle()}

                <span className="item-count position-absolute" onClick={() => {
                    if (!props.disableRedirects) {
                        props.toggleLockerPageModal(props.post, props.loggedInUser, null, null, props.toggleSignUpModal)
                    } else {
                        window.open(getLockerShareLink(props.post.shareCode));
                    }

                }} style={{ cursor: "pointer", display: props.hideItemCount ? "none" : "", top: props.brandEmbed ? "92%" : "", bottom: props.brandEmbed ? "unset" : "", width: ["sm", "md"].includes(props.deviceSize) ? "75%" : "" }}>
                    {renderProductCount()}
                </span>
            </>
        );
    }

    let gridItem = () => {
        if (trimmedProducts?.length > 0) {
            return (
                <div style={{ borderRadius: "15px", overflow: "hidden" }}>

                    {renderUserTile()}
                    <div className="feed-slider">
                        {displayPin()}
                        <div className="image-container">
                            {renderPost()}
                        </div>
                    </div>
                    <div className="feed-slider-products" style={{ paddingBottom: props.brandEmbed && props.isBrandSmall ? "37%" : props.brandEmbed ? "40%" : "" }}>
                        <div {...productPageHandlers} className={`row mx-0 product-image-gallery justify-content-center px-2 position-absolute pt-0 mt-0`} style={{ zIndex: props.brandEmbed ? 899 : "", width: "100%", bottom: "3%" }}>
                            {renderProductPagination()}
                            {renderProducts()}
                        </div>
                    </div>
                </div>

            )
        } else {
            return (
                <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                    {renderUserTile()}
                    <div className="feed-slider-noproduct">
                        {displayPin()}
                        {renderPost()}
                    </div>
                </div>
            )
        }
    }

    return (
        gridItem()
    )
}

const mapDispatch = { toggleShareModal, togglePostPageModal, toggleBlogPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(LockerFeedPostGridItem);