import React from "react";
import Select from 'react-select';
import AccountBanner from "../../components/AccountBanner"
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import ActionButton from "components/ActionButton";
import BrandNames from "components/BrandNames";
import Modal from 'react-modal';
import LoadSpinner from "components/LoadSpinner";
import { Link } from "react-router-dom";


class ManageBrands extends React.Component {

    state = {
        // NEW BRANDS CODE
        brands: [],
        selectedBrandName: null,
        show: false,
        showProducts: false,
        selected: "",
        selectedBrandNameLoading: false,
        selectedOriginalBrand: [{ name: "", id: 0 }],
        selectedNewBrand: [{ name: "", id: 0 }],
        savingBrandAssociations: false,
        brandsLoading: false,
        search: "",
        finishedFetchingBrands: false,
        selectedBrandProducts: [],
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/ManageBrands', text: 'Manage Brands' }];
    brandNamesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ManageBrands', text: 'Brand Names', active: true }
    ];
    brandProductBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ManageBrands', text: 'Brand Product Association', active: true }
    ];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.fetchBrands();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.search !== this.state.search && this.state.search === "") {
            this.fetchBrands();
        }
    }

    fetchBrands = async () => {
        this.setState({ brandsLoading: true, brands: [] });
        const search = this.state.search;
        const url = `/api/Merchant/GetBrandsWithAssociatedProducts?skip=0&take=200&search=${search}`;
        let brands = await getRequest(url);
        this.setState({ brands, brandsLoading: false });
    }

    handleBrandNamesClick = async (manufacturerName, manufacturerId) => {
        this.setState({ selected: manufacturerName, selectedBrandName: [], selectedBrandNameLoading: true, show: true });

        const url = `/api/Merchant/GetBrandMerchants?manufacturerId=${manufacturerId}`;
        const selectedBrandName = await getRequest(url); 

        this.setState({ selectedBrandName, selectedBrandNameLoading: false });
    }

    handleBrandProductsClick = async (manufacturerName, manufacturerId) => {
        this.setState({ selected: manufacturerName, selectedBrandProducts: [], selectedBrandNameLoading: true, showProducts: true });

        const url = `/api/Merchant/GetBrandProductUrls?manufacturerId=${manufacturerId}`;
        const selectedBrandProducts = await getRequest(url);

        this.setState({ selectedBrandProducts, selectedBrandNameLoading: false });
    }

    handleBrandClick = (manufacturerId, table) => {
        switch (table) {
            case "original":
                const selectedOriginalBrand = this.state.brands.filter(brand => brand.id === manufacturerId);
                this.setState({ selectedOriginalBrand });
                return;
            case "new":
                const selectedNewBrand = this.state.brands.filter(brand => brand.id === manufacturerId);
                this.setState({ selectedNewBrand });
                return;
        }
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({ search: e.target.value });
    }

    resetSearch = () => {
        this.setState({ search: "", finishedFetchingBrands: false });
    }

    searchBrands = async () => {
        const search = this.state.search.trim();
        if (search.length > 0) {
            this.fetchBrands();
        }
    }

    brandsReset = () => {
        this.setState({
            selectedOriginalBrand: [{ name: "", id: 0 }],
            selectedNewBrand: [{ name: "", id: 0 }]
        });
    }

    fetchMore = async () => {
        let skip = this.state.brands?.length;
        const search = this.state.search;
        const url = `/api/Merchant/GetBrandsWithAssociatedProducts?skip=${skip}&take=200&search=${search}`;
        let newBrands = await getRequest(url);

        if (newBrands == null || newBrands == undefined || newBrands?.length === 0) {
            this.setState({ finishedFetchingBrands: true });
        } else {
            const brands = [...this.state.brands, ...newBrands]
            this.setState({ brands });
        }
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false });
    }

    renderBuyLogic = (shareCode) => {
        let url = "/Products/BuyAuthorized?shareCode=" + shareCode + "&referrer=" + document.referrer + "&browse=false";
        return url;
    }

    saveBrandAssociations = async () => {
        this.setState({ savingBrandAssociations: true })

        const url = `/Products/UpdateProducts`;
        const model = {
            newManufacturerId: this.state.selectedNewBrand[0].id,
            newManufacturerName: this.state.selectedNewBrand[0].name,
            oldManufacturerId: this.state.selectedOriginalBrand[0].id,
        };

        await postRequest(url, model);

        this.brandsReset();
        this.fetchBrands();
        this.setState({ savingBrandAssociations: false });
    }

    displayDetailsTable = () => {
        if (this.state.selectedBrandName?.length > 0) {
            return (
                <div className="table-responsive product-table mx-auto mb-3" style={{ maxWidth: "90%", maxHeight: "300px" }}>
                    <table className="table" style={{ border: 0, maxWidth: "350px", margin: "0 auto" }}>
                        <tbody>
                            <tr>
                                <td style={{ border: 0, padding: 0 }}>
                                    <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>MERCHANT:</h6>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>AFFILIATE:</h6>
                                </td>
                            </tr>
                            {this.displayBrandDetailRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    displayProductsDetailsTable = () => {
        if (this.state.selectedBrandProducts?.length > 0) {
            return (
                <div className="table-responsive product-table mx-auto mb-3" style={{ maxWidth: "90%", maxHeight: "300px" }}>
                    <table className="table" style={{ border: 0, maxWidth: "350px", margin: "0 auto", tableLayout: "fixed" }}>
                        <colgroup>
                            <col span="1" style={{ width: "70%" }} />
                            <col span="1" style={{ width: "30%" }} />

                        </colgroup>
                        <tbody>
                            <tr className="pb-3">
                                <td style={{ border: 0, padding: 0 }}>
                                    <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>URL:</h6>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }}>AFFILIATE:</h6>
                                </td>
                            </tr>
                            {this.displayProductsBrandDetailRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }


    displayBrandDetailRows = () => {
        return this.state.selectedBrandName?.map((m, i) => {
            return (
                <tr key={i}>
                    <td style={{ border: 0, padding: 0 }}>
                        <h6>{m.merchantName} {m.integrated ? "*" : ""}</h6>
                    </td>
                    <td style={{ border: 0, padding: 0, fontSize: "12px" }}>
                        <h6>{m.affiliateProgramManager}</h6>
                    </td>
                </tr>
            )
        });
    }

    displayProductsBrandDetailRows = () => {
        return this.state.selectedBrandProducts?.map((m, i) => {
            const url = this.renderBuyLogic(m.shareCode)

            if (i === this.state.selectedBrandProducts?.length - 1) {
                return (
                    <tr key={i}>
                        <td style={{ border: 0, padding: "7px 0", wordBreak: "break-all" }}>
                            <a href={m.productUrl} target={`${m.merchantName}-${m.merchantId}`}><span style={{ fontSize: "12px", textDecoration: "underline" }}>{m.productUrl}</span></a>
                            {
                                !!(m.shareCode) ?
                                    <a href={url} target={`${m.merchantName}-${m.merchantId}`}>
                                    <button className="btn btn-secondary" style={{ marginLeft: "10px", padding: "2px", fontSize: "10px" }} onClick={() => this.renderBuyLogic(m.shareCode)}>"Buy"</button>
                                   </a>
                                    : <></>
                            }
                        </td>
                        <td style={{ border: 0, padding: 0 }}>
                            <span style={{ fontSize: "14px" }}>{m.affiliateProgramManager}</span>
                        </td>
                    </tr>
                )
            }
            return (
                <>
                    <tr key={i}>
                        <td style={{ border: 0, padding:0, wordBreak: "break-all" }}>
                            <a href={m.productUrl} target={`${m.merchantName}-${m.merchantId}`}><span style={{ fontSize: "12px", textDecoration: "underline" }}>{m.productUrl}</span></a>
                            {
                                !!(m.shareCode) ?
                                    <a href={url} target={`${m.merchantName}-${m.merchantId}`}>
                                        <button className="btn btn-secondary" style={{ marginLeft: "10px", padding: "2px", fontSize: "10px" }} onClick={() => this.renderBuyLogic(m.shareCode)}>"Buy"</button>
                                    </a>
                                    : <></>
                            }
                        </td>
                        <td style={{ border: 0, padding: 0 }}>
                            <span style={{ fontSize: "14px" }}>{m.affiliateProgramManager}</span>
                        </td>
                    </tr>
                    <hr />
                </>
            )
        });
    }

    render() {
        const width = "500px";

        return (
            <div>
                <Modal isOpen={this.state.show}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100 bg-white" style={{ boxShadow: "1px 1px gray" }}>
                        <div className="mx-0 my-0">
                            <div className="d-flex flex-column pt-4">
                                <h4 className="mb-4 text-center" style={{ color: "#e55934" }}>Associated Merchants and Affiliates</h4>
                                {
                                    this.state.selectedBrandNameLoading ?
                                        <LoadSpinner /> :
                                        <>
                                            <h6 className="mb-4 text-center"><span style={{ color: "#e55934" }}>Brand: </span>{this.state.selected == "" ? "[Blank Name]" : this.state.selected}</h6>
                                            {this.displayDetailsTable()}
                                            <div className="ml-3 position-relative">
                                                <p className="small position-absolute"><span>*</span> Merchant Integrated</p>
                                            </div>
                                        </>
                                }
                                
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.showProducts}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ showProducts: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100 bg-white" style={{ boxShadow: "1px 1px gray" }}>
                        <div className="mx-0 my-0">
                            <div className="d-flex flex-column pt-4">
                                <h4 className="mb-4 text-center" style={{ color: "#e55934" }}>Product URLs</h4>
                                {
                                    this.state.selectedBrandNameLoading ?
                                        <LoadSpinner /> :
                                        <>
                                            <h6 className="mb-4 text-center"><span style={{ color: "#e55934" }}>Brand: </span>{this.state.selected == "" ? "[Blank Name]" : this.state.selected}</h6>
                                            {this.displayProductsDetailsTable()}
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </Modal>

                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Manage Brands</h1>
                    </AccountBanner>
                </section>

                <BrandNames
                    handleBrandNamesClick={this.handleBrandNamesClick}
                    handleBrandProductsClick={this.handleBrandProductsClick}
                    selectedBrandName={this.state.selectedBrandName}
                    brands={this.state.brands}
                    finishedFetchingBrands={this.state.finishedFetchingBrands}
                    fetchMore={this.fetchMore}
                    handleBrandClick={this.handleBrandClick}
                    selectedNewBrand={this.state.selectedNewBrand}
                    selectedOriginalBrand={this.state.selectedOriginalBrand}
                    savingBrandAssociations={this.state.savingBrandAssociations}
                    saveBrandAssociations={this.saveBrandAssociations}
                    brandsLoading={this.state.brandsLoading}
                    search={this.state.search}
                    handleSearch={this.handleSearch}
                    resetSearch={this.resetSearch}
                    searchBrands={this.searchBrands}
                    brandsReset={this.brandsReset}
                />
                
            </div>
        )
    }
}

export default ManageBrands;