import * as React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import CreatorDashboardHeader from './CreatorDashboardHeader';
import CreatorSettings from './CreatorSettings';
import CreatorDashboardProfile from "./CreatorDashboardProfile"
import MyProducts from "../Account/MyProducts"
import CreatorDashboardContactUs from "./CreatorDashboardContactUs"
import Brands from "../Account/Brands";
import CurrentDeals from "../Account/CurrentDeals";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import Marquee from "react-fast-marquee";
import CheckIcon from "icons/CheckIcon";
import { getMerchantShareLink } from "sharedUtils/shareLinkPrefixes"
import Modal from "components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import { toggleFollowersFollowingModal, closeAllModals, toggleFetchFollowing, toggleCreateLockerModal, toggleProductPageModal, toggleShareModal, toggleSharePostModal, toggleShareBlogModal, toggleAddProductOrDeepLinkModal } from "stateManagement/reducers/modalReducer";
import Feed from "components/Feed/Feed";
import ActionButton from "components/ActionButton";
import { Link } from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap";
import RockporchMountainIcon from "components/icons/RockporchMountainIcon";
import { Helmet } from 'react-helmet';
import { InlineWidget } from "react-calendly";
import InformationIcon from "components/icons/InformationIcon";
import Select, { components } from 'react-select'
import { asDate } from "sharedUtils/timeUtils";
import { getReferralShareLink } from "sharedUtils/shareLinkPrefixes"
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import NextIcon from "components/icons/NextIcon";
import PreviousIcon from "components/icons/PreviousIcon"
import CloseIcon from "components/icons/CloseIcon";
import { getQueryStringParams } from "sharedUtils/navUtils"

const { DateTime } = require("luxon");

const CustomSingleValue = ({ children, data, handleInfoClick, toggleDropdown, ...props }) => (
    <components.SingleValue {...props}>
        {data.label}
        <button
            onClick={(e) => { handleInfoClick(data.value); }}
            onTouchStart={(e) => { handleInfoClick(data.value, "touch"); }}

            style={{ zIndex: "999", display: data.value < 3 ? "" : "none", backgroundColor: "transparent", position: "relative", bottom: "2px", left: "3px", cursor: "pointer", outline: "none", border: 0, padding: 0, }}>
            <InformationIcon />
        </button>
    </components.SingleValue>
);

class CreatorDashboard extends React.Component {
    infoTab = -1;
    menuIsOpen = false;
    mobile = false;
    productsTab = 0;
    constructor(props){
        super(props);

        this.handleInfoClick = this.handleInfoClick.bind(this);

        this.selectOptions = {
            last24Hours: {
                value: 0,
                label: "Yesterday",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 1 })).toUTC(),
                        endDate: asDate(DateTime.now()).toUTC()
                    }
                }
            },
            last7Days: {
                value: 1,
                label: "Last 7 Days",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 7 })).toUTC(),
                        endDate: asDate(DateTime.now().plus({ days: 1 })).toUTC()
                    }
                }
            },
            last30Days: {
                value: 2,
                label: "Last 30 Days",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 30 })).toUTC(),
                        endDate: asDate(DateTime.now().plus({ days: 1 })).toUTC()
                    }
                }
            },
            last90Days: {
                value: 3,
                label: "Last 90 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 90 })).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            yearToDate: {
                value: 4,
                label: "Year To Date",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year, 1, 1)).toUTC();
                    let endDate = asDate(DateTime.now().plus({ days: 1 })).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            lastYear: {
                value: 5,
                label: "Last Year",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year - 1, 1, 1)).toUTC();
                    let endDate = asDate(DateTime.local(DateTime.now().year, 1, 1)).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            }
        };
        this.state = {
            activeIndex: 0,
            activeInfoIndex: 0,
            seeMore: false,
            selectOptions: {
                options: [
                    this.selectOptions.last24Hours,
                    this.selectOptions.last7Days,
                    this.selectOptions.last30Days,
                    this.selectOptions.last90Days,
                    this.selectOptions.yearToDate,
                    this.selectOptions.lastYear,
                ],
                defaultValue: this.selectOptions.last7Days,
                currentValue: this.selectOptions.last7Days,
            },
            selectContentOptions: {
                options: [
                    { value: 0, label: "Profile Details" },
                    { value: 1, label: "Saved Links" },
                    { value: 2, label: "Lockers" },
                    { value: 3, label: "Posts" },
                    { value: 4, label: "Brand Partners" },
                    { value: 5, label: "Current Deals"},
                    { value: 6, label: "Refer A Creator" },
                ],
                defaultValue: { value: 0, label: "Profile Details" },
                currentValue: { value: 0, label: "Profile Details" },
            },
            analytics: null,
            modalOpen: false,
            shareLink: "",
            taskList: [],
            analyticsLoading: false,
            creatorDisplay: false,
            basicDisplay: true,
            sectors: [],
            persistentSectors: [],
            allSectors: [],
            tab: 0,
            editMode: false,
            creatorSettingsEditMode: false,
            cancelEdit: false,
            cancelCreatorSettingsEdit: false,
            loggedInUser: {},
            profileBanner: "images/Profile-Banner.png",
            uploadingProfileBanner: false,
            isSavingPaymentEmail: false,
            followers: 0,
            following: 0,
            isGrid: true,
            lockers: [],
            products: [],
            posts: [],
            activityFeed: [],
            showMessageDetails: false,
            isFollowing: false,
            isLoading: true,
            finishedFetching: false,
            finishedFetchingProducts: false,
            selectedFile: {},
            selectedFileUrl: null,
            uploadingBackground: false,
            selectedCase: 3, //1:Activity, 2:Lockers, 3:Products, 4:Posts, 5:About
            postCount: 0,
            productCount: 0,
            lockerCount: 0,
            finishedFetchingActivity: false,
            finishedFetchingLockers: false,
            finishedFetchingPosts: false,
            paymentEmail: "",
            paymentEmailObject: null,
            persistentPaymentEmail: "",
            taskActionId: 1,
            selectToggle: false,
            isSavingBasicSettings: false,
            isSavingCreatorSettings: false,
            userInformation: null,
            persistentUserInformation: null,
            basicUserInformation: null,
            creatorSettingsInformation: null,
            hideTasks: false,
            hideTaskActions: false,
            marqueeHtml: "",
            marqueeBackgroundColor: "",
            marqueeRedirectUrl: null,
        }
    }
    

    componentDidMount = async () => {
        this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);

        this.fetchCreatorDashboardMarquee();

        let sharePath = "/prflink/" + this.props.loggedInUser.userName;
        let shareLink = await getMerchantShareLink(sharePath);
        this.setState({ shareLink });

        document.documentElement.addEventListener('dragleave', this.handleDragLeave);

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.updatePersistantUserInformation(this.props.loggedInUser);

        //this.handleSelect(this.state.selectOptions.defaultValue);
        this.getCreatorDashboardAnalytics();


        const params = getQueryStringParams(window.location.search);
        const tab = Math.floor(params.tab);
        const tasks = Math.floor(params.tasks);
        const productsTab = Math.floor(params.productsTab);

        if (!isNaN(tab) && Number.isInteger(tab) && tab >= 0 && tab <= 6) {
            if (tab === 6) {
                this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
            } else {
                this.setState({ tab: Math.floor(tab) });
                const selectedOption = this.state.selectContentOptions.options.find(option => option.value === tab);
                if (!!(selectedOption)) {
                    this.handleContentSelect(selectedOption);
                }
            }
        }

        let queryTaskActions = false;

        if (!isNaN(tasks) && Number.isInteger(tasks) && tasks === 1) {
            queryTaskActions = true;
        }

        if (!isNaN(productsTab) && Number.isInteger(productsTab) && productsTab >= 0 && productsTab <= 1) {
            this.productsTab = productsTab;
        }

        await this.fetchTaskList(true, false, queryTaskActions, true);


        await Promise.all([
            this.getPaymentEmail(),
            this.fetchCounts(this.props.loggedInUser.id),
            this.fetchIsFollowing(this.props.loggedInUser.id),
            this.fetchActivityFeed(0, 12)]);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!!(prevProps.deviceSize) && !!(this.props.deviceSize) && prevProps.deviceSize !== this.props.deviceSize) {
            this.setState({ activeIndex: 0 })
        }
        if (!!(this.props.reloadCreatorDashboardAnalyticsData) && prevProps.reloadCreatorDashboardAnalyticsData != null && prevProps.reloadCreatorDashboardAnalyticsData != undefined && prevProps.reloadCreatorDashboardAnalyticsData !== this.props.reloadCreatorDashboardAnalyticsData) {
            const selectOption = this.state.selectOptions.currentValue;
            this.handleSelect(selectOption);
        }

        if (prevProps.screenWidth !== this.props.screenWidth) {
            this.mobile = false;
        }

        const prevKey = prevProps.location.state?.key;
        const currentKey = this.props.location.state?.key;

        if (prevKey !== currentKey) {
            const params = getQueryStringParams(window.location.search);
            const productsTab = Math.floor(params.productsTab);

            if (!isNaN(productsTab) && Number.isInteger(productsTab) && productsTab >= 0 && productsTab <= 1) {
                this.productsTab = productsTab;
            }
            //this.productsTab = 1;
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        document.documentElement.removeEventListener('dragleave', this.handleDragLeave);
    }

    fetchCreatorDashboardMarquee = async () => {
        let url = `/api/Creator/GetCreatorDashboardMarquee`;
        let creatorDashboardMarquee = await getRequest(url);
        if (!!(creatorDashboardMarquee) && creatorDashboardMarquee.uiHtml?.trim().length > 0) {
            this.setState({ marqueeHtml: creatorDashboardMarquee.uiHtml, marqueeBackgroundColor: creatorDashboardMarquee.backgroundColor, marqueeRedirectUrl: creatorDashboardMarquee.redirectUrl });
        }
    }

    toggleDisplay = (basic = true) => {
        this.setState({ creatorDisplay: basic ?  false : true, basicDisplay: basic ? true : false });
    }

    handleDragLeave = (e) => {
        if (e.screenX === 0) {
        //    this.postTaskItemCompleted();
        }
    }

    handleSelect = async (selectedOption) => {
        if (selectedOption) {
            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = selectedOption;
            this.setState({ selectOptions });
        }
        const dateRange = selectedOption?.getDateRange();
        this.getCreatorDashboardAnalytics(dateRange?.startDate, dateRange?.endDate);
    }

    handleContentSelect = (selectedOption) => {
        if (!!(selectedOption.selectedOption)) {
            selectedOption = { ...selectedOption.selectedOption };
        }
        if (selectedOption) {
            if (selectedOption.value === 6) {
                this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
            } else {
                const selectContentOptions = { ...this.state.selectContentOptions };
                selectContentOptions.currentValue = selectedOption;
                let tab = typeof selectedOption.value === 'number' ? selectedOption.value : this.state.tab;
                this.setState({ selectContentOptions, tab });
                this.updateQueryString(selectedOption.value)
            }
        }
    }

    toggleDropdown = (e, fromWhere = "") => {
        const classList = e.target.classList;

        if (fromWhere === "touch") {
            this.mobile = true;
        } else if (this.mobile) {
            return;
        }
        // svg on the icon has classList.value === '';
        if (e.target.tagName !== "svg" || !!(classList.value)) {
            this.menuIsOpen = !this.menuIsOpen;
            this.forceUpdate();
        }
    }

    updateQueryString = (tab) => {
        const { history } = this.props;

        // Build the new search string with updated query parameters
        const newSearch = `?tab=${tab}`;

        // Use history.replace to update the URL without adding a new entry to the history stack
        history.replace({
            search: newSearch,
        });
    };

    getCreatorDashboardAnalytics = async (startDate, endDate) => {
        let now = DateTime.now();
        let offset = now.offset;

        let getAnalyticsUrl = '/api/Merchant/GetCreatorDashboardAnalytics';

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.toSQL({ includeOffset: false })}` : `?endDate=${endDate.toSQL({ includeOffset: false })}`;
        }

        if (getAnalyticsUrl.includes("?")) {
            getAnalyticsUrl += `&offset=${offset}`
        } else {
            getAnalyticsUrl += `?offset=${offset}`
        }

        this.setState({ analyticsLoading: true });
        let analytics = await getRequest(getAnalyticsUrl) ?? [];
        this.setState({ analytics, loading: false, analyticsLoading: false });
    }

    getPaymentEmail = async () => {
        let paymentEmail = await getRequest("api/Account/GetPaymentEmail");
        
        this.setState({ persistentPaymentEmail: paymentEmail?.email ?? "", paymentEmailObject: paymentEmail, paymentEmail: paymentEmail?.email ?? "", email: paymentEmail?.email ?? "" });
    }

    updateBasicUserInformation = (propsUserInformation) => {
        this.setState({ basicUserInformation: propsUserInformation });
    }

    updateCreatorSettingsInformation = (propsUserInformation) => {
        this.setState({ creatorSettingsInformation: propsUserInformation });
    }

    parseUserInfo = (propsUserInformation) => {
        if (!!(propsUserInformation)) {
            return {
                name: propsUserInformation.name ?? this.state.persistentUserInformation?.name ?? "",
                firstName: propsUserInformation.firstName ?? this.state.persistentUserInformation?.firstName ?? "",
                lastName: propsUserInformation.lastName ?? this.state.persistentUserInformation?.lastName ?? "",
                email: propsUserInformation.email ?? this.state.persistentUserInformation?.email ?? "",
                address: propsUserInformation.address ?? this.state.persistentUserInformation?.address ?? "",
                city: propsUserInformation.city ?? this.state.persistentUserInformation?.city ?? "",
                state: propsUserInformation.state ?? this.state.persistentUserInformation?.state ?? "",
                country: propsUserInformation.country ?? this.state.persistentUserInformation?.country ?? "",
                website: propsUserInformation.website ?? this.state.persistentUserInformation?.website ?? "",
                postalCode: propsUserInformation.postalCode ?? this.state.persistentUserInformation?.postalCode ?? "",
                phoneNumber: propsUserInformation.phoneNumber ?? this.state.persistentUserInformation?.phoneNumber ?? "",
                tagLine: propsUserInformation.tagLine ?? this.state.persistentUserInformation?.tagLine ?? "",
                bio: propsUserInformation.bio ?? this.state.persistentUserInformation?.bio ?? "",
                userName: propsUserInformation.userName ?? this.state.persistentUserInformation?.userName ?? "",
                imageUrl: propsUserInformation.imageUrl ?? this.state.persistentUserInformation?.imageUrl ?? "",

                proStatusId: propsUserInformation.proStatusId ?? this.state.persistentUserInformation?.proStatusId ?? null,

                googleUsername: propsUserInformation.googleUsername ?? this.state.persistentUserInformation?.googleUsername ?? "",
                facebookUsername: propsUserInformation.facebookUsername ?? this.state.persistentUserInformation?.facebookUsername ?? "",
                instagramUsername: propsUserInformation.instagramUsername ?? this.state.persistentUserInformation?.instagramUsername ?? "",
                tikTokUsername: propsUserInformation.tikTokUsername ?? this.state.persistentUserInformation?.tikTokUsername ?? "",
                stravaUsername: propsUserInformation.stravaUsername ?? this.state.persistentUserInformation?.stravaUsername ?? "",

                onboardingComplete: propsUserInformation.onboardingComplete ?? this.state.persistentUserInformation?.onboardingComplete ?? null,
                profileBannerUrl: propsUserInformation.profileBannerUrl ?? this.state.persistentUserInformation?.profileBanner ?? "",

                quickStart: propsUserInformation.quickStart ?? this.state.persistentUserInformation?.quickStart ?? null,
                rpManagerUserId: propsUserInformation.rpManagerUserId ?? this.state.persistentUserInformation?.rpManagerUserId ?? null
            }
        }
    }

    updatePaymentEmail = (paymentEmail) => {
        this.setState({ paymentEmail });
    }

    updatePersistantUserInformation = (propsUserInformation) => {
        this.setState({ persistentUserInformation: this.parseUserInfo(propsUserInformation) });
    }

    fetchTaskList = async (setActionId = false, hideTasks = false, queryStringTasks = false, initialCall = false) => {
        let url = `/api/Creator/GetTaskList?userId=${this.props.loggedInUser.id}`;
        const taskList = await getRequest(url);

        if (!!(taskList) && taskList.length > 0) {
            // Sort the taskList
            taskList.sort((a, b) => {
                if (a.ordinal !== null && b.ordinal !== null) {
                    if (a.ordinal !== b.ordinal) {
                        return a.ordinal - b.ordinal;
                    } else {
                        // If ordinals are equal, sort by id
                        return a.id - b.id;
                    }
                } else if (a.ordinal !== null) {
                    // Place items with valid ordinal before those with null ordinal
                    return -1;
                } else if (b.ordinal !== null) {
                    // Place items with valid ordinal before those with null ordinal
                    return 1;
                } else {
                    // If both ordinals are null, sort by id
                    return a.id - b.id;
                }
            });

            const taskListWithNoUserId = taskList.filter(item => !(item.userTaskId));
            const taskActionId = !!(taskListWithNoUserId) && taskListWithNoUserId.length > 0 ? taskListWithNoUserId[0].id : 1;

            if (queryStringTasks) {
                this.setState({ taskList, taskActionId: 1, hideTaskActions: false });
            } else {
                this.setState({ taskList, taskActionId: setActionId ? taskActionId : this.state.taskActionId, hideTaskActions: hideTasks ? hideTasks : setActionId && (!(taskListWithNoUserId) || taskListWithNoUserId.length === 0) ? true : false, hideTasks: initialCall && (!(taskListWithNoUserId) || taskListWithNoUserId.length === 0) ? true : this.state.hideTasks });
            }
        }        
    }

    postTaskItemCompleted = async (hideTaskActions = false) => {
        let { taskList, taskActionId } = this.state;

        let url = `/api/Creator/PostUserCreatorDashboardTaskItemTaskCompleted`;
        let taskItem = taskList.filter(item => item.id === taskActionId)[0];
        let upload = {
            id: taskItem.id,
            taskName: taskItem.taskName ?? "",
            ordinal: taskItem.ordinal ?? null,
            userTaskId: taskItem.userTaskId ?? null,
            userId: taskItem.userId ?? null,
        }

        await postRequest(url, upload);
        setTimeout(() => {
            this.fetchTaskList(false, hideTaskActions);
        }, 1000)
    }

    downwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
        )
    }

    upwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
        )
    }

    handleChevronClick = () => {
        this.setState({ selectToggle: !this.state.selectToggle });
    };

    next = (arrayCount) => {
        this.setState({ activeIndex: this.state.activeIndex < arrayCount - 1 ? this.state.activeIndex + 1 : 0 })
    }

    previous = (arrayCount) => {
        this.setState({ activeIndex: this.state.activeIndex > 0 ? this.state.activeIndex - 1 : arrayCount - 1 })
    }

    infoNext = (arrayCount) => {
        this.setState({ activeInfoIndex: this.state.activeInfoIndex < arrayCount - 1 ? this.state.activeInfoIndex + 1 : 0 })
    }

    infoPrevious = (arrayCount) => {
        this.setState({ activeInfoIndex: this.state.activeInfoIndex > 0 ? this.state.activeInfoIndex - 1 : arrayCount - 1 })
    }

    goToIndex = (activeIndex) => {
        this.setState({ activeIndex });
    }

    goToInfoIndex = activeInfoIndex => {
        this.setState({ activeInfoIndex })
    }

    renderAnalytics = () => {
        let menu = [
            {
                title: "Sales generated",
                name: "salesGenerated",
                actionType: "url",
                action: "/Account/Analytics?date=" + this.state.selectOptions.currentValue.value + "&tab=1",
                linkText: "View analytics",
                money: true
            },
            {
                title: "Commissionable links created",
                name: "commissionableLinksCreated",
                actionType: "modal",
                action: this.props.toggleAddProductOrDeepLinkModal,
                linkText: "Create a commissionable link",
                money: false
            },
            {
                title: "Lockers created",
                name: "lockersCreated",
                actionType: "modal",
                action: this.props.toggleCreateLockerModal,
                linkText: "Create a Locker",
                money: false
            },
            {
                title: "Clicks",
                name: "clicks",
                actionType: "modal",
                action: this.goToSavedLinks,
                linkText: "Share a link",
                money: false
            },
            {
                title: "Commissions earned",
                name: "commissionsEarned",
                actionType: "url",
                action: "/Account/Analytics?date=" + this.state.selectOptions.currentValue.value + "&tab=1",
                linkText: "View analytics",
                money: true
            },
        ];

        const flexContainerStyles = {
            fontFamily: "Archivo, sf_pro_displayregular, sans-serif",
            fontSize: "14px",
            gap: "10px",
            display: 'flex',
            overflowX: 'auto',
            paddingBottom: '10px',
            whiteSpace: 'nowrap',
        };

        const itemStyles = {
            flex: '0 0 auto',
            padding: '10px 30px 10px 10px',
        //    borderRight: '1px solid #ccc', // Add vertical separator between items
        };

        const lastItemStyles = {
            ...itemStyles,
            borderRight: 'none', // Remove border for the last item
        };

        const selectStyle = {
            padding: '2px 20px 2px 2px',
            width: '53px', /* Adjust width to leave space for the arrow */
            border: '1px solid #ccc',
            borderRadius: '5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textIndent: "3px",
            fontSize: "10px",
            cursor: "pointer",
            background: "white",
        };

        const itemsPerSlide = this.props.deviceSize === "sm" ? 1 : this.props.deviceSize === "md" ? 2 : this.props.deviceSize === "lg" ? 3 : 4; // Number of items per slide
        const menuChunks = [];

        // Divide 'menu' into chunks to display multiple items per slide
        for (let i = 0; i < menu.length; i += itemsPerSlide) {
            menuChunks.push(menu.slice(i, i + itemsPerSlide));
        }

        const menuPortalTarget = document.body; // Render menu outside the containing div

        const menuRender = menuChunks.map((chunk, i) => {
            return (
                <CarouselItem key={i}>
                    <div key={i} className="d-flex align-items-start justify-content-around" style={{ ...lastItemStyles, padding: 0, minWidth: "70vw" }}>
                        {chunk.map((item, j) => (

                            <div key={j} className={`mr-1 d-flex flex-column mx-auto`}>
                                <div>
                                    {
                                        item.money ?
                                            <span><strong>${item.name === "salesGenerated" ? this.state.analytics?.totalSales?.toFixed(2) ?? 0 : this.state.analytics?.totalCommissions?.toFixed(2) ?? 0}</strong></span>
                                            :
                                            <span><strong>{item.name === "commissionableLinksCreated" ? this.state.analytics?.commissionableLinksCreated ?? 0 : item.name === "clicks" ? this.state.analytics?.totalClicks ?? 0 : this.state.analytics?.lockersCreated ?? 0}</strong></span>
                                    }
                                </div>
                                <div>
                                    {item.title}
                                </div>
                                <div>
                                    {
                                        item.actionType === "modal" ?
                                            <a href="https://www.rockporch.com"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    item.action();
                                                }}
                                                style={{ textDecoration: "underline" }}
                                            >
                                                <span className={`m-0 `}>
                                                    {item.linkText}
                                                </span>
                                            </a>
                                            :
                                            <a href={item.action}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(item.action)
                                                }}
                                                style={{ textDecoration: "underline" }}
                                            >
                                                <span className={`m-0 `}>
                                                    {item.linkText}
                                                </span>
                                            </a>}
                                </div>
                            </div>
                        ))}
                    </div>
                </CarouselItem>
            );
        });

        if (!["xl"].includes(this.props.deviceSize)) {
            return (
                <div className={`d-flex creator-dashboard-analytics pt-0 ${this.state.hideTasks ? "mt-md-3" : "mt-md-5"} mb-0 justify-content-center pb-0`} id="contentDiv" style={{ margin: "0 auto" }}>
                    <div>
                        <PreviousIcon onClick={() => this.previous(menuRender.length)} className={`creator-dashboard-prev-next`} height={75} width={36} opacity={0.1} style={{ position: "relative", right: ["sm"].includes(this.props.deviceSize) ? "" : "20px", transform: "scaleY(2.5)" }} />
                    </div> 
                    <Carousel
                        activeIndex={this.state.activeIndex}
                        next={() => this.next(menuRender.length)}
                        previous={() => this.previous(menuRender.length)}
                        style={{ overflow: "visible" }}
                        interval={null}
                        keyboard={false}
                        controls={false}
                        className={`${this.props.deviceSize === "sm" ? "creator-dashboard-analytics-carousel-slide" : "creator-dashboard-analytics-carousel-slide"}`}
                    >
                        <CarouselIndicators items={menuRender} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                        {menuRender}
                        {/*<CarouselControl direction="prev" directionText="Previous" onClickHandler={() => this.previous(menuRender.length)} />*/}
                        {/*<CarouselControl direction="next" directionText="Next" onClickHandler={() => this.next(menuRender.length)} />*/}
                    </Carousel>
                    <div>
                        <NextIcon onClick={() => this.next(menuRender.length)} className={`creator-dashboard-prev-next`} height={75} width={36} opacity={0.1} style={{ position: "relative", left: ["sm"].includes(this.props.deviceSize) ? "" : "20px", transform: "scaleY(2.5)" }} />
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex flex-wrap justify-content-around mx-4" style={flexContainerStyles}>
                {menu.map((item, i) => (
                    <div key={i} className={`d-flex align-items-start ${i === menu.length - 1 ? "" : "creator-dashboard-analytics-desktop-item"}`} style={i === menu.length - 1 ? lastItemStyles : itemStyles}>
                        <div key={i} className={`mr-1 d-flex flex-column`}>
                            <div>
                                {
                                    item.money ?
                                        <span><strong>${item.name === "salesGenerated" ? this.state.analytics?.totalSales?.toFixed(2) ?? 0 : this.state.analytics?.totalCommissions?.toFixed(2) ?? 0}</strong></span>
                                        :
                                        <span><strong>{item.name === "commissionableLinksCreated" ? this.state.analytics?.commissionableLinksCreated ?? 0 : item.name === "clicks" ? this.state.analytics?.totalClicks ?? 0 : this.state.analytics?.lockersCreated ?? 0}</strong></span>
                                }
                            </div>
                            <div>
                                {item.title}
                            </div>
                            <div>
                                {
                                    item.actionType === "modal" ?
                                        <a href="https://www.rockporch.com"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                item.action();
                                            }}
                                            style={{ textDecoration: "underline" }}
                                        >
                                            <span className={`m-0 `}>
                                                {item.linkText}
                                            </span>
                                        </a>
                                        :
                                        <a href={item.action}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push(item.action)
                                            }}
                                            style={{ textDecoration: "underline" }}
                                        >
                                            <span className={`m-0 `}>
                                                {item.linkText}
                                            </span>
                                        </a>}
                            </div>
                        </div>
                    </div>
                    
                ))}
            </div>
        );
    }

    handleTaskActionClick = (task) => {
        if (!(task.userTaskId)) {
            this.setState({ taskActionId: task.id })
        }
    }

    goToSavedLinks = () => {
        this.setState({ tab: 1 });
        let contentMenuElement = document.getElementById('contentMenu');
        if (contentMenuElement) {
            contentMenuElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    nextTask = () => {
        this.postTaskItemCompleted();
        const actionId = this.state.taskActionId;
        this.setState({ taskActionId: this.taskAction === this.state.taskList.length ? actionId + 1 : actionId + 1 })
    }

    previousTask = () => {
        this.postTaskItemCompleted();
        const actionId = this.state.taskActionId;
        this.setState({ taskActionId: actionId > 1 ? actionId - 1 : this.state.taskList.length })
    }

    finishTasks = () => {
        this.postTaskItemCompleted(true);
        this.setState({ hideTaskActions: true })
    }

    renderTaskOrderingIcon = (character, selected = false) => {
        selected = this.state.hideTaskActions ? false : selected;

        return (
            <div
                className="checkmark"
                style={{
                    position: 'relative',
                    width: selected ? '20px' : '16px',
                    height: selected ? '20px' : '16px',
                    background: !selected ? "#AAAFB4" : '#D46240',
                    borderRadius: '50%',
                    display: "inline-block",
                    top: selected ? "5px" : "4px",
                }}
            >
                <span
                    style={{
                        position: 'absolute',
                        color: 'white',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: selected ? '14px' : '10px',
                    }}
                >{!!(character) ? character : '✔'}</span>
            </div>
        );
    }

    renderTasks = () => {
        if (this.state.taskList?.length > 0) {
            return (
                <div>
                    <div className="d-flex justify-content-end position-relative" style={{ top: this.props.screenWidth < 767 && this.state.hideTasks ? "-1px" : this.state.hideTasks ? "10px" : this.props.screenWidth < 767 ? "-1px" : "-22px", right: this.props.screenWidth < 576 ? "" : "", zIndex: 1 }}>
                        <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ hideTasks: !this.state.hideTasks }); }}>{this.state.hideTasks ? <CloseIcon style={{ transform: "rotate(45deg)" }} /> : <CloseIcon />}</a>
                    </div>
                    <div className={`${this.state.hideTasks ? "" : "d-flex"} flex-wrap justify-content-${this.props.screenWidth < 991 && this.props.screenWidth > 766 ? "between" : "around"} ${this.props.deviceSize === "sm" ? "flex-column mb-4 mx-auto" : ""}`} style={{
                       display: this.state.hideTasks ? "none" : "",    fontFamily: "Archivo, sf_pro_displayregular, sans-serif", fontSize: "12px", width: this.props.deviceSize === "sm" ? "85%" : "",
                    }}>
                        {this.state.taskList.map((task, i) => (
                            <div key={i}>
                                <div className={`mb-3 mr-2 ${task.id === this.state.taskActionId && !this.state.hideTaskActions ? "task-list-item" : ""}${this.state.taskActionId === task.actionTaskId ? "-active" : ""}`}
                                    style={{ cursor: "pointer", position: "relative", top: task.id === this.state.taskActionId && !this.state.hideTaskActions? "" : "3px" }}
                                    onClick={() => this.handleTaskHeaderClick(task.id)}
                                >
                                    {this.renderTaskOrderingIcon(!!(task.userTaskId) ? "" : task.id, task.id === this.state.taskActionId)}
                                    <span
                                        className={`ml-1 ${!!(task.userTaskId) || this.state.taskActionId !== task.id ? "text-muted" : ""}`}
                                    >
                                        {task.taskName}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={`mt-0 pt-3 pb-3 mx-auto ${["sm"].includes(this.props.deviceSize) ? "mb-4" : ""}`} style={{ backgroundColor: "rgb(233,236,239)", display: this.state.hideTasks || this.state.hideTaskActions ? "none" : "" }}>
                        {this.renderTaskActions()}
                    </div>
                </div>
        
            );
        }
    };

    handleTaskHeaderClick = (taskId) => {
        this.setState({ hideTaskActions: this.state.taskActionId === taskId && !this.state.hideTaskActions ? true : false, taskActionId: taskId })
    }

    toggleGrid = () => {
        let newIsGrid = !this.state.isGrid;
        this.setState({ isGrid: newIsGrid });
    }

    fetchCounts = async (userId) => {
        let url = `api/Post/GetPostCount?userId=${userId}&onlyPublished=true`;
        let count = await getRequest(url);

        let sum = count.length > 0 ? count.reduce(function (a, b) {
            return { count: a.count + b.count };
        }) : { count: 0 };
        let totalCount = sum.count;
        let lockerCount = 0;
        let lockers = count.find(({ userPostTypeId }) => userPostTypeId === 2);

        if (lockers) {
            lockerCount = lockers.count;
        }

        let productCountUrl = `/Products/GetUserMerchantProductCount?userId=${userId}`
        let productCount = (await getRequest(productCountUrl)).count;

        this.setState({ postCount: totalCount - lockerCount, lockerCount, productCount });
    }

    fetchIsFollowing = async (userId) => {
        let url = `/api/Account/IsFollowing?id=${userId}`;
        let data = await getRequest(url);
        this.setState({ isFollowing: data });
    }

    fetchActivityFeed = async (skip, take) => {
        let url = this.props.loggedInUser != null ? '/api/post/getPostsByUserAuthorized' : '/api/post/getPostsByUser';

        let data = await getRequest(`${url}?userName=${this.props.loggedInUser.userName}&skip=${skip}&take=${take}`);

        let lockers = data.filter((post) => post.type === 2);
        let lockerIds = this.state.lockers.map((locker) => locker.id);

        let posts = data.filter((post) => post.type === 1 || post.type === 4);
        let postIds = this.state.posts.map((post) => post.id);

        let lockersToAdd = lockers.filter((locker) => !lockerIds.includes(locker.id));
        let postsToAdd = posts.filter((post) => !postIds.includes(post.id));

        this.setState({
            activityFeed: this.state.activityFeed.concat(data),
            finishedFetchingActivity: data.length < take ? true : false,
            finishedFetchingPosts: data.length < take ? true : this.state.finishedFetchingPosts,
            finishedFetchingLockers: data.length < take ? true : this.state.finishedFetchingLockers,
            lockers: [...this.state.lockers, ...lockersToAdd],
            posts: [...this.state.posts, ...postsToAdd]
        });
    }

    fetchLockers = async (skip, take) => {
        var postResults = await getRequest(`/api/post/getPostsByUser?userName=${this.props.loggedInUser.userName}&skip=${skip}&take=${take}&onlyLockers=true`);

        let lockers = postResults.filter((post) => post.type === 2);
        let lockerIds = this.state.lockers.map((locker) => locker.id);

        let lockersToAdd = lockers.filter((locker) => !lockerIds.includes(locker.id));

        this.setState({ lockers: [...this.state.lockers, ...lockersToAdd], finishedFetchingLockers: postResults.length < take ? true : false });
    }

    fetchPosts = async (skip, take) => {
        var postResults = await getRequest(`/api/post/getPostsByUser?userName=${this.props.loggedInUser.userName}&skip=${skip}&take=${take}&onlyPosts=true`);

        let posts = postResults.filter((post) => post.type !== 2);
        let postIds = this.state.posts.map((post) => post.id);
        let postsToAdd = posts.filter((post) => !postIds.includes(post.id));

        this.setState({ posts: [...this.state.posts, ...postsToAdd], finishedFetchingPosts: postResults.length < take ? true : false });
    }

    onTabChange = (tabIndex) => {
        this.setState({ tab: tabIndex });
        this.updateQueryString(tabIndex);

        if (this.infoTab !== tabIndex) {
            this.infoTab = -1;
            this.setState({ activeInfoIndex: 0 });
        }
    };


    setSectors = (sectors, allSectors) => {
        this.setState({ sectors, allSectors, persistentSectors: sectors });
    }

    updateSectors = (sectors) => {
        this.setState({ sectors })
    }

    saveBasicSettings = async (e) => {
        e.preventDefault();
        if (!!(this.state.basicUserInformation) && !!(this.state.sectors)) {
            this.setState({ isSavingBasicSettings: true });
            const userInformation = this.parseUserInfo(this.state.basicUserInformation);

            let selectedSectorIds = this.state.sectors.map((sector) => { return sector.sectorId });

            let userUrl = '/api/Account/PostUser';
  
            await postRequest(userUrl, userInformation);
            await postRequest('/api/Sector/UpdateUserSectors', selectedSectorIds);

            this.setState({ isSavingBasicSettings: false, editMode: false,userInformation, persistentUserInformation: userInformation, persistentSectors: this.state.sectors });
        }
    }
        
    saveCreatorSettings = async (e) => {
        e.preventDefault();
        if (!!(this.state.creatorSettingsInformation) && !!(this.state.sectors)) {
            this.setState({ isSavingCreatorSettings: true });
            const userInformation = this.parseUserInfo(this.state.creatorSettingsInformation);

            let selectedSectorIds = this.state.sectors.map((sector) => { return sector.sectorId });

            let userUrl = '/api/Account/PostUser';

            await postRequest(userUrl, userInformation);
            await postRequest('/api/Sector/UpdateUserSectors', selectedSectorIds);

            this.setState({ persistentPaymentEmail: this.state.paymentEmail, isSavingCreatorSettings: false, creatorSettingsEditMode: false, userInformation, persistentUserInformation: userInformation, persistentSectors: this.state.sectors });
        }
    }

    savePaymentEmail = async () => {
        this.setState({ isSavingPaymentEmail: true });

        const email = { email: this.state.paymentEmail };

        let emailUrl = '/api/Account/UpdatePaymentEmail';
        await postRequest(emailUrl, email);

        this.setState({ persistentPaymentEmail: this.state.paymentEmail, isSavingPaymentEmail: false });
    }

    profilePageRedirect = () => {
        let { history } = this.props;
        history.push('/Profile/' + this.props.loggedInUser.userName)
    };

    addLockerModal = () => {
        this.props.toggleCreateLockerModal();
        this.postTaskItemCompleted();
    };

    createCommissionableLink = () => {
        this.props.toggleAddProductOrDeepLinkModal();
        this.postTaskItemCompleted();
    }

    toggleEditMode = (e, taskAction = false) => {
        this.setState({ editMode: !this.state.editMode, tab: 0 })
        let contentMenuElement = document.getElementById('contentMenu');
        if (contentMenuElement) {
            contentMenuElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (taskAction) {
            this.postTaskItemCompleted();
        }
    };

    cancelEdit = () => {
        this.setState({ editMode: false, cancelEdit: true });
    }

    cancelCreatorSettingsEdit = () => {
        this.setState({ creatorSettingsEditMode: false, cancelCreatorSettingsEdit: true });
    }

    resetCancelEdit = () => {
        this.setState({ editMode: false, cancelEdit: false });
    }

    resetCancelCreatorSettingsEdit = () => {
        this.setState({ creatorSettingsEditMode: false, cancelCreatorSettingsEdit: false });
    }

    editCreatorSettingsRedirect = () => {
        this.setState({ creatorSettingsEditMode: !this.state.creatorSettingsEditMode, tab: 0 })
        let contentMenuElement = document.getElementById('creatorSettings');
        if (contentMenuElement) {
            contentMenuElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    shareLink = () => {
        this.setState({ tab: 1 })
        const option = this.state.selectContentOptions.options.filter(o => o.value === 1)[0];
        this.handleContentSelect(option);
        let contentMenu = document.getElementById('contentMenu');
        setTimeout(() => {
            if (contentMenu) {
                contentMenu.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 50)

        this.postTaskItemCompleted();
    };

    editPaypalEmail = () => {
        this.toggleDisplay(false);
        this.setState({ creatorSettingsEditMode: true, tab: 0 })
        setTimeout(() => {
            let contentMenuElement = document.getElementById('paypalAddress');
            let paymentEmailElement = document.getElementById('paymentEmail')
            if (contentMenuElement) {
                contentMenuElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            if (paymentEmailElement) {
                paymentEmailElement.focus();
            }
        }, 50);
    //    this.postTaskItemCompleted();
    };

    redirectToCalendly = () => {
        const url = 'https://meetings.hubspot.com/elaine80';
        const popupWindow = window.open(url, '_blank', 'width=600,height=700');

        if (popupWindow) {
            popupWindow.focus(); // Focus on the newly opened popup window
        } else {
            // If the browser blocks popups, provide an alternative action (e.g., open the link in the current window)
            window.location.href = url;
        }

        this.postTaskItemCompleted();
    };



    toggleShowLearnMore = () => {
        this.setState({ learnMore: true });
        setTimeout(() => {
            this.setState({ learnMore: false });
        }, 5000);
    }

    setProductsTab = (tab) => {
        this.productsTab = tab;
    }

    displayContent = (isGrid = this.state.isGrid) => {
        const smallSizes = ["sm", "md"];
        switch (this.state.tab) {
            case 0: {
                return (
                    <div>
                        <div className={`pb-${["sm"].includes(this.props.deviceSize) && !this.state.basicDisplay ? "0" : "5"}`} style={{ marginLeft: ["sm", "md"].includes(this.props.deviceSize) ? "-13px" : "", marginRight: this.props.deviceSize === "sm" ? "-13px" : "", borderBottom: ["sm"].includes(this.props.deviceSize) && !this.state.basicDisplay ? "" : "1px solid lightgray" }}>
                            <div className={`${["sm"].includes(this.props.deviceSize) ? "mx-3" : ""}`}>
                                <h6>My Profile</h6>
                                <p style={{ maxWidth: "600px" }}>
                                    The world wants to get to know YOU! So tell them a little more about who you are, what you love, and what moves you.                                 </p>
                                <p style={{ maxWidth: "600px" }}>
                                    Plus, when you send your followers to your RockPorch profile, they’ll know they’ve landed in the right place!
                                </p>
                            </div> 
                            <div className={`position-relative ${["sm"].includes(this.props.deviceSize) ? "mb-3 mr-5" : ""} d-flex justify-content-end`} style={{ bottom: "9px", left: ["sm"].includes(this.props.deviceSize) ? "20px" : "", }}>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.profilePageRedirect(); }} style={{ borderRadius: "3px", bottom: "5px", textDecoration: "underline", fontSize: ["sm"].includes(this.props.deviceSize) ? "12px" : "" }}>Preview Public Profile</a>
                            </div>
                                {
                                this.props.deviceSize === "sm" ?
                                    <>
                                        <div className={`d-flex justify-content-around`} style={{ paddingTop: "5px" }}>
                                            <p onClick={() => this.toggleDisplay(true)} style={{ cursor: "pointer", padding: "0 10px 10px 10px", borderBottom: this.state.basicDisplay ? "1px solid #d46240" : "", fontWeight: "bold", fontSize: "14px" }}>Basic Information</p>
                                            <p onClick={() => this.toggleDisplay(false)} style={{ cursor: "pointer", fontWeight: "bold", fontSize: "14px", padding: "0 10px 10px 10px", borderBottom: this.state.creatorDisplay ? "1px solid #d46240" : "" }}>Creator Settings</p>
                                        </div>
                                        <div className={`${this.state.basicDisplay ? "d-flex" : ""} dub justify-content-between`} style={{ margin: "0 16px", paddingTop: "30px", display: this.state.basicDisplay ? "" : "none" }}>
                                            <h6 className="font-weight-bold">Basic Information</h6>
                                        </div>
                                            
                                    </>
                                            :
                                        <div className={`d-flex justify-content-between`} style={{ margin: "0 16px", paddingTop: "20px" }}>
                                            <h6 >Basic Information</h6>
                                        </div>
                                }

                                {
                                    this.state.editMode && this.props.deviceSize !== "sm" ?
                                    <div className={`position-relative d-flex justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ bottom: "9px" }}>
                                            <ActionButton onClick={this.cancelEdit} isDisabled={this.state.isSavingBasicSettings} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-outline-brand">Cancel Edit</ActionButton>

                                            <ActionButton onClick={(e) => this.saveBasicSettings(e)} isLoading={this.state.isSavingBasicSettings} style={{ borderRadius: "3px", bottom: "5px", marginLeft: "10px" }} className="btn btn-primary">Save Changes</ActionButton>
                                        </div>
                                        :
                                        this.props.deviceSize !== "sm" ?
                                        <div className={`position-relative ${this.state.basicDisplay || !["sm"].includes(this.props.deviceSize) ? "d-flex" : ""} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ bottom: "9px" }}>
                                            <ActionButton onClick={this.toggleEditMode} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-outline-brand">Edit Profile</ActionButton>
                                            </div>
                                            :
                                        <></>
                                }

                            {
                                this.state.editMode && this.props.deviceSize === "sm" ?
                                    <div className={`position-relative ${this.state.basicDisplay ? "d-flex" : ""} mt-3 mb-3 justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ width: "85%", margin: "0 auto", display: this.state.basicDisplay ? "" : "none", bottom: "9px" }}>
                                        <ActionButton isLoading={this.state.isSavingBasicSettings} onClick={this.cancelEdit} style={{ borderRadius: "3px", bottom: "5px", fontSize: "12px", marginRight: "10px" }} className="btn btn-outline-brand">Cancel Edit</ActionButton>

                                        <ActionButton isLoading={this.state.isSavingBasicSettings} onClick={(e) => this.saveBasicSettings(e)} style={{ borderRadius: "3px", bottom: "5px", marginLeft: "10px", fontSize: "12px" }} className="btn btn-primary">Save Changes</ActionButton>
                                    </div>
                                    :
                                    this.props.deviceSize === "sm" ?
                                        <div className={`position-relative ${this.state.basicDisplay ? "d-flex" : ""} mt-3 justify-content-end`} style={{ display: this.state.basicDisplay ? "" : "none", bottom: "9px", right: "25px" }}>
                                            <p onClick={this.toggleEditMode} style={{ cursor: "pointer", borderRadius: "3px", bottom: "5px", fontSize: "12px", textDecoration: "underline" }} className="">Edit Information</p>
                                        </div>
                                        :
                                        <></>
                            }

                            <CreatorDashboardProfile
                                userInformation={this.props.loggedInUser}
                                deviceSize={this.props.deviceSize}
                                editMode={this.state.editMode}
                                isSaving={this.state.isSavingBasicSettings}
                                updateUserInformation={this.updateBasicUserInformation}
                                cancelEdit={this.state.cancelEdit}
                                resetCancelEdit={this.resetCancelEdit}
                                updatePersistantUserInformation={this.updatePersistantUserInformation}
                                persistentUserInformation={this.state.persistentUserInformation}
                                persistentSectors={this.state.persistentSectors}
                                setSectors={this.setSectors}
                                updateSectors={this.updateSectors}
                                hideDisplay={["sm"].includes(this.props.deviceSize) && !this.state.basicDisplay }
                            />
                        </div>
                        <div id="creatorSettings" className="pb-4" style={{ borderBottom: "1px solid lightgray", display: this.state.creatorDisplay ? "" : !["sm"].includes(this.props.deviceSize) ? "" : "none", marginLeft: ["sm", "md"].includes(this.props.deviceSize) ? "-13px" : "", marginRight: this.props.deviceSize === "sm" ? "-13px" : "" }} >
                            <div className={`d-flex justify-content-between`} style={{ margin: "0 16px", paddingTop: "30px" }}>
                                <h6 style={{ fontWeight: this.props.deviceSize === "sm" ? "bold" : "" }}>{this.props.deviceSize === "sm" ? "Personal Information" : "Creator Settings"}</h6>

                                {
                                    this.state.creatorSettingsEditMode && this.props.deviceSize !== "sm" ?
                                        <div className={`position-relative d-flex justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ bottom: "9px" }}>
                                            <ActionButton onClick={this.cancelCreatorSettingsEdit} isDisabled={this.state.isSavingCreatorSettings} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-outline-brand">Cancel Edit</ActionButton>

                                            <ActionButton onClick={(e) => this.saveCreatorSettings(e)} isLoading={this.state.isSavingCreatorSettings} style={{ borderRadius: "3px", bottom: "5px", marginLeft: "10px" }} className="btn btn-primary">Save Changes</ActionButton>
                                        </div>
                                        :
                                        this.props.deviceSize !== "sm" ?
                                        <div className={`position-relative d-flex justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ bottom: "9px" }}>
                                            <ActionButton onClick={this.editCreatorSettingsRedirect} style={{ borderRadius: "3px" }} className="btn btn-outline-brand">Edit Creator Settings</ActionButton>
                                            </div>
                                            :
                                        <></>
                                }
                            </div>
                            {
                                this.props.deviceSize === "sm" && this.state.creatorSettingsEditMode ?
                                        <div className={`mb-3 mt-3 position-relative d-flex justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ width: "85%", margin: "0 auto", bottom: "9px" }}>
                                            <ActionButton onClick={this.cancelCreatorSettingsEdit} style={{ fontSize: "12px", borderRadius: "3px", bottom: "5px" }} className="btn btn-outline-brand">Cancel Edit</ActionButton>

                                            <ActionButton onClick={(e) => this.saveCreatorSettings(e)} style={{ borderRadius: "3px", bottom: "5px", marginLeft: "10px", fontSize: "12px" }} className="btn btn-primary">Save Changes</ActionButton>
                                        </div>
                                    :
                                        this.props.deviceSize === "sm" ?
                                        <div className={`position-relative d-flex justify-content-end mt-3`} style={{ bottom: "9px", right: "25px" }}>
                                            <p onClick={this.editCreatorSettingsRedirect} style={{ cursor: "pointer", borderRadius: "3px", bottom: "5px", textDecoration: "underline", fontSize: "12px" }} className="">Edit Information</p>
                                        </div>
                                        :
                                        <></>
                            }
                            <div style={{ margin: "0 16px", paddingTop: "10px", display: this.state.creatorDisplay ? "" : !["sm"].includes(this.props.deviceSize) ? "" :"none" }}>
                                <CreatorSettings
                                    userInformation={this.props.loggedInUser}
                                    deviceSize={this.props.deviceSize}
                                    paymentEmail={this.state.paymentEmail}
                                    editMode={this.state.creatorSettingsEditMode}
                                    persistentUserInformation={this.state.persistentUserInformation}
                                    updateUserInformation={this.updateCreatorSettingsInformation}
                                    cancelEdit={this.state.cancelCreatorSettingsEdit}
                                    resetCancelEdit={this.resetCancelCreatorSettingsEdit}
                                    updatePaymentEmail={this.updatePaymentEmail}
                                    persistentPaymentEmail={this.state.persistentPaymentEmail}
                                    hideDisplay={["sm"].includes(this.props.deviceSize) && !this.state.creatorDisplay}
                                    isSavingPaymentEmail={this.state.isSavingPaymentEmail}
                                    savePaymentEmail={this.savePaymentEmail}
                                />
                            </div>  

                        </div>
                    </div>
                )
            }
            case 1: {
                return (                    
                    <div>
                        <div>
                            <h6>Saved Links</h6>
                            <p style={{ maxWidth: "600px" }}>
                                Manage your commissionable links. Here, you can easily add new commissionable links, grab existing commissionable links, and add product reviews and activity tags to improve searchability.
                            </p>
                        </div>  
                        <div id="myProducts" className={`d-flex justify-content-end position-relative`} style={{ height: ["sm", "md"].includes(this.props.deviceSize) ? "" : "48px", bottom: ["sm", "md"].includes(this.props.deviceSize) ? "9px" : "-32px", right: ["sm", "md"].includes(this.props.deviceSize) ? "" : "4px", width: ["sm", "md"].includes(this.props.deviceSize) ? "99%" : "" }}>                          
                            <ActionButton onClick={this.props.toggleAddProductOrDeepLinkModal} style={{ zIndex: 1, borderRadius: "3px", bottom: "5px", width: smallSizes.includes(this.props.deviceSize) ? "100%" : "" }} className="btn btn-primary">Create Commissionable Link</ActionButton>
                        </div>
                        <MyProducts
                            setProductsTab={this.setProductsTab}
                            loggedInUser={this.props.loggedInUser}
                            setLoggedInUser={this.props.setLoggedInUser}
                            deviceSize={this.props.deviceSize}
                            setShowTopNav={this.props.setShowTopNav}
                            isModal={false}
                            showBrandNav={this.props.showBrandNav}
                            brandNav={this.props.brandNav}
                            screenWidth={this.props.screenWidth}
                            screenHeight={this.props.screenHeight}
                            creatorDashboard={true}
                            tab={this.productsTab}
                            resetProductsTab={this.resetProductsTab}
                            goToSavedLinks={this.goToSavedLinks}
                        />
                    </div>    
                );
            }
            case 2: {
                return (

                    <div className="container-fluid explore-feed-wrapper px-0">

                        <div className="row m-0">
                            <div className="d-flex justify-content-between w-100">
                                <div>
                                    <h6>My Lockers</h6>
                                    <p style={{ maxWidth: "600px", marginRight: "25px" }}>
                                        Add new lockers and edit your existing lockers to make sharing and shopping quick and easy.
                                    </p>
                                </div>
                                <div className={`d-flex flex-column justify-content-around position-relative`} style={{ bottom: "9px" }}>
                                    <ActionButton onClick={this.addLockerModal} style={{ borderRadius: "3px", bottom: "5px", maxHeight: "40px" }} className="btn btn-outline-brand">Create Locker</ActionButton>
                                    <a href="/Account/Drafts?tab=2" target="_blank" style={{ textDecoration: "underline", textAlign: "right" }}>Drafts</a>
                                </div>
                            </div>
                            <div className={`container ${this.state.lockers?.length > 0 ? "gray-bg px-md-5" : ""} px-0 mt-1 top-section-child`}>
                                <Feed
                                    loggedInUser={this.props.loggedInUser}
                                    posts={this.state.lockers}
                                    isGrid={isGrid}
                                    finishedFetching={this.state.finishedFetchingLockers}
                                    showFollowSuggestions={false}
                                    fetchMore={() => this.fetchLockers(this.state.lockers.length, 24)}
                                    updateParent={(updates) => this.setState({ lockers: updates })}
                                    toggleSignUpModal={this.props.toggleSignUpModal}
                                    deviceSize={this.props.deviceSize}
                                    className={`p-3`}
                                    creatorDashboard={true}
                                />
                            </div>
                        </div>
                    </div>
                    
                );
            }
            case 3: {
                return (

                    <div className="container-fluid explore-feed-wrapper px-0">

                        <div className="row m-0">
                            <div className="d-flex justify-content-between w-100">
                                <div>
                                    <h6>My Posts</h6>
                                    <p style={{ maxWidth: "600px", marginRight: "25px" }}>
                                        Add photos and pull social posts from Instagram, Facebook, YouTube, and TikTok directly to your RockPorch account.
                                    </p>
                                </div>
                                <div className={`d-flex flex-column justify-content-around position-relative`} style={{ bottom: "9px" }}>
                                    <ActionButton onClick={this.props.toggleSharePostModal} style={{ borderRadius: "3px", bottom: "5px", maxHeight: "40px" }} className="btn btn-outline-brand">Create Post</ActionButton>
                                    <a href="/Account/Drafts?tab=1" target="_blank" style={{ textDecoration: "underline", textAlign: "right" }}>Drafts</a>

                                </div>
                            </div>
                            <div className={`container ${this.state.lockers?.length > 0 ? "gray-bg px-md-5" : ""} px-0 mt-1 top-section-child`}>
                                <Feed loggedInUser={this.props.loggedInUser}
                                    posts={this.state.posts}
                                    isGrid={isGrid}
                                    finishedFetching={this.state.finishedFetchingActivity}
                                    showFollowSuggestions={false}
                                    fetchMore={() => this.fetchActivityFeed(this.state.activityFeed.length, 24)}
                                    updateParent={(updates) => this.setState({ activityFeed: updates })}
                                    showPins={true}
                                    toggleSignUpModal={this.props.toggleSignUpModal}
                                    deviceSize={this.props.deviceSize}
                                    creatorDashboard={true}
                                />
                            </div>
                        </div>
                    </div>

                );
            }
            case 4: {
                return (
                    <div>
                        <Brands
                            loggedInUser={this.props.loggedInUser}
                            setLoggedInUser={this.props.setLoggedInUser}
                            deviceSize={this.props.deviceSize}
                            setShowTopNav={this.props.setShowTopNav}
                            isModal={false}
                            showBrandNav={this.props.showBrandNav}
                            brandNav={this.props.brandNav}
                            screenWidth={this.props.screenWidth}
                            screenHeight={this.props.screenHeight}
                            creatorDashboard={true}
                        />
                    </div>
                );
            }
            case 5: {
                return (
                    <div>
                        <CurrentDeals
                            loggedInUser={this.props.loggedInUser}
                            setLoggedInUser={this.props.setLoggedInUser}
                            deviceSize={this.props.deviceSize}
                            setShowTopNav={this.props.setShowTopNav}
                            isModal={false}
                            showBrandNav={this.props.showBrandNav}
                            brandNav={this.props.brandNav}
                            screenWidth={this.props.screenWidth}
                            screenHeight={this.props.screenHeight}
                            creatorDashboard={true}
                        />
                    </div>
                );
            }
            default: {
                return <></>
            }
        }
    }

    taskAction = (actionId, multiplesNumber) => {
        switch (actionId) {
            case 1: {
                return (
                    <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                        <ActionButton onClick={this.editPaypalEmail} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary">Connect PayPal Account</ActionButton>
                    </div>
                );
            }
            case 2: {
                return (
                    <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                        <ActionButton onClick={this.redirectToCreateCommissionableLinkVideo} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary">Watch a quick video to learn how!</ActionButton>
                    </div>
                );
            }
            case 3: {
                return (
                    <div className={`d-flex justify-content-around mb-3 flex-column`} style={{ bottom: "9px" }}>
                        <ActionButton onClick={this.redirectToShareLinkVideo} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary mb-3">Watch a quick video to learn how!</ActionButton>
                        <a href="/Explore" target="_blank" style={{ fontSize: ["sm"].includes(this.props.deviceSize) ? "13px" : "" , borderRadius: "3px", bottom: "5px", textDecoration: "underline" }} className="">Get inspiration from other RockPorch creators!</a>
                    </div>

                );
            }
            case 4: {
                return (
                    <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                        <p style={{ fontSize: "14px" }}>Need help? <a href="#" onClick={(e) => { e.preventDefault(); this.redirectToBookmarkVideo(); }}>Watch a quick video to learn how!</a></p>
                    </div>
                );
            }
                
            default: {
                return <></>
            }
        }
    }

    redirectToBookmarkVideo = () => {
        const url = 'https://www.youtube.com/watch?v=n4q9UqBzkDk';
        const popupWindow = window.open(url, '_blank', 'width=600,height=700');

        if (popupWindow) {
            popupWindow.focus(); // Focus on the newly opened popup window
        } else {
            // If the browser blocks popups, provide an alternative action (e.g., open the link in the current window)
            window.location.href = url;
        }
    }

    redirectToCreateCommissionableLinkVideo = () => {
        const url = 'https://youtu.be/BKEsZhkyoMs?si=ls8-u-ACFm2AdKDC';
        const popupWindow = window.open(url, '_blank', 'width=600,height=700');

        if (popupWindow) {
            popupWindow.focus(); // Focus on the newly opened popup window
        } else {
            // If the browser blocks popups, provide an alternative action (e.g., open the link in the current window)
            window.location.href = url;
        }
    }

    redirectToCreateFirstLockerVideo = () => {
        const url = 'https://youtu.be/vkGqKtt6a5M';
        const popupWindow = window.open(url, '_blank', 'width=600,height=700');

        if (popupWindow) {
            popupWindow.focus(); // Focus on the newly opened popup window
        } else {
            // If the browser blocks popups, provide an alternative action (e.g., open the link in the current window)
            window.location.href = url;
        }
    }

    redirectToCreateStoriesVideo = () => {
        const url = 'https://help.instagram.com/813938898787367';
        const popupWindow = window.open(url, '_blank', 'width=600,height=700');

        if (popupWindow) {
            popupWindow.focus(); // Focus on the newly opened popup window
        } else {
            // If the browser blocks popups, provide an alternative action (e.g., open the link in the current window)
            window.location.href = url;
        }
    }

    redirectToShareLinkVideo = () => {
        const url = 'https://youtu.be/sPttH4seRD4';
        const popupWindow = window.open(url, '_blank', 'width=600,height=700');

        if (popupWindow) {
            popupWindow.focus(); // Focus on the newly opened popup window
        } else {
            // If the browser blocks popups, provide an alternative action (e.g., open the link in the current window)
            window.location.href = url;
        }
    }

    redirectToExplore = () => {
        const url = '/Explore';
        this.props.history.push(url);
    }

    handleInfoClick = (infoTab, whereFrom = "") => {
        if (whereFrom === "touch") {
            this.mobile = true;
        } else if (this.mobile) {
            return;
        }
        this.infoTab = infoTab === this.infoTab ? -1 : infoTab;
        this.forceUpdate();
        if (this.infoTab === -1) {
            this.setState({ activeInfoIndex: 0 });
        }
    }

    renderCalendly = () => {
        const styles = {
            height: '450px'
        }

        return (
            <div style={{ width: "35%", margin: "0 auto" }}>
                <div className="App">
                    <InlineWidget url="https://calendly.com/brad-barnes-tech" style={styles} />
                </div>
            </div>
        );
    }

    renderTaskActions = () => {
        let bookmarkOrigin = window.location.origin;
        let buttonStyle = {
            background: "#D2E3E0"
        };

        switch (this.state.taskActionId) {
            case 1: {
                return (
                    <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0 }} >
                            <strong>CONNECT YOUR PAYPAL</strong>
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            Sales aren't sales until you get paid... we pay out commissions on the 1st of every month (if it's less than $20.00 we roll it over til the next month).
                        </p>
                        <div>
                            {this.taskAction(this.state.taskActionId)}
                        </div>
                        <div className="d-flex justify-content-center" style={{ width: "100%", padding: "0 5%" }} >
                            <div>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.nextTask(); }} style={{ textDecoration: "underline", borderRadius: "3px", bottom: "5px" }}>
                                   Done! Next step, please.
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }
            case 2: {
                return (
                    <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0 }} >
                            <strong>ADD A PRODUCT AND GENERATE YOUR FIRST COMMISSIONABLE LINK</strong>
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            First, decide what product
                            you’d like to share with your followers. Then, generate a commissionable link to the product.
                            Whenever someone clicks on your commissionable links, you’ll earn a commission on any purchase they make from that brand for 30 DAYS! PLUS, links last forever, so.... you can earn from them now, next month, or even next year!
                        </p>
                        <div>
                            {this.taskAction(this.state.taskActionId)}
                        </div>
                        <div className="d-flex justify-content-center" style={{ width: "100%", padding: "0 5%" }} >
                            <div>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.nextTask(); }} style={{ textDecoration: "underline", borderRadius: "3px", bottom: "5px" }}>
                                    Done! Next step, please.
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }
            case 5: {
                return (
                    <div className="text-center" style={{ width: "35%", margin: "0 auto" }}>
                        <p className="mb-4">
                            Drag the button below to your browser's bookmark bar. When on a merchant partner's page, click the RockPorch bookmark to get the shareable link.
                            <Link className="ml-1" id="addProductLearnMore" to="/" onClick={e => {
                                e.preventDefault();
                            //    this.toggleShowLearnMore();
                            }}>
                                Learn more
                            </Link>

                            <Popover placement="bottom"
                                isOpen={this.state.learnMore}
                                target="addProductLearnMore"
                                trigger="legacy"
                                toggle={() => this.setState({ learnMore: true })}
                            >
                                <PopoverBody>
                                    You must be on an individual product page to add a product to My Products, otherwise the tool will create a Deep Link instead.
                                </PopoverBody>
                            </Popover>
                        </p>

                        <div
                            draggable="true"
                            title="Drag to your bookmark bar"
                        >
                            <a href={`javascript:(function() { \n
                                        let location = "${bookmarkOrigin}" +"/Product/AddProductOrDeepLinkModal?url="+ encodeURIComponent(window.location.href);\n
                                        window.open(location,"AddProductOrDeepLink","toolbar=no,status=no,resizable=yes,width=800,height=640,scrollbars=yes")\n
                                    })()`}
                                className="btn"
                                style={buttonStyle} title="Drag to your bookmark bar"
                                onClick={(e) => e.preventDefault()}
                            >
                                <RockporchMountainIcon /> +Add to RockPorch
                            </a>
                        </div>
                    </div>
                );
            }
            case 3: {
                return (
                    <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0 }} >
                            <strong>SHARE YOUR FIRST COMMISSIONABLE LINK</strong>
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            Create an Instagram story, reel, YouTube video, or blog post and feature your product. Add your commissionable link to
                            your content in-platform (or if YouTube, in the comments) and share away!
                        </p>
                        <div>
                            {this.taskAction(this.state.taskActionId)}
                        </div>
                        <div className="d-flex justify-content-center" style={{ width: "100%", padding: "0 5%" }} >
                            <div>
                                <a href="#" onClick={(e) => { e.preventDefault(); this.nextTask(); }} style={{ textDecoration: "underline", borderRadius: "3px", bottom: "5px" }}>
                                    Done! Next step, please.
                                </a>
                            </div>
                        </div>
                    </div>
                );
            }
            case 4: {
                return (
                    <div className={`${this.props.deviceSize === "sm" ? "d-flex flex-column align-items-center" : "justify-content-center"} text-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <div className="d-flex flex-column">
                            <p className={`mb-3 mx-5 ${this.props.deviceSize === "sm" ? "text-center" : ""}`} style={{ margin: 0 }} >
                                <strong>OUR MOST SUCCESSFUL CREATORS START STRONG!
                                </strong>
                            </p>
                            <p className={`mb-3 mx-5 ${this.props.deviceSize === "sm" ? "text-center" : ""}`} style={{ margin: 0, lineHeight: "24px" }} >
                                You’re most likely to
                                jumpstart your earnings by sharing 10 links in your first 10 days of becoming
                                a RockPorch creator.
                            </p>
                            {/*<div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>*/}
                            {/*    <a href="#" onClick={(e) => { e.preventDefault(); this.redirectToExplore(); }} style={{ fontSize: ["sm"].includes(this.props.deviceSize) ? "13px" : "", borderRadius: "3px", bottom: "5px", textDecoration: "underline" }} className="">Get inspiration from other RockPorch creators!</a>*/}
                            {/*</div>*/}
                            {/*<p onClick={() => this.setState({ seeMore: !this.state.seeMore })} style={{ cursor: "pointer", color: "#d46240", textDecoration: "underline" }}>{this.state.seeMore ? "See less..." : "See more..."}</p>*/}
                        </div>
                        <div className={`d-flex flex-column`} style={{ display: this.state.seeMore ? "" : "" }}>
                            <p className={`mb-3 mx-5 ${this.props.deviceSize === "sm" ? "text-center" : ""}`} style={{ margin: 0, lineHeight: "24px" }} >
                                Want to make it easy to create new links? Simply install the Link Generator Bookmark.  
                                Just drag the button below to your browser's bookmark bar. When on a brand partner's page, just click the RockPorch bookmark to get your shareable, commissionable link. No more back and forth
                                between brand sites and your RockPorch dashboard... and you’re off to the races!
                            </p>
                            <p className={`mb-3 mx-5 ${this.props.deviceSize === "sm" ? "text-center" : ""}`} style={{ margin: 0, lineHeight: "24px" }} >
                                Tip: You can access the
                                    bookmark tool on mobile, too
                            </p>

                            <div
                                className="mx-auto"
                                draggable="true"
                                title="Drag to your bookmark bar"
                            >
                                <a href={`javascript:(function() { \n
                                        let location = "${bookmarkOrigin}" +"/Product/AddProductOrDeepLinkModal?url="+ encodeURIComponent(window.location.href);\n
                                        window.open(location,"AddProductOrDeepLink","toolbar=no,status=no,resizable=yes,width=800,height=640,scrollbars=yes")\n
                                    })()`}
                                    className="btn"
                                    style={buttonStyle} title="Drag to your bookmark bar"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <RockporchMountainIcon /> + RockPorch Tool
                                </a>
                                <p className="mt-2">Drag button to your browser bookmark bar</p>
                                <div>
                                    {this.taskAction(this.state.taskActionId)}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center" style={{ width: "100%", padding: "0 5%" }} >
                                <div>
                                    <a href="#" onClick={(e) => { e.preventDefault(); this.finishTasks(); }} style={{ textDecoration: "underline", borderRadius: "3px", bottom: "5px" }}>
                                        All Done!
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            default: {
                return (
                    <div className={`d-flex justify-content-center ${this.state.taskActionId === 5 ? "align-items-baseline" : ""}`} style={{ margin: "0 auto" }}>
                        <p style={{ width: "250px", margin: 0 }} >
                        </p>
                        <div>
                        </div>
                    </div>
                );
            }
        }

        
    }

    toggleProfileShareModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    displayShareModal = () => {
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleProfileShareModal}>
                <ShareBlock shareCode={this.state.shareLink}
                    title="Profile share"
                    customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                    shareBlockStyle={{ border: "none", backgroundColor: "transparent" }}
                    loggedInUser={this.props.loggedInUser}
                />
            </Modal>
        )
    }

    resetInfoIcon = () => {
        this.infoTab = -1;
        this.forceUpdate();
        this.setState({ activeInfoIndex: 0 });
    }

    renderInfoIcon = () => {
        let buttonStyle = {
            background: "#D2E3E0"
        };

        const tab1Items = [
            <CarouselItem>
                <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                    <p className={`mb-3 mx-4 text-center w-100`} style={{ margin: 0 }} >
                        <strong>UPDATE YOUR EXISTING LINKS</strong>
                    </p>
                    <p className={`mb-3 mx-4 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                        If you have existing affiliate links in the market, update them using your RockPorch commissionable links. WHY IS THIS IMPORTANT?
                    </p>
                    <p className={`mb-3 mx-4 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                        Because you'll increase your chances for getting paid brand campaigns. Our brand partners consider your sales data (among other things) when evaluating creators for campaigns. Having all your sales consolidated in one platform means a more impressive "resume."
                    </p>
                </div>
            </CarouselItem>,
            <CarouselItem>
                <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                    <p className={`mb-3 mx-4 text-center w-100`} style={{ margin: 0 }} >
                        <strong>CREATE A STORIES HIGHLIGHT</strong>
                    </p>
                    <p className={`mb-3 mx-4 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                        Keep your top product recs front and center and easy to find for your fans by saving every social story that includes a commissionable link to a "Links," "Products," or similar highlight.                     </p>
                    <div>
                        <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                            <ActionButton onClick={this.redirectToCreateStoriesVideo} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary">HOW TO CREATE A STORIES HIGHLIGHT</ActionButton>
                        </div>
                    </div>
                </div>
            </CarouselItem>
        ]

        switch (this.state.tab) {
            case 0: {
                return (
                    <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <p className={`mb-3 mx-5 text-center w-100 position-relative`} style={{ margin: 0 }} >
                            <strong>SHARE YOUR PROFILE URL</strong>
                            <span onClick={this.resetInfoIcon} style={{ position: "absolute", right: "0px", cursor: "pointer", float: "right", fontSize: "10px", textDecoration: "underline" }}>dismiss</span>
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                        Add your RockPorch Profile URL to your social profile or link bio. Now you can send your followers directly to your RockPorch profile to find products you have shared with them (and you'll earn commissions every time they purchase.)
                        </p>
                        <div>
                            <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                                <ActionButton onClick={this.toggleProfileShareModal} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary">SHARE MY PROFILE URL</ActionButton>
                            </div>
                        </div>
                    </div>
                );
            }
            case 1: {
                return (
                    <div className={`position-relative pt-0 creator-dashboard-info mb-0 justify-content-center pb-0 position-relative`} id="contentDiv" style={{ margin: "0 auto" }}>
                        <span onClick={this.resetInfoIcon} style={{ zIndex: "2", position: "absolute", right: "0px", cursor: "pointer", float: "right", fontSize: "10px", textDecoration: "underline" }}>dismiss</span>

                        <div className="position-absolute" style={{ zIndex: "1", top: "50%", left: ["sm"].includes(this.props.deviceSize) ? "-4%" : ["sm", "md"].includes(this.props.deviceSize) ? "-2%" : "2%", transform: "translate(0, -50%)" }}>
                            <PreviousIcon onClick={() => this.infoPrevious(tab1Items.length)} className={`creator-dashboard-prev-next`} height={75} width={36} opacity={0.1} style={{ transform: "scaleY(2.5)" }} />
                        </div>
                        <Carousel
                            activeIndex={this.state.activeInfoIndex}
                            next={() => this.next(tab1Items.length)}
                            previous={() => this.previous(tab1Items.length)}
                            style={{ overflow: "visible" }}
                            interval={null}
                            keyboard={false}
                            controls={false}
                            className="creator-dashboard-info-carousel-slide"
                        >
                            {tab1Items}
                            {/*<CarouselControl direction="prev" directionText="Previous" onClickHandler={() => this.previous(menuRender.length)} />*/}
                            {/*<CarouselControl direction="next" directionText="Next" onClickHandler={() => this.next(menuRender.length)} />*/}
                        </Carousel>
                        <div className="position-absolute" style={{ zIndex: "1", top: "50%", right: ["sm"].includes(this.props.deviceSize) ? "-5%" : ["sm", "md"].includes(this.props.deviceSize) ? "-2%" : "2%", transform: "translate(0, -50%)" }}>
                            <NextIcon onClick={() => this.infoNext(tab1Items.length)} className={`creator-dashboard-prev-next`} height={75} width={36} opacity={0.1} style={{ transform: "scaleY(2.5)" }} />
                        </div>
                    </div>
                );
            }
            case 2: {
                return (
                    <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <p className={`mb-3 mx-5 text-center w-100 position-relative`} style={{ margin: 0 }} >
                            <strong>CREATE YOUR FIRST ROCKPORCH LOCKER</strong>
                            <span onClick={this.resetInfoIcon} style={{ position: "absolute", right: "0px", cursor: "pointer", float: "right", fontSize: "10px", textDecoration: "underline" }}>dismiss</span>
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            RockPorch Lockers are like an Amazon Storefront, with a SOUL! 
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            Create any kind of curated list of products you like, but a good starting place might be an evergreen collection of your essentials like “Top 10 Never Camp/Run/Hike Without.” 
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            Or if you get asked often, “Where did you get that?,” create a “Most Asked About Products” Locker. No more hunting down products to share with your people. Now, just send them the commissionable link to your Locker.
                        </p>
                        <div>
                            <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                                <ActionButton onClick={this.redirectToCreateFirstLockerVideo} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary">WATCH A QUICK VIDEO TO LEARN HOW</ActionButton>
                            </div>
                        </div>
                    </div>
                );
            }
            case 3: {
                return (
                    <div className={`d-flex flex-column align-items-center`} style={{ margin: "0 auto", maxWidth: "800px" }}>
                        <p className={`mb-3 mx-5 text-center w-100 position-relative`} style={{ margin: 0 }} >
                            <strong>SHARE YOUR PROFILE URL</strong>
                            <span onClick={this.resetInfoIcon} style={{ position: "absolute", right: "0px", cursor: "pointer", float: "right", fontSize: "10px", textDecoration: "underline" }}>dismiss</span>
                        </p>
                        <p className={`mb-3 mx-5 text-center`} style={{ margin: 0, lineHeight: "24px" }} >
                            Add your RockPorch Profile URL to your social profile or link bio. Now you can send your followers directly to your RockPorch profile to find products you have shared with them (and you'll earn commissions every time they purchase.)
                        </p>
                        <div>
                            <div className={`d-flex justify-content-around mb-3`} style={{ bottom: "9px" }}>
                                <ActionButton onClick={this.editPaypalEmail} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-primary">SHARE MY PROFILE URL</ActionButton>
                            </div>
                        </div>
                    </div>
                );
            }
            default: {
                return (
                    <div>
                    </div>
                );
            }
        }


    }

    renderOption = ({ value, label }) => {
        const selectedOption = this.state.selectContentOptions?.options?.find(option => option.value === value);

        if (value < 3) {
            return (
                <div className="custom-option" style={{ cursor: "pointer" }}
                    onClick={() => { this.onTabChange(value); this.handleContentSelect({ selectedOption }); }}
                    onTouchStart={() => { this.onTabChange(value); this.handleContentSelect({ selectedOption }); }}
                >
                    <span className={`nav-link text-around`}>{label}</span>
                </div>
            );
        }
        return (
            <div className="custom-option" style={{ cursor: "pointer" }}
                onClick={() => { this.onTabChange(value); this.handleContentSelect({ selectedOption }); }}
                onTouchStart={() => { this.onTabChange(value); this.handleContentSelect({ selectedOption }); }}
            >
                <span className={`nav-link text-around`}>{label}</span>
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.displayShareModal()}
                {
                    this.state.marqueeHtml ?
                        <div style={{ borderTop: "1px solid", borderBottom: "1px solid", backgroundColor: this.state.marqueeBackgroundColor ?? "", height: "40px", marginTop: ["sm", "md"].includes(this.props.deviceSize) ? "55px" : "66px", position: "relative", top: ["sm", "md"].includes(this.props.deviceSize) ? "-4px" : "0px" }}>
                            {
                                !!(this.state.marqueeRedirectUrl) ?
                                    <a href={this.state.marqueeRedirectUrl} target="_blank" style={{ cursor: "pointer", zIndex: 0 }}>
                                        <Marquee style={{ height: "100%", zIndex: 0, }}>
                                            <div className="d-flex align-items-center" dangerouslySetInnerHTML={{ __html: this.state.marqueeHtml }} />
                                        </Marquee>
                                    </a>
                                    :
                                    <Marquee style={{ height: "100%", zIndex: 0, }}>
                                        <div className="d-flex align-items-center" dangerouslySetInnerHTML={{ __html: this.state.marqueeHtml }} />
                                    </Marquee>
                            }
                        </div>
                        :
                        <></>
                }
               

            <section className="container">
                <Helmet>
                    <title>RockPorch - Creator Dashboard</title>
                </Helmet>

                    <div className="full-height">

                    <div>
                        <CreatorDashboardHeader
                            loggedInUser={this.props.loggedInUser}
                            deviceSize={this.props.deviceSize}
                            renderCalendly={this.redirectToCalendly}
                            setLoggedInUser={this.props.setLoggedInUser}
                        />
                        <div className={`py-${this.state.hideTasks ? "3" : ["sm"].includes(this.props.deviceSize) ? "3" : "3 pt-5"} mt-0`}>
                            {this.renderTasks()}
                        </div>

                            <div className={`mt-0 mx-auto pb-0 ${["xl"].includes(this.props.deviceSize) ? "pt-2" : ""}`} id="contentMenu" style={{ backgroundColor: ["xl"].includes(this.props.deviceSize) ? "#fcf2f0" : "" }}>
                            {this.renderAnalytics()}
                        </div>

                            <div className={`${["sm", "xl"].includes(this.props.deviceSize) ? "" : "mt-5"}`} style={{ margin: "0 auto" }}>
                                <div className={`w-100 d-flex justify-content-between align-items-baseline mt-4 mb-4`} style={{ borderTop: ["sm"].includes(this.props.deviceSize) ? "" : "1px solid lightgray", borderLeft: ["sm"].includes(this.props.deviceSize) ? "" : "1px solid lightgray", borderRight: ["sm"].includes(this.props.deviceSize) ? "" : "1px solid lightgray", borderBottom: "1px solid lightgray", borderColor: "lightgray" }}>
                                {
                                ["sm"].includes(this.props.deviceSize) ?
                                <div className="w-100" onClick={(e) => this.toggleDropdown(e, "divClick")} onTouchStart={(e) => this.toggleDropdown(e, "touch")}>
                                    <Select
                                        isMulti={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        blurInputOnSelect={true}
                                        captureMenuScroll={true}
                                        onChange={(e) => { this.handleContentSelect(e) }}
                                        options={this.state.selectContentOptions.options}
                                        value={this.state.selectContentOptions.currentValue}
                                        components={{ SingleValue: (props) => <CustomSingleValue {...props} toggleDropdown={this.toggleDropdown} handleInfoClick={this.handleInfoClick} />, Option: this.renderOption }}
                                        menuIsOpen={this.menuIsOpen}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                fontSize: "16px",
                                                outerHeight: "16px",
                                                background: "transparent",
                                                minHeight: 0,
                                                fontFamily: "Archivo, sf_pro_displayregular, sans-serif",
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                position: "relative",
                                                display: "inline-block",
                                                width: "100%",
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: "none"
                                            }),
                                            indicatorContainer: (provided) => ({
                                                ...provided,
                                                padding: 0,
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                lineHeight: "20px",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                position: 'absolute', // Set the menu position to absolute
                                                zIndex: '9999', // Ensure higher z-index to overlap other elements
                                                width: "100%",
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                            }),
                                        }}
                                                />
                                        </div>
                                        :
                                        <div>
                                                <ul className={`nav justify-content-${["sm"].includes(this.props.deviceSize) ? "center" : "start"} position-relative`} style={{ fontFamily: "Archivo, sf_pro_displayregular, sans-serif", fontSize: this.props.screenWidth < 991 && this.props.screenWidth > 766 ? "13px" : "" }}>
                                                    <li className={`nav-item ${this.state.tab === 0 ? "btn-secondary" : ""}`} style={{ cursor: "pointer" }} onClick={() => this.onTabChange(0)}>
                                                        <span className={`nav-link text-around`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Profile Details<button onClick={() => this.handleInfoClick(0)} style={{ backgroundColor: "transparent", position: "relative", bottom: "6px", left: "3px", cursor: "pointer", outline: "none", border: 0, padding: 0, }}><InformationIcon fill={this.state.tab === 0 ? "white" : ""} fillOpacity={this.state.tab === 0 ? "1" : ""} /></button></span>
                                                </li>
                                                    <li className={`nav-item ${this.state.tab === 1 ? "btn-secondary" : ""}`} style={{ cursor: "pointer" }} onClick={() => this.onTabChange(1)}>
                                                        <span className={`nav-link text-center`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Saved Links<button onClick={() => this.handleInfoClick(1)} style={{ backgroundColor: "transparent", position: "relative", bottom: "6px", left: "3px", cursor: "pointer", outline: "none", border: 0, padding: 0 }}><InformationIcon fill={this.state.tab === 1 ? "white" : ""} fillOpacity={this.state.tab === 1 ? "1" : ""} /></button></span>
                                                </li>
                                                    <li className={`nav-item ${this.state.tab === 2 ? "btn-secondary" : ""}`} style={{ cursor: "pointer" }} onClick={() => this.onTabChange(2)}>
                                                        <span className={`nav-link text-center`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Lockers<button onClick={() => this.handleInfoClick(2)} style={{ backgroundColor: "transparent", position: "relative", bottom: "6px", left: "3px", cursor: "pointer", outline: "none", border: 0, padding: 0 }}><InformationIcon fill={this.state.tab === 2 ? "white" : ""} fillOpacity={this.state.tab === 2 ? "1" : ""} /></button></span>
                                                </li>
                                                    <li className={`nav-item ${this.state.tab === 3 ? "btn-secondary" : ""}`} style={{ cursor: "pointer" }} onClick={() => this.onTabChange(3)}>
                                                        <span className={`nav-link text-center`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Posts</span>
                                                </li>
                                                    <li className={`nav-item ${this.state.tab === 4 ? "btn-secondary" : ""}`} style={{ cursor: "pointer" }} onClick={() => this.onTabChange(4)}>
                                                        <span className={`nav-link text-center`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Brand Partners</span>
                                                </li>
                                                    <li className={`nav-item ${this.state.tab === 5 ? "btn-secondary" : ""}`} style={{ cursor: "pointer" }} onClick={() => this.onTabChange(5)}>
                                                        <span className={`nav-link text-center`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Current Deals</span>
                                                    </li>
                                                <li className={`nav-item dashboard-tab-refer-a-creator`} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
                                                }}>
                                                        <span className={`nav-link text-center`} style={{ padding: ["md"].includes(this.props.deviceSize) ? ".5rem 0.6rem" : "" }}>Refer A Creator</span>
                                                </li>
                                            </ul>
                                            </div>
                                    }
                                </div>
                                    {
                                        this.state.tab === this.infoTab ?
                                        <div className="p-3 mb-4" style={{ backgroundColor: "rgb(233, 236, 239)" }}>
                                            {this.renderInfoIcon()}
                                        </div>

                                                :
                                             <></>
                                    }
                            <div className="mb-5" style={{ zIndex: 9999 }}>
                                {this.displayContent()}
                            </div>
                        </div>
                        
                    </div>
                </div>
                <CreatorDashboardContactUs deviceSize={this.props.deviceSize} />
                </section>
            </div>
        );

    }

};

function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer };
    return result;
}

export default connect(mapStateToProps, { toggleFollowersFollowingModal, closeAllModals, toggleProductPageModal, toggleFetchFollowing, toggleCreateLockerModal, toggleShareModal, toggleSharePostModal, toggleShareBlogModal, toggleAddProductOrDeepLinkModal })(withRouter(CreatorDashboard));