import React from "react";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from "lodash";
import AccountBanner from "components/AccountBanner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import toast, { Toaster } from 'react-hot-toast';
import $ from 'jquery';

class CreatorPaymentRunTotals extends React.Component {
    state = {
        loading: this.props.loading,
        paymentRunDetailInfos: {},
        creatorPaymentRunTotals: [],
        reversingPayments: false,
        affiliateTable: {
            title: "Affiliate Networks",
            columns: [
                {
                    name: "userId",
                    label: "User Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "userName",
                    label: "User Name",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "userPaymentEmail",
                    label: "Payment Email",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "amount",
                    label: "Creator Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.creatorPaymentRunTotals[dataIndex];
                            if (item) {
                                return '$' + item.amount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
            ],
            data: [],
            options: {
                customToolbarSelect: () => { },
                selectableRows: "multiple",
                sortOrder: {
                    name: 'userId',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle MuiTypography-subtitle1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.handlePaymentReversal(selectedRows, displayData); }}>Reverse Payment</button>
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        },
    }

    paymentRunId = this.props.paymentRunId;
    affiliateDetailTableWrapperRef = React.createRef();
    affiliateDetailTableMessageTextboxRef = React.createRef();

    componentDidMount = async () => {
        if (!!(this.props.paymentRunDetailInfos)) {
            this.processCreatorPaymentRunTotals();
        }
    }

    processCreatorPaymentRunTotals = () => {
        let creatorPaymentRunTotals = this.props.paymentRunDetailInfos.reduce((totals, payment) => {
            const { userId, userName, userPaymentEmail, amount, commissionId } = payment;

            if (!totals[userId]) {
                totals[userId] = {
                    userId,
                    userName,
                    userPaymentEmail,
                    amount: 0,
                    commissionIds: []
                };
            }

            totals[userId].amount += amount;
            if (!totals[userId].commissionIds.includes(commissionId)) {
                totals[userId].commissionIds.push(commissionId);
            }

            return totals;
        }, {});

        creatorPaymentRunTotals = Object.values(creatorPaymentRunTotals);

        this.setState({ creatorPaymentRunTotals });
    }



    handlePaymentReversal = async (selectedRows, displayData) => {
        this.setState({ reversingPayments: true });

        const selectedRowsData = selectedRows.data;
        const creatorData = this.state.creatorPaymentRunTotals;
        const userId = selectedRowsData.map((row) => displayData[row.index])[0].data[0];

        const filteredData = creatorData.filter((creator) => creator.userId === userId);
        const creatorUserNames = [];

        const creatorSubmissionArray = filteredData.map(fd => {
            creatorUserNames.push(fd.userName)
            return {
                userId: fd.userId,
                paymentRunId: this.props.paymentRunId,
                creatorCommissions: fd.amount,
                commissionIds: fd.commissionIds
            };
        });


        let url = 'api/merchant/ReversePaymentRunByCreator';
        let result = await postRequest(url, creatorSubmissionArray);

        this.setState({ reversingPayments: false });

        await this.handlePaymentReversalToast(creatorUserNames, this.props.paymentRunId, result);
    }

    handlePaymentReversalToast = (reversedCreators, paymentRunId, resultSuccess) => {
        const reversedCreatorsCount = reversedCreators.length;

        if (resultSuccess) {
            let reversedCreatorsString = '';

            if (reversedCreators.length === 1) {
                reversedCreatorsString = <span style={{ fontWeight: "bold" }}>{reversedCreators[0]}</span>;
            } else if (reversedCreators.length === 2) {
                reversedCreatorsString = (
                    <>
                        <span style={{ fontWeight: "bold" }}>{reversedCreators[0]}</span>
                        {' and '}
                        <span style={{ fontWeight: "bold" }}>{reversedCreators[1]}</span>
                    </>
                );
            } else if (reversedCreators.length > 2) {
                const lastCreator = reversedCreators.pop();
                const creatorsJoined = reversedCreators.map(creator => <span style={{ fontWeight: "bold" }}>{creator}</span>);
                reversedCreatorsString = (
                    <>
                        {creatorsJoined.reduce((prev, curr) => [prev, ', ', curr])}
                        {' and '}
                        <span style={{ fontWeight: "bold" }}>{lastCreator}</span>
                    </>
                );
            }

            toast.success(
                <div className="p-2">
                    <h3 className="text-center">Success</h3>
                    <hr />
                    <p><small>REVERSED <span style={{ fontWeight: "bold" }}>{reversedCreatorsCount} {reversedCreatorsCount === 1 ? "record" : "records"}</span></small></p>
                    <p><small>FOR {reversedCreatorsString}</small></p>
                    <p><small>in <span style={{ fontWeight: "bold" }}>Payment Run {paymentRunId}</span></small></p>
                </div>,
                {
                    duration: 7000,
                    position: 'top-center'
                }
            );
        } else {
            toast.success(
                <div className="p-2">
                    <h3 className="text-center" style={{ color: "red" }}>Error</h3>
                    <hr />
                    <p style={{ color: "red" }} ><small>SOMETHING WENT WRONG! Try again.</small></p>
                </div>,
                {
                    duration: 4000,
                    position: 'top-center'
                }
            );
        }
    }


    render() {
        return (
            <>
                <Toaster />
                <section>
                    {
                        this.state.reversingPayments ?
                            <LoadSpinnerOverlay />
                            : <></>
                    }
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.affiliateTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.affiliateTable.title}
                                        data={this.state.creatorPaymentRunTotals}
                                        columns={this.state.affiliateTable.columns}
                                        options={this.state.affiliateTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>                   
            </>
        )
    }
}

export default CreatorPaymentRunTotals;