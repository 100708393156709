import React from "react";

const FacebookIconNoLink = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M24.3879 0.200195H1.61246C0.832729 0.200195 0.200195 0.832729 0.200195 1.61246V24.3879C0.200195 25.1687 0.832729 25.8002 1.61246 25.8002H13.8727V15.8866H10.5351V12.0242H13.8727V9.17086C13.8727 5.86526 15.893 4.06686 18.8423 4.06686C20.2557 4.06686 21.4706 4.17033 21.8247 4.21726V7.67326H19.7757C18.1757 7.67326 17.8642 8.44233 17.8642 9.56233V12.0274H21.6871L21.1911 15.8994H17.8642V25.8002H24.3869C25.1687 25.8002 25.8002 25.1687 25.8002 24.3879V1.61246C25.8002 0.832729 25.1687 0.200195 24.3879 0.200195Z"
                    fill="#212529" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="25.6" height="25.6" fill="white"
                        transform="translate(0.200195 0.200195)" />
                </clipPath>
            </defs>
        </svg>
        )
}

export default FacebookIconNoLink;