import React from "react";

import AccountBanner from "components/AccountBanner";

import { getRequest, postRequest } from "sharedUtils/httpUtils";
import ActionButton from "components/ActionButton";
import FormInputField from "../../components/FormInputField";

//{sectorId,name}
class Sectors extends React.Component {

    state = {
        sectors: [],
        sectorCreateInput: ""
    }

    async componentDidMount() {
        let sectors = await this.fetchSectors();
        this.setState({ sectors })
    }

    addSector = async (sectorName) => {
        if (sectorName.length > 0) {
            let sector = { name: sectorName };
            let sectors = [...this.state.sectors];
            sectors.push(sector);
            this.setState({ sectors, sectorCreateInput: "" });

            await postRequest(`api/Sector/CreateSector?text=${sectorName}`);
        }

        //make http request here
    }

    checkIfSectorExists = (sectorName) => {
        let existing = this.state.sectors.filter((sector) => sector.name.toLowerCase() === this.state.sectorCreateInput.toLowerCase());
        if (existing.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    fetchSectors = async () => {
        let sectors = await getRequest(`api/Sector/GetSectors`);
        return sectors;
    }

    displaySectors = () => {

        let sorted = this.state.sectors.filter((sector) => {
            return sector.name.toLowerCase().includes(this.state.sectorCreateInput.toLowerCase())
        }).sort((a, b) => {
            if (b.name.charAt(0) > a.name.charAt(0)) {
                return -1
            }
            else {
                return 1
            }

        });
        //test.
        return sorted.map((sector, i) => {
            return (
                <button key={i} className="btn btn-light d-flex align-items-center" disabled>{sector.name}</button>
            )
        })
    }

    handleSectorInput = (e) => {
        this.setState({ sectorCreateInput: e.target.value })
    }

    render() {
        return (

            <div>
                <section>
                    <AccountBanner>
                        <h1 className="mb-4">Sectors</h1>
                        <p className="mb-0">Create sectors</p>
                    </AccountBanner>
                </section>

                <section className="gray-bg full-height">
                    <div className="container py-5">

                       
                        <div className="input-group mb-3">

                            <input type="text"
                                class="form-control"
                                placeholder="New Sector"
                                onChange={this.handleSectorInput}
                            />

                            <div className="input-group-append">
                                <ActionButton className="btn btn-primary"
                                    isDisabled={this.state.sectorCreateInput.length === 0 || this.checkIfSectorExists(this.state.sectorCreateInput) === true}
                                    onClick={() => this.addSector(this.state.sectorCreateInput)}
                                >
                                    Add Sector
                                </ActionButton>
                            </div>
                        </div>
                        <div className="tag-content-sm d-flex justify-content flex-wrap py-3">
                            {this.displaySectors()}
                        </div>

                    </div>
                </section>
            </div>
        )
    }

}



export default Sectors;