import * as React from "react";
import { NavLink } from "react-router-dom";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { postRequest } from "sharedUtils/httpUtils";

class OnboardGettingStarted extends React.Component {

    state = {
        activeIndex: 0,
        animating: false
    }

    convertToUserModel = () => {
        return {
            name: this.props.loggedInUser.name,
            firstName: this.props.loggedInUser.firstName,
            lastName: this.props.loggedInUser.lastName,
            bio: this.props.loggedInUser.bio,
            tagLine: this.props.loggedInUser.tagLine,
            website: this.props.loggedInUser.website,
            googleUsername: this.props.loggedInUser.googleUsername,
            facebookUsername: this.props.loggedInUser.facebookUsername,
            instagramUsername: this.props.loggedInUser.instagramUsername,
            imageUrl: this.props.loggedInUser.imageUrl,
            userName: this.props.loggedInUser.userName,
            email: this.props.loggedInUser.email,

            phoneNumber: this.props.loggedInUser.phoneNumber,
            proStatusId: this.props.loggedInUser.proStatusId,
            address: this.props.loggedInUser.address,
            city: this.props.loggedInUser.city,
            state: this.props.loggedInUser.state,
            postalCode: this.props.loggedInUser.postalCode,
            country: this.props.loggedInUser.country,

            onboardingComplete: this.props.loggedInUser.onboardingComplete,
            profileBannerUrl: this.props.loggedInUser.profileBannerUrl,

            quickStart: this.props.loggedInUser.quickStart
        }
    }

    saveUser = async () => {
        let userUrl = '/api/Account/PostUser';
        this.props.loggedInUser.onboardingComplete = true;
        let user = await postRequest(userUrl, this.convertToUserModel());
        this.props.setLoggedInUser(user);
    }

    items = [
        {
            src: 'https://picsum.photos/600/400',
            altText: 'Slide 1',
            caption: 'Slide 1',
            key: 1
        },
        {
            src: 'https://picsum.photos/600/401',
            altText: 'Slide 2',
            caption: 'Slide 2',
            key: 2
        },
        {
            src: 'https://picsum.photos/600/402',
            altText: 'Slide 3',
            caption: 'Slide 3',
            key: 3
        },
        {
            src: 'https://picsum.photos/600/403',
            altText: 'Slide 4',
            caption: 'Slide 4',
            key: 4
        },
        {
            src: 'https://picsum.photos/600/404',
            altText: 'Slide 5',
            caption: 'Slide 5',
            key: 5
        },
    ];

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    slides = this.items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => this.setState({ animating: true })}
                onExited={() => this.setState({ animating: false })}
                key={item.src}
            >
                <img width="100%" src={item.src} alt={item.altText} />
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });

    componentDidMount = async () => {
        this.props.setShowTopNav(false);
    }

    onSubmitClick = async () => {
        this.props.setShowTopNav(true);
        await this.saveUser();
        this.props.history.push('/HomeFeed');
    }

    render = () => {
        return (
            <div className="container-fluid gray-bg full-height">
                <div className="row mt-0">
                    <div className="col-12 px-0 top-banner position-relative">
                        <img src="images/top-banner.png" alt="" className="w-100 h-100" />
                        <div className="interest-steps position-absolute text-center w-100">
                            <h1 className="mb-4">Getting Started</h1>
                            <p>Click through to view different components of the platform</p>
                            <ul className="steps-count pl-0">
                                <li className="d-inline-flex justify-content-center align-items-center active">1</li>
                                <li className="d-inline-flex justify-content-center align-items-center active">2</li>
                                <li className="d-inline-flex justify-content-center align-items-center active">3</li>
                                <li className="d-inline-flex justify-content-center align-items-center active">4</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 who-to-follow pt-4">
                        <div className="container">
                            <div className="row pt-0 mt-0 justify-content-center pb-5">
                                <Carousel
                                    activeIndex={this.state.activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={this.items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                                    {this.slides}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row py-4 position-fixed white-bg secondary-footer">
                    <div className="col-12 text-center text-lg-right">
                        <NavLink to="/HomeFeed">
                            <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.onSubmitClick() }} >Continue to Your Feed</button>
                        </NavLink>
                    </div>
                </div>
            </div>)
    }
}

export default OnboardGettingStarted;