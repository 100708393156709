import React from "react"

const MailIcon = (props) => {
    return (
        <a href={"mailto:?subject=Check out what I found on RockPorch&body=" + props.shareCode} className="mail d-flex justify-content-center align-items-center mx-2">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M4.70387 5.53369H21.2965C22.6056 5.53369 23.6668 6.56229 23.6668 7.83113V18.1696C23.6668 19.4384 22.6056 20.467 21.2965 20.467H4.70387C3.39475 20.467 2.3335 19.4384 2.3335 18.1696V7.83113C2.3335 6.56229 3.39475 5.53369 4.70387 5.53369ZM20.2824 7.64693L13.0002 11.4475L5.71788 7.64693C5.28564 7.42134 4.74656 7.57809 4.51382 7.99703C4.28107 8.41597 4.4428 8.93846 4.87504 9.16405L12.5787 13.1846C12.8418 13.3219 13.1585 13.3219 13.4216 13.1846L21.1253 9.16405C21.5575 8.93846 21.7192 8.41597 21.4865 7.99703C21.2538 7.57809 20.7147 7.42134 20.2824 7.64693Z"
                    fill="#212529" /> </svg>
        </a>
    )
}

export default MailIcon;