import React from "react";

const DraggablePhoto = (props) => {
    return (
        <img
            src={props.url}
            style={{
                height: props.height,
                width: props.width,
                border: "1px solid #efecec",
                borderRadius: "10px",
                objectFit: "cover",
            }}
        />
    )
}

export default DraggablePhoto;