import React from "react";
import { withRouter } from 'react-router-dom';
import AccountBanner from "../../components/AccountBanner"
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "../../components/LoadSpinner";
import InformationIcon from "components/icons/InformationIcon";
import { Popover, UncontrolledPopover, PopoverBody } from "reactstrap";
import Select from 'react-select';
import { debounce } from "throttle-debounce";
import Modal from "../../components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import MerchantShareBlock from "components/Share/MerchantShareBlock";
import { toggleProductPageModal, toggleShareModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getMerchantShareLink } from "../../sharedUtils/shareLinkPrefixes"
import ShareIosIcon from "../../components/icons/ShareIosIcon";
import { Helmet } from 'react-helmet';
import { flatMap } from "lodash";

class CurrentDeals extends React.Component {

    constructor(props) {
        super(props)

        this.selectOptions = {
            name: {
                value: 1,
                label: "Name",
            },
            recentlyAdded: {
                value: 2,
                label: "Recently Added"
            },
            commission: {
                value: 3,
                label: "Estimated Commission"
            },
        }

        this.state = {
            modalOpen: false,
            shareLink: "",
            partners: [],
            loading: true,
            currentDeals: [],
            upcomingCurrentDeals: [],
        }
    }

    breadCrumbList = [{ link: '/Account/CreatorDashboard', text: 'Creator Dashboard' }, { link: '/Account/Brands', text: 'Merchant Partners', active: true }];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }

        if (!this.props.creatorDashboard) {
            window.scrollTo(scrollOptions);
        }

        await Promise.all([
            this.getPartners(),
        ]);

        await this.getCurrentDeals();

        this.setState({ loading: false })
    }

    getCurrentDeals = async () => {
        const getUrl = "api/Merchant/GetCurrentDeals?admin=false&upcoming=true";
        const currentDealsInitial = await getRequest(getUrl);
        if (!!(currentDealsInitial) && this.state.partners.length > 0) {
            const currentDeals = this.addMerchantNameAndLogoToDeals(currentDealsInitial);

            const today = new Date();
            today.setHours(0, 0, 0, 0); // Reset time part to 00:00:00

            const upcomingDeals = [];
            const remainingCurrentDeals = [];

            currentDeals.forEach(deal => {
                const startDateUTC = new Date(deal.startDate);
                const startDateLocal = new Date(startDateUTC.getTime() - (startDateUTC.getTimezoneOffset() * 60000));
                startDateLocal.setHours(0, 0, 0, 0); // Reset time part to 00:00:00

                if (startDateLocal > today) {
                    deal.startDateLocal = startDateLocal;
                    deal.upcoming = true;
                    upcomingDeals.push(deal);
                } else {
                    deal.startDateLocal = startDateLocal;
                    deal.upcoming = false;
                    remainingCurrentDeals.push(deal);
                }
            });

            const dealsWithOrdinal = remainingCurrentDeals.filter(deal => deal.ordinal !== undefined && deal.ordinal !== null);
            const dealsWithoutOrdinal = remainingCurrentDeals.filter(deal => deal.ordinal === undefined || deal.ordinal === null);

            const sortedDealsWithOrdinal = dealsWithOrdinal.sort((a, b) => a.ordinal - b.ordinal);
            const finalCurrentDeals = [...sortedDealsWithOrdinal, ...dealsWithoutOrdinal, ...upcomingDeals];
            this.setState({ currentDeals: finalCurrentDeals });
        }
    }

    addMerchantNameAndLogoToDeals = (deals) => {
        const merchants = this.state.partners;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        return deals
            .map(deal => {
                const correspondingMerchant = merchants.find((merchant) => merchant.id === deal.merchantId);
                const merchantName = correspondingMerchant ? correspondingMerchant.merchantName : 'Unknown Merchant';
                const merchantLogoUrl = correspondingMerchant ? correspondingMerchant.merchantLogoUrl : "";
                const commission = correspondingMerchant ? correspondingMerchant.commission : "";

                return {
                    ...deal,
                    merchantName,
                    merchantLogoUrl,
                    commission,
                };
            });
    };

    getPartners = async () => {
        let partnersUrl = '/api/Merchant/GetCommissionEstimate?showAll=false&getMaxByMerchant=true';
        let partners = await getRequest(partnersUrl);
        this.setState({ partners: partners, filteredPartners: partners });
    }

    renderShareOverlay = (shareLink) => {
        return (<span className="ml-2 position-absolute" onClick={(e) => {
            e.preventDefault();
            this.props.toggleShareModal(shareLink);
        }} style={{ cursor: "pointer", backgroundColor: "#f8f9fa", borderRadius: "999px", padding: "5px", bottom: "-2px" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    displayShareModal = () => {
        let shareProps = {
            shareCode: this.state.shareLink,
            title: "Merchant share",
            customStyle: { maxWidth: "100%", backgroundColor: "white" },
            shareBlockStyle: { border: "none", backgroundColor: "transparent" },
            loggedInUser: this.props.loggedInUser,
            type: "merchant",
        }
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleProductShareModal}>
                <ShareBlock {...shareProps}>
                    <MerchantShareBlock
                        {...shareProps}
                    />
                </ShareBlock>
            </Modal>
        )
    }

    renderDeals() {
        const largeScreen = ["lg", "xl"].includes(this.props.deviceSize);

        if (!(this.state.currentDeals) || this.state.currentDeals.length === 0) {
            return (
                <div className="w-100 d-flex justify-content-center">
                    <span style={{ fontFamily: "'Archivo', sans-serif" }}>No current deals are available at this time</span>
                </div>
            )
        }

        return this.state.currentDeals.map((deal, i) => {
            let redirectToMerchantUrl = `/m/${deal.merchantId}?userId=${this.props.loggedInUser.id}&redirectUrl=${deal.url}&currentDeal=true`;

            const lastCard = largeScreen ? (i + 1) % 3 === 0 : (i + 1) % 1 === 0;
            const lastRowIndex = largeScreen ? Math.floor((this.state.currentDeals.length - 1) / 3) * 3 : Math.floor((this.state.currentDeals.length - 1) / 1) * 1;

            let commissionPercentage = deal.commission * 100;
            let roundedCommissionPercentage = 0;;
            if (commissionPercentage > 0) {
                roundedCommissionPercentage = commissionPercentage.toFixed(2);
                if (roundedCommissionPercentage.toString().endsWith(".00")) { roundedCommissionPercentage = roundedCommissionPercentage.toString().slice(0, -3) };
            }

            const dateString = deal.endDate
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric"
            });

            const dealStyle = ["sm", "md"].includes(this.props.deviceSize) ? { width: "335px", margin: "0 auto", border: "1px solid", marginBottom: "5px" } : {}

            const formatDate = (date) => {
                return date.toLocaleDateString("en-US", {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric"
                })
            };

            return (
                <div className={`col-lg-4 px-0 border-dark ${!lastCard ? "border-right" : ""} ${i < lastRowIndex ? "border-bottom" : ""}`} style={dealStyle} key={deal.id}>

                    <a href={redirectToMerchantUrl} style={{ cursor: deal.upcoming ? "initial" : "pointer" }} onClick={(e) => deal.upcoming ? e.preventDefault() : console.log()} target="_blank">
                        <div className="h-100 bg-white position-relative">
                            <div className="card-body d-flex flex-column">
                                <h4 className="card-title mb-4" style={{ color: "black", display: !!(deal.merchantLogoUrl) ? "none" : "" }}>{deal.merchantName}</h4>
                                <div className="d-flex align-items-center brands-new-wrapper mb-4" >
                                    <img style={{ display: !!(deal.merchantLogoUrl) ? "" : "none", objectFit: "contain", height: "60px", maxWidth: "100%", filter: "grayscale(100%) contrast(2) brightness(1)" }} src={deal.merchantLogoUrl} />
                                </div>
                                <p className="card-text mb-4" style={{ fontFamily: "'Archivo', sans-serif", fontSize: "16px", fontWeight: "bold" }}>{deal.description}</p>
                                <div className="position-relative">
                                    {
                                        !!(deal.upcoming) ?
                                            <a href={redirectToMerchantUrl} className="mb-4 mt-auto" style={{ color: "#d46240", fontSize: "20px", fontWeight: "bold", cursor: deal.upcoming ? "initial" : ""}} onClick={(e) => e.preventDefault()}>Starting   {formatDate(deal.startDateLocal)}</a>

                                            :
                                            <a href={redirectToMerchantUrl} className="mb-4 mt-auto" style={{ fontSize: "20px", fontWeight: "bold", cursor: deal.upcoming ? "initial" : "" }} onClick={(e) => e.preventDefault()}>Explore Sale</a>
                                    }
                                    <div
                                        className="mt-2 new-button text-center"
                                        style={{ cursor: deal.upcoming ? "initial" : "pointer", display: !!(deal.upcoming) ? "none" : "" }}
                                        onClick={(e) => { e.preventDefault(); this.toggleProductShareModal(redirectToMerchantUrl); }}
                                    >Share A Commissionable Link
                                    </div>

                                </div>
                                <p className="card-text mt-3 mb-0 font-italic" style={{ fontFamily: "'Archivo', sans-serif", fontSize: "12px", color: "gray" }}>Estimated Commission: {roundedCommissionPercentage}%</p>
                                <p className="card-text mb-0 font-italic" style={{ fontFamily: "'Archivo', sans-serif", fontSize: "12px", color: "gray" }}>Expires: {formattedDate}</p>
                            </div>
                        </div>
                    </a>
                </div>
            )
        })
    }

    toggleProductShareModal = async (urlFragment) => {
        let showModal = !this.state.modalOpen;
        let shareLink = await getMerchantShareLink(urlFragment);

        if (showModal) {
            this.setState({ modalOpen: showModal, shareLink });
        }
        else {
            this.setState({ modalOpen: showModal, shareLink: "" });
        }
    }

    render() {
        let smallSizes = ["sm", "md"];
        let { deviceSize } = this.props;

        if (this.state.loading) {
            return (
                <>
                    < LoadSpinner />
                    <Helmet>
                        <title>RockPorch - Current Deals</title>
                    </Helmet>
                </>
            )
        }
        return (
            <>
                <Helmet>
                    <title>RockPorch - Current Deals</title>
                </Helmet>
                {this.displayShareModal()}
                <section style={{ display: this.props.creatorDashboard ? "none" : "" }}>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Current Deals</h1>
                        <p className="mb-0">View current deals from brands</p>
                    </AccountBanner>
                </section>
                <section className={`${["sm", "md"].includes(this.props.deviceSize) ? "" : "gray-bg"}`}>
                    <div className={`container ${["sm","md"].includes(this.props.deviceSize) ? "" : "py-5"} px-0 px-md-3`}>

                        <div className="row mx-0 my-0">

                            <section className={`${["sm", "md"].includes(this.props.deviceSize) ? "" : "gray-bg"} w-100`}>
                                <div className="bg-white container">
                                    <div className={`${["sm", "md"].includes(this.props.deviceSize) ? "py-3" : "row py-5"} mt-0`}>
                                        {this.renderDeals()}
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}
function mapStateToProps(storeState, ownProps) {
    return { interactionState: storeState.interactionReducer }
}

export default connect(mapStateToProps, { toggleProductPageModal, toggleShareModal })(CurrentDeals);