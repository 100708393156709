import { createStore, applyMiddleware,compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import reducer from "./reducers/reducer";

export const myRefStore = {};

function refHandler({ getState }) {
    return next => action => {
        switch (action.type) {
            case 'TOGGLE_POST_PAGE_MODAL':
                if (!myRefStore.setPost) {
                    myRefStore.setPost = action.payload.setPost;
                } else {
                    myRefStore.setPost(action.payload.post);
                }
                break;
            case 'TOGGLE_LOCKER_PAGE_MODAL':
                if (!myRefStore.setLocker) {
                    myRefStore.setLocker = action.payload.setPost;
                } else {
                    myRefStore.setLocker(action.payload.post);
                }
                break;
            case 'TOGGLE_PRODUCT_PAGE_MODAL':
                if (!myRefStore.setProduct) {
                    myRefStore.setProduct = action.payload.setProduct;
                } else {
                    myRefStore.setProduct(action.payload.product);
                }
                break;
        }
        const returnValue = next(action);
        return returnValue;
    };
} 

const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const composedEnhancers = composeWithDevTools();
const store = createStore(reducer, composedEnhancers(applyMiddleware(refHandler)));

export default store;
