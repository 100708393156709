import React, { useState, useEffect, useCallback } from "react";
import LikeIcon from "./icons/LikeIcon";
import Carousel from "./Carousel";
import { Link } from "react-router-dom";
import CommentIcon from "./icons/CommentIcon";
import ShareIcon from "./icons/ShareIcon";
import BookmarkIcon from "./icons/BookmarkIcon";
import RatingIcon from "./icons/RatingIcon";
import RatingIconHalf from "./icons/RatingIconHalf";
import RatingIconEmpty from "./icons/RatingIconEmpty";
import SaleIcon from "./icons/SaleIcon";

import ShareBar from "components/Share/ShareBar";
import ProductReviewUsersList from "components/ProductReviewUsers/ProductReviewUsersList";
import { currencyFormat, titleCase } from "sharedUtils/productUtils";

import StarRatingComponent from 'react-star-rating-component';
//import { Modal, ModalBody, ModalHeader } from "reactstrap";

import Modal from "components/Modal";

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { TextareaAutosize } from "@material-ui/core";

/**
 * 
 * @param {{
 * product:object 
 * rating:number, 
 * loggedInUser:object,
 * starHover:Function,
 * starHoverOut:Function,
 * starClick:Function,
 * deviceSize:string,
 * buyAction:Function}} props
 */
const BrowseProductDetailSection = (props) => {
    let { imageUrl, manufacturer, merchant, name, ratingCount, likes, price, description } = props.product;

    const collapsedContentStyle = { textAlign: "left", marginTop: "0em", marginBottom: "0px", maxHeight: "10rem", textOverflow: "ellipsis", overflow: "hidden" };
    const expandedContentStyle = { textAlign: "left", marginTop: "0em", marginBottom: "0px" };

    const [contentStyle, setContentStyle] = useState(expandedContentStyle);
    const [merchantOverflow, setMerchantOverflow] = useState(false);
    const [showUserReviewList, setShowReviewList] = useState(false);

    const contentRef = React.createRef();

    const merchantRef = useCallback(node => {
        if (node !== null && !!(node.offsetHeight) && node.offsetHeight > 20) {
            setMerchantOverflow(true);
        }
    }, []);

    useEffect(() => {
        if (props.collapsed) {
            setContentStyle(collapsedContentStyle);
        } else {
            setContentStyle(expandedContentStyle);
        }
    }, [props.collapsed]);

    let renderRatingIcons = () => {
        if (props.loadingPartial) {
            return <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        }
        if (props.loggedInUser) {
            return (
                <div className="position-relative w-100 d-flex align-items-center">
                    <StarRatingComponent
                        name="starRating"
                        starCount={5}
                        value={props.rating}
                        renderStarIcon={(index, value) => {
                            if (index <= value) {
                                return (<span className="ratingIcon" key={index}><RatingIcon /></span>);
                            } else {
                                return (<span className="ratingIcon" key={index}><RatingIconEmpty /></span>);
                            }
                        }}
                        renderStarIconHalf={(index, value) => {
                            return (<span className="ratingIcon" key={index}><RatingIconHalf /></span>);
                        }}
                        onStarHover={props.starHover}
                        onStarHoverOut={props.starHoverOut}
                        onStarClick={props.starClick}
                    />

                    <span className="rating-count" style={{ marginBottom: "7px", marginleft: "3px" }}>
                        <Link to="/" onClick={e => {
                            e.preventDefault();
                            if (props.loggedInUser) {
                                setShowReviewList(true);
                            }
                            else {
                                props.toggleSignUpModal();
                            }

                        }}>

                            {!props.loadingPartial && ratingCount > 0 ? `(${ratingCount})` : null}

                        </Link>
                    </span>
                    {props.renderThumbsUpOverlay(props.productInfo, true)}
                </div>
             
            )
        } else {
            let ratingIcons = [];
            let { rating } = props;
            for (let i = 0; i < 5; i++) {
                if (rating - i >= 1) {
                    ratingIcons.push(<span className="ratingIcon" key={i}><RatingIcon /></span>);
                }
                else if (rating - i <= 1 && rating - i > 0) {
                    ratingIcons.push(<span className="ratingIcon" key={i}><RatingIconHalf /></span>);
                }
                else {
                    ratingIcons.push(<span className="ratingIcon" key={i}><RatingIconEmpty /></span>)
                }
            }
            return ratingIcons;
        }
    }

    let buyAction = shareCode => {
        let url = props.loggedInUser ? "/Products/BuyAuthorized" : "/Products/Buy";
        url += "?shareCode=" + shareCode + "&referrer=" + document.referrer + "&browse=true";
        return url;
    }

    const renderSaleOverlay = (sale) => {
        let deviceSize = ["sm", "md"].includes(props.deviceSize);
        if (sale) {
            return (
                <span className="on-sale-icon-browse-details">
                    <SaleIcon width={`${!deviceSize ? "22" : "18"}`} height={`${!deviceSize ? "22" : "18"}`} />
                </span >
            );
        }
    }

    let displayTableRows = () => {
        if (!!(props.products) && props.products.length > 0) {
            const showMerchant = props.products.length > 1;
            return props.products.map((product, i) => {
                let url = buyAction(product.shareCode);
                let sale = product.sale && !!(product.salePrice);
                let btnStyle = props.deviceSize === "sm" ? { maxWidth: "70px", fontSize: "12px" } : { maxWidth: "80px", maxHeight: "40px", fontSize: "12px" }
                return (
                    <div className="d-flex align-items-center" key={i}>
                        <div className="" style={{ visibility: showMerchant ? "" : "hidden", height: "40px", width: "40px", marginRight: "10px" }}>
                            <img className="border-0" src={product.logoUrl} style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }} onError={(e) => {
                                e.target.onError = null
                                e.target.src = "images/blank-banner.png"
                            }} />
                        </div>
                        <h6
                            className={`${showMerchant ? "" : "hidden"} ${merchantOverflow && sale ? "pl-2" : ""}`}
                            ref={merchantRef}
                            style={{
                                visibility: showMerchant ? "" : "hidden",
                                overflowWrap: "anywhere",
                                flexGrow: 1
                            }}
                        >
                            {product.merchant}
                            {product.sale && !!product.salePrice && <SaleIcon style={{ marginLeft: "6px" }} />}
                        </h6>
                        <div className="d-flex" style={{ marginRight: "10px" }}>
                            {product.sale && !!(product.salePrice) ? <SaleIcon style={{ display: showMerchant ? "none" : "", marginRight: "5px" }} /> : ""}
                            <div className="mr-1">
                                <h6 className={`${product.sale && !!(product.salePrice) ? "on-sale-price-single" : ""}`} style={{ paddingTop: product.sale && !!(product.salePrice) ? "2px" : "", marginRight: product.sale && !!(product.salePrice) && props.deviceSize === "sm" ? "5px" : "" }}>{currencyFormat(product.sale && !!(product.salePrice) && !!(product.originalPrice)? product.originalPrice : product.price)}</h6>
                            </div>
                            <div className={`${sale ? "mr-1" : ""}`}>
                                {product.sale && !!(product.salePrice) ? <h6>${product.salePrice.toFixed(2)}</h6> : <></>}
                            </div>
                        </div>
                        <div style={{ marginRight: showMerchant ? "" : "10px", marginBottom: "9px" }}>
                            <a href={url} onClick={() => props.handleGoogleAnalytics4BuyButton(true)} target={props.product.merchant + Date.now().toString()}>
                                <button className="btn btn-primary" style={btnStyle}>
                                    Buy Now
                                </button>
                            </a>
                        </div>
                    </div>
                );
            });
        }
    }

    let concatMerchantProduct = (manufacturer, merchant, name) => {
        if (manufacturer && manufacturer.toLowerCase() === merchant.toLowerCase()) {
            if (name.toLowerCase().indexOf(manufacturer.toLowerCase()) === 0) {
                return name.slice(manufacturer.length - name.length).trim();
            }
            return name;
        } else {
            if (manufacturer && name.toLowerCase().indexOf(manufacturer.toLowerCase()) === 0) {
                return name;
            }
            return name;
            //return (manufacturer + ' ' + name).trimStart();
        }
    }

    let displayManufacturer = (manufacturer) => {
        if (manufacturer?.toLowerCase().includes("rcktemp_")) {
            return "";
        }
        return manufacturer;
    }

    let displayUserReviewList = () => {
        return (
            <Modal isOpen={showUserReviewList}
                showHeader={false}
                toggleModal={() => setShowReviewList(!showUserReviewList)}
            >

                <ProductReviewUsersList merchantProductId={props.product.userMerchantProductId} loggedInUser={props.loggedInUser} deviceSize={props.deviceSize} />

            </Modal>
        )

    }

    return (
        <div className="container product-detail mt-3 d-flex justify-content-center">
            {displayUserReviewList()}
            <div className="row pt-3 pb-1 mt-0 product-image-gallery">
                <div className="col-5 mb-4 mb-md-0">
                    <div className="product-image">
                        <img className="border-0" src={[imageUrl]} onError={(e) => {
                            e.target.onError = null
                            e.target.src = "images/blank-banner.png"
                        }} />
                       
                    </div>
                </div>

                {/*<div className="pl-2 pr-2 pr-md-4" style={{ maxWidth: "500px" }}>*/}
                <div className="col-7 pl-2 pr-2 pr-md-4">
                    <h5 className="product-company">{displayManufacturer(manufacturer)}</h5>
                    <h1 className="product-name mb-0">{titleCase(concatMerchantProduct(manufacturer, merchant, name)).toUpperCase()}</h1>
                    <div className="product-rating mb-1 d-flex">
                        {renderRatingIcons()}
                    </div>
                    
                    {
                        props.deviceSize !== "sm" && description?.toLowerCase() !== name?.toLowerCase() ? (
                            <>
                                <div className="mx-auto mb-3" style={{ overflow: "visible", maxWidth: "90%" }}>
                                    {displayTableRows()}
                                </div>
                                <p className="mb-0" style={contentStyle} ref={contentRef}>
                                    {description}
                                </p>
                            </>
                        ) : null
                    }
                </div>

                {
                    props.deviceSize === "sm" && description?.toLowerCase() !== name?.toLowerCase() ? (
                        <>
                            <div className="table-responsive product-table mx-auto mb-3" style={{ overflow: "visible", maxWidth: "90%" }}>
                                {displayTableRows()}
                            </div>
                            <div className="col-12 mt-1 mb-1">
                                <p className="mb-0" style={contentStyle} ref={contentRef}>
                                    {description}
                                </p>
                            </div>
                        </>
                    ) : null
                }
            </div>

        </div>
    )
}

export default BrowseProductDetailSection