import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import LoadSpinner from "components/LoadSpinner";
import { escapeLeadingUnderscores } from 'typescript'

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            popUpAuthentication: {
                finished: false,
                jsxVal: <span></span>
            }
        };
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        await this.populateAuthenticationState();

        if (this.state.authenticated === false && this.props.launchAuthPopup === true) {

        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    handleAuthPopupRedirect = async (authPopup) => {
        const { component: Component, componentProps, ...rest } = this.props;
        let link = document.createElement("a");
        link.href = this.props.location.pathname;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`

        let jsx = <span></span>
        //this.setState({ popUpAuthentication: {finished:false, jsxVal:<p>Loading ...</p>}})
        var interval = setInterval(async () => {
            let isAuthenticated = await authService.isAuthenticated();
            if (authPopup) {

                if (isAuthenticated === true) {
                    authPopup.close();
                    jsx = <Redirect to={redirectUrl} />;
                    this.setState({ popUpAuthentication: { finished: true, jsxVal: jsx } });
                    clearInterval(interval);
                }
            }
            else {
                if (isAuthenticated === true) {
                    authPopup.close();
                    jsx = <Redirect to={redirectUrl} />;
                    this.setState({ popUpAuthentication: { finished: true, jsxVal: jsx } });
                    clearInterval(interval);
                }
                else {
                    this.setState({ popUpAuthentication: { finished: true, jsxVal: <span>Login unsuccessful</span> } });
                    clearInterval(interval);
                }

            }
        }, 3000);

        //return jsx;

    }

    render() {
        const { ready, authenticated } = this.state;
        

        let link = document.location;
        const returnUrl = `${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${returnUrl}`

        if (!ready) {
            return <div></div>;
        }
        else if (this.props.navRedirect != undefined && this.props.navRedirect != null) {
            return (
                <Redirect to={`/${this.props.navRedirect}`} />
            )
        }
        /*else if (authenticated === false && this.props.launchAuthPopup === true) {
            let link = document.createElement("a");
            link.href = this.props.location?.pathname;
            const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
            const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`

            let features = "menubar=no,location=no,resizable=no,scrollbars=yes,status=yes,width=500,height=500"
            let authPopup = window.open(redirectUrl, "authWindow", features);
            this.handleAuthPopupRedirect(authPopup).then(val => val);
            
            if (this.state.popUpAuthentication.finished === true) {
                return this.state.popUpAuthentication.jsxVal
            } else {                
                return (
                    <div style={{ position: "absolute", top: "0", height: "100vh", width: "100vw", textAlign: 'center' }}>
                        <LoadSpinner />
                        <h1>Waiting for login ... </h1>
                    </div>
                )
            }
        }*/
        else {
            const { component: Component, componentProps, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        return <Component {...props} {...componentProps} timestamp={new Date().getTime().toString()} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
