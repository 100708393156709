import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SwipeableProducts from "./SwipeableProducts";
import { trimProductsArray, getProperties } from "../../sharedUtils/postFunctions";

import Modal from "components/Modal";
import PostPageProduct from "components/PostPageProduct";
import UserTileGeneral from "components/UserTileGeneral";
import ImageWrapper from "components/ImageWrapper";

import { closeAllModals } from "stateManagement/reducers/modalReducer";

import blankFeedPost from "../../sharedUtils/blankFeedPost";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { getPostShareLink } from "../../sharedUtils/shareLinkPrefixes"

class PostPageEmbedBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessageDetails: false,
            likes: {
                count: 0,
                likedByUser: false
            },
            loggedInUser: null,
            isAuthorized: false,
            isFollowing: false,
            commentInput: "",
            shareCode: "",
            post: blankFeedPost,
            showDescriptionModal: false,
            selectedProduct: 0,
            isLoading: true,
            loadingComments: false,
            productPage: 0,
            submittingComment: false,
            shareLink: "",
        }
    }

    trimmedProducts = null;

    totalHeight = React.createRef();

    async componentDidMount() {
        const messageTotalHeight = () => {
            let totalHeight = this.totalHeight?.current?.offsetHeight;
            if (totalHeight > 100) {
                const messageObj = { shareCode: this.state.shareCode, height: totalHeight};
                const messageString = JSON.stringify(messageObj);

                window.parent.postMessage(messageString, "*");
            }
        }

        window.addEventListener('message', function (event) {
            messageTotalHeight();
        });



        if (this.props.setShowTopNav) {
            this.props.setShowTopNav(false);
        }

        let shareCode = "";
        let post = null;
        let loggedInUser = null;

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }

        if (this.props.isModal !== true) {
            window.scrollTo(scrollOptions);
            this.props.closeAllModals();
        }

        // from feed
        if (this.props.match.params != undefined && this.props.match.params.post != undefined) {
            shareCode = this.props.match.params.post.shareCode;
            post = this.props.match.params.post;
            loggedInUser = this.props.match.params.loggedInUser;
            // from modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params != undefined &&
            this.props.location.state.destinationState.params.post != undefined) {

            shareCode = this.props.location.state.destinationState.params.post.shareCode;
            post = { ...this.props.location.state.destinationState.params.post, comments: [] };
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;

            // from direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }

        let shareLink = await getPostShareLink(shareCode);

        if (loggedInUser == undefined) {
            let data = await this.fetchPost(shareCode, false);
            if (data == undefined || data == null) {
                this.props.history.push("/NotFound");
            } else {
                this.setState({ post: data, isLoading: false, shareCode, shareLink });
            }
        } else {
            if (post == undefined) {
                let data = await this.fetchPost(shareCode, true);
                if (data == undefined || data == null) {
                    this.props.history.push("/NotFound");
                } else {
                    this.setState({ post: data, isLoading: false, loggedInUser, shareCode, shareLink });
                }
            } else {
                let trackBaseUrl = this.state.loggedInUser !== undefined ? `api/Post/TrackPostViewAuthorized` : `api/Post/TrackPostView`
                let trackPostViewUrl = `${trackBaseUrl}?userPostId=${post.id}&referrer=${document.referrer}`;

                getRequest(trackPostViewUrl);
                this.setState({ viewTracked: true });

                if (post.comments == undefined || post.comments.length == undefined) {
                    post.comments = [];
                };
                await this.setState({ post: post, isLoading: false, loggedInUser, shareCode, shareLink });
            }
            if (!!(post)) {
                this.trimmedProducts = trimProductsArray(post.productItems?.$values).map((i) => { return i; })

            }
        }
    }


    async componentDidUpdate(prevProps, prevState) {
        if (this.props?.match?.params?.shareCode != prevProps?.match?.params?.shareCode) {

            this.setState({ isLoading: true });

            let shareCode = this.props.match.params.shareCode;
            let isAuthorized = this.props.loggedInUser !== null;
            let post = this.props.location.state.destinationState.params.post

            if (post == null || post == undefined) {
                post = await this.fetchPost(shareCode, isAuthorized);

                if (post == null || post == undefined) {
                    this.props.history.push("/NotFound");
                    return -1
                }
            }

            this.setState({ post, isLoading: false });
        }
    }

    fetchPost = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? "/api/post/getPostAuthorized" : "/api/post/getPost";
        let url = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}`
        let data = await getRequest(url);

        return data;
    }

    renderProductImages = () => {
        return trimProductsArray(this.state.post.productItems?.$values)?.map((val, i) => {
            return (
                <div key={i} className="col-4 px-1 py-1">
                    <div className="post-page-product">
                        <div className="product-image-in-page">
                            {/*{this.renderInfoOverlay(val)}*/}
                            <PostPageProduct
                                deviceSize={this.props.deviceSize}
                                comment={val.comment}
                                product={val}
                                productKey={i}
                                key={i}
                                showDescription={(i) => this.toggleDescriptionModal(i)}
                                post={this.state.post}
                                loggedInUser={this.state.loggedInUser}
                                parentModal={this.props.isModal === true ? { type: "post", shareCode: this.props.match.params?.post?.shareCode, parentState: { ...this.state.post } } : null}
                            />
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{val.manufacturer?.toLowerCase().includes("rcktemp") ? val.merchant : val.manufacturer}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>${val.price.toFixed(2)}</div></h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    toggleDescriptionModal = (productIndex) => {
        let newModalState = !this.state.showDescriptionModal;
        this.setState({ showDescriptionModal: newModalState, selectedProduct: productIndex });
    }

    renderDescriptionModal = () => {
        if (this.state.showDescriptionModal === true) {
            return (
                <Modal isOpen={this.state.showDescriptionModal} toggleModal={this.toggleDescriptionModal} showHeader={false}>
                    <div className="share-block" style={{ border: "none", backgroundColor: "transparent" }}>
                        <UserTileGeneral disableLink={true} user={this.state.post.user} subtitle={" "} showCheckMark={false} />
                        <p className="pt-3 mb-0">{this.state.post.productItems?.$values[this.state.selectedProduct].comment}</p>
                    </div>
                </Modal>
            )
        }
    }

    redirectToPostPage = (e) => {
        e.preventDefault();
        let url = `/p/${this.state.shareCode}`;
        window.open(url);
    }

    render() {
        if (this.state.post === "notFound") {
            return <h1>Post not found</h1>
        }

        let postImages = getProperties(this.state.post?.properties)
            .filter(property => property?.parentId == null && (property?.role === 0 || property?.role === 1 || property?.role === 3 && !property?.value.includes("blockquote") || property?.role === 8))
            .map(property => {
                return {
                    url: property?.value,
                    role: property?.role,
                    id: property?.id,
                    width: property?.width,
                    height: property?.height
                }
            });

        if (this.state.isLoading === true) {
            return <></>
        }

        //if the post isn't published, redirect to HomeFeed
        if ([0, 2, 6].includes(this.state.post.status) === true && this.state.post.user.id !== this.state.loggedInUser?.id && this.props.isModal !== true) {
            return <Redirect to="/HomeFeed" />
        }

        return (

            <section className={`container explore-feed-wrapper d-flex flex-column pl-0 pr-0`} style={{ maxWidth: "500px", borderRadius: "20px", boxShadow: "0px 5px 15px rgb(229 89 52 /40%)", width: this.totalHeight?.current?.offsetWidth > 300 ? "94%" : this.totalHeight?.current?.offsetWidth > 200 ? "88%" : "82%", marginTop: "10px" }} ref={this.totalHeight}>
                {this.renderDescriptionModal()}

                <div className={`row d-flex align-items-center pl-0 mx-0 py-0 mt-1`} onClick={this.redirectToLockerPage}
                >

                    <div className="col-12 mt-0 pl-2">
                        <UserTileGeneral disableLink={true} user={this.state.post.user} subtitle={" "} showCheckMark={false} />
                    </div>

                </div>

                <div className={`${this.state.post?.productItems?.$values?.length > 0 ? "feed-slider" : "feed-slider-noproduct"}`} style={{ paddingBottom: this.state.post?.productItems?.$values?.length > 0 ? "" : "131.7%" }}>
                    <div className="image-container">
                        <div className="image-items">
                            <ImageWrapper
                                src={postImages[0].url || "images/blank-banner.png"}
                                alt=""
                                className={"feed-post-grid-image"}
                                role={postImages[0].role}
                                onClick={this.redirectToPostPage}
                                width={postImages[0].width}
                                height={postImages[0].height}
                                style={{ cursor: "pointer", height: this.state.post?.productItems?.$values?.length > 0 ? "100%" : "101.1%", borderRadius: this.state.post?.productItems?.$values?.length > 0 ? "" : "0 0 20px 20px" }}
                            />
                        </div>
                    </div>
                </div>



                <div className={`container container-top-gap mt-1 px-2`}>
                    

                    {
                        this.state.post?.productItems?.$values?.length > 0 ? (
                            <div>
                                <SwipeableProducts redirect={this.redirectToPostPage} brand={true} style={{ paddingBottom: 0 }} productItems={this.state.post?.productItems?.$values} />
                            </div>
                        ) : null
                    }
                </div>
            </section>
        )
    }
}

const mapDispatch = {
    closeAllModals: closeAllModals,
}

export default connect(null, mapDispatch)(PostPageEmbedBrand);