import { getRequest } from "../../../sharedUtils/httpUtils";
const ENABLE_AUDIT_MESSAGES = false;

const youtubeDiscoveryDocs = "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest";
const youtubeScope = "https://www.googleapis.com/auth/youtube.readonly";
const requiredPermissions = [youtubeScope];
let ytAuth = undefined;
let ytClient = undefined;
var _authorization = undefined;


function getAuthorization() {
    let retVal = _authorization;
    if (ENABLE_AUDIT_MESSAGES)
        console.log(new Date(), '_authorization', 'get', retVal);
    return retVal;
}
function setAuthorization(value) {
    if (ENABLE_AUDIT_MESSAGES)
        console.log(new Date(), '_authorization', 'set', value);
    _authorization = value;
}

var _isInitialized = undefined;
function getIsInitialized() {
    let retVal = _isInitialized;
    if (ENABLE_AUDIT_MESSAGES)
        console.log(new Date(), '_isInitialized', 'get', retVal);
    return retVal;
}
function setIsInitialized(value) {
    if (ENABLE_AUDIT_MESSAGES)
        console.log(new Date(), '_isInitialized', 'set', value);
    _isInitialized = value;
}

var _userProfile = undefined;

function getUserProfileInternal() {
    let retVal = _userProfile;
    if (ENABLE_AUDIT_MESSAGES) console.log(new Date(), '_userProfile', 'get', retVal);
    return retVal;
}

function setUserProfileInternal(value) {
    if (ENABLE_AUDIT_MESSAGES) console.log(new Date(), '_userProfile', 'set', value);
    _userProfile = value;
}

function resetEnvironmentVariables() {
    setAuthorization(undefined);
    setUserProfileInternal(undefined);
    ytAuth = undefined;
    ytClient = undefined;
}

function getYoutubeClient() {
    return new Promise((resolve) => {
        if (window.google) {
            resolve(window.google);
        }
        const id = 'youtube-jssdk';
        const fjs = document.querySelectorAll('script')[0];
        if (document.getElementById(id)) {
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = '//accounts.google.com/gsi/client';
        js.onload = 'initClient()';
        js.addEventListener('load', () => {
            resolve(window.google);
        });
        fjs.parentNode.insertBefore(js, fjs);
    });
}



function getPlayerScript() {
    return new Promise((resolve) => {
        if (window.YT) {
            resolve(window.YT);
        }
        const id = 'youtube-player-jssdk';
        const fjs = document.querySelectorAll('script')[0];
        if (document.getElementById(id)) {
            return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = '//www.youtube.com/iframe_api';
        js.addEventListener('load', () => {
            resolve(window.YT);
        });
        fjs.parentNode.insertBefore(js, fjs);
    });
}

function getAuthInstance(google) {
    return google.accounts.oauth2.initCodeClient({
        client_id: '837406387167-tssbf2nh2dq9eg0tkbcpgt2m273ceabi.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.readonly',
        ux_mode: 'popup',
        callback: (response) => {
            ytAuth = !!(response.code) ? response.code : null;
            if (!!(response.error)) {
                setYtAuthNull();
            }
            // After receipt, the code is exchanged for an access token and
            // refresh token, and the platform then updates this web app
            // running in user's browser with the requested calendar info.
        },
        error_callback: (response) => {
            setYtAuthNull();
        },
    });
}

function setYtAuthNull() {
    ytAuth = -1;
}

const ytAuthWait = () => new Promise((resolve) => {
    const checkVariable = () => {
        if (ytAuth != undefined) {
            resolve();
        } else {
            setTimeout(checkVariable, 100);
        }
    }
    checkVariable();
});

function setUserData(auth) {
    if (!!(auth.id_token)) {
        try
        {
            let encodedPayload = auth.id_token?.split(".")[1];
            let payload = JSON.parse(atob(encodedPayload));
            const userInfo = {
                id: payload.sub,
                name: payload.picture,
                imageUrl: payload.picture,
            };
            setUserProfileInternal(userInfo);
        }
        catch (err)
        {
            console.error("Error trying to decode userInfo from JWT", err);
            return -1;
        }
        
    }
}

function getGoogleAuth() {
    return new Promise(async (resolve, reject) => {
        try {
            const google = await getYoutubeClient();
            const client = getAuthInstance(google);
            ytClient = typeof client == 'object' ? { ...client } : undefined;
            client.requestCode();

            await ytAuthWait();
            
            if (ytAuth === -1) {
                ytAuth = undefined;
                resolve(undefined);
            }
            const auth = await doAuthorize();
            let setAuth = 0;
            setAuth = setUserData(auth);
            if (setAuth == -1) {
                auth = undefined;
            }
            resolve(auth);
        }
        catch (ex) {
            reject(ex);
        }
    });
}

async function doAuthorize() {
    let auth = await getRequest(`/api/google/accesstoken/?authorizationCode=${ytAuth}`);
    return auth;
}


let _clientHandle = undefined;
var _ytPlayerLib = undefined;


class YoutubeClient {
    constructor() {
        //getPlayerScript()
        //    .then(
        //        (val) => {
        //            _ytPlayerLib = val;
        //        }
        //    );
    }

    get isAuthorized() {
        return _authorization != undefined;
    }

    getChannelsFor(userProfile) {
        return new Promise(async (resolve, reject) => {
            if (!(userProfile?.id)) {
                reject('userProfile argument is required');
            } else {
                try {
                    const targetModelFrom = (sourceItem) => {
                        let item_snippet = sourceItem.snippet;
                        return {
                            ...item_snippet,
                            id: sourceItem.contentDetails.relatedPlaylists.uploads,
                            name: item_snippet.title,
                            videoCount: sourceItem.statistics.videoCount,
                            raw: { ...sourceItem }
                        };
                    };

                    // data is the internal model of videos
                    let data = [];
                    let retVal = { data: data }

                    const handleResolved = async (api) => {
                        let source = api.items;
                        while (source.length)
                            data.push(targetModelFrom(source.pop()));
                        if (!!(api.nextPageToken)) {
                            retVal.paging = { nextPageToken: api.nextPageToken };
                        } else {
                            retVal.isFullyLoaded = true;
                        }
                    };

                    let nextPageToken = null;
                    if (!!(userProfile.channels?.paging?.nextPageToken)) {
                        nextPageToken = userProfile.channels.paging.nextPageToken;
                        delete userProfile.channels.paging;
                    }
                    let url = `https://youtube.googleapis.com/youtube/v3/channels?part=contentDetails%2Cid%2Csnippet%2Cstatistics&mine=true&maxresults=25`
                    if (!!(nextPageToken)) {
                        url += "&pageToken=" + nextPageToken;
                    }

                    let api = await getRequest(url, false, _authorization.access_token);
                    await handleResolved(api);

                    resolve(retVal);

                } catch (ex) {
                    reject(ex);
                }
            }
        });
    }

    getPostsFor(channel) {
        return new Promise(async (resolve, reject) => {
            if (!(channel?.id)) {
                reject('channel argument is required');
            } else {
                try {
                    //const api = await getYoutube();

                    let data = [];

                    const targetModelFrom = (sourceItem) => {
                        let retVal = null;
                        let item_snippet = sourceItem?.snippet;
                        if (!!(item_snippet?.resourceId?.videoId)) {
                            retVal = {
                                content: item_snippet.description,
                                embed: null,
                                id: sourceItem.id,
                                link: null,
                                media: [{
                                    children: [],
                                    duration: null,
                                    height: null,
                                    imageLink: null,
                                    embedLink: null,
                                    isExternalReference: false,
                                    thumbnailLink: null,
                                    videoId: item_snippet.resourceId.videoId,
                                    videoLink: null,
                                    width: null,
                                }],
                                platformName: 'youtube',
                                publishedOn: item_snippet.publishedAt,
                                raw: { playlistItem: sourceItem },
                                title: item_snippet.title
                            };
                        }
                        return retVal;
                    };

                    const updateModels = (items) => {
                        // iterate through the raw items
                        for (let y = 0; y < items.length; y++) {
                            let videoModel = items[y];
                            // iterate through our video model
                            for (let x = 0; x < data.length; x++) {
                                let targetModel = data[x];
                                let media = targetModel.media[0];
                                // match them to populate our model
                                if (media.videoId === videoModel.id) {

                                    // was just a placeholder so we can link back
                                    delete media.videoId;

                                    var startIndex = videoModel.player.embedHtml.indexOf(" src=\"");
                                    if (startIndex < 0) {
                                        targetModel.media.pop();
                                    } else {
                                        startIndex += 6; // length of ' src="'
                                        var endIndex = videoModel.player.embedHtml.indexOf("\"", startIndex);

                                        const widthStartIndex = videoModel.player.embedHtml.indexOf("width=") + 'width="'.length;
                                        const widthEndIndex = videoModel.player.embedHtml.indexOf('"', widthStartIndex);
                                        const width = videoModel.player.embedHtml.slice(widthStartIndex, widthEndIndex);

                                        const heightStartIndex = videoModel.player.embedHtml.indexOf("height=") + 'height="'.length;
                                        const heightEndIndex = videoModel.player.embedHtml.indexOf('"', heightStartIndex);
                                        const height = videoModel.player.embedHtml.slice(heightStartIndex, heightEndIndex);

                                        media.duration = videoModel.contentDetails.duration;
                                        media.height = parseInt(height);
                                        media.width = parseInt(width);

                                        media.embedLink = targetModel.link = targetModel.embed = videoModel.player.embedHtml.slice(startIndex, endIndex);

                                        var thumbnails = videoModel?.snippet?.thumbnails;
                                        if (!!thumbnails) {
                                            media.children = [];
                                            var item = thumbnails.default;
                                            if (!!(item)) {
                                                media.children.push({ height: item.height, thumbnailLink: item.url, width: item.width, isExternalReference: true });
                                            }
                                            item = thumbnails.medium;
                                            if (!!(item)) {
                                                media.children.push({ height: item.height, thumbnailLink: item.url, width: item.width, isExternalReference: true });
                                            }
                                            item = thumbnails.high;
                                            if (!!(item)) {
                                                media.children.push({ height: item.height, thumbnailLink: item.url, width: item.width, isExternalReference: true });
                                            }
                                            item = thumbnails.standard;
                                            if (!!(item)) {
                                                media.children.push({ height: item.height, thumbnailLink: item.url, width: item.width, isExternalReference: true });
                                            }
                                            item = thumbnails.maxres;
                                            if (!!(item)) {
                                                media.children.push({ height: item.height, thumbnailLink: item.url, width: item.width, isExternalReference: true });
                                            }

                                            var filteredThumbs = media.children.filter(child => child.width <= media.width);
                                            if (filteredThumbs.length > 0) {
                                                media.children = [filteredThumbs.sort((a, b) => { return a.width < b.width ? 1 : -1 })[0]];
                                            } else {
                                                media.children = [media.children.sort((a, b) => { return a.width > b.width ? 1 : -1 })[0]];
                                            }
                                                                                           
                                        }
                                    }
                                    targetModel.raw.video = videoModel;
                                    break;
                                }
                            }
                        }

                        // search in data for model items with model.id == response.items.id
                    };
                    let retVal = { data: data }


                    const handleRejected = (response) => {
                        reject(response.error);
                    }

                    const handleResolved = async (response) => {
                        let source = response.items.sort((a, b) => { return new Date(a.snippet.publishedAt) - new Date(b.snippet.publishedAt)});
                        let videoIds = '';
                        while (source.length) {
                            var item = source.pop();
                            // TODO: filter out items that already exist in user profile

                            var model = targetModelFrom(item);
                            if (!!model) {
                                videoIds += ((videoIds.length > 0) ? ',' : '');
                                videoIds += model.media[0].videoId;
                                data.push(model);
                            }
                        }

                        if (videoIds.length > 0) {
                            const handleInnerResolved = async (response) => {
                                updateModels(response.items);
                            };

                            let url = `https://youtube.googleapis.com/youtube/v3/videos?part=contentDetails%2Cid%2Csnippet%2Cstatistics%2Cplayer&id=${videoIds}&maxWidth=640&maxresults=25`

                            let api = await getRequest(url, false, _authorization.access_token);
                            const promise = new Promise((resolve, reject) => {
                                if (api) {
                                    resolve(api);
                                } else {
                                    reject(new Error('api variable is not defined'));
                                }
                            });

                            promise.then(handleInnerResolved, handleRejected);

                        }
                        if (!!(response.nextPageToken)) {
                            retVal.paging = { nextPageToken: response.nextPageToken };
                        } else {
                            retVal.isFullyLoaded = true;
                        }
                    };

                    let nextPageToken;
                    if (!!(channel.posts?.paging?.nextPageToken)) {
                        nextPageToken = channel.posts.paging?.nextPageToken;
                        delete channel.posts.paging;
                    }

                    let url = "https://youtube.googleapis.com/youtube/v3/playlistItems?part=contentDetails%2Cid%2Csnippet%2Cstatus&playlistId=" + channel.id + "&maxResults=25";
                    if (!!(nextPageToken)) {
                        url += "&pageToken=" + nextPageToken;
                    }
                    let api = await getRequest(url, false, _authorization.access_token);
                    const promise = new Promise((resolve, reject) => {
                        if (api) {
                            resolve(api);
                        } else {
                            reject(new Error('api variable is not defined'));
                        }
                    });

                    await promise.then(handleResolved, handleRejected);

                    resolve(retVal);

                } catch (ex) {
                    reject(ex);
                }
            }
        });
    }

    getUserProfile() {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(getUserProfileInternal());
            } catch (ex) {
                reject(ex);
            }
        });
    }

    init(appId, apiKey) {
        let candidate = { api: this };
        let isInitializing = false;
        if (_clientHandle === undefined) {
            _clientHandle = candidate;
            isInitializing = true;
        }
        let retVal = _clientHandle;
        return new Promise(async (resolve, reject) => {
            try {
                resolve(retVal);
            } catch (ex) {
                reject(ex);
            }
        });
    }

    login() {
        return new Promise(async (resolve, reject) => {
            const auth = await getGoogleAuth();
            try {
                setAuthorization(!!(auth) ? auth : undefined);
                resolve(auth);
            }
            catch (ex) {
                reject(ex);
            }
        });
    }

    logout() {
        return new Promise(async (resolve, reject) => {
            try {
                let google = await getYoutubeClient();
                google.accounts.oauth2.revoke(_authorization.access_token);
                resetEnvironmentVariables();
                resolve(true);
            } catch (ex) {
                reject(ex);
            }
        });
    }

    processEmbeds(elements, headers, onReady) {
        const doEmbed = async () => {
            let tasks = [];
            for (let i = 0; i < elements.length; i++) {
                let element = elements[i];
                let task = new Promise((resolve, reject) => {
                    try {
                        resolve(element);
                    }
                    catch (ex) {
                        reject(ex.error);
                    }
                });
                tasks.push(task);
            }
            while (tasks.length) {
                let task = tasks.pop();
                let element = await task;

                let child = document.createElement('div');
                element.appendChild(child);

                const YT = await getPlayerScript();
                let args = {
                    videoId: element.getAttribute('data-id')
                };
                if (!!onReady) {
                    args.events = {
                        'onReady': onReady
                    }
                }
                new YT.Player(child, args);
            }
        }
        doEmbed();
    }

}

const youtubeClient = window.youtubeClient || new YoutubeClient();
if (!window.youtubeClient) {
    window.youtubeClient = youtubeClient;
}

export default youtubeClient;
