import React from "react";
import { useState, useEffect } from "react";


const ErrorLog = (props) => {

    const [log, setLog] = useState(props.log);

    useEffect(() => {
        if (props.log !== log) {
            setLog(props.log);
        }
    }, [props.log, log]);

    const toggleErrorLog = () => {
        props.toggleErrorLog();
    }

    const displayTableRows = () => {
        if (log) {
            return log.allXml.error.serverVariables.item.map((row, i) => {
                const value = row?.value?.string != null ? row.value.string : "";
                return (
                    <tr key={i}>
                        <td>{row.name}</td>
                        <td>{value}</td>
                    </tr>
                )
            })
        }
        else {
            return (
                <tr key={0}>
                    <td colSpan={1}>No data</td>
                </tr>
            )
        }
    }

    if (log) {
        let date = new Date(log.timeUtc);
        date = date.setHours(date.getHours() - 5);
        const dayNum = new Date(date).getDay().toLocaleString();
        const formattedDate = new Date(date).toLocaleString();

        const dayOfWeek = ['Sunday', 'Monday', 'Tuesday',
            'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const day = dayOfWeek[dayNum];

        return (
            <div style={props.display ? {} : { display: 'none' }}>
                <div style={{ margin: "50px", padding: "30px", backgroundColor: "#fff", fontSize: "14px", lineHeight: "1.5rem" }}>
                    <button style={{ marginBottom: "30px", fontSize: "16px", padding: "10px", borderRadius: "3px", border: "none", backgroundColor: "#e55934", color: "#fff" }} onClick={toggleErrorLog}>Return To Logs</button>

                    <h3 style={{ overflow: "hidden", wordBreak: "break-all", marginBottom: "20px" }}>{log.message}</h3>
                    <h6>{log.type}</h6>
                    {log.apiName === "Web" ?
                        <p style={{ fontSize: "12px", lineHeight: "1.5rem", marginTop: "10px" }}>{log.allXml.error.detail}</p> :
                        <pre style={{ lineHeight: "1.5rem", marginTop: "10px" }}>{log.allXml.error.detail}</pre>
                        }
                    <p style={{fontWeight: 550, marginTop: "40px", marginBottom: "40px" }}>Logged on {day}, {formattedDate}</p>

                    <div className="row mt-0 mx-0" style={{ lineHeight: ".9rem" }}>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th><strong>Name</strong></th>
                                        <th><strong>Value</strong></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {displayTableRows()}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <button style={{ marginTop: "30px", fontSize: "16px", padding: "10px", borderRadius: "3px", border: "none",  backgroundColor: "#e55934", color: "#fff" }} onClick={toggleErrorLog}>Return To Logs</button>
                    </div >
                </div>
        )
    } else {
        return (
            <div style={props.display ? {} : { display: 'none' }}>
                <div>
                    <h1>NO LOG</h1>
                    <button style={{ border: "none", fontSize: "16px", padding: "10px", borderRadius: "3px", backgroundColor: "#e55934", color: "#fff" }} onClick={toggleErrorLog}>Return to Error Logs</button>
                </div >
            </div>
        )
    }
}

export default ErrorLog;