import React, { useState } from "react";
import InformationIcon from "components/icons/InformationIcon"
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import ActionButton from "components/ActionButton";


/**
 * 
 * @param {{addToLocker:Function,selectedProducts:object[]}} props
 */
const ProductSelectorModalFooter = (props) => {
    const [isAdding, setIsAdding] = useState(false);
    function displayCount() {
        if (props.selectedProducts?.length > 0) {
            let text = props.selectedProducts.length === 1 ? "Selected product" : "Selected products";
            return (
                <p className="my-0" style={{ lineHeight: "48px"}}>
                    <span className="alert alert-danger">{props.selectedProducts.length}</span> {text}
                </p>
            )
        }
        else {
            return null
        }
    }

    return (
        <div className="modal-footer">
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                {displayCount()}
                {
                    props.selectedProducts.length === 0 ? (
                        <div className="py-1">
                            <small>
                                Estimated Commission
                                <sup>*</sup>
                                <Link to="#" onClick={e => e.preventDefault()} style={{ cursor: "pointer", fontSize: "8px", backgroundColor: "transparent", border: "0px solid transparent" }} id="estimatedCommissionsFooter">
                                    <small><InformationIcon style={{ position: "relative", top: "-3px" }} x="15" y="15" /></small>
                                </Link>
                                
                            </small>
                            <UncontrolledPopover trigger="legacy" target="estimatedCommissionsFooter" placement="top">
                                <PopoverBody>
                                    Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of consumer's purchase
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                    ):<span></span>
                    
                }
                <ActionButton isLoading={isAdding} className="btn btn-primary" onClick={async () => {
                    setIsAdding(true);
                    await props.addToLocker(props.selectedProducts)
                    setIsAdding(false);
                }}>
                    Add
                </ActionButton>
            </div>

       
            
        </div>
    )
}

export default ProductSelectorModalFooter;