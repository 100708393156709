import React from "react";
import { Link, NavLink, withRouter } from 'react-router-dom';
import BrowseProductFeed from "./BrowseProductFeed";
import BrowseBar from "./BrowseBar";
import NoResults from "components/NoResults";
import { getRequest } from "sharedUtils/httpUtils";
import { getAuthorizedUserData } from "sharedUtils/authUtils";
import LoadSpinner from "components/LoadSpinner";

import { toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getQueryStringParams, showBrandNav } from "sharedUtils/navUtils"

import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import StarRatingComponent from 'react-star-rating-component';
import CloseIcon from "components/icons/CloseIcon";

import { Slider } from '@material-ui/core';
import { Helmet } from 'react-helmet';


class Browse extends React.Component {
    state = {
        isGrid: true,
        lockerSearchResults: [],
        productSearchResults: [],
        postSearchResults: [],
        peopleSearchResults: [],
        searchString: "",
        /*1:Locker, 2:Products, 3:Posts, 4:People*/
        resultCase: 2,
        searching: true,
        loggedInUser: {},
        finishedFetchingLockers: false,
        finishedFetchingPosts: false,
        finishedFetchingProducts: false,
        categories: [],
        category: 0,
        merchant: 0,
        brand: 0,
        max: 2000,
        min: 0,
        merchants: [],
        brands: [],
        rating: 0,
        onSale: false,
        sortBy: { value: 0, label: "Recommended" },
        sortByQuery: null,
        query: "",
        closeFilter: false,
        categoryToggle: true,
        bestUsesToggle: true,
        priceToggle: true,
        ratingToggle: true,
        sortByToggle: true,
        sideFilterFetch: false,
        filterOpen: false,
        isMobile: false,
        mobileLocationBarOpen: false,
        searchInputInit: false,
        mounted: false,
        skipFetch: false,
        bestUses: [],
        searchBar: false,
    }

    asideRef = React.createRef();
    animationFrameId = null;

    async componentDidMount() {
        this.props.fetchCategories();
        //this.props.setShowTopNav(true, this.props.showBrandNav(window.location.search));
        this.props.setShowTopNav(true, true);
        await this.fetchCategories();

        document.addEventListener("touchstart", this.handleTouchStartListener, { passive: false });
        document.addEventListener("mouseup", this.handleTouchStartListener, { passive: false });

        this.setState({ isMobile: window.innerWidth - document.documentElement.clientWidth < 5 });
        const search = getQueryStringParams(window.location.search).search;
        const searchPath = this.getLastSegmentFromPath();
        if (!!(searchPath) || !!(search)) {
            const searchInput = !!(searchPath) ? searchPath : search;

            this.props.populateSearch(searchInput);
        }
        this.initializeSearchStringAndQueryParams();

        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        if (this.state.merchants?.length < 1) {
            this.getMerchants();
        }

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }

        window.scrollTo(scrollOptions);
        this.setState({ mounted: true });
    }

    scrollOptions = {
        left: 0,
        top: 0,
        behavior: 'auto',
    }

    handleSearchReset = async () => {
        this.props.handleResetBrowseSearch(false);
        this.setState({ searchValue: "" });
    }

    resetSearch = async () => {
        await this.props.handleResetBrowseSearch(true);
        await this.props.handleResetSearchBar(true);
        await this.setState({ searchValue: "" });
    }

    getLastSegmentFromPath = () => {
        const pathname = window.location.pathname;
        const trimmedPathname = pathname.replace(/^\/|\/$/g, '');
        const segments = trimmedPathname.split('/');
        if (segments.length === 1) {
            return null; // Return null if only one segment
        }
        const lastSegment = segments[segments.length - 1];
        return lastSegment;
    };

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.resetBrowseSearch !== this.props.resetBrowseSearch && this.props.resetBrowseSearch) {
            await this.handleSearchReset();
            await this.props.populateSearch("");
            this.fetchResults();
            return;
        }
        if (this.state.mounted && decodeURIComponent(this.props.location.search) !== this.state.query) {
            window.scrollTo(this.scrollOptions);

            this.initializeSearchStringAndQueryParams();
        }

        if (prevState.filterOpen !== this.state.filterOpen && !!(this.asideRef?.current)) {
            if (this.state.filterOpen) {
                this.asideRef.current.classList.add('visible');
                this.props.setBrowseFilterOpen(true);
            } else {
                this.asideRef.current.classList.remove('visible');
                this.props.setBrowseFilterOpen(false);
            }
        }
        if (Math.abs(this.props.screenWidth - prevProps.screenWidth) > 10) {
            const isMobile = window.innerWidth - document.documentElement.clientWidth < 5;
            this.setState({ isMobile });
        }
        if (prevState.category !== this.state.category && this.state.category > 0) {
            this.handleSearchForBestUses();
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
        document.removeEventListener("touchstart", this.handleTouchStartListener);
        document.removeEventListener("mouseup", this.handleTouchStartListener);

        window.cancelAnimationFrame(this.animationFrameId);
    }

    handleTouchStartListener = (e) => {
        if (["sm", "md"].includes(this.props.deviceSize) && !this.state.filterOpen && e.target.className === 'browse-filter-btn') {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            this.setState({ filterOpen: true });
        } else if (["sm", "md"].includes(this.props.deviceSize) && this.state.filterOpen && (!e.target.closest(`.browse-filter-aside`) || e.target.classList.contains("close-icon-filter"))) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            this.setState({ filterOpen: false });
        }
    }

    handleResize = () => {
        if (this.animationFrameId) {
            window.cancelAnimationFrame(this.animationFrameId);
        }
        this.animationFrameId = window.requestAnimationFrame(() => {
            this.updateMobileLocationBarOpen();
        });
    }

    updateMobileLocationBarOpen() {
        if (this.state.isMobile) {
            const mobileLocationBarOpen = (window.innerHeight < window.screen.height);
            this.setState({ mobileLocationBarOpen });
        }
    }

    handleSortFilterReset = async (catReset = true) => {
        await this.props.populateSearch("");
        await this.props.handleResetSearchBar(true);

        if (catReset) {
            const category = 0;

            this.setState({ category, searchValue: "" });
        }

        const min = 0;
        const max = 2000;
        const sortBy = { value: 0, label: "Recommended" };
        const rating = 0;
        const merchant = 0;
        const onSale = false;

        this.setState({ min, max, sortBy, rating, merchant, onSale });
    }

    initializeSearchStringAndQueryParams = async () => {
        let searchPath = !!(this.props.match.params.searchString) ? this.props.match.params.searchString : "";
        this.props.populateSearch(searchPath);

        this.setState({ closeFilter: true })
        const params = getQueryStringParams(window.location.search);
        this.setState({ query: decodeURIComponent(window.location.search) })

        let category = params.category;

        let searchValue = params.search;
        let subcategory = params.subcategory;

        const min = params.min;
        const max = params.max;
        const sortby = params.sortby;
        const rating = params.rating;
        const merchant = params.merchant;
        const onsale = params.onsale;

        const searchBar = params.searchBar;
        if (!!(searchBar)) {
            this.setState({ searchBar: true })
        }

        if (!!(searchValue)) {
            await this.handleSortFilterReset();

            await this.props.populateSearch(decodeURIComponent(searchValue));
        } else {
            if (!(this.getLastSegmentFromPath())) {
                await this.props.handleResetSearchBar(true);
            }
        }
        if (!!(category) && this.state.categories.length > 0) {
            let categoryObj = this.state.categories.filter(cat => cat.name.toLowerCase() === category.toLowerCase() && cat.level === 1)[0];

            if (!!(subcategory)) {
                let subCategoryObj = this.state.categories.filter(cat => cat.name.toLowerCase() === subcategory.toLowerCase() && cat.parentCategoryId === categoryObj.id)[0];

                if (!!(subCategoryObj)) {
                    category = subCategoryObj.id;
                    this.setState({ category });
                }
            } else {
                if (!!(categoryObj)) {
                    category = categoryObj.id;
                    this.setState({ category });
                }
            }
        }


        let merchantId = null;
        if (!!(merchant)) {
            const merchants = await this.getMerchants(true);
            if (!!(merchants) && !!(merchants.filter(x => x.name.toLowerCase() === merchant.toLowerCase()).length === 1)) {
                let merchantArrObj = merchants.filter(x => x.name.toLowerCase() === merchant.toLowerCase());
                merchantId = merchantArrObj[0].id;
                this.setState({ merchant: merchantId });
            }
        }

        if (!!(min) && !!(parseFloat(min))) {
            this.setState({ min: parseFloat(min) })
        }

        if (!!(max) && !!(parseFloat(max))) {
            this.setState({ max: parseFloat(max) })
        }
        if (!!(onsale) && onsale === "true" || onsale === "false") {
            this.setState({ onSale: onsale === "true" })
        }
        let sortBy = null;
        if (!!(sortby)) {
            const sortArray = [
                { value: 0, label: "Recommended" },
                { value: 1, label: "Relevance" },
                { value: 2, label: "Price" },
                { value: 3, label: "Rating" },
            ];

            if (!!(sortArray.filter(x => x.label.toLowerCase() === sortby.toLowerCase()).length === 1)) {
                sortBy = sortArray.filter(x => x.label.toLowerCase() === sortby.toLowerCase())[0];
                this.setState({ sortByQuery: sortBy })
            }
        }

        if (!!(rating) && !!(parseFloat(rating))) {
            this.setState({ rating: parseFloat(rating) })
        }

        if (this.props.loggedInUser !== null) {
            this.setState({ loggedInUser: this.props.loggedInUser });
        }
        else {
            let user = await getAuthorizedUserData();
            this.setState({ loggedInUser: user });
        }

        this.fetchResults(!!(category) ? category : this.state.category, !!(min) ? min : null, !!(max) ? max : null, !!(merchantId) ? merchantId : 0, !!(rating) ? rating : 0,
            !!(sortBy) ? sortBy.label : "Recommended", onsale ? onsale : false);

        this.setState({ searchInputInit: false })
    }

    fetchCategories = async () => {
        let categories = null;
        if (!(this.state.categories) || this.state.categories.length === 0) {
            let url = `/api/Sector/GetCategories`;
            categories = await getRequest(url);

            this.setState({ categories: !!(categories) ? categories : this.state.categories });
        }
    }

    fetchResults = async (category = this.state.category, min = this.state.min, max = this.state.max,
        merchantId = this.state.merchant, rating = this.state.rating, sortBy = this.state.sortBy.label, onSale = this.state.onSale) => {
        if (this.state.skipFetch) return;

        this.setState({ sideFilterFetch: true })
        if (max == 2000) {
            max = null;
        }
        if (min == 0) {
            min = null;
        }

        const take = 24;

        this.setState({ searching: true });


        let search = this.props.searchInput;
        let url = `/search/SearchProducts?userId=${this.state.loggedInUser?.id}${!!(search) && search.length > 0 ? "&search=" + search : ""}&skip=0&take=${take}&min=${min}&max=${max}&merchantId=${merchantId}&rating=${rating}&sortBy=${sortBy}&onSale=${onSale}`;
        if (!!(category) && category > 0) {
            url += `&category=${category}`;
        }

        let results = await getRequest(url);
        if (results === undefined || results === null || results.length === 0 && !!(this.props.searchInput) && this.props.searchInput.length > 0) {
            this.setState({ productSearchResults: [], finishedFetchingProducts: true, searching: false, });
            this.props.history.push(`/SearchResults/${this.props.searchInput}`);
        }
        else {
            this.setState({ productSearchResults: results, finishedFetchingProducts: results.length === 0, searching: false })
        }
        this.setState({ sideFilterFetch: false, searchBar: false });
    }

    getMerchants = async (doReturn = false) => {
        let merchantUrl = '/api/Merchant/GetMerchantList';
        let merchants = await getRequest(merchantUrl);
        this.setState({ merchants });
        if (doReturn) {
            return merchants;
        }
    }

    fetchMore = async () => {
        const min = this.state.min;
        const max = this.state.max;
        let category = !!(this.state.thirdLevelSub) ? this.state.thirdLevelSub : !!(this.state.subCategory) ? this.state.subCategory : this.state.category;
        let search = !!(this.state.searchValue) && this.state.searchValue.length > 0 ? this.state.searchValue : this.props.searchInput;

        const take = 24;

        const sortBy = this.state.sortBy.label;

        const merchantId = this.state.merchant;
        const rating = this.state.rating;
        const onSale = this.state.onSale;

        let { lockerSearchResults, productSearchResults, postSearchResults, peopleSearchResults, resultCase } = this.state;

        let existingResults = [lockerSearchResults, productSearchResults, postSearchResults, peopleSearchResults];

        let skip = existingResults[resultCase - 1].length;

        let url = `/search/SearchProducts?userId=${this.state.loggedInUser?.id}${!!(search) && search.length > 0 ? "&search=" + search : ""}&skip=${skip}&take=${take}&min=${min}&max=${max}&merchantId=${merchantId}&rating=${rating}&sortBy=${sortBy}&onSale=${onSale}`;
        if (!!(category) && category > 0) {
            url += `&category=${category}`;
        }
        let data = await getRequest(url);

        if (data === undefined || data === null || data?.length === 0) {
            this.setState({ finishedFetchingProducts: true });
        }
        else {
            let newData = [...productSearchResults, ...data];
            this.setState({ productSearchResults: newData });
        }
    }

    createCategoryAncestry = (selectedCategory) => {
        if (this.state.categories.length > 0) {
            const categories = this.state.categories;
            let categoryArray = [];
            let currentCategory = categories.find(category => category.id === selectedCategory);
            while (!!(currentCategory)) {
                categoryArray.unshift(currentCategory);
                if (!!(currentCategory.parentCategoryId)) {
                    currentCategory = categories.find(category => category.id === currentCategory.parentCategoryId);
                } else {
                    currentCategory = null;
                }
            }
            return categoryArray;
        } else {
            return [];
        }
    };

    handleSearchForBestUses = () => {
        let result = [];
        const categories = this.state.categories;
        if (categories.length > 0) {
            let ancestryArray = this.createCategoryAncestry(this.state.category);
            let originalAncestryArray = [...ancestryArray];
            while (ancestryArray.length >= 2) {
                const firstCategory = ancestryArray[0];
                const secondCategory = ancestryArray[1];

                for (const category of categories) {
                    if (category.name === firstCategory.name && category.level > 1) {
                        const firstMatch = category;
                        const secondMatchArray = categories.filter(cat => cat.level === firstMatch.level + 1 && cat.parentCategoryId === firstMatch.id && cat.name === secondCategory.name);

                        if (secondMatchArray.length > 0) {
                            const secondMatch = secondMatchArray[0];
                            const rootMatch = this.createCategoryAncestry(secondMatch.id)[0];
                            if (rootMatch.id !== firstCategory.id) {
                                result.push({
                                    lowestLevelName: !!(rootMatch.menuName) ? rootMatch.menuName : rootMatch.name,
                                    lowestLevelId: rootMatch.id,
                                    topLevelCategoryId: secondMatch.id,
                                });
                            }
                        }
                    }
                }
                if (result.length === 1 && result[0].lowestLevelId === originalAncestryArray[0].id && result[0].topLevelCategoryId === originalAncestryArray[originalAncestryArray.length - 1].id) {
                    result = [];
                    break;
                } else if (result.length > 0) {
                    break;
                } else {
                    ancestryArray.shift();
                }
            }
        }
        this.setState({ bestUses: result })
    }

    toggleLayout = () => {
        let newLayout = !this.state.isGrid;
        this.setState({ isGrid: newLayout });
    }

    resetCloseFilter = () => {
        this.setState({ closeFilter: false });
    }

    handleCloseFilter = () => {
        this.setState({ closeFilter: true });
    }

    starClick = async (nextValue, prevValue, name) => {
        if (nextValue !== 0 && Number.isInteger(prevValue) || Math.floor(prevValue) + 1 !== nextValue) {
            nextValue = nextValue - .5;
        }
        this.setState({ rating: nextValue });
    }

    starClickFetch = async (nextValue, prevValue, name) => {
        this.setState({ sideFilterFetch: true })
        if (nextValue !== 0 && Number.isInteger(prevValue) || Math.floor(prevValue) + 1 !== nextValue) {
            nextValue = nextValue - .5;
        }
        this.setState({ rating: nextValue });

        await this.fetchResults(this.state.category, this.state.min, this.state.max, this.state.merchant, nextValue);
        this.setState({ sideFilterFetch: false })

    }

    sortByFetch = async (sortBy) => {
        const sortByArr = [
            { value: 0, label: "Recommended" },
            { value: 1, label: "Relevance" },
            { value: 2, label: "Price" },
            { value: 3, label: "Rating" },
        ];
        this.setState({ sideFilterFetch: true, sortBy: sortByArr.filter(x => x.label.toLowerCase() === sortBy.toLowerCase())[0] })


        await this.fetchResults(this.state.category, this.state.min, this.state.max, this.state.merchant, this.state.rating, sortBy);
        this.setState({ sideFilterFetch: false })
    }

    onSaleFetch = async () => {
        const onSaleCurrent = this.state.onSale;
        this.setState({ sideFilterFetch: true, onSale: !onSaleCurrent })


        await this.fetchResults(this.state.category, this.state.min, this.state.max, this.state.merchant, this.state.rating, this.state.sortBy.label, !onSaleCurrent);
        this.setState({ sideFilterFetch: false })
    }

    handlePriceRange = async (event, newValue) => {
        this.setState({ sideFilterFetch: true, min: newValue[0], max: newValue[1] });
        this.setState({ sideFilterFetch: false })
    };

    handlePriceRangeFetch = async (event, newValue) => {
        this.setState({ sideFilterFetch: true, min: newValue[0], max: newValue[1] });

        await this.fetchResults(this.state.category, newValue[0], newValue[1]);
        this.setState({ sideFilterFetch: false })
    };

    setFilterOpen = (filterOpen) => {
        this.setState({ filterOpen });
    }

    onCategoryInputChangeFetch = async (category) => {
        this.setState({ sideFilterFetch: true })

        this.setState({ category });

        await this.fetchResults(category);

        this.setState({ sideFilterFetch: false })
    };

    displayProductResults = () => {
        if (this.state.productSearchResults?.length === 0) {
            return <NoResults />
        }
        else {
            if (this.state.isGrid === true) {
                return (
                    <div className="product-grid">
                        <BrowseProductFeed
                            products={this.state.productSearchResults}
                            isGrid={this.state.isGrid}
                            finishedFetching={this.state.finishedFetchingProducts}
                            loggedInUser={this.state.loggedInUser}
                            fetchMore={this.fetchMore}
                            toggleSignUpModal={this.props.toggleSignUpModal}
                            deviceSize={this.props.deviceSize}
                        />
                    </div>
                )
            }
            else {
                return <BrowseProductFeed
                    products={this.state.productSearchResults}
                    isGrid={this.state.isGrid}
                    finishedFetching={this.state.finishedFetchingProducts}
                    fetchMore={this.fetchMore}
                    loggedInUser={this.state.loggedInUser}
                    toggleSignUpModal={this.props.toggleSignUpModal}
                    deviceSize={this.props.deviceSize}
                />
            }
        }

    }

    downwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
        )
    }

    upwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
        )
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    sortCategories = categories => {
        return categories.sort((a, b) => {
            if (a.menuVisible && !b.menuVisible) {
                return -1; // a comes first
            } else if (!a.menuVisible && b.menuVisible) {
                return 1; // b comes first
            } else if (a.menuVisible && b.menuVisible) {
                if (a.ordinal < b.ordinal) {
                    return -1; // a comes first
                } else if (a.ordinal > b.ordinal) {
                    return 1; // b comes first
                } else {
                    return 0; // no change in order
                }
            } else {
                if (a.menuVisibleWithMore && !b.menuVisibleWithMore) {
                    return 1; // b comes first
                } else if (!a.menuVisibleWithMore && b.menuVisibleWithMore) {
                    return -1; // a comes first
                } else {
                    return 0; // no change in order
                }
            }
        });
    }

    handleInputChangeFetch = async (category, isChecked) => {
        if (!!(this.props.searchInput) && this.props.searchInput.length > 0) {
            this.setState({ skipFetch: true })
            await this.resetSearch();
            this.setState({ skipFetch: false });
        }
        if (isChecked) {
            this.setState({ category });

            this.fetchResults(category);
            window.scrollTo(this.scrollOptions);
            return;
        }

        this.onCategoryInputChangeFetch(category);

    }

    handleInputBestUses = async (category) => {
        if (category !== this.state.category) {
            if (!!(this.props.searchInput) && this.props.searchInput.length > 0) {
                this.setState({ skipFetch: true })
                await this.resetSearch();
                this.setState({ skipFetch: false });
            }
            this.setState({ category })
            this.onCategoryInputChangeFetch(category);
        }
    }

    parseCategoriesByParentCategoryId = parentCategoryId => {
        if (!!(parentCategoryId)) {
            return this.sortCategories(this.state.categories.filter(cat => cat.parentCategoryId === parentCategoryId));
        }
        return this.sortCategories(this.state.categories.filter(cat => cat.level === 1));
    }

    renderFilterCategories = () => {
        let category = this.state.category;
        let categoryObj = this.state.categories.filter(cat => cat.id === category)[0];

        let categories = this.parseCategoriesByParentCategoryId(!!(categoryObj) ? category : null);

        if (categories.length === 0) {
            categories = this.parseCategoriesByParentCategoryId(categoryObj == null || categoryObj == undefined || !categoryObj.hasOwnProperty('parentCategoryId') || categoryObj.parentCategoryId == undefined || categoryObj.parentCategoryId === 0 ? -1 : categoryObj.parentCategoryId);
        }
        return categories.map((cat, index) => {
            const name = !!(cat.menuName) ? cat.menuName : cat.name;
            return (
                <div className="form-check" key={index}>
                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                        <input type="checkbox" checked={cat.id === category} className="form-check-input" onClick={() => this.handleInputChangeFetch(cat.id === category && (categoryObj == null || categoryObj == undefined || !categoryObj.hasOwnProperty('parentCategoryId') || categoryObj.parentCategoryId == undefined || categoryObj.parentCategoryId === 0) ? 0 : cat.id === category ? categoryObj.parentCategoryId : cat.id, cat.id === category)} value="" />
                        {name}
                    </label>
                </div>
            );
        });
    }

    renderBestUses = () => {
        let bestUses = this.sortCategories(this.state.bestUses)
        return bestUses.map((cat, index) => {
            const name = cat.lowestLevelName;
            return (
                <div className="form-check" key={index}>
                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                        <input type="checkbox" checked={cat.topLevelCategoryId === this.state.category} className="form-check-input" onClick={() => this.handleInputBestUses(cat.topLevelCategoryId)} value="" />
                        {name}
                    </label>
                </div>
            );
        });
    }

    handleAsideVisibility = () => {
        const { filterOpen } = this.state;
        const asideElement = this.asideRef.current;

        if (filterOpen) {
            asideElement.classList.add('visible');
        } else {
            asideElement.classList.remove('visible');
        }
    }

    resetFilter = async () => {
        this.setState({
            sideFilterFetch: true,
            categoryToggle: true,
            priceToggle: true,
            ratingToggle: true,
            sortByToggle: true,
            filterOpen: false,
        });

        if (!!(this.props.searchInput) && this.props.searchInput.length > 0) {
            this.setState({ skipFetch: true })
            await this.resetSearch();
            this.setState({ skipFetch: false });
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        await this.handleSortFilterReset();
        this.handleCloseFilter();
        await this.fetchResults();

        this.setState({ sideFilterFetch: false })
    }

    render() {
        const asideStyle = {
            marginRight: "40px",
            padding: ["sm", "md"].includes(this.props.deviceSize) ? "20px" : "20px 20px 20px 3px",
            width: "25%",
            overflow: "auto",
        }

        const divStyle = {
            marginBottom: "10px",
            borderBottom: "1px solid gray",
            color: "black",
        }

        let containerAppendClass = this.props.deviceSize === "sm" || this.props.deviceSize === "md" ? "" : "";

        return (
            <section className="gray-bg full-height explore-feed-wrapper" style={{ position: this.state.filterOpen ? "fixed" : "" }}>
                <Helmet>
                    <title>RockPorch - Browse</title>
                </Helmet>
                <BrowseBar
                    isGrid={this.state.isGrid}
                    toggleAction={this.toggleLayout}
                    hideToggleAction={this.state.resultCase === 4}
                    categories={this.state.categories}
                    category={this.state.category}
                    deviceSize={this.props.deviceSize}
                    closeFilter={this.state.closeFilter}
                    resetCloseFilter={this.resetCloseFilter}
                    loggedInUser={this.props.loggedInUser}
                    screenWidth={this.props.screenWidth}
                    setFilterOpen={this.setFilterOpen}
                    filterOpen={this.state.filterOpen}
                    searchInput={this.props.searchInput}
                    getLastSegmentFromPath={this.getLastSegmentFromPath}
                />
                <div className="browse-view" style={{ width: !this.state.isMobile && this.props.screenWidth <= 991 ? `calc(100vw - 17px)` : this.state.isMobile && this.props.screenWidth <= 991 ? "100vw" : "" }}>
                    {
                        this.state.searching && !this.state.sideFilterFetch ?
                            <LoadSpinner /> :
                            <div className="container d-flex w-100 px-0">
                                <aside ref={this.asideRef} className={`browse-filter-aside ${this.state.filterOpen ? "" : !!(this.props.loggedInUser) ? "browse-filter-aside-close" : "browse-filter-aside-close-anonymous"}`} style={{ ...asideStyle, height: ["sm", "md"].includes(this.props.deviceSize) && this.props.screenWidth < 576 ? this.props.screenHeight - 120 + "px" : ["sm", "md"].includes(this.props.deviceSize) ? this.props.screenHeight - 140 + "px" : "" }}>
                                    <h5 style={{ marginBottom: "10px", paddingBottom: "10px", borderBottom: "1px solid gray", fontFamily: "'Archivo', sans-serif" }}>Filter</h5>
                                    {
                                        ["sm", "md"].includes(this.props.deviceSize) ?
                                            <button className="close browse-close mr-2 position-absolute" onClick={this.handleCloseFilter} style={{ right: "5%", top: "17px", zIndex: 1000 }} >
                                                <CloseIcon browse={true} width={15} height={15} />
                                            </button>
                                            :
                                            <></>
                                    }
                                    <div className="post-edit-products-scroll" style={{ height: this.state.isMobile && ["sm", "md"].includes(this.props.deviceSize) && this.state.mobileLocationBarOpen ? "62%" : ["sm", "md"].includes(this.props.deviceSize) ? "64%" : "", overflowY: ["sm", "md"].includes(this.props.deviceSize) ? "scroll" : "" }}>
                                        <div style={{ ...divStyle }}>
                                            <p className="pb-0 mb-0">
                                                <button onClick={() => this.setState({ categoryToggle: !this.state.categoryToggle })} className={`btn w-100 d-flex justify-content-between align-items-center ${this.state.categoryToggle ? "collapsed" : ""}`} type="button" data-toggle="collapse" data-target="#categoryDropdown" aria-expanded={this.state.categoryToggle} aria-controls="collapseExample">
                                                    Category
                                                    {this.state.categoryToggle ? this.upwardChevron() : this.downwardChevron()}
                                                </button>
                                            </p>
                                            <div className={`collapse ${this.state.categoryToggle ? "show" : ""} w-75 mx-auto mb-3`} id="categoryDropdown">
                                                {this.renderFilterCategories()}
                                            </div>
                                        </div>
                                        {
                                            this.state.bestUses.length > 0 ?
                                                <div style={{ ...divStyle }}>
                                                    <p className="pb-0 mb-0">
                                                        <button onClick={() => this.setState({ bestUsesToggle: !this.state.bestUsesToggle })} className={`btn w-100 d-flex justify-content-between align-items-center ${this.state.bestUsesToggle ? "collapsed" : ""}`} type="button" data-toggle="collapse" data-target="#bestUsesDropdown" aria-expanded={this.state.bestUsesToggle} aria-controls="collapseExample">
                                                            Best Uses
                                                            {this.state.bestUsesToggle ? this.upwardChevron() : this.downwardChevron()}
                                                        </button>
                                                    </p>
                                                    <div className={`collapse ${this.state.bestUsesToggle ? "show" : ""} w-75 mx-auto mb-3`} id="bestUsesDropdown">
                                                        {this.renderBestUses()}
                                                    </div>
                                                </div> :
                                                <></>
                                        }
                                        <div style={divStyle}>
                                            <p className="pb-0 mb-0">
                                                <button onClick={() => this.setState({ priceToggle: !this.state.priceToggle })} className={`btn w-100 d-flex justify-content-between align-items-center ${this.state.priceTogglepriceToggle ? "collapsed" : ""}`} type="button" data-toggle="collapse" data-target="#priceDropdown" aria-expanded={this.state.priceToggle} aria-controls="collapseExample">
                                                    Price
                                                    {this.state.priceToggle ? this.upwardChevron() : this.downwardChevron()}
                                                </button>
                                            </p>
                                            <div className={`collapse ${this.state.priceToggle ? "show" : ""} mb-3 w-75 mx-auto`} id="categoryDropdown">

                                                <div className="position-relative mb-5">
                                                    <div style={{ width: "94%", position: "absolute", left: "50%", top: "-60px", transform: "translate(-50%, 0)" }}>
                                                        <Slider
                                                            getAriaLabel={() => "Price Range"}
                                                            value={[!!(this.state.min) ? this.state.min : 0, !!(this.state.max) ? this.state.max : 2000]}
                                                            min={0}
                                                            max={2000}
                                                            onChange={this.handlePriceRange}
                                                            onChangeCommitted={this.handlePriceRangeFetch}
                                                            className="py-2"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="position-relative">
                                                    <span className="position-absolute" style={{ top: "-34px", left: "0px", fontSize: ".6rem", fontFamily: "'Archivo', sans-serif" }}>{this.state.min <= 0 ? "Min" : this.formatter.format(this.state.min)}</span>
                                                    <span className="position-absolute" style={{ top: "-34px", right: "0px", fontSize: ".6rem", fontFamily: "'Archivo', sans-serif" }}>{this.state.max >= 2000 ? "Max" : this.formatter.format(this.state.max)}</span>
                                                </div>
                                                <div className="form-check mb-3" style={{ marginTop: "65px" }}>
                                                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                        <input type="checkbox" className="form-check-input" checked={this.state.onSale} onClick={() => this.onSaleFetch()} value="" />On Sale
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={divStyle}>
                                            <p className="pb-0 mb-0">
                                                <button onClick={() => this.setState({ ratingToggle: !this.state.ratingToggle })} className={`btn w-100 d-flex justify-content-between align-items-center`} type="button" data-toggle="collapse" data-target="#ratingDropdown" aria-expanded={this.state.ratingToggle} aria-controls="collapseExample">
                                                    Rating
                                                    {this.state.ratingToggle ? this.upwardChevron() : this.downwardChevron()}
                                                </button>
                                            </p>
                                            <div className={`collapse ${this.state.ratingToggle ? "show" : ""} w-75 mx-auto`} id="categoryDropdown">
                                                <div className="pb-3">
                                                    <StarRatingComponent
                                                        name="starRating"
                                                        starCount={5}
                                                        value={this.state.rating}
                                                        renderStarIcon={(index, value) => {
                                                            if (index <= value) {
                                                                return (<span className="ratingIcon" key={index}><RatingIcon /></span>);
                                                            } else {
                                                                return (<span className="ratingIcon" key={index}><RatingIconEmpty /></span>);
                                                            }
                                                        }}
                                                        renderStarIconHalf={(index, value) => {
                                                            return (<span className="ratingIcon" key={index}><RatingIconHalf /></span>);
                                                        }}
                                                        onStarClick={this.starClickFetch}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ ...divStyle, borderBottom: ["sm", "md"].includes(this.props.deviceSize) ? "" : "1px solid gray" }}>
                                            <p className="pb-0 mb-0">
                                                <button onClick={() => this.setState({ sortByToggle: !this.state.sortByToggle })} className={`btn w-100 d-flex justify-content-between align-items-center ${this.state.sortByToggle ? "collapsed" : ""}`} type="button" data-toggle="collapse" data-target="#sortByDropdown" aria-expanded={this.state.sortByToggle} aria-controls="collapseExample">
                                                    Sort By
                                                    {this.state.sortByToggle ? this.upwardChevron() : this.downwardChevron()}
                                                </button>
                                            </p>
                                            <div className={`collapse ${this.state.sortByToggle ? "show" : ""} w-75 mx-auto mb-3`} id="categoryDropdown">
                                                <div className="form-check">
                                                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                        <input type="checkbox" className="form-check-input" checked={this.state.sortBy.label === "Recommended"} onClick={() => this.sortByFetch("Recommended")} value="" />Recommended
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                        <input type="checkbox" className="form-check-input" checked={this.state.sortBy.label === "Relevance"} onClick={() => this.sortByFetch("Relevance")} value="" />Relevance
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                        <input type="checkbox" className="form-check-input" checked={this.state.sortBy.label === "Price"} onClick={() => this.sortByFetch("Price")} value="" />Price
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                                        <input type="checkbox" className="form-check-input" checked={this.state.sortBy.label === "Rating"} onClick={() => this.sortByFetch("Rating")} value="" />Rating
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around mt-3 mb-1" style={{ borderTop: ["sm", "md"].includes(this.props.deviceSize) ? "1px solid gray" : "", paddingTop: ["sm", "md"].includes(this.props.deviceSize) ? "20px" : "" }}>
                                        {
                                            ["sm", "md"].includes(this.props.deviceSize) ?
                                                <button className="btn btn-primary close-icon-filter" onClick={this.handleCloseFilter}>
                                                    View Products
                                                </button>
                                                :
                                                <></>
                                        }
                                        <button className="btn btn-secondary" onClick={this.resetFilter}>
                                            Reset
                                        </button>
                                    </div>
                                </aside>
                                {
                                    this.state.sideFilterFetch ?
                                        <LoadSpinner style={{ width: ["sm", "md"].includes(this.props.deviceSize) ? "" : "73%" }} />
                                        :
                                        <div className={`container ${containerAppendClass}`} style={{ width: ["sm", "md"].includes(this.props.deviceSize) ? "" : "73%" }}>
                                            <div className="row grid" style={{ marginTop: this.props.screenWidth < 577 && this.state.isGrid ? "2px" : this.props.screenWidth < 577 ? "0px" : ["sm", "md"].includes(this.props.deviceSize) ? "15px" : "" }}>
                                                <div className="col-12 px-0">
                                                    {this.displayProductResults()}
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </div>
            </section>

        )
    }
}

function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer, fetchFollowing: storeState.modalReducer.fetchFollowing }
    return result;
}

export default withRouter(connect(mapStateToProps, { toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing })(Browse));