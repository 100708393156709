import React, { useState } from 'react';
import { Coordinator } from 'components/Post/Create/Coordinator'

export const Create = () => {
    const [showModal, setShowModal] = useState(false);

    const handleHideModal = () => {
        setShowModal(false);
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    return <>
        <button disabled={showModal} onClick={handleShowModal}>Share Social Post</button>
        <Coordinator
            id="post-share"
            showModal={showModal}
            onModalClosed={handleHideModal}
        />
    </>;
};
