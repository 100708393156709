import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/**
 * 
 * @param {{editClick:Function,deleteClick:Function,product:object, toggleShareModal:Function}} props
 */
const MyProductsItemDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className={`${props.forMobile ? "mt-2" : ""}`}>
            <DropdownToggle caret style={props.style ?? {}}>
                Action
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem className="p-1" onClick={() => props.toggleShareModal(props.product, props.isUserMerchant ? props.isUserMerchant : false)}>
                    Share
                </DropdownItem>

                <DropdownItem onClick={props.editClick} className="p-1">
                    Edit
                </DropdownItem>

                <DropdownItem onClick={props.hideClick} className="p-1">
                    Delete
                </DropdownItem>
            </DropdownMenu>

        </Dropdown>

    )
}


export default MyProductsItemDropdown;