import React from "react";
import { withRouter } from 'react-router-dom';
import ActionButton from "components/ActionButton";

/**
 * 
 * @param {{userInformation:object}} props
 */
class CreatorDashboardContactUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
    }

    handleContactClick = () => {
        const emailAddress = 'Creatorsupport@rockporch.com';
        const mailtoLink = `mailto:${emailAddress}`;
        window.open(mailtoLink, '_blank');
    };
    
    render() {
        return (    
            <div className={`mb-5 mx-${["sm", "md"].includes(this.props.deviceSize) ? "1" : "3"}`} style={{ marginLeft: this.props.deviceSize === "sm" ? "-13px" : "", marginRight: this.props.deviceSize === "sm" ? "-13px" : "", }} >
                <div>
                    <h3 className="mb-3" style={{ fontWeight: "bold", fontSize: "", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", lineHeight: "30px" }}>
                        Have Questions, Comments, or Ideas?
                    </h3>
                    <div className={`mb-5 d-flex ${["sm"].includes(this.props.deviceSize) ? "flex-column" : "justify-content-between"}`}>
                        <div>
                            <p style={{ lineHeight: "24px", fontSize: "16px", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", maxWidth: this.props.deviceSize === "sm" ? "" : "75%" }}>
                                We’re here to help!
                            </p>
                            <p style={{ lineHeight: "24px", fontSize: "16px", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", maxWidth: this.props.deviceSize === "sm" ? "" : "75%" }}>
                                The RockPorch Creator Development Team is here to help guide and support you every step of the way!  Whether that's questions on how to get started or keep the momentum going, or you have great ideas on features that would make your life easier, <span style={{ fontStyle: "italic" }}>just click the <span style={{ fontWeight: "bold" }}>Contact Us </span>Button</span> and we’ll get right back to you!
                            </p>
                            {
                                this.props.creatorApp ?
                                    <></>
                                    :
                                    <>
                                        <p style={{ lineHeight: "24px", fontSize: "16px", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", maxWidth: this.props.deviceSize === "sm" ? "" : "75%" }}>
                                            In the meantime, here are a couple of resources that may help:
                                        </p>
                                        <p style={{ lineHeight: "24px", fontSize: "16px", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", maxWidth: this.props.deviceSize === "sm" ? "" : "75%" }}>
                                            <span role="img" aria-label="light bulb emoji">🔎</span>  Dive into our Creator Frequently Asked Questions to get quick answers to any issues you might be facing <a href="https://www.rockporch.com/creator-faqs/" rel="noreferrer" target="_blank" style={{ fontSize: "16px" }}>here</a>!.
                                        </p>
                                        <p style={{ lineHeight: "24px", fontSize: "16px", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", maxWidth: this.props.deviceSize === "sm" ? "" : "75%" }}>
                                            <span role="img" aria-label="light bulb emoji">💡</span> Learn strategy and get platform tutorials on our Creator Resources page <a href="https://www.rockporch.com/CREATOR-RESOURCES" rel="noreferrer" target="_blank" style={{ fontSize: "16px" }}>here</a>.
                                        </p>
                                        <p style={{ lineHeight: "24px", fontSize: "16px", fontFamily: "Archivo, sf_pro_displayregular, sans-serif", maxWidth: this.props.deviceSize === "sm" ? "" : "75%" }}>
                                            <span role="img" aria-label="strong arm flex emoji">💪</span> We have a private Facebook group just for RockPorch creators. You’ll find tips, tricks, and an awesome community of badass RockPorch creators like you. Join the group <a href="https://www.facebook.com/groups/rockporchmasters" rel="noreferrer" target="_blank" style={{ fontSize: "16px" }}>here</a>.
                                        </p>
                                    </>
                            }
                            
                        </div>
                        
                        <div style={{ position: this.props.deviceSize === "sm" ? "" : "relative" }}>
                            <ActionButton onClick={this.handleContactClick} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-outline-brand">Contact Us</ActionButton>
                        </div>
                    </div>
                </div>

            </div>
        );
    };
}


export default withRouter(CreatorDashboardContactUs);
