import React from "react";
import { getRequest } from "sharedUtils/httpUtils";
import Modal from "components/Modal";
import ActionButton from "components/ActionButton";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class BrandSelectorForm extends React.Component {
    state = {
        options: [],
        selected: [],
        loading: false
    }

    setSelected = (selected) => {
        this.setState({ selected });
    }

    cleanUp = () => {
        this.setState({ selected: [], loading: false });
        this.props.toggleModal();
    }

    submit = () => {
        this.setState({ loading: true });
        this.props.setMerchantUser(this.state.selected[0]);
        this.cleanUp();
    }

    componentDidMount = async () => {
        let partnersUrl = '/api/Merchant/GetCommissionEstimate?getMaxByMerchant=true';
        let partners = await getRequest(partnersUrl);

        let options = partners.map(partner => ({ ...partner, label: partner.merchantName }))
        this.setState({ options });
    }

    render() {
        return (
            <Modal isOpen={this.props.showModal} toggleModal={this.cleanUp}>
                <div className="row mt-0 mx-0">
                    <div className="col-md-12">
                        <h5 className="">Assign Merchant Partner</h5>
                        <div className="form">
                            <Typeahead
                                id="merchantSearch"
                                onChange={selected => this.setSelected(selected)}
                                options={this.state.options}
                                selected={this.state.selected}
                                placeholder="Search for Merchant Partner..." />

                            <div className="form-group button-block text-md-right mb-0">
                                <button className="btn btn-secondary mt-3" onClick={this.cleanUp}>
                                    Cancel
                                </button>

                                <ActionButton
                                    className="btn btn-primary ml-3 mt-3"
                                    onClick={() => { this.submit(); }}
                                    isDisabled={this.state.selected.length === 0}
                                    isLoading={this.state.submitting}
                                >
                                    Assign
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default BrandSelectorForm;