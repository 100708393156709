import React, { createRef } from "react";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';


class BrandNavLowerMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hover: "",
            hoverDropdown: false,
            prevHover: "",
            categories: (!!this.props.categories) ? this.props.categories.filter(cat => cat.level === 1) : [],
            moreMenuItems: (!!this.props.categories) ? this.props.categories.filter(cat => cat.menuVisibleUnderMore === true) : [],
            subcategories: (!!this.props.categories) ? this.props.categories.filter(cat => cat.level === 2) : [],
        };
    }

    brandNavLowerMenuRef = createRef();
    dropdownRef = createRef();

    async componentDidMount() {
        document.addEventListener("touchstart", this.handleTouchStartListener, { passive: false });
        this.setState({ categories: this.props.categories?.filter(cat => cat.level === 1), moreMenuItems: this.props.categories?.filter(cat => cat.menuVisibleUnderMore === true), subcategories: this.props.categories?.filter(cat => cat.level === 2) });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.categories !== this.props.categories) {
            this.setState({ categories: this.props.categories.filter(cat => cat.level === 1), moreMenuItems: this.props.categories.filter(cat => cat.menuVisibleUnderMore === true), subcategories: this.props.categories.filter(cat => cat.level === 2) });
        }
        if (this.props.navHover && this.state.prevHover !== "" && this.state.hoverDropdown && !this.props.hover) {
            this.setState({ prevHover: "", hoverDropdown: false });
        }

    }

    handleTouchStartListener = (e) => {
        if (!e.target.closest(`.dropdown`) && this.brandNavLowerMenuRef.current && this.state.hover !== e.target.id.slice("mouse-".length) && this.state.hoverDropdown && !this.brandNavLowerMenuRef.current.contains(e.target)) {
            e.stopImmediatePropagation();
            e.preventDefault();
            this.setState({ hoverDropdown: false, hover: "", prevHover: "" });
        }
    }

    handleMouseEnter = (e) => {
        if (this.state.hover !== "mouse-" + e.target.id && !e.target.className.includes("mouse-")) {
            let hover = e.target.id?.length > "mouse-".length ? e.target.id?.slice("mouse-".length) : e.target.id;
            this.setState({ hover, prevHover: hover, hoverDropdown: true });
        } else if (
            e.target.className.includes("mouse-") &&
            (!e.target.className.includes("mouse-" + this.state.hover) || this.state.hover === "")
        ) {
            const substring = e.target.className.substring(e.target.className.indexOf("mouse-") + "mouse-".length);
            const hover = substring.length > 0 ? substring : e.target.id;
            this.setState({ hover, prevHover: hover, hoverDropdown: true });
        }
    }

    handleMouseLeave = (e) => {
        if (this.state.hover !== "") {
            this.setState({ hover: "" });
        }
    }

    handleMouseEnterDropdown = (e) => {
        if (this.state.hoverDropdown !== true) {
            this.setState({ hoverDropdown: true });
        }
    }

    handleMouseLeaveDropdown = (e) => {
        if (this.state.hoverDropdown !== false && this.state.hover === "") {
            this.setState({ hoverDropdown: false });
        }
    }

    handleCloseDropdown = () => {
        if (this.state.hoverDropdown) {
            this.setState({ hoverDropdown: false });
        }
    }

    handleTouchStart = (e) => {
        if (this.state.hover !== "" && e.target.id?.includes(this.state.hover)) {
            this.setState({ hover: this.state.hoverDropdown ? "" : this.state.hover, hoverDropdown: !this.state.hoverDropdown, prevHover: "" });
        } else {
            this.setState({ hover: e.target.id.slice("mouse-".length), hoverDropdown: !this.state.hoverDropdown })
        }
    }

    menuCategories = ["Camp", "Climb", "Cycle", "Run", "Snow", "Water", "Fish/Hunt", "Travel", "Men", "Women", "Kids", "More"];

    mapSubcategories = (id) => {
        const category = this.state.categories.filter(cat => cat.id === id)[0].name;
        const subcategories = this.state.subcategories.filter(cat => cat.parentCategoryId === id);
        return subcategories.map((subC, i) => {
            const subName = !!(subC.menuName) && subC.menuName.length > 0 ? subC.menuName : subC.name;
            return (
                <Link className="dropdown-item brand-nav-a" onClick={this.handleCloseDropdown} key={i} to={`/Browse?category=${encodeURIComponent(category)}&subcategory=${encodeURIComponent(subC.name)}&i=${Math.floor(Date.now() / 1000)}`}>{subName}</Link>
                //    <a className="dropdown-item brand-nav-a" href={`/Browse?category=cycling&subcategory=bikes`}>{subC.name}</a>
            );
        });
    };

    mapMoreMenuItems = () => {
        return this.state.moreMenuItems.map( (item, i) => {
            const categoryObj = item.level === 1 ? null : this.props.categories.filter(cat => cat.id === item.parentCategoryId)[0];
            const category = !!(categoryObj) ? categoryObj.name : item.name;
            const subCategory = item.level === 1 ? null : item.name;
            return (
                <Link className="brand-nav-a" style={{ display: "block" }} onClick={this.handleCloseDropdown} key={i} to={`/Browse?category=${category}${!!(subCategory) ? "&subcategory=" + subCategory : ""}&i=${Math.floor(Date.now() / 1000)}`}>{!!(item.menuName) ? item.menuName : item.name}</Link>
                
            );
        });
    }

    mapMenuItems = () => {
        const categories = !!(this.state.categories) && this.state.categories.length > 0 ? [...this.state.categories.filter(cat => cat.level === 1 && cat.menuVisible === true), { more: "More" }] : [];
        
        return categories.map((cat, i) => { 
            if (i === categories.length - 1) {
                const more = cat.more;
                const isHovering = !this.props.navHover && more === this.state.hover || (!this.props.navHover && more === this.state.prevHover && this.state.hoverDropdown);

                return (
                    <div key={i}>
                        <div className={`dropdown top-nav-right-list-items-bottom top-nav-right-list-items-bottom-dropdown ${!this.props.navHover && more === this.state.hover ? "show" : ""}`} style={{ height: "20px" }}>
                            <a className={`dropdown d-flex mt-1 position-relative dropdown top-nav-right-list-items-bottom-dropdown ${isHovering ? "brand-nav-lower-menu-hover" : "text-secondary"}`} style={{ zIndex: 999 }} onTouchStart={this.handleTouchStart} id={`mouse-${more}`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} role="button" data-toggle="" aria-expanded="false">
                                More
                                <span className={`brand-nav-dropdown-toggle position-absolute ${!this.props.navHover && more === this.state.hover || (!this.props.navHover && more === this.state.prevHover && this.state.hoverDropdown) ? "rotate-brand-nav-chevron" : ""} mouse-${more}`} style={{ right: "-10px", top: "-1px" }}>
                                    <span className="brand-nav-chevron-iconset brand-nav-chevron-span">
                                        <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <title>Expand</title>
                                            <path className={`${isHovering ? "brand-nav-lower-menu-hover" : ""}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                        </svg>
                                    </span>
                                </span>
                            </a>
                            
                            <div ref={this.dropdownRef} className={`dropdown-menu dropdown-menu-brand-nav-bottom w-100 mt-0 ${!this.props.navHover && more === this.state.hover || (!this.props.navHover && more === this.state.prevHover && this.state.hoverDropdown) ? "show" : ""}`} onMouseEnter={this.handleMouseEnterDropdown} onMouseLeave={this.handleMouseLeaveDropdown} style={{ marginLeft: "4px", transform: !!(this.props.loggedInUser) && this.props.loggedInUser?.proStatusId === 0 && this.props.screenWidth < 754 ? "translate3d(-4px,  42px, 0px)" : !!(this.props.loggedInUser) && this.props.loggedInUser?.proStatusId === 0  && this.props.screenWidth > 990? "translate3d(-4px,  22px, 0px)" : !!(this.props.loggedInUser) && this.props.loggedInUser?.proStatusId === 0 ? "translate3d(-4px,  22px, 0px)" : this.props.screenWidth < 756 ? "translate3d(-4px, 46px, 0px)" : this.props.screenWidth < 850 ? "translate3d(-4px, 22px, 0px)" : "translate3d(-4px,22px, 0px)", zIndex: -1 }}>
                                <div style={{ borderTop: "1px solid lightgrey", marginTop: "-10px", marginBottom: "20px" }}></div>

                                <h6>Categories</h6>
                                <div style={{ borderTop: "1px solid black", marginTop: "", marginBottom: "12px" }}></div>
                                <div className="brand-nav-a" style={{ columns: "3 auto" }}>
                                    {this.mapMoreMenuItems()}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                );
            }
            const categoryName = !!(cat.menuName) && cat.menuName.length > 0 ? cat.menuName : cat.name;
            const category = cat.name;
            const isHovering = !this.props.navHover && category === this.state.hover || (!this.props.navHover && category === this.state.prevHover && this.state.hoverDropdown);
            return (
                <div key={i}>
                    <div ref={this.brandNavLowerMenuRef} className={`dropdown top-nav-right-list-items-bottom top-nav-right-list-items-bottom-dropdown ${!this.props.navHover && category === this.state.hover ? "show" : ""}`} style={{ height: "20px" }}>
                        <a className={`dropdown d-flex mt-1 position-relative top-nav-right-list-items-bottom-dropdown ${isHovering ? "brand-nav-lower-menu-hover" : "text-secondary"}`} style={{ zIndex: 999 }} onTouchStart={this.handleTouchStart} id={`mouse-${category}`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} role="button" data-toggle="" aria-expanded="false">
                            {categoryName}
                            <span className={`brand-nav-dropdown-toggle position-absolute ${!this.props.navHover && category === this.state.hover || (!this.props.navHover && category === this.state.prevHover && this.state.hoverDropdown) ? "rotate-brand-nav-chevron" : ""} mouse-${category}`} style={{ right: "-10px", top: "-1px" }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span">
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <title>Expand</title>
                                        <path className={`${isHovering ? "brand-nav-lower-menu-hover" : ""}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                        </a>

                        <div ref={this.dropdownRef} className={`dropdown-menu dropdown-menu-brand-nav-bottom w-100 mt-0 ${!this.props.navHover && category === this.state.hover || (!this.props.navHover && category === this.state.prevHover && this.state.hoverDropdown) ? "show" : ""}`} onMouseEnter={this.handleMouseEnterDropdown} onMouseLeave={this.handleMouseLeaveDropdown} style={{ marginLeft: "4px", transform: !!(this.props.loggedInUser) && this.props.loggedInUser?.proStatusId === 0 && this.props.screenWidth < 754 ? "translate3d(-4px,  100px, 0px)" : !!(this.props.loggedInUser) && this.props.loggedInUser?.proStatusId === 0 && this.props.screenWidth > 991 ? "translate3d(-4px,  22px, 0px)" : !!(this.props.loggedInUser) && this.props.loggedInUser?.proStatusId === 0 ? "translate3d(-4px, 22px, 0px)" : this.props.screenWidth < 756 ? "translate3d(-4px, 46px, 0px)" : this.props.screenWidth < 850 ? "translate3d(-4px,22px, 0px)" : "translate3d(-4px,22px, 0px)", zIndex: -1 }}>
                            <div style={{ borderTop: "1px solid lightgrey", marginTop: "-10px", marginBottom: "20px" }}></div>
                            <div className={`${this.props.deviceSize === "sm" ? "shrink-a-font-size-mobile-div" : ""}`} style={{ display: this.props.deviceSize === "sm" ? "" : "flex", justifyContent: "space-around", gap: ["xl", "l"].includes(this.props.deviceSize) ? "50px" : "25px" }}>
                                {this.props.deviceSize === "sm" ?
                                    <div className="brand-nav-dropdown-post-featured mb-4">
                                        <div style={{ width: "50%" }}>
                                            <h6>Related Post</h6>
                                            <div style={{ borderTop: "1px solid black", marginTop: "", marginBottom: "20px" }}></div>
                                            <div style={{ width: this.props.screenWidth > 576 ? "220px" : "150px", height: this.props.showBlogPostsFlag ? "150px" : "130px", paddingBottom: this.props.screenWidth > 576 ? 0 : "75%", position: "relative", borderRadius: "12px", overflow: "hidden" }}>
                                                <Link to={`/${cat.typeId === 2 ? "l" : "p"}/${cat.shareCode}?showBrandNav=true`} onClick={this.handleCloseDropdown}>
                                                    <img src={cat.imageUrl} style={{ position: "absolute", top: 0, left: 0, backgroundColor: "#fff", width: "100%", height: "100%", objectFit: "cover" }} alt={`Related Post Image for ${this.state.categories[0].category}`}></img>
                                                </Link>
                                            </div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <h6>Featured</h6>
                                            <div style={{ borderTop: "1px solid black", marginTop: "", marginBottom: "12px" }}></div>

                                            <Link className="dropdown-item brand-nav-a" to={`/Browse?sortby=recommended&category=${encodeURIComponent(category)}&i=${Math.floor(Date.now() / 1000)}`} onClick={this.handleCloseDropdown}>Most Endorsed Products</Link>
                                            <Link className="dropdown-item brand-nav-a" to={`/SearchResults/${encodeURIComponent(category)}?tab=locker&showBrandNav=true`} onClick={this.handleCloseDropdown}>Lockers (Gear Lists)</Link>
                                            <Link className="dropdown-item brand-nav-a" to={`/SearchResults/${encodeURIComponent(category)}?tab=posts&showBrandNav=true`} onClick={this.handleCloseDropdown}>Related Social Posts & Videos</Link>
                                            <Link className="dropdown-item brand-nav-a" to={`/SearchResults/${encodeURIComponent(category)}?tab=people&showBrandNav=true`} onClick={this.handleCloseDropdown}>Relevant Creators</Link>
                                            {
                                                this.props.showBlogPostsFlag ?

                                                <Link className="dropdown-item brand-nav-a" to={`#`} onClick={this.handleCloseDropdown}>Blog Posts & Expert Advice</Link>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                        :
                                    <>
                                        <div>
                                            <h6>Related Post</h6>
                                            <div style={{ borderTop: "1px solid black", marginTop: "", marginBottom: "20px" }}></div>
                                            <div style={{ width: "160px", height: "170px", paddingBottom: "75%", position: "relative", borderRadius: "12px", overflow: "hidden" }}>
                                                <Link to={`/${cat.typeId === 2 ? "l" : "p"}/${cat.shareCode}?showBrandNav=true`} onClick={this.handleCloseDropdown}>
                                                    <img src={cat.imageUrl} style={{ position: "absolute", top: 0, left: 0, backgroundColor: "#fff", width: "100%", height: "100%", objectFit: "cover" }} alt={`Related Post Image for ${this.state.categories[0].category}`}></img>
                                                </Link>
                                            </div>
                                        </div>
                                        <div>
                                            <h6>Featured</h6>
                                            <div style={{ borderTop: "1px solid black", marginTop: "", marginBottom: "12px" }}></div>

                                            <Link className="dropdown-item brand-nav-a" to={`/Browse?sortby=recommended&category=${encodeURIComponent(category)}&i=${Math.floor(Date.now() / 1000)}`} onClick={this.handleCloseDropdown}>Most Endorsed Products</Link>
                                            <Link className="dropdown-item brand-nav-a" to={`/SearchResults/${encodeURIComponent(category)}?tab=locker&showBrandNav=true`} onClick={this.handleCloseDropdown}>Lockers (Gear Lists)</Link>
                                            <Link className="dropdown-item brand-nav-a" to={`/SearchResults/${encodeURIComponent(category)}?tab=posts&showBrandNav=true`} onClick={this.handleCloseDropdown}>Related Social Posts & Videos</Link>
                                            <Link className="dropdown-item brand-nav-a" to={`/SearchResults/${encodeURIComponent(category)}?tab=people&showBrandNav=true`} onClick={this.handleCloseDropdown}>Relevant Creators</Link>
                                            {
                                                this.props.showBlogPostsFlag ?

                                                    <Link className="dropdown-item brand-nav-a" to={`#`} onClick={this.handleCloseDropdown}>Blog Posts & Expert Advice</Link>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </>
                                }

                                <div style={{ flexGrow: 1 }}>
                                    <h6>Categories</h6>
                                    <div style={{ borderTop: "1px solid black", marginTop: "", marginBottom: "12px" }}></div>
                                    <div style={{ columns: "2 auto" }}>
                                        {this.mapSubcategories(cat.id)}
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            );
        });
    }


    render() {


        return (
            <div className={`d-flex justify-content-around flex-wrap mx-auto my-${!!(this.props.loggedInUser) && ["sm", "md"].includes(this.props.deviceSize) ? "1" : "0"} ${!["sm", "md"].includes(this.props.deviceSize) ? "mb-1" : "0"}`} style={{ width: "93%", paddingTop: "3px", gap: this.props.screenWidth > 385 && this.props.screenWidth < 991 ? "0 20px" : this.props.screenWidth <= 385 ?"0 10px" : ""  }}>
                {this.mapMenuItems()}
            </div>
        )
    }
}

export default BrandNavLowerMenu;