import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import { connect } from "react-redux";
import ProductPage from "views/Product/ProductPage";
import { toggleProductPageModal,toggleLockerPageModal,togglePostPageModal } from "stateManagement/reducers/modalReducer";


/**
 * 
 * @param {{loggedInUser:object, deviceSize:any,parentModal:{type:string,parentState:object}} props
 */
const ProductPageModal = (props) => {
    let paramProps = {
        params: {
            product: props.product,
            loggedInUser: props.loggedInUser,
            setProduct: props.setProduct
        }
    }

    useEffect(() => {
        if (props.showModal) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [props.showModal]);


    let [modalDialogExpanded, setDialogExpanded] = useState(false);
    let productModalClass = modalDialogExpanded === true ? "" : "product-modal-dialog";
    return (
        <Modal
            showHeader={true}
            hideOpenAsPage={true}
            isOpen={props.showModal}
            modalDialogCustomClass={`modal-dialog-md ${productModalClass}`}
            modalHeaderRedirect={`/Product/ProductPage/${props.product?.shareCode}?r=0`}
            modalBodyCustomClass={`modal-body pt-0 px-0 mt-0 pb-1 ${modalDialogExpanded ? "pb-4" : "pb - 1"}`}
            toggleModal={() => {
                setDialogExpanded(false);
                if (props.parentModal != null) {
                    switch (props.parentModal?.type) {
                        case "locker": {
                            props.toggleLockerModal(props.parentModal.parentState, props.loggedInUser, false, null);
                            break;
                        }
                        case "post": {
                            props.togglePostModal(props.parentModal.parentState, props.loggedInUser,false,null);
                            break;
                        }
                        default: {
                            props.toggleModal(props.product, props.loggedInUser, false, props.setProduct, null);
                            break;
                        }
                    }
                }
                else {
                    props.toggleModal(props.product, props.loggedInUser, false, props.setProduct, null);
                }
                
            }}
            state={paramProps}
        >
            <ProductPage
                match={paramProps}
                isModal={true}
                deviceSize={props.deviceSize}
                loggedInUser={props.loggedInUser}
                isCommenting={props.isCommenting || false}
                isBrowse={props.isBrowse || false}
                isSearch={props.isSearch || false}
                expandDialog={() => {
                    setDialogExpanded(!modalDialogExpanded);
                }}
                toggleSignUpModal={props.toggleSignUpModal}
                toggleShareModal={props.toggleShareModal}
                screenWidth={props.screenWidth}
                brandNav={props.brandNav}
            />
        </Modal>
        )
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.productPageModal.showProductPageModal,
        shareCode: storeState.modalReducer.productPageModal.shareCode,
        product: storeState.modalReducer.productPageModal.product,
        loggedInUser: storeState.modalReducer.productPageModal.loggedInUser,
        isCommenting: storeState.modalReducer.productPageModal.isCommenting,
        setProduct: storeState.modalReducer.productPageModal.setProduct,
        parentModal: storeState.modalReducer.productPageModal.parentModal,
        isBrowse: storeState.modalReducer.productPageModal.isBrowse,
        isSearch: storeState.modalReducer.productPageModal.isSearch,
    }
}

const mapDispatch = {
    toggleModal: toggleProductPageModal,
    toggleLockerModal: toggleLockerPageModal,
    togglePostModal: togglePostPageModal
}

export default connect(mapStateToProps, mapDispatch)(ProductPageModal);