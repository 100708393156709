import React from "react";
import { Redirect } from "react-router-dom";

/**
 * 
 * @param {{loggedInUser:object}} props
 */
const AuthorizedLanding = (props) => {
    if (props.loggedInUser?.proStatusId === 1) {
        return <Redirect to={`/CreatorDashboard`} />
    } else {
        return <Redirect to="/Explore"/>
    }
}

export default AuthorizedLanding;