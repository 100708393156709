import React from "react";

/**
 * 
 * @param {{fill:string,width:string, height:string, fillOpacity:string}} props
 */
const Tick = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "12"} height={props.height || "12"} viewBox="0 0 12 12" fill={props.fill || "none"}>
            <path d="M10.5904 1.05832C10.8766 0.690119 11.3815 0.645345 11.7181 0.958319C12.0547 1.27129 12.0957 1.8235 11.8095 2.1917L5.00955 10.9417C4.71891 11.3157 4.20392 11.3551 3.86851 11.029L0.268517 7.52898C-0.0617085 7.20793 -0.0914536 6.65487 0.202081 6.29368C0.495614 5.9325 1.00127 5.89997 1.3315 6.22102L4.32017 9.12667L10.5904 1.05832Z" fill={props.fill || "none"} fillOpacity={props.fillOpacity || "0.8"} />
        </svg>
        )
}

export default Tick;