import authService from "components/api-authorization/AuthorizeService";

var jsonref = require('jsonref');


/**
 * @description Accepts a url and makes a get request, adding the authorization bearer token and parsing the response json
 * @param {string} url
 * @param {boolean} shouldReturnError
 */
export async function getRequest(url, shouldReturnError = false, access_token = null) {
    const token = access_token || await authService.getAccessToken();

    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    const fetchInit = {
        headers: headers,
    };

    let fetchExcel = false;

    const response = await fetch(url, fetchInit).then(async (result) => {
        if (result.status === 200) {
            const contentType = result.headers.get('content-type');
            if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                fetchExcel = true;
                // Handle file download
                const blob = await result.blob();
                const dispositionHeader = result.headers.get('content-disposition');
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(dispositionHeader);
                const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'ProductsWithoutCategories.xlsx';
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
                window.URL.revokeObjectURL(url);
                return null;
            } else {
                const json = await result.json();
                if (typeof json !== 'object') {
                    return json;
                }
                const parsed = await jsonref.parse(json, { scope: window.location });
                return parsed;
            }
        } else {
            const error = await result.text();
            if (shouldReturnError && error && error.length > 0) {
                if (error.trim().startsWith('System')) {
                    return { errorMessage: 'There was an issue looking up the product' };
                }
                return { errorMessage: error.substring(0, 150) };
            }
        }
        return null;
    }).catch(async (err) => { });

    return fetchExcel ? true : response;
}



/**
 * @description Makes a post request accepting the url and payload as arguments
 * @param {string} url
 * @param {object} payload
 */
export async function postRequest(url, payload, contentType = "application/json", stringify = true, token = null) {
    token = token == null ? await authService.getAccessToken() : token;

    let authHeader = !token ? {} : {
        Authorization: `Bearer ${token}`
    }

    if (contentType !== null) {
        authHeader["Content-Type"] = contentType;
    }

    let fetchInit = {
        headers: authHeader,
        method: "POST",
    }

    if (contentType !== null) {
        fetchInit.contentType = contentType;
    }

    if (payload !== null ) {
        fetchInit.body = stringify ? JSON.stringify(payload) : payload;
    }

    let response = await fetch(url, fetchInit).then(async result => {

        // Handle redirects which occur when trying to POST to an API without being signed in
        if (result.redirected) {
            if (result.url.toLowerCase().includes('?returnurl=')) {
                var urlWithoutQueryString = result.url.split('?')[0];
                var newRedirectUrl = `${urlWithoutQueryString}?returnUrl=${window.location.pathname}`;
                window.location.href = newRedirectUrl;
            } else {
                window.location.href = result.url;
            }
        }

        let json = await result.json();       

        if (typeof (json) !== "object") {
            return json;
        }

        let parsed = await jsonref.parse(json, { scope: window.location });
        return parsed;
        
    }).catch(err => {
        console.log(err);
    });
    
    return response;
}

export async function postSpreadsheet(url, formData) {
    let token = await authService.getAccessToken();

    let authHeader = !token ? {} : {
        Authorization: `Bearer ${token}`
    };

    let fetchInit = {
        headers: authHeader,
        method: "POST",
        body: formData
    };

    try {
        const response = await fetch(url, fetchInit);

        if (response.ok) {
            const json = await response.json();
            return json;
        } else {
            const errorMessage = await response.text();
            throw new Error(errorMessage);
        }
    } catch (error) {
        return null;
    }
}


export function getQueryVariable(query, variable) {
    let vars = (query[0] === '?' ? query.substr(1) : query).split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}
