import React from "react";

const CameraIcon = () => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.83 2H18C19.1 2 20 2.9 20 4V16C20 17.1 19.1 18 18 18H2C0.9 18 0 17.1 0 16V4C0 2.9 0.9 2 2 2H5.17L7 0H13L14.83 2ZM2 16H18V4H13.95L12.12 2H7.88L6.05 4H2V16ZM10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C12.76 15 15 12.76 15 10C15 7.24 12.76 5 10 5ZM7 10C7 11.65 8.35 13 10 13C11.65 13 13 11.65 13 10C13 8.35 11.65 7 10 7C8.35 7 7 8.35 7 10Z" fill="black" fillOpacity="0.6" />
        </svg>
    )
}

export default CameraIcon;