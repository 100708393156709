import React from "react";
import ImageWrapper from "components/ImageWrapper";
import Tick from "components/icons/Tick";
import SaleIcon from "components/icons/SaleIcon";

import InformationIcon from "components/icons/InformationIcon";
import { currencyFormat } from "sharedUtils/productUtils";

import { UncontrolledPopover, PopoverBody } from "reactstrap";

/**
 * 
 * @param {{
 * product:{brand:string,name:string,vendor:string,price:Number,commission:Number,imageUrl:string},
 * select:Function, 
 * arrayKey:Number,
 * isSelected:boolean,
 * isExisting:boolean
 * }} props
 */ 
const ProductSelectCard = (props) => {
    function displayCheckBox() {
        if (props.isExisting === false) {
            return (
                <div
                    style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                    border: "1px solid grey",
                    backgroundColor: props.isSelected === true ? "green" : "transparent",
                    paddingLeft: "3px",
                    position: "absolute",
                    marginTop: "5px",
                    marginLeft: "5px",
                    zIndex: "2"
                }}
                >
                    {
                        props.isSelected === true ? (

                            <Tick />

                        ) : null
                    }
                </div>

            )
        }
        
    }

    const renderSaleOverlay = (sale) => {
        if (sale) {
            return (
                <span className="on-sale-icon">
                    <SaleIcon />
                </span >
            );
        }
    }

    return (
        <a href="#"
            onClick={(e) => {
                e.preventDefault();
                if (props.isExisting === false) {
                    props.select(props.product);
                }
            }}
            className="custom-cards d-block w-100"
        >
            <div className="post-block" style={{ border: props.isSelected ? "2px solid green" : "" }}>
                {displayCheckBox()}
                <figure className="mb-2">    
                    <ImageWrapper
                        src={props.product.imageUrl}
                        size={"ProductGrid"}
                        className=""
                        alt="..."
                        />
                </figure>

                <div className="product-detail pt-0 px-0">
                    <p className="product-name mb-0 pt-0"><strong>{props.product.name}</strong></p>
                    <p className="card-text mb-0 pt-0">
                        <small>Estimated Commission<sup>*</sup></small>

                        {/*
                        <small className="d-inline-block ml-1">
                            <button style={{ outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id="infoIcon"><InformationIcon x="12" y="12"/></button>
                        </small>

                        <UncontrolledPopover
                            placement="bottom"
                            target="infoIcon"
                            trigger="focus"
                        >
                            <PopoverBody>Commissions are estimated based on factors including merchant rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase.</PopoverBody>
                        </UncontrolledPopover>
                        */}
                        <br />
                    {currencyFormat(props.product.estimatedCommission) || 0}
                    </p>
                    <p className="card-text mb-0 pt-0">
                        <small>Price</small>
                        {
                            props.product.sale && !!(props.product.salePrice) ?
                                <span><SaleIcon style={{ marginLeft: "3px" }} /></span>
                                : <></>
                        }
                        <br />
                        {/*{currencyFormat(props.product.price) || 0}*/}
                        <div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{props.product.sale && !!(props.product.salePrice) ? "$" + props.product.salePrice.toFixed(2) : <></>}</div><div style={{ fontSize: props.product.sale && !!(props.product.salePrice) ? "10px" : "", marginLeft: props.product.sale && !!(props.product.salePrice) ? "" : "-4px" }} className={`${props.product.sale && !!(props.product.salePrice) ? "on-sale-price" : ""}`}>${props.product.sale && !!(props.product.salePrice) && !!(props.product.originalPrice) ? props.product.originalPrice.toFixed(2) : props.product.price.toFixed(2)}</div></div>
                    </p>
                </div>
            </div>
        </a>
    )
}

export default ProductSelectCard;