import React from "react";

/**
 *
 * @param {{customClass:string} props
 */
const NotificationDotIcon = (props) => {
    return (
        <span className={`notification-dot ${props.customClass ? props.customClass : ""}`}></span>
    )
}

export default NotificationDotIcon;