import React from "react";
import AccountBanner from "../../components/AccountBanner";
import { Form, FormGroup, Label, Input } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { isValidUrl } from "sharedUtils/validationUtils";
import { getRequest, postRequest } from 'sharedUtils/httpUtils';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LoadSpinner from "../../components/LoadSpinner";
import Modal from "components/Modal";

class FeaturedProducts extends React.Component {
    state = {
        id: 0,
        addShareCode: "",
        showError: false,
        isSubmitting: false,
        isDeleting: false,
        isFetching: false,
        show: false,
        selectedCase: 1,
        addSubmitError: false,
        deleteError: false,
        featuredProductsFromDb: [],
        FeaturedProductsTable: {
            title: "Featured Products",
            columns: [
                {
                    name: "merchant",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true,
                        display: true // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "name",
                    label: "Name",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "350px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "imageUrl",
                    label: "Image URL",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const url = value;
                            return <img src={url} style={{ height: "100px", width: "100px", objectFit: "contain" }} />;
                        },
                    }
                },
                {
                    name: "manufacturer",
                    label: "Brand",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "350px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "shareCode",
                    label: "Sharecode",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "200px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "userMerchantProductId",
                    label: "UserMerchantProductId",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "featuredPublished",
                    label: "Published",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value) {
                                return String(value);
                            }
                            return "false";
                        },
                    }
                },
            ],
            options: {
                customToolbarSelect: () => { },
                selectableRows: "multiple",
                sortOrder: {
                    name: 'userId',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle MuiTypography-subtitle1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.confirmDelete(selectedRows, displayData); }}>Delete Selected Products</button>
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.confirmPublishSelected(selectedRows, displayData); }}>Publish Selected Products</button>
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        },
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                        root: {
                        }
                    },
                }
            })
        },
    };

    async componentDidMount() {
        this.setState({ isFetching: true });

        await this.fetchFeaturedProducts();

        this.setState({ isFetching: false });
    }

    fetchFeaturedProducts = async () => {
        const getUrl = "api/Featured/GetFeaturedProducts?admin=true";
        const tc = await getRequest(getUrl);
        this.setState({ featuredProductsFromDb: tc });
    }


    getModalTitle = () => {
        return "Add Featured Product Item";
    }

    handleAddProductButton = () => {
        this.setState({ show: true })
    }

    confirmPublishAll = () => {
        const confirmDelete = window.confirm("ALL ITEMS will be published...");
        if (confirmDelete) {
            this.handlePublish();
        }
    }

    confirmPublishSelected = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("SELECTED ITEMS will be published...");
        if (confirmDelete) {
            this.handlePublish(selectedRows, displayData);
        }
    }


    handlePublish = async (selectedRows = null, displayData = null) => {
        this.setState({ loading: true });
        let umps = [];
        if (!!(selectedRows) && !!(selectedRows.data) && selectedRows.data.length > 0) {
            const selectedRowsData = selectedRows.data;
            umps = selectedRowsData.map(row => displayData[row.index].data[5]);
        }

        const publishUrl = "api/Featured/PublishFeaturedProduct";

        const payload = {
            userMerchantProductIds:umps
        }

        const publish = await postRequest(publishUrl, payload);

        if (!!(publish)) {
            this.setState({ featuredProductsFromDb: publish, publishError: false });
        } else {
            this.setState({ publishError: true });
        }

        this.setState({ loading: false });
    };


    displayToggleButtons = () => {
        let options = [
            {
                name: "Featured Content",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }


    breadCrumbList = [
        { link: "/Admin", text: "Admin" },
        { link: "/Admin/Featured", text: "Featured" },
    ];

    handleShareCode = (e) => {
        this.setState({ addShareCode: e.target.value });
    }


    handleSubmit = async () => {
        this.setState({ isSubmitting: true });

        const addUrl = "api/Featured/AddFeaturedProduct";

        const payload = {
            shareCode: this.state.addShareCode
        }

        const featuredProductsFromDb = await postRequest(addUrl, payload);
        if (!!(featuredProductsFromDb)) {
            this.setState({ featuredProductsFromDb, show: false });
            this.resetState();
        } else {
            this.setState({ addSubmitError: true });
        }

        this.setState({ isSubmitting: false });
    };

    confirmDelete = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("Are you sure you want to DELETE these products? This will be permanent...");
        if (confirmDelete) {
            this.handleDelete(selectedRows, displayData);
        }
    }

    handleDelete = async (selectedRows, displayData) => {
        this.setState({ loading: true });

        const selectedRowsData = selectedRows.data;
        const umps = selectedRowsData.map(row => displayData[row.index].data[5]);
        const deleteUrl = "api/Featured/DeleteFeaturedProductsByUMPId";
        const payload = {
            userMerchantProductIds: umps
        }

        const featuredProductsFromDb = await postRequest(deleteUrl, payload);

        if (!!(featuredProductsFromDb)) {
            this.setState({ featuredProductsFromDb, deleteError: false });
        } else {
            this.setState({ deleteError: true });
        }

        this.setState({ loading: false });
    }

    resetState = () => {
        this.setState({ addShareCode: "", addSubmitError: false });
    }

    render() {
        const submitDisabled = this.state.addShareCode.length === 0;

        return (
            <>
                <Modal isOpen={this.state.show} modalHeaderClass={"current-deals-modal-header"}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'lightgray',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '350px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => { this.setState({ show: false }); this.resetState(true); }}
                    toggleModal={() => { this.setState({ show: false }); this.resetState(true); }}
                >

                    <section className="gray-bg" style={{ marginBottom: "-20px", marginTop: "-5px" }}>
                        <div className="container pt-0 pb-5" >
                            <div className="d-flex flex-column pt-4 mb-2">
                                <h4>{this.getModalTitle()}</h4>
                            </div>

                            <FormGroup className="form-group" >
                                <Label className="control-label">Product ShareCode:</Label>
                                <Input
                                    className="form-control"
                                    name="addShareCode"
                                    type="text"
                                    value={this.state.addShareCode}
                                    placeholder="Enter sharecode for Product here..."
                                    onChange={this.handleShareCode}
                                />
                            </FormGroup>
                            <p style={{ color: "red", display: this.state.addSubmitError ? "" : "none" }}>ERROR: check shareCode and try again</p>
                            <div className="d-flex">
                                <ActionButton className="btn btn-primary mt-3"
                                    onClick={this.handleSubmit}
                                    isLoading={this.state.isSubmitting}
                                    isDisabled={submitDisabled}
                                >
                                    Add
                                </ActionButton>

                            </div>
                        </div>

                    </section>
                </Modal>
                <section>
                    <div className="container-fluid">
                        {
                            this.state.isFetching ?
                                <LoadSpinner />
                                :
                                <div className="row">
                                    <div className="col">
                                        <div className="text-right mb-2">
                                            <ActionButton className="btn btn-primary mt-3 mr-2"
                                                onClick={this.confirmPublishAll}
                                            >
                                                Publish
                                            </ActionButton>
                                            <ActionButton className="btn btn-primary mt-3"
                                                onClick={this.handleAddProductButton}
                                            >
                                                Add Featured Product
                                            </ActionButton>
                                        </div>
                                        <p style={{ color: "red", display: this.state.deleteError ? "" : "none" }} className="text-right">ERROR DELETING CONTENT: try again</p>
                                        <p style={{ color: "red", display: this.state.publishError ? "" : "none" }} className="text-right">ERROR PUBLISHING CONTENT: try again</p>

                                        <ThemeProvider theme={this.state.FeaturedProductsTable.getTheme()}>
                                            <MUIDataTable
                                                title={this.state.FeaturedProductsTable.title}
                                                data={this.state.featuredProductsFromDb}
                                                columns={this.state.FeaturedProductsTable.columns}
                                                options={this.state.FeaturedProductsTable.options}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                        }
                    </div>
                </section>

            </>
        )
    }
}

export default FeaturedProducts;