import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import InformationIcon from "components/icons/InformationIcon";
import Select from 'react-select'
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import _ from "lodash";
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "./LoadSpinner";
import { Link } from "react-router-dom";

const { DateTime } = require("luxon");

class BrandAnalyticsChart extends Component {
    constructor(props) {
        super(props);

        this.selectOptions = {
            last24Hours: {
                value: 0,
                label: "Yesterday",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 1 })).toUTC(),
                        endDate: asDate(DateTime.now()).toUTC()
                    }
                }
            },
            last7Days: {
                value: 1,
                label: "Last 7 Days",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 7 })).toUTC(),
                        endDate: asDate(DateTime.now()).toUTC()
                    }
                }
            },
            last30Days: {
                value: 2,
                label: "Last 30 Days",
                getDateRange: () => {
                    return {
                        startDate: asDate(DateTime.now().minus({ days: 30 })).toUTC(),
                        endDate: asDate(DateTime.now()).toUTC()
                    }
                }
            },
            last90Days: {
                value: 3,
                label: "Last 90 Days",
                getDateRange: () => {
                    let startDate = asDate(DateTime.now().minus({ days: 90 })).toUTC();
                    let endDate = asDate(DateTime.now()).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            },
            yearToDate: {
                value: 4,
                label: "Year To Date",
                getDateRange: () => {
                    let startDate = asDate(DateTime.local(DateTime.now().year, 1, 1)).toUTC();
                    let endDate = asDate(DateTime.now()).toUTC();

                    return {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            }
        };

        this.state = {
            selectOptions: {
                options: [
                    this.selectOptions.last24Hours,
                    this.selectOptions.last7Days,
                    this.selectOptions.last30Days,
                    this.selectOptions.last90Days,
                    this.selectOptions.yearToDate
                ],
                defaultValue: this.selectOptions.last7Days,
                currentValue: this.selectOptions.last7Days,
            },
            series: [
                {
                    name: 'Merchant Redirects',
                    type: 'line',
                    data: []
                },
                {
                    name: 'Sales Count',
                    type: 'line',
                    data: []
                },
                {
                    name: 'Estimated Commissions',
                    type: 'bar',
                    data: []
                }
            ],
            options: {
                chart: {
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                markers: {
                    size: [1, 1, 1],
                },
                stroke: {
                    width: [2, 2, 2]
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [2]
                },
                labels: [],
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [
                    {
                        seriesName: 'Merchant Redirects',
                        title: {
                            text: 'Count'
                        },
                        labels: {
                            // Formats the number displayed on the y-axis label and in the tooltip
                            formatter: function (val) {
                                return val.toFixed(0);
                            }
                        }
                    },
                    {
                        seriesName: 'Merchant Redirects',
                        show: false,
                        labels: {
                            // Formats the number displayed on the y-axis label and in the tooltip
                            formatter: function (val) {
                                return val.toFixed(0);
                            }
                        }
                    },
                    {
                        seriesName: 'Commissions Earned',
                        opposite: true,
                        title: {
                            text: '$',
                        },
                    }
                ]
            },
        };
    }

    componentDidMount = async () => {
        this.handleSelect(this.state.selectOptions.defaultValue);
    }

    handleSelect = async (selectedOption) => {
        if (selectedOption) {
            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = selectedOption;
            this.setState({ selectOptions });
        }
        const dateRange = selectedOption?.getDateRange();
        this.props.getAnalytics(dateRange?.startDate, dateRange?.endDate);
    }

    displayTableRows = () => {
        if (this.props.analytics.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="7" style={{ textAlign: "center" }}>No data for this time frame</td>
                </tr>
            );
        }

        let aggregated = [];

        this.props.analytics.forEach((dataPoint, i) => {

            let dt2 = new Date(dataPoint.date).toLocaleDateString("en-us");
            let found = aggregated.findIndex(el => el.date === dataPoint.date);
            if (found === -1) {
                aggregated.push({ ...dataPoint });
            }
            else {
                aggregated[found].productClicks = aggregated[found].productClicks + dataPoint.productClicks;
                aggregated[found].productViews = aggregated[found].productViews + dataPoint.productViews;
                aggregated[found].postViews = aggregated[found].postViews + dataPoint.postViews;
                aggregated[found].salesCount = aggregated[found].salesCount + dataPoint.salesCount;
                aggregated[found].commissionsEarned = aggregated[found].commissionsEarned + dataPoint.commissionsEarned;
            }
        })

        return aggregated.map((data, i) => {
            if (data.productClicks > 0 || data.salesCount > 0 || data.commissionsEarned > 0) {
                return (
                    <tr key={i}>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{convertToNumericMonthAndDay(data.date, false)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.productClicks}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.salesCount}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.commissionsEarned.toFixed(2)}</span>
                        </td>
                    </tr>);
            }
        }).reverse();
    }

    displayTableHeader = () => {
        let headers = ["Date", "Merchant Redirects", "Sales Count", "Estimated Commission"];
        let infoIcon = ["", "A User has clicked a link that redirected them to a Merchant’s site", "A User placed an order with a Merchant within the allowable cookie life", "The estimated commission earned across all orders. See RockPorch ToS for more detail"]

        return headers.map((header, i) => {
            return (
                <th key={i} style={{ minWidth: "0", paddingLeft: "4px", paddingRight: "4px" }} >
                    <strong>{header}</strong>
                    {i > 1 ?
                        <>
                            <small className="d-inline-block ml-1">
                                <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id={'infoIcon' + i}><InformationIcon /></button>
                            </small>
                            <UncontrolledPopover
                                placement={i === 6 ? 'left' : 'bottom'}
                                target={'infoIcon' + i}
                                trigger="legacy"
                            >
                                <PopoverBody>{infoIcon[i]}</PopoverBody>
                            </UncontrolledPopover>
                        </>
                        : null}

                </th>
            )
        })
    }

    displayTotals = () => {
        let totalAnalytics = {
            postViews: 0,
            productClicks: 0,
            productViews: 0,
            salesConversionPercentage: 0,
            salesCount: 0,
            commissionsEarned: 0
        }

        this.props.analytics.forEach((dataPoint) => {
            totalAnalytics.postViews += dataPoint.postViews;
            totalAnalytics.productClicks += dataPoint.productClicks;
            totalAnalytics.productViews += dataPoint.productViews;
            totalAnalytics.salesCount += dataPoint.salesCount;
            totalAnalytics.commissionsEarned += dataPoint.commissionsEarned;
        })

        return (
            <tr>
                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        Totals
                    </strong>
                </td>
                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        {totalAnalytics.productClicks}
                    </strong>
                </td>
                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        {totalAnalytics.salesCount}
                    </strong>
                </td>
                <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>
                        ${totalAnalytics.commissionsEarned.toFixed(2)}
                    </strong>
                </td>
            </tr>
        )
    }

    getDaysBetweenDates = function (start, end) {
        for (var arr = [], dt = new DateTime(start); dt < end; dt = dt.plus({ days: 1 })) {
            arr.push(dt.toJSDate());
        }
        return arr;
    };

    displayChart = () => {
        var data = [];
        var labels = [];

        const dateRange = this.state.selectOptions.currentValue.getDateRange();
        var dates = this.getDaysBetweenDates(dateRange.startDate, dateRange.endDate);
        dates.forEach(date => {
            const formattedDate = convertToNumericMonthAndDayAndYear(date, false);

            if (!data.find(x => x.date == formattedDate)) {

                data.push({
                    date: formattedDate,
                    postViews: 0,
                    productViews: 0,
                    productClicks: 0,
                    salesCount: 0,
                    commissionsEarned: 0
                });

                const formattedLabelDate = convertToNumericMonthAndDayAndYear(date, false);
                labels.push(formattedLabelDate);
            }
        });

        this.props.analytics.forEach(analytic => {
            const formattedDate = convertToNumericMonthAndDayAndYear(new Date(analytic.date), false);
            const item = _.find(data, (item) => item?.date === formattedDate);
            if (item) {
                item.postViews += analytic.postViews;
                item.productViews += analytic.productViews;
                item.productClicks += analytic.productClicks;
                item.salesCount += analytic.salesCount;
                item.commissionsEarned += analytic.commissionsEarned;
            }
        });

        const { options, series } = this.state;
        series[0].data = data.map((item) => item.productClicks);
        series[1].data = data.map((item) => item.salesCount);
        series[2].data = data.map((item) => item.commissionsEarned);
        options.labels = labels;

        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" />
            </div>
        );
    }

    render() {
        return (
            <div className="container pt-0 pb-5 px-0 px-md-3" style={this.props.display ? {} : { display: 'none' }}>
                <div className="search-interest py-4 mx-auto">
                    <div className="search-block">
                        <div className="row mx-0">
                            <div className="col-12 col-md-4 mt-1 position-relative float-right">
                                <Select
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    placeholder="Select timeframe ..."
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    onChange={(e) => { this.handleSelect(e) }}
                                    options={this.state.selectOptions.options}
                                    defaultValue={this.state.selectOptions.defaultValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.analyticsLoading ?
                        <LoadSpinner /> :
                        <>
                            {this.displayChart()}
                            <div className="row mx-0 my-0">
                                <div className="table-responsive product-table">
                                    <table className="table">
                                        <colgroup>
                                            <col span="1" style={{ width: "14%" }} />
                                            <col span="1" style={{ width: "14%" }} />
                                            <col span="1" style={{ width: "14%" }} />
                                            <col span="1" style={{ width: "14%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                {this.displayTableHeader()}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.displayTableRows()}
                                            {this.displayTotals()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}

export default BrandAnalyticsChart;