import React from "react";
import { getRequest } from "sharedUtils/httpUtils";
import UserTileGeneral from "components/UserTileGeneral";
import InfiniteScroll from "react-infinite-scroll-component";


class FollowersFollowingRender extends React.Component {
    follow = (userId, followUnfollow) => {
        let followersOrFollowing = this.props.followersOrFollowing;
        let users = this.props.users;

        users.forEach((user, i) => {
            if (user.id === userId) {
                let isFollowingChange = !user?.isFollowing;
                user.isFollowing = isFollowingChange;
            }
        })

        this.props.setUsers(users);

        let url = followUnfollow === "follow" ? "api/Account/Follow" : "api/Account/Unfollow";
        url += `?id=${userId}`;

        let req = getRequest(url);

        if (this.props.followersOrFollowing !== "creators" && !this.props.fetchFollowing) this.props.toggleFetchFollowing();
    }

    mapUsers = () => {
        let users = !!(this.props.users) ? this.props.users : [];
        
        return users?.map((user, i) => {
            let buttonText = user.isFollowing === true ? "Unfollow -" : "Follow +";
            return (
                <div className="d-flex user-follow mb-1 py-1" style={{ boxShadow: "none" }} key={i} >
                    <UserTileGeneral user={user} isFollowing={user.isFollowing} showCheckMark={false} subtitle={" "} />

                    {
                        user.proStatusId === 1 && this.props.loggedInUser?.id !== user.id ?
                            <button className="btn btn-sm follow-btn d-flex justify-content-center"
                                style={{ marginLeft: "-50px", zIndex: !(this.props.loggedInUser) ? 900 : "" }}
                                onClick={() => {
                                    if (!(this.props.loggedInUser)) {
                                        window.open(window.location.origin + "/Identity/Account/Register?returnUrl=/profile/" + user.userName);
                                    } else if (user.isFollowing === true) {
                                        this.follow(user.id, "unfollow")
                                    }
                                    else {
                                        this.follow(user.id, "follow")
                                    }
                                }}
                            >
                                {buttonText}
                            </button> : <></>
                    }
                </div>
            )
        })
    }

    render() {
        return (
            <div className="ml-3 mt-0">
                <h5 className="mb-3">{this.props.heading}</h5>
                <InfiniteScroll
                    dataLength={this.props.followingFollowersCreatorsLength}
                    hasMore={this.props.finishedFetching}
                    next={this.props.fetchMore}
                    height={300}
                    style={{ overflow: "auto" }}
                    className="infinite-scroll"
                >
                    {this.mapUsers()}
                </InfiniteScroll>
            </div>
        )
    }
}

export default FollowersFollowingRender;
