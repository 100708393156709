import React from "react"

const RightArrowIconSmall = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.13128 16.4337C8.0434 16.5212 7.99946 16.635 7.99946 16.7751C7.99946 16.8977 8.0434 17.0027 8.13128 17.0903C8.23675 17.1778 8.351 17.2216 8.47403 17.2216C8.59707 17.2216 8.70253 17.1778 8.79042 17.0903L14.0899 11.7852C14.1777 11.6802 14.2217 11.5664 14.2217 11.4438C14.2217 11.3212 14.1777 11.2074 14.0899 11.1024L8.79042 5.79733C8.70253 5.70979 8.59707 5.66602 8.47403 5.66602C8.351 5.66602 8.23675 5.70979 8.13128 5.79733C8.0434 5.88487 7.99946 5.99868 7.99946 6.13874C7.99946 6.2613 8.0434 6.36635 8.13128 6.45389L12.9561 11.4438L8.13128 16.4337Z" fill="#212529" />
        </svg>
    )
}

export default RightArrowIconSmall;